/* eslint-disable */
import React, { useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Avatar, Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import moment from "moment";
import Select from "react-select";
import axios from "axios";
import toastr from "toastr";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { Col } from "reactstrap";
import "./profile.scss";

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
const ProfileMenu = (props) => {
  let { details = {} } = props;
  const [fullDetails, setDetails] = useState({});
  const [selectedCurrentStatus, setSelectedCurrentStatus] = useState(null);
  const [currentStatusOptions, setCurrentStatusOptions] = useState([]);

  useEffect(() => {
    if (details !== null && details !== "undefined") {
      setDetails(details);
      let status = details?.basic_details?.basic_id?.current_status?.name;
      let status_value = {};
      status_value.label =
        details?.basic_details?.basic_id?.current_status?.name;
      status_value.value =
        details?.basic_details?.basic_id?.current_status?._id;
      setSelectedCurrentStatus(status_value);
    }
    getCurrentstatuslist();
  }, [details]);

  let { basic_details } = fullDetails;

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };
  const getCurrentstatuslist = () => {
    axios
      .get(`${API_URL}options/current-status`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;

        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setCurrentStatusOptions(options);
      });
  };
  const updateStatus = (selected) => {
    let data = {
      status: selected.value,
      admission_id: details._id,
    };
    axios
      .put(`${API_URL}admission/current-status`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Status Updated Successfully");
          setSelectedCurrentStatus(selected);
        } else {
          toastr.error(res.data.message);
          return;
        }
      });
  };

  return (
    <div
      className="card"
      style={{
        // minHeight: "45rem",
        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
      }}
    >
      <div className="card-body">
        <div className="float-end dropdown">
          <div
            tabindex="-1"
            role="menu"
            aria-hidden="true"
            className="dropdown-menu-end dropdown-menu"
          >
            <a to="/" tabindex="0" role="menuitem" className="dropdown-item">
              Edit
            </a>
            <a to="/" tabindex="1" role="menuitem" className="dropdown-item">
              Action
            </a>
            <a to="/" tabindex="2" role="menuitem" className="dropdown-item">
              Remove
            </a>
          </div>
        </div>
        <Stack direction="row" spacing={2} style={{ justifyContent: "center" }}>
          <Avatar
            alt={`${basic_details?.basic_id?.fname}`}
            sx={{ width: 56, height: 56 }}
            src={
              basic_details?.photo
                ? `https://crmapi.pratheekshathalassery.com/uploads/admissions/photos/${basic_details?.photo}`
                : ""
            }
            style={{
              width: "6rem",
              height: "6rem",
              marginBottom: "auto",
            }}
          />
        </Stack>
        <a
          aria-haspopup="true"
          className="text-body font-size-16 ddropdown-toggle"
          aria-expanded="false"
        >
          {/* <i
            className="fas fa-key"
            style={{
              fontSize: "1em",
              position: "relative",
              float: "right",
              bottom: "83px",
            }}
          ></i> */}
        </a>
        <h5
          className="mt-3 mb-1"
          style={{
            textAlign: "center",
            fontFamily: "IBM Plex Sans,sans-serif",
          }}
        >
          {typeof basic_details?.basic_id?.fname !== "undefined" &&
            basic_details?.basic_id?.fname +
              " " +
              basic_details?.basic_id?.lname}
        </h5>
        <div
          className="text-muted"
          style={{
            textAlign: "center",
            color: "green !important",
            fontFamily: "IBM Plex Sans,sans-serif",
            fontSize: "14px",
            marginBottom: "3px",
          }}
        >
          <div
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
          >
            {/* <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Call Now
                </Tooltip>
              }
            > */}
            <button
              class="btn btn-success"
              onClick={() =>
                (window.location.href =
                  "tel:+91" + basic_details?.basic_id?.mobile)
              }
              style={{
                borderRadius: "40px",
                marginRight: "10px",
                padding: "0",
                width: "28px",
              }}
            >
              <i className="uil-phone-alt" style={{ fontSize: "15px" }} />
            </button>
            {/* </OverlayTrigger> */}
          </div>
          <a
            style={{ cursor: "pointer", color: "black" }}
            href={"tel:+91" + basic_details?.basic_id?.mobile}
          >
            {basic_details?.basic_id?.mobile}
          </a>
        </div>

        <div
          className="mt-1 mb-2"
          style={{
            textAlign: "center",
            fontFamily: "IBM Plex Sans,sans-serif",
          }}
        ></div>
        <Divider />

        <table
          style={{
            width: "100%",
            borderCollapse: "separate",
            borderSpacing: "0 8px",
          }}
        >
          {/* <tr>
            <td>
              <span className="h5 font-size-13">Name: </span>
            </td>
            <td>
              <span
                style={{ wordBreak: "break-all" }}
                className="h5 font-size-14"
              >
                {basic_details?.basic_id?.fname +
                  " " +
                  basic_details?.basic_id?.lname}
              </span>
            </td>
          </tr> */}
          <tr>
            <td>
              <span className="font-size-13">Reg No </span>
            </td>
            <td>
              <span className="h5 font-size-14">{fullDetails?.reg_no}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="font-size-13">Status </span>
            </td>
            <td>
              <Col md="10">
                {/* <span className="h5 font-size-14">{fullDetails?.basic_details?.basic_id?.current_status?.name}</span> */}
                <div className="mb-0">
                  <Select
                    styles={style}
                    name="current_status"
                    value={selectedCurrentStatus}
                    onChange={(selected) => {
                      updateStatus(selected);
                    }}
                    options={currentStatusOptions}
                    classNamePrefix="select2-selection "
                    className="status_class"
                  />
                </div>
              </Col>
            </td>
          </tr>
          <tr>
            <td>
              <span className="font-size-13">Age </span>
            </td>
            <td>
              <span className="h5 font-size-14">{basic_details?.age}</span>
            </td>
          </tr>
          {basic_details?.religion && (
            <tr>
              <td>
                <span className="font-size-13">Religion </span>
              </td>
              <td>
                <span
                  style={{ wordBreak: "break-all" }}
                  className="h5 font-size-14"
                >
                  {basic_details?.religion?.name}
                </span>
              </td>
            </tr>
          )}

          {basic_details?.basic_id?.email && (
            <tr>
              <td>
                <span className="font-size-13">Email </span>
              </td>
              <td>
                <span
                  style={{ wordBreak: "break-all" }}
                  className="h5 font-size-14"
                >
                  <MailOutlineIcon style={{ fontSize: "15px" }} />
                  &nbsp;{" "}
                  <a
                    style={{ cursor: "pointer", color: "#495057" }}
                    href={"mailto:" + basic_details?.basic_id?.email}
                  >
                    {basic_details?.basic_id?.email}
                  </a>
                </span>
              </td>
            </tr>
          )}
          {basic_details?.support_persons[0]?.sp_name && (
            <tr>
              <td>
                <span className="font-size-13">Support Person </span>
              </td>
              <td>
                <span
                  style={{ wordBreak: "break-all" }}
                  className="h5 font-size-14"
                >
                  {basic_details?.support_persons[0]?.sp_name}
                </span>
              </td>
            </tr>
          )}
          {basic_details?.support_persons[0]?.sp_mobile && (
            <tr>
              <td>
                <span className="font-size-13">Support Person </span>
              </td>
              <td>
                <span className="h5 font-size-14">
                  <i className="mdi mdi-phone"></i>&nbsp;
                  <a
                    style={{ cursor: "pointer", color: "#495057" }}
                    href={
                      "tel:+91" + basic_details?.support_persons[0]?.sp_mobile
                    }
                  >
                    {basic_details?.support_persons[0]?.sp_mobile}
                  </a>
                </span>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <span className="font-size-13">Registration Date </span>
            </td>
            <td>
              <span
                style={{ wordBreak: "break-all" }}
                className="h5 font-size-14"
              >
                {moment(fullDetails?.basic_details?.reg_date).format(
                  "DD-MM-YYYY"
                )}
              </span>
            </td>
          </tr>
          {fullDetails?.exp_discharge_date && (
            <tr>
              <td>
                <span className="font-size-13">Discharged Date </span>
              </td>
              <td>
                <span
                  style={{ wordBreak: "break-all" }}
                  className="h5 font-size-14"
                >
                  {moment(fullDetails?.exp_discharge_date).format("DD-MM-YYYY")}
                </span>
              </td>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
};

export default ProfileMenu;
