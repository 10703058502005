import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

export let getMedicineChecklist = (date, keyword) =>
  axios
    .get(`${API_URL}medicine-checklist?date=${date}&keyword=${keyword}`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => console.error(err));

export let getPatientMedicine = (id, date, time) =>
  axios
    .get(
      `${API_URL}medicine-checklist/options?date=${date}&admission_id=${id}`,
      {
        headers: { "x-access-token": accessToken },
      }
    )
    .then((res) => res.data.data[0][time])
    .catch((err) => console.error(err));

export let updateMedicine = (data) =>
  axios
    .put(`${API_URL}medicine-checklist`, data, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data)
    .catch((err) => console.error(err));
