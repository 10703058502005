/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import saveAs from "file-saver";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//import "./grouptherapy.scss";
import toastr from "toastr";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import "./billgeneration.scss";
const Billgeneration = (props) => {
  const formRef = useRef();
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientOptions, setPatientOptions] = useState([]);
  const [values, setValues] = useState([{}]);
  const [patientvalues, setPatientValues] = useState(false);
  const [masterObject, setmasterObject] = useState({ chart: {} });
  const [billvalues, setBillValues] = useState(false);
  const [billIdTobeDeleted, setBillIdTobeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    handleTableData();
    fetch_all_patients();
  }, []);

  const fetch_all_income_expense = (e) => {
    return axios
      .get(`${API_URL}bill/total_income_expense?admission_id=` + e, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let data = res.data.data;
        return data;
      });
  };

  const handleSelectChange = async (selected, type) => {
    switch (type) {
      case "admission_id":
        setSelectedPatient(selected);
        setPatientValues(true);
        setBillValues(true);
        let data = await fetch_all_income_expense(selected.value);
        let name = data.fname + " " + data.lname;

        setmasterObject({
          ...masterObject,
          ...data,
          name: name,
          admission_id: selected.value,
          councilor_name: `${data.counsellor_first_name || ""} ${data.counsellor_last_name || ""}`,
          admitted_date: moment(data?.admitted_date).format("DD-MM-YYYY"),
          discharged_date: data?.exp_discharge_date ? moment(data?.exp_discharge_date).format("DD-MM-YYYY") : "",
          pay_amount: data?.total_pay,
        });

        break;

      default:
        break;
    }
  };
  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var to_pay = masterObject.Total_expense - masterObject.Total_income;
    var total_pay = to_pay - value;

    setmasterObject({
      ...masterObject,
      [name]: value,
      pay_amount: total_pay,
    });
  };

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}bill/patient`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: `${el?.fname || ""}  ${el?.lname || ""}  ${el?.reg_no ? `- ${el?.reg_no}` : "" || ""}`,
              value: el?._id,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  function handleTableData() {
    var url = `${API_URL}bill/bill_list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        result.map((item, index) => {
          item.id = index + 1;
          item.date = moment(item?.date).format("DD-MM-YYYY");
          item.Patient_name = item?.Patient_name;
          item.reg_no = item?.admission_id?.reg_no;
          item.Counselor_name = item?.Counselor_name;
          item.staff = item?.addedby?.firstName;

          if (props.options.edit) {
            item.action = (
              <>
                <i
                  //  className="fas fa-upload"
                  className="fas fa-print"
                  style={{
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // downloadbill(item);
                    handlePrint(`${API_URL}` + item?.pdf_link);
                  }}></i>

                <i
                  className="far fa-trash-alt"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    setBillIdTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </>
            );
          } else {
            item.action = (
              <i
                //  className="fas fa-upload"
                className="fas fa-print"
                style={{
                  fontSize: "0.9rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // downloadbill(item);
                  handlePrint(`${API_URL}` + item?.pdf_link);
                }}></i>
            );
          }
        });

        setTableData(result);
      });
  }
  const handlePrint = (url) => {
    window.open(url, "_blank");
  };
  const downloadbill = (item) => {
    saveAs(`${API_URL}uploads` + item?.pdf_link, `Bill-${moment().format("DDMMYYYY")}`);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "Patient_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "reg_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Councilor",
        field: "Counselor_name",
        sort: "asc",
        width: 270,
      },

      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  useEffect(() => {
    data.rows.map((item) => {
      if (props.options.edit) {
        item.action = (
          <>
            <i
              className="fas fa-print"
              style={{
                fontSize: "0.9rem",
                cursor: "pointer",
              }}
              onClick={() => {
                handlePrint(`${API_URL}` + item?.pdf_link);
              }}></i>

            <i
              className="far fa-trash-alt"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "5px",
              }}
              onClick={() => {
                setBillIdTobeDeleted(item._id);
                setConfirmDeleteAlert(true);
              }}></i>
          </>
        );
      } else {
        item.action = (
          <i
            className="fas fa-print"
            style={{
              fontSize: "0.9rem",
              cursor: "pointer",
            }}
            onClick={() => {
              // downloadbill(item);
              handlePrint(`${API_URL}` + item?.pdf_link);
            }}></i>
        );
      }
    });
  }, [props.options]);

  function handleTableInputchange(e, index, goal) {
    let { name, value } = e.target;
    const list = [...values];
    //list[index]={goal:'',activities:'',time_frame:''}
    //list[index].goal = goal;
    list[index][name] = value;
    setValues(list);
    setmasterObject({ ...masterObject, treatment_plan: list });
  }

  const handleValidSubmit = (data) => {
    axios
      .post(`${API_URL}bill/add_bill`, masterObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success("bill generated successfully");

        formRef.current.reset();

        setmasterObject({ chart: {} });
        setSelectedPatient(null);

        handleTableData();
        setBillValues(false);
      })
      .catch((err) => {
        return;
      });
    // }
  };
  // console.log(masterObject);
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}bill?_id=` + billIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Bill deleted successfully");
                  if (masterObject && masterObject._id === billIdTobeDeleted) {
                    formRef.current.reset();
                    setmasterObject({});
                    setBillIdTobeDeleted(null);
                  }
                  setBillIdTobeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Bill");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Bill Generation" />
          {props.options.edit && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={() => {
                        handleValidSubmit(masterObject);
                      }}>
                      <Row>
                        <Col md="3">
                          <div className="mt-1">
                            <Label>Patient</Label>
                            <Select
                              name="admission_id"
                              value={selectedPatient}
                              onChange={(selected) => {
                                handleSelectChange(selected, "admission_id");
                              }}
                              options={patientOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </Row>
                      {billvalues == true ? (
                        <>
                          <Col xl="9" className="mt-4">
                            <Row style={{ marginLeft: "2px" }}>
                              <Table style={{ textAlign: "center" }} className="table table-bordered dataTable table_income">
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      1
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "280px",
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      Patient Name
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "12.819px",

                                        color: "#495057",
                                        fontWeight: "200",
                                      }}
                                      colSpan={2}>
                                      {masterObject.name}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      2
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      Councilor Name
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "12.819px",

                                        color: "#495057",
                                        fontWeight: "200",
                                      }}
                                      colSpan={2}>
                                      {masterObject.councilor_name}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      3
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      Admitted Date
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "12.819px",

                                        color: "#495057",
                                        fontWeight: "200",
                                      }}
                                      colSpan={2}>
                                      {masterObject?.admitted_date}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      4
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      Discharge Date
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "12.819px",

                                        color: "#495057",
                                        fontWeight: "200",
                                      }}
                                      colSpan={2}>
                                      {/* {masterObject?.discharged_date} */}
                                      <AvField
                                        name="discharged_date"
                                        type="date"
                                        className="form-control"
                                        onChange={(e) => {
                                          let value = moment(e.target.value).format("DD-MM-YYYY");
                                          setmasterObject((pre) => ({ ...pre, discharged_date: value }));
                                        }}
                                        // max={moment().format("YYYY-MM-DD")}
                                        value={masterObject?.discharged_date && moment(masterObject?.discharged_date, "DD-MM-YYYY").format("YYYY-MM-DD")}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "200",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}></TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "200",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}></TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",

                                        color: "#495057",
                                      }}>
                                      Income (Rs)
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      Expense (Rs)
                                    </TableCell>
                                  </TableRow>
                                  {Object.keys(masterObject?.chart).map((key, idx) => {
                                    return (
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: "#495057",
                                          }}>
                                          {idx + 4 + 1}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: "#495057",
                                            textTransform: "capitalize",
                                          }}>
                                          {key}
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            textAlign: "right",
                                            fontSize: "12.819px",
                                            color: "#495057",
                                            fontWeight: "200",
                                          }}>
                                          {masterObject.chart[key].type === "income" ? masterObject.chart[key].amount : 0}
                                        </TableCell>

                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            textAlign: "right",
                                            fontSize: "12.819px",
                                            color: "#495057",
                                            fontWeight: "200",
                                          }}>
                                          {masterObject.chart[key].type === "expense" ? masterObject.chart[key].amount : 0}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      11
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      Total
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                        fontWeight: "200",
                                      }}>
                                      {masterObject.Total_income}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "right",
                                        marginRight: "1000px",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                        fontWeight: "200",
                                      }}>
                                      {masterObject.Total_expense}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      12
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      Discount
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                        fontWeight: "200",
                                      }}
                                      colSpan={4}>
                                      <Col xl="8" className="float-right">
                                        <AvField
                                          name="discount"
                                          type="number"
                                          validate={{
                                            pattern: { value: "^[0-9]+$" },
                                          }}
                                          className="form-control"
                                          onChange={handleChangeInput}
                                          value={masterObject?.discount}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        />
                                      </Col>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      13
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "#495057",
                                      }}>
                                      Pay Amount
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "right",
                                        fontSize: "12.819px",

                                        color: "#495057",
                                        fontWeight: "200",
                                      }}
                                      colSpan={4}>
                                      <Col xl="8" className="float-right">
                                        {masterObject?.pay_amount}
                                      </Col>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Row>
                          </Col>
                          <Col md="1" className="mt-1 float-right">
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          </Col>
                        </>
                      ) : null}
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <MDBDataTable
                        id="billTableID"
                        responsive
                        //striped
                        bordered
                        data={data}
                        info={true}
                        searching={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Billgeneration;
