/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, FormGroup, Label, Row, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import saveAs from "file-saver";
import toastr from "toastr";
import { AvField, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "./styles.css";
import { MDBDataTable } from "mdbreact";
import { createCertificate, getInternCertificates } from "./api";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import DeleteAlert from "../../../components/Common/DeleteAlert";

const Interns = (props) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState({ show: false });
  const [tableData, setTableData] = useState([]);
  const [masterObject, setMasterObj] = useState({});
  const [reload, setReload] = useState(false);
  useEffect(() => {
    getInternCertificates().then((data) => {
      data.map((item, idx) => {
        item.id = idx + 1;
        item.name = item.firstname + " " + item.lastname;
        item.created_by = item.addedBy.firstName;

        if (props.options.edit) {
          item.action = (
            <>
              <span>
                <i
                  onClick={() => handlePrint(API_URL + item.certificate_path)}
                  className="fas fa-print"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                  }}></i>
                {/* </a> */}

                <i
                  className="far ms-3 fa-trash-alt"
                  style={{ fontSize: "0.9em", cursor: "pointer" }}
                  onClick={() => {
                    setConfirmDeleteAlert({ show: true, id: item._id });
                  }}></i>
              </span>
            </>
          );
        } else {
          item.action = (
            <>
              <span>
                <i
                  onClick={() => handlePrint(API_URL + item.certificate_path)}
                  className="fas fa-print"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                  }}></i>
              </span>
            </>
          );
        }
      });
      setTableData(data);
    });
  }, [reload]);

  const handlePrint = (url) => {
    window.open(url, "_blank");
    // saveAs(certificatePath);
    // window.print();
  };

  const handleChange = (e) => {
    let { name, type, value, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;
    setMasterObj((preVal) => {
      return {
        ...preVal,
        [name]: newValue,
      };
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "disabled",
        width: 150,
      },
      {
        label: "From Date",
        field: "to_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "To Date",
        field: "from_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Duration",
        field: "duration",
        sort: "asc",
        width: 200,
      },
      {
        label: "place",
        field: "place",
        sort: "asc",
        width: 100,
      },
      {
        label: "Created By",
        field: "created_by",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };
  useEffect(() => {
    data.rows.map((item) => {
      if (props.options.edit) {
        item.action = (
          <>
            <span>
              <i
                onClick={() => handlePrint(API_URL + item.certificate_path)}
                className="fas fa-print"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                }}></i>

              <i
                className="far ms-3 fa-trash-alt"
                style={{ fontSize: "0.9em", cursor: "pointer" }}
                onClick={() => {
                  setConfirmDeleteAlert({ show: true, id: item._id });
                }}></i>
            </span>
          </>
        );
      } else {
        <span>
          <i
            onClick={() => handlePrint(API_URL + item.certificate_path)}
            className="fas fa-print"
            style={{
              fontSize: "0.9em",
              cursor: "pointer",
            }}></i>
        </span>;
      }
    });
  }, [props.options]);

  const handleSubmit = (data) => {
    createCertificate(data).then((res) => {
      if (res.status === 200) {
        toastr.success(res.message);
        formRef.current.reset();
        setMasterObj({});
        setReload(!reload);
      }
    });
  };

  let deleteToggle = () => {
    setConfirmDeleteAlert({
      ...confirmDeleteAlert,
      show: !confirmDeleteAlert.show,
    });
  };

  let deleteCertificate = (id) => {
    axios
      .delete(`${API_URL}certificates/intern?id=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success(res.data.message);
          setReload(!reload);
        }
        return;
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
    setConfirmDeleteAlert({ show: false });
  };

  return (
    <>
      <DeleteAlert name="Certificate Delete" onConfirm={deleteCertificate} toggle={deleteToggle} data={confirmDeleteAlert} />;
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Intern Certificates" />
          {props.options.edit && (
            <Row>
              <Card>
                <CardBody>
                  <AvForm ref={formRef} onValidSubmit={() => handleSubmit(masterObject)}>
                    <Row>
                      <Col md={4}>
                        <Row>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="firstname">First name</Label>
                              <AvField
                                // disabled={!editable}
                                name="firstname"
                                type="text"
                                className="form-control"
                                placeholder="Enter firstname"
                                value={masterObject?.firstname}
                                onChange={handleChange}
                                validate={{
                                  required: { value: true },
                                }}
                                // errorMessage="Enter fathername"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="lastname">Last name</Label>
                              <AvField
                                name="lastname"
                                type="text"
                                className="form-control"
                                placeholder="Enter lastname"
                                value={masterObject?.lastname}
                                onChange={handleChange}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={4} className="male-female">
                            <AvRadioGroup name="gender" required>
                              <FormGroup className="mb-3">
                                <Label htmlFor="gender" style={{ marginBottom: "0.3rem" }}>
                                  Gender
                                </Label>
                                <AvRadio className="form-check-input" name="gender" label={"Male"} onClick={handleChange} id="male" value={1} />
                                <AvRadio className="form-check-input" name="gender" id="Female" label={"female"} onClick={handleChange} value={0} />
                              </FormGroup>
                            </AvRadioGroup>
                          </Col>
                          <Col md={8}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="firstname">College</Label>
                              <AvField
                                // disabled={!editable}
                                name="college"
                                type="textarea"
                                rows="1"
                                className="form-control"
                                placeholder="Enter college"
                                value={masterObject?.college}
                                onChange={handleChange}
                                validate={{
                                  required: { value: true },
                                }}
                                // errorMessage="Enter fathername"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label>From Date</Label>
                              <AvField
                                //   isDisabled={!editable}
                                name="from_date"
                                placeholder="Date"
                                type="date"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={handleChange}
                                value={masterObject?.from_date}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label>To Date</Label>
                              <AvField
                                //   isDisabled={!editable}
                                name="to_date"
                                placeholder="Date"
                                type="date"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={handleChange}
                                value={masterObject?.to_date}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={2}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="lastname">Place</Label>
                          <AvField
                            name="place"
                            type="text"
                            className="form-control"
                            placeholder="Enter place"
                            onChange={handleChange}
                            value={masterObject?.place}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1} className="create-btn">
                        <button className="btn btn-primary" type="submit">
                          Create
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Row>
          )}
          <Row>
            <Card>
              <CardBody>
                <MDBDataTable className="text-center" id="internDataTabel" responsive bordered data={data} />
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Interns;
