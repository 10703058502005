
/* eslint-disable */
import moment from "moment";
import * as XLSX from "xlsx";

export const getDate = (date) => {
  if (!date) {
    return null;
  }
  let dateValue = date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate()) + " 00:00:00.000";

  return moment(dateValue).format("YYYY-MM-DD");
};
export const getFirstday = (date) => {
  if (!date) {
    return null;
  }
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return moment(firstDay).format("YYYY-MM-DD");
};
export const getMonth = (date) => {
  if (!date) {
    return null;
  }
  let dateValue = date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate()) + " 00:00:00.000";

  return moment(dateValue).format("YYYY-MM");
};
const pad = (number) => {
  if (number < 10) {
    return "0" + number;
  }
  return number;
};
export const getDateTime = (date) => {
  if (!date) {
    return null;
  }

  let seconds =
    pad(String(date.getMilliseconds()).padStart(3, "0")).length > 3
      ? pad(String(date.getMilliseconds()).padStart(2, "0"))
      : pad(String(date.getMilliseconds()).padStart(3, "0"));
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    ":" +
    seconds
  );
};

export const setCompany = (companyID) => {
  localStorage.setItem("companyID", companyID);
};
export const getCompany = () => {
  return localStorage.getItem("companyID");
};

export const setLocalbody = (localbodyID) => {
  localStorage.setItem("localbodyID", localbodyID);
};
export const getLocalbody = () => {
  return localStorage.getItem("localbodyID");
};

export const getArraySumByKey = (arr, keyName) =>
  arr.map((item) => parseFloat(item[keyName] ? item[keyName] : 0)).reduce((a, b) => parseFloat(a ? a : 0) + parseFloat(b ? b : 0), 0);

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    if (amount === "-" || amount === "+") amount = amount + "0";

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {}
};

export const getDateAndTime = (date) => {
  if (!date) {
    return null;
  }
  return date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate()) + "T" + pad(date.getHours()) + ":" + pad(date.getMinutes());
};
export const setAssignCust = (customer) => {
  localStorage.setItem("customer", customer);
};
export const getAssignCust = () => {
  return localStorage.getItem("customer");
};
export const setMyObject = (myObject) => {
  localStorage.setItem("myObject", JSON.stringify(myObject));
};
export const getMyObject = () => {
  return localStorage.getItem("myObject");
};

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

export const inWords = (num) => {
  if ((num = num.toString()).length > 9) return "overflow";
  let n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore " : "";
  str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh " : "";
  str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand " : "";
  str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred " : "";
  str += n[5] != 0 ? (str != "" ? "and " : "") + (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) + "rupees only " : "";
  return str;
};

export const getRouteName = (props) => {
  let path = props.location.pathname;
  var res = path.split("/");
  return res[1];
};
export const getRouteLength = (props) => {
  let path = props.location.pathname;
  var res = path.split("/");
  let length = res.length;
  return length;
};

export const setLocalbodyName = (localbodyName) => {
  localStorage.setItem("localbodyName", localbodyName);
};
export const getLocalbodyName = () => {
  return localStorage.getItem("localbodyName");
};
export const removeByAttr = (arr, attr, value) => {
  var i = arr.length;
  while (i--) {
    if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
      arr.splice(i, 1);
    }
  }
  return arr;
};
export const getDaysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};
export const getDaysInMonthWithoutSunday = (year, month) => {
  var day, counter, date;
  day = 1;
  counter = 0;
  date = new Date(year, month, day);
  while (date.getMonth() === month) {
    if (date.getDay() === 0) {
      // Sun=0, Mon=1, Tue=2, etc.
      counter += 1;
    }
    day += 1;
    date = new Date(year, month, day);
  }
  return counter;
};

export const getUserId = () => {
  if (localStorage.getItem("authUser")) {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    let user_id = user_obj._id;
    return user_id;
  }
};

export const jsonToExcel = (jsonArray, sheetName = moment().format("DDMMYYhhmmss")) => {
  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(jsonArray);

  // Create a workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  // Save to a file
  XLSX.writeFile(wb, `${sheetName}.xlsx`);
};
