import { takeEvery, put, call } from "redux-saga/effects";
import { GET_FUND_TRANSFER, ADD_FUND_TRANSFER, GET_FUND_SOURCE } from "./actionTypes";
import {
  getAllFundTransferSuccess,
  getAllFundTransferFail,
  addFundTransferSuccess,
  addFundTransferFail,
  getAllFundSourceSuccess,
  getAllFundSourceFail,
} from "./actions";
import { getAllFundTransfer, addFundTransfer, getAllFundSource } from "../../helpers/backend_helper";

function* fetchFundTransfer({ localbody_id }) {
  try {
    const response = yield call(getAllFundTransfer, localbody_id);
    yield put(getAllFundTransferSuccess(response));
  } catch (error) {
    yield put(getAllFundTransferFail(error));
  }
}
function* fetchFundSource({ localbody_id }) {
  try {
    const response = yield call(getAllFundSource, localbody_id);
    yield put(getAllFundSourceSuccess(response));
  } catch (error) {
    yield put(getAllFundSourceFail(error));
  }
}
function* onAddFundTransfer({ payload: data }) {
  try {
    const response = yield call(addFundTransfer, data);
    yield put(addFundTransferSuccess(response));
  } catch (error) {
    yield put(addFundTransferFail(error.response));
  }
}
function* fundTransferSaga() {
  yield takeEvery(GET_FUND_TRANSFER, fetchFundTransfer);
  yield takeEvery(ADD_FUND_TRANSFER, onAddFundTransfer);
  yield takeEvery(GET_FUND_SOURCE, fetchFundSource);
}

export default fundTransferSaga;
