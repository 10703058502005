/* eslint-disable */
import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row,Col,Card,CardBody,Label,Button } from "reactstrap";
import $ from "jquery";
import moment from "moment";
import {
    getFirstday,
    getDate,
  } from "../../../helpers/globalFunctions";
  import "./style.scss";
const DayBook =(props)=>{
    const [master, setMaster] = useState({
        from_date: getFirstday(new Date()),
        to_date: getDate(new Date()),
      });
    let handleDate = (e) => {
        var date1 = $("#from_date").val();
        var date2 = $("#to_date").val();
    
        //date validation
        let toDate = moment(date1);
        let fromDate = moment(date2);
        let result = fromDate.diff(toDate, "days");
    
        if (result + 1 <= 0) {
          setMaster({
            ...master,
            [e.target.name]: e.target.value,
            ["to_date"]: "",
          });
          date2 = "";
        } else {
          setMaster({
            ...master,
            [e.target.name]: e.target.value,
          });
        }
      };
    return(
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                <Breadcrumbs title="Home" breadcrumbItem="Day Book" />
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="2">
                                    <div className="mb-3">
                                    <Label htmlFor="validationCustom05">From</Label>
                                    {/* <div className="col-md-10"> */}
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="from_date"
                                        name="from_date"
                                        onChange={handleDate}
                                        value={master?.from_date}
                                    />
                                    {/* </div> */}
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div className="mb-3">
                                    <Label htmlFor="validationCustom05">To</Label>
                                    {/* <div className="col-md-10"> */}
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="to_date"
                                        name="to_date"
                                        onChange={handleDate}
                                        value={master?.to_date}
                                        min={master?.from_date}
                                    />
                                    {/* </div> */}
                                    </div>
                                </Col>
                                <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                                    <Button
                                    color="primary"
                                    type="submit"
                                    >
                                    Search
                                    </Button>
                                </Col>
                                <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                                    <Button color="danger" type="reset">
                                    Reset
                                    </Button>
                                </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="12">
                    <Card>
                            <CardBody>
                                <Button className="btn btn-success mb-3" style={{float:"right"}}><i class="fas fa-file-excel"></i> Export</Button>
                                <table className="table table-bordered" id="tableDayBook">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Order No.</th>
                                            <th>Description</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={5} style={{ textAlign:"right", fontWeight:'bold'}}>Total Opening Balance</td>
                                            <td style={{ textAlign:"right", fontWeight:'bold'}}>672.00</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>01-11-2022 10:00:00</td>
                                            <td>311 0001</td>
                                            <td>ORDER COLLECTION - AIR CARGO</td>
                                            <td>00.00</td>
                                            <td>00.00</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>01-11-2022 10:00:00</td>
                                            <td>311 0001</td>
                                            <td>ORDER COLLECTION - AIR CARGO</td>
                                            <td>00.00</td>
                                            <td>29.00</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>02-11-2022 10:00:00</td>
                                            <td>311 0002</td>
                                            <td>ORDER COLLECTION - SEA CARGO</td>
                                            <td>00.00</td>
                                            <td>00.00</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>02-11-2022 10:00:00</td>
                                            <td>311 0002</td>
                                            <td>ORDER COLLECTION - SEA CARGO</td>
                                            <td>00.00</td>
                                            <td>30.00</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} style={{ textAlign:"right", fontWeight:'bold'}}>Total</td>
                                            <td style={{textAlign:"right"}}>00.00</td>
                                            <td style={{textAlign:"right"}}>59.00</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} style={{ textAlign:"right", fontWeight:'bold'}}>Today Balance</td>
                                            <td style={{ textAlign:"right", fontWeight:'bold'}}>59.00</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} style={{ textAlign:"right", fontWeight:'bold'}}>Today Closing Balance</td>
                                            <td style={{ textAlign:"right", fontWeight:'bold'}}>731.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default DayBook;