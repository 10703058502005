import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { ComponentToPrint } from "./ComponentToPrint";

const Example = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef}>
              <h1>Hello</h1>
            </ComponentToPrint>
          </div>
          <button onClick={handlePrint}>Print this out!</button>
        </div>
      </div>
    </>
  );
};

export default Example;
