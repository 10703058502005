/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, DropdownToggle, DropdownMenu, DropdownItem, Table, UncontrolledDropdown } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";

//Import Image
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import { getNonDrinkers } from "./api";
import MarqueeScroll from "./MarqueeScroll";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";
import { Avatar } from "@mui/material";
import { ObjectId } from "../../helpers/globalVariables";

const TopUser = ({ marquee }) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [accordian, setAccordian] = useState(false);
  const [nonDrinkers, setNonDrinkers] = useState([]);
  const [nonDrinkersAll, setNonDrinkersAll] = useState([]);
  const [filter, setFilter] = useState({ days: 1, exact: 0 });
  const [first, setFirst] = useState(false);
  const [selectedDrop, setSelectedDrop] = useState("All Members");
  const history = useHistory();
  const divRef = useRef(null);
  const loggerUser = JSON.parse(localStorage.getItem("authUser"));
  const [isAdmin, setIsAdmin] = useState(loggerUser.privilage === ObjectId.ADMIN || false);

  useEffect(() => {
    getNonDrinkers({ days: 10 }).then((res) => {
      setNonDrinkersAll(res.data);
    });
  }, []);
  useEffect(() => {
    getNonDrinkers(filter).then((res) => {
      setNonDrinkers(res.data);
    });
  }, [filter]);
  if (marquee) {
    return (
      <React.Fragment>
        {nonDrinkersAll.length > 0 && (
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Abstain more than 10 days</h4>
              <SimpleBar style={{ maxHeight: "336px", overflow: "hidden" }}>
                <MarqueeScroll>
                  <div className="table-responsive">
                    <Table className="table-borderless table-centered table-nowrap">
                      <tbody>
                        {nonDrinkersAll.map((data, idx) => (
                          <tr>
                            <td style={{ width: "20px" }}>
                              <Avatar
                                alt="....."
                                sx={{ width: 56, height: 56 }}
                                src={data.photo ? `${API_URL}uploads/admissions/photos/${data?.photo}` : ""}
                                style={{
                                  width: "3.5rem",
                                  height: "3.5rem",
                                  marginBottom: "auto",
                                }}
                              />
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <h6
                                style={{ cursor: "pointer" }}
                                className="font-size-15 mb-1 fw-normal"
                                onClick={() => {
                                  if (data.editable && isAdmin) {
                                    return history.push("/admission/" + data?.reg_no, {
                                      id: data?.admission_id,
                                    });
                                  }
                                }}>
                                {data?.fname + " " + data?.lname}
                              </h6>
                              <p className="text-muted font-size-13 mb-0">
                                <i className="mdi mdi-map-marker"></i> {data?.address}
                              </p>
                              <p className="text-muted font-size-13 mb-0">
                                <i className="mdi mdi-phone"></i>&nbsp;
                                <a className="link-hover" href={`tel:${data?.mobile}`}>
                                  {data?.mobile}
                                </a>
                              </p>
                            </td>
                            <td>
                              <span className={`badge ${"bg-soft-success"} font-size-12 p-2`}>{data?.days + " Days"}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </MarqueeScroll>
              </SimpleBar>
            </CardBody>
          </Card>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-end">
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-reset" id="dropdownMenuButton5" caret>
                  <span style={{ cursor: "pointer" }} className="text-muted">
                    {selectedDrop}
                    <i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu style={{ cursor: "pointer" }} className="dropdown-menu-end">
                  <DropdownItem
                    className={`${filter.days === 90 && "bg-soft-dark"} rounded`}
                    onClick={() => {
                      setSelectedDrop("3 Month");
                      setFilter({ days: 90, exact: 1 });
                    }}
                    style={{ cursor: "pointer" }}>
                    3 Month
                  </DropdownItem>
                  <DropdownItem
                    className={`${filter.days === 180 && "bg-soft-dark"} rounded`}
                    onClick={() => {
                      setSelectedDrop("6 Month");
                      setFilter({ days: 180, exact: 1 });
                    }}
                    style={{ cursor: "pointer" }}>
                    6 Month
                  </DropdownItem>
                  <DropdownItem
                    className={`${filter.days === 270 && "bg-soft-dark"} rounded`}
                    onClick={() => {
                      setSelectedDrop("9 Month");
                      setFilter({ days: 270, exact: 1 });
                    }}
                    style={{ cursor: "pointer" }}>
                    9 Month
                  </DropdownItem>
                  <DropdownItem
                    className={`${filter.days === 365 && "bg-soft-dark"} rounded`}
                    onClick={() => {
                      setSelectedDrop("12 Month");
                      setFilter({ days: 365, exact: 1 });
                    }}
                    style={{ cursor: "pointer" }}>
                    12 Month
                  </DropdownItem>
                  <DropdownItem
                    className={`${filter.days === 730 && "bg-soft-dark"} rounded`}
                    onClick={() => {
                      setSelectedDrop("2 Year");
                      setFilter({ days: 730, exact: 1 });
                    }}
                    style={{ cursor: "pointer" }}>
                    2 Year
                  </DropdownItem>
                  <DropdownItem
                    className={`${filter.days === 1 && "bg-soft-dark"} rounded`}
                    onClick={() => {
                      setSelectedDrop("All Members");
                      setFilter({ days: 1, exact: 0 });
                    }}
                    style={{ cursor: "pointer" }}>
                    All Members
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <h4 className="card-title mb-4">Non Drinkers</h4>
            <SimpleBar
              style={{
                maxHeight: "336px",
                overflow: "auto",
                overflowX: "hidden",
              }}>
              <div className="table-responsive" style={{ overflow: "hidden" }}>
                <Table className="table-borderless table-centered table-nowrap">
                  <tbody>
                    {nonDrinkers.map((data, idx) => (
                      <tr>
                        <td style={{ width: "20px" }}>
                          {/* <img
                    src={`${API_URL}uploads/admissions/photos/${data?.photo}`}
                    style={{ width: "3.5rem", height: "3.5rem" }}
                    className="avatar-xs rounded-circle "
                    alt="..."
                  /> */}
                          <Avatar
                            alt="....."
                            sx={{ width: 56, height: 56 }}
                            src={data.photo ? `${API_URL}uploads/admissions/photos/${data?.photo}` : ""}
                            style={{
                              width: "3.5rem",
                              height: "3.5rem",
                              marginBottom: "auto",
                            }}
                          />
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <h6
                            style={{ cursor: "pointer" }}
                            className="font-size-15 mb-1 fw-normal"
                            onClick={() => {
                              if (data.editable && isAdmin) {
                                return history.push("/admission/" + data?.reg_no, {
                                  id: data?.admission_id,
                                });
                              }
                            }}>
                            {data?.fname + " " + data?.lname}
                          </h6>
                          <p className="text-muted font-size-13 mb-0">
                            <i className="mdi mdi-map-marker"></i> {data?.address}
                          </p>
                          <p className="text-muted font-size-13 mb-0">
                            <i className="mdi mdi-phone"></i>&nbsp;
                            <a className="link-hover" href={`tel:${data?.mobile}`}>
                              {data?.mobile}
                            </a>
                          </p>
                        </td>
                        <td style={{ width: "15px" }}>
                          <span className={`badge ${"bg-soft-success"} font-size-12 p-2`}>{data?.days + " Days"}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </SimpleBar>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
};

export default TopUser;
