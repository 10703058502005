/* eslint-disable */
import React, { useState, useEffect,  } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import axios from "axios";
import classnames from "classnames"
import Select from 'react-select';
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import accessToken from "../../helpers/jwt-token-access/accessToken";

import "./medicalform.scss"
const FormWizard = () => {
  const [activeTab, setactiveTab] = useState(1)
  const [values, setValues] = useState([{}]);
  const [masterObject, setmasterObject] = useState({});
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientOptions, setPatientOptions] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";


  useEffect(() => {
    fetch_all_patients()

  }, []);


  const addMore = () => {
    setValues([...values, {}]);
  };

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
  };
  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.fname + " " + el?.lname + "-" + el?.reg_no,
              value: el?._id,

            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };
  const handleSelectChange = async (selected,type) =>{
    switch (type) {
      case "admission_id":
        setSelectedPatient(selected);
        default:
          break;
    }
  }
  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;


    setmasterObject({
        ...masterObject,
        [name]: value,
    });
};
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Medical Form" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}>
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                          >
                            <span className="number">1.</span>{" "}
                            Drinking/Drug History
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 2 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                          >
                            <span className="number me-2">2.</span>{" "}
                            Previous History
                          </NavLink>
                        </NavItem>

                      </ul>
                    </div>
                    <div >
                      <div className="body">
                        <TabContent
                          activeTab={activeTab}
                        >
                          <TabPane tabId={1}>
                            <AvForm className="needs-validation">
                              <Card>
                                <CardBody id="myOverflow" className="scroll-bar">
                                  <Row>
                                    <Col md="3">
                                      <div className="mt-1">
                                        <Label>Patient</Label>
                                        <Select
                                          name="admission_id"
                                          value={selectedPatient}
                                          onChange={(selected) => {
                                            handleSelectChange(selected, "admission_id");
                                          }}
                                          // validate={{ required: { value: true } }}
                                          options={patientOptions}
                                          classNamePrefix="select2-selection"

                                        />
                                      </div>
                                    </Col>

                                  </Row>
                                  {values && values.map((data, index) => {
                                    return (
                                      <Row
                                        key={index}
                                        className={
                                          values.length >= 2 && index !== 0
                                            ? "shadow rounded p-2 mt-3 border_bottom"
                                            : values.length === 1
                                              ? "shadow rounded p-2"
                                              : "shadow rounded p-2 border_bottom"
                                        }
                                      >
                                        <Col md="3" className="mt-2">
                                          <Label>Drugs</Label>
                                          <Select
                                            name="drugs"
                                            // value={selectedState}
                                            // validate={{ required: { value: true } }}
                                            onChange={(selected) => {
                                              // handleSelectChange(selected, "state");
                                            }}
                                            // options={stateOptions}
                                            classNamePrefix="select2-selection"
                                            menuPosition="auto"
                                          />
                                        </Col>
                                        <Col md="3">
                                          <div className="mt-2">
                                            <Label>Age of first use</Label>
                                            <AvField
                                              name="first_use_age"
                                              placeholder="Age of first use"
                                              type="text"
                                              className="form-control"
                                            //validate={{ required: { value: true } }}
                                            onChange={handleChangeInput}
                                            value={masterObject?.first_use_age}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mt-2">
                                            <Label>Years of use</Label>
                                            <AvField
                                              name="years_of_use"
                                              placeholder="Years of use"
                                              type="text"
                                              className="form-control"
                                            //validate={{ required: { value: true } }}
                                            onChange={handleChangeInput}
                                            value={masterObject?.years_of_use}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mt-2">
                                            <Label>Years of excessive use</Label>
                                            <AvField
                                              name="years_excessive_use"
                                              placeholder="Years of excessive use"
                                              type="text"
                                              className="form-control"
                                            //validate={{ required: { value: true } }}
                                            onChange={handleChangeInput}
                                            value={masterObject?.years_excessive_use}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mt-2">
                                            <Label>Specific type of drugs</Label>
                                            <AvField
                                              name="type_of_drug"
                                              placeholder="Specific type of drugs"
                                              type="text"
                                              className="form-control"
                                            //validate={{ required: { value: true } }}
                                            onChange={handleChangeInput}
                                            value={masterObject?.type_of_drug}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mt-2">
                                            <Label>Route of administration</Label>
                                            <AvField
                                              name="route"
                                              placeholder="Route of administration"
                                              type="text"
                                              className="form-control"
                                            //validate={{ required: { value: true } }}
                                            onChange={handleChangeInput}
                                            value={masterObject?.route}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mt-2">
                                            <Label>Frequency of use in the last 30 days</Label>
                                            <AvField
                                              name="frqncy_of_last_30d"
                                              placeholder="Frequency of use in the last 30 days"
                                              type="text"
                                              className="form-control"
                                            //validate={{ required: { value: true } }}
                                            onChange={handleChangeInput}
                                            value={masterObject?.frqncy_of_last_30d}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mt-2">
                                            <Label>Quantity used in the last 30 days</Label>
                                            <AvField
                                              name="qty_used_last_30d"
                                              placeholder="Quantity used in the last 30 days"
                                              type="text"
                                              className="form-control"
                                            //validate={{ required: { value: true } }}
                                            onChange={handleChangeInput}
                                            value={masterObject?.qty_used_last_30d}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mt-2">
                                            <Label>Past use if any</Label>
                                            <AvField
                                              name="past_use"
                                              placeholder="Past use if any"
                                              type="text"
                                              className="form-control"
                                            //validate={{ required: { value: true } }}
                                            onChange={handleChangeInput}
                                            value={masterObject?.past_use}
                                            />
                                          </div>
                                        </Col>
                                        <Col md={12}>
                                          {values.length !== 1 ? (
                                            <h5 style={{ float: "right" }}>
                                              <i
                                                onClick={() => handleRemove(index)}
                                                className="trash-btn mdi mdi-delete"
                                              ></i>
                                            </h5>
                                          ) : (
                                            ""
                                          )}
                                        </Col>

                                      </Row>
                                    )
                                  })}


                                  <div className="mt-3" style={{ display: 'flex', justifyContent: "left", alignItems: "left" }}>
                                    <span
                                      style={{ cursor: "pointer", fontWeight: "bold" }}
                                      onClick={() => addMore()}
                                      className="addspan"
                                    >
                                      <span className="mdi mdi-plus-circle-outline"></span> Add New
                                    </span>
                                  </div>
                                  <br />
                                  <Row className="mt-4">
                                    <Col md={12} >
                                      <FormGroup className="float-right ">
                                        <button type="submit" className="save-continue-btn">
                                          Submit
                                          <i style={{ fontSize: "13px" }}></i>
                                        </button>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>


                            </AvForm>
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={() => {
                                  //  handleSubmit(mstrObject);
                                }}
                              >
                                <Card>
                                  <CardBody>
                                    <Col md="12">
                                      <Row>
                                        <Col md="6">

                                          <div className="mt-3 mb-2">
                                            <h6 style={{ fontWeight: "600" }}>
                                              1. Withdrawal symptoms experienced when the patient stopped - Alcohol
                                            </h6>
                                          </div>
                                          <Row>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Tremors</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Insomnia</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="2">
                                              <p className="ms-1">
                                                <Label>Fits</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Nausea</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Achs/Pains</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md="6">

                                          <div className="mt-3 mb-2">
                                            <h6 style={{ fontWeight: "600" }}>
                                              2. Withdrawal symptoms experienced when the patient stopped - Drugs
                                            </h6>
                                          </div>

                                          <Row>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Tremors</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Insomnia</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Diarrhea</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Severe Pain</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Restlessness</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                          </Row>

                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col md="12">
                                      <Row>
                                        <Col md="6">
                                          <Row>
                                            <div className="mt-3 mb-2">
                                              <h6 style={{ fontWeight: "600" }}>
                                                3. Other psychiatric complications
                                              </h6>
                                            </div>
                                          </Row>
                                          <Row>
                                            {/* <div className='mt-2'> */}
                                            <Col md="4">
                                              <p className="ms-1">
                                                <Label>Depression</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Confusion</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Hallucinations</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>

                                          </Row>

                                          <Row>
                                            <Col md="4">
                                              <p className="ms-1">
                                                <Label>Aggressive outbursts</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>

                                            <Col md="4">
                                              <p className="ms-1">
                                                <Label>Paranoid Ideas</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            {/* </div> */}
                                          </Row>
                                          <Row>
                                            <Col md="5">
                                              <p className="ms-1">
                                                <Label>Suicidal ideation/attempts</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>

                                        <Col md="6">
                                          <Row >
                                            <div className="mt-3 mb-2">
                                              <h6 style={{ fontWeight: "600" }}>
                                                4. History of other medical problems in the past
                                              </h6>
                                            </div>
                                          </Row>
                                          <Row>
                                            {/* <div className='mt-2'> */}
                                            <Col md="4">
                                              <p className="ms-1">
                                                <Label>Haematemesis</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Jaundice</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Abscesses</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            {/* </div> */}
                                          </Row>
                                          <Row>
                                            {/* <div className='mt-2'> */}
                                            <Col md="4">
                                              <p className="ms-1">
                                                <Label>Bleeding piles</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Skin problems</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Any other</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            {/* </div> */}
                                          </Row>
                                        </Col>

                                      </Row>
                                    </Col>
                                    <Col md="12">
                                      <Row>
                                        <Col md="6">
                                          <Row >
                                            <div className="mt-3 mb-2">
                                              <h6 style={{ fontWeight: "600" }}>
                                                5. Chronic health problems
                                              </h6>
                                            </div>
                                          </Row>
                                          <Row>
                                            {/* <div className='mt-2'> */}
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Diabetics</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="4">
                                              <p className="ms-1">
                                                <Label>Liver disorders</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Epilepsy</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            {/* </div> */}
                                          </Row>
                                          <Row>
                                            {/* <div className='mt-2'> */}
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Infections</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="6">
                                              <p className="ms-1">
                                                <Label>Cardiac problems-HBP/IHD/RHD</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>

                                            {/* </div> */}
                                          </Row>
                                          <Row>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Others</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md="6">
                                          <Row>
                                            <div className="mt-3 mb-2">
                                              <h6 style={{ fontWeight: "600" }}>
                                                6. History of previous head injuries,if any
                                              </h6>
                                            </div>
                                          </Row>
                                          <Row>

                                            <AvField
                                              name="history"
                                              //placeholder=""
                                              type="textarea"
                                              className="form-control"
                                            // style={{
                                            //   height: "75px",
                                            // }}
                                            //validate={{ required: { value: true } }}
                                            //onChange={handleChangeInput}
                                            //value={masterObject?.serial_number}

                                            />

                                          </Row>
                                        </Col>

                                      </Row>
                                    </Col>
                                    <Col md="12">
                                      <Row>
                                        <Col md="6">
                                          <Row >
                                            <div className="mt-3 mb-2">
                                              <h6 style={{ fontWeight: "600" }}>
                                                7. Other Information
                                              </h6>
                                            </div>
                                          </Row>
                                          <Row>
                                            <h6 style={{ fontWeight: "450" }}>
                                              7.1 Use of Tobacco Products:
                                            </h6>
                                          </Row>
                                          <Row>
                                            {/* <div className='mt-2'> */}
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Smoking</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="4">
                                              <p className="ms-1">
                                                <Label>Pan Chewing</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Others</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            {/* </div> */}
                                          </Row>



                                          <Row>
                                            <h6 style={{ fontWeight: "450" }}>
                                              7.2 Family History of:
                                            </h6>
                                          </Row>
                                          <Row>
                                            {/* <div className='mt-2'> */}
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Alcoholism</Label>
                                                <input
                                                  // disabled={!editable}
                                                  // checked={stnExpBf.pvrty_dbts}
                                                  name="tremors"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="3">
                                              <p className="ms-1">
                                                <Label>Drug Abuse</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            <Col md="4">
                                              <p className="ms-1">
                                                <Label>Psychiatric illness</Label>
                                                <input
                                                  // disabled={!editable}
                                                  type="checkbox"
                                                  //checked={stnExpBf.erly_prnt_ls}
                                                  className="form-check-input"
                                                  name="insomnia"
                                                  style={{ marginLeft: "10px" }}
                                                //  onClick={(e) => handleInput(e, 0)}
                                                />
                                              </p>
                                            </Col>
                                            {/* </div> */}
                                          </Row>



                                        </Col>
                                        <Col md="6">
                                          <Row>
                                            <div className="mt-3 mb-2">
                                              <h6 style={{ fontWeight: "600" }}>
                                                8. Physical condition at the time of admission
                                              </h6>
                                            </div>
                                          </Row>
                                          <Row>
                                            {/* <Col md="3">Hi</Col>
                                            <Col md="3">Hlo</Col> */}
                                          </Row>

                                        </Col>

                                      </Row>
                                    </Col>

                                  </CardBody>
                                </Card>
                              </AvForm>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>


                  </div>
                </CardBody>
              </Card>
            </Col>


          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormWizard
