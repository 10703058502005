/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import FormGroup from "@mui/material/FormGroup";
import { Link } from "react-router-dom";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import { Row, Col, Card, CardBody, Button, Label, Media, Collapse, TabPane } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getDate } from "../../../../helpers/globalFunctions";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import "./review.scss";
import { useHistory } from "react-router-dom";
import axios from "axios";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Followup = (props) => {
  const formRef = useRef();
  let { activeTabVartical, details, id, editable } = props;
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientOptions, setPatientOptions] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [values, setValues] = useState([{}]);
  const [isOpen, setIsOpen] = useState(false);
  const [patientvalues, setPatientValues] = useState(false);
  const [masterObject, setmasterObject] = useState({
    review_date: getDate(new Date()),
    reviewdate: getDate(new Date()),
    visit_date: getDate(new Date()),
  });

  const [medicineOptions, setMedicineOptions] = useState([]);
  const [medicationIdTobeDeleted, setMedicationTobeDeleted] = useState(null);
  const [medicationIdTobeUpdated, setMedicationTobeUpdated] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [counsellorIdTobeDeleted, setCounsellorTobeDeleted] = useState(null);
  const [counsellorIdTobeUpdated, setCounsellorTobeUpdated] = useState(null);
  const [confirmDeleteAlerts, setConfirmDeleteAlerts] = useState(null);
  const [homevisitsIdTobeDeleted, setHomevisitsTobeDeleted] = useState(null);
  const [homevisitsIdTobeUpdated, setHomevisitsTobeUpdated] = useState(null);
  const [homevisitsconfirmDeleteAlerts, setHomevisitsConfirmDeleteAlerts] = useState(null);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [counselorpopupData, setCounselorPopupData] = useState({});
  const [counselorpopupView, setCounselorPopupView] = useState(false);
  const [homevisitsData, setHomevisitsPopupData] = useState({});
  const [homevisitspopupView, setHomevisitsPopupView] = useState(false);
  const [selectedReview, setSelectedReview] = useState("");
  const [reviewValue, setReviewValue] = useState(1);
  const [reviewOptions, setReviewOptions] = useState([]);

  const toggle = () => setIsOpen(!isOpen);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [counsellortableData, setCounsellorTableData] = useState([]);
  const [homevisitstableData, setHomevisitsTableData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetch_all_patients();
    handleTableData(props);
    fetch_all_medicines();
    fetch_all_review_type();
    counsellorTableData(props);
    homevisitsTableData(props);
    setmasterObject({
      ...masterObject,
      admission_id: props.id,
    });
  }, []);

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.fname + " " + el?.lname + "-" + el?.reg_no,
              value: el?._id,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  const fetch_all_review_type = () => {
    axios
      .get(`${API_URL}options/reviews`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let review_data =
          result &&
          result.map((el) => {
            if (result.length == 1) {
              setReviewValue(el?.prev_type);
              setSelectedReview({
                label: el?.name,
                value: el?._id,
              });
            } else {
              setSelectedReview({
                label: "Medical Review",
                value: "64535d998316a5f958a49c19",
              });
            }

            return {
              label: el?.name,
              value: el?._id,
            };
          });

        setReviewOptions([
          {
            options: review_data,
          },
        ]);
      });
  };

  const fetch_all_medicines = () => {
    axios
      .get(`${API_URL}medicine/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let medicine_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name + " " + el?.dose,
              value: el?._id,
            };
          });
        setMedicineOptions([
          {
            options: medicine_data,
          },
        ]);
      });
  };

  const fetch_patient_details = (_id) => {
    return axios
      .get(`${API_URL}admission?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let data = {};
        data.mobile = result?.basic_details?.basic_id?.mobile;
        data.age = result?.basic_details?.age;
        data.counsellor = result?.counsellor_id?.firstName;
        data.admitted_date = moment(result?.basic_details?.reg_date).format("DD-MM-YYYY");

        if (result?.basic_details?.gender == 0) {
          data.gender = "Female";
        }
        if (result?.basic_details?.gender == 1) {
          data.gender = "Male";
        }
        if (result?.basic_details?.gender == 2) {
          data.gender = "Others";
        }
        //  setmasterObject(data)

        return data;
      });
  };

  const addMore = () => {
    setValues([...values, {}]);
  };

  function handleTableData(props) {
    let admission_id = props.id;
    var url = `${API_URL}follow-up/medical_review/list?admission_id=` + admission_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
          item.date = moment(item?.review_date).format("DD-MM-YYYY");
          item.reg_no = item?.admission_id?.reg_no;
          item.firstName = item?.addedBy?.firstName;
          item.complaints = item?.complaints_of_patient;

          item.action = (
            <>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  preupdateMedication(item);
                  toTop();
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "0.9em", cursor: "pointer" }}
                onClick={() => {
                  setMedicationTobeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </>
          );
        });

        setTableData(result);
      });
  }

  function counsellorTableData(props) {
    let admission_id = props.id;
    var url = `${API_URL}follow-up/counsellor_review/list?admission_id=` + admission_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
          item.review_date = moment(item?.review_date).format("DD-MM-YYYY");
          item.reg_no = item?.admission_id?.reg_no;
          item.firstName = item.addedBy?.firstName;
          item.issues_dealt = item?.issues_dealt;
          item.recovery_status = item?.recovery_status;
          item.other_communication = item?.other_communication;

          item.action = (
            <>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpenform(item);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  preupdateCounsellorReview(item);
                  toTop();
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "0.9em", cursor: "pointer" }}
                onClick={() => {
                  setCounsellorTobeDeleted(item._id);
                  setConfirmDeleteAlerts(true);
                }}></i>
            </>
          );
        });

        setCounsellorTableData(result);
      });
  }

  function homevisitsTableData(props) {
    let admission_id = props.id;
    var url = `${API_URL}follow-up/home_visits/list?admission_id=` + admission_id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
          item.visit_date = moment(item?.visit_date).format("DD-MM-YYYY");
          item.last_visit = moment(item?.last_visit).format("DD-MM-YYYY");
          item.reg_no = item?.admission_id?.reg_no;
          item.firstName = item.addedBy?.firstName;
          item.reason = item?.reason;
          item.issues_dealt = item?.issues_dealt;
          item.response = item?.response;
          item.action = (
            <>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpenformhomevisits(item);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  preupdateHomevisitsReview(item);
                  toTop();
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "0.9em", cursor: "pointer" }}
                onClick={() => {
                  setHomevisitsTobeDeleted(item._id);
                  setHomevisitsConfirmDeleteAlerts(true);
                }}></i>
            </>
          );
        });

        setHomevisitsTableData(result);
      });
  }

  const customStyles = {
    lineHeight: "1.8",
  };
  const reset = () => {
    formRef.current.reset();
    // handleTableData();
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      // {
      //     label: "Name",
      //     field: "name",
      //     sort: "asc",
      //     width: 270,
      // },
      {
        label: "Complaints",
        field: "complaints",
        sort: "asc",
        width: 200,
      },

      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "review_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Reg No",
        field: "reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Issues",
        field: "issues_dealt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Recovery Status",
        field: "recovery_status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Communication Forms",
        field: "other_communication",
        sort: "asc",
        width: 100,
      },

      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: counsellortableData,
  };

  const data2 = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "visit_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "reg_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Visit",
        field: "last_visit",
        sort: "asc",
        width: 270,
      },
      {
        label: "Reasons",
        field: "reason",
        sort: "asc",
        width: 200,
      },
      {
        label: "Issues",
        field: "issues_dealt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Response",
        field: "response",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: homevisitstableData,
  };

  const handleSelectChange = async (selected, type) => {
    switch (type) {
      case "admission_id":
        setSelectedPatient(selected);
        setPatientValues(true);

        setmasterObject({
          ...masterObject,
          mobile: data.mobile,
          age: data.age,
          gender: data.gender,
          admitted_date: data.admitted_date,
          counsellor: data.counsellor,
          admission_id: selected.value,
        });
        break;
      case "review_id":
        setSelectedReview(selected);
        if (selected.value == "64535d998316a5f958a49c19") {
          setReviewValue(1);
          // setMedicalReviewValue(true)
          // setCounselorValue(false)
          // setHomevisitsValue(false)
        }

        if (selected.value == "64535dca8316a5f958a49c1a") {
          setReviewValue(2);
          // setCounselorValue(true)
          // setMedicalReviewValue(false)
          // setHomevisitsValue(false)
        }

        if (selected.value == "64535e048316a5f958a49c1b") {
          setReviewValue(3);
          // setHomevisitsValue(true)
          // setCounselorValue(false)
          // setMedicalReviewValue(false)
        }

      default:
        break;
    }
  };

  function handleChangeSelectItem(selected, index) {
    const list = [...values];
    list[index]["medicine"] = selected.value;
    list[index]["medicine_name"] = selected.label;
    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
    setSelectedMedicine(selected);
  }

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
  };

  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };

  function handleTableInputchange(e, index) {
    let { name, value } = e.target;
    const list = [...values];
    list[index][name] = e.target.value;

    if (name == "days") {
      let medication_date = masterObject?.medication_date;
      let endDate = moment(medication_date).add(e.target.value - 1, "days");
      let end_date = moment(endDate).format("YYYY-MM-DD");
      list[index]["end_date"] = end_date;
    }
    setValues(list);
    setmasterObject({ ...masterObject, medicine_with_dosage: list });
  }

  const handleValidSubmit = (e) => {
    if (reviewValue == 1) {
      if (medicationIdTobeUpdated) {
        axios
          .put(`${API_URL}follow-up/medical_review`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            toastr.success("Medication updated successfully");
            formRef.current.reset();
            setValues([{}]);
            setmasterObject({});
            setSelectedPatient(null);
            handleTableData(props);
          })
          .catch((err) => {
            return;
          });
      } else {
        axios
          .post(`${API_URL}follow-up/medical_review`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            toastr.success("Medical Review added successfully");

            formRef.current.reset();

            setValues([{}]);
            setmasterObject({});
            setSelectedPatient(null);
            handleTableData(props);
          })
          .catch((err) => {
            return;
          });
      }
    }
    if (reviewValue == 2) {
      if (counsellorIdTobeUpdated) {
        axios
          .put(`${API_URL}follow-up/counsellor_review`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            toastr.success("Counselor updated successfully");
            formRef.current.reset();

            setmasterObject({});
            setSelectedPatient(null);
            counsellorTableData(props);
          })
          .catch((err) => {
            return;
          });
      } else {
        axios
          .post(`${API_URL}follow-up/counsellor_review`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            toastr.success("Counselor Review added successfully");

            formRef.current.reset();

            // setValues([{}]);
            setmasterObject({});
            setSelectedPatient(null);
            counsellorTableData(props);
          })
          .catch((err) => {
            return;
          });
      }
    }
    if (reviewValue == 3) {
      if (homevisitsIdTobeUpdated) {
        axios
          .put(`${API_URL}follow-up/home_visits`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            toastr.success("Followup updated successfully");
            formRef.current.reset();

            setmasterObject({});
            setSelectedPatient(null);
            homevisitsTableData(props);
          })
          .catch((err) => {
            return;
          });
      } else {
        axios
          .post(`${API_URL}follow-up/home_visits`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            toastr.success("Followup added successfully");

            formRef.current.reset();

            // setValues([{}]);
            setmasterObject({});
            setSelectedPatient(null);
            homevisitsTableData(props);
          })
          .catch((err) => {
            return;
          });
      }
    }
  };

  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}follow-up/medical_review?m_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        item.date = moment(result?.medication_date).format("DD-MM-YYYY");
        item.reg_no = result?.admission_id?.reg_no;
        item.firstName = result?.addedBy?.firstName || result?.updatedBy?.firstName;
        item.complaints_of_patient = result?.complaints_of_patient;
        item.medicine = result?.medicine;
        // setMedicineData(result?.medicine)

        setPopupData(item);
        setPopupView(true);
      });
  };
  const handleClickOpenform = (item) => {
    axios
      .get(`${API_URL}follow-up/counsellor_review?cr_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        item.date = moment(result?.reviewdate).format("DD-MM-YYYY");
        item.reg_no = result?.admission_id?.reg_no;
        item.firstName = result?.addedBy?.firstName || result?.updatedBy?.firstName;
        // item.reviewdate = result?.reviewdate
        item.issues_dealt = result?.issues_dealt;
        item.recovery_status = result?.recovery_status;
        item.other_communication = result?.other_communication;

        // setMedicineData(result?.medicine)

        setCounselorPopupData(item);
        setCounselorPopupView(true);
      });
  };

  const handleClickOpenformhomevisits = (item) => {
    axios
      .get(`${API_URL}follow-up/home_visits?homevisit_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        item.visit_date = moment(result?.visit_date).format("DD-MM-YYYY");
        item.last_visit = moment(result?.last_visit).format("DD-MM-YYYY");
        item.reg_no = result?.admission_id?.reg_no;
        item.firstName = result?.addedBy?.firstName || result?.updatedBy?.firstName;
        // item.reviewdate = result?.reviewdate
        item.reason = result?.reason;
        item.issues_dealt = result?.issues_dealt;
        item.response = result?.response;

        // setMedicineData(result?.medicine)

        setHomevisitsPopupData(item);
        setHomevisitsPopupView(true);
      });
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preupdateMedication = (item) => {
    setMedicationTobeUpdated(item?._id);
    axios
      .get(`${API_URL}follow-up/medical_review?m_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let items = {};
        items._id = item?._id;
        items.admission_id = result?.admission_id?._id;
        items.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        // items.date = moment(result?.medication_date).format("DD-MM-YYYY")
        items.reg_no = result?.admission_id?.reg_no;
        items.firstName = result.added_By?.firstName;
        items.complaints_of_patient = result?.complaints_of_patient;
        items.review_date = result?.review_date;

        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };
        if (result?.medicine.length > 0) {
          let tableData = [];
          result?.medicine &&
            result?.medicine.map((el, index) => {
              let val = {};
              val.frq_morning = el?.frq_morning;
              val.frq_noon = el?.frq_noon;
              val.frq_night = el?.frq_night;
              val.days = el?.days;
              val.remarks = el?.remarks;
              val.medicine = el?.medicine._id;
              // val.medicine_name = el?.medicine?.name + " " + el?.medicine?.dose;
              if (el?.medicine?.dose !== "") {
                val.medicine_name = el?.medicine?.name + " " + el?.medicine?.dose;
              }
              val.medicine_name = el?.medicine?.name;
              tableData.push(val);
            });
          items.medicine = result?.medicine;
          setValues(tableData);
        }
        setSelectedPatient(patient);
        setmasterObject(items);

        // setMedicineData(result?.medicine)

        // setPopupData(item)
        // setPopupView(true);
      });
  };

  //preupdateCounsellorReview

  const preupdateCounsellorReview = (item) => {
    setCounsellorTobeUpdated(item?._id);
    axios
      .get(`${API_URL}follow-up/counsellor_review?cr_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let items = {};
        items.cr_id = item?._id;
        items.admission_id = result?.admission_id?._id;
        items.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        items.review_date = result?.review_date;
        items.reg_no = result?.admission_id?.reg_no;
        items.firstName = result.added_By?.firstName;
        items.complaints_of_patient = result?.complaints_of_patient;
        // items.medication_date = result?.medication_date;
        items.issues_dealt = result?.issues_dealt;
        items.recovery_status = result?.recovery_status;
        items.other_communication = result?.other_communication;
        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };

        setSelectedPatient(patient);
        setmasterObject(items);

        // setMedicineData(result?.medicine)

        // setPopupData(item)
        // setPopupView(true);
      });
  };

  const preupdateHomevisitsReview = (item) => {
    setHomevisitsTobeUpdated(item?._id);
    axios
      .get(`${API_URL}follow-up/home_visits?homevisit_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let items = {};
        items.homevisit_id = item?._id;
        items.admission_id = result?.admission_id?._id;
        items.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        items.visit_date = result?.visit_date;
        items.reg_no = result?.admission_id?.reg_no;
        items.firstName = result.added_By?.firstName;
        items.last_visit = result?.last_visit;
        items.reason = result?.reason;
        items.issues_dealt = result?.issues_dealt;
        items.response = result?.response;
        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };

        setSelectedPatient(patient);
        setmasterObject(items);

        // setMedicineData(result?.medicine)

        // setPopupData(item)
        // setPopupView(true);
      });
  };

  return (
    <TabPane tabId={1}>
      <React.Fragment>
        {reviewValue == 1 ? (
          <>
            {confirmDeleteAlert ? (
              <SweetAlert
                title=""
                showCancel
                confirmButtonText="Delete"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  axios
                    .delete(`${API_URL}follow-up/medical_review?_id=` + medicationIdTobeDeleted, {
                      headers: {
                        "x-access-token": accessToken,
                      },
                    })
                    .then((res) => {
                      if (res.data.status === 200) {
                        toastr.success("Medical Review deleted successfully");
                        if (masterObject && masterObject._id === medicationIdTobeDeleted) {
                          formRef.current.reset();
                          setmasterObject({});
                          // setMedicationIdTobeUpdated(null);
                        }
                        setMedicationTobeDeleted(null);
                        handleTableData(props);
                      } else {
                        toastr.error(res.data.message, "Failed to delete Medical Review");
                        return;
                      }
                    })
                    .catch((err) => {
                      toastr.error(err.response.data.message);
                      return;
                    });
                  setConfirmDeleteAlert(false);
                }}
                onCancel={() => setConfirmDeleteAlert(false)}>
                Are you sure you want to delete it?
              </SweetAlert>
            ) : null}
          </>
        ) : null}
        {reviewValue == 2 ? (
          <>
            {confirmDeleteAlerts ? (
              <SweetAlert
                title=""
                showCancel
                confirmButtonText="Delete"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  axios
                    .delete(`${API_URL}follow-up/counsellor_review?cr_id=` + counsellorIdTobeDeleted, {
                      headers: {
                        "x-access-token": accessToken,
                      },
                    })
                    .then((res) => {
                      if (res.data.status === 200) {
                        toastr.success("Counselor Review deleted successfully");
                        if (masterObject && masterObject.medication_id === counsellorIdTobeDeleted) {
                          formRef.current.reset();
                          setmasterObject({});
                          // setMedicationIdTobeUpdated(null);
                        }
                        setMedicationTobeDeleted(null);
                        counsellorTableData(props);
                      } else {
                        toastr.error(res.data.message, "Failed to delete Counselor Review");
                        return;
                      }
                    })
                    .catch((err) => {
                      toastr.error(err.response.data.message);
                      return;
                    });
                  setConfirmDeleteAlerts(false);
                }}
                onCancel={() => setConfirmDeleteAlerts(false)}>
                Are you sure you want to delete it?
              </SweetAlert>
            ) : null}
          </>
        ) : null}
        {reviewValue == 3 ? (
          <>
            {homevisitsconfirmDeleteAlerts ? (
              <SweetAlert
                title=""
                showCancel
                confirmButtonText="Delete"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  axios
                    .delete(`${API_URL}follow-up/home_visits?homevisit_id=` + homevisitsIdTobeDeleted, {
                      headers: {
                        "x-access-token": accessToken,
                      },
                    })
                    .then((res) => {
                      if (res.data.status === 200) {
                        toastr.success("Followup deleted successfully");
                        if (masterObject && masterObject.homevisit_id === homevisitsIdTobeDeleted) {
                          formRef.current.reset();
                          setmasterObject({});
                          // setMedicationIdTobeUpdated(null);
                        }
                        setHomevisitsTobeDeleted(null);
                        homevisitsTableData(props);
                      } else {
                        toastr.error(res.data.message, "Failed to Followup Review");
                        return;
                      }
                    })
                    .catch((err) => {
                      toastr.error(err.response.data.message);
                      return;
                    });
                  setHomevisitsConfirmDeleteAlerts(false);
                }}
                onCancel={() => setHomevisitsConfirmDeleteAlerts(false)}>
                Are you sure you want to delete it?
              </SweetAlert>
            ) : null}
          </>
        ) : null}
        {reviewValue == 1 ? (
          <>
            <Modal show={popupView} size="lg" centered={true}>
              <div className="modal-header">
                <h5 className="modal-title mt-0">Medical Review Details </h5>
                <button
                  type="button"
                  onClick={() => {
                    setPopupView(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
                <div className="modal-body">
                  <table id="product_available_day" className="table table-bordered dataTable">
                    <tr>
                      <td style={{ width: "184px", padding: "10px" }}>Name :</td>
                      <td>{popupData?.name}</td>
                      <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                      <td style={{ textAlign: "left" }}>{popupData?.reg_no}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px" }}>Date :</td>
                      <td>{popupData?.date}</td>
                      <td style={{ paddingLeft: "25px" }}>Staff :</td>
                      <td style={{ textAlign: "left" }}>{popupData?.firstName}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px" }}>Complaints :</td>
                      <td>{popupData?.complaints_of_patient}</td>
                      <td style={{ padding: "10px" }}></td>
                      <td></td>
                    </tr>
                  </table>
                  <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                    <TableHead>
                      <tr style={{ textAlign: "center" }}>
                        <th
                          style={{
                            width: "5px",
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "12.819px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            color: "#495057",
                          }}
                          rowSpan={2}>
                          No.
                        </th>
                        <th
                          style={{
                            width: "150px",
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "12.819px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            color: "#495057",
                          }}
                          rowSpan={2}>
                          Medicine
                        </th>
                        <th
                          style={{
                            width: "100px",
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "12.819px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            color: "#495057",
                          }}
                          colSpan={3}>
                          Frequency
                        </th>

                        <th
                          style={{
                            width: "100px",
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "12.819px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            color: "#495057",
                          }}
                          rowSpan={2}>
                          Days
                        </th>

                        <th
                          style={{
                            width: "200px",
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "12.819px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            color: "#495057",
                          }}
                          rowSpan={2}>
                          Remarks
                        </th>
                      </tr>
                      <tr>
                        <th style={{ width: "10px" }}>Morning</th>
                        <th style={{ width: "10px" }}>Noon</th>
                        <th style={{ width: "10px" }}>Night</th>
                      </tr>
                    </TableHead>
                    <TableBody>
                      {popupData?.medicine &&
                        popupData?.medicine.map((item, index) => {
                          if (!item?.medicine?.dose) {
                            item.medicine.dose = "";
                          }
                          return (
                            <tr>
                              <td
                                style={{
                                  // width: "180px",
                                  textAlign: "center",
                                  fontWeight: "200",
                                  fontSize: "12.819px",
                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                  color: "#495057",
                                }}>
                                {index + 1}
                              </td>
                              <td
                                component="th"
                                scope="row"
                                style={{
                                  textAlign: "left",
                                  fontSize: "12.819px",
                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                  color: "#495057",
                                  fontWeight: "200",
                                }}>
                                {item?.medicine?.name + " " + item?.medicine?.dose}
                              </td>

                              <td>{item?.frq_morning}</td>
                              <td>{item?.frq_noon}</td>
                              <td>{item?.frq_night}</td>
                              <td>{item?.days}</td>
                              <td>{item?.remarks}</td>
                            </tr>
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
              </AvForm>
            </Modal>
          </>
        ) : null}
        {reviewValue == 2 ? (
          <>
            <Modal show={counselorpopupView} size="lg" centered={true}>
              <div className="modal-header">
                <h5 className="modal-title mt-0">Counselor Review Details </h5>
                <button
                  type="button"
                  onClick={() => {
                    setCounselorPopupView(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
                <div className="modal-body">
                  <table id="product_available_day" className="table table-bordered dataTable">
                    <tr>
                      <td style={{ width: "184px", padding: "10px" }}>Name :</td>
                      <td>{counselorpopupData?.name}</td>
                      <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                      <td style={{ textAlign: "left" }}>{counselorpopupData?.reg_no}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px" }}>Date :</td>
                      <td>{counselorpopupData?.date}</td>
                      <td style={{ paddingLeft: "25px" }}>Staff :</td>
                      <td style={{ textAlign: "left" }}>{counselorpopupData?.firstName}</td>
                    </tr>
                    <tr colSpan={4}>
                      <td style={{ padding: "10px" }}>Issues dealt with :</td>
                      <td>{counselorpopupData?.issues_dealt}</td>
                      <td style={{ padding: "10px" }}></td>
                      <td></td>
                    </tr>
                    <tr colSpan={4}>
                      <td style={{ padding: "10px" }}>Recovery Status :</td>
                      <td>{counselorpopupData?.recovery_status}</td>
                      <td style={{ padding: "10px" }}></td>
                      <td></td>
                    </tr>
                    <tr colSpan={4}>
                      <td style={{ padding: "10px" }}>Communication Forms :</td>
                      <td>{counselorpopupData?.other_communication}</td>
                      <td style={{ padding: "10px" }}></td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              </AvForm>
            </Modal>
          </>
        ) : null}
        {reviewValue == 3 ? (
          <>
            <Modal show={homevisitspopupView} size="lg" centered={true}>
              <div className="modal-header">
                <h5 className="modal-title mt-0">Home Visits Details </h5>
                <button
                  type="button"
                  onClick={() => {
                    setHomevisitsPopupView(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
                <div className="modal-body">
                  <table id="product_available_day" className="table table-bordered dataTable">
                    <tr>
                      <td style={{ width: "184px", padding: "10px" }}>Name :</td>
                      <td>{homevisitsData?.name}</td>
                      <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                      <td style={{ textAlign: "left" }}>{homevisitsData?.reg_no}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px" }}>Visit Date :</td>
                      <td>{homevisitsData?.visit_date}</td>
                      <td style={{ paddingLeft: "25px" }}>Staff :</td>
                      <td style={{ textAlign: "left" }}>{homevisitsData?.firstName}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px" }}>Last Visit Date :</td>
                      <td>{homevisitsData?.last_visit}</td>
                      <td style={{ paddingLeft: "25px" }}></td>
                      <td style={{ textAlign: "left" }}></td>
                    </tr>
                    <tr colSpan={4}>
                      <td style={{ padding: "10px" }}>Reasons :</td>
                      <td>{homevisitsData?.reason}</td>
                      <td style={{ padding: "10px" }}></td>
                      <td></td>
                    </tr>
                    <tr colSpan={4}>
                      <td style={{ padding: "10px" }}>Issues :</td>
                      <td>{homevisitsData?.issues_dealt}</td>
                      <td style={{ padding: "10px" }}></td>
                      <td></td>
                    </tr>
                    <tr colSpan={4}>
                      <td style={{ padding: "10px" }}>Response :</td>
                      <td>{homevisitsData?.response}</td>
                      <td style={{ padding: "10px" }}></td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              </AvForm>
            </Modal>
          </>
        ) : null}

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  ref={formRef}
                  className="needs-validation"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Review Type</Label>
                        <Select
                          isDisabled={!editable}
                          name="review_id"
                          value={selectedReview}
                          onChange={(selected) => {
                            handleSelectChange(selected, "review_id");
                          }}
                          options={reviewOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    {reviewValue == 1 ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Date</Label>
                            <AvField
                              disabled={!editable}
                              name="review_date"
                              placeholder="Date"
                              type="date"
                              className="form-control"
                              onChange={handleChangeInput}
                              value={masterObject?.review_date}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Complaints</Label>
                            <AvField
                              disabled={!editable}
                              name="complaints_of_patient"
                              placeholder="Complaints"
                              type="textarea"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              value={masterObject?.complaints_of_patient}
                              onChange={handleChangeInput}
                              rows={1}
                            />
                          </div>
                        </Col>

                        <div className="mt-0">
                          <h6>Medicine Details</h6>
                        </div>

                        <Row>
                          <Col xl="12">
                            <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                              <TableHead>
                                <TableRow style={{ textAlign: "center" }}>
                                  <TableCell
                                    style={{
                                      width: "0px",
                                      textAlign: "center",
                                      fontWeight: "normal",
                                      paddingLeft: "0rem",
                                      paddingRight: "0rem",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    No.
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      // width: "230px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Medicine Name
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "130px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Frequency(Morning/Noon/Night)
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "90px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Days
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      //width: "250px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Remarks
                                  </TableCell>
                                  {values.length !== 1 ? (
                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "500",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontSize: "12.819px",
                                        fontFamily: "IBM Plex Sans, sans-serif",
                                        color: "#495057",
                                      }}>
                                      Action
                                    </TableCell>
                                  ) : null}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values &&
                                  values.map((item, index) => (
                                    <TableRow>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          textAlign: "center",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                          fontWeight: "500",
                                        }}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell>
                                        {/* <AvField
                                                                            name="name"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Medicine Name"
                                                                            id="validationCustom05"
                                                                            onChange={(e) => handleTableInputchange(e, index)}
                                                                            value={item.name}
                                                                        /> */}

                                        <Select
                                          isDisabled={!editable}
                                          name="medicine_name"
                                          //value={selectedMedicine}
                                          value={{
                                            label: item.medicine_name,
                                            value: item.medicine,
                                          }}
                                          onChange={(selected) => {
                                            handleChangeSelectItem(selected, index);
                                          }}
                                          options={medicineOptions}
                                          classNamePrefix="select2-selection"
                                        />
                                      </TableCell>
                                      <TableCell style={{ display: "flex", gap: "10px" }}>
                                        <AvField
                                          disabled={!editable}
                                          name="frq_morning"
                                          type="Number"
                                          className="form-control"
                                          //placeholder="Morning"
                                          id="validationCustom05"
                                          onChange={(e) => handleTableInputchange(e, index)}
                                          value={item?.frq_morning || ""}
                                        />
                                        <AvField
                                          disabled={!editable}
                                          name="frq_noon"
                                          type="Number"
                                          className="form-control"
                                          // placeholder="Noon"
                                          id="validationCustom05"
                                          onChange={(e) => handleTableInputchange(e, index)}
                                          value={item?.frq_noon || ""}
                                        />
                                        <AvField
                                          disabled={!editable}
                                          name="frq_night"
                                          type="Number"
                                          className="form-control"
                                          // placeholder="Night"
                                          id="validationCustom05"
                                          onChange={(e) => handleTableInputchange(e, index)}
                                          value={item?.frq_night || ""}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <AvField
                                          disabled={!editable}
                                          name="days"
                                          placeholder="Days"
                                          type="Number"
                                          className="form-control"
                                          id="validationCustom05"
                                          onChange={(e) => handleTableInputchange(e, index)}
                                          value={item?.days || ""}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <AvField
                                          disabled={!editable}
                                          name="remarks"
                                          placeholder="Remarks"
                                          type="text"
                                          className="form-control"
                                          id="validationCustom05"
                                          onChange={(e) => handleTableInputchange(e, index)}
                                          value={item?.remarks || ""}
                                        />
                                      </TableCell>

                                      <TableCell>
                                        {editable && (
                                          <>
                                            {values.length !== 1 && (
                                              <i
                                                className="fas fa-trash"
                                                style={{
                                                  cursor: "pointer",
                                                  justifyContent: "center",
                                                }}
                                                onClick={() => handleRemove(index)}></i>
                                            )}
                                          </>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </Col>
                        </Row>
                        {editable && (
                          <span
                            onClick={() => addMore()}
                            style={{
                              width: "190px",
                              cursor: "pointer",
                              fontSize: "12.819px",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              color: "#495057",
                              fontWeight: "500",
                            }}>
                            {" "}
                            + Add More{" "}
                          </span>
                        )}
                      </>
                    ) : null}
                    {/* </Row>

                                        <Row> */}
                    {reviewValue == 2 ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Date</Label>
                            <AvField
                              disabled={!editable}
                              name="reviewdate"
                              placeholder="Date"
                              type="date"
                              className="form-control"
                              onChange={handleChangeInput}
                              value={masterObject?.review_date}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Issues</Label>
                            <AvField
                              disabled={!editable}
                              name="issues_dealt"
                              placeholder="Issues Dealt in Counselling"
                              type="textarea"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              value={masterObject?.issues_dealt}
                              onChange={handleChangeInput}
                              rows={1}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Recovery Status</Label>
                            <AvField
                              disabled={!editable}
                              name="recovery_status"
                              placeholder="Recovery Status"
                              type="text"
                              className="form-control"
                              value={masterObject?.recovery_status}
                              onChange={handleChangeInput}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Other Forms Of Communication</Label>
                            <AvField
                              disabled={!editable}
                              name="other_communication"
                              placeholder="Other Communication forms"
                              type="text"
                              className="form-control"
                              value={masterObject?.other_communication}
                              onChange={handleChangeInput}
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    {/* </Row>
                                        <Row> */}
                    {reviewValue == 3 ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Date</Label>
                            <AvField
                              disabled={!editable}
                              name="visit_date"
                              placeholder="Date"
                              type="date"
                              className="form-control"
                              onChange={handleChangeInput}
                              value={masterObject?.visit_date}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Date of last visit to the centre</Label>
                            <AvField
                              disabled={!editable}
                              name="last_visit"
                              placeholder="Date"
                              type="date"
                              className="form-control"
                              onChange={handleChangeInput}
                              value={masterObject?.last_visit}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Reasons For Making Home Visits</Label>
                            <AvField
                              disabled={!editable}
                              name="reason"
                              placeholder="Reasons for home visits"
                              type="textarea"
                              className="form-control"
                              value={masterObject?.reason}
                              onChange={handleChangeInput}
                              rows={1}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Issues Dealt During Visits</Label>
                            <AvField
                              disabled={!editable}
                              name="issues_dealt"
                              placeholder="Issues Dealt During Visits"
                              type="textarea"
                              className="form-control"
                              value={masterObject?.issues_dealt}
                              onChange={handleChangeInput}
                              rows={1}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Response to the home visits</Label>
                            <AvField
                              disabled={!editable}
                              name="response"
                              placeholder="Response to the home visits"
                              type="textarea"
                              className="form-control"
                              value={masterObject?.response}
                              onChange={handleChangeInput}
                              rows={1}
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                  <Row className="mt-1">
                    <Col md="12" className="mt-1">
                      <FormGroup className="float-right ">
                        {reviewValue == 1 ? (
                          <>
                            {editable && (
                              <>
                                {medicationIdTobeUpdated ? (
                                  <Button color="primary" type="submit">
                                    {"Update"}
                                  </Button>
                                ) : (
                                  <Button color="primary" type="submit">
                                    {"Submit"}
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        ) : null}
                        {reviewValue == 2 ? (
                          <>
                            {editable && (
                              <>
                                {counsellorIdTobeUpdated ? (
                                  <Button color="primary" type="submit">
                                    {"Update"}
                                  </Button>
                                ) : (
                                  <Button color="primary" type="submit">
                                    {"Submit"}
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        ) : null}
                        {reviewValue == 3 ? (
                          <>
                            {editable && (
                              <>
                                {homevisitsIdTobeUpdated ? (
                                  <Button color="primary" type="submit">
                                    {"Update"}
                                  </Button>
                                ) : (
                                  <Button color="primary" type="submit">
                                    {"Submit"}
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    {reviewValue == 1 ? (
                      <MDBDataTable
                        id="medicalreviewTableID"
                        responsive
                        bordered
                        data={data}
                        info={true}
                        searching={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    ) : null}
                    {reviewValue == 2 ? (
                      <MDBDataTable
                        id="medicationTableID"
                        responsiv
                        bordered
                        data={data1}
                        info={true}
                        searching={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    ) : null}
                    {reviewValue == 3 ? (
                      <MDBDataTable
                        id="medicationTableID"
                        responsiv
                        bordered
                        data={data2}
                        info={true}
                        searching={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    ) : null}
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </TabPane>
  );
};

export default Followup;
