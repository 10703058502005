import React, { useState,   } from "react";

import Select from 'react-select';

import {
    Row,
    Col,
    Card,
    CardBody,

    Label,

} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";



function Medicalform() {


    const [values] = useState([{

    }]);
    return (

        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Medical-Form" />


                    <AvForm className="needs-validation">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        <div className="mt-1">
                                            <Label>Patient</Label>

                                            <Select
                                                name="patient-name" />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Row className="mt-4">
                                    <Col xl="12">
                                        <Table
                                            style={{ textAlign: "center" }}
                                            className="table table-bordered dataTable"
                                        >
                                            <TableHead className="table table-bordered dataTable">
                                                <TableRow style={{ textAlign: "center" }}>
                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                             
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                        }}
                                                    >
                                                        #
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                                       
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                            fontFamily: "'Mulish', sans-serif",
                                                        }}
                                                    >
                                                        Caregory
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                                    
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                            fontFamily: "'Mulish', sans-serif",
                                                        }}
                                                    >
                                                        Drugs
                                                    </TableCell>

                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                                   
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                            fontFamily: "'Mulish', sans-serif",
                                                        }}
                                                    >
                                                        Age of first use
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                                                   
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                            fontFamily: "'Mulish', sans-serif",
                                                        }}
                                                    >
                                                        year of use
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                                                            
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                            fontFamily: "'Mulish', sans-serif",
                                                        }}
                                                    >
                                                        Type Of drug
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                                                            
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                            fontFamily: "'Mulish', sans-serif",
                                                        }}
                                                    >
                                                        Route of Administration
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                                                            
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                            fontFamily: "'Mulish', sans-serif",
                                                        }}
                                                    >
                                                        Frequency Of Use In The last 30 Days
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                                                            
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                            fontFamily: "'Mulish', sans-serif",
                                                        }}
                                                    >
                                                        Quantity used in the last 30 days
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "0px",
                                                            textAlign: "center",
                                                            fontWeight: "normal",
                                                            paddingLeft: "0rem",
                                                            paddingRight: "0rem",
                                                            
                                                            fontSize: "0.875rem",
                                                            color: "#282828",
                                                            fontFamily: "'Mulish', sans-serif",
                                                        }}
                                                    >
                                                        past use if any
                                                    </TableCell>
                                                    {values.length !== 1 ? (
                                                        <TableCell
                                                            style={{
                                                                width: "0px",
                                                                textAlign: "center",
                                                                fontWeight: "normal",
                                                                paddingLeft: "0rem",
                                                                paddingRight: "0rem",
                                                                
                                                                fontSize: "0.875rem",
                                                                color: "#282828",
                                                                fontFamily: "'Mulish', sans-serif",
                                                            }}
                                                        >
                                                            Action
                                                        </TableCell>
                                                    ) : null}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {values &&
                                                    values.map((item, index) => (

                                                        <TableRow>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Select
                                                                    name="item_type"
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Select
                                                                    name="item_category"

                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <AvField
                                                                    name="description"
                                                                    type="text"

                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <AvField
                                                                    name="unit"
                                                                    type="text"
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <AvField
                                                                    name="quantity"
                                                                    type="number"
                                                                    onWheel={(e) => e.target.blur()}


                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <AvField
                                                                    name="rate"
                                                                    type="number"
                                                                    onWheel={(e) => e.target.blur()}

                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <AvField
                                                                    name="amount"
                                                                    type="number"
                                                                    onWheel={(e) => e.target.blur()}

                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <AvField
                                                                    name="amount"
                                                                    type="number"
                                                                    onWheel={(e) => e.target.blur()}

                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <AvField
                                                                    name="amount"
                                                                    type="number"
                                                                    onWheel={(e) => e.target.blur()}

                                                                />
                                                            </TableCell>
                                                            {values.length !== 1 && (
                                                                <TableCell>
                                                                    <i
                                                                        className="uil uil-times"
                                                                        style={{
                                                                            fontSize: "20px",
                                                                            cursor: "pointer",
                                                                            paddingLeft: "5px",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    ></i>
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </Col>
                                </Row>
                                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        //   onClick={() => addMore()}
                                        className="addspan"
                                    >
                                        + Add New
                                    </span>
                                </div>
                                <br />
                            </CardBody>
                        </Card>


                    </AvForm>


                </div>
            </div>




        </React.Fragment>
    )
}

export default Medicalform