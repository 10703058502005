/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Col, FormGroup, Label, Row, TabPane } from "reactstrap";
import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function ChildhoodDetails(props) {
  let { details = {}, editable } = props;

  let initialState = {
    childhood_history: {
      chldhd_des: null,
      stn_exp_bf_15: {},
      bhvr_prblm_bfr_15: {},
    },
  };

  const [mstrObject, setMstrObject] = useState(initialState);
  
  const [stnExpBf, setStnExpBf] = useState({
    // pvrty_dbts: false,
    // erly_prnt_ls: false,
    // ext_mrtl_afrs_of_prnt: false,
    // brkn_hm_sngl_prnt: false,
    // violence: false,
    // sxly_absd_by_otr: false,
    // none: false,
    // stn_other: false,
  });
  const [bhvrPrblmBfr, setBhvrPrblmBfr] = useState({
    // rng_awy_frm_hm: false,
    // frqt_pyscl_fts_vlnc: false,
    // detrcn_of_otr_prpty: false,
    // stealing: false,
    // scltc_bkwrdns: false,
    // exp_wt_drgs: false,
    // gambling: false,
    // bhr_other: false,
  });

  useEffect(() => {
    if (Object.keys(details).length !== 0) {
      let data = { ...mstrObject };
      data.childhood_history = details;
      setMstrObject(data);
      setStnExpBf(details.stn_exp_bf_15);
      setBhvrPrblmBfr(details.bhvr_prblm_bfr_15);
    }
  }, [details]);


  const handleChange = (e) => {
    let { name, value } = e.target;
    let data = { ...mstrObject };
    data.childhood_history[name] = value;
    setMstrObject(data);
  };

  const handleInput = (e, mthd) => {
    if (mthd === 0) {
      let { name, value } = e.target
     
      setStnExpBf((preVal) => {
        return {
          ...preVal,
          [name]: value === "true",
        };
      });
      let data = { ...mstrObject };
      data.childhood_history.stn_exp_bf_15[name] = value;
      setMstrObject(data);
    } else if (mthd === 1) {
      let { name, value } = e.target
      setBhvrPrblmBfr((preVal) => {
        return {
          ...preVal,
          [name]: value === "true",
        };
      });
      let data = { ...mstrObject };
      data.childhood_history.bhvr_prblm_bfr_15[name] = value;
      setMstrObject(data);


    }

  };

  let handleSubmit = (value) => {
    value.admission_id = props.id;
    axios
      .put(`${API_URL}admission/childhood-history`, value, {
        headers: { "x-access-token": accessToken },
      })
      .then((res) => {
        toastr.success(res.data.message);
    
        props.handleToggleBar("increment");
      })
      .catch((err) => {
      });
  };


  return (
    <TabPane tabId={2}>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
          handleSubmit(mstrObject);
        }}
      >
        <Row>
          <h6>1. How would you describe your childhood/teenage years?</h6>
        </Row>
        <Row>
          <Col md="10">
            <div className="mt-2">
              <AvField
                disabled={!editable}
                name="chldhd_des"
                type="textarea"
                value={mstrObject?.childhood_history?.chldhd_des}
                className="form-control ms-3"
                // validate={{ required: { value: true } }}
                onChange={handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="">
          <div className="mt-3 mb-2">
            <h6 style={{ fontWeight: "600" }}>
              2. Did you experience the following before the age of 15 years?
            </h6>
          </div>
        </Row>

        <Row >
          {/* <div className='mt-2'> */}
       
          <Col md="6">
            <p className="ms-3">
              <Label>2.1 Poverty/severe debts</Label>

              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="pvrty_dbts"
                style={{ marginLeft: "20px" }}
                id="pvrty_dbts"
                value={true}
                checked={stnExpBf?.pvrty_dbts === true}
                onClick={(e) => handleInput(e, 0)}
              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="pvrty_dbts"
                id="pvrty_dbts1"
                style={{ marginLeft: "10px" }}
                value={false}
                checked={stnExpBf?.pvrty_dbts === false}
                onClick={(e) => handleInput(e, 0)}

              /> No
            </p>

          </Col>
          <Col md="6">
            <p>
              <Label>2.2 Early Parental loss</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="erly_prnt_ls"
                id="erly_prnt_ls"
                style={{ marginLeft: "20px" }}
                value={true}
                checked={stnExpBf.erly_prnt_ls == true}
                onClick={(e) => handleInput(e, 0)}
              /> Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="erly_prnt_ls"
                id="erly_prnt_ls1"
                style={{ marginLeft: "10px" }}
                value={false}
                checked={stnExpBf.erly_prnt_ls == false}
                onClick={(e) => handleInput(e, 0)}


              /> No
            </p>
          </Col>

          {/* </div> */}
        </Row>


        <Row>
          <Col md="6">
            <p className="ms-3">
              <Label>2.3 Extra marital affairs of parents</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="ext_mrtl_afrs_of_prnt"

                // style={{ marginLeft: "20px" }}
                value={true}
                checked={stnExpBf?.ext_mrtl_afrs_of_prnt === true}
                onClick={(e) => handleInput(e, 0)}

              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="ext_mrtl_afrs_of_prnt"
                // id="pvrty_dbts1"
                //style={{ marginLeft: "10px" }}
                value={false}
                checked={stnExpBf?.ext_mrtl_afrs_of_prnt === false}
                onClick={(e) => handleInput(e, 0)}


              /> No

            </p>
          </Col>
          <Col md="6">
            <p>
              <Label>2.4 Broken home/single parenting</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="brkn_hm_sngl_prnt"

                style={{ marginLeft: "20px" }}
                value={true}
                checked={stnExpBf?.brkn_hm_sngl_prnt === true}
                onClick={(e) => handleInput(e, 0)}

              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="brkn_hm_sngl_prnt"
                // id="pvrty_dbts1"
                style={{ marginLeft: "10px" }}
                value={false}
                checked={stnExpBf?.brkn_hm_sngl_prnt == false}
                onClick={(e) => handleInput(e, 0)}


              /> No

            </p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <p className="ms-3">
              <Label>2.5 Violence</Label>

              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="violence"

                style={{ marginLeft: "20px" }}
                value={true}
                checked={stnExpBf?.violence === true}
                onClick={(e) => handleInput(e, 0)}

              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="violence"
                // id="pvrty_dbts1"
                style={{ marginLeft: "10px" }}
                value={false}
                checked={stnExpBf?.violence == false}
                onClick={(e) => handleInput(e, 0)}

              /> No
            </p>
          </Col>
          <Col md="6">
            <p>
              <Label> 2.6 Sexually abused by others</Label>

              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="sxly_absd_by_otr"

                style={{ marginLeft: "20px" }}
                value={true}
                checked={stnExpBf?.sxly_absd_by_otr === true}
                onClick={(e) => handleInput(e, 0)}

              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="sxly_absd_by_otr"
                // id="pvrty_dbts1"
                style={{ marginLeft: "10px" }}
                value={false}
                checked={stnExpBf?.sxly_absd_by_otr == false}
                onClick={(e) => handleInput(e, 0)}


              /> No

            </p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <p className="ms-3">
              <Label>2.7 None</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="none"

                style={{ marginLeft: "20px" }}
                value={true}
                checked={stnExpBf.none === true}
                onClick={(e) => handleInput(e, 0)}

              /> Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="none"

                style={{ marginLeft: "10px" }}
                value={false}
                checked={stnExpBf.none == false}
                onClick={(e) => handleInput(e, 0)}


              /> No
            </p>
          </Col>
          <Col md="6">
            <p>
              <Label>2.8 Any other</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="stn_other"

                style={{ marginLeft: "20px" }}
                value={true}
                checked={stnExpBf.stn_other == true}
                onClick={(e) => handleInput(e, 0)}

              /> Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="stn_other"

                style={{ marginLeft: "10px" }}
                value={false}
                checked={stnExpBf.stn_other === false}
                onClick={(e) => handleInput(e, 0)}


              /> No

            </p>
          </Col>
        </Row>

        <Row>
          <div className="mt-2 mb-2">
            <h6 style={{ fontWeight: "600" }}>
              3. Did you face any behaviour problems before the age of 15 years?
            </h6>
          </div>
        </Row>
        <Row>
          {/* <div className='mt-2'> */}
          <Col md="6">
            <p className="ms-3">
              <Label>3.1 Running away from home</Label>

              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="rng_awy_frm_hm"
                style={{ marginLeft: "20px" }}
               
                value={true}
                checked={bhvrPrblmBfr?.rng_awy_frm_hm === true}
                onClick={(e) => handleInput(e, 1)}
              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="rng_awy_frm_hm"
               
                style={{ marginLeft: "10px" }}
                value={false}
                checked={bhvrPrblmBfr?.rng_awy_frm_hm === false}
                onClick={(e) => handleInput(e, 1)}

              /> No

              {/* <input
                disabled={!editable}
                type="checkbox"
                className="form-check-input"
                name="rng_awy_frm_hm"
                checked={bhvrPrblmBfr.rng_awy_frm_hm}
                style={{ marginLeft: "10px" }}
                onClick={(e) => handleInput(e, 1)}
              /> */}
            </p>
          </Col>
          <Col md="6">
            <p>
              <Label>3.2 Frequent physical fights</Label>

              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="frqt_pyscl_fts_vlnc"
                style={{ marginLeft: "20px" }}
               
                value={true}
                checked={bhvrPrblmBfr?.frqt_pyscl_fts_vlnc === true}
                onClick={(e) => handleInput(e, 1)}
              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="frqt_pyscl_fts_vlnc"
               
                style={{ marginLeft: "10px" }}
                value={false}
                checked={bhvrPrblmBfr?.frqt_pyscl_fts_vlnc === false}
                onClick={(e) => handleInput(e, 1)}

              /> No

            </p>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <p className="ms-3">
              <Label>3.3 Destruction of others property</Label>

              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="detrcn_of_otr_prpty"
                style={{ marginLeft: "20px" }}
               
                value={true}
                checked={bhvrPrblmBfr?.detrcn_of_otr_prpty === true}
                onClick={(e) => handleInput(e, 1)}
              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="detrcn_of_otr_prpty"
               
                style={{ marginLeft: "10px" }}
                value={false}
                checked={bhvrPrblmBfr?.detrcn_of_otr_prpty === false}
                onClick={(e) => handleInput(e, 1)}

              /> No

              
            </p>
          </Col>
          <Col md="6">
            <p>
              <Label>3.4 Stealing</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="stealing"
                style={{ marginLeft: "20px" }}
               
                value={true}
                checked={bhvrPrblmBfr?.stealing === true}
                onClick={(e) => handleInput(e, 1)}
              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="stealing"
               
                style={{ marginLeft: "10px" }}
                value={false}
                checked={bhvrPrblmBfr?.stealing === false}
                onClick={(e) => handleInput(e, 1)}

              /> No
              {/* <input
                disabled={!editable}
                type="checkbox"
                className="form-check-input"
                name="stealing"
                checked={bhvrPrblmBfr.stealing}
                style={{ marginLeft: "10px" }}
                onClick={(e) => handleInput(e, 1)}
              /> */}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <p className="ms-3">
              <Label>3.5 Scholastic forwardness</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="scltc_bkwrdns"
                style={{ marginLeft: "20px" }}
               
                value={true}
                checked={bhvrPrblmBfr?.scltc_bkwrdns === true}
                onClick={(e) => handleInput(e, 1)}
              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="scltc_bkwrdns"
               
                style={{ marginLeft: "10px" }}
                value={false}
                checked={bhvrPrblmBfr?.scltc_bkwrdns === false}
                onClick={(e) => handleInput(e, 1)}

              /> No
              {/* <input
                disabled={!editable}
                type="checkbox"
                className="form-check-input"
                name="scltc_bkwrdns"
                checked={bhvrPrblmBfr.scltc_bkwrdns}
                style={{ marginLeft: "10px" }}
                onClick={(e) => handleInput(e, 1)}
              /> */}
            </p>
          </Col>
          <Col md="6">
            <p>
              <Label>3.6 Experimenting with drugs/alcohol</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="exp_wt_drgs"
               
               
                value={true}
                checked={bhvrPrblmBfr?.exp_wt_drgs === true}
                onClick={(e) => handleInput(e, 1)}
              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="exp_wt_drgs"
               
             
                value={false}
                checked={bhvrPrblmBfr?.exp_wt_drgs === false}
                onClick={(e) => handleInput(e, 1)}

              /> No
              {/* <input
                disabled={!editable}
                type="checkbox"
                className="form-check-input"
                name="exp_wt_drgs"
                checked={bhvrPrblmBfr.exp_wt_drgs}
                style={{ marginLeft: "10px" }}
                onClick={(e) => handleInput(e, 1)}
              /> */}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <p className="ms-3">
              <Label>3.7 Gambling</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="gambling"
                style={{ marginLeft: "20px" }}
               
                value={true}
                checked={bhvrPrblmBfr?.gambling === true}
                onClick={(e) => handleInput(e, 1)}
              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="gambling"
               
                style={{ marginLeft: "10px" }}
                value={false}
                checked={bhvrPrblmBfr?.gambling === false}
                onClick={(e) => handleInput(e, 1)}

              /> No


              {/* <input
                disabled={!editable}
                type="checkbox"
                name="gambling"
                className="form-check-input"
                checked={bhvrPrblmBfr.gambling}
                style={{ marginLeft: "10px" }}
                onClick={(e) => handleInput(e, 1)}
              /> */}
            </p>
          </Col>
          <Col md="6">
            <p>
              <Label>3.8 Any other</Label>
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="bhr_other"
                style={{ marginLeft: "20px" }}
               
                value={true}
                checked={bhvrPrblmBfr?.bhr_other === true}
                onClick={(e) => handleInput(e, 1)}
              />   Yes{" "}
              &nbsp;
              <input
                disabled={!editable}
                type="radio"
                name="bhr_other"
               
                style={{ marginLeft: "10px" }}
                value={false}
                checked={bhvrPrblmBfr?.bhr_other === false}
                onClick={(e) => handleInput(e, 1)}

              /> No

              {/* <input
                disabled={!editable}
                type="checkbox"
                className="form-check-input"
                name="bhr_other"
                checked={bhvrPrblmBfr.bhr_other}
                style={{ marginLeft: "10px" }}
                onClick={(e) => handleInput(e, 1)}
              /> */}
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} sm={12} className="submit-btn-fixed">
            <FormGroup className="float-right ">
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("decrement")}
              >
                <i class="fas fa-solid fa-less-than"></i>
              </a>
              {editable && (
                <button type="submit" className="save-continue-btn m-2">
                  Save and continue{" "}
                  <i
                    style={{ fontSize: "13px" }}
                    className="mdi mdi-arrow-right"
                  ></i>
                </button>
              )}
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("increment")}
              >
                <i class="fas fa-solid fa-greater-than"></i>
              </a>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </TabPane>
  );
}

export default ChildhoodDetails;
