/* eslint-disable */
import { get, post, put, delQuery } from "../../helpers/api_helper";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

export const getState = () =>
  new Promise(async (resolve, reject) => {
    let response = await get(`${API_URL}enquiry/state-list`);
    let data = response.data;
    data.map((item) => {
      item.value = item._id;
      item.label = item.name;
      delete item._id;
      delete item.name;
      delete item.id;
    });
    resolve(data);
  });

export const getDistrict = (id = null) =>
  new Promise(async (resolve, reject) => {
    if (id) {
      let response = await get(`${API_URL}enquiry/district-list/?id=${id}`);
      let data = response.data;
      data.map((data) => {
        data.value = data._id;
        data.label = data.district_name;
        delete data.district_name;
        delete data._id;
      });
      resolve(data);
    } else {
      resolve([]);
    }
  });

export const onSubmit = async (data) => await post(`${API_URL}staff`, data);

export const getStaffDetails = async () => await get(`${API_URL}staff/list`);

export const updateStaff = async (data) => await put(`${API_URL}staff`, data);

export const deleteStaff = async (id) =>
  await delQuery(`${API_URL}staff?staff_id`, id);
