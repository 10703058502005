/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr, { options } from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./test.scss";
const Test = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [testIdTobeUpdated, setTestIdTobeUpdated] = useState(null);
  const [testIdTobeDeleted, setTestTobeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [tests, setTests] = useState([{ name: "", price: "" }]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preUpdate = (item_data, test_arr = []) => {
    //console.log(item_data, "item_data");
    toTop();
    if (item_data?.category) {
      setMaster({
        test_name: item_data.category,
        price: item_data.price,
        test_id: item_data._id,
      });
      setTestIdTobeUpdated(item_data?._id);
      if (test_arr.length > 0) {
        var tarr = [];
        test_arr.map((row) => {
          tarr.push({ _id: row?._id, name: row?.name, price: row?.price });
        });
        setTests(tarr);
      } else setTests([{ name: "", price: "" }]);
    }
  };
  const handleRemove = (index) => {
    var list = [...tests];
    list.splice(index, 1);
    setTests(list);
  };
  const handleTableData = () => {
    axios
      .get(`${API_URL}test-category/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setDetails(result);
      });
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleTableInputChange = (e, index_no) => {
    let lists = [...tests];
    lists[index_no][e.target.name] = e.target.value;
    if (e.target.value) {
      lists[index_no][e.target.name + "_error"] = "";
    } else {
      lists[index_no][e.target.name + "_error"] = "Enter test " + e.target.name;
    }
    setTests(lists);
    //console.log(details);
    //console.log(master);
  };
  const handleRow = () => {
    let list = [...tests];
    list.push({ name: "" });
    setTests(list);
  };
  const handleReset = () => {
    setTests([{ name: "" }]);
    setMaster(null);
    formRef.current.reset();
    setTestIdTobeUpdated(null);
  };
  const handleValidSubmit = () => {
    let list = [...tests];
    var name_miss1 = list
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => !item.hasOwnProperty("name") || item.name == "")
      .map(({ index }) => index);
    var price_miss1 = list
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => !item.hasOwnProperty("price") || item.price == "")
      .map(({ index }) => index);

    var errors = 0;
    if (name_miss1.length > 0) {
      name_miss1.map((item) => {
        list[item]["name" + "_error"] = "Enter test name";
      });
      errors++;
    }
    // if (price_miss1.length > 0) {
    //   price_miss1.map((item) => {
    //     list[item]["price" + "_error"] = "Enter test price";
    //   });
    //   errors++;
    // }
    setTests(list);
    if (errors > 0) {
      return;
    }
    master.tests = tests;
    if (testIdTobeUpdated) {
      axios
        .put(`${API_URL}test-category/update`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Test updated successfully");
            setMaster({});
            handleTableData();
            setTestIdTobeUpdated(null);
            handleReset();
          } else {
            toastr.error("Failed to update Test");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}test-category/add`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Test added successfully");
            setMaster(null);
            setTests([{ name: "", price: "" }]);
            handleTableData();
            handleReset();
          } else {
            toastr.error("Failed to add Test");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}test-category` + "/" + testIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Test deleted successfully");
                  if (master && master.test_id === testIdTobeDeleted) {
                    formRef.current.reset();
                    handleReset();
                  }
                  setTestTobeDeleted(null);
                  handleTableData();
                  handleReset();
                } else {
                  toastr.error(res.data.message, "Failed to delete Test");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Test" />
          {props.options.edit && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={() => {
                        handleValidSubmit();
                      }}>
                      <Row>
                        <Col md="3">
                          <Row>
                            <Col md="12">
                              <div className="mb-3">
                                <Label htmlFor="validationCustom01">Test Category</Label>
                                <AvField
                                  name="test_name"
                                  placeholder="Test Category"
                                  type="text"
                                  errorMessage="Enter Test Category"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="validationCustom01"
                                  value={master?.test_name}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="3">
                          <Row>
                            <Col md="12">
                              <div className="mb-3">
                                <Label htmlFor="validationCustom01">Test Category Price</Label>
                                <AvField
                                  name="price"
                                  placeholder="Price"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  validate={{ required: { value: true } }}
                                  id="validationCustom01"
                                  value={master?.price || ""}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <h6 className="label-heading">Medical Tests and It's Prices</h6>
                          <table className="table table-bordered" id="testTableID">
                            <tbody>
                              {tests?.map((items, index_no) => {
                                return (
                                  <tr key={index_no}>
                                    <td>{index_no + 1}</td>
                                    <td>
                                      <AvField
                                        name="name"
                                        placeholder="Test"
                                        type="text"
                                        className={items?.name_error ? "form-control cust-error-fiels" : "form-control cust-success-fiels"}
                                        id="validationCustom01"
                                        value={items?.name}
                                        onChange={(e) => {
                                          handleTableInputChange(e, index_no);
                                        }}
                                      />
                                      <div className="invalid-feedback cust-error-div">{items?.name_error}</div>
                                    </td>
                                    <td>
                                      {tests.length > 1 ? (
                                        <i
                                          className="fas fa-trash action-icon"
                                          onClick={() => {
                                            handleRemove(index_no);
                                          }}></i>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </Col>
                        <Row>
                          <Col md="12" className="text-right" style={{ textAlign: "right" }}>
                            <span onClick={handleRow} className="add-new-span">
                              <i className="fas fa-plus font-size-11"></i> New Test
                            </span>
                            {testIdTobeUpdated ? (
                              <Button color="primary" type="submit">
                                {"Update"}
                              </Button>
                            ) : (
                              <Button color="primary" type="submit">
                                {"Submit"}
                              </Button>
                            )}

                            <Button color="danger" type="reset" style={{ marginLeft: "10px" }} onClick={handleReset}>
                              {"Reset"}
                            </Button>
                          </Col>
                        </Row>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <MDBDataTable
                    id="manageTestTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  /> */}
                  <table className="table table-bordered" id="manageTestTable" key="2">
                    <thead>
                      <tr>
                        <th rowSpan="2">#</th>
                        <th rowSpan="2">Category</th>
                        <th colspan="2">Medical Tests and It's Prices</th>
                        <th rowSpan="2">Staff</th>
                        {props.options.edit && <th rowSpan="2">Action</th>}
                      </tr>
                      <tr>
                        <th>Test</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details?.map((item, index) => {
                        if (item?.tests_list?.length > 0) {
                          return (
                            <>
                              {item?.tests_list?.map((item1, index1) => {
                                if (index1 == 0) {
                                  return (
                                    <tr key={index + index1}>
                                      <td rowSpan={item?.tests_list?.length}>{index + 1}</td>
                                      <td rowSpan={item?.tests_list?.length}>{item?.category}</td>
                                      <td>{item1?.name}</td>
                                      <td style={{ textAlign: "right" }}>{item1?.price}</td>
                                      <td rowSpan={item?.tests_list?.length}>{item?.staff}</td>
                                      {props.options.edit && (
                                        <td rowSpan={item?.tests_list?.length}>
                                          <div class="action align-action-icon">
                                            <i
                                              className="fas fa-edit"
                                              onClick={() => {
                                                preUpdate(item, item?.tests_list);
                                              }}></i>
                                            <i
                                              className="fas fa-trash"
                                              onClick={() => {
                                                setConfirmDeleteAlert(true);
                                                setTestTobeDeleted(item?._id);
                                              }}></i>
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                } else {
                                  return (
                                    <tr>
                                      <td>{item1?.name}</td>
                                      <td style={{ textAlign: "right" }}>{item1?.price}</td>
                                    </tr>
                                  );
                                }
                              })}
                            </>
                          );
                        } else {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.category}</td>
                              <td></td>
                              <td style={{ textAlign: "right" }}></td>
                              <td>{item?.staff}</td>
                              {props.options.edit && (
                                <td>
                                  <div class="action align-action-icon">
                                    <i
                                      className="fas fa-edit"
                                      onClick={() => {
                                        preUpdate(item, []);
                                      }}></i>
                                    <i
                                      className="fas fa-trash"
                                      onClick={() => {
                                        setConfirmDeleteAlert(true);
                                        setTestTobeDeleted(item?._id);
                                      }}></i>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Test;
