/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import "./grouptherapy.scss";
import toastr from "toastr";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const GroupTherapy = (props) => {
  const formRef = useRef();
  let { editable } = props;
  //   const [selectedPatient, setSelectedPatient] = useState("");
  //   const [patientOptions, setPatientOptions] = useState([]);
  //   const [counsellingType, setCounsellingType] = useState("");
  const [values, setValues] = useState([{}]);
  //   const [isOpen, setIsOpen] = useState(false);
  //   const [patientvalues, setPatientValues] = useState(false);
  //   const [patientprevvalues, setPatientPrevValues] = useState(false);
  const [masterObject, setmasterObject] = useState({});
  //   const [selectedGender, setSelectedGender] = useState(null);
  const [grouptherapyIdTobeDeleted, setGrouptherapyIdTobeDeleted] = useState(null);
  const [grouptherapyIdTobeUpdated, setGrouptherapyIdTobeUpdated] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [issues, setIssues] = useState([]);
  //   const [selectedValue, setSelectedValue] = useState([{}]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // fetch_all_patients();
    handleTableData(props);
    fetch_previous_grouptherapy_details();
    fetch_all_issues();

    setmasterObject({
      ...masterObject,
      admission_id: props.id,
    });
    // eslint-disable-next-line
  }, [props]);

  const handleRadioChange = (e, index) => {
    var list = [...values];
    list[index] = { issue: "", code: "" };
    list[index].issue = e.target.id;
    list[index].code = e.target.value;
    // setSelectedValue(e.target.value);
    setValues(list);
    setmasterObject({
      ...masterObject,
      details: list,
    });
  };

  //   const fetch_all_patients = () => {
  //     axios
  //       .get(`${API_URL}admission/options/names`, {
  //         headers: {
  //           "x-access-token": accessToken,
  //         },
  //       })
  //       .then((res) => {
  //         let patient_data =
  //           res.data.data &&
  //           res.data.data.map((el) => {
  //             return {
  //               label: el?.fname + " " + el?.lname + "-" + el?.reg_no,
  //               value: el?._id,
  //             };
  //           });
  //         // setPatientOptions([
  //         //   {
  //         //     options: patient_data,
  //         //   },
  //         // ]);
  //       });
  //   };

  const fetch_all_issues = () => {
    axios
      .get(`${API_URL}group-therapy/issues`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let data = res.data.data;

        setIssues(data);
      });
  };
  //   const handleSelectChange = async (selected, type) => {
  //     switch (type) {
  //       case "admission_id":
  //         // setSelectedPatient(selected);
  //         // setPatientValues(true);

  //         //fetch_previous_medication_details(selected.value)
  //         let data = await fetch_patient_details(selected.value);
  //         let previous_data = await fetch_previous_grouptherapy_details(selected.value);
  //         setmasterObject({
  //           ...masterObject,
  //           // mobile: data.mobile,
  //           // age: data.age,
  //           // gender: data.gender,
  //           admission_id: selected.value,
  //           // counsellor: data.counsellor,
  //           // prev_from_date: previous_data.from_date,
  //           // prev_to_date: previous_data.to_date,
  //           // prev_therapist: previous_data.therapist,
  //           // prev_details: previous_data.details,
  //           // previous_data: previous_data
  //         });

  //         break;
  //       case "type":
  //         // setCounsellingType(selected);
  //         setmasterObject({
  //           ...masterObject,
  //           type: selected.value,
  //         });
  //       default:
  //         break;
  //     }
  //   };
  //   const fetch_patient_details = (_id) => {
  //     return axios
  //       .get(`${API_URL}admission?admission_id=` + _id, {
  //         headers: {
  //           "x-access-token": accessToken,
  //         },
  //       })
  //       .then((res) => {
  //         let result = res.data.data;

  //         let data = {};
  //         data.mobile = result?.basic_details?.basic_id?.mobile;
  //         data.age = result?.basic_details?.age;
  //         //data.gender = result?.basic_details?.gender
  //         data.counsellor = result?.counsellor_id?.firstName;
  //         data.admitted_date = moment(result?.date).format("DD-MM-YYYY");
  //         let gender_value = {};

  //         if (result?.basic_details?.gender == 0) {
  //           data.gender = "Female";
  //         }
  //         if (result?.basic_details?.gender == 1) {
  //           data.gender = "Male";
  //         }
  //         if (result?.basic_details?.gender == 2) {
  //           data.gender = "Others";
  //         }

  //         // setSelectedGender(gender_value);
  //         return data;
  //       });
  //   };

  const fetch_previous_grouptherapy_details = (_id) => {
    return axios
      .get(`${API_URL}group-therapy/latest?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        if (result.length > 0) {
          //   setPatientPrevValues(true);
        } else {
          //   setPatientPrevValues(false);
        }

        return result;
      });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const preUpdateGrouptherapy = (item) => {
    setGrouptherapyIdTobeUpdated(item?._id);
    axios
      .get(`${API_URL}group-therapy?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        //item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname
        item._id = result?._id;
        item.admission_id = result?.admission_id?._id;
        item.reg_no = result?.admission_id?.reg_no;
        item.staff = result?.addedBy?.firstName;
        item.from_date = result?.from_date;
        item.to_date = result?.to_date;

        item.group = result?.group;
        item.therapist = result?.therapist;
        item.details = result?.details;
        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };
        setValues(result?.details);

        // setSelectedPatient(patient);

        setmasterObject(item);
      });
  };
  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}group-therapy?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        item.from_date = moment(result?.from_date).format("DD-MM-YYYY");
        item.to_date = moment(result?.to_date).format("DD-MM-YYYY");
        item.reg_no = result?.admission_id?.reg_no;
        item.staff = result?.addedBy?.firstName;

        item.group = result?.group;
        item.therapist = result?.therapist;
        item.details = result?.details;
        // result?.details.map((value,index)=>{
        //     item.issue=

        // })

        setPopupData(item);
        setPopupView(true);
      });
  };

  function handleTableData(props) {
    let admission_id = props.id;

    var url = `${API_URL}group-therapy/list?admission_id=` + admission_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
          item.from_date = moment(item?.from_date).format("DD-MM-YYYY");
          item.to_date = moment(item?.to_date).format("DD-MM-YYYY");
          item.reg_no = item?.admission_id?.reg_no;
          item.firstName = item?.addedBy?.firstName;
          // item.duration = item?.duration;
          item.group = item?.group;
          item.therapist = item?.therapist;

          item.action = (
            <>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  preUpdateGrouptherapy(item);
                  toTop();
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "0.9em", cursor: "pointer" }}
                onClick={() => {
                  setGrouptherapyIdTobeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </>
          );
        });

        setTableData(result);
      });
  }

  const customStyles = {
    lineHeight: "1.8",
  };
  const reset = () => {
    formRef.current.reset();
    // handleTableData();
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },

      {
        label: "From Date",
        field: "from_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "To Date",
        field: "to_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Group",
        field: "group",
        sort: "asc",
        width: 200,
      },
      {
        label: "Therapist",
        field: "therapist",
        sort: "asc",
        width: 200,
      },

      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };

  const handleValidSubmit = (e) => {
    if (grouptherapyIdTobeUpdated) {
      axios
        .put(`${API_URL}group-therapy`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Group Therapy updated successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({
            admission_id: props.id,
          });
          //   setSelectedPatient(null);
          //   setCounsellingType(null);
          //   setPatientValues(false);
          //   setPatientPrevValues(false);
          handleTableData(props);
          setValues([{}]);
        })
        .catch((err) => {
          return;
        });
    } else {
      axios
        .post(`${API_URL}group-therapy`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Group Therapy added successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({
            admission_id: props.id,
          });
          //   setSelectedPatient(null);
          //   setPatientValues(false);
          //   setPatientPrevValues(false);
          handleTableData(props);
        })
        .catch((err) => {
          return;
        });
    }
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}group-therapy?_id=` + grouptherapyIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Group Therapy deleted successfully");
                  if (masterObject && masterObject._id === grouptherapyIdTobeDeleted) {
                    formRef.current.reset();
                    setmasterObject({});
                    setGrouptherapyIdTobeDeleted(null);
                  }
                  setGrouptherapyIdTobeDeleted(null);
                  handleTableData(props);
                } else {
                  toastr.error(res.data.message, "Failed to delete Group Therapy");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Group Therapy Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table id="product_available_day" className="table table-bordered dataTable">
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>Name :</td>
                <td>{popupData?.name}</td>
                <td style={{ paddingLeft: "25px" }}>From Date :</td>
                <td style={{ textAlign: "left" }}>{popupData?.from_date}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>To Date :</td>
                <td>{popupData?.to_date}</td>
                <td style={{ paddingLeft: "25px" }}>Group :</td>
                <td style={{ textAlign: "left" }}>{popupData?.group}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Therapist :</td>
                <td>{popupData?.therapist}</td>
                <td style={{ paddingLeft: "25px" }}>Staff :</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
              </tr>
            </table>
            <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
              <TableHead>
                <TableRow style={{ textAlign: "center" }}>
                  <TableCell
                    style={{
                      width: "5px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    No.
                  </TableCell>
                  <TableCell
                    style={{
                      width: "150px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Issues
                  </TableCell>
                  <TableCell
                    style={{
                      width: "100px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Code
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popupData?.details &&
                  popupData?.details.map((item, index) => (
                    <TableRow>
                      <TableCell
                        style={{
                          // width: "180px",
                          textAlign: "center",
                          fontWeight: "200",
                          fontSize: "12.819px",
                          // fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                        }}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          textAlign: "left",
                          fontSize: "12.819px",
                          //fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                          fontWeight: "200",
                        }}>
                        {item?.issue?.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          textAlign: "center",
                          fontSize: "12.819px",
                          //fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                          fontWeight: "200",
                          display: "flex",
                          gap: "10px",
                        }}>
                        {item?.code}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </AvForm>
      </Modal>

      <Row>
        <Col xl="12">
          <Card>
            <CardBody>
              <AvForm
                ref={formRef}
                className="needs-validation"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}>
                <Row>
                  <Col md="3" className="mt-1">
                    <Label>From Date</Label>
                    <AvField
                      disabled={!editable}
                      name="from_date"
                      placeholder="Date"
                      type="date"
                      className="form-control"
                      //validate={{ required: { value: true } }}
                      onChange={handleChangeInput}
                      max={moment().format("YYYY-MM-DD")}
                      value={masterObject?.from_date}
                    />
                  </Col>
                  <Col md="3" className="mt-1">
                    <Label>To Date</Label>
                    <AvField
                      disabled={!editable}
                      name="to_date"
                      placeholder="Date"
                      type="date"
                      className="form-control"
                      //validate={{ required: { value: true } }}
                      max={moment().format("YYYY-MM-DD")}
                      min={masterObject.from_date}
                      value={masterObject.to_date}
                      onChange={handleChangeInput}
                    />
                  </Col>

                  <Col md="3" className="mt-1">
                    <Label>Therapist</Label>
                    <AvField
                      disabled={!editable}
                      name="therapist"
                      type="text"
                      className="form-control"
                      placeholder="Therapist Name"
                      value={masterObject?.therapist}
                      onChange={handleChangeInput}
                    />
                  </Col>
                  <Col md="3" className="mt-1">
                    <Label>Group</Label>
                    <AvField
                      disabled={!editable}
                      name="group"
                      type="text"
                      className="form-control"
                      placeholder="Group"
                      value={masterObject?.group}
                      onChange={handleChangeInput}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl="7">
                    <div className="mt-3">
                      <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                        <TableHead>
                          <TableRow style={{ textAlign: "center" }}>
                            <TableCell
                              style={{
                                width: "5px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#495057",
                              }}>
                              No.
                            </TableCell>
                            <TableCell
                              style={{
                                width: "150px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#495057",
                              }}>
                              Issues
                            </TableCell>
                            <TableCell
                              style={{
                                width: "100px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#495057",
                              }}>
                              Code
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {issues &&
                            issues.map((item, index) => (
                              <TableRow>
                                <TableCell
                                  style={{
                                    // width: "180px",
                                    textAlign: "center",
                                    fontWeight: "200",
                                    fontSize: "12.819px",
                                    // fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "12.819px",
                                    //fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                    fontWeight: "200",
                                  }}>
                                  {item?.name}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "12.819px",
                                    //fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                    fontWeight: "200",
                                    display: "flex",
                                    gap: "10px",
                                  }}>
                                  <div className="form-check mb-3">
                                    <input
                                      disabled={!editable}
                                      className="form-check-input"
                                      type="radio"
                                      name={`issue1-${index}`}
                                      value="A"
                                      id={`${item._id}`}
                                      onChange={(e) => handleRadioChange(e, index)}
                                      // checked={selectedValue=="A"}
                                      checked={values[index]?.code === "A"}
                                    />
                                    <label className="form-check-label">A</label>
                                  </div>
                                  <div className="form-check mb-3">
                                    <input
                                      disabled={!editable}
                                      className="form-check-input"
                                      type="radio"
                                      name={`issue2-${index}`}
                                      value="B"
                                      id={`${item._id}`}
                                      onChange={(e) => handleRadioChange(e, index)}
                                      //checked={selectedValue === "B"}
                                      checked={values[index]?.code === "B"}
                                    />
                                    <label className="form-check-label">B</label>
                                  </div>
                                  <div className="form-check mb-3">
                                    <input
                                      disabled={!editable}
                                      className="form-check-input"
                                      type="radio"
                                      name={`issue3-${index}`}
                                      value="C"
                                      id={`${item._id}`}
                                      onChange={(e) => handleRadioChange(e, index)}
                                      //checked={selectedValue === "C"}
                                      checked={values[index]?.code === "C"}
                                    />
                                    <label className="form-check-label" htmlFor={`issue3-${index}`}>
                                      C
                                    </label>
                                  </div>
                                  <div className="form-check mb-3">
                                    <input
                                      disabled={!editable}
                                      className="form-check-input"
                                      type="radio"
                                      name={`issue4-${index}`}
                                      value="D"
                                      id={`${item._id}`}
                                      onChange={(e) => handleRadioChange(e, index)}
                                      //checked={selectedValue === "D"}
                                      checked={values[index]?.code === "D"}
                                    />
                                    <label className="form-check-label" htmlFor={`issue4-${index}`}>
                                      D
                                    </label>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </div>
                  </Col>
                </Row>
                <Col md="1" className="mt-1">
                  {editable && (
                    <>
                      {grouptherapyIdTobeUpdated ? (
                        <Button color="primary" type="submit">
                          {"Update"}
                        </Button>
                      ) : (
                        <Button color="primary" type="submit">
                          {"Submit"}
                        </Button>
                      )}
                    </>
                  )}
                </Col>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl="12">
          <Card>
            <CardBody>
              <AvForm>
                <Row>
                  <MDBDataTable
                    id="grouptherapyTableID"
                    responsive
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default GroupTherapy;
