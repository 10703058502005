/* eslint-disable */
import React, { useState, useEffect } from "react";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import Settings from "@mui/icons-material/Settings";

// import MenuItem from "@mui/material/MenuItem";

import Select from "react-select";
import toastr from "toastr";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

import {
  Row,
  Col,
  Card,
  CardBody,
  // CardSubtitle,
  // Button,
  Label,
  CardTitle,
  //CardHeader,
} from "reactstrap";

import { AvForm, } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./rules.scss";
// import { Grid } from "@mui/material";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
// import { fontSize } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const DatatableTables = (props) => {
  console.log("props", props);
  const [details, ] = useState([]);
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);
  const [privilegeOptions, setPrivilegeOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [dataOptions, setDataOptions] = useState([]);
  const [allTabs, setTabs] = useState([]);
  const [reload, setReload] = useState([]);
  const [disabled, setDisabled] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    fetch_option_list();
  }, []);

  const fetch_option_list = () => {
    axios
      .get(`${API_URL}rules/option_list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setOptions(result);
      });
  };

  const handleSelectChange = (selected) => {
    setSelectedPrivilege(selected);

    let userid = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
    }
    data = {
      privilege: selected.value,
    };
    axios
      .post(`${API_URL}rules/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((item, index) => {
            item.sub_menu &&
              item.sub_menu.map((opt) => {
                opt.option = options;
                opt.mainmenu_id = item._id;
              });
          });
        setDataOptions(result);
      });
  };

  const handleAllmenuCheckboxChange = (e, item) => {
    let data = {
      status: e.target.checked === true ? 1 : 0,
      option_name: item._id,
      privilege: selectedPrivilege?.value,
    };
    setDisabled(item._id);
    e.target.disabled = true;
    axios
      .post(`${API_URL}rules/options`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selectedPrivilege);
          setDisabled(null);
          e.target.disabled = false;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        setDisabled(null);
        e.target.disabled = false;
        return;
      });
  };

  const handleOptionCheckboxChange = (e, el, element) => {
    let data = {
      status: e.target.checked === true ? 1 : 0,
      option_name: element._id,
      privilege: selectedPrivilege?.value,
      sub_menu: el?._id,
      main_menu: el?.mainmenu_id,
    };
    axios
      .post(`${API_URL}rules/menu_option`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selectedPrivilege);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  const handleMainmenuCheckboxChange = (e, item) => {
    let data = {
      mainmenu_id: item._id,
      privilege: selectedPrivilege.value,
      status: e.target.checked === true ? 1 : 0,
    };
    axios
      .post(`${API_URL}rules/menu`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selectedPrivilege);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  const handleCheckboxChange = (e, element, item) => {
    let data = {
      mainmenu_id: item._id,
      privilege: selectedPrivilege.value,
      submenu_id: element._id,
      status: e.target.checked === true ? 1 : 0,
    };
    axios
      .post(`${API_URL}rules/add`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selectedPrivilege);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  const handleOptionCheckboxChanges = (e, element, item) => {
    let data = {
      status: e.target.checked === true ? 1 : 0,
      option_name: element._id,
      privilege: selectedPrivilege?.value,
      //sub_menu: el?._id,
      main_menu: item?._id,
    };

    axios
      .post(`${API_URL}rules/menu_option`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selectedPrivilege);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  useEffect(() => {
    fetch_all_privilege();
  }, []);

  function fetch_all_privilege() {
    axios
      .get(`${API_URL}privilage/items`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var privilege_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.privilege_name,
              value: el._id,
            };
          });

        setPrivilegeOptions([
          {
            options: privilege_data,
          },
        ]);
      });
  }

  let getAllTabs = (privilage) => {
    axios
      .get(`${API_URL}tabs?privilege=${privilage}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => setTabs(res.data.data))
      .catch((err) => console.log(err.response.data.message));
  };

  useEffect(() => {
    //console.log("event triggered");
    getAllTabs(selectedPrivilege?.value || "");
  }, [selectedPrivilege, reload]);

  let addTab = (tab_id, e) => {
    let status = e.target.checked === true ? 0 : 1;
    axios
      .post(
        `${API_URL}tabs`,
        {
          privilege: selectedPrivilege.value,
          tab_id,
          status,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => setReload(!reload))
      .catch((err) => console.log(err.response.data.message));
  };

  let changeTabEdit = (tab_id, status) => {
    //console.log(selectedPrivilege.value);
    //console.log(tab_id);
    //console.log(status);
    axios
      .put(
        `${API_URL}tabs/change-status`,
        {
          privilege: selectedPrivilege.value,
          tab_id,
          status,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => setReload(!reload))
      .catch((err) => console.log(err.response.data.message));
  };

  //console.log(allTabs);

  const data = {
    columns: [
      {
        label: "Menu",
        field: "mmenu",
        sort: "asc",
        width: 150,
        class: "menu",
      },
      {
        label: "Sub Menu",
        field: "smenu",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel value="start" control={<Checkbox size="small" />} label="View" labelPlacement="start" />
              </FormGroup>
            </FormControl>
          </>
        ),
        field: "view",
        sort: "asc",
        width: 270,
      },
      {
        label: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel value="start" control={<Checkbox size="small" />} label="Edit" labelPlacement="start" />
              </FormGroup>
            </FormControl>
          </>
        ),
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel value="start" control={<Checkbox size="small" />} label="Delete" labelPlacement="start" />
              </FormGroup>
            </FormControl>
          </>
        ),
        field: "delete",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel value="start" control={<Checkbox size="small" />} label="Export" labelPlacement="start" />
              </FormGroup>
            </FormControl>
          </>
        ),
        field: "export",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Rules CRM" />
          <Row>
            <Col xl="12">
              <Card className="p-2">
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Set Rules</Label>
                          <Select
                            name="rules"
                            value={selectedPrivilege}
                            onChange={(selected) => {
                              handleSelectChange(selected);
                            }}
                            classNamePrefix="select2-selection"
                            options={privilegeOptions}
                          />
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="7">
              <Card>
                <CardBody style={{ marginTop: "35px", marginBottom: "35px" }}>
                  <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                    <TableHead>
                      <TableRow style={{ textAlign: "center" }}>
                        <TableCell style={{ width: "180px", textAlign: "center" }}>Menu</TableCell>
                        <TableCell style={{ width: "210px", textAlign: "center" }}>Sub Menu</TableCell>

                        {options &&
                          options.map((item) => {
                            let status = false;
                            dataOptions &&
                              dataOptions.map((mainmenu) => {
                                let submenu = mainmenu.sub_menu;

                                submenu &&
                                  submenu.map((submenu) => {
                                    let submenuOptions = submenu.sub_menu_options;
                                    submenuOptions &&
                                      submenuOptions.map((submenuOptions) => {
                                        if (submenuOptions === item?._id) status = true;
                                        // else status = false;
                                      });
                                  });
                                  
                              });
                            return (
                              <TableCell
                              // style={{ width: "130px", textAlign: "center", display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
                              >
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                  {item.option_name}
                                  <input
                                    type="checkbox"
                                    style={{
                                      marginLeft: "6px",
                                      cursor: "pointer",
                                    }}
                                    checked={status === true ? true : false}
                                    onChange={(e) => handleAllmenuCheckboxChange(e, item)}
                                  />
                                </div>
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataOptions &&
                        dataOptions.map((item, index) => (
                          <TableRow style={{ verticalAlign: "top" }}>
                            <TableCell component="td" scope="row" style={{ textAlign: "left" }}>
                              <div>
                                <ul style={{ listStyleType: "none" }}>
                                  <li className="mb-2">
                                    <input
                                      type="checkbox"
                                      className="mb-2"
                                      checked={item.allocation_status === 1 ? true : false}
                                      onChange={(e) => handleMainmenuCheckboxChange(e, item)}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                      }}>
                                      {item.main_menuname}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              <div>
                                <ul style={{ listStyleType: "none" }}>
                                  {item.sub_menu &&
                                    item.sub_menu.map((element, index) => {
                                      return (
                                        <li className="mb-2">
                                          <input
                                            type="checkbox"
                                            checked={element.allocation_status === 1 ? true : false}
                                            onChange={(e) => handleCheckboxChange(e, element, item)}
                                            className="mb-2"
                                            style={{ cursor: "pointer" }}
                                          />
                                          <span
                                            style={{
                                              marginLeft: "5px",
                                            }}>
                                            {element.sub_name}
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </TableCell>
                            {options &&
                              options.map((element, index) => {
                                // console.log("element", element)

                                var status = false;
                                return (
                                  <TableCell>
                                    <div>
                                      <ul style={{ listStyleType: "none" }}>
                                        {
                                          item.sub_menu.length > 0
                                            ? // {item.sub_menu &&
                                              item.sub_menu.map((el, index) => {
                                                var status = false;

                                                el.sub_menu_options &&
                                                  el.sub_menu_options.map((submenuOpt) => {
                                                    if (submenuOpt === element?._id) {
                                                      status = true;
                                                    }
                                                  });

                                                return (
                                                  <li className="mb-2">
                                                    <input
                                                      type="checkbox"
                                                      className="mb-2"
                                                      checked={status === true ? true : false}
                                                      onChange={(e) => handleOptionCheckboxChange(e, el, element)}
                                                      style={{ cursor: "pointer" }}
                                                      disabled={disabled === element?._id ? true : false}
                                                    />
                                                    <span
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}>
                                                      {""}
                                                    </span>
                                                  </li>
                                                );
                                              })
                                            : //}
                                              (item.maimenu_options &&
                                                item.maimenu_options.map((mainmenuOpt) => {
                                                  if (mainmenuOpt === element?._id) {
                                                    status = true;
                                                  }
                                                }),
                                              (
                                                <li className="mb-2">
                                                  <input
                                                    type="checkbox"
                                                    className="mb-2"
                                                    checked={status === true ? true : false}
                                                    disabled={disabled === element?._id ? true : false}
                                                    onChange={(e) =>
                                                      handleOptionCheckboxChanges(
                                                        e,
                                                        // el,
                                                        element,
                                                        item
                                                      )
                                                    }
                                                    style={{ cursor: "pointer" }}
                                                  />
                                                  <span
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}>
                                                    {""}
                                                  </span>
                                                </li>
                                              ))

                                          // element.maimenu_options &&
                                          // element.maimenu_options.map(
                                          //   (mainmenuOpt) => {
                                          //     if (
                                          //       mainmenuOpt === element?._id
                                          //     ) {
                                          //       let status = true;
                                          //     }
                                          //   }

                                          // )

                                          //   (<li className="mb-2">
                                          //     <input
                                          //       type="checkbox"
                                          //       className="mb-2"
                                          //       // checked={status === true?true:false}
                                          //       onChange={(e) =>
                                          //         handleOptionCheckboxChange(
                                          //           e,
                                          //           // el,
                                          //           element
                                          //         )
                                          //       }
                                          //       style={{ cursor: "pointer" }}
                                          //     />
                                          //     <span
                                          //       style={{
                                          //         marginLeft: "5px",
                                          //       }}
                                          //     >
                                          //       {""}
                                          //     </span>
                                          //   </li>
                                          //   )
                                        }
                                      </ul>
                                    </div>
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </Col>

            <Col md={5}>
              <Card className="p-2">
                <CardBody>
                  <CardTitle className="h5">Admission Tab Settings</CardTitle>
                  <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                    <TableHead>
                      <TableRow style={{ textAlign: "center" }}>
                        <TableCell style={{ width: "180px", textAlign: "center" }}>Tabs</TableCell>
                        <TableCell style={{ width: "210px", textAlign: "center" }}>
                          <div>
                            <span>Edit tab</span>
                            {/* <input type="checkbox" name="" id="" /> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allTabs.map((data, idx) => {
                        return (
                          <TableRow>
                            <TableCell className="text-center">
                              <div
                                className="d-flex"
                                style={{
                                  gap: "8px",
                                  justifyContent: "start",
                                  paddingLeft: "1.5rem",
                                }}>
                                <input
                                  style={{ cursor: "pointer" }}
                                  type="checkbox"
                                  checked={data.allocation_status === 0 ? true : false}
                                  name="add"
                                  onClick={(e) => addTab(data._id, e)}
                                />
                                <div style={{ marginTop: "3px" }} className="">
                                  {data.name}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <input
                                style={{ cursor: "pointer" }}
                                type="checkbox"
                                checked={data.tab_edit && data.tab_edit}
                                onClick={(e) => changeTabEdit(data._id, e.target.checked)}
                                name=""
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
