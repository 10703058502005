import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import TopUser from "./topuser";

import MiniWidget from "./mini-widget";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { Modal } from "react-bootstrap";
//Import Components
// import "./dashboard.scss";

// import CountUp from "react-countup";
import { get } from "../../helpers/api_helper";

//Import Image
// const series1 = [
//   {
//     data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
//   },
// ];

// const options1 = {
//   fill: {
//     colors: ["#5b73e8"],
//   },
//   chart: {
//     width: 70,
//     sparkline: {
//       enabled: !0,
//     },
//   },
//   plotOptions: {
//     bar: {
//       columnWidth: "50%",
//     },
//   },
//   labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
//   xaxis: {
//     crosshairs: {
//       width: 1,
//     },
//   },
//   tooltip: {
//     fixed: {
//       enabled: !1,
//     },
//     x: {
//       show: !1,
//     },
//     y: {
//       title: {
//         formatter: function (seriesName) {
//           return "";
//         },
//       },
//     },
//     marker: {
//       show: !1,
//     },
//   },
// };

// const series2 = [70];

// const options2 = {
//   fill: {
//     colors: ["#34c38f"],
//   },
//   chart: {
//     sparkline: {
//       enabled: !0,
//     },
//   },
//   dataLabels: {
//     enabled: !1,
//   },
//   plotOptions: {
//     radialBar: {
//       hollow: {
//         margin: 0,
//         size: "60%",
//       },
//       track: {
//         margin: 0,
//       },
//       dataLabels: {
//         show: !1,
//       },
//     },
//   },
// };

const series3 = [55];

const options3 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

// const series4 = [
//   {
//     data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
//   },
// ];

// const options4 = {
//   fill: {
//     colors: ["#f1b44c"],
//   },
//   chart: {
//     width: 70,
//     sparkline: {
//       enabled: !0,
//     },
//   },
//   plotOptions: {
//     bar: {
//       columnWidth: "50%",
//     },
//   },
//   labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
//   xaxis: {
//     crosshairs: {
//       width: 1,
//     },
//   },
//   tooltip: {
//     fixed: {
//       enabled: !1,
//     },
//     x: {
//       show: !1,
//     },
//     y: {
//       title: {
//         formatter: function (seriesName) {
//           return "";
//         },
//       },
//     },
//     marker: {
//       show: !1,
//     },
//   },
// };

const Dashboard = (props) => {
  // const [showPopup, setShowPopup] = useState(false);
  // const [activeTabJustify1, setactiveTabJustify1] = useState("1");
  // const [isNonDoc, setisNonDoc] = useState("block");
  // const [isDoc, setisDoc] = useState("none");
  // const [isPick, setisPick] = useState("block");
  // const [isDrop, setisDrop] = useState("none");
  // const [activeCargoType, setActiveCargoType] = useState("road");
  // const [activeTabJustify2, setactiveTabJustify2] = useState("1");
  const [details, setDetails] = useState({});
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    get(`${API_URL}dashboard/counts`).then((res) => setDetails(res.data));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setInterval(() => {
      get(`${API_URL}dashboard/counts`).then((res) => setDetails(res.data));
    }, 1000 * 60 * 10);
    // eslint-disable-next-line
  }, []);

  const reports = [
    {
      id: 1,
      icon: "fas fa-users",
      mainIcon: "mdi mdi-book-account",
      iconSize: "3rem",
      iconColor: "#006888",
      iconOpacity: 0.5,
      title: "Admissions",
      value: details?.totalAdmission || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 50,
      chartwidth: 80,
      badgeValue: "23",
      color: "danger",
      desc: "Total Admissions",
      series: series3,
      options: options3,
    },
    {
      id: 1,
      icon: "fas fa-users",
      mainIcon: "fas fa-envelope",
      iconSize: "3rem",
      iconColor: "#A0D8B3",
      iconOpacity: 0.7,
      title: "Enquiry",
      value: details?.totalEnquiry || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 50,
      chartwidth: 80,
      badgeValue: "23",
      color: "danger",
      desc: "Total Admissions",
      series: series3,
      options: options3,
    },
    {
      id: 1,
      icon: "fas fa-users",
      mainIcon: "fas fa-wine-bottle",
      iconSize: "2.2rem",
      iconColor: "#B04759",
      iconOpacity: 0.7,
      title: "Non Drinker",
      value: details?.totalAdmissionNondrunk || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 50,
      chartwidth: 80,
      badgeValue: "23",
      color: "danger",
      crossSign: true,
      desc: "Non Drinker",
      series: series3,
      options: options3,
    },
    {
      id: 1,
      icon: "fas fa-users",
      mainIcon: "bx bx-happy-alt",
      iconSize: "3.8rem",
      iconColor: "#1B9C85",
      iconOpacity: 0.7,
      title: "Discharged",
      value: details?.totalDischarged || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 50,
      chartwidth: 80,
      badgeValue: "23",
      color: "danger",
      desc: "Non Drinker",
      series: series3,
      options: options3,
    },
    {
      id: 1,
      icon: "fas fa-users",
      mainIcon: "fas fa-user-times",
      iconSize: "3rem",
      iconColor: "red",
      iconOpacity: 0.4,
      title: "Discontinued",
      value: details?.totalDiscontinued || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 50,
      chartwidth: 80,
      badgeValue: "23",
      color: "danger",
      desc: "Non Drinker",
      series: series3,
      options: options3,
    },
    // {
    //   id: 4,
    //   icon: "fas fa-duotone fa-book",
    //   title: "Courses",
    //   value: 5,
    //   decimal: 0,
    //   prefix: "",
    //   suffix: "",
    //   charttype: "bar",
    //   chartheight: 40,
    //   chartwidth: 70,
    //   badgeValue: "20",
    //   color: "info",
    //   desc: "Total Enquiry",
    //   series: series4,
    //   options: options4,
    // },
  ];

  // function toggleCustomJustified1(tab) {
  //   if (activeTabJustify1 !== tab) {
  //     setactiveTabJustify1(tab);
  //   }
  // }
  // function toggleCustomJustified2(tab) {
  //   if (activeTabJustify2 !== tab) {
  //     setactiveTabJustify2(tab);
  //   }
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />
          <Row>
            <MiniWidget reports={reports} />
          </Row>
          <Row>
            <Col xl={4}>
              <TopUser />
            </Col>
            <Col xl={4}>
              <TopUser marquee={true} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
