/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Row,
  TabPane,
} from "reactstrap";
import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function OccupationalHistory(props) {
  let {  details, editable } = props;

  useEffect(() => {
    setMasterObj(details);
  }, [details]);

  const [MasterObj, setMasterObj] = useState({
    occupational_damage: {
      absenteesim: false,
      warning: false,
      suspension: false,
      transfer: false,
      loss_of_pay: false,
      accidents: false,
      dismissal: false,
      intoxication_at_work: false,
    },
  });

  const handleInput = (e, mthd) => {
    let { name, value, type, checked } = e.target;
    let newVal = type === "checkbox" ? checked : value;
    if (type === "radio") {
      value = Number(value);
    }

    if (mthd === 0) {
      setMasterObj((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (mthd === 1) {
      setMasterObj((prev) => ({
        ...prev,
        occupational_damage: {
          ...prev?.occupational_damage,
          [name]: newVal,
        },
      }));
    }
  };

  function handleValidSubmit() {
    MasterObj.admission_id = props.id;
    axios
      .post(`${API_URL}admission/occupational-history`, MasterObj, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success(res.data.message);
        props.handleToggleBar("increment");
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  }

  return (
    <TabPane tabId={6}>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
          handleValidSubmit();
        }}>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="6">
              <h6>1. At what age did you start working?</h6>
            </Col>

            <Col md="6" style={{ display: "flex", justifyContent: "space-between" }}>
              <AvField
                disabled={!editable}
                name="start_working_age"
                type="number"
                className="form-control form-control3"
                onChange={(e) => handleInput(e, 0)}
                value={MasterObj?.start_working_age}
              />
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="6">
              <h6>2. How long have you been working?</h6>
            </Col>

            <Col md="6" style={{ display: "flex", justifyContent: "space-between" }}>
              <AvField
                disabled={!editable}
                name="working_tenure"
                type="text"
                className="form-control form-control3"
                onChange={(e) => handleInput(e, 0)}
                value={MasterObj?.working_tenure}
              />
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col>
              <h6>3. Have you received any special award,recognition,merit certificates or promotions in the past?</h6>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: "1rem" }} md="9" className="mt-1">
              <AvField
                disabled={!editable}
                name="achievements"
                type="textarea"
                className="form-control "
                onChange={(e) => handleInput(e, 0)}
                value={MasterObj?.achievements}
              />
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="7">
              <h6>4. Did you change your job frequently due to addiction?</h6>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="frqnt_job_change"
                  value={1}
                  checked={MasterObj?.frqnt_job_change == 1 ? true : false}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="frqnt_job_change"
                  value={0}
                  checked={MasterObj?.frqnt_job_change == 0 ? true : false}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="7">
              <h6>5. Did you have any periods of unemployment?</h6>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  name="unemployment_prd"
                  checked={MasterObj?.unemployment_prd == 1 ? true : false}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  name="unemployment_prd"
                  checked={MasterObj?.unemployment_prd == 0 ? true : false}
                  onClick={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="9">
              <p style={{ marginLeft: "1rem" }}>5.1 How long and for what reasons?</p>
            </Col>
            <Col style={{ marginLeft: "1rem" }} md="9">
              <AvField
                disabled={!editable}
                name="unemployment_det"
                type="textarea"
                className="form-control "
                onChange={(e) => handleInput(e, 0)}
                value={MasterObj?.unemployment_det}
              />
            </Col>
          </Row>
        </Col>
        <Col className="mt-3">
          <Row>
            <h6>6. Occupational damage</h6>
          </Row>
        </Col>

        <Row>
          <Col md="6">
            <p style={{ marginLeft: "1rem" }}>
              6.1 Absenteeism
              <input
                disabled={!editable}
                type="checkbox"
                checked={MasterObj?.occupational_damage?.absenteesim}
                name="absenteesim"
                style={{ marginLeft: "10px" }}
                onClick={(e) => handleInput(e, 1)}
              />
            </p>
          </Col>
          <Col md="6">
            <p>
              6.2 Loss of pay
              <input
                disabled={!editable}
                type="checkbox"
                checked={MasterObj?.occupational_damage?.loss_of_pay}
                name="loss_of_pay"
                style={{ marginLeft: "10px" }}
                onClick={(e) => handleInput(e, 1)}
              />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <p style={{ marginLeft: "1rem" }}>
              6.3 Warning/Memos
              <input
                disabled={!editable}
                type="checkbox"
                checked={MasterObj?.occupational_damage?.warning}
                name="warning"
                style={{ marginLeft: "10px" }}
                onClick={(e) => handleInput(e, 1)}
              />
            </p>
          </Col>
          <Col md="6">
            <p>
              6.4 Accidents on the job
              <input
                disabled={!editable}
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={MasterObj?.occupational_damage?.accidents}
                name="accidents"
                onClick={(e) => handleInput(e, 1)}
              />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <p style={{ marginLeft: "1rem" }}>
              6.5 Suspension order
              <input
                disabled={!editable}
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={MasterObj?.occupational_damage?.suspension}
                name="suspension"
                onClick={(e) => handleInput(e, 1)}
              />
            </p>
          </Col>
          <Col md="6">
            <p>
              6.6 Dismissal order
              <input
                disabled={!editable}
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={MasterObj?.occupational_damage?.dismissal}
                name="dismissal"
                onClick={(e) => handleInput(e, 1)}
              />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <p style={{ marginLeft: "1rem" }}>
              6.7 Transfer order
              <input
                disabled={!editable}
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={MasterObj?.occupational_damage?.transfer}
                name="transfer"
                onClick={(e) => handleInput(e, 1)}
              />
            </p>
          </Col>
          <Col md="6">
            <p>
              6.8 Worked under the influence of alcohol/drugs
              <input
                disabled={!editable}
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={MasterObj?.occupational_damage?.intoxication_at_work}
                name="intoxication_at_work"
                onClick={(e) => handleInput(e, 1)}
              />
            </p>
          </Col>
        </Row>

        <Col md="12" className="mt-3">
          <Row>
            <Col>
              <h6>7. Specify nature of current work</h6>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: "1rem" }} md="9" className="mt-1">
              <AvField
                disabled={!editable}
                name="crnt_wrk_ntur"
                type="textarea"
                className="form-control "
                onChange={(e) => handleInput(e, 0)}
                value={MasterObj?.crnt_wrk_ntur}
              />
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="7">
              <h6>8. Occupational damage as perceived by the counsellor</h6>
            </Col>
            <Col md="5">
              <p>
                Mild{" "}
                <input
                  disabled={!editable}
                  name="ocpnl_dmg_by_cnslr"
                  type="radio"
                  checked={MasterObj?.ocpnl_dmg_by_cnslr == 0 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; Moderate{" "}
                <input
                  disabled={!editable}
                  name="ocpnl_dmg_by_cnslr"
                  type="radio"
                  checked={MasterObj?.ocpnl_dmg_by_cnslr == 1 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e, 0)}
                />
                &nbsp; Severe{" "}
                <input
                  disabled={!editable}
                  name="ocpnl_dmg_by_cnslr"
                  type="radio"
                  checked={MasterObj?.ocpnl_dmg_by_cnslr == 2 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={2}
                  onClick={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
        </Col>

        <Row className="mt-4">
          <Col md={12} className="submit-btn-fixed">
            <FormGroup className="float-right ">
              <a className="save-continue-btn" onClick={() => props.handleToggleBar("decrement")}>
                <i class="fas fa-solid fa-less-than"></i>
              </a>
              <button type="submit" className="save-continue-btn m-2">
                Save and continue <i style={{ fontSize: "13px" }} className="mdi mdi-arrow-right"></i>
              </button>
              <a className="save-continue-btn" onClick={() => props.handleToggleBar("increment")}>
                <i class="fas fa-solid fa-greater-than"></i>
              </a>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </TabPane>
  );
}

export default OccupationalHistory;
