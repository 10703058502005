/* eslint-disable */
import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import io from 'socket.io-client';
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import { getUserId } from "../../../helpers/globalFunctions"


//Import images
// import avatar3 from "../../../assets/images/users/avatar-3.jpg"
// import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import moment from "moment"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  // const [status, setstatus] = useState(0);
  const [notificationNumber, setNotificationNumber] = useState(0);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [notifications, setnotifications] = useState();
  // const [notificatonTime, setnotificatonTime] = useState();

  useEffect(() => {
    getall_unRead()

  }, [notificationNumber]);


  const userId = getUserId();
  const history = useHistory();

  function change_Status(id) {
    //console.log("id", id);
    let dat = { message_id: id };
    //console.log("dat", dat);
    axios
      .put(`${API_URL}notification/view-status`, dat, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          getall_unRead();
        }
      });
  }





  useEffect(() => {
    const socket = io(`${API_URL}?token=${accessToken}`); // Replace with your server URL

    socket.on('new_notification', (number) => {
      setNotificationNumber(number);
    });

    socket.on('notification', (number) => {
      setNotificationNumber(number);
    });

    socket.on('connect_error', (err) => {
    })
    return () => {
      socket.disconnect();
    };
  }, []);


  function markAllread() {
    axios.put(`${API_URL}notification/all-read`, {}, {
      headers: {
        "x-access-token": accessToken,
      },
    }).then((res) => {
    })
  }


  function getall_unRead() {
    axios.get(`${API_URL}notification?read_status=0&user_id=${userId}`, {
      headers: {
        "x-access-token": accessToken,
      },
    })
      .then((res) => {
        setnotifications(res.data.data)
      })
      .catch(err => console.log(err))
  }
  const currentTime = moment().format("HH:mm")

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell"></i>
          <span className="badge bg-danger rounded-pill">{notificationNumber}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a style={{ cursor: "pointer" }} onClick={() => markAllread()} className="small">
                  {" "}
                  Mark all as read
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {
              notifications &&
              notifications?.notifications?.map((item) => {
                return (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>{
                      setMenu(false)
                      change_Status(item._id)
                      history.push(`/admission/${item?.data_id?.reg_no || item?.data_id?.serial_number || item?.data_id._id}`, {
                        id: item?.data_id?._id,
                      })}
                    }
                    className="text-reset notification-item">
                    <div className="d-flex align-items-start">
                      <div className="avatar-xs me-3">
                        <span style={{backgroundColor:"#067395"}} className="avatar-title  rounded-circle font-size-16">

                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                      <div className="flex-1">
                        <h6 className="mt-0 mb-1">
                          {item?.title}
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">
                            {item?.body}
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            {moment(item?.time, "HH:mm").format("h:mm A")}  {moment(item?.date).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </span>)
              })
            }





          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notification"
            ><i className="uil-arrow-circle-right me-1"></i>
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}