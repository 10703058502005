import { takeEvery, put, call } from "redux-saga/effects";

import { GET_PATIENTS_OPTIONS } from "./actionTypes";

import { getPatientsOptionsFail, getPatientsOptionsSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getPatients } from "../../helpers/backend_helper";

function* fetchPatients({ payload: companyId }) {
  try {
    let response = yield call(getPatients);
    response = response?.data.map((item) => ({ ...item, label: `${item.fname || ""} ${item.lname || ""}`, value: item._id }));
    yield put(getPatientsOptionsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(getPatientsOptionsFail(error));
  }
}

function* branchesSaga() {
  yield takeEvery(GET_PATIENTS_OPTIONS, fetchPatients);
}

export default branchesSaga;
