import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_CUSTOMERS,
  GET_CUST_VISITLOG,
  GET_CUST_RECEIPTS,
  GET_CUST_INVOICE,
  GET_CUST_STATEMENT,
  ADD_CUSTOMERS,
  UPDATE_CUSTOMERS,
  GET_CUSTOMER_TYPE,
  DELETE_CUSTOMERS,
} from "./actionTypes";

import {
  getCustomersSuccess,
  getCustomersFail,
  getCustVisitLogSuccess,
  getCustVisitLogFail,
  getCustReceiptsSuccess,
  getCustReceiptsFail,
  getCustInvoiceSuccess,
  getCustInvoiceFail,
  getCustStatementSuccess,
  getCustStatementFail,
  addCustomersSuccess,
  addCustomersFail,
  updateCustomersSuccess,
  updateCustomersFail,
  deleteCustomersSuccess,
  deleteCustomersFail,
  getCustomerTypeSuccess,
  getCustomerTypeFail,
} from "./actions";

import {
  getCustomers,
  getCustVisitLog,
  getCustReceipts,
  getCustInvoice,
  getCustStatement,
  addCustomers,
  updateCustomers,
  getCustomerType,
  deleteCustomers,
} from "../../helpers/backend_helper";

function* fetchCustomers() {
  try {
    const response = yield call(getCustomers);
    yield put(getCustomersSuccess(response));
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}

function* fetchVisitLog({ custId }) {
  try {
    const response = yield call(getCustVisitLog, custId);
    yield put(getCustVisitLogSuccess(response));
  } catch (error) {
    yield put(getCustVisitLogFail(error));
  }
}

function* fetchCustReceipts({ custId }) {
  try {
    const response = yield call(getCustReceipts, custId);
    yield put(getCustReceiptsSuccess(response));
  } catch (error) {
    yield put(getCustReceiptsFail(error));
  }
}
function* fetchCustInvoice({ custId }) {
  try {
    const response = yield call(getCustInvoice, custId);
    yield put(getCustInvoiceSuccess(response));
  } catch (error) {
    yield put(getCustInvoiceFail(error));
  }
}
function* fetchCustStatement({ custId }) {
  try {
    const response = yield call(getCustStatement, custId);
    yield put(getCustStatementSuccess(response));
  } catch (error) {
    yield put(getCustStatementFail(error));
  }
}

function* onAddCustomer({ payload: data }) {
  try {
    const response = yield call(addCustomers, data);
    yield put(addCustomersSuccess(response));
  } catch (error) {
    yield put(addCustomersFail(error.response));
  }
}

function* onUpdateCustomer({ payload: data }) {
  try {
    const response = yield call(updateCustomers, data);
    yield put(updateCustomersSuccess(response));
  } catch (error) {
    yield put(updateCustomersFail(error.response));
  }
}
function* fetchCustomerTypes() {
  try {
    const response = yield call(getCustomerType);
    yield put(getCustomerTypeSuccess(response));
  } catch (error) {
    yield put(getCustomerTypeFail(error));
  }
}
function* ondeleteCustomer({ payload: customerId }) {
  try {
    const response = yield call(deleteCustomers, customerId);
    yield put(deleteCustomersSuccess(response));
  } catch (error) {
    yield put(deleteCustomersFail(error.response));
  }
}
function* customerSaga() {
  yield takeEvery(GET_CUSTOMERS, fetchCustomers);
  yield takeEvery(GET_CUST_VISITLOG, fetchVisitLog);
  yield takeEvery(GET_CUST_RECEIPTS, fetchCustReceipts);
  yield takeEvery(GET_CUST_INVOICE, fetchCustInvoice);
  yield takeEvery(GET_CUST_STATEMENT, fetchCustStatement);
  yield takeEvery(ADD_CUSTOMERS, onAddCustomer);
  yield takeEvery(UPDATE_CUSTOMERS, onUpdateCustomer);
  yield takeEvery(GET_CUSTOMER_TYPE, fetchCustomerTypes);
  yield takeEvery(DELETE_CUSTOMERS, ondeleteCustomer);
}

export default customerSaga;
