/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./styles.css";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { getDischargeCertificates, getDischargedPatients, getIdentity, submitDischarge } from "./api";
import toastr from "toastr";

const Discharge = () => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [dischargedPatients, setDischargedPatients] = useState([]);
  const [identity, setIdentity] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedIdentity, setSelectedIdentity] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getDischargedPatients().then((data) => setDischargedPatients(data));
    getIdentity().then((data) => setIdentity(data));
  }, []);

  const handlePrint = (url) => {
    window.open(url, "_blank");
    // saveAs(certificatePath);
    // window.print();
  };

  useEffect(() => {
    getDischargeCertificates().then((data) => {
      data.map((item, idx) => {
        //console.log(item);
        item.id = idx + 1;
        item.name = item?.basic_details?.basic_id.fname + " " + item?.basic_details?.basic_id?.lname;
        item.admission_date = item?.basic_details.reg_date;
        item.action = (
          <span>
            <i
              onClick={() => handlePrint(API_URL + item.certificate_path)}
              className="fas fa-print"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
              }}></i>
          </span>
        );
      });
      setTableData(data);
    });
  }, [reload]);

  let handleSubmit = (data) => {
    let {
      basic_details: { basic_id },
      basic_details,
    } = data;
    let details = {
      id: data._id,
      patient_name: basic_id.fname + " " + basic_id.lname,
      identity_type: basic_details.identity_type,
      identity_no: basic_details.identity_no,
      admission_date: basic_details.reg_date,
      discharge_date: data.exp_discharge_date,
    };

    submitDischarge(details).then((data) => {
      if (data.status === 200) {
        toastr.success(data.message);
        setSelectedPatient(null);
        setReload(!reload);
      }
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Admission Date",
        field: "admission_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Discharge Date",
        field: "exp_discharge_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Discharge Certificates" />
        <Row>
          <Card>
            <CardBody>
              <AvForm
                // ref={formRef}
                onValidSubmit={() => {
                  handleSubmit(selectedPatient);
                }}>
                <Row>
                  <Col md={3}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="patients">Select Patient</Label>
                      <Select
                        name="patients"
                        menuPosition="fixed"
                        value={selectedPatient}
                        onChange={(selected) => {
                          setSelectedPatient(selected);
                        }}
                        // validate={{ required: { value: true } }}
                        options={dischargedPatients}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                  {selectedPatient && (
                    <>
                      <Col md={3}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="aadharNo">Identity No</Label>
                          <AvField
                            disabled={true}
                            name="aadharNo"
                            type="text"
                            className="form-control"
                            placeholder="Aadhar No"
                            value={selectedPatient?.basic_details?.identity_no}
                            //   onChange={handleChange}
                            validate={{
                              required: { value: true },
                              // pattern: {
                              //   value:
                              //     "^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$",
                              // },
                              //   minLength: { value: 14 },
                              //   maxLength: { value: 14 },
                            }}
                            // errorMessage="Enter fathername"
                          />
                          {/* <span className="font-13 text-muted">
                            format: 9999 9999 9999
                          </span> */}
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="firstname">Admission Date</Label>
                          <AvField
                            // disabled={!editable}
                            disabled={true}
                            name="firstname"
                            type="date"
                            className="form-control"
                            value={selectedPatient?.basic_details?.reg_date}
                            //   onChange={handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            // errorMessage="Enter fathername"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="firstname">Discharge Date</Label>
                          <AvField
                            disabled={true}
                            name="firstname"
                            type="date"
                            className="form-control"
                            value={selectedPatient?.exp_discharge_date}
                            //   onChange={handleChange}
                            validate={{
                              required: { value: true },
                            }}
                            // errorMessage="Enter fathername"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2} className={!selectedIdentity && "d-flex align-items-center mt-2"}>
                        <button type="submit" className="btn btn-primary">
                          Create
                        </button>
                      </Col>
                    </>
                  )}
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <MDBDataTable className="text-center" id="internDataTabel" responsive bordered data={data} />
            </CardBody>
          </Card>
        </Row>
      </div>
    </div>
  );
};

export default Discharge;
