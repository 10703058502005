/* eslint-disable */
import React, { useState } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Col, Label, Button, Card, CardBody, Row, ToastHeader, Table } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
import "./enquiry.scss";
import { LabelOutlined } from "@mui/icons-material";

const Enquiry = () => {
  const reset = () => {
    // formRef.current.reset();
    // setcargoIdToUpdated(null);
    // setcargoobject({});
  };

  const [isChecked, setIsChecked] = useState(false);
  const [treatment, setTreatment] = useState(null);
  const [treatmentMode, setTreatmentMOde] = useState(null);
  const [values, setValues] = useState([
    {
      Name_Family_Member: "",
      Address: "",
      Mobile: "",
    },
  ]);
  const addMore = () => {
    setValues([...values, { Name_Family_Member: "", Address: "", Mobile: "" }]);
  };

  const handleChange = (e, data) => {
    if (data == "0") {
      if (e.target.checked) {
        setTreatment(1);
        if (treatment == 1) {
          setIsChecked(true);
          // setIsChecked1(true);
          // setcourseObject({
          //   ...courseObject,
          //   mode: "2"

          // })
        } else {
          setIsChecked(false);
          // setIsChecked1(false);
          // setcourseObject({
          //   ...courseObject,
          //   mode: "0"
          // })
        }
      } else {
        // $("#amount").removeAttr('required');
        setTreatment(null);
        if (treatment == 1) {
          setIsChecked(true);
          // setIsChecked(false);
          // setcourseObject({
          //   ...courseObject,
          //   mode: "1"
          // })
        } else {
          setIsChecked(false);
          // setcourseObject({
          //   ...courseObject,
          //   mode: ""
          // })
        }
      }

      // }
      // else {
      //   if (e.target.checked) {
      //     setLiveMode(1);
      //     if (recordedMode == 1) {
      //       setIsChecked(true);
      //       setIsChecked1(true);
      //       setcourseObject({
      //         ...courseObject,
      //         mode: "2"
      //       })
      //     }
      //     else {
      //       setIsChecked1(true);
      //       setIsChecked(false);
      //       setcourseObject({
      //         ...courseObject,
      //         mode: "1"
      //       })
      //     }
      //   }
      //   else {
      //     setLiveMode(null);
      //     if (recordedMode == 1) {
      //       setIsChecked(true);
      //       setIsChecked1(false);
      //       setcourseObject({
      //         ...courseObject,
      //         mode: "0"
      //       })
      //     }
      //     else {
      //       setIsChecked1(false);
      //       setIsChecked(false);
      //       setcourseObject({
      //         ...courseObject,
      //         mode: ""
      //       })
      //     }
      //   }
    }
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "Name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date",
        field: "Date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "Mobile",
        sort: "asc",
        width: 200,
      },
      {
        label: "Address",
        field: "Address",
        sort: "asc",
        width: 200,
      },
      {
        label: "Remarks",
        field: "Remarks",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
  };
  // const handleRemove = (index) => {
  //    const list = [...values];
  //   list.splice(index, 1);
  //   //console.log(index, "index");
  //   // setbatchObject({ ...batchObject, day: list });
  //   // setValues(list);
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Enquiry" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                  // className="needs-validation"
                  // ref={formRef}
                  // onValidSubmit={(e, v) => {
                  // handleValidSubmit(e, v);
                  // }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Name</Label>
                          <AvField
                            name="name"
                            placeholder="Name"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            // errorMessage="Enter Cargo Type"
                            // onChange={handleChangeInput}
                            // value={cargoobject?.cargo_Type}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Date</Label>
                          <AvField
                            name="date"
                            placeholder="Date"
                            type="date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            // errorMessage="Enter Cargo Type"
                            // onChange={handleChangeInput}
                            // value={cargoobject?.cargo_Type}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Mobile</Label>
                          <AvField
                            name="mobile"
                            placeholder="Mobile"
                            type="Number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            // errorMessage="Enter Cargo Type"
                            // onChange={handleChangeInput}
                            // value={cargoobject?.cargo_Type}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Country</Label>
                          <Select
                            name="Country"
                            placeholder="Select"
                            // value={selectedItem}
                            // validate={{required:{value:true}}}
                            // onChange={(selected) => {
                            // //   handleSelectChange(selected)
                            // }}
                            options={[{ label: "India" }, { label: "Germany" }, { label: "England" }, { label: "Spain" }]}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {/* {selectedItem===null?errors.StatusFor:""} */}
                          </p>
                        </div>
                      </Col>
                      <Col md="3" className="mt-2">
                        <Label>State</Label>
                        <Select
                          name="state"
                          placeholder="Select"
                          // value={selectedState}
                          validate={{ required: { value: true } }}
                          // onChange={(selected) => {
                          // handleSelectChange(selected, '3')
                          //   }}
                          options={[{ label: "Kerala" }, { label: "Karnataka" }, { label: "Maharashtra" }]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      <Col md="3" className="mt-2">
                        <Label>District</Label>
                        <Select
                          name="district"
                          placeholder="Select"
                          // value={selectedState}
                          validate={{ required: { value: true } }}
                          // onChange={(selected) => {
                          // handleSelectChange(selected, '3')
                          //   }}
                          options={[{ label: "Alappuzha" }, { label: "Eranakula" }, { label: "Kannur" }]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      <Col md="4">
                        <div className="mb-3">
                          <Label>Address</Label>
                          <AvField
                            name="address"
                            placeholder="Address"
                            type="textarea"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            // errorMessage="Enter Cargo Type"
                            // onChange={handleChangeInput}
                            // value={cargoobject?.cargo_Type}
                          />
                        </div>
                      </Col>

                      <Col md="2" className="mt-2">
                        <Label>Pin</Label>
                        <AvField
                          name="pin"
                          type="text"
                          minLength={6}
                          maxLength={6}
                          className="form-control"
                          placeholder="Pincode"
                          validate={{ required: { value: true }, pattern: { value: "^[0-9]+$", errorMessage: "Invalid pincode" } }}
                          errorMessage="Pincode is required"
                          // onChange={handleChangeInput}
                          // value={studentObject?.pin}
                        />
                      </Col>
                      <Col md="3" className="mt-2">
                        <Label>Gender</Label>
                        <Select
                          name="gender"
                          placeholder="Select"
                          // value={selectedGender}
                          validate={{ required: { value: true } }}
                          // onChange={(selected) => {
                          //     handleSelectChange(selected, '1')
                          // }}
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>
                      <Col md="3" className="mt-2">
                        <Label>Religion</Label>
                        <Select
                          name="religion"
                          placeholder="Select"
                          // value={selectedGender}
                          validate={{ required: { value: true } }}
                          // onChange={(selected) => {
                          //     handleSelectChange(selected, '1')
                          // }}
                          options={[{ label: "Muslim" }, { label: "Christain" }, { label: "hindu" }]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>
                      <Col md="3" className="mt-2">
                        <Label>Age</Label>
                        <AvField
                          name="age"
                          placeholder="Age"
                          type="Number"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          // errorMessage="Enter Cargo Type"
                          // onChange={handleChangeInput}
                          // value={cargoobject?.cargo_Type}
                        />
                      </Col>
                      <Col md="3" className="mt-2">
                        <Label>Date of Birth</Label>
                        <AvField
                          name="dob"
                          type="date"
                          className="form-control"
                          placeholder="DOB"
                          validate={{ required: { value: true } }}
                          errorMessage="Date of Birth is required"
                          // onChange={handleChangeInput}
                          // value={studentObject?.dob}
                          // min={getDate(new Date())}
                        />
                      </Col>
                      <Col md="3" className="mt-2">
                        <Label>Qualification</Label>
                        <Select
                          name="qualification"
                          placeholder="Select"
                          // value={selectedQualification}
                          validate={{ required: { value: true } }}
                          // onChange={(selected) => {
                          //     handleSelectChange(selected, '2')
                          // }}
                          options={[{ label: "+2" }, { label: "BCA" }, { label: "MBA" }]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>
                      <Col md="3" className="mt-2">
                        <Label>Occupation</Label>
                        <AvField
                          name="occupation"
                          type="text"
                          className="form-control"
                          placeholder="Occupation"
                          validate={{ required: { value: true } }}
                          errorMessage="Date of Birth is required"
                          // onChange={handleChangeInput}
                          // value={studentObject?.dob}
                          // min={getDate(new Date())}
                        />
                      </Col>
                      <Col md="3" className="mt-2">
                        <Label>Community</Label>
                        <AvField
                          name="community"
                          type="text"
                          className="form-control"
                          placeholder="Community"
                          validate={{ required: { value: true } }}
                          errorMessage="Date of Birth is required"
                          // onChange={handleChangeInput}
                          // value={studentObject?.dob}
                          // min={getDate(new Date())}
                        />
                      </Col>
                      <Col md="3">
                        <div className="mt-2">
                          <Label>Income</Label>
                          <AvField
                            name="income"
                            placeholder="Income"
                            type="Number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            // errorMessage="Enter Cargo Type"
                            // onChange={handleChangeInput}
                            // value={cargoobject?.cargo_Type}
                          />
                        </div>
                      </Col>

                      <Col md="4" className="mt-2">
                        <Label>Marital Status</Label>
                        <Select
                          name="marital_status"
                          placeholder="Select"
                          // value={selectedQualification}
                          validate={{ required: { value: true } }}
                          // onChange={(selected) => {
                          //     handleSelectChange(selected, '2')
                          // }}
                          options={[{ label: "Married" }, { label: "Single" }]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      <Col md="4" className="mt-2">
                        <Label>Living Arrangements</Label>
                        <Select
                          name="living_arrange"
                          placeholder="Select"
                          // value={selectedQualification}
                          validate={{ required: { value: true } }}
                          // onChange={(selected) => {
                          //     handleSelectChange(selected, '2')
                          // }}
                          options={[
                            { label: "Live With Family" },
                            { label: "Live Alone" },
                            { label: "Live With Friends Or Distant Relatives" },
                            { label: "On The Street" },
                          ]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>
                      <Col md="4" className="mt-2">
                        <Label>Place Of Residence</Label>
                        <Select
                          name="placeofresidence"
                          placeholder="Select"
                          // value={selectedQualification}
                          validate={{ required: { value: true } }}
                          // onChange={(selected) => {
                          //     handleSelectChange(selected, '2')
                          // }}
                          options={[{ label: "Rural" }, { label: "urban" }]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>
                      <Col md="4" className="mt-2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Adhaar</Label>
                          {/* {userObject?.user_image ? ( */}
                          <div div className="img-wraps">
                            {/* {userObject.user_image &&
                                               userObject.user_image.startsWith(
                                                "http"
                                               ) ? ( */}
                            {/* <img
                                             alt=""
                                             width="150"
                                            height="150"
                                           //  src={`${userObject.user_image}`}
                                          /> */}
                            {/* ) : ( */}
                            {/* <img
                                       alt=""
                                       width="150"
                                      height="150"
                                     //  src={`${API_URL}/uploads/cargoUser_images/${userObject?.user_image}`}
                                        /> */}
                            {/* )} */}
                            {/* <button
                                    className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                    //  onClick={deleteImage}
                                   style={{ width: "150px" }}
                                     >
                                   Delete
                                  </button> */}
                          </div>
                          {/* ) : ( */}
                          <AvField
                            name="Adhaar"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            //  onChange={uploadImage}
                            rows="1"
                          />
                          {/* )} */}
                        </div>
                      </Col>

                      <Col md="4" className="mt-2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Photo</Label>
                          {/* {userObject?.user_image ? ( */}
                          <div div className="img-wraps">
                            {/* {userObject.user_image &&
                                               userObject.user_image.startsWith(
                                                "http"
                                               ) ? ( */}
                            {/* <img
                                             alt=""
                                             width="150"
                                            height="150"
                                           //  src={`${userObject.user_image}`}
                                          /> */}
                            {/* ) : ( */}
                            {/* <img
                                       alt=""
                                       width="150"
                                      height="150"
                                     //  src={`${API_URL}/uploads/cargoUser_images/${userObject?.user_image}`}
                                        /> */}
                            {/* )} */}
                            {/* <button
                                    className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                    //  onClick={deleteImage}
                                   style={{ width: "150px" }}
                                     >
                                   Delete
                                  </button> */}
                          </div>
                          {/* ) : ( */}
                          <AvField
                            name="Photo"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            //  onChange={uploadImage}
                            rows="1"
                          />
                          {/* )} */}
                        </div>
                      </Col>

                      <Col md="12" style={{ paddingTop: "24px" }}>
                        <table className="table table-bordered" id="familyMembersTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name Of Family Member</th>
                              <th>Address</th>
                              <th>Mobile</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>
                                <AvField
                                  name="Name_Family_Member"
                                  type="text"
                                  placeholder="Name Of Family Member"
                                  //  value={item.start_time}
                                  //  onChange={(e) =>
                                  //  handleTableInputchange(e, index)
                                  // }
                                />
                              </td>
                              <td>
                                <AvField
                                  name="Address"
                                  type="textarea"
                                  style={{ height: "10px" }}
                                  placeholder="Address"
                                  //  value={item.start_time}
                                  //  onChange={(e) =>
                                  //  handleTableInputchange(e, index)
                                  // }
                                />
                              </td>
                              <td>
                                <AvField
                                  name="Mobile"
                                  type="Number"
                                  placeholder="Mobile"
                                  //  value={item.start_time}
                                  //  onChange={(e) =>
                                  //  handleTableInputchange(e, index)
                                  // }
                                />
                              </td>
                              <td>
                                {/* {values?.length !== 1 && ( */}
                                <i
                                  className="fas fa-trash"
                                  style={{ cursor: "pointer" }}
                                  // onClick={() => handleRemove(index)}
                                ></i>
                                {/* )} */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          className="text-primary"
                          onClick={() => addMore()}
                          style={{
                            cursor: "pointer",
                            margin: "auto",
                            paddingTop: "5px",
                          }}>
                          {" "}
                          + Add New{" "}
                        </div>
                      </Col>

                      <Col md="3" className="mt-2">
                        <Label>Referral</Label>
                        <Select
                          name="referral"
                          placeholder="Select"
                          // value={selectedQualification}
                          validate={{ required: { value: true } }}
                          // onChange={(selected) => {
                          //     handleSelectChange(selected, '2')
                          // }}
                          options={[
                            { label: "Family" },
                            { label: "Physicians" },
                            { label: "Self" },
                            { label: "Media" },
                            { label: "Recovered Addict" },
                            { label: "Friends" },
                            { label: "Employer" },
                            { label: "Social Worker" },
                            { label: "Through Awareness Programme" },
                            { label: "Any Other" },
                          ]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      <Col md="2">
                        <div className="mt-3">
                          <Label>Prior Treatment For Addiction</Label>
                          <input type="checkbox" value={0} onChange={(e) => handleChange(e, "0")} checked={isChecked} />
                          &nbsp;
                          <div>
                            {/* <input type="checkbox" value={0} 
                                            //  onChange={(e) => handleChange(e, "0")} 
                                             checked={isChecked}
                                             /> Recorded */}
                            {/* &nbsp; */}
                          </div>
                        </div>
                      </Col>

                      <Col md="2" style={{ display: isChecked ? "block" : "none" }}>
                        <div className="mt-3">
                          <Label>Year</Label>
                          {isChecked ? (
                            <AvField
                              name="year"
                              // id="live_amount"
                              type="year"
                              className="form-control"
                              validate={{ required: { value: true } }} //pattern: { value: '^[0-9.]+$', errorMessage: "Invalid amount" } }}
                              //  errorMessage="Enter Amount"
                              max={moment().format("YYYY-MM")}
                              // onChange={handleChangeInput}
                              // value={courseObject?.live_amount}
                            />
                          ) : (
                            <AvField
                              name="year"
                              // id="live_amount"
                              type="year"
                              className="form-control"
                              //  errorMessage="Enter Amount"
                              max={moment().format("YYYY-MM")}
                              // onChange={handleChangeInput}
                              // value={courseObject?.live_amount}
                            />
                          )}
                        </div>
                      </Col>
                      <Col md="2" style={{ display: isChecked ? "block" : "none" }}>
                        <div className="mt-3">
                          <Label>Place Of Treatment</Label>
                          {isChecked ? (
                            <AvField
                              name="palce_of_treatment"
                              // id="offer_amount"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true }, pattern: { value: "^[0-9.]+$", errorMessage: "Invalid amount" } }}
                              // errorMessage="Enter Offer Amount"
                              // onChange={handleChangeInput}
                              // value={courseObject?.offer_amount}
                            />
                          ) : (
                            <AvField
                              name="palce_of_treatment"
                              // id="offer_amount"
                              type="text"
                              className="form-control"
                              // errorMessage="Enter Offer Amount"
                              // onChange={handleChangeInput}
                              // value={courseObject?.offer_amount}
                            />
                          )}
                        </div>
                      </Col>

                      <Col md="2" style={{ display: isChecked ? "block" : "none" }}>
                        <div className="mt-3">
                          <Label>Days And Month Of Sobriety </Label>
                          {isChecked ? (
                            <AvField
                              name="sobriety"
                              // id="offer_amount"
                              type="date"
                              className="form-control"
                              validate={{ required: { value: true }, pattern: { value: "^[0-9.]+$", errorMessage: "Invalid amount" } }}
                              // errorMessage="Enter Sobriety"
                              // onChange={handleChangeInput}
                              // value={courseObject?.offer_amount}
                            />
                          ) : (
                            <AvField
                              name="sobriety"
                              // id="offer_amount"
                              type="date"
                              className="form-control"
                              // errorMessage="Enter Sobriety"
                              // onChange={handleChangeInput}
                              // value={courseObject?.offer_amount}
                            />
                          )}
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="mt-3">
                          <Label>Remarks</Label>
                          <AvField
                            name="remarks"
                            placeholder="Remarks"
                            type="textarea"
                            style={{ height: "10px" }}
                            className="form-control"
                            validate={{ required: { value: true } }}
                            // errorMessage="Enter Cargo Type"
                            // onChange={handleChangeInput}
                            // value={cargoobject?.cargo_Type}
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ paddingTop: "0px" }}>
                        <div className="mt-5">
                          {/* {cargoIdToUpdated ? ( */}
                          {/* <Button className='mx-2'
                                  color='primary'
                                  type='update'>
                                  update
                                </Button> */}
                          {/* )  */}
                          {/* :  */}
                          <Button className="mx-2" color="primary" type="submit">
                            Submit
                          </Button>
                          {/* } */}
                          <Button color="danger" type="reset" onClick={() => reset()}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    id="AddComplaintstatustable"
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Enquiry;
