/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import CreatableSelect from "react-select/creatable";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import { Row, Col, Card, CardBody, Button, Label, FormGroup } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";


//Import Breadcrumb
import "./style.scss";
import toastr from "toastr";
import axios from "axios";
import Breadcrumb from "../../components/Common/Breadcrumb";
import accessToken from "../../helpers/jwt-token-access/accessToken";

const AIMeeting = (props) => {

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  const [prevTable, setPrevTable] = useState([]);

  const [masterObject, setmasterObject] = useState({});
  const [meetingObject, setmeetingObject] = useState({});

  // const [isChecked, setIsChecked] = useState(false);

  const [meetingIdToBeUpdated, setMeetingIdToBeUpdated] = useState(null);
  const [meetingIdToBeDeleted, setMeetingIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [attendaceDeleteAlert, setAttendaceDeleteAlert] = useState(null);
  const [attendaceDelete, setAttendaceDelete] = useState(null);

  const [popupView, setPopupView] = useState(false);

  const [createdOption, setCreatedOption] = useState(false);
  const [patientOptions, setPatientOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [attendanceData, setAttendanceData] = useState({});
  const [attendanceTable, setAttendanceTable] = useState([]);
  const [meetingId, setMeetingId] = useState("");
  const [meetingDetails, setMeetingDetails] = useState({});
  const [keyWord, setKeyWord] = useState("");

  useEffect(() => {
    handlePrevTableData();
  }, []);

  useEffect(() => {
    getPatients(keyWord);
  }, [keyWord]);

  useEffect(() => {
    getMeetingAttendance(meetingId);
  }, [meetingId]);

  function handlePrevTableData() {
    var url = `${API_URL}ai-meeting/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.map((item, index) => {
          item.id = index + 1;
          item.date = moment(item.meeting_date).format("DD-MM-YYYY");

          if (props.options.edit) {
            item.action = (
              <>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    setMeetingDetails(item);
                    setMeetingId(item._id);
                    handleClickOpen(item);
                  }}></i>

                <i
                  className="fas fa-edit"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    preUpdateMeeting(item);
                    toTop();
                  }}></i>

                <i
                  className="fas fa-trash-alt"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMeetingIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </>
            );
          } else {
            item.action = (
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  setMeetingDetails(item);
                  setMeetingId(item._id);
                  handleClickOpen(item);
                }}></i>
            );
          }
        });

        setPrevTable(result);
      });
  }

  const preUpdateMeeting = (item) => {
    setMeetingIdToBeUpdated(item?._id);
    axios
      .get(`${API_URL}ai-meeting?meeting_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.map((item, index) => {
          item.meeting_id = item?._id;
          item.meeting_date = item?.meeting_date;
          item.remarks = item?.remarks;
          setmasterObject(item);
          handlePrevTableData();
          setmeetingObject(item.meeting_id);
        });
      });
  };

  const handleClickOpen = () => {
    setPopupView(true);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const getPatients = (keyword) => {
    axios
      .get(`${API_URL}ai-meeting/admission-options?keyword=${keyword || ""}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => setPatientOptions(res.data.data))
      .catch((err) => console.log(err.response.data.message));
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };

  const handleValidSubmit = () => {
    if (meetingIdToBeUpdated) {
      axios
        .put(`${API_URL}ai-meeting`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Meeting updated successfully");
          handlePrevTableData();
          formRef.current.reset();
          setmasterObject({});
          setMeetingIdToBeUpdated(null);
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    } else {
      axios
        .post(`${API_URL}ai-meeting`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Meeting added successfully");
          setmasterObject({});
          handlePrevTableData();
          formRef.current.reset();
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          console.log(err);
          return;
        });
    }
  };

  const handleCreated = (e) => {
    setIsLoading(true);
    setPatientOptions((pre) => {
      return [...pre, { label: e, value: e }];
    });
    setCreatedOption(true);
    setValue({ label: e, value: e });
    let data = {
      meeting_id: meetingId,
      name: e,
      mobile: "",
      address: "",
      attendee_type: 1,
    };
    setAttendanceData(data);
    setIsLoading(false);
  };

  const attendanceSubmit = (data) => {
    axios
      .put(`${API_URL}ai-meeting/attendance`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success(res.data.message);
        let data = {
          meeting_id: meetingId,
        };
        getMeetingAttendance(meetingId);
        setValue("");
        setAttendanceData(data);
        setCreatedOption(false);
      })
      .catch((err) => console.error(err.response.data));
  };

  const handleSelectChange = (e) => {
    setCreatedOption(false);
    if (e === null) {
      setKeyWord("");
      setValue("");
      setAttendanceData((preState) => {
        return {
          ...preState,
          name: "",
          attendee_id: "",
          mobile: "",
        };
      });
      return "";
    }
    setValue(e);
    let data = {
      meeting_id: meetingId,
      attendee_id: e.value,
      name: e.label,
      mobile: e.mobile,
      attendee_type: e.attendee_type,
      // att_type: "",
    };
    setAttendanceData(data);
  };

  const handleModalChange = (e) => {
    let { name, value } = e.target;
    setAttendanceData((preState) => {
      return {
        ...preState,
        [name]: value,
      };
    });
  };

  const getMeetingAttendance = (id) => {
    axios
      .get(`${API_URL}ai-meeting?meeting_id=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let attendance = res.data.data[0].attendance;
        attendance.map((data, idx) => {
          data.id = idx + 1;
          data.action = (
            <>
              <i
                className="fas fa-trash-alt"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAttendaceDeleteAlert(true);
                  setAttendaceDelete(data);
                }}></i>
            </>
          );
        });
        setAttendanceTable(attendance);
      })
      .catch((err) => err.response.data.message);
  };

  const deleteAttendance = () => {
    attendaceDelete.attendee_id = attendaceDelete._id;
    attendaceDelete.meeting_id = meetingId;
    delete attendaceDelete.action;
    delete attendaceDelete.id;
    delete attendaceDelete._id;
    delete attendaceDelete.name;
    delete attendaceDelete.address;
    delete attendaceDelete.mobile;
    delete attendaceDelete.lname;
    delete attendaceDelete.reg_no;
    attendaceDelete.att_type = 0;
    axios
      .put(`${API_URL}ai-meeting/attendance`, attendaceDelete, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Attendance deleted successfully");
          setAttendaceDeleteAlert(false);
          setAttendaceDelete(null);
          getMeetingAttendance(meetingId);
        }
      })
      .catch((err) => console.error(err.response.data));
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Meeting Name",
        field: "remarks",
        sort: "asc",
        width: 200,
      },
      {
        label: "Present",
        field: "total_attendence",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 270,
      },
    ],
    rows: prevTable,
  };

  useEffect(() => {
    data.rows.map((item) => {
      if (props.options.edit) {
        item.action = (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                setMeetingDetails(item);
                setMeetingId(item._id);
                handleClickOpen(item);
              }}></i>

            <i
              className="fas fa-edit"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                preUpdateMeeting(item);
                toTop();
              }}></i>

            <i
              className="fas fa-trash-alt"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
              }}
              onClick={() => {
                setMeetingIdToBeDeleted(item._id);
                setConfirmDeleteAlert(true);
              }}></i>
          </>
        );
      } else {
        item.action = (
          <i
            className="fas fa-eye"
            style={{
              fontSize: "0.9em",
              cursor: "pointer",
              marginLeft: "0.1em",
              marginRight: "0.4em",
            }}
            onClick={() => {
              setMeetingDetails(item);
              setMeetingId(item._id);
              handleClickOpen(item);
            }}></i>
        );
      }
    });
  }, [props.options]);

  const dataAttendance = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 200,
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 270,
      },
    ],
    rows: attendanceTable,
  };

  useEffect(() => {
    if (props.options.edit) {
      dataAttendance.columns.push({
        label: "Action",
        field: "action",
        sort: "asc",
        width: 270,
      });
    }
  }, [props.options, attendanceTable]);

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}ai-meeting?meeting_id=` + meetingIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Meeting deleted successfully");
                  handlePrevTableData();
                  setMeetingIdToBeDeleted(null);
                  setmasterObject({});
                  formRef.current.reset();
                } else {
                  toastr.error("Failed To Delete");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      {attendaceDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={deleteAttendance}
          onCancel={() => setAttendaceDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal show={popupView} size="xl" centered={true}>
        <div className="modal-header pb-2">
          <h5 className="modal-title mt-0">Meeting Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
              handlePrevTableData();
              setMeetingId("");
              setMeetingDetails({});
            }}
            className="close"
            data-dismiss="modal"
            aria-label="close"></button>
        </div>
        <Row>
          <div style={{ marginLeft: "2rem", fontSize: "13px" }} className="mt-2 mb-0">
            <Col md={12}>
              <Label style={{ fontWeight: "400" }}>Meeting name:&nbsp;</Label>
              <span style={{ marginRight: "10px", fontWeight: "500" }}>&nbsp;{meetingDetails?.remarks}</span>
              <Label style={{ fontWeight: "400" }}>Meeting date:&nbsp;</Label>
              <span style={{ fontWeight: "500" }}>&nbsp;{meetingDetails?.date}</span>
            </Col>
          </div>
        </Row>
        {props.options.edit && (
          <Card className="px-4 py-0 pb-4 mt-0">
            <AvForm className="mt-3" onValidSubmit={() => attendanceSubmit(attendanceData)}>
              <Row>
                <Col lg={3} xl={3}>
                  <FormGroup className="mx-2">
                    <Label>Patients</Label>
                    <CreatableSelect
                      value={value}
                      isDisabled={isLoading}
                      isLoading={isLoading}
                      options={patientOptions}
                      onChange={(e) => handleSelectChange(e)}
                      onCreateOption={handleCreated}
                      onInputChange={(e) => setKeyWord(e)}
                      isClearable /* options={colourOptions} */
                    />
                  </FormGroup>
                </Col>

                {createdOption && (
                  <>
                    <Col lg={3} xl={2}>
                      <FormGroup className="mx-2">
                        <Label>Mobile</Label>
                        <AvField
                          name="mobile"
                          type="number"
                          className="form-control"
                          value={attendanceData?.mobile}
                          onChange={(e) => handleModalChange(e)}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} xl={4}>
                      <FormGroup className="mx-2">
                        <Label>Address</Label>
                        <AvField
                          name="address"
                          type="textarea"
                          // style={{ resize: "none" }}
                          rows="1"
                          className="form-control"
                          value={attendanceData?.address}
                          onChange={(e) => handleModalChange(e)}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </>
                )}

                <Col lg={3} xl={2} className="d-flex align-items-center present-div">
                  <FormGroup className="mx-2 py-2 d-flex" style={{ gap: "1rem", marginBottom: "-5px" }}>
                    <span className="d-flex">
                      <Label className="mb-0 me-1">Present</Label>
                      <input
                        onClick={handleModalChange}
                        type="radio"
                        className="form-check-input"
                        name="att_type"
                        value={1}
                        checked={attendanceData?.att_type == 1 ? true : false}
                      />
                    </span>
                    <span className="d-flex">
                      <Label className="mb-0 me-1">Absent</Label>
                      <input
                        onClick={handleModalChange}
                        type="radio"
                        className="form-check-input"
                        name="att_type"
                        value={0}
                        checked={attendanceData?.att_type == 0 ? true : false}
                      />
                    </span>
                  </FormGroup>
                </Col>
                <Col lg={2} xl={1} className="modal-btn">
                  <button className="btn btn-primary">Submit</button>
                </Col>
              </Row>
            </AvForm>
          </Card>
        )}

        <Card className="mt-2">
          <CardBody>
            <MDBDataTable
              id="aimeeting-attendance"
              responsive
              // striped
              bordered
              data={dataAttendance}
              info={true}
              searching={true}
              disableRetreatAfterSorting={true}
              entries={20}
            />
          </CardBody>
        </Card>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="home" breadcrumbItem="AI-Meeting" />

          {props.options.edit && (
            <Row>
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}>
                    <Row>
                      <Col lg="3">
                        <FormGroup className="mx-2">
                          <Label>Date</Label>
                          <AvField
                            name="meeting_date"
                            type="date"
                            className="form-control"
                            value={masterObject?.meeting_date}
                            // onChange={(e) => setDate(e.target.value)}
                            onChange={handleChangeInput}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup className="mx-2">
                          <Label>Meeting name</Label>
                          <AvField
                            name="remarks"
                            placeholder="Meeting"
                            type="text"
                            className="form-control"
                            onChange={handleChangeInput}
                            value={masterObject?.remarks}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3" style={{ marginTop: "26px", paddingLeft: "1.2rem" }}>
                        {meetingIdToBeUpdated ? (
                          <Button color="primary" type="submit">
                            {"Update"}
                          </Button>
                        ) : (
                          <Button color="primary" type="submit">
                            {"Submit"}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Row>
          )}
          <Row>
            <h5 style={{ fontWeight: "500", fontSize: "1.10rem" }}>Meetings</h5>
            <Card className="mt-2">
              <CardBody>
                <MDBDataTable
                  id="aimeetingTableID"
                  responsive
                  // striped
                  bordered
                  data={data}
                  info={true}
                  searching={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AIMeeting;
