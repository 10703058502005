import { Col, FormGroup, Label, Row, TabPane } from "reactstrap";
import toastr from "toastr";
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
import { useEffect, useState } from "react";
import { addFamilyHistory, getQualifications } from "./api";
import "./style.css";

function FamilyHIstory(props) {
  let { details, editable } = props;
  let initialFamily = {
    family_details: [{}],
  };

  const [qualifications, setQualifications] = useState([]);
  const [familyHistory, setFamilyHistory] = useState(initialFamily);
  const [inCaseOfDeath, setIncaseOfDeath] = useState(false);

  useEffect(() => {
    getQualifications().then((data) => setQualifications(data));
  }, []);

  useEffect(() => {
    if (details !== null && typeof details !== "undefined") {
      details.family_details.map((data) => {
        data.selected = data?.qualification?._id
          ? {
              value: data?.qualification?._id || null,
              label: data?.qualification?.qualification_name || null,
            }
          : null;

        data.qualification = data?.qualification?._id || null;

        return data;
      });

      details.family_details = details.family_details.length === 0 ? (details.family_details = [{}]) : details.family_details;

      setIncaseOfDeath(details.parents_death);
      setFamilyHistory(details);
    }
  }, [details]);

  function addNewRow() {
    setFamilyHistory((preState) => {
      return {
        ...preState,
        family_details: [...preState.family_details, {}],
      };
    });
  }

  function removeRow(index) {
    let data = { ...familyHistory };
    data.family_details.splice(index, 1);
    setFamilyHistory(data);
  }

  function handleTableChange(e, index) {
    let { name, value } = e.target;
    let data = { ...familyHistory };
    data.family_details[index][name] = value;
    setFamilyHistory(data);
  }

  function handleChange(e) {
    let { name, value } = e.target;
    setFamilyHistory((preState) => {
      return {
        ...preState,
        [name]: value,
      };
    });
  }

  function handleSourceOfDeath(e) {
    let { name } = e.target;
    let value = e.target.checked;
    setFamilyHistory((preState) => {
      return {
        ...preState,
        [name]: value,
      };
    });
  }

  function handleSelect(e, name, index) {
    let data = { ...familyHistory };
    data.family_details[index][name] = e.value;
    data.family_details[index].selected = e;
    setFamilyHistory(data);
    // setQalSlct(e);
  }
  const handleFamilySubmit = (value) => {
    // if (familyHistory === null) {
    //   setError((prevValue) => {
    //     return {
    //       ...prevValue,
    //       qualification: true,
    //     };
    //   });
    //   return;
    // } else {
    //   setError((prevValue) => {
    //     return {
    //       ...prevValue,
    //       qualification: false,
    //     };
    //   });
    // }
    value.admission_id = props.id;
    addFamilyHistory(value)
      .then((data) => {
        if (data.status === 200) {
          toastr.success(data.message);
          props.handleToggleBar("increment");
        }
      })
      .catch((err) => {
        toastr.error(err.response.message);
      });
  };

  const handleKey = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38) {
      e.preventDefault();
    }
  };
  // const onlyString = (event) => {
  //   const key = event.key;
  //   const isLetter = /^[A-Za-z]$/.test(key);
  //   const isBackspace = key === "Backspace";
  //   if (!isLetter && !isBackspace) {
  //     event.preventDefault();
  //   }
  // };
  return (
    <TabPane tabId={1}>
      <div>
        <AvForm onValidSubmit={(e) => handleFamilySubmit(familyHistory)}>
          {familyHistory.family_details.map((data, index) => {
            return (
              <Row
                key={index}
                className={
                  familyHistory.family_details.length >= 2 && index !== 0
                    ? "shadow rounded p-2 mt-3 border_bottom"
                    : familyHistory.family_details.length === 1
                    ? "shadow rounded p-2"
                    : "shadow rounded p-2 border_bottom"
                }>
                <Col lg="4">
                  <FormGroup className="mb-3">
                    <Label htmlFor="name">Name</Label>
                    <AvField
                      disabled={!editable}
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Enter name"
                      value={data?.name}
                      onChange={(e) => handleTableChange(e, index)}
                      // validate={{
                      //   required: { value: true },
                      // }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup className="mb-3">
                    <Label htmlFor="relation">Relation</Label>
                    <AvField
                      disabled={!editable}
                      name="relation"
                      type="text"
                      className="form-control"
                      placeholder="Enter relation"
                      value={data?.relation}
                      onChange={(e) => handleTableChange(e, index)}
                      // validate={{
                      //   required: { value: true },
                      // }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <FormGroup className="mb-3">
                    <Label htmlFor="age">Age</Label>
                    <AvField
                      disabled={!editable}
                      name="age"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={handleKey}
                      className="form-control"
                      placeholder="Enter age"
                      value={data?.age}
                      onChange={(e) => handleTableChange(e, index)}
                      // validate={{
                      //   required: { value: true },
                      // }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="2">
                  <FormGroup className="mb-3">
                    <Label htmlFor="income">Income</Label>
                    <AvField
                      disabled={!editable}
                      name="income"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={handleKey}
                      className="form-control"
                      placeholder="Enter income"
                      value={data?.income}
                      onChange={(e) => handleTableChange(e, index)}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup className="mb-3">
                    <Label htmlFor="age">Education</Label>
                    <Select
                      isDisabled={!editable}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                        }),
                      }}
                      className="basic-single "
                      classNamePrefix="select"
                      // isClearable={true}
                      value={data?.selected}
                      name="qualification"
                      options={qualifications}
                      onChange={(e) => {
                        handleSelect(e, "qualification", index);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup className="mb-3">
                    <Label htmlFor="occupation">Occupation</Label>
                    <AvField
                      name="occupation"
                      disabled={!editable}
                      type="text"
                      className="form-control"
                      placeholder="Enter occupation"
                      value={data?.occupation}
                      onChange={(e) => handleTableChange(e, index)}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup className="mb-3">
                    <Label htmlFor="use-of-intoxicants">Use of intoxicants</Label>
                    <AvField
                      disabled={!editable}
                      name="intoxicants"
                      id="use-of-intoxicants"
                      type="text"
                      className="form-control"
                      placeholder="Enter intoxicants"
                      value={data?.intoxicants}
                      onChange={(e) => handleTableChange(e, index)}
                    />
                  </FormGroup>
                </Col>
                {editable && (
                  <Col md={12}>
                    {familyHistory.family_details.length !== 1 ? (
                      <h5 style={{ float: "right" }}>
                        <i onClick={() => removeRow(index)} className="trash-btn mdi mdi-delete"></i>
                      </h5>
                    ) : (
                      ""
                    )}
                  </Col>
                )}
              </Row>
            );
          })}
          {editable && (
            <Row>
              <Col md={12} className="m-1 p-1">
                <p className="new-row" onClick={addNewRow}>
                  Add More <span className="mdi mdi-plus-circle-outline"></span>
                </p>
              </Col>
            </Row>
          )}
          {/* //* Incase of death */}
          <Row>
            <Col md={12} className={editable ? "mb-3" : "mb-3 mt-4"}>
              <Label check for="checkbox" htmlFor="parents-death">
                In case of death of parents give reason for death
              </Label>
              &nbsp; &nbsp;
              <AvInput
                disabled={!editable}
                type="checkbox"
                name="parents_death"
                style={{ cursor: "pointer" }}
                id="parents-death"
                checked={inCaseOfDeath}
                onClick={(e) => {
                  setIncaseOfDeath((pre) => {
                    handleSourceOfDeath(e);
                    return !pre;
                  });
                }}
              />
            </Col>
          </Row>
          {inCaseOfDeath && (
            <Row className=" rounded p-3 ps-0">
              {/* //* Father name */}
              <Col lg="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="father_death_reason">Father death reason</Label>
                  <AvField
                    disabled={!editable}
                    name="father_death_reason"
                    type="text"
                    className="form-control"
                    placeholder="Enter father death reason"
                    value={familyHistory?.father_death_reason}
                    // onKeyDown={onlyString}
                    onChange={handleChange}
                    validate={
                      {
                        // required: { value: true },
                      }
                    }
                    // errorMessage="Enter mother name"
                  />
                </FormGroup>
              </Col>
              {/* //* Father age */}
              <Col lg="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="father_death_patient_age">How old were you at that time</Label>
                  <AvField
                    disabled={!editable}
                    name="father_death_patient_age"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={handleKey}
                    className="form-control"
                    placeholder="Enter age"
                    value={familyHistory.father_death_patient_age}
                    onChange={handleChange}
                    validate={
                      {
                        // required: { value: true },
                      }
                    }
                    // errorMessage="Enter fathername"
                  />
                </FormGroup>
              </Col>
              {/* //* mother name */}
              <Col lg="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="mother_death_reason">Mother death reason</Label>
                  <AvField
                    disabled={!editable}
                    name="mother_death_reason"
                    type="text"
                    className="form-control"
                    placeholder="Enter mother death reason"
                    value={familyHistory?.mother_death_reason}
                    // onKeyDown={onlyString}
                    onChange={handleChange}
                    validate={
                      {
                        // required: { value: true },
                      }
                    }
                    // errorMessage="Enter mother name"
                  />
                </FormGroup>
              </Col>
              {/* //* mother age */}
              <Col lg="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="mother_death_patient_age">How old were you at that time</Label>
                  <AvField
                    disabled={!editable}
                    name="mother_death_patient_age"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={handleKey}
                    className="form-control"
                    placeholder="Enter age"
                    value={familyHistory?.mother_death_patient_age}
                    onChange={handleChange}
                    validate={
                      {
                        // required: { value: true },
                      }
                    }
                    // errorMessage="Enter fathername"
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col md={12} className="submit-btn-fixed">
              <FormGroup className="float-right ">
                <span className="save-continue-btn" onClick={() => props.handleToggleBar("decrement")}>
                  <i class="fas fa-solid fa-less-than"></i>
                </span>
                {/* <input
                  className="save-continue-btn"
                  type="submit"
                  value={"Save and continue"}
                /> */}
                {editable && (
                  <button type="submit" className="save-continue-btn m-2">
                    Save and continue
                    <i style={{ fontSize: "13px" }} className="mdi mdi-arrow-right"></i>
                  </button>
                )}
                <span className="save-continue-btn" onClick={() => props.handleToggleBar("increment")}>
                  <i class="fas fa-solid fa-greater-than"></i>
                </span>
              </FormGroup>
            </Col>
          </Row>
        </AvForm>
      </div>
    </TabPane>
  );
}

export default FamilyHIstory;
