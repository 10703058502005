import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

export let getTemperature = (date, keyword, page, limit) =>
  axios
    .get(`${API_URL}temp-bp-chart?date=${date}&keyword=${keyword || ""}&page=${page}&limit=${limit}`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data.data)
    .catch((err) => console.error(err));

export let addTemperature = (data) =>
  axios
    .post(`${API_URL}temp-bp-chart`, data, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data)
    .catch((err) => console.error(err));

export let deleteTempBp = (data) =>
  axios
    .delete(
      `${API_URL}temp-bp-chart`,
      { data: data },
      {
        headers: { "x-access-token": accessToken },
      }
    )
    .then((res) => res.data)
    .catch((err) => console.error(err));
