import { GET_PATIENTS_OPTIONS_SUCCESS, GET_PATIENTS_OPTIONS_FAIL } from "./actionTypes";

const INIT_STATE = {};

const patients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PATIENTS_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.payload,
      };

    case GET_PATIENTS_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default patients;
