/* eslint-disable */
import React, { useState, useEffect } from "react";
// import { useDispatch,  } from "react-redux";
// import { MDBDataTable } from "mdbreact";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import Save from "@mui/icons-material/Save";
// import Resete from "@mui/icons-material/ResetTvRounded";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormLabel from "@mui/material/FormLabel";
// import FormControl from "@mui/material/FormControl";/
// import Checkbox from "@mui/material/Checkbox";
// import { Link } from "react-router-dom";
import defaultProfile from "../../../assets/images/person-icon.png";
import {
  Row,
  // Col,
  // Card,
  // CardBody,
  // CardTitle,
  // CardSubtitle,
  // Button,
  // Label,
  // CardHeader,
} from "reactstrap";

// import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Staff.scss";
// import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
// import IconButton from "@mui/material/IconButton";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

// import { getUsers } from "../../../store/actions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody, getCompany } from "../../../helpers/globalFunctions";

const DatatableTables = () => {
  const [currentLocalbody, ] = useState(getLocalbody());
  const [currentCompany, ] = useState(getCompany());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();
  // const visitlogdata = {
  //   columns: [
  //     {
  //       label: "#",
  //       field: "id",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Privilage Name",
  //       field: "name",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Privilage Shortcode",
  //       field: "pcode",
  //       sort: "asc",
  //       width: 270,
  //     },
  //     {
  //       label: "Company Name",
  //       field: "cname",
  //       sort: "asc",
  //       width: 150,
  //     },
      
  //     {
  //       label: "Action",
  //       field: "action",
  //       sort: "asc",
  //       width: 200,
  //     },
  //   ],
  //   rows: [
  //     {
  //       si: "1",
  //       name: "Admin",
  //       pcode: "ADM",
  //       office: "Edinburgh",
  //       age: "61",
  //       date: "2011/04/25",
  //       salary: "$320",
  //       cname: (
  //         <>
  //           <FormControl component="fieldset">
  //             <FormGroup aria-label="position" row>
  //               <FormControlLabel
  //                 style={{ marginRight: "15%" }}
  //                 value="end"
  //                 control={<Checkbox />}
  //                 label="Mavoor road - Calicut"
  //                 labelPlacement="end"
  //               />
  //               <FormControlLabel
  //                 value="end"
  //                 control={<Checkbox />}
  //                 label="City Center - Thrissur"
  //                 labelPlacement="end"
  //               />
  //               <FormControlLabel
  //                 style={{ marginRight: "15%" }}
  //                 value="end"
  //                 control={<Checkbox />}
  //                 label="Kottakal - Malapuram"
  //                 labelPlacement="end"
  //               />
  //               <FormControlLabel
  //                 value="end"
  //                 control={<Checkbox />}
  //                 label="Kochi"
  //                 labelPlacement="end"
  //               />
  //               <Grid item lg="5"></Grid>
  //               <Row style={{ marginLeft: "-237px" }}>
  //                 <Col md="3">
  //                   <FormControlLabel
  //                     className="check-fields"
  //                     value="end"
  //                     control={<Checkbox />}
  //                     label="Australia"
  //                     labelPlacement="end"
  //                   />
  //                 </Col>
  //               </Row>
  //             </FormGroup>
  //           </FormControl>
  //         </>
  //       ),
  //       action: (
  //         <>
  //           {" "}
  //           <EditIcon />
  //           <DeleteIcon />
  //         </>
  //       ),
  //       action: (
  //         <>
  //           {" "}
  //           <EditIcon />
  //           <DeleteIcon />
  //         </>
  //       ),
  //     },
  //   ],
  // };
  const [tableData, setTableData] = useState([]);
  // const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getUsers());
    handleTableData();
    // eslint-disable-next-line
  }, [currentLocalbody]);
  function handleTableData() {
    var url =
      `${API_URL}addressbook?localbody_id=` +
      currentLocalbody +
      "&company_id=" +
      currentCompany;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          // item.localbody_name =  getLocalbody();
          // item.type = item?.cust_type;
          // item.district_name = item?.district;
          // item.ward_name = item?.ward;

          // item.image = (
          //   <div style={{ display: "flex", justifyContent: "center" }}>
          //     <img
          //       src={`${API_URL}public/uploads${item.popup_image}`}
          //       height="160"
          //       width="120"
          //     />
          //   </div>
          // );
          return item
        });

        setTableData(result);
      });
  }
  // const handleClickOpenProfile = (item) => {
  //   // dispatch(users(item._id));
  // };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Address Book" />
          <Row>
            {tableData?.map((item) => (
              <div className="col-sm-6 col-xl-3">
                <div
                  className="text-center card"
                  style={{ borderRadius: "5px" }}
                >
                  <div className="card-body">
                    <div className="float-end dropdown">
                      
                      <a
                        aria-haspopup="true"
                        className="text-body font-size-16 dropdown-toggle"
                        aria-expanded="false"
                      >
                        {/* <i className="uil uil-ellipsis-h"></i> */}
                      </a>
                      <div
                        tabindex="-1"
                        role="menu"
                        aria-hidden="true"
                        className="dropdown-menu-end dropdown-menu"
                      >
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Edit
                        </a>
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Action
                        </a>
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Remove
                        </a>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    {item?.user_image ? (
                      <div className="mb-4">
                        <img
                          className="avatar-lg rounded-circle img-thumbnail"
                          src={`${API_URL}uploads/user_images/${item?.user_image}`}
                          alt="/"
                        />
                      </div>
                    ) : (
                      <div className="mb-4">
                        <img
                          className="avatar-lg rounded-circle img-thumbnail"
                          src={defaultProfile}
                          alt="/"
                        />
                      </div>
                    )}
                    <h5 className="font-size-16 mb-1">
                      <a className="text-dark">
                        {item.firstName} &nbsp;
                        {item.lastName}
                      </a>
                    </h5>
                    <p className="text-muted mb-2">{item.email}</p>
                  </div>
                  <div className="btn-group" role="group">
                    {/* <Link to={{ pathname: "/staff1", state: { userId: item } }}> */}
                      <a
                        type="button"
                        className="text-truncate btn btn-btn-outline-light"
                        style={{textAlign:"left"}}
                        onClick={() => {
                          history.push("/staff/" + item._id);
                          // window.location.assign("/staff/" + item._id);
                        }}
                      >
                        <i className="uil uil-user me-1"></i>
                        Profile
                      </a>
                    {/* </Link> */}
                    <button
                      type="button"
                      className="text-truncate btn btn-btn-outline-light"
                    >
                      {/* <i classname="uil uil-envelope-alt me-1"></i>
                      Message */}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default DatatableTables;
