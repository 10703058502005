import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

export let getMedicineChecklist = (date, id) =>
  new Promise(async (resolve, reject) => {
    try {
      if (id === undefined) {
        return resolve([]);
      }
      let response = await axios.get(
        `${API_URL}medicine-checklist?date=${date}&admission_id=${id}`,
        {
          headers: { "x-access-token": accessToken },
        }
      );
      resolve(response.data.data[0]);
    } catch (error) {
      reject(error);
    }
  });

export let getHistory = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      if (id === undefined) {
        return resolve([]);
      }
      let response = await axios.get(
        `${API_URL}medicine-checklist/history?admission_id=${id}`,
        {
          headers: { "x-access-token": accessToken },
        }
      );
      resolve(response.data.data);
    } catch (error) {
      reject(error);
    }
  });
