/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import jwt_decode from "jwt-decode";
import toastr from "toastr";
import axios from "axios";
import $ from "jquery";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import {
  apiError,
  getIncomeExpenseAccType,
  getAllFundSource,
  // addIncomeExpense,
  // getAllIncomeExpenses,
  // getUsers,
} from "../../../store/actions";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getDateTime, getDate, getFirstday, getLocalbody } from "../../../helpers/globalFunctions";
import moment from "moment";
import "./incomeExpenses.scss";
import { ObjectId } from "../../../helpers/globalVariables";
const IncomeExpense = (props) => {
  const [incomeExpenseList, setIncomeExpenseList] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [accountDetailType, setAccountDetailType] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedAccountDetailType, setSelectedAccountDetailType] = useState(null);
  const [chartOfAccount, setChartOfAccount] = useState([]);
  const [uploadProgress, setUploadProgress] = useState();
  const [selectedChartOfAccount, setSelectedChartOfAccont] = useState(null);
  const [fundSourceOptions, setFundSourceOptions] = useState([]);
  const [selectedFundSource, setSelectedFundSource] = useState(null);
  const [master, setMaster] = useState({
    income_expense_date: getDate(new Date()),
    income_expense_time: moment().format("HH:mm:ss"),
    localbody_id: getLocalbody(),
  });
  const [userId, setUserId] = useState(1);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [selectedChartOfAccForSearch, setSelectedChartOfAccForSearch] = useState(null);
  const [accDetailTypeForSearch, setAccDetailTypeForSearch] = useState(null);
  const [accTypeForSearch, setAccTypeForSearch] = useState(null);
  const [accountDetailTypeSearch, setAccountDetailTypeSearch] = useState([]);
  const [chartOfAccountSearch, setChartOfAccountSearch] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [patientOptions, setPatientOptions] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedTransactionMode, setSelectedTransactionMode] = useState(null);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);

  const [btnDisable, setBtnDisable] = useState(false);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const dispatch = useDispatch();
  const formRef = useRef();

  const { incomeExpenseAccTypes, incomeExpenses, addIncomeExpenseResponse, addingIncomeExpense, error } = useSelector((state) => state.incomeExpenses);

  const loggerUser = JSON.parse(localStorage.getItem("authUser"));
  const [isAdmin, setIsAdmin] = useState(loggerUser.privilage === ObjectId.ADMIN || false);
  const [isEdit, setIsEdit] = useState(true);

  // isAdmin ? false : !selectedPatient?.editable
  // console.log(isEdit);

  useEffect(() => {
    dispatch(getIncomeExpenseAccType());
    dispatch(getAllFundSource(currentLocalbody));
    // dispatch(getAllIncomeExpenses(currentLocalbody));
    handleTableData();
    // dispatch(getUsers());
    fetch_all_staff(currentLocalbody);
    fetch_all_patients();
  }, [currentLocalbody]);

  const { fundSource } = useSelector((state) => state.fundTransfers);
  // const { users } = useSelector((state) => state.users);
  useEffect(() => {
    var data =
      incomeExpenseAccTypes &&
      incomeExpenseAccTypes.map((item) => {
        return {
          label: item.acc_type_name,
          value: item._id,
        };
      });

    setAccountType([
      {
        options: data,
      },
    ]);
  }, [incomeExpenseAccTypes]);

  useEffect(() => {
    var data =
      fundSource &&
      fundSource.map((item) => {
        return {
          label: item.acc_chart_name,
          value: item._id,
        };
      });

    setFundSourceOptions([
      {
        options: data,
      },
    ]);
  }, [fundSource]);

  // useEffect(() => {
  //   var data =
  //     users &&
  //     users.map((user) => {
  //       return {
  //         label: user.firstName,
  //         value: user._id,
  //       };
  //     });

  //   setUser([
  //     {
  //       options: data,
  //     },
  //   ]);
  // }, [users]);

  // useEffect(() => {
  //   let incomeExpenseData = JSON.parse(JSON.stringify(incomeExpenses));

  //   let data = [];
  //   incomeExpenseData.map((item, index) => {
  //     item.auth_userid = userId;
  //     item.id = index + 1;
  //     item.income_expense_amount1 = item.income_expense_amount;
  //     item.income_expense_total1 = item.income_expense_total;
  //     item.income_expense_date1 = moment(item.income_expense_date).format(
  //       "DD-MM-YYYY"
  //     );
  //     if (item.income_expense_approve_status === 0) {
  //       item.status = (
  //         <div style={{ display: "flex", justifyContent: "center" }}>
  //           <Button
  //             color="primary"
  //             size="sm"
  //             onClick={() => {
  //               updateApproveStatus(item, 1);
  //             }}
  //           >
  //             Pending
  //           </Button>
  //         </div>
  //       );
  //     } else if (item.income_expense_approve_status === 1) {
  //       item.status = (
  //         <div style={{ display: "flex", justifyContent: "center" }}>
  //           <Button color="success" size="sm" disabled>
  //             Approved
  //           </Button>
  //         </div>
  //       );
  //     } else if (item.income_expense_approve_status === 2) {
  //       item.status = (
  //         <div style={{ display: "flex", justifyContent: "center" }}>
  //           <Button color="danger" size="sm" disabled>
  //             Rejected
  //           </Button>
  //         </div>
  //       );
  //     }
  //     data.push(item);
  //   });
  //   setIncomeExpenseList(data);
  // }, [incomeExpenses]);

  const updateApproveStatus = (item, selected) => {
    let user_id = "";

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      user_id = obj._id;
    }

    let data = {
      user_id: user_id,
      localbody_id: currentLocalbody,
      status: selected?.value,
      id: item?._id,
    };
    axios
      .post(`${API_URL}api/staff/approve_expense`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success(res.data.message);

          let date1 = searchData?.from_date ? searchData.from_date : "";
          let date2 = searchData?.to_date ? searchData.to_date : "";
          let acc_type = searchData?.income_expense_head ? searchData.income_expense_head : "";
          let acc_detail = searchData?.income_expense_subhead ? searchData.income_expense_subhead : "";
          let user_id = searchData?.income_expense_staff ? searchData.income_expense_staff : "";
          let acc_chart = searchData?.income_expense_chartofaccounts ? searchData.income_expense_chartofaccounts : "";

          handleTableData(date1, date2, acc_type, acc_detail, acc_chart, user_id);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  useEffect(() => {
    if (addIncomeExpenseResponse.type === "success") {
      toastr.success(addIncomeExpenseResponse.message);
      // dispatch(getAllIncomeExpenses(currentLocalbody));
      handleTableData();
    } else if (addIncomeExpenseResponse.type === "failure") {
      toastr.error(error.data.message, addIncomeExpenseResponse.message);
    }
  }, [addIncomeExpenseResponse]);

  const handleSelectedAccDetailType = (selected) => {
    setSelectedAccountDetailType(selected);
    setSelectedChartOfAccont(null);
    setMaster({
      ...master,
      ["income_expense_subhead"]: selected.value,
    });
    // getChartOfAccount(selected.value, "fromAccDetailType");
  };
  const handleSelectedAccType = (selected) => {
    setSelectedAccountType(selected);
    setSelectedAccountDetailType(null);
    setSelectedChartOfAccont(null);
    setChartOfAccount([]);
    setMaster({
      ...master,
      ["income_expense_head"]: selected.value,
    });
    getChartOfAccount(selected.value, "fromAccType");
    getDetailType(selected.value, "fromAccType");
  };
  const getDetailType = (type, name) => {
    var url = `${API_URL}account_detail/options` + "?acc_type_id=" + type;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        let data =
          result &&
          result.map((item) => {
            return {
              label: item.acc_detail_name,
              value: item._id,
            };
          });
        if (name === "fromAccType") {
          setAccountDetailType([
            {
              options: data,
            },
          ]);
        } else {
          setAccountDetailTypeSearch([
            {
              options: data,
            },
          ]);
        }
      });
  };
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setUser([
          {
            options: staff_data,
          },
        ]);
      });
  };
  const getChartOfAccount = (type, name) => {
    var url = `${API_URL}chart_of_account/options?acc_type_id=` + type;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let data =
          result &&
          result.map((item) => {
            return {
              label: item.acc_chart_name,
              value: item._id,
            };
          });
        if (name === "fromAccType") {
          setChartOfAccount([
            {
              options: data,
            },
          ]);
        } else {
          setChartOfAccountSearch([
            {
              options: data,
            },
          ]);
        }
      });
  };

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: `${el?.fname || ""}  ${el?.lname || ""}  ${el?.reg_no ? `- ${el?.reg_no}` : "" || ""}`,
              value: el?._id,
              editable: el?.editable,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  console.log(patientOptions);

  const handleSelectedChartOfAccount = (selected) => {
    setSelectedChartOfAccont(selected);
    setMaster({
      ...master,
      ["income_expense_chartofaccounts"]: selected.value,
    });
  };

  const handleSelectedChange = (selected, type) => {
    switch (type) {
      case "admission_id":
        setSelectedPatient(selected);
        setMaster({
          ...master,
          ["admission_id"]: selected.value,
        });

        setIsEdit(selected?.editable);

        break;

      case "transaction_mode":
        setSelectedTransactionMode(selected);
        setMaster({
          ...master,
          ["transaction_mode"]: selected.value,
        });

      default:
        break;
    }
  };
  const handleSelectedFundSource = (selected) => {
    setSelectedFundSource(selected);
    setMaster({
      ...master,
      ["income_expense_fundsource"]: selected.value,
    });
  };

  const handleDate = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeInput = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const onAmountChange = (e) => {
    let amount = Number(e.target.value);
    let tax = Number(master?.income_expense_gst ? master.income_expense_gst : 0);
    let Total = amount + parseFloat((amount * tax) / 100);
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
      ["income_expense_total"]: Total,
    });
  };
  const onGstChange = (e) => {
    let amount = Number(master?.income_expense_amount ? master.income_expense_amount : 0);
    let tax = Number(e.target.value);

    let Total = amount + parseFloat((amount * tax) / 100);
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
      ["income_expense_total"]: Total,
    });
  };
  const handleValidSubmit = (e, v) => {
    if (selectedAccountType === null) {
      toastr.error("Account type is required");
      return;
    }
    // if (selectedAccountDetailType === null) {
    //   toastr.error("Account detail type is required");
    //   return;
    // }
    if (selectedPatient === null) {
      toastr.error("Patient is required");
      return;
    }
    if (selectedChartOfAccount === null) {
      toastr.error("Chart of account is required");
      return;
    }
    // if (selectedFundSource === null) {
    //   toastr.error("Fund source is required");
    //   return;
    // }

    setBtnDisable(true);

    axios
      .post(`${API_URL}income_expense`, master, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Income expense added successfully");
          if (res.data.message) {
            toastr.warning(res.data.message);
          }
          formRef.current.reset();
          setSelectedFundSource(null);
          setSelectedChartOfAccont(null);
          setSelectedAccountType(null);
          setSelectedPatient(null);
          setSelectedAccountDetailType(null);
          setSelectedTransactionMode(null);
          setMaster({
            ["auth_userid"]: userId,
            ["income_expense_date"]: getDate(new Date()),
            ["income_expense_time"]: moment(getDateTime(new Date())).format("HH:mm:ss"),
            ["localbody_id"]: getLocalbody(),
          });
          // dispatch(getAllIncomeExpenses(currentLocalbody));
          handleTableData();
        } else {
          toastr.error("Failed to add income expense");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      })
      .finally(() => {
        setBtnDisable(false);
      });

    // dispatch(addIncomeExpense(master));
    // formRef.current.reset();
    // setSelectedFundSource(null);
    // setSelectedChartOfAccont(null);
    // setSelectedAccountType(null);
    // setSelectedAccountDetailType(null);
    // setMaster({
    //   ["auth_userid"]: userId,
    //   ["income_expense_date"]: getDate(new Date()),
    //   ["income_expense_time"]: moment(getDateTime(new Date())).format(
    //     "HH:mm:ss"
    //   ),
    // });
  };
  const reset = () => {
    setMaster({
      ["auth_userid"]: userId,
      ["income_expense_date"]: "",
      ["income_expense_time"]: "",
      ["localbody_id"]: getLocalbody(),
    });
    formRef.current.reset();
    setSelectedFundSource(null);
    setSelectedChartOfAccont(null);
    setSelectedAccountType(null);
    setSelectedAccountDetailType(null);
    setSelectedTransactionMode(null);
    setSelectedPatient(null);
  };

  const handleSelectedForSearch = (selected, name) => {
    // let date1 = searchData?.from_date ? searchData.from_date : "";
    // let date2 = searchData?.to_date ? searchData.to_date : "";
    // let acc_type = searchData?.income_expense_head
    //   ? searchData.income_expense_head
    //   : "";
    // let acc_detail = searchData?.income_expense_subhead
    //   ? searchData.income_expense_subhead
    //   : "";
    // let user_id = searchData?.income_expense_staff
    //   ? searchData.income_expense_staff
    //   : "";
    // let acc_chart = searchData?.income_expense_chartofaccounts
    //   ? searchData.income_expense_chartofaccounts
    //   : "";
    switch (name) {
      case "chart":
        setSelectedChartOfAccForSearch(selected);
        setSearchData({
          ...searchData,
          ["income_expense_chartofaccounts"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   acc_type,
        //   acc_detail,
        //   selected.value,
        //   user_id
        // );
        break;
      case "accDetailType":
        setAccDetailTypeForSearch(selected);
        setSelectedChartOfAccForSearch(null);
        setSearchData({
          ...searchData,
          ["income_expense_subhead"]: selected.value,
          ["income_expense_chartofaccounts"]: "",
        });
        getChartOfAccount(selected.value, "fromAccDetailTypeSearch");
        // handleTableData(date1, date2, acc_type, selected.value, "", user_id);
        break;
      case "accType":
        setAccTypeForSearch(selected);
        setAccDetailTypeForSearch(null);
        setSelectedChartOfAccForSearch(null);
        setChartOfAccountSearch([]);
        setSearchData({
          ...searchData,
          ["income_expense_head"]: selected.value,
          ["income_expense_subhead"]: "",
          ["income_expense_chartofaccounts"]: "",
        });
        getDetailType(selected.value, "fromAccTypeSearch");
        // handleTableData(date1, date2, selected.value, "", "", user_id);
        break;
      case "staff":
        setSelectedUser(selected);
        setSearchData({
          ...searchData,
          ["income_expense_staff"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   acc_type,
        //   acc_detail,
        //   acc_chart,
        //   selected.value
        // );
        break;
      default:
        break;
    }
  };
  const handleDateSearch = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    // let acc_type = searchData?.income_expense_head
    //   ? searchData.income_expense_head
    //   : "";
    // let acc_detail = searchData?.income_expense_subhead
    //   ? searchData.income_expense_subhead
    //   : "";
    // let user_id = searchData?.income_expense_staff
    //   ? searchData.income_expense_staff
    //   : "";
    // let acc_chart = searchData?.income_expense_chartofaccounts
    //   ? searchData.income_expense_chartofaccounts
    //   : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

    // setSearchData({
    //   ...searchData,
    //   [e.target.name]: e.target.value,
    // });

    // handleTableData(date1, date2, acc_type, acc_detail, acc_chart, user_id);
  };
  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}income_expense/view?incomeexpense_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data[0];
        // let data = {};
        // data.date = moment(result?.date).format("DD-MM-YYYY");
        // data.c_date = moment(result?.c_date).format("DD-MM-YYYY");
        // data.name =
        //   item?.admission_id?.basic_details?.basic_id?.fname +
        //   " " +
        //   item?.admission_id?.basic_details?.basic_id?.lname;
        // data.duration = result?.duration;
        // data.time = moment(item?.time, "hhmmss").format("hh:mm a");
        // if (result?.type == 0) {
        //   data.type = "I";
        // } else {
        //   data.type = "F";
        // }
        // data.issues = result?.issues;
        // data.staff =
        //   result?.addedBy?.firstName + " " + result?.addedBy?.lastName;

        setPopupData(result);
        setPopupView(true);
      });
  };
  const handleTableData = () =>
    // from_date = "",
    // to_date = "",
    // acc_type = "",
    // acc_detail = "",
    // acc_chart = "",
    // user_id = ""
    {
      var url = `${API_URL}income_expense`;

      // if (from_date === "" && to_date === "") {
      //   from_date = from_date ? from_date : getFirstday(new Date());
      //   to_date = to_date ? to_date : getDate(new Date());
      // }

      // url =
      //   url +
      //   "&from_date=" +
      //   from_date +
      //   "&to_date=" +
      //   to_date +
      //   "&acc_type=" +
      //   acc_type +
      //   "&acc_detail=" +
      //   acc_detail +
      //   "&acc_chart=" +
      //   acc_chart +
      //   "&user_id=" +
      //   user_id;

      axios
        .get(url, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          let result = res.data.data;
          let Data = [];
          result.map((item, index) => {
            item.id = index + 1;
            item.income_expense_amount1 = item.income_expense_amount;
            item.income_expense_total1 = item.income_expense_total;
            item.patient = item?.fname + " " + item?.lname;
            item.income_expense_date1 = moment(item.income_expense_date).format("DD-MM-YYYY");

            let selectedStatus = {};
            if (item.income_expense_approve_status === 0) {
              selectedStatus = {
                label: "Pending",
                value: 0,
              };
            } else if (item.income_expense_approve_status === 1) {
              selectedStatus = {
                label: "Approved",
                value: 1,
              };
            } else if (item.income_expense_approve_status === 2) {
              selectedStatus = { label: "Rejected", value: 2 };
            } else selectedStatus = null;

            if (item.income_expense_approve_status === 0) {
              item.status = (
                <div className="mb-3">
                  <Select
                    styles={style}
                    name="income_expense_approve_status"
                    value={selectedStatus}
                    onChange={(selected) => {
                      updateApproveStatus(item, selected);
                    }}
                    options={[
                      // {
                      //   label: "Pending",
                      //   value: 0,
                      // },
                      {
                        label: "Approved",
                        value: 1,
                      },
                      {
                        label: "Rejected",
                        value: 2,
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              );
            } else {
              item.status = (
                <div className="mb-3">
                  <Select
                    styles={style}
                    name="income_expense_approve_status"
                    value={selectedStatus}
                    onChange={(selected) => {
                      // updateStatus(selected, item);
                    }}
                    options={[]}
                    classNamePrefix="select2-selection"
                    isDisabled={true}
                  />
                </div>
              );
            }

            item.action = (
              <>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}></i>
              </>
            );

            // if (item.income_expense_approve_status === 0) {
            //   item.status = (
            //     <div style={{ display: "flex", justifyContent: "center" }}>
            //       <Button
            //         color="primary"
            //         size="sm"
            //         onClick={() => {
            //           updateApproveStatus(item, 1);
            //         }}
            //       >
            //         Pending
            //       </Button>
            //     </div>
            //   );
            // } else if (item.income_expense_approve_status === 1) {
            //   item.status = (
            //     <div style={{ display: "flex", justifyContent: "center" }}>
            //       <Button color="success" size="sm" disabled>
            //         Approved
            //       </Button>
            //     </div>
            //   );
            // } else if (item.income_expense_approve_status === 2) {
            //   item.status = (
            //     <div style={{ display: "flex", justifyContent: "center" }}>
            //       <Button color="danger" size="sm" disabled>
            //         Rejected
            //       </Button>
            //     </div>
            //   );
            // }

            Data.push(item);
          });
          setIncomeExpenseList(Data);
        });
    };
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "income_expense_date1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Head",
        field: "acc_type_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sub Head",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Chart of Account",
      //   field: "acc_chart_name",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Patient",
        field: "patient",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "income_expense_amount1",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Total",
      //   field: "income_expense_total1",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Comment",
        field: "income_expense_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "added_user",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: incomeExpenseList,
    //   rows:[{
    //     id:1,
    //     income_expense_date1:"04/01/2022",
    //     acc_type_name:"Income",
    //     acc_detail_name:"Receipt",
    //     acc_chart_name:"Waste Collection",
    //     fund_source:"Sanila V",
    //     income_expense_amount1:"100.00",
    //     income_expense_total1:"100.00",
    //     income_expense_comment:"Receipt amount",
    //     added_user:"Sanila"
    //   },
    //   {
    //     id:2,
    //     income_expense_date1:"04/01/2022",
    //     acc_type_name:"Income",
    //     acc_detail_name:"Receipt",
    //     acc_chart_name:"Waste Collection",
    //     fund_source:"Subisha K",
    //     income_expense_amount1:"50.00",
    //     income_expense_total1:"50.00",
    //     income_expense_comment:"Receipt amount",
    //     added_user:"Subisha"
    //   }
    // ]
  };
  const searchReset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setAccTypeForSearch(null);
    setAccDetailTypeForSearch(null);
    setChartOfAccountSearch(null);
    setSelectedUser(null);
    handleTableData();
    setSelectedTransactionMode(null);
    setSelectedPatient(null);
  };
  const handleSearch = () => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let acc_type = searchData?.income_expense_head ? searchData.income_expense_head : "";
    let acc_detail = searchData?.income_expense_subhead ? searchData.income_expense_subhead : "";
    let user_id = searchData?.income_expense_staff ? searchData.income_expense_staff : "";
    let acc_chart = searchData?.income_expense_chartofaccounts ? searchData.income_expense_chartofaccounts : "";

    handleTableData(date1, date2, acc_type, acc_detail, acc_chart, user_id);
  };
  const uploadDocument = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}income_expense/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setMaster({
            ...master,
            [`income_expense_document`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const deleteUserDocument = () => {
    setMaster({
      ...master,
      [`income_expense_document`]: "",
    });
  };
  return (
    <React.Fragment>
      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Income Expense Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table id="product_available_day" className="table table-bordered dataTable">
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>Patient Name :</td>
                <td>{popupData?.fname + " " + popupData?.lname}</td>
                <td style={{ paddingLeft: "25px" }}>Date :</td>
                <td style={{ textAlign: "left" }}>{moment(popupData?.income_expense_date).format("DD-MM-YYYY")}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Account Head :</td>
                <td>{popupData?.acc_type_name}</td>
                <td style={{ paddingLeft: "25px" }}>Account Sub Head :</td>
                <td style={{ textAlign: "left" }}>{popupData?.acc_detail_name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Chart Of Account :</td>
                <td>{popupData?.acc_chart_name}</td>
                <td style={{ paddingLeft: "25px" }}>Amount :</td>
                <td style={{ textAlign: "left" }}>{popupData?.income_expense_amount}</td>
              </tr>
              <tr colSpan={4}>
                <td style={{ padding: "10px" }}>Comment :</td>
                <td>{popupData?.income_expense_comment}</td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Income/Expense" />
          {props.options.edit && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}>
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Date</Label>
                            {/* <div className="col-md-10"> */}
                            <input
                              className="form-control"
                              type="date"
                              id="income_expense_date"
                              name="income_expense_date"
                              //defaultValue={master?.income_expense_date}
                              value={master?.income_expense_date}
                              onChange={handleDate}
                            />
                            {/* </div> */}
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Patients</Label>
                            <Select
                              name="admission_id"
                              value={selectedPatient}
                              onChange={(selected) => {
                                handleSelectedChange(selected, "admission_id");
                              }}
                              options={patientOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        {/* <Col md="3">
              <div className="mb-3">
                <Label htmlFor="validationCustom05">Time</Label>
                <AvField
                  name="income_expense_time"
                  placeholder="Time"
                  type="time"
                  errorMessage="Enter Time"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="validationCustom05"
                  value={master?.income_expense_time}
                  onChange={handleChangeInput}
                />
              </div>
            </Col> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Account Head</Label>
                            <Select
                              name="acc_type_id"
                              value={selectedAccountType}
                              onChange={(selected) => {
                                handleSelectedAccType(selected);
                              }}
                              options={accountType}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        {/* <Col md="3">
              <div className="mb-3">
                <Label> Account Category</Label>
                <Select
                  name="acc_detail_id"
                  value={selectedAccountDetailType}
                  onChange={(selected) => {
                    handleSelectedAccDetailType(selected);
                  }}
                  options={accountDetailType}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Sub Head</Label>
                            <Select
                              name="acc_chart_id"
                              value={selectedChartOfAccount}
                              onChange={(selected) => {
                                handleSelectedChartOfAccount(selected);
                              }}
                              options={chartOfAccount}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Amount</Label>
                            <AvField
                              name="income_expense_amount"
                              placeholder="Amount"
                              type="number"
                              min="0"
                              errorMessage="Enter Amount"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={master?.income_expense_amount}
                              //onChange={onAmountChange}
                              onChange={handleChangeInput}
                              disabled={isAdmin ? false : !isEdit}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Transaction Mode</Label>
                            <Select
                              name="transaction_mode"
                              value={selectedTransactionMode}
                              onChange={(selected) => {
                                handleSelectedChange(selected, "transaction_mode");
                              }}
                              options={[
                                { label: "Cash", value: 0 },
                                { label: "Bank Transfer", value: 1 },
                                { label: "Card", value: 2 },
                                { label: "UPI", value: 3 },
                              ]}
                              classNamePrefix="select2-selection"
                              isDisabled={isAdmin ? false : !isEdit}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Bill No</Label>
                            <AvField
                              name="bill_no"
                              placeholder="Bill No"
                              type="numtextber"
                              min="0"
                              // errorMessage="Enter GST"
                              // className="form-control"
                              // validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={master?.income_expense_gst}
                              // onChange={onGstChange}
                              onChange={handleChangeInput}
                              disabled={isAdmin ? false : !isEdit}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Comments</Label>
                            <AvField
                              name="income_expense_comment"
                              placeholder="Comments"
                              type="textarea"
                              // errorMessage="Enter Comments"
                              className="form-control"
                              //  validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={master?.income_expense_comment}
                              onChange={handleChangeInput}
                              rows={1}
                              disabled={isAdmin ? false : !isEdit}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-0">
                            <Label htmlFor="validationCustom03">Image</Label>
                            {master?.income_expense_document ? (
                              <div div className="img-wraps">
                                {master.income_expense_document && master.income_expense_document.startsWith("http") ? (
                                  <img alt="" width="150" height="150" src={`${master.income_expense_document}`} />
                                ) : (
                                  <img alt="" width="150" height="150" src={`${API_URL}uploads/Bill_images/${master.income_expense_document}`} />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteUserDocument}
                                  disabled={isAdmin ? false : !isEdit}
                                  style={{ width: "150px" }}>
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="user_documents"
                                disabled={isAdmin ? false : !isEdit}
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadDocument}
                                rows="1"
                              />
                            )}
                          </div>
                        </Col>

                        <Col md="2">
                          {isAdmin ? (
                            <>
                              <div>
                                <Button className="me-2" color="primary" type="submit" disabled={btnDisable ? true : false} style={{ marginTop: "25px" }}>
                                  {btnDisable ? "Adding" : "Submit"}
                                </Button>
                                <Button color="danger" type="reset" onClick={() => reset()} style={{ marginTop: "25px" }}>
                                  Reset
                                </Button>
                              </div>
                            </>
                          ) : isEdit ? (
                            <>
                              <div>
                                <Button className="me-2" color="primary" type="submit" disabled={btnDisable ? true : false} style={{ marginTop: "25px" }}>
                                  {btnDisable ? "Adding" : "Submit"}
                                </Button>
                                <Button color="danger" type="reset" onClick={() => reset()} style={{ marginTop: "25px" }}>
                                  Reset
                                </Button>
                              </div>
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="incomeExpenseId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(IncomeExpense));

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
