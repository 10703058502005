/* eslint-disable */
import React, { useState, useEffect, } from "react";
import {  Col,  FormGroup,Label, Row,TabPane, } from "reactstrap";
import Select from "react-select";
import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./maritalhistory.scss";
import { fetchCastes, fetchReligions } from "./api";
import { getQualifications } from "../Family History/api";
import "../styles.scss";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function MaritalHistory(props) {
  let { details,  editable } = props;

  const [selectgender, setselectgender] = useState(null);
  const [selectededucation, setselectededucation] = useState(null);
  const [selectmarital, setselectmarital] = useState(null);
  const [values, setvalues] = useState([{}]);
  const [mstrObject, setMstrObject] = useState();
  const [religions, setReligions] = useState([]);
  const [casteOptions, setCaste] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [religionSlct, setReligionSlct] = useState(null);
  const [casteSlct, setCasteSlct] = useState(null);
  const [qlfcSlct, setQlfcSlct] = useState(null);
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  useEffect(() => {
    let data = details;

    setMstrObject(data);
    let relig = {
      label: data?.spouse_details?.religion?.name,
      value: data?.spouse_details?.religion?._id,
    };
    setReligionSlct(relig);
    let cast = {
      label: data?.spouse_details?.caste?.name,
      value: data?.spouse_details?.caste?._id,
    };
    setCasteSlct(cast);
    let eduop = {
      label: data?.spouse_details?.education?.qualification_name,
      value: data?.spouse_details?.education?._id,
    };
    setQlfcSlct(eduop);
    if (data?.children_details.length > 0) {
      let tableData = [];
      data?.children_details &&
        data?.children_details.map((item, index) => {
          let val = {};
          val.name = item?.name;
          val.age = item?.age;
          val.occupation = item?.occupation;
          val.mental_status = item?.mental_status;
          val.education = item?.education?._id;
          val.education_name = item?.education?.qualification_name;

          if (item?.gender == 1) {
            val.gender_name = "Male";
            val.gender = 1;
          }
          if (item?.gender == 0) {
            val.gender_name = "Female";
            val.gender = 0;
          }
          if (item?.gender == 2) {
            val.gender_name = "Others";
            val.gender = 2;
          }
          if (item?.marital_status == 0) {
            val.marital_status_name = "Married";
            val.marital_status = 0;
          }
          if (item?.marital_status == 1) {
            val.marital_status_name = "Unmarried";
            val.marital_status = 1;
          }
          if (item?.marital_status == 2) {
            val.marital_status_name = "Separated";
            val.marital_status = 2;
          }
          if (item?.marital_status == 3) {
            val.marital_status_name = "Divorced";
            val.marital_status = 3;
          }
          if (item?.marital_status == 4) {
            val.marital_status_name = "Widowed";
            val.marital_status = 3;
          }
          tableData.push(val);
        });
      setvalues(tableData);
    }
  }, [details]);

  useEffect(() => {
    getRelationshiplist();
    fetchReligions()
      .then((data) => setReligions(data))
      .catch((err) => console.log(err));

    getQualifications()
      .then((data) => setQualifications(data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchCastes()
      .then((data) => setCaste(data))
      .catch((err) => console.log(err));
  }, []);

  const getRelationshiplist = () => {
    axios
      .get(`${API_URL}options/relationships`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setRelationshipOptions(options);
      });
  };

  const handleInput = (e, objName) => {
    let { name, type, value, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;
    if (objName === undefined) {
      setMstrObject((prevState) => ({
        ...prevState,
        admission_id: props.id,
        [name]: newValue,
      }));
    } else {
      setMstrObject((prevState) => ({
        ...prevState,
        admission_id: props.id,
        [objName]: {
          ...prevState[objName],
          [name]: newValue,
        },
      }));
    }
  };

  const handleInputs = (e, objName, objNames) => {
    let { name, type, value, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    setMstrObject((prevState) => ({
      ...prevState,
      admission_id: props.id,
      [objName]: {
        ...prevState[objName],
        [objNames]: {
          ...prevState?.[objName]?.[objNames],
          [name]: newValue,
        },
      },
    }));
  };

  const handleSelect = (e, mthd) => {
    let { name, value } = e;
    let data = { ...mstrObject };
    if (mthd === 0) {
      data.spouse_details[name] = value;
      setMstrObject(data);
    }
  };

  const handleChange = (e, mthd) => {
    let { name, value } = e.target;
    let data = { ...mstrObject };
    if (mthd === 0) {
      data.spouse_details[name] = value;
      setMstrObject(data);
    } else if (mthd === 1) {
      data[name] = value;
      setMstrObject(data);
    }
  };

  const addMore = () => {
    setvalues([...values, {}]);
  };

  const removeRow = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setvalues(list);
    setMstrObject({ ...mstrObject, children_details: list });
  };

  function handlechildrenChange(e, index) {
    let name = e.target.name;
    let value = e.target.value;
    var list = [...values];
    list[index][name] = value;

    setvalues(list);
    // setMstrObject({ ...mstrObject, children_details: list })
    setMstrObject((prevState) => ({
      ...prevState,
      admission_id: props.id,
      children_details: list,
    }));
  }

  function handleChangeSelectItem(selected, index) {
    const list = [...values];
    list[index]["gender"] = selected.value;
    list[index]["gender_name"] = selected.label;
    // setSPSelectedState(selected)
    setvalues(list);
    setMstrObject((prevState) => ({
      ...prevState,
      admission_id: props.id,
      children_details: list,
    }));
  }
  function handleChangeEducation(selected, index) {
    const list = [...values];
    list[index]["education"] = selected.value;
    list[index]["education_name"] = selected.label;
    // setSPSelectedState(selected)
    setvalues(list);
    setMstrObject((prevState) => ({
      ...prevState,
      admission_id: props.id,
      children_details: list,
    }));
  }
  function handleChangeMaritalHistory(selected, index) {
    const list = [...values];
    list[index]["marital_status"] = selected.value;
    list[index]["marital_status_name"] = selected.label;
    // setSPSelectedState(selected)
    setvalues(list);
    setMstrObject((prevState) => ({
      ...prevState,
      admission_id: props.id,
      children_details: list,
    }));
  }

  function handleSelectChange(selected, name, index = null) {
    switch (name) {
      case "gender":
        setselectgender(selected);
        var list = [...values];
        list[index].gender = selected.value;
        setvalues(list);

        break;
      case "education":
        setselectededucation(selected);
        var list = [...values];
        list[index].education = selected.value;
        setvalues(list);
        break;
      case "marital_status":
        setselectmarital(selected);
        var list = [...values];
        list[index].marital_status = selected.value;
        setvalues(list);

        setMstrObject({ ...mstrObject, children_details: list });
    }
  }

  const handleSelectChanges = (e, type, objName) => {
    switch (type) {
      case "religion":
        setReligionSlct(e);
        setMstrObject((prevState) => ({
          ...prevState,
          admission_id: props.id,
          [objName]: {
            ...prevState[objName],
            religion: e.value,
          },
        }));
        break;
      case "caste":
        setCasteSlct(e);
        setMstrObject((prevState) => ({
          ...prevState,
          admission_id: props.id,
          [objName]: {
            ...prevState[objName],
            caste: e.value,
          },
        }));
        break;
      case "education":
        setQlfcSlct(e);
        setMstrObject((prevState) => ({
          ...prevState,
          admission_id: props.id,
          [objName]: {
            ...prevState[objName],
            education: e.value,
          },
        }));
        break;
      // setSelectedState(e);
      // setmasterObject({
      //   ...masterObject,
      //   state: e.value,
      // });
    }
  };

  const handleHealthStatusChanges = (e, objName, objNames, type) => {
    switch (type) {
      case "releation":
        setReligionSlct(e);
        // setMstrObject((prevState) => ({
        //   ...prevState,
        //   admission_id: props.id,
        //   [objName]: {
        //     ...prevState[objName],
        //     religion: e.value,
        //   },

        setMstrObject((prevState) => ({
          ...prevState,
          admission_id: props.id,
          [objName]: {
            ...prevState[objName],
            [objNames]: {
              ...prevState?.[objName]?.[objNames],
              releation: e.label,
            },
          },
        }));

        break;
    }
  };

  function handleValidSubmit() {
    mstrObject.admission_id = props.id;
    axios
      .post(`${API_URL}admission/marital-history`, mstrObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success(res.data.message);
        props.handleToggleBar("increment");
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  }
  return (
    <TabPane tabId={4}>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
          handleValidSubmit();
        }}>
        <h6>1. Details Regarding Spouse</h6>
        <Row>
          <Col md="4">
            <div className="mt-2">
              <Label>Name</Label>
              <AvField
                disabled={!editable}
                name="name"
                placeholder="Name"
                type="text"
                className="form-control"
                onChange={(e) => {
                  // handleChange(e, 0);
                  handleInput(e, "spouse_details");
                }}
                value={mstrObject?.spouse_details?.name}
              />
            </div>
          </Col>

          <Col md="2">
            <div className="mt-2">
              <Label>Age</Label>
              <AvField
                disabled={!editable}
                name="age"
                type="text"
                className="form-control"
                onChange={(e) => {
                  handleInput(e, "spouse_details");
                  //handleChange(e, 0);
                }}
                value={mstrObject?.spouse_details?.age}
              />
            </div>
          </Col>

          <Col md="3" className="mt-2">
            <Label>Religion</Label>
            <Select
              isDisabled={!editable}
              name="religion"
              value={religionSlct}
              options={religions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
              onChange={(e) => {
                handleSelectChanges(e, "religion", "spouse_details");
                //  setReligionSlct(e);
                // handleSelect(e, 0);
              }}
            />
          </Col>

          <Col md="3" className="mt-2">
            <Label>Community</Label>
            <Select
              isDisabled={!editable}
              value={casteSlct}
              name="caste"
              options={casteOptions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
              onChange={(e) => {
                //  setCasteSlct(e);
                handleSelectChanges(e, "caste", "spouse_details");
              }}
            />
          </Col>

          <Col md="3" className="mt-2">
            <Label>Education</Label>
            <Select
              isDisabled={!editable}
              name="education"
              options={qualifications}
              classNamePrefix="select2-selection"
              menuPosition="auto"
              value={qlfcSlct}
              onChange={(e) => {
                //   setQlfcSlct(e);
                handleSelectChanges(e, "education", "spouse_details");
              }}
            />
          </Col>

          <Col md="3" className="mt-2">
            <Label>Occupation</Label>
            <AvField
              disabled={!editable}
              name="occupation"
              type="text"
              className="form-control"
              placeholder="Occupation"
              onChange={(e) => {
                // handleChange(e, 0);
                handleInput(e, "spouse_details");
              }}
              value={mstrObject?.spouse_details?.occupation}
            />
          </Col>

          <Col md="3">
            <div className="mt-2">
              <Label>Income</Label>
              <AvField
                disabled={!editable}
                name="income"
                placeholder="Income"
                type="Number"
                className="form-control"
                onChange={(e) => {
                  //handleChange(e, 0);
                  handleInput(e, "spouse_details");
                }}
                value={mstrObject?.spouse_details?.income}
              />
            </div>
          </Col>

          <Col md="3">
            <FormGroup className="mt-2">
              <Label htmlFor="name">No of years of Marriage</Label>
              <AvField
                disabled={!editable}
                name="no_of_yrs_of_mrg"
                type="text"
                className="form-control"
                placeholder="Enter name"
                onChange={(e) => {
                  handleInput(e);
                }}
                value={mstrObject?.no_of_yrs_of_mrg}
              />
            </FormGroup>
          </Col>
        </Row>

        <Col md="12" className="mt-3">
          <Row>
            <Col md="5">
              <h6>2. Is this marriage arranged or by choice?</h6>
            </Col>
            <Col md="5">
              <p>
                Arranged{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  name="marriage_type"
                  // checked={marrage === 0}
                  // onChange={handlemarrage}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.marriage_type == 0 ? true : false}
                />{" "}
                &nbsp; Choice{" "}
                <input
                  disabled={!editable}
                  style={{ marginLeft: "10px" }}
                  type="radio"
                  name="marriage_type"
                  value={1}
                  checked={mstrObject?.marriage_type == 1 ? true : false}
                  onClick={(e) => handleInput(e)}
                />{" "}
              </p>
            </Col>
          </Row>
        </Col>

        <Col md="12" className="mt-1">
          <Row>
            <Col md="3">
              <p style={{ marginLeft: "1rem" }}>2.1 Accepted by family?</p>
            </Col>
            <Col md="3">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="family_accepted"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.family_accepted == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="family_accepted"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.family_accepted == 0 ? true : false}
                />
              </p>
            </Col>
          </Row>
        </Col>

        <Col md="12" className="mt-1">
          <Row>
            <Col md="8">
              <h6>3. Details regarding previous or subsequent marriages,if any</h6>
            </Col>
            <Col md="3">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="prev_subsequent_mrrg"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.prev_subsequent_mrrg == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  name="prev_subsequent_mrrg"
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.prev_subsequent_mrrg == 0 ? true : false}
                />{" "}
              </p>
            </Col>
          </Row>
        </Col>

        <Col md="12" className="mt-1">
          <Row>
            <Col md="8">
              <h6>4. Have you been separated from your spouse due to your addiction?</h6>
            </Col>
            <Col md="3">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  name="sprtd_due_to_addiction"
                  value={1}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.sprtd_due_to_addiction == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="sprtd_due_to_addiction"
                  value={0}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.sprtd_due_to_addiction == 0 ? true : false}
                />{" "}
              </p>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col md="5" style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ marginLeft: "1rem" }}>4.1 Separation Period </p>
              <AvField
                disabled={!editable}
                name="prd_of_lng_separation"
                type="text"
                className="form-control form-control1"
                onChange={(e) => handleInput(e)}
                value={mstrObject?.prd_of_lng_separation}
              />
            </Col>
          </Row>
        </Col>

        <Col md="12" className="mt-2">
          <Row>
            <Col md="4">
              <h6>5. Is patient suspicious of wife?</h6>
            </Col>
            <Col md="8">
              <p>
                While Abstinent{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="suspicious_of_wife"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.suspicious_of_wife == 1 ? true : false}
                />{" "}
                &nbsp; Under the influence of Alcohol/Drugs{" "}
                <input
                  disabled={!editable}
                  name="suspicious_of_wife"
                  type="radio"
                  value={2}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.suspicious_of_wife == 2 ? true : false}
                />
              </p>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col md="9" style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ marginLeft: "1rem" }}>5.1 Other history about spouse </p>
              <AvField
                disabled={!editable}
                name="other_history"
                type="textarea"
                className="form-control form-control2"
                onChange={(e) => handleInput(e)}
                value={mstrObject?.other_history}
              />
            </Col>
          </Row>
        </Col>
        <Col md="9" className="mt-3">
          <Row>
            <Col md="6">
              <h6>6. Any instance of violence in the family?</h6>
            </Col>
            <Col md="3">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="violence_in_fmly"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.violence_in_fmly == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="violence_in_fmly"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.violence_in_fmly == 0 ? true : false}
                />{" "}
              </p>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col md="9" style={{ display: "flex" }}>
              <p style={{ marginLeft: "1rem" }}>6.1 Physical violence directed towards family members</p>
              <AvField
                disabled={!editable}
                type="text"
                className="form-control form-control2"
                name="phcl_vilnce_family"
                onChange={(e) => {
                  handleInput(e);
                }}
                value={mstrObject?.phcl_vilnce_family}
              />
            </Col>
          </Row>
        </Col>
        <Col className="mt-2">
          <Row>
            <Col md="9" style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ marginLeft: "1rem" }}>6.2 Breaking articles at home</p>

              <AvField
                disabled={!editable}
                name="brkng_artcls"
                type="text"
                className="form-control form-control2"
                onChange={(e) => {
                  handleInput(e);
                }}
                value={mstrObject?.brkng_artcls}
              />
            </Col>
          </Row>
        </Col>
        <Col className="mt-2">
          <Row>
            <Col md="9" style={{ display: "flex", marginTop: "1.2rem" }}>
              <p style={{ marginLeft: "1rem" }}>6.3 Violent incidents with neighbours and outsiders</p>
              <AvField
                disabled={!editable}
                name="violence_in_neibr"
                type="text"
                className="form-control form-control2"
                onChange={(e) => {
                  handleChange(e, 1);
                }}
                value={mstrObject?.violence_in_neibr}
              />
            </Col>
          </Row>
        </Col>
        <Col className="mt-2">
          <Row>
            <Col md="9" style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ marginLeft: "1rem" }}>6.4 Verbally abusive</p>
              <AvField
                disabled={!editable}
                name="verbal_abusive"
                type="text"
                className="form-control form-control2"
                onChange={(e) => {
                  handleChange(e, 1);
                }}
                value={mstrObject?.verbal_abusive}
              />
            </Col>
          </Row>
        </Col>
        <h6>7. Details Regarding Children</h6>

        <Row className="mt-2">
          <Col md="11">
            <Row>
              <Col style={{ display: "flex", justifyContent: "space-between" }} className="mt-2" md="6">
                <p style={{ marginLeft: "1rem" }}>No.of childrens</p>
                <AvField
                  style={{ width: "50%" }}
                  disabled={!editable}
                  name="no_of_children"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => handleChange(e, 1)}
                  value={mstrObject?.no_of_children}
                />
              </Col>

              <Col style={{ display: "flex", justifyContent: "space-between" }} className="mt-2" md="6">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p>Male</p>
                  <AvField
                    style={{ width: "50%" }}
                    disabled={!editable}
                    name="no_of_male"
                    type="number"
                    className="mx-2"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleChange(e, 1)}
                    value={mstrObject?.no_of_male}
                  />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p style={{ marginLeft: "1rem" }}>Female</p>
                  <AvField
                    style={{ width: "50%" }}
                    className="mx-2"
                    disabled={!editable}
                    name="no_of_female"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleChange(e, 1)}
                    value={mstrObject?.no_of_female}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl="12" className="mt-3">
            {values &&
              values.map((item, index) => (
                <Row
                  className={
                    values.length >= 2 && index !== 0
                      ? "shadow rounded p-2 mt-3 mb-3 border_bottom"
                      : values.length === 1
                      ? " rounded p-2 "
                      : "shadow rounded p-2 mb-3 border_bottom"
                  }>
                  <Col md={3}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="name">Name</Label>
                      <AvField
                        disabled={!editable}
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Enter name"
                        //   value={data?.name}
                        //   onChange={(e) => handleTableChange(e, index)}
                        // validate={{
                        //   required: { value: true },
                        // }}
                        onChange={(e) => {
                          handlechildrenChange(e, index);
                        }}
                        value={item?.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="name">Age</Label>
                      <AvField
                        disabled={!editable}
                        name="age"
                        type="number"
                        className="form-control"
                        placeholder="Enter age"
                        onChange={(e) => {
                          handlechildrenChange(e, index);
                        }}
                        value={item?.age}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="name">Sex</Label>
                      <Select
                        disabled={!editable}
                        name="gender_name"
                        options={[
                          { label: "Male", value: 1 },
                          { label: "Female", value: 0 },
                          { label: "Others", value: 2 },
                        ]}
                        classNamePrefix="select2-selection"
                        onChange={(selected) => {
                          handleChangeSelectItem(selected, index);
                          // handleSelectChange(selected, "gender", index)
                        }}
                        //value={selectgender}
                        value={{
                          label: item?.gender_name,
                          value: item?.gender,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="name">Education</Label>
                      <Select
                        disabled={!editable}
                        name="education"
                        options={qualifications}
                        classNamePrefix="select2-selection"
                        menuPosition="auto"
                        onChange={(selected) => {
                          handleChangeEducation(selected, index);
                        }}
                        // value={selectededucation}
                        value={{
                          label: item?.education_name,
                          value: item?.education,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="name">Occupation</Label>
                      <AvField
                        disabled={!editable}
                        name="occupation"
                        type="text"
                        className="form-control"
                        placeholder="Enter occupation"
                        onChange={(e) => {
                          handlechildrenChange(e, index);
                        }}
                        value={item?.occupation}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="name">Married / Not</Label>
                      <Select
                        disabled={!editable}
                        name="marital_status_name"
                        options={[
                          { label: "Married", value: 0 },
                          { label: "Unmarried", value: 1 },
                          { label: "Separated", value: 2 },
                          { label: "Divorced", value: 3 },
                          { label: "Widowed", value: 4 },
                        ]}
                        classNamePrefix="select2-selection"
                        onChange={(selected) => {
                          // handleSelectChange(selected, "marital_status", index)
                          handleChangeMaritalHistory(selected, index);
                        }}
                        //value={selectmarital}
                        value={{
                          label: item?.marital_status_name,
                          value: item?.marital_status,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="name">Mental status</Label>
                      <AvField
                        disabled={!editable}
                        name="mental_status"
                        type="text"
                        className="form-control"
                        placeholder="Enter Mental Status"
                        onChange={(e) => {
                          handlechildrenChange(e, index);
                        }}
                        value={item?.mental_status}
                      />
                    </FormGroup>
                  </Col>
                  {editable && (
                    <Col md={12}>
                      {values.length !== 1 ? (
                        <h5 style={{ float: "right" }}>
                          <i onClick={() => removeRow(index)} className="trash-btn mdi mdi-delete"></i>
                        </h5>
                      ) : (
                        ""
                      )}
                    </Col>
                  )}
                </Row>
              ))}
          </Col>
        </Row>
        <Row>
          {editable && (
            <Col md={12} className="m-1 p-1">
              <p className="new-row" onClick={addMore}>
                Add More <span className="mdi mdi-plus-circle-outline"></span>
              </p>
            </Col>
          )}
        </Row>
        <Col className="mt-3">
          <h6>8. Health status of family</h6>
        </Col>

        <p style={{ marginLeft: "1rem" }}>8.1 Has there been anyone in your family who has suffered from any of these problems?</p>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="3">
              <p style={{ marginLeft: "1rem" }}>8.1.1 Major depression</p>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  //checked={dipcheck === 1}
                  name="prb_status"
                  //onChange={handledipression}

                  onClick={(e) => handleInputs(e, "fmly_health_status", "major_depression")}
                  checked={mstrObject?.fmly_health_status?.major_depression?.prb_status == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  // checked={dipcheck === 0}
                  name="prb_status"
                  // onChange={handledipression}

                  onClick={(e) => handleInputs(e, "fmly_health_status", "major_depression")}
                  checked={mstrObject?.fmly_health_status?.major_depression?.prb_status == 0 ? true : false}
                />{" "}
                &nbsp; Don't Know{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={2}
                  // checked={dipcheck === 2}
                  name="prb_status"
                  //onChange={handledipression}
                  onClick={(e) => handleInputs(e, "fmly_health_status", "major_depression")}
                  checked={mstrObject?.fmly_health_status?.major_depression?.prb_status == 2 ? true : false}
                />{" "}
                &nbsp;
              </p>
            </Col>
            <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
              <Col md="5">
                <p>Relationship </p>
              </Col>
              <Col md="7">
                <Select
                  name="releation"
                  isDisabled={!editable}
                  options={relationshipOptions}
                  onChange={(e) => handleHealthStatusChanges(e, "fmly_health_status", "major_depression", "releation")}
                  value={{
                    label: mstrObject?.fmly_health_status?.major_depression?.releation,
                    value: mstrObject?.fmly_health_status?.major_depression?.releation,
                  }}
                />
              </Col>
              {/* <AvField
                disabled={!editable}
                name="releation"
                type="text"
                className="form-control form-control3 mx-2"
                //onChange={handledipchage}
                onChange={(e) =>
                  handleInputs(e, "fmly_health_status", "major_depression")
                }
                value={
                  mstrObject?.fmly_health_status?.major_depression?.releation
                }
              /> */}
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="3">
              <p style={{ marginLeft: "1rem" }}>8.1.2 Suicide/attempt</p>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  name="s_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "suicide_attmept")}
                  checked={mstrObject?.fmly_health_status?.suicide_attmept?.s_prb_status == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  name="s_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "suicide_attmept")}
                  checked={mstrObject?.fmly_health_status?.suicide_attmept?.s_prb_status == 0 ? true : false}
                />{" "}
                &nbsp; Don't Know{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={2}
                  name="s_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "suicide_attmept")}
                  checked={mstrObject?.fmly_health_status?.suicide_attmept?.s_prb_status == 2 ? true : false}
                />{" "}
                &nbsp;
              </p>
            </Col>
            <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
              <Col md="5">
                <p>Relationship </p>
              </Col>
              <Col md="7">
                <Select
                  name="releation"
                  isDisabled={!editable}
                  options={relationshipOptions}
                  onChange={(e) => handleHealthStatusChanges(e, "fmly_health_status", "suicide_attmept", "releation")}
                  value={{
                    label: mstrObject?.fmly_health_status?.suicide_attmept?.releation,
                    value: mstrObject?.fmly_health_status?.suicide_attmept?.releation,
                  }}
                />
              </Col>
              {/* <p>Relationship </p>
              <AvField
                disabled={!editable}
                name="releation"
                type="text"
                className="form-control form-control3 mx-2"
                onChange={(e) =>
                  handleInputs(e, "fmly_health_status", "suicide_attmept")
                }
                value={
                  mstrObject?.fmly_health_status?.suicide_attmept?.releation
                }
              /> */}
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="3">
              <p style={{ marginLeft: "1rem" }}>8.1.3 Psychiatric illnesses</p>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  name="p_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "psychiatric_illnesses")}
                  checked={mstrObject?.fmly_health_status?.psychiatric_illnesses?.p_prb_status == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  name="p_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "psychiatric_illnesses")}
                  checked={mstrObject?.fmly_health_status?.psychiatric_illnesses?.p_prb_status == 0 ? true : false}
                />{" "}
                &nbsp; Don't Know{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={2}
                  name="p_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "psychiatric_illnesses")}
                  checked={mstrObject?.fmly_health_status?.psychiatric_illnesses?.p_prb_status == 2 ? true : false}
                />{" "}
                &nbsp;
              </p>
            </Col>
            <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
              <Col md="5">
                <p>Relationship </p>
              </Col>
              <Col md="7">
                <Select
                  name="releation"
                  isDisabled={!editable}
                  options={relationshipOptions}
                  onChange={(e) => handleHealthStatusChanges(e, "fmly_health_status", "psychiatric_illnesses", "releation")}
                  value={{
                    label: mstrObject?.fmly_health_status?.psychiatric_illnesses?.releation,
                    value: mstrObject?.fmly_health_status?.psychiatric_illnesses?.releation,
                  }}
                />
              </Col>
              {/* <p>Relationship </p>
              <AvField
                disabled={!editable}
                name="releation"
                type="text"
                className="form-control form-control3 mx-2"
                onChange={(e) =>
                  handleInputs(e, "fmly_health_status", "psychiatric_illnesses")
                }
                value={
                  mstrObject?.fmly_health_status?.psychiatric_illnesses
                    ?.releation
                }
              /> */}
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="3">
              <p style={{ marginLeft: "1rem" }}>8.1.4 Alcohol dependence</p>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  name="a_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "alcohol_dependence")}
                  checked={mstrObject?.fmly_health_status?.alcohol_dependence?.a_prb_status == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  name="a_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "alcohol_dependence")}
                  checked={mstrObject?.fmly_health_status?.alcohol_dependence?.a_prb_status == 0 ? true : false}
                />{" "}
                &nbsp; Don't Know{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={2}
                  name="a_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "alcohol_dependence")}
                  checked={mstrObject?.fmly_health_status?.alcohol_dependence?.a_prb_status == 2 ? true : false}
                />{" "}
                &nbsp;
              </p>
            </Col>
            <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
              <Col md="5">
                <p>Relationship </p>
              </Col>
              <Col md="7">
                <Select
                  name="releation"
                  isDisabled={!editable}
                  options={relationshipOptions}
                  onChange={(e) => handleHealthStatusChanges(e, "fmly_health_status", "alcohol_dependence", "releation")}
                  value={{
                    label: mstrObject?.fmly_health_status?.alcohol_dependence?.releation,
                    value: mstrObject?.fmly_health_status?.alcohol_dependence?.releation,
                  }}
                />
              </Col>

              {/* <p>Relationship </p>
              <AvField
                name="releation"
                type="text"
                className="form-control form-control3 mx-2"
                onChange={(e) =>
                  handleInputs(e, "fmly_health_status", "alcohol_dependence")
                }
                value={
                  mstrObject?.fmly_health_status?.alcohol_dependence?.releation
                }
              /> */}
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="3">
              <p style={{ marginLeft: "1rem" }}>8.1.5 Drug dependence</p>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  name="d_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "drug_dependence")}
                  checked={mstrObject?.fmly_health_status?.drug_dependence?.d_prb_status == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  name="d_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "drug_dependence")}
                  checked={mstrObject?.fmly_health_status?.drug_dependence?.d_prb_status == 0 ? true : false}
                />{" "}
                &nbsp; Don't Know{" "}
                <input
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={2}
                  name="d_prb_status"
                  onClick={(e) => handleInputs(e, "fmly_health_status", "drug_dependence")}
                  checked={mstrObject?.fmly_health_status?.drug_dependence?.d_prb_status == 2 ? true : false}
                />{" "}
                &nbsp;
              </p>
            </Col>
            <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
              <Col md="5">
                <p>Relationship </p>
              </Col>
              <Col md="7">
                <Select
                  name="releation"
                  isDisabled={!editable}
                  options={relationshipOptions}
                  onChange={(e) => handleHealthStatusChanges(e, "fmly_health_status", "drug_dependence", "releation")}
                  value={{
                    label: mstrObject?.fmly_health_status?.drug_dependence?.releation,
                    value: mstrObject?.fmly_health_status?.drug_dependence?.releation,
                  }}
                />
              </Col>
              {/* <p>Relationship </p>
              <AvField
                name="releation"
                type="text"
                className="form-control form-control3 mx-2"
                onChange={(e) =>
                  handleInputs(e, "fmly_health_status", "drug_dependence")
                }
                value={
                  mstrObject?.fmly_health_status?.drug_dependence?.releation
                }
              /> */}
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="3">
              <p style={{ marginLeft: "1rem" }}>8.1.6 Any other</p>
            </Col>
            <Col md="4">
              <p>
                <AvField
                  name="prblem"
                  type="textarea"
                  className="form-control form-control5"
                  onChange={(e) => handleInputs(e, "fmly_health_status", "any_other")}
                  value={mstrObject?.fmly_health_status?.any_other?.prblem}
                />
              </p>
            </Col>
            <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
              <Col md="5">
                <p>Relationship </p>
              </Col>
              <Col md="7">
                <Select
                  name="releation"
                  isDisabled={!editable}
                  options={relationshipOptions}
                  onChange={(e) => handleHealthStatusChanges(e, "fmly_health_status", "any_other", "releation")}
                  value={{
                    label: mstrObject?.fmly_health_status?.any_other?.releation,
                    value: mstrObject?.fmly_health_status?.any_other?.releation,
                  }}
                />
              </Col>

              {/* <p>Relationship </p>
              <AvField
                name="releation"
                type="text"
                className="form-control form-control3 mx-2"
                onChange={(e) =>
                  handleInputs(e, "fmly_health_status", "any_other")
                }
                value={mstrObject?.fmly_health_status?.any_other?.releation}
              /> */}
            </Col>
          </Row>
        </Col>

        <h6>9. Adjustment patterns</h6>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="2">
              <p style={{ marginLeft: "1rem" }}>9.1 Parents</p>
            </Col>
            <Col md="6">
              <Row style={{ display: "flex" }}>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  No family(Dead){" "}
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px", justifyContent: "flex-end" }}
                    value={1}
                    name="parents"
                    checked={parents === 1}
                    onChange={handleparents}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.parents?.no_family || false}
                    name="no_family"
                    className="form-check-input cursor-pointer"
                    id="no_family"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "parents")}
                  />
                </p>
              </Row>
              <Row style={{ display: "flex" }}>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Disowned by family/mutual rejection{" "}
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px", justifyContent: "flex-end" }}
                    value={2}
                    name="parents"
                    checked={parents === 2}
                    onChange={handleparents}

                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.parents?.mutual_rejection || false}
                    name="mutual_rejection"
                    className="form-check-input cursor-pointer"
                    id="mutual_rejection"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "parents")}
                  />
                </p>
              </Row>
              <Row style={{ display: "flex" }}>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Mixed or indifferent feelings
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px", justifyContent: "flex-end" }}
                    value={3}
                    name="parents"
                    checked={parents === 3}
                    onChange={handleparents}

                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.parents?.indifferent_feelings || false}
                    name="indifferent_feelings"
                    className="form-check-input cursor-pointer"
                    id="indifferent_feelings"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "parents")}
                  />
                </p>
              </Row>
              <Row style={{ display: "flex" }}>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Usually friendly minor conflicts
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px", justifyContent: "flex-end" }}
                    value={4}
                    name="parents"
                    checked={parents === 4}
                    onChange={handleparents}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.parents?.minor_conflicts || false}
                    name="minor_conflicts"
                    className="form-check-input cursor-pointer"
                    id="minor_conflicts"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "parents")}
                  />
                </p>
              </Row>
              <Row style={{ display: "flex" }}>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Supportive
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px", justifyContent: "flex-end" }}
                    value={5}
                    name="parents"
                    checked={parents === 5}
                    onChange={handleparents}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.parents?.supportive || false}
                    name="supportive"
                    className="form-check-input cursor-pointer"
                    id="supportive"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "parents")}
                  />
                </p>
              </Row>
              <Row style={{ display: "flex" }}>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Not applicable
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px", justifyContent: "flex-end" }}
                    value={6}
                    name="parents"
                    checked={parents === 6}
                    onChange={handleparents}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.parents?.not_applocable || false}
                    name="not_applocable"
                    className="form-check-input cursor-pointer"
                    id="not_applocable"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "parents")}
                  />
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="2">
              <p style={{ marginLeft: "1rem" }}>9.2 Siblings</p>
            </Col>
            <Col md="6">
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  No family(Dead){" "}
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={1}
                    name="siblings"
                    checked={siblings === 1}
                    onChange={handlesibling}

                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.siblings?.no_family || false}
                    name="no_family"
                    className="form-check-input cursor-pointer"
                    id="no_family"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "siblings")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Disowned by family/mutual rejection{" "}
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={2}
                    name="siblings"
                    checked={siblings === 2}
                    onChange={handlesibling}

                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.siblings?.mutual_rejection || false}
                    name="mutual_rejection"
                    className="form-check-input cursor-pointer"
                    id="mutual_rejection"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "siblings")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Mixed or indifferent feelings
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={3}
                    name="siblings"
                    checked={siblings === 3}
                    onChange={handlesibling}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.siblings?.indifferent_feelings || false}
                    name="indifferent_feelings"
                    className="form-check-input cursor-pointer"
                    id="indifferent_feelings"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "siblings")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Usually friendly minor conflicts
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={4}
                    name="siblings"
                    checked={siblings === 4}
                    onChange={handlesibling}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.siblings?.minor_conflicts || false}
                    name="minor_conflicts"
                    className="form-check-input cursor-pointer"
                    id="minor_conflicts"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "siblings")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Supportive
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={5}
                    name="siblings"
                    checked={siblings === 5}
                    onChange={handlesibling}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.siblings?.supportive || false}
                    name="supportive"
                    className="form-check-input cursor-pointer"
                    id="supportive"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "siblings")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Not applicable
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={6}
                    name="siblings"
                    checked={siblings === 6}
                    onChange={handlesibling}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.siblings?.not_applocable || false}
                    name="not_applocable"
                    className="form-check-input cursor-pointer"
                    id="not_applocable"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "siblings")}
                  />
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="2">
              <p style={{ marginLeft: "1rem" }}>9.3 Spouse</p>
            </Col>
            <Col md="6">
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  No family(Dead){" "}
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={1}
                    name="spouse"
                    checked={spous === 1}
                    onChange={handlespouse}

                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.spouse?.no_family || false}
                    name="no_family"
                    className="form-check-input cursor-pointer"
                    id="no_family"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "spouse")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Disowned by family/mutual rejection{" "}
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={2}
                    name="spouse"
                    checked={spous === 2}
                    onChange={handlespouse}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.spouse?.mutual_rejection || false}
                    name="mutual_rejection"
                    className="form-check-input cursor-pointer"
                    id="mutual_rejection"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "spouse")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Mixed or indifferent feelings
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={3}
                    name="spouse"
                    checked={spous === 3}
                    onChange={handlespouse}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.spouse?.indifferent_feelings || false}
                    name="indifferent_feelings"
                    className="form-check-input cursor-pointer"
                    id="indifferent_feelings"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "spouse")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Usually friendly minor conflicts
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={4}
                    name="spouse"
                    checked={spous === 4}
                    onChange={handlespouse}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.spouse?.minor_conflicts || false}
                    name="minor_conflicts"
                    className="form-check-input cursor-pointer"
                    id="minor_conflicts"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "spouse")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Supportive
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={5}
                    name="spouse"
                    checked={spous === 5}
                    onChange={handlespouse}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.spouse?.supportive || false}
                    name="supportive"
                    className="form-check-input cursor-pointer"
                    id="supportive"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "spouse")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Not applicable
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={6}
                    name="spouse"
                    checked={spous === 6}
                    onChange={handlespouse}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.spouse?.not_applocable || false}
                    name="not_applocable"
                    className="form-check-input cursor-pointer"
                    id="not_applocable"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "spouse")}
                  />
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="2">
              <p style={{ marginLeft: "1rem" }}>9.4 Children</p>
            </Col>
            <Col md="6">
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  No family(Dead){" "}
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={1}
                    name="childern"
                    checked={child === 1}
                    onChange={handlechildren}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.childern?.no_family || false}
                    name="no_family"
                    className="form-check-input cursor-pointer"
                    id="no_family"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "childern")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Disowned by family/mutual rejection{" "}
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={2}
                    name="childern"
                    checked={child === 2}
                    onChange={handlechildren}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.childern?.mutual_rejection || false}
                    name="mutual_rejection"
                    className="form-check-input cursor-pointer"
                    id="mutual_rejection"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "childern")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Mixed or indifferent feelings
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={3}
                    name="childern"
                    checked={child === 3}
                    onChange={handlechildren}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.childern?.indifferent_feelings || false}
                    name="indifferent_feelings"
                    className="form-check-input cursor-pointer"
                    id="indifferent_feelings"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "childern")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Usually friendly minor conflicts
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={4}
                    name="childern"
                    checked={child === 4}
                    onChange={handlechildren}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.childern?.minor_conflicts || false}
                    name="minor_conflicts"
                    className="form-check-input cursor-pointer"
                    id="minor_conflicts"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "childern")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Supportive
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={5}
                    name="childern"
                    checked={child === 5}
                    onChange={handlechildren}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.childern?.supportive || false}
                    name="supportive"
                    className="form-check-input cursor-pointer"
                    id="supportive"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "childern")}
                  />
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  Not applicable
                  {/* <input
                    type="radio"
                    style={{ marginLeft: "10px" }}
                    value={6}
                    name="childern"
                    checked={child === 6}
                    onChange={handlechildren}
                  /> */}
                  <input
                    disabled={!editable}
                    checked={mstrObject?.reltn_with_fmly_mbrs?.childern?.not_applocable || false}
                    name="not_applocable"
                    className="form-check-input cursor-pointer"
                    id="not_applocable"
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInputs(e, "reltn_with_fmly_mbrs", "childern")}
                  />
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-2">
          <Row>
            <Col md="5">
              <h6>10. Family Damage as seen by the counsellor</h6>
            </Col>
            <Col md="7">
              <p>
                Mild{" "}
                <input
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  name="fmly_dmg_sn_by_conslr"
                  // onChange={hamdledamage}
                  // checked={damage === 1}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.fmly_dmg_sn_by_conslr == 1 ? true : false}
                />{" "}
                &nbsp; Moderate{" "}
                <input
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={2}
                  name="fmly_dmg_sn_by_conslr"
                  // onChange={hamdledamage}
                  // checked={damage === 2}
                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.fmly_dmg_sn_by_conslr == 2 ? true : false}
                />{" "}
                &nbsp; Severe{" "}
                <input
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={3}
                  name="fmly_dmg_sn_by_conslr"
                  // onChange={hamdledamage}
                  // checked={damage === 3}

                  onClick={(e) => handleInput(e)}
                  checked={mstrObject?.fmly_dmg_sn_by_conslr == 3 ? true : false}
                />
              </p>
            </Col>
          </Row>
        </Col>
        <Row className="mt-4">
          <Col md={12} className="submit-btn-fixed">
            <FormGroup className="float-right ">
              <a className="save-continue-btn" onClick={() => props.handleToggleBar("decrement")}>
                <i class="fas fa-solid fa-less-than"></i>
              </a>
              <button type="submit" className="save-continue-btn m-2">
                Save and continue
                <i style={{ fontSize: "13px" }} className="mdi mdi-arrow-right"></i>
              </button>
              <a className="save-continue-btn" onClick={() => props.handleToggleBar("increment")}>
                <i class="fas fa-solid fa-greater-than"></i>
              </a>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </TabPane>
  );
}

export default MaritalHistory;
