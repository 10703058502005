import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

export let getQualifications = () =>
  axios
    .get(`${API_URL}enquiry/qualification-list`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) =>
      res.data.data.map((data) => ({
        label: data.qualification_name,
        value: data._id,
        name: 'education'
      }))
    )
    .catch((err) => console.error(err));

export let addFamilyHistory = (values) =>
  axios.post(`${API_URL}admission/family-history`,
    values,
    {
      headers: { "x-access-token": accessToken },
    }
  ).then((res) => res.data
  ).catch((err) => err)


