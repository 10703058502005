/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";

import { Col, FormGroup, Label, Row, TabPane } from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import toastr from "toastr";
//Import Breadcrumb
import accessToken from "../../../../helpers/jwt-token-access/accessToken";

//import "./medicalform.scss"
const DrinkingHistory = (props) => {
  let { id, editable } = props;

  const [values, setValues] = useState([{}]);
  const [masterObject, setmasterObject] = useState({});
  const [patientOptions, setPatientOptions] = useState([]);
  const [drugOptions, setDrugOptions] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    fetch_all_patients();
    fetch_all_drugs();
    previousmedicalform(props);
  }, [props]);
  const previousmedicalform = (props) => {
    let admission_id = props.id;
    axios
      .get(`${API_URL}medical-form?admission_id=` + admission_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let tableData = [];
        let data = {};
        if (result?.drug_history.length > 0) {
          result?.drug_history &&
            result?.drug_history.map((item, index) => {
              let val = {};
              val.drug = item?.drug?._id;
              val.drug_name = item?.drug?.name;
              val.first_use_age = item?.first_use_age;
              val.years_of_use = item?.years_of_use;
              val.years_excessive_use = item?.years_excessive_use;
              val.type_of_drug = item?.type_of_drug;
              val.route = item?.route;
              val.frqncy_of_last_30d = item?.frqncy_of_last_30d;
              val.qty_used_last_30d = item?.qty_used_last_30d;
              val.past_use = item?.past_use;
              tableData.push(val);
            });
          setValues(tableData);
        }

        data.drug_history = result?.drug_history;
        data.admission_id = props?.id;
        data.expndre_drgs_lst_yr = result?.expndre_drgs_lst_yr;
        data.expndre_drgs_crnt = result?.expndre_drgs_crnt;
        data.last_drug_taken = result?.last_drug_taken;
        data.diagnosis = result?.diagnosis;
        setmasterObject(data);
      });
  };

  const addMore = () => {
    setValues([...values, {}]);
  };

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
    setmasterObject({ ...masterObject, drug_history: list });
  };
  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.fname + " " + el?.lname + "-" + el?.reg_no,
              value: el?._id,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  const fetch_all_drugs = () => {
    axios
      .get(`${API_URL}options/drugs`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        const options = res.data.data.map((group) => {
          return {
            label: group.category.name,
            value: group.category._id,
            options: group.drugs.map((drug) => {
              return {
                label: drug.label,
                value: drug.value,
              };
            }),
          };
        });
        setDrugOptions(options);
      });
  };
  // const handleSelectChange = async (selected, type) => {
  //     switch (type) {
  //         case "admission_id":
  //             setSelectedPatient(selected);
  //         default:
  //             break;
  //     }
  // }
  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // color: state.isSelected ? "white" : "black",
      // backgroundColor: state.isSelected ? "#0074D9" : "white"
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: "black",
      fontWeight: "bold",
    }),
  };
  function handleTableInputchange(e, index) {
    let { name, value } = e.target;
    const list = [...values];
    list[index][name] = e.target.value;

    setValues(list);
    setmasterObject({ ...masterObject, drug_history: list });
  }

  function handleChangeSelectItem(selected, index) {
    const list = [...values];
    list[index]["drug"] = selected.value;
    list[index]["drug_name"] = selected.label;
    // setSPSelectedState(selected)
    setValues(list);
    setmasterObject({
      ...masterObject,
      admission_id: props.id,
      drug_history: list,
    });
  }

  const handleValidSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${API_URL}medical-form/drug-history`, masterObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success("Drinking/Drug History added successfully");
        // formRef.current.reset();
      });
  };

  return (
    <TabPane tabId={1}>
      {/* <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Medical Form" /> */}

      <Row>
        <Col lg="12">
          {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
          <AvForm
            className="needs-validation"
            method="POST"
            ref={formRef}
            onValidSubmit={(e) => {
              handleValidSubmit(e);
            }}>
            {/* <Card>
                                <CardBody id="myOverflow" className="scroll-bar"> */}
            <div id="myOverflow" className="scroll-bar">
              {values &&
                values.map((data, index) => {
                  return (
                    <Row
                      key={index}
                      className={
                        values.length >= 2 && index !== 0
                          ? "shadow rounded p-2 mt-3 border_bottom"
                          : values.length === 1
                          ? "shadow rounded p-2"
                          : "shadow rounded p-2 border_bottom"
                      }>
                      <Col md="3" className="mt-2">
                        <Label>Drugs</Label>
                        <Select
                          name="drug_name"
                          value={{
                            label: data.drug_name,
                            value: data.drug,
                          }}
                          // validate={{ required: { value: true } }}
                          onChange={(selected) => {
                            handleChangeSelectItem(selected, index);
                          }}
                          isDisabled={!editable}
                          styles={customStyles}
                          options={drugOptions}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>
                      <Col md="3">
                        <div className="mt-2">
                          <Label>Age of first use</Label>
                          <AvField
                            name="first_use_age"
                            placeholder="Age of first use"
                            type="text"
                            className="form-control"
                            disabled={!editable}
                            //validate={{ required: { value: true } }}
                            onChange={(e) => handleTableInputchange(e, index)}
                            value={data?.first_use_age}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-2">
                          <Label>Years of use</Label>
                          <AvField
                            name="years_of_use"
                            placeholder="Years of use"
                            type="text"
                            disabled={!editable}
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            onChange={(e) => handleTableInputchange(e, index)}
                            value={data?.years_of_use}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-2">
                          <Label>Years of excessive use</Label>
                          <AvField
                            name="years_excessive_use"
                            placeholder="Years of excessive use"
                            type="text"
                            disabled={!editable}
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            onChange={(e) => handleTableInputchange(e, index)}
                            value={data?.years_excessive_use}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-2">
                          <Label>Specific type of drugs</Label>
                          <AvField
                            name="type_of_drug"
                            placeholder="Specific type of drugs"
                            type="text"
                            disabled={!editable}
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            onChange={(e) => handleTableInputchange(e, index)}
                            value={data?.type_of_drug}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-2">
                          <Label>Route of administration</Label>
                          <AvField
                            name="route"
                            placeholder="Route of administration"
                            type="text"
                            disabled={!editable}
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            onChange={(e) => handleTableInputchange(e, index)}
                            value={data?.route}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-2">
                          <Label>Frequency of use in last 30 days</Label>
                          <AvField
                            name="frqncy_of_last_30d"
                            placeholder="Last 30 days frequency of use"
                            type="text"
                            disabled={!editable}
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            onChange={(e) => handleTableInputchange(e, index)}
                            value={data?.frqncy_of_last_30d}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-2">
                          <Label>Quantity used in last 30 days</Label>
                          <AvField
                            name="qty_used_last_30d"
                            placeholder="Last 30 days quantity used"
                            type="text"
                            disabled={!editable}
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            onChange={(e) => handleTableInputchange(e, index)}
                            value={data?.qty_used_last_30d}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-2">
                          <Label>Past use if any</Label>
                          <AvField
                            name="past_use"
                            placeholder="Past use if any"
                            type="text"
                            disabled={!editable}
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            onChange={(e) => handleTableInputchange(e, index)}
                            value={data?.past_use}
                          />
                        </div>
                      </Col>
                      {editable && (
                        <Col md={12}>
                          {values.length !== 1 ? (
                            <h5 style={{ float: "right" }}>
                              <i onClick={() => handleRemove(index)} className="trash-btn mdi mdi-delete"></i>
                            </h5>
                          ) : (
                            ""
                          )}
                        </Col>
                      )}
                    </Row>
                  );
                })}

              {editable && (
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "left",
                  }}>
                  <span style={{ cursor: "pointer", fontWeight: "bold" }} onClick={() => addMore()} className="addspan">
                    <span className="mdi mdi-plus-circle-outline"></span> Add New
                  </span>
                </div>
              )}
              <br />
              <div>
                <Col md="12">
                  <Row>
                    <Col md="5">
                      <Label>Average expenditure on drugs/alcohol per months(in Rs):</Label>
                    </Col>
                    <Col md="1">
                      <Label>Last Year</Label>
                    </Col>
                    <Col md="2">
                      <AvField
                        name="expndre_drgs_lst_yr"
                        placeholder=""
                        type="text"
                        disabled={!editable}
                        className="form-control"
                        //validate={{ required: { value: true } }}
                        onChange={handleChangeInput}
                        value={masterObject?.expndre_drgs_lst_yr}
                      />
                    </Col>
                    <Col md="1">
                      <Label>Current</Label>
                    </Col>
                    <Col md="2">
                      <AvField
                        name="expndre_drgs_crnt"
                        placeholder=""
                        type="text"
                        disabled={!editable}
                        className="form-control"
                        //validate={{ required: { value: true } }}
                        onChange={handleChangeInput}
                        value={masterObject?.expndre_drgs_crnt}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Label>Last drink/drugs taken:</Label>
                    </Col>
                    <Col md="2">
                      <AvField
                        name="last_drug_taken"
                        placeholder=""
                        type="text"
                        disabled={!editable}
                        className="form-control"
                        //validate={{ required: { value: true } }}
                        onChange={handleChangeInput}
                        value={masterObject?.last_drug_taken}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <div className="mt-3">
                        <Label>Diagnossis:</Label>
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="mt-3">
                        <AvField
                          name="diagnosis"
                          placeholder=""
                          type="textarea"
                          disabled={!editable}
                          className="form-control"
                          //validate={{ required: { value: true } }}
                          onChange={handleChangeInput}
                          value={masterObject?.diagnosis}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              {editable && (
                <Row className="mt-4">
                  <Col md={11}>
                    <FormGroup className="float-right ">
                      <button type="submit" className="save-continue-btn">
                        Save
                        <i style={{ fontSize: "13px" }}></i>
                      </button>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </div>
            {/* </CardBody>
                              </Card> */}
          </AvForm>
        </Col>
      </Row>
      {/* </Container>
      </div> */}
    </TabPane>
  );
};

export default DrinkingHistory;
