import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

export const getAdmissionDetails = (id) =>
  axios
    .get(`${API_URL}admission?admission_id=${id}`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
