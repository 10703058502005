import { get, post } from "../../../helpers/api_helper";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
export const getInternCertificates = () =>
  new Promise(async (resolve, reject) => {
    let response = await get(`${API_URL}certificates/intern`);
    resolve(response.data);
  });

export const createCertificate = async (data) =>
  await post(`${API_URL}certificates/intern`, data).then((res) => res);
