/* eslint-disable */
import { Card, CardBody, Col, FormGroup, Label, Row, Table } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { getDate } from "../../helpers/globalFunctions";
import { addTemperature, deleteTempBp, getTemperature } from "./api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import "./styles.css";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const Temperature = (props) => {
  const history = useHistory();
  const limit = 20;
  const [date, setDate] = useState(getDate(new Date()));
  const [page, setPage] = useState(1);
  const [keyWord, setKeyword] = useState("");
  const [tempData, setTempData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedAdmissionType, setSelectedAdmissionType] = useState(null);

  useEffect(() => {
    fetchData(date, keyWord, 1);
  }, [ keyWord, date]);

  function fetchData(date, keyWord, page) {
    // getTemperature("2023-07-06", keyWord, page, limit).then((data) => {
    getTemperature(date, keyWord, page, limit).then((data) => {
      setTotalCount(data.total_count);
      keyWord.length > 0 ? setTempData(data.details) : setTempData([...tempData, ...data.details]);
      setPage(keyWord.length > 0 ? 0 : page + 1);
    });
  }

  function fetchMoreData() {
    fetchData(date, keyWord, page);
  }

  function addMoreRow(tableIdx, tempIdx) {
    const data = [...tempData];
    data[tableIdx]["temp_bp_charts"]["temp_details"].push({});
    setTempData(data);
  }

  function handleDelete(tableIdx, tempIdx, { admission_id }, { _id }) {
    const data = [...tempData];
    data[tableIdx]["temp_bp_charts"]["temp_details"].splice(tempIdx, 1);
    setTempData(data);

    if (admission_id && _id) {
      deleteTempBp({ admission_id, temp_id: _id }).then((res) => res);
    }
  }

  let handleChange = (e, tableIdx, tempIdx) => {
    let { name, value } = e.target;
    let data = [...tempData];
    data[tableIdx]["temp_bp_charts"]["temp_details"][tempIdx][name] = value;
    setTempData(data);
  };

  function focusChange(e, tableIdx, tempIdx, { admission_id }, { _id }) {
    let { name, value } = e.target;
    const delayInMilliseconds = 2000;
    const inputBox = e.target;

    if (value.length > 0) {
      inputBox.classList.add("table-input-save");
    }

    setTimeout(() => {
      inputBox.classList.remove("table-input-save");
    }, delayInMilliseconds);

    let data = {
      // date: "2023-07-06",
      date: date,
      admission_id: admission_id,
      update_field: name,
      value,
    };
    data.temp_id = _id ? _id : null;
    addTemperature(data).then((res) => {
      if (res.status) {
        if (res.new_row) {
          const value = res.data.temp_details[res.data.temp_details.length - 1]._id;
          let data = [...tempData];
          data[tableIdx]["temp_bp_charts"]["temp_details"][tempIdx]["_id"] = value;
          setTempData(data);
        }
        return;
      }
    });
  }

  const handleKey = (e, { admission_id }) => {
    if (e.keyCode === 40 || e.keyCode === 38) {
      e.preventDefault();
    }
    if (e.keyCode === 13) {
      const inputs = e.target.parentNode.parentNode.parentNode.querySelectorAll(".table-input");
      const selectedIdx = Array.prototype.indexOf.call(inputs, e.target);
      if (selectedIdx < inputs.length - 1) {
        inputs[selectedIdx + 1].focus();
      }
      // if (selectedIdx === inputs.length - 1) {
      //   let { name, value } = e.target;
      //   let data = {
      //     date: date,
      //     admission_id: admission_id,
      //     [name]: value,
      //   };
      //   addTemperature(data).then((res) =>
      //     res.status === 200 ? setReload(!reload) : //console.log(res)
      //   );
      // }
      //
    }
  };

  const onFocus = (e) => {
    e.target.select();
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Temperature" />
          <Row>
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    <Col lg="3">
                      <FormGroup className="mx-2 my-3">
                        <Label htmlFor="date">Date</Label>
                        <AvField
                          // disabled={!editable}
                          name="date"
                          type="date"
                          className="form-control"
                          value={date}
                          onChange={(e) => {
                            setDate(e.target.value);
                            setTempData([]);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mx-2 my-3 position-relative">
                        <Label>Name/Reg</Label>
                        <AvField
                          name="text"
                          placeholder="Name/RegNo"
                          type="text"
                          className="form-control"
                          value={keyWord}
                          onChange={(e) => {
                            setKeyword(e.target.value);
                            setTempData([]);
                          }}
                        />
                        {keyWord?.length > 0 && (
                          <i
                            style={{
                              right: "10px",
                              top: "55%",
                              // transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setKeyword("");
                              setTempData([]);
                            }}
                            className="position-absolute">
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg">
                              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                            </svg>
                          </i>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup className="mx-2 my-3">
                        <Label>Admission Type</Label>
                        <Select
                          name="admission_type"
                          value={selectedAdmissionType}
                          options={[
                            {
                              label: "OP",
                              value: "0",
                            },
                            {
                              label: "IP",
                              value: "1",
                            },
                          ]}
                          onChange={(selected) => {
                            // handleSelect(selected, "admission_type");
                          }}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    <div className="table-responsive">
                      <InfiniteScroll
                        dataLength={tempData.length}
                        // style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                        // inverse={true} //
                        hasMore={tempData.length < totalCount}
                        loader={keyWord.length <= 0 ? <h6 className="text-center mt-2">Loading...</h6> : <></>}
                        endMessage={<h6 className="text-center mt-2">_________________________</h6>}
                        // style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                        // inverse={true} //
                        next={fetchMoreData}
                        // scrollableTarget="scrollableDiv"
                      >
                        <Table style={{ border: "1px solid #dadada" }} className="table table-bordered mb-0 table-hover ">
                          <thead>
                            <tr style={{ textAlign: "center" }}>
                              <th className="vertical-middle" scope="col" rowSpan={2}>
                                No
                              </th>
                              <th className="vertical-middle" rowSpan={2}>
                                Reg No
                              </th>
                              <th
                                className="vertical-middle"
                                style={{
                                  minWidth: "10rem",
                                }}
                                rowSpan={2}>
                                Name
                              </th>
                              <th className="vertical-middle" rowSpan={1} colSpan={3}>
                                Temperature
                              </th>
                              <th className="vertical-middle" rowSpan={2}>
                                BP
                              </th>

                              <th className="vertical-middle" rowSpan={2}>
                                Pulse
                              </th>
                              {props.options.edit && <th className="vertical-middle" rowSpan={2}></th>}
                            </tr>
                            <tr>
                              <th scope="row" className="text-center">
                                Morning
                              </th>
                              <th className="text-center" scope="row">
                                Noon
                              </th>
                              <th className="text-center" scope="row">
                                Night
                              </th>
                            </tr>
                          </thead>
                          <tbody style={{ border: "1px solid #dadada" }}>
                            {tempData?.map((data, idx) => {
                              return (
                                <>
                                  {data?.temp_bp_charts?.temp_details?.map((item, index) => {
                                    if (index === 0) {
                                      return (
                                        <tr className="vertical-middle" key={idx}>
                                          <th rowSpan={data?.temp_bp_charts?.temp_details.length} className="vertical-middle" scope="row">
                                            {idx + 1}
                                          </th>
                                          <td rowSpan={data?.temp_bp_charts?.temp_details.length} className="vertical-middle">
                                            {data.reg_no}
                                          </td>
                                          <td rowSpan={data?.temp_bp_charts?.temp_details.length} className="vertical-middle">
                                            <span
                                              className="table-link-hover"
                                              onClick={() =>
                                                history.push("/admission/" + data?.reg_no || "", {
                                                  id: data?.admission_id,
                                                })
                                              }>
                                              {data.fname + " " + data.lname}
                                            </span>
                                          </td>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.temp_morning_time !== "undefined" ? item?.temp_morning_time : ""}
                                              name="temp_morning_time"
                                              disabled={!props.options.edit}
                                            />
                                            <input
                                              onFocus={onFocus}
                                              placeholder="value"
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              style={{ cursor: "pointer" }}
                                              type="text"
                                              value={typeof item?.temp_morning !== "undefined" ? item?.temp_morning : ""}
                                              name="temp_morning"
                                              disabled={!props.options.edit}
                                            />
                                          </td>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.temp_noon_time !== "undefined" ? item?.temp_noon_time : ""}
                                              name="temp_noon_time"
                                              disabled={!props.options.edit}
                                            />
                                            <input
                                              onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              placeholder="value"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              type="text"
                                              value={typeof item?.temp_noon !== "undefined" ? item?.temp_noon : ""}
                                              name="temp_noon"
                                              disabled={!props.options.edit}
                                            />
                                          </td>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.temp_evening_time !== "undefined" ? item?.temp_evening_time : ""}
                                              name="temp_evening_time"
                                              disabled={!props.options.edit}
                                            />
                                            <input
                                              onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              style={{ cursor: "pointer" }}
                                              placeholder="value"
                                              type="text"
                                              value={typeof item?.temp_evening !== "undefined" ? item?.temp_evening : ""}
                                              name="temp_evening"
                                              disabled={!props.options.edit}
                                            />
                                          </td>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.bp_time !== "undefined" ? item?.bp_time : ""}
                                              name="bp_time"
                                              disabled={!props.options.edit}
                                            />
                                            <input
                                              onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              style={{ cursor: "pointer" }}
                                              placeholder="value"
                                              type="text"
                                              value={typeof item?.bp !== "undefined" ? item?.bp : ""}
                                              name="bp"
                                              disabled={!props.options.edit}
                                            />
                                          </td>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.pulse_time !== "undefined" ? item?.pulse_time : ""}
                                              name="pulse_time"
                                              disabled={!props.options.edit}
                                            />
                                            <input
                                              onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              style={{ cursor: "pointer" }}
                                              placeholder="value"
                                              type="text"
                                              value={typeof item?.pulse !== "undefined" ? item?.pulse : ""}
                                              name="pulse"
                                              disabled={!props.options.edit}
                                            />
                                          </td>
                                          {props.options.edit && (
                                            <td>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                                                    Add more
                                                  </Tooltip>
                                                }>
                                                <span onClick={() => addMoreRow(idx, index)}>
                                                  <i style={{ fontSize: "1.5em", cursor: "pointer" }} className="mdi mdi-plus-circle-outline"></i>
                                                </span>
                                              </OverlayTrigger>
                                            </td>
                                          )}
                                        </tr>
                                      );
                                    } else {
                                      return (
                                        <tr>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.temp_morning_time !== "undefined" ? item?.temp_morning_time : ""}
                                              name="temp_morning_time"
                                            />
                                            <input
                                              onFocus={onFocus}
                                              placeholder="value"
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              style={{ cursor: "pointer" }}
                                              type="text"
                                              value={typeof item?.temp_morning !== "undefined" ? item?.temp_morning : ""}
                                              name="temp_morning"
                                            />
                                          </td>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.temp_noon_time !== "undefined" ? item?.temp_noon_time : ""}
                                              name="temp_noon_time"
                                            />
                                            <input
                                              onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              placeholder="value"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              type="text"
                                              value={typeof item?.temp_noon !== "undefined" ? item?.temp_noon : ""}
                                              name="temp_noon"
                                            />
                                          </td>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.temp_evening_time !== "undefined" ? item?.temp_evening_time : ""}
                                              name="temp_evening_time"
                                            />
                                            <input
                                              onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              style={{ cursor: "pointer" }}
                                              placeholder="value"
                                              type="text"
                                              value={typeof item?.temp_evening !== "undefined" ? item?.temp_evening : ""}
                                              name="temp_evening"
                                            />
                                          </td>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.bp_time !== "undefined" ? item?.bp_time : ""}
                                              name="bp_time"
                                            />
                                            <input
                                              onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              style={{ cursor: "pointer" }}
                                              placeholder="value"
                                              type="text"
                                              value={typeof item?.bp !== "undefined" ? item?.bp : ""}
                                              name="bp"
                                            />
                                          </td>
                                          <td className="p-2">
                                            <input
                                              // onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              // onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input"
                                              placeholder="time"
                                              style={{ cursor: "pointer" }}
                                              type="time"
                                              value={typeof item?.pulse_time !== "undefined" ? item?.pulse_time : ""}
                                              name="pulse_time"
                                            />
                                            <input
                                              onFocus={onFocus}
                                              onBlur={(e) => focusChange(e, idx, index, data, item)}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => handleChange(e, idx, index)}
                                              onKeyDown={(e) => handleKey(e, data)}
                                              className="w-100 table-input mt-2"
                                              style={{ cursor: "pointer" }}
                                              placeholder="value"
                                              type="text"
                                              value={typeof item?.pulse !== "undefined" ? item?.pulse : ""}
                                              name="pulse"
                                            />
                                          </td>
                                          <td className="vertical-middle">
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                                                  Delete
                                                </Tooltip>
                                              }>
                                              <span onClick={() => handleDelete(idx, index, data, item)}>
                                                <i style={{ fontSize: "1.15em", paddingLeft: "0.15em", cursor: "pointer" }} className="fas fa-trash"></i>
                                              </span>
                                            </OverlayTrigger>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                                </>
                              );
                            })}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    </div>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Temperature;
