import PropTypes from "prop-types";
import React from "react";
import {/* Col,*/ Card, CardBody } from "reactstrap";
import CountUp from "react-countup";
// import ReactApexChart from "react-apexcharts";
import "./styles.css";

const MiniWidget = (props) => {
  return (
    <React.Fragment>
      <div className="my-container">
        {props.reports.map((report, key) => (
          <>
            <Card className="hover-effect">
              <CardBody
                className="rounded"
                style={{ minHeight: "7rem", maxHeight: "7rem" }}
              >
                <div className="float-end mt-2 position-relative">
                  {/* <ReactApexChart
                  options={report.options}
                  series={report.series}
                  type={report.charttype}
                  height={report.chartheight}
                  width={report.chartwidth}
                /> */}
                  <i
                    style={{
                      color: report.iconColor,
                      fontSize: report.iconSize,
                      opacity: report.iconOpacity,
                      cursor: "pointer",
                    }}
                    class={`${report.mainIcon || ""} ${
                      report.crossSign && "cross-sign"
                    } `}
                  ></i>
                </div>
                <div>
                  <h4 className="mb-1 mt-1">
                    <span>
                      <CountUp
                        end={report.value}
                        separator=","
                        prefix={report.prefix}
                        suffix={report.suffix}
                        decimals={report.decimal}
                      />
                    </span>
                  </h4>
                  <h5 className="text-muted h5 mb-0">{report.title}</h5>
                </div>
                {/* <p className="text-muted mt-3 mb-0">
                <span className={"text-" + report.color + " me-1"}>
                  <i className={report.icon + " me-1"}></i>
                  {report.badgeValue}
                </span>{" "}
                {report.desc}
              </p> */}
              </CardBody>
            </Card>
          </>
        ))}
      </div>
    </React.Fragment>
  );
};

export default MiniWidget;

MiniWidget.propTypes = {
  reports: PropTypes.array,
};
