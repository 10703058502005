/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import {
  getLocalbody,
  getDate,
  getFirstday,
  formatMoney,
  getArraySumByKey,
} from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import $ from "jquery";
const ProfitAndLoss = (props) => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [income, setIncome] = useState([]);
  const [expense, setExpense] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [balance, setBalance] = useState(0);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    fetch_all_data();
  }, [currentLocalbody]);

  const fetch_all_data = (from_date = "", to_date = "") => {
    let url = `${API_URL}profit_loss?localbody_id=` + currentLocalbody;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }
    url = url + "&from_date=" + from_date + "&to_date=" + to_date;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let income = res.data.income;
        let expense = res.data.expense;

        var totalExpense = 0;
        var totalIncome = 0;
        expense &&
          expense.map((el) => {
            totalExpense =
              totalExpense + getArraySumByKey(el.chart_of_account, "amount");
          });
        income &&
          income.map((el) => {
            totalIncome =
              totalIncome + getArraySumByKey(el.chart_of_account, "amount");
          });
        let balance = totalIncome - totalExpense;
        setIncome(income);
        setExpense(expense);
        setTotalExpense(totalExpense);
        setTotalIncome(totalIncome);
        setBalance(balance);
      });
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    fetch_all_data();
  };
  const handleSearch = () => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    fetch_all_data(date1, date2);
  };
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Profit And Loss" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="5">
                      <div className="mt-2" style={{ paddingTop: "18px" }}>
                        <Button
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                          style={{ marginLeft: "15px" }}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <Card>
                <CardBody>
                  <table
                    id="product_available_day"
                    className="table table-bordered dataTable"
                  >
                    <thead>
                      <th colSpan={4}>Expense</th>
                    </thead>
                    <tbody>
                      {expense &&
                        expense.map((el, index) => {
                          var val = {};
                          var length = null;
                          if (
                            el.chart_of_account &&
                            el.chart_of_account.length > 0
                          ) {
                            val = el.chart_of_account[0];
                            length = el.chart_of_account.length;
                          }
                          let data =
                            el.chart_of_account &&
                            el.chart_of_account.filter((e, key) => key !== 0);
                          let totExpense = getArraySumByKey(
                            el.chart_of_account,
                            "amount"
                          );
                          return (
                            <>
                              <tr>
                                <td rowSpan={length + 1}>
                                  {el?.acc_detail_name}
                                </td>
                                <td>
                                  <span style={{ float: "left" }}>
                                    {val?.account_name}
                                  </span>
                                  <span style={{ float: "right" }}>
                                    {formatMoney(val?.amount)}
                                  </span>
                                </td>
                              </tr>
                              {data &&
                                data.map((el) => {
                                  return (
                                    <tr>
                                      <td>
                                        <span style={{ float: "left" }}>
                                          {el?.account_name}
                                        </span>
                                        <span style={{ float: "right" }}>
                                          {formatMoney(el?.amount)}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              <tr>
                                <th>
                                  <span style={{ float: "right" }}>
                                    {formatMoney(totExpense)}
                                  </span>
                                </th>
                              </tr>
                            </>
                          );
                        })}
                      <tr>
                        <th colSpan={2}>
                          <span style={{ float: "left" }}>TOTAL</span>
                          <span style={{ float: "right" }}>
                            {formatMoney(totalExpense)}
                          </span>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardBody>
                  <table
                    id="product_available_day"
                    className="table table-bordered dataTable"
                  >
                    <thead>
                      <th colSpan={4}>Income</th>
                    </thead>
                    <tbody>
                      {income &&
                        income.map((el, index) => {
                          var val = {};
                          var length = null;
                          if (
                            el.chart_of_account &&
                            el.chart_of_account.length > 0
                          ) {
                            val = el.chart_of_account[0];
                            length = el.chart_of_account.length;
                          }
                          let data =
                            el.chart_of_account &&
                            el.chart_of_account.filter((e, key) => key !== 0);
                          let totIncome = getArraySumByKey(
                            el.chart_of_account,
                            "amount"
                          );

                          return (
                            <>
                              <tr>
                                <td rowSpan={length + 1}>
                                  {el?.acc_detail_name}
                                </td>
                                <td>
                                  <span style={{ float: "left" }}>
                                    {val?.account_name}
                                  </span>
                                  <span style={{ float: "right" }}>
                                    {formatMoney(val?.amount)}
                                  </span>
                                </td>
                              </tr>
                              {data &&
                                data.map((el) => {
                                  return (
                                    <tr>
                                      <td>
                                        <span style={{ float: "left" }}>
                                          {el?.account_name}
                                        </span>
                                        <span style={{ float: "right" }}>
                                          {formatMoney(el?.amount)}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              <tr>
                                <th>
                                  <span style={{ float: "right" }}>
                                    {formatMoney(totIncome)}
                                  </span>
                                </th>
                              </tr>
                            </>
                          );
                        })}
                      <tr>
                        <th colSpan={2}>
                          <span style={{ float: "left" }}>TOTAL</span>
                          <span style={{ float: "right" }}>
                            {formatMoney(totalIncome)}
                          </span>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <table
                    id="product_available_day"
                    className="table table-bordered dataTable"
                  >
                    <tbody>
                      <tr colSpan={3}>
                        <td style={{ textAlign: "center" }}>
                          <span style={{ fontWeight: "bold" }}>Income</span>{" "}
                          {":"} <span>{formatMoney(totalIncome)}</span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span style={{ fontWeight: "bold" }}>Expense</span>{" "}
                          {":"} <span>{formatMoney(totalExpense)}</span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span style={{ fontWeight: "bold" }}>Balance</span>{" "}
                          {":"} <span>{formatMoney(balance)}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default ProfitAndLoss;
