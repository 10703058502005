import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

export let fetchReligions = () =>
  axios
    .get(`${API_URL}options/religion`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) =>
      res.data.data.map((data) => ({
        label: data.name,
        value: data._id,
        name: "religion",
      }))
    )
    .catch((err) => err);

export let fetchCastes = () =>
  axios
    // .get(`${API_URL}options/caste?religion=${religion}`, {
    .get(`${API_URL}options/community`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) =>
      res.data.data.map((data) => ({
        label: data.name,
        value: data._id,
        name: "caste",
      }))
    )
    .catch((err) => err);

export let getRelationshiplist = () =>
  axios
    // .get(`${API_URL}options/caste?religion=${religion}`, {
    .get(`${API_URL}options/relationships`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) =>
      res.data.data.map((data) => ({
        label: data.name,
        value: data._id,
        name: "relationships",
      }))
    )
    .catch((err) => err);

// export let fetchRefferals = () =>
//     axios.get(`${API_URL}options/refferals`, {
//         headers: { "x-access-token": accessToken },
//     }).then(res =>
//         res.data.data.map(data => ({ label: data.name, value: data._id }))
//     ).catch(err => console.log(err))
// {
// admission_id: ObjectId
// spouse_details: {
//         name: String,
//         age: Number,
//         religion: ObjectId
//         caste: ObjectId,
//         education: ObjectId,
//         occupation: String,
//         income: Number
//       },
//       no_of_yrs_of_mrg: Number, // Number of years of marriage
//       marriage_type: Number , // 0 = arranged, 1= by choice,
//       family_accepted: Boolean,
//       prev_subsequent_mrrg: Boolean,
//       sprtd_due_to_addiction: Boolean, // Have you been seperated from your spouse due to your addiction (qustion in form)
//       prd_of_lng_separation: String, // Period of longest seperation
//       suspicious_of_wife: Number, // 0 = No, 1 = while abstinent, 2 = under the influence of drugs
//       other_history: String,// History of addiction in her family,her addictin history if any etc.
//       violence_in_fmly: Boolean, // Any instance of violence in family
//       phcl_vilnce_family: String, // Physical violence directed towards family members
//       brkng_artcls: String,// Breaking articles
//       violence_in_neibr: String, //Violence incidents with neighbours and outsiders
//       verbal_abusive: String,
//       children_details: [{
//         status: { type: Number , default: 0},
//         name: String,
//         age: Number,
//         gender: Number, // 1 = male, 0 = female , 3 = others
//         education: ObjectId
//         occupation: String,
//         marital_status: Number, ////0 = Un-married, 1 = married, 2 = Separated, 3= Divorced, 4 = Widowed
//         mental_status: String
//       }],
//       fmly_health_status: [{ // Family health status
//         problem: String,
//         prb_status: Number, // 0 = Yes, 1= No, 3= Don't know.
//         relation: String
//       }]
// reltn_with_fmly_mbrs: { // Relationship with family members
//         parents: String,// 1.No family, 2.mutual rejection, 3.indifferent feelings, 4.minor conflicts, 5.supportive, 6.not applicable
//         siblings: String,// 1.No family, 2.mutual rejection, 3.indifferent feelings, 4.minor conflicts, 5.supportive, 6.not applicable
//         spouse: String,// 1.No family, 2.mutual rejection, 3.indifferent feelings, 4.minor conflicts, 5.supportive, 6.not applicable
//         childern: String// 1.No family, 2.mutual rejection, 3.indifferent feelings, 4.minor conflicts, 5.supportive, 6.not applicable
//       },
//       fmly_dmg_sn_by_conslr: Number// 1. mid, 2. moderate, 3.severe
//     }
