import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import {  Col, FormGroup, Label, Row,  } from "reactstrap";
import { getDate } from "../../../helpers/globalFunctions";
import toastr from "toastr";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { getHistory, getMedicineChecklist } from "./api";
import { updateMedicine } from "../../MedicineChart/api";
import "./styles.css";
import moment from "moment";

const MedicalChart = ({ editable, id }) => {
  const [date, setDate] = useState(getDate(new Date()));
  const [details, setDetails] = useState({});
  const [reload, setReload] = useState(false);
  const [tableDatas, setTableDatas] = useState([]);

  useEffect(() => {
    getMedicineChecklist(date, id).then((data) => setDetails(data));
    getHistory(id).then((data, idx) => {
      data.map((item, idx) => {
        item.id = idx + 1;
        item.date = moment(item.date).format("DD-MM-YYYY");
        item.morning = (
          <ul>
            {item.medicines_morning.map((med) => (
              <li>{med.name}</li>
            ))}
          </ul>
        );
        item.noon = (
          <ul>
            {item.medicines_noon.map((med) => (
              <li>{med.name}</li>
            ))}
          </ul>
        );
        item.night = (
          <ul>
            <div>
              {item.medicines_night.map((med) => (
                <li>{med.name}</li>
              ))}
            </div>
          </ul>
        );
        return item
      });
      setTableDatas(data);
    });
  }, [id, date, reload]);

  let handleSelect = (e, name) => {
    let data = {};
    let objectId = null;
    let logObject = {};
    if (e !== null) {
      objectId = e.map((e) => e.value);
      logObject = e.map((e) => ({
        medicine: e._id,
        medication_id: e.medication_id,
      }));
    } else {
      objectId = [];
    }
    data.date = date;
    data.admission_id = id;
    data[name] = objectId;
    data[name + "_log"] = logObject;
    updateMedicine(data).then((data) => {
      setReload(!reload);
      data.status === 200 ? toastr.success(data.message) : console.log(data);
    });
  };

  const tableData = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Morning",
        field: "morning",
        sort: "asc",
        width: 200,
      },
      {
        label: "Noon",
        field: "noon",
        sort: "asc",
        width: 200,
      },
      {
        label: "Night",
        field: "night",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tableDatas,
  };
  return (
    <div className="mt-2">
      <AvForm>
        {editable && (
          <Row>
            <Col md={3}>
              <FormGroup className="mx-2">
                <Label className="ms-1" htmlFor="date">
                  Date
                </Label>
                <AvField name="date" type="date" id="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="mx-2">
                <Label className="ms-1" htmlFor="date">
                  Morning
                </Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  // menuPosition="auto"
                  name="medicines_morning"
                  defaultValue={details?.given_morning}
                  value={details?.given_morning}
                  isMulti={true}
                  options={details?.pending_morning}
                  onChange={(e) => {
                    handleSelect(e, "medicines_morning");
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="mx-2">
                <Label className="ms-1" htmlFor="date">
                  Noon
                </Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  // menuPosition="auto"
                  name="medicines_morning"
                  defaultValue={details?.given_noon}
                  value={details?.given_noon}
                  isMulti={true}
                  options={details?.pending_noon}
                  onChange={(e) => {
                    handleSelect(e, "medicines_noon");
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="mx-2">
                <Label className="ms-1" htmlFor="date">
                  Night
                </Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  // menuPosition="auto"
                  name="medicines_morning"
                  defaultValue={details?.given_night}
                  value={details?.given_night}
                  isMulti={true}
                  options={details?.pending_night}
                  onChange={(e) => {
                    handleSelect(e, "medicines_night");
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          {/* <Col md={12}> */}
          <MDBDataTable
            id="medical-chart"
            responsive
            // striped
            bordered
            data={tableData}
            info={true}
            searching={true}
            disableRetreatAfterSorting={true}
            entries={20}
          />
          {/* </Col> */}
        </Row>
      </AvForm>
    </div>
  );
};

export default MedicalChart;
