/* eslint-disable */
import React, { useState, useEffect,  } from "react";
import { Col, FormGroup,  Row, TabPane,  } from "reactstrap";

import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function FinancialHistory(props) {
  let { activeTabVartical, details, id, editable } = props;

  const [msterobj, setmsterobj] = useState({ debt_details: { total_amount: null } });

  const [finacialdmg, setfinacialdmg] = useState(null);
  const [hideAll, sethideAll] = useState(true);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    totalAmount();
  }, [reload]);

  useEffect(() => {
    let data = { ...msterobj };
    data.financial_history = details;

    setmsterobj(details);

    // if(details?.debt_details?.none==1){
    //   sethideAll(false)
    // }
  }, [details]);

  const handleInput = (e, mthd) => {
    let { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      value = checked ? 1 : 0;
    }

    if (mthd === 0) {
      if (type === "checkbox" && value == 1) {
        sethideAll(false);
        setmsterobj((prev) => ({
          ...prev,
          debt_details: {
            none: value,
          },
        }));
      } else {
        sethideAll(true);
        setmsterobj((prev) => ({
          ...prev,
          debt_details: {
            ...(prev?.debt_details || {}),
            [name]: value,
          },
        }));
        setReload((pre) => !pre);
      }
    }
  };

  function totalAmount() {
    let totalAmount = 0;
    Object.keys(msterobj?.debt_details).forEach((key) => {
      if (key !== "total_amount" && key !== "none") {
        totalAmount += Number(msterobj.debt_details[key]);
      }
    });

    setmsterobj((prev) => ({
      ...prev,
      debt_details: {
        ...prev.debt_details,
        total_amount: totalAmount,
      },
    }));
  }

  //console.log(msterobj);

  function handleOptionChangedmg(e) {
    setfinacialdmg(Number(e.target.value));
    setmsterobj({
      ...msterobj,
      fncl_dmg_prcvd_by_cnslr: Number(e.target.value),
    });
  }

  function handleValidSubmit() {
    msterobj.admission_id = props.id;
    axios
      .post(`${API_URL}admission/financial-history`, msterobj, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success(res.data.message);
        props.handleToggleBar("increment");
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  }

  return (
    <TabPane tabId={7}>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
          handleValidSubmit();
        }}>
        <Col md="12">
          <Row>
            <Col md="7">
              <h6 style={{ display: "inline" }}>1. Details of debts to be cleared</h6>
              <h6 style={{ float: "right" }}>Amount: {msterobj?.debt_details?.total_amount !== 0 && msterobj?.debt_details?.total_amount}</h6>
            </Col>
          </Row>
        </Col>
        {hideAll && (
          <div>
            <Col md="10" className="mt-2">
              <Row>
                <Col md="6">
                  <p style={{ marginLeft: "1rem" }}>1.1 Money borrowed from family and friends</p>
                </Col>

                <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
                  <AvField
                    disabled={!editable}
                    name="brd_frm_fmly"
                    type="number"
                    className="form-control form-control3"
                    placeholder="Amount"
                    onChange={(e) => {
                      handleInput(e, 0);
                    }}
                    value={msterobj?.debt_details?.brd_frm_fmly}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="10" className="mt-2">
              <Row>
                <Col md="6">
                  <p style={{ marginLeft: "1rem" }}>1.2 Loans from banks</p>
                </Col>

                <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
                  <AvField
                    disabled={!editable}
                    name="loans_frm_bnk"
                    type="number"
                    className="form-control form-control3"
                    placeholder="Amount"
                    onChange={(e) => {
                      handleInput(e, 0);
                    }}
                    value={msterobj?.debt_details?.loans_frm_bnk}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="10" className="mt-2">
              <Row>
                <Col md="6">
                  <p style={{ marginLeft: "1rem" }}>1.3 Loans from place of work</p>
                </Col>

                <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
                  <AvField
                    disabled={!editable}
                    name="loans_frm_wrk_plce"
                    type="number"
                    className="form-control form-control3"
                    placeholder="Amount"
                    onChange={(e) => {
                      handleInput(e, 0);
                    }}
                    value={msterobj?.debt_details?.loans_frm_wrk_plce}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="10" className="mt-2">
              <Row>
                <Col md="6">
                  <p style={{ marginLeft: "1rem" }}>1.4 Money borrowed from money lenders</p>
                </Col>

                <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
                  <AvField
                    disabled={!editable}
                    name="brd_frm_lenders"
                    type="number"
                    className="form-control form-control3"
                    placeholder="Amount"
                    onChange={(e) => {
                      handleInput(e, 0);
                    }}
                    value={msterobj?.debt_details?.brd_frm_lenders}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="10" className="mt-2">
              <Row>
                <Col md="6">
                  <p style={{ marginLeft: "1rem" }}>1.5 Money for redeeming articles from pawn shops</p>
                </Col>

                <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
                  <AvField
                    disabled={!editable}
                    name="rdmng_artcl_frm_pwn_shp"
                    type="number"
                    className="form-control form-control3"
                    placeholder="Amount"
                    onChange={(e) => {
                      handleInput(e, 0);
                    }}
                    value={msterobj?.debt_details?.rdmng_artcl_frm_pwn_shp}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="10">
              <Row>
                <Col md="6">
                  <p style={{ marginLeft: "1rem" }}>1.6 Outstanding debts at various shops</p>
                </Col>

                <Col md="4" style={{ display: "flex", justifyContent: "space-between" }}>
                  <AvField
                    disabled={!editable}
                    name="dbts_vrius_shps"
                    type="number"
                    className="form-control form-control3"
                    placeholder="Amount"
                    onChange={(e) => {
                      handleInput(e, 0);
                    }}
                    value={msterobj?.debt_details?.dbts_vrius_shps}
                  />
                </Col>
              </Row>
            </Col>
          </div>
        )}
        <Col md="10" className="mt-2">
          <Row>
            <Col md="6">
              <p style={{ marginLeft: "1rem" }}>
                1.7 None
                <input
                  disabled={!editable}
                  type="checkbox"
                  checked={msterobj?.debt_details?.none === 1 ? true : false}
                  name="none"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp;
              </p>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="6">
              <h6>2. Financial damage as perceived by the counsellor</h6>
            </Col>
            <Col md="5">
              <p>
                Mild{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  checked={msterobj?.fncl_dmg_prcvd_by_cnslr === 0}
                  value={0}
                  style={{ marginLeft: "10px" }}
                  onChange={handleOptionChangedmg}
                />{" "}
                &nbsp; Moderate{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  checked={msterobj?.fncl_dmg_prcvd_by_cnslr === 1}
                  value={1}
                  style={{ marginLeft: "10px" }}
                  onChange={handleOptionChangedmg}
                />
                &nbsp; Severe{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  checked={msterobj?.fncl_dmg_prcvd_by_cnslr === 2}
                  value={2}
                  style={{ marginLeft: "10px" }}
                  onChange={handleOptionChangedmg}
                />
              </p>
            </Col>
          </Row>
        </Col>

        <Row className="mt-4">
          <Col md={12} className="submit-btn-fixed">
            <FormGroup className="float-right ">
              <a className="save-continue-btn" onClick={() => props.handleToggleBar("decrement")}>
                <i class="fas fa-solid fa-less-than"></i>
              </a>
              <button type="submit" className="save-continue-btn m-2">
                Save and continue <i style={{ fontSize: "13px" }} className="mdi mdi-arrow-right"></i>
              </button>
              <a className="save-continue-btn" onClick={() => props.handleToggleBar("increment")}>
                <i class="fas fa-solid fa-greater-than"></i>
              </a>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </TabPane>
  );
}

export default FinancialHistory;
