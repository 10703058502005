import { Card, CardBody, Col, FormGroup,  Row, Table } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { getDate } from "../../helpers/globalFunctions";
import { addTestResult, getPatientResults, getPatientsOpt } from "./api";

import "./styles.css";
import { useHistory } from "react-router-dom";
import Select from "react-select";

const TestResult = (props) => {
  const history = useHistory();
  const [date, setDate] = useState(getDate(new Date()));
  const [tableData, setTableData] = useState({});
  const [reload, setReload] = useState(false);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [focusedValue, setFocusedValue] = useState(null);

  useEffect(() => {
    getPatientsOpt()
      .then((res) => setPatients(res))
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    // getPatientResults("6427f40ac7014b33083e7f6d", "2023-06-15")
    getPatientResults(selectedPatient?.value, date)
      .then((res) => setTableData(res?.data[0]))
      .catch((err) => console.log(err.response.data));
  }, [date, selectedPatient]);

  let handleChange = (e, category, test) => {
    let { name, value } = e.target;
    let data = { ...tableData };
    data.category[category].tests[test][name] = value;
    setTableData(data);
  };

  function focusChange(e, admission_id, test_id, date) {
    let { name, value } = e.target;

    const delayInMilliseconds = 2000;
    const inputBox = e.target;

    if (focusedValue !== value) {
      if (value.length > 0) {
        inputBox.classList.add("table-input-save");
      }

      setTimeout(() => {
        inputBox.classList.remove("table-input-save");
      }, delayInMilliseconds);

      let data = {
        date,
        admission_id,
        test_id,
        [name]: value,
      };

      addTestResult(data).then((res) => (res.status === 200 ? setReload(!reload) : console.log(res)));
    }
  }

  const handleKey = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38) {
      e.preventDefault();
    }
    if (e.keyCode === 13) {
      const inputs = document.querySelectorAll(".table-input");
      var currentInput = document.activeElement;
      var currentIndex = Array.prototype.indexOf.call(inputs, currentInput);
      var nextIndex = (currentIndex + 1) % inputs.length;
      inputs[nextIndex].focus();
    }
  };

  const handleSelectChange = (e, type) => {
    switch (type) {
      case "patient":
        setSelectedPatient(e);
        break;
      default:
        return;
    }
  };
  const onFocus = (e) => {
    setFocusedValue(e.target.value);
    e.target.select();
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Test Result" />
          <Row>
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    <Col lg="3">
                      <FormGroup className="mx-2 my-3">
                        {/* <Label htmlFor="date">Date</Label> */}
                        <AvField
                          // disabled={!editable}
                          name="date"
                          type="date"
                          className="form-control"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mx-2 my-3 position-relative">
                        <Select
                          name="qualification"
                          placeholder="Select"
                          value={selectedPatient}
                          onChange={(selected) => {
                            handleSelectChange(selected, "patient");
                          }}
                          options={patients}
                          classNamePrefix="select2-selection"
                          menuPosition="fixed"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    {tableData && (
                      <Col lg="4" className="d-flex align-items-center mt-1 ps-4" style={{ gap: "1rem" }}>
                        <div>
                          <h5 style={{ fontSize: "1.05rem" }}>Reg no: &nbsp; {tableData?.admissions?.reg_no || "Nil"}</h5>
                        </div>
                        <div>
                          <h5 style={{ fontSize: "1.05rem" }}>
                            Name: &nbsp;{" "}
                            <span
                              onClick={() => {
                                history.push("/admission/" + tableData?.admissions?.reg_no, {
                                  id: tableData?.admission_id,
                                });
                              }}
                              className="dashboard-link">
                              {tableData?.fname + " " + tableData?.lname}
                            </span>
                          </h5>
                        </div>
                      </Col>
                    )}
                  </Row>
                </AvForm>
              </CardBody>
            </Card>

            {tableData && (
              <Card id="show-tranisition">
                <CardBody>
                  <AvForm>
                    <Row>
                      <div className="table-responsive">
                        <Table id="test-result-table" style={{ border: "1px solid #dadada" }} className="table table-bordered mb-0 table-hover ">
                          <thead>
                            <tr style={{ textAlign: "center" }}>
                              {/* <th style={{ verticalAlign: "middle" }} scope="col">
        No
      </th> */}
                              <th style={{ verticalAlign: "middle" }}>{/* Category */} </th>
                              <th
                                style={{
                                  verticalAlign: "middle",
                                  minWidth: "10rem",
                                }}>
                                Test
                              </th>
                              <th style={{ verticalAlign: "middle" }}>Result</th>
                            </tr>
                          </thead>
                          <tbody style={{ border: "1px solid #dadada" }}>
                            {tableData?.category?.map((data, idx) => {
                              if (data?.tests.length > 0) {
                                return (
                                  <>
                                    {data?.tests.map((item, index) => {
                                      if (index === 0) {
                                        return (
                                          <tr key={index + "tstrslt"}>
                                            {/* <td
                      className="vertical-align-middle"
                      rowSpan={
                        data?.tests.length === 0
                          ? 1
                          : data?.tests.length
                      }
                    >
                      {idx + 1}
                    </td> */}
                                            <td className="category-name" rowSpan={data?.tests.length === 0 ? 1 : data?.tests.length}>
                                              {data?.name}
                                            </td>
                                            <td className="vertical-align-middle text-left">{item?.name}</td>
                                            <td className="vertical-align-middle">
                                              <div>
                                                <input
                                                  onFocus={onFocus}
                                                  onBlur={(e) => focusChange(e, tableData?.admission_id, item?._id, date)}
                                                  onWheel={(e) => e.target.blur()}
                                                  onChange={(e) => handleChange(e, idx, index)}
                                                  onKeyDown={(e) => handleKey(e, data)}
                                                  className="w-100 table-input"
                                                  style={{ cursor: "pointer" }}
                                                  type="text"
                                                  value={item?.value}
                                                  name="value"
                                                  disabled={!props.options.edit}
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      } else {
                                        return (
                                          <tr>
                                            <td>{item?.name}</td>
                                            <td>
                                              <input
                                                onFocus={onFocus}
                                                onBlur={(e) => focusChange(e, tableData?.admission_id, item?._id, date)}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => handleChange(e, idx, index)}
                                                onKeyDown={(e) => handleKey(e, data)}
                                                className="w-100 table-input"
                                                style={{ cursor: "pointer" }}
                                                type="text"
                                                value={item?.value}
                                                name="value"
                                                disabled={!props.options.edit}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })}
                                  </>
                                );
                              } else {
                                return <></>;
                              }
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default TestResult;
