/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, FormGroup, Label, Row, Card, CardBody } from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import InputMask from "react-input-mask";

import { getDate } from "../../../helpers/globalFunctions";
import { getAdmissionDetails } from "../main/api";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function NewAdmission(props) {
  const [masterObject, setmasterObject] = useState({
    state: "634d2b7f2bd8fbee01762bcb",
    state_name: "Kerala",
    district: "629b4df34d405c9d49192652",
    district_name: "Kannur",
    reg_date: getDate(new Date()),
  });
  const [values, setValues] = useState([{}]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedState, setSelectedState] = useState({
    label: "Kerala",
    value: "634d2b7f2bd8fbee01762bcb",
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "Kannur",
    value: "629b4df34d405c9d49192652",
  });
  const [selectedGender, setSelectedGender] = useState(null);
  // const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState(null);
  // const [selectedEmploymentValue, setSelectedEmploymentValue] = useState(false);
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
  // const [selectedQualification, setSelectedQualification] = useState(null);
  // const [qualificationOptions, setQualificationOptions] = useState([]);
  // const [selectedPlaceResidence, setSelectedPlaceResidence] = useState(null);
  const [selectedPriorTreatment, setSelectedPriorTreatment] = useState(null);
  const [selectedPriorValue, setSelectedPriorValue] = useState(false);
  const [selectedRefferal, setSelectedRefferal] = useState(null);
  const [referalOptions, setReferalOptions] = useState([]);
  // const [selectedLivingArrangements, setSelectedLivingArrangements] = useState(null);
  // const [livingArrangementsOptions, setlivingArrangementsOptions] = useState([]);
  const [selectedReligion, setSelectedReligion] = useState(null);
  const [religionOptions, setReligionOptions] = useState([]);
  const [selectedCaste, setSelectedCaste] = useState(null);
  const [casteOptions, setCasteOptions] = useState([]);
  // const [uploadProgress, setUploadProgress] = useState();
  const [priorTreatment, setPriorTreatment] = useState({});
  // const [counsellorOptions, setCounsellorOptions] = useState([]);
  // const [selectedCounsellor, setSelectedCounsellor] = useState(null);
  const [selectedCurrentStatus, setSelectedCurrentStatus] = useState(null);
  const [currentStatusOptions, setCurrentStatusOptions] = useState([]);
  const [selectedIDcardtype, setSelectedIDcardtype] = useState(null);
  // eslint-disable-next-line
  const [sameAddress, setSameAddress] = useState(false);
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  const [mobileOptions, setMobileOptions] = useState([]);
  const [selectedMob, setselectedMob] = useState(null);
  const years = [];
  const currentYear = new Date().getFullYear();
  const [DOB, setDOB] = useState({ YYYY: "", MM: "", DD: "" });
  const [admissionTimes, setAdmissionTimes] = useState([]);
  const [selectedAdmTimes, setSelectedAdmTimes] = useState(null);
  const [times, setTimes] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    const AddId = props.location.state;

    if (AddId) {
      const item_id = AddId.item;
      axios
        .get(`${API_URL}enquiry/single-enquiry?id=` + item_id, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          let data = {};
          let result = res.data.data;
          data.enq_id = result?._id;
          data.basic_id = result?.enq_basic_id;
          data.mobile = result?.mobile;
          data.first_name = result?.fname;
          data.last_name = result?.lname;
          data.pin = result?.pin;
          data.address = result?.address;
          data.state = result?.state;
          data.district = result?.district;
          let state_value = {};
          state_value.label = result?.state_name;
          state_value.value = result?.state;
          let district_value = {};
          district_value.label = result?.district_name;
          district_value.value = result?.district;
          setSelectedState(state_value);
          setSelectedDistrict(district_value);
          setmasterObject(data);
        });
    }
    fetch_all_patients();
    // eslint-disable-next-line
  }, []);

  for (let i = 1900; i <= currentYear; i++) {
    var k = {
      label: i,
      value: i,
    };
    years.push(k);
  }

  const handleYearChange = (e) => {
    setSelectedYear(e);
    setPriorTreatment({
      ...priorTreatment,
      year: e.value,
    });
    setmasterObject({ ...masterObject, prior_treatment_det: priorTreatment });
  };

  const addMore = () => {
    setValues([...values, {}]);
  };

  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });

    if (e.target.name == "dob") {
      const currentDate = new Date();
      const selectedDate = new Date(e.target.value);

      const yearDiff = currentDate.getFullYear() - selectedDate.getFullYear();
      const monthDiff = currentDate.getMonth() - selectedDate.getMonth();
      const dayDiff = currentDate.getDate() - selectedDate.getDate();

      let calculatedAge = yearDiff;

      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        calculatedAge--;
      }
      setmasterObject({
        ...masterObject,
        [name]: value,
        age: calculatedAge,
      });
    }
  };

  const handleChangeDob = (e) => {
    let { name, value } = e.target;
    const inputs = e.target.parentNode.parentNode.parentNode.querySelectorAll("input");
    const selectedIdx = Array.prototype.indexOf.call(inputs, e.target);

    const newDOB = { ...DOB };

    if (name === "DD") {
      newDOB.DD = value.length === 3 ? value.replace(/^0+/, "") : value.padStart(2, "0");
    } else if (name === "MM") {
      newDOB.MM = value.length === 3 ? value.replace(/^0+/, "") : value.padStart(2, "0");
    } else if (name === "YYYY") {
      newDOB.YYYY = value;
    }

    if (value >= 2 && selectedIdx < inputs.length - 1) {
      inputs[selectedIdx + 1].focus();
    }

    setDOB(newDOB);
  };

  //console.log(DOB, "dob");

  const handleCreateMobile = useCallback(
    (inputValue) => {
      setMobileOptions([...mobileOptions, { value: inputValue, label: inputValue }]);
      setselectedMob({ value: inputValue, label: inputValue });
      setmasterObject({
        ...masterObject,
        mobile: inputValue,
      });
    },
    [mobileOptions, masterObject]
  );

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/mobiles`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.mobile,
              value: el?._id,
            };
          });
        setMobileOptions(patient_data);
      });
  };

  useEffect(() => {
    let age;
    if (DOB.YYYY.length > 3) {
      const currentDate = new Date();
      age = currentDate.getFullYear() - DOB.YYYY;
    } else {
      age = "";
    }

    setmasterObject({
      ...masterObject,
      dob: `${DOB.YYYY ? DOB.YYYY + (DOB.MM.length > 0 ? "-" : "") : ""}${DOB.MM ? DOB.MM + "-" : ""}${DOB.DD ? DOB.DD : ""}`,
      age,
    });
  }, [DOB]);

  const handleSameaddress = (e, index) => {
    if (e.target.checked == true) {
      const address = masterObject.address;
      const list = [...values];
      list[index]["sp_address"] = address;
      list[index]["sp_state"] = masterObject.state;
      list[index]["sp_state_name"] = masterObject.state_name;
      list[index]["sp_district"] = masterObject.district;
      list[index]["sp_district_name"] = masterObject.district_name;
      setValues(list);
      setmasterObject({ ...masterObject, support_persons: list });
    } else {
      const list = [...values];
      list[index]["sp_address"] = "";
      list[index]["sp_state"] = "";
      list[index]["sp_state_name"] = "";
      list[index]["sp_district"] = "";
      list[index]["sp_district_name"] = "";
      setValues(list);
      setmasterObject({ ...masterObject, support_persons: list });
    }
  };
  function handleTableInputchange(e, index) {
    let { name, value } = e.target;
    const list = [...values];
    list[index][name] = e.target.value;

    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
  }

  function handleInputchange(e) {
    var name = e.target.name;
    var value = e.target.value;

    setPriorTreatment({
      ...priorTreatment,
      [name]: value,
    });
    setmasterObject({ ...masterObject, prior_treatment_det: priorTreatment });
  }

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
  };

  function handleChangeSelectItem(selected, index) {
    const list = [...values];
    list[index]["sp_state"] = selected.value;
    list[index]["sp_state_name"] = selected.label;

    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
    getDistrict(selected.value);
  }

  function handleChangeSelectItem1(selected, index) {
    const list = [...values];
    list[index]["sp_district"] = selected.value;
    list[index]["sp_district_name"] = selected.label;

    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
  }

  function handleChangeSelectItem2(selected, index) {
    const list = [...values];
    list[index]["sp_relation"] = selected.label;
    // list[index]["sp_district_name"] = selected.label;

    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
  }

  useEffect(() => {
    getStates();
    getDistrict(masterObject?.state);
    getQualification();
    getRefferallist();
    getlivingarrangement();
    getReligionlist();
    getCounsellors();
    getCurrentstatuslist();
    getCommunitylist();
    getRelationshiplist();
    getAdmissionTimes();
    // eslint-disable-next-line
  }, []);
  const getStates = () => {
    axios
      .get(`${API_URL}enquiry/state-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id, id: item.id });
        });
        setStateOptions(options);
      });
  };

  const getQualification = () => {
    axios
      .get(`${API_URL}enquiry/qualification-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.qualification_name, value: item._id });
        });
        // setQualificationOptions(options);
      });
  };
  const getDistrict = (_id) => {
    axios
      .get(`${API_URL}enquiry/district-list/?id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.district_name, value: item._id });
        });
        setDistrictOptions(options);
      });
  };

  const getRefferallist = () => {
    axios
      .get(`${API_URL}options/refferals`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
          return item;
        });
        setReferalOptions(options);
      });
  };
  const getAdmissionTimes = () => {
    axios
      .get(`${API_URL}options/admission-times`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data;
        setAdmissionTimes(data);
      });
  };
  const getlivingarrangement = () => {
    axios
      .get(`${API_URL}options/living-arrangements`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        // setlivingArrangementsOptions(options);
      });
  };

  const getReligionlist = () => {
    axios
      .get(`${API_URL}options/religion`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setReligionOptions(options);
      });
  };

  const getCommunitylist = (_id) => {
    axios
      .get(`${API_URL}options/community`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setCasteOptions(options);
      });
  };

  const getCounsellors = () => {
    axios
      .get(`${API_URL}user/counsellors`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.firstName, value: item._id });
        });
        // setCounsellorOptions(options);
      });
  };

  const getCurrentstatuslist = () => {
    axios
      .get(`${API_URL}options/current-status`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;

        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setCurrentStatusOptions(options);
      });
  };

  const getRelationshiplist = () => {
    axios
      .get(`${API_URL}options/relationships`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setRelationshipOptions(options);
      });
  };

  const handleSelectChange = (e, type) => {
    switch (type) {
      case "state":
        setSelectedState(e);
        setmasterObject({
          ...masterObject,
          state: e.value,
          state_name: e.label,
        });
        getDistrict(e.value);
        setSelectedDistrict(e.value);
        break;
      case "district":
        setSelectedDistrict(e);
        setmasterObject({
          ...masterObject,
          district: e.value,
          district_name: e.label,
        });
        break;
      case "gender":
        setSelectedGender(e);
        setmasterObject({
          ...masterObject,
          gender: e.value,
        });
        break;
      case "marital_status":
        setSelectedMaritalStatus(e);
        setmasterObject({
          ...masterObject,
          marital_status: e.value,
        });
        break;
      case "qualification":
        // setSelectedQualification(e);
        setmasterObject({
          ...masterObject,
          qualification: e.value,
        });
        break;
      case "employment_status":
        // setSelectedEmploymentStatus(e);
        setmasterObject({
          ...masterObject,
          employment_status: e.value,
        });
        if (e.value == 1) {
          // setSelectedEmploymentValue(true);
        }
        if (e.value == 0) {
          // setSelectedEmploymentValue(false);
        }
        break;
      case "recidence_place":
        // setSelectedPlaceResidence(e);
        setmasterObject({
          ...masterObject,
          recidence_place: e.value,
        });
        break;
      case "prior_treatment":
        setSelectedPriorTreatment(e);
        setmasterObject({
          ...masterObject,
          prior_treatment: e.value,
        });
        if (e.value == 1) {
          setSelectedPriorValue(true);
        }
        if (e.value == 0) {
          setSelectedPriorValue(false);
        }
        break;
      case "refferal":
        setSelectedRefferal(e);
        setmasterObject({
          ...masterObject,
          refferal: e.value,
        });
        break;
      case "living_arragement":
        // setSelectedLivingArrangements(e);
        setmasterObject({
          ...masterObject,
          living_arragement: e.value,
        });
        break;
      case "religion":
        setSelectedReligion(e);
        setmasterObject({
          ...masterObject,
          religion: e.value,
        });
        // getCommunitylist(e.value);
        // setSelectedCaste(e.value);
        break;
      case "community":
        setSelectedCaste(e);
        setmasterObject({
          ...masterObject,
          community: e.value,
        });
        break;
      // case "counsellor_id":
      //   setSelectedCounsellor(e);
      //   setmasterObject({
      //     ...masterObject,
      //     counsellor_id: e.value,
      //   });
      //   break;

      case "current_status":
        setSelectedCurrentStatus(e);
        setmasterObject({
          ...masterObject,
          current_status: e.value,
        });
        break;
      case "identity_type":
        setSelectedIDcardtype(e);
        setmasterObject({
          ...masterObject,
          identity_type: e.value,
          identity_no: "",
        });
        // if (e.value == 1) {
        //     setSelectedIDcardtypevalue(1)
        // }
        // if (e.value == 2) {
        //     setSelectedIDcardtypevalue(2)
        // }
        // if (e.value == 3) {
        //     setSelectedIDcardtypevalue(3)
        // }
        // if (e.value == 4) {
        //     setSelectedIDcardtypevalue(4)
        // }

        break;
      case "mobile":
        setselectedMob(e);
        getAdmissionDetails(e.value).then((res) => {
          handleDetails(res);
          setTimes(true);
        });
        break;
      case "admission_times":
        setSelectedAdmTimes(e);
        setmasterObject({
          ...masterObject,
          admission_times: e._id,
        });
        break;
      default:
        break;
    }
  };

  function handleDetails(res) {
    const details = res?.basic_details;

    let data = {};
    data.basic_id = details?.basic_id?._id;
    data.mobile = details?.basic_id?.mobile;
    data.first_name = details?.basic_id?.fname;
    data.last_name = details?.basic_id?.lname;
    data.pin = details?.basic_id?.pin;
    data.address = details?.basic_id?.address;
    data.state = details?.basic_id?.state?._id;
    data.district = details?.basic_id?.district?._id;
    data.admission_type = res?.admission_type;
    data.ip_number = res?.ip_number;
    // data.reg_date = details?.reg_date;
    data.aadhar_image = details?.aadhar_image;
    data.photo = details?.photo;
    data.aadhar_no = details?.aadhar_no;
    data.income = details?.income;
    data.reg_no = res?.reg_no;
    data.serial_number = res?.serial_number;
    // data.exp_discharge_date = res?.exp_discharge_date;
    data.remarks = res?.remarks;
    data.occupation = details?.occupation;
    data.dob = details?.dob;
    if (details?.dob) {
      const [YYYY, MM = "", DD = ""] = details?.dob?.split("-");
      setDOB({
        YYYY: YYYY,
        DD: DD,
        MM: MM,
      });

      if (YYYY.length > 3) {
        const currentDate = new Date();
        data.age = currentDate.getFullYear() - YYYY;
      } else {
        data.age = "";
      }
    }

    let district_value = {};
    district_value.label = details?.basic_id?.district?.district_name;
    district_value.value = details?.basic_id?.district?._id;
    setSelectedDistrict(district_value);

    let state_value = {};
    state_value.label = details?.basic_id?.state?.name;
    state_value.value = details?.basic_id?.state?._id;
    data.state_name = state_value?.label;
    getDistrict(details?.basic_id?.state?._id);
    setSelectedState(state_value);

    let gender_value = {};
    if (details?.gender === 0) {
      gender_value.label = "Female";
      gender_value.value = 0;
    }
    if (details?.gender === 1) {
      gender_value.label = "Male";
      gender_value.value = 1;
    }
    if (details?.gender === 2) {
      gender_value.label = "Others";
      gender_value.value = 2;
    }
    setSelectedGender(gender_value);

    // Maritial status
    let marital_status_value = {};
    data.marital_status = details?.marital_status;
    if (details?.marital_status === 0) {
      marital_status_value.label = "Unmarried";
      marital_status_value.value = 0;
    }
    if (details?.marital_status === 1) {
      marital_status_value.label = "Married";
      marital_status_value.value = 1;
    }
    if (details?.marital_status === 2) {
      marital_status_value.label = "Separated";
      marital_status_value.value = 2;
    }
    if (details?.marital_status === 3) {
      marital_status_value.label = "Divorced";
      marital_status_value.value = 3;
    }
    if (details?.marital_status === 4) {
      marital_status_value.label = "Widowed";
      marital_status_value.value = 4;
    }
    setSelectedMaritalStatus(marital_status_value);

    // Religion
    let religion_value = {};
    religion_value.label = details?.religion?.name;
    religion_value.value = details?.religion?._id;
    data.religion = details?.religion?._id;
    setSelectedReligion(religion_value);

    //Community
    let community_value = {};
    community_value.label = details?.community?.name;
    community_value.value = details?.community?._id;
    data.community = details?.community?._id;
    setSelectedCaste(community_value);

    //Referral
    let referral_value = {};
    referral_value.label = details?.refferal?.name;
    referral_value.value = details?.refferal?._id;
    data.refferal = details?.refferal?._id;
    setSelectedRefferal(referral_value);

    //Treatment for addiction
    let prior_treatment_value = {};
    data.prior_treatment = details?.prior_treatment;
    if (details?.prior_treatment === 0) {
      prior_treatment_value.label = "No";
      prior_treatment_value.value = 0;
    }
    if (details?.prior_treatment === 1) {
      prior_treatment_value.label = "Yes";
      prior_treatment_value.value = 1;
      setSelectedPriorValue(true);
      data.prior_treatment_det = details?.prior_treatment_det;
      data.place_of_treatment = details?.prior_treatment_det?.place_of_treatment;
      data.period = details?.prior_treatment_det?.period;
      setPriorTreatment(details?.prior_treatment_det);
      setSelectedYear({
        label: details?.prior_treatment_det?.year,
      });
    }
    setSelectedPriorTreatment(prior_treatment_value);

    // Identity type
    let identity_type_value = {};
    data.identity_type = details?.identity_type;
    if (details?.identity_type === "1") {
      identity_type_value.label = "Voter ID";
      identity_type_value.value = 1;
    }
    if (details?.identity_type === "2") {
      identity_type_value.label = "Aadharcard";
      identity_type_value.value = 2;
    }
    if (details?.identity_type === "3") {
      identity_type_value.label = "Pancard";
      identity_type_value.value = 3;
    }
    if (details?.identity_type === "4") {
      identity_type_value.label = "Passort";
      identity_type_value.value = 4;
    }

    if (details?.identity_type === "5") {
      identity_type_value.label = "Driving licence";
      identity_type_value.value = 5;
    }
    if (details?.identity_type === "6") {
      identity_type_value.label = "Ration Card";
      identity_type_value.value = 6;
    }

    data.identity_no = details?.identity_no;
    setSelectedIDcardtype(identity_type_value);

    setmasterObject(data);
  }

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("photo", e.target.files[0]);
    axios
      .post(`${API_URL}admission/photo`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          // setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setmasterObject({
            ...masterObject,
            [`photo`]: response.data.file.filename,
          });

          toastr.success("Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteImage = () => {
    setmasterObject({
      ...masterObject,
      [`photo`]: "",
    });
  };

  const handleValidSubmit = (e) => {
    if (!selectedMob) {
      toastr.error("Please create a mobile no");
      return;
    }
    axios
      .post(`${API_URL}admission`, masterObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        // if (res.data.status === 200) {
        toastr.success("Admission added successfully");
        formRef.current.reset();
        setValues([{}]);
        setselectedMob(null);
        setmasterObject({});
        setSelectedState(null);
        setSelectedDistrict(null);
        setSelectedGender(null);
        setSelectedReligion(null);
        setSelectedCaste(null);
        setSelectedRefferal(null);
        setSelectedMaritalStatus(null);
        setSelectedIDcardtype(null);
        setSelectedPriorTreatment(null);
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  const getValidationPattern = (selectedIDcardtype) => {
    switch (selectedIDcardtype) {
      case 1: // Voter ID
        return /^[A-Za-z]{3}\d{7}$/; // Example pattern, modify it as per your requirement
      case 2: // Aadhaar Card
        return /^\d{4}\s\d{4}\s\d{4}$/;
      case 3: // PAN Card
        return /^[A-Z]{5}\d{4}[A-Z]{1}$/;
      // case 4: // Driving License
      //    return /^\d{2}\/\d{5}\/\d{4}$/;
      case 4:
        return /^[A-Za-z]{1}[0-9]{7}$/;
      case (5, 6):
        return /^.*$/;

      default:
        return /^.*$/; // Default pattern to allow any value
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="New Admission" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {props.options?.edit ? (
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}>
                      <Row>
                        {/* <Col md="3">
                          <div className="mt-2">
                            <Label>Mobile</Label>
                            <AvField
                              name="mobile"
                              placeholder="Mobile"
                              type="Number"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                pattern: { value: "/^[6-9][0-9]{9}$/" },
                              }}
                              errorMessage="Enter valid 10 digit number"
                              onChange={handleChangeInput}
                              value={masterObject?.mobile}
                            />
                          </div>
                        </Col> */}
                        <Col md="3">
                          <div className="mt-2">
                            <Label>Mobile</Label>
                            <CreatableSelect
                              searchable={true}
                              openOnClick={false}
                              openMenuOnClick={false}
                              name="mobile"
                              id="mobile"
                              value={selectedMob}
                              options={mobileOptions}
                              classNamePrefix="select2-selection"
                              onChange={(selected) => {
                                handleSelectChange(selected, "mobile");
                              }}
                              onCreateOption={handleCreateMobile}
                              formatCreateLabel={(inputValue) =>
                                /^(0|91)?[6-9][0-9]{9}$/.test(inputValue) ? `Create mobile: ${inputValue}` : "Invalid mobile number"
                              }
                              isClearable
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mt-2">
                            <Label>First Name</Label>
                            <AvField
                              disabled={times}
                              name="first_name"
                              placeholder="First Name"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              value={masterObject?.first_name}
                              onChange={handleChangeInput}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mt-2">
                            <Label>Last Name</Label>
                            <AvField
                              disabled={times}
                              name="last_name"
                              placeholder="Last Name"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              onChange={handleChangeInput}
                              value={masterObject?.last_name}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mt-2">
                            <Label>Serial Number</Label>
                            <AvField
                              name="serial_number"
                              placeholder="Serial Number"
                              type="text"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              onChange={handleChangeInput}
                              value={masterObject?.serial_number}
                            />
                          </div>
                        </Col>

                        <Col md="3" className="mt-2">
                          <Row>
                            {times && (
                              <Col xl="6" className="mt-2">
                                <Label>Admission times</Label>
                                <Select
                                  name="admission_times"
                                  value={selectedAdmTimes}
                                  onChange={(selected) => {
                                    handleSelectChange(selected, "admission_times");
                                  }}
                                  options={admissionTimes}
                                  classNamePrefix="select2-selection"
                                  menuPosition="auto"
                                />
                              </Col>
                            )}
                            <Col xl={times ? "6" : "12"}>
                              <div className="mt-2">
                                <Label>Registration Date</Label>
                                <AvField
                                  name="reg_date"
                                  placeholder="Date"
                                  type="date"
                                  className="form-control"
                                  //validate={{ required: { value: true } }}
                                  onChange={handleChangeInput}
                                  // value={getDate(new Date())}
                                  value={masterObject?.reg_date}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col md="3" className="mt-2">
                          <Label>State</Label>
                          <Select
                            isDisabled={times}
                            name="state"
                            value={selectedState}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "state");
                            }}
                            options={stateOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        <Col md="3" className="mt-2">
                          <Label>District</Label>
                          <Select
                            isDisabled={times}
                            name="district"
                            value={selectedDistrict}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "district");
                            }}
                            options={districtOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        {/* </Row>
                    <Row> */}
                        <Col md="3">
                          <div className="mt-2">
                            <Label>Address</Label>
                            <AvField
                              disabled={times}
                              name="address"
                              placeholder="Address"
                              type="textarea"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              onChange={handleChangeInput}
                              value={masterObject?.address}
                              rows={1}
                            />
                          </div>
                        </Col>
                        <Col md="3" className="mt-2">
                          <Label>Pin</Label>
                          <AvField
                            name="pin"
                            type="text"
                            disabled={times}
                            // minLength={6}
                            // maxLength={6}
                            className="form-control"
                            placeholder="Pincode"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "Invalid pincode",
                              },
                            }}
                            onChange={handleChangeInput}
                            value={masterObject?.pin}
                          />
                        </Col>

                        <Col md="3">
                          <div className="mt-2 date-fields">
                            <Label>DOB</Label>
                            <div className="d-flex w-100" style={{ gap: "3px" }}>
                              <div style={{ flex: 1 }}>
                                <AvField
                                  name="DD"
                                  disabled={times}
                                  value={DOB?.DD}
                                  validate={{
                                    // min: { value: 1, errorMessage: "Day must be between 1 and 31" },
                                    // max: { value: 31, errorMessage: "Day must be between 1 and 31" },
                                    min: { value: 1, errorMessage: "1 to 31" },
                                    max: { value: 31, errorMessage: "1 to 31" },
                                    required: { value: DOB.MM.length > 0, errorMessage: "Year is required" },
                                  }}
                                  placeholder="DD"
                                  onChange={handleChangeDob}
                                  type="number"
                                  className="form-control"
                                />
                              </div>
                              <div style={{ flex: 1 }}>
                                <AvField
                                  name="MM"
                                  disabled={times}
                                  validate={{
                                    // min: { value: 1, errorMessage: "Month must be between 1 and 12" },
                                    // max: { value: 12, errorMessage: "Month must be between 1 and 12" },
                                    min: { value: 1, errorMessage: "1 to 12" },
                                    max: { value: 12, errorMessage: "1 to 12" },
                                    required: { value: DOB.DD.length > 0, errorMessage: "Month is required" },
                                  }}
                                  placeholder="MM"
                                  onChange={handleChangeDob}
                                  value={DOB?.MM}
                                  type="number"
                                  className="form-control"
                                />
                              </div>
                              <div style={{ flex: 2 }}>
                                <AvField
                                  name="YYYY"
                                  disabled={times}
                                  placeholder="YYYY"
                                  value={DOB?.YYYY}
                                  onChange={handleChangeDob}
                                  validate={{
                                    required: { value: true, errorMessage: "Year is required" },
                                    pattern: {
                                      value: /^[0-9]{4}$/,
                                      errorMessage: "Year must be a 4-digit number (YYYY)",
                                    },
                                  }}
                                  type="number"
                                  className="form-control flex-grow-1"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="1">
                          <div className="mt-2">
                            <Label>Age</Label>
                            <AvField
                              disabled={times}
                              name="age"
                              type="Number"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              onChange={handleChangeInput}
                              value={masterObject?.age || ""}
                            />
                          </div>
                        </Col>
                        <Col md="2" className="mt-2">
                          <Label>Gender</Label>
                          <Select
                            name="gender"
                            isDisabled={times}
                            placeholder="Select"
                            value={selectedGender}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "gender");
                            }}
                            options={[
                              { label: "Female", value: 0 },
                              { label: "Male", value: 1 },
                              { label: "Others", value: 2 },
                            ]}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="3" className="mt-2">
                          <Label>Marital Status</Label>
                          <Select
                            isDisabled={times}
                            name="marital_status"
                            value={selectedMaritalStatus}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "marital_status");
                            }}
                            options={[
                              { label: "Unmarried", value: 0 },
                              { label: "Married", value: 1 },
                              { label: "Separated", value: 2 },
                              { label: "Divorced", value: 3 },
                              { label: "Widowed", value: 4 },
                            ]}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        <Col md="3" className="mt-2">
                          <Label>Religion</Label>
                          <Select
                            isDisabled={times}
                            name="religion"
                            value={selectedReligion}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "religion");
                            }}
                            options={religionOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="3" className="mt-2">
                          <Label>Community</Label>
                          <Select
                            isDisabled={times}
                            name="community"
                            value={selectedCaste}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "community");
                            }}
                            options={casteOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="3" className="mt-2">
                          <Label>Referral</Label>
                          <Select
                            isDisabled={times}
                            name="refferal"
                            placeholder="Select"
                            value={selectedRefferal}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "refferal");
                            }}
                            options={referalOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="3" className="mt-2">
                          <Label>Treated For Addiction Before</Label>
                          <Select
                            isDisabled={times}
                            name="prior_treatment"
                            placeholder="Select"
                            value={selectedPriorTreatment}
                            //  validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "prior_treatment");
                            }}
                            options={[
                              { label: "Yes", value: 1 },
                              { label: "No", value: 0 },
                            ]}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        {selectedPriorValue == true ? (
                          <>
                            <Col md="3" className="mt-2">
                              <Label>Year Of Treatment</Label>
                              <Select
                                // isDisabled={!editable}
                                value={selectedYear}
                                onChange={handleYearChange}
                                name="year"
                                //  onChange={(e)=> handleInputchange(e)}
                                options={years}
                                classNamePrefix="select2-selection"
                                menuPosition="auto"
                              />
                            </Col>

                            <Col md="3" className="mt-2">
                              <Label>Place of Treatment</Label>
                              <AvField
                                //disabled={!editable}
                                name="place_of_treatment"
                                type="text"
                                className="form-control"
                                placeholder="Place of Treatment"
                                //onChange={handleChangeInput}
                                value={masterObject?.place_of_treatment}
                                onChange={(e) => handleInputchange(e)}
                              />
                            </Col>
                            <Col md="3" className="mt-2">
                              <Label>Days/months of sobriety</Label>
                              <AvField
                                //disabled={!editable}
                                name="period"
                                type="text"
                                className="form-control"
                                placeholder="Days/months of sobriety"
                                onChange={(e) => handleInputchange(e)}
                                // onChange={handleChangeInput}
                                value={masterObject?.period}
                              />
                            </Col>
                          </>
                        ) : null}

                        <Col md="3" className="mt-2">
                          <Label>ID Card Type</Label>
                          <Select
                            isDisabled={times}
                            name="identity_type"
                            placeholder="Select"
                            value={selectedIDcardtype}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "identity_type");
                            }}
                            options={[
                              {
                                label: "Voter ID",
                                value: 1,
                                placeholder: "Voter ID Number",
                              },
                              {
                                label: "Aadharcard",
                                value: 2,
                                placeholder: "Aadharcard Number",
                              },
                              {
                                label: "Pancard",
                                value: 3,
                                placeholder: "Pancard Number",
                              },
                              {
                                label: "Driving licence",
                                value: 5,
                                placeholder: "Driving licence Number",
                              },
                              {
                                label: "Passport",
                                value: 4,
                                placeholder: "Passport Number",
                              },
                              {
                                label: "Ration Card",
                                value: 6,
                                placeholder: "Ration Card Number",
                              },
                            ]}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        <Col md="3">
                          <div className="mt-2">
                            <Label>{selectedIDcardtype?.label || "ID Card No"}</Label>
                            {selectedIDcardtype?.value === 2 ? (
                              <AvField
                                disabled={times}
                                name="identity_no"
                                placeholder={selectedIDcardtype?.placeholder || "ID Card Number"}
                                type={selectedIDcardtype?.type || "text"}
                                className="form-control"
                                onChange={handleChangeInput}
                                value={masterObject?.identity_no}
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: /^\d{4}\s\d{4}\s\d{4}$/,
                                    message: "Invalid Aadhaar Card Number",
                                  },
                                }}
                                mask="9999 9999 9999"
                                tag={InputMask}
                              />
                            ) : (
                              <AvField
                                disabled={times}
                                name="identity_no"
                                placeholder={selectedIDcardtype?.placeholder || "ID Card Number"}
                                type={selectedIDcardtype?.type || "text"}
                                className="form-control"
                                onChange={handleChangeInput}
                                value={masterObject?.identity_no}
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: getValidationPattern(selectedIDcardtype?.value),
                                    //  message: getValidationErrorMessage(selectedIDcardtype?.value),
                                  },
                                }}
                              />
                            )}
                          </div>
                        </Col>
                        <Col md="3" className="mt-2">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Photo</Label>
                            {masterObject?.photo ? (
                              <div div className="img-wraps d-flex flex-column">
                                {masterObject.photo && masterObject.photo.startsWith("http") ? (
                                  <img alt="" width="150" height="150" src={`${masterObject.photo}`} />
                                ) : (
                                  <img alt="" width="150" height="150" src={`${API_URL}uploads/admissions/photos/${masterObject.photo}`} />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteImage}
                                  style={{ width: "150px" }}
                                  type="button">
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField name="photo" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
                            )}
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mt-2">
                            <Label>Discharge Date</Label>
                            <AvField
                              name="exp_discharge_date"
                              placeholder="Date"
                              type="date"
                              className="form-control"
                              //validate={{ required: { value: true } }}
                              onChange={handleChangeInput}
                              value={masterObject?.exp_discharge_date}
                            />
                          </div>
                        </Col>
                        <Col md="3" className="mt-2">
                          <Label>Current Status</Label>
                          <Select
                            name="current_status"
                            placeholder="Select"
                            value={selectedCurrentStatus}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "current_status");
                            }}
                            options={currentStatusOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        <Col md="3">
                          <div className="mt-2">
                            <Label>Remarks</Label>
                            <AvField
                              name="remarks"
                              placeholder="Remarks"
                              type="textarea"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              onChange={handleChangeInput}
                              value={masterObject?.remarks}
                              rows={1}
                            />
                          </div>
                        </Col>
                      </Row>

                      <br></br>
                      <h5>Support Person Details</h5>
                      <Row>
                        <Col xl="12">
                          <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                            <TableHead>
                              <TableRow style={{ textAlign: "center" }}>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  No.
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "200px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  Name
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "200px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  Address
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "150px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  State
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "150px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  District
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "150px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  Mobile
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "150px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  Relationship
                                </TableCell>

                                {values.length !== 1 ? (
                                  <TableCell
                                    style={{
                                      width: "0px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      paddingLeft: "0rem",
                                      paddingRight: "0rem",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Action
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values &&
                                values.map((item, index) => (
                                  <TableRow
                                    style={{
                                      verticalAlign: "top",
                                    }}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "center",
                                        fontSize: "12.819px",
                                        fontFamily: "IBM Plex Sans, sans-serif",
                                        color: "#495057",
                                        fontWeight: "500",
                                      }}>
                                      {index + 1}
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="sp_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        id="validationCustom05"
                                        onChange={(e) => handleTableInputchange(e, index)}
                                        value={item.sp_name}
                                      />
                                    </TableCell>
                                    <TableCell style={{ marginTop: "20px" }}>
                                      <AvField
                                        name="sp_address"
                                        placeholder="Address"
                                        type="textarea"
                                        className="form-control"
                                        id="validationCustom05"
                                        onChange={(e) => handleTableInputchange(e, index)}
                                        value={item.sp_address}
                                        rows={1}
                                      />
                                      <p></p>
                                      <AvInput
                                        type="checkbox"
                                        name="same_address"
                                        style={{ cursor: "pointer" }}
                                        id="parents-death"
                                        checked={sameAddress}
                                        onClick={(e) => {
                                          handleSameaddress(e, index);
                                        }}
                                      />
                                      &nbsp; &nbsp;
                                      <Label check for="checkbox">
                                        Same as patient address
                                      </Label>
                                    </TableCell>
                                    <TableCell>
                                      <Select
                                        name="sp_state_name"
                                        //value={selectedSPState}
                                        value={{
                                          label: item.sp_state_name,
                                          value: item.sp_state,
                                        }}
                                        // validate={{ required: { value: true } }}
                                        onChange={(selected) => {
                                          handleChangeSelectItem(selected, index);
                                        }}
                                        options={stateOptions}
                                        classNamePrefix="select2-selection"
                                        menuPosition="auto"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Select
                                        name="sp_district_name"
                                        value={{
                                          label: item.sp_district_name,
                                          value: item.sp_district,
                                        }}
                                        onChange={(selected) => {
                                          handleChangeSelectItem1(selected, index);
                                        }}
                                        options={districtOptions}
                                        classNamePrefix="select2-selection"
                                        menuPosition="auto"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="sp_mobile"
                                        type="number"
                                        className="form-control"
                                        placeholder="Mobile"
                                        id="validationCustom05"
                                        onChange={(e) => handleTableInputchange(e, index)}
                                        value={item.sp_mobile}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Select
                                        name="sp_relation"
                                        //value={selectedSPState}
                                        value={{
                                          label: item.sp_relation,
                                          value: item.sp_relation,
                                        }}
                                        // validate={{ required: { value: true } }}
                                        onChange={(selected) => {
                                          handleChangeSelectItem2(selected, index);
                                        }}
                                        options={relationshipOptions}
                                        classNamePrefix="select2-selection"
                                        menuPosition="auto"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {values.length !== 1 && (
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            cursor: "pointer",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => handleRemove(index)}></i>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Col>
                      </Row>
                      <span
                        onClick={() => addMore()}
                        style={{
                          width: "190px",
                          cursor: "pointer",
                          fontSize: "12.819px",
                          fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                          fontWeight: "500",
                        }}>
                        {" "}
                        + Add More{" "}
                      </span>
                      <Row className="mt-4">
                        <Col md={12} className="submit-btn-fixed">
                          <FormGroup className="float-right ">
                            <button type="submit" className="save-continue-btn">
                              Submit
                              <i style={{ fontSize: "13px" }}></i>
                            </button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </AvForm>
                  ) : (
                    <h4 style={{ fontStyle: "IBM Plex Sans, sans-serif" }}>You dont have access new admission</h4>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NewAdmission;
