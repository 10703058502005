/* eslint-disable */ import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUST_VISITLOG,
  GET_CUST_VISITLOG_SUCCESS,
  GET_CUST_VISITLOG_FAIL,
  GET_CUST_RECEIPTS,
  GET_CUST_RECEIPTS_SUCCESS,
  GET_CUST_RECEIPTS_FAIL,
  GET_CUST_INVOICE,
  GET_CUST_INVOICE_SUCCESS,
  GET_CUST_INVOICE_FAIL,
  GET_CUST_STATEMENT_FAIL,
  GET_CUST_STATEMENT_SUCCESS,
  GET_CUST_STATEMENT,
  ADD_CUSTOMERS,
  ADD_CUSTOMERS_SUCCESS,
  ADD_CUSTOMERS_FAIL,
  UPDATE_CUSTOMERS,
  UPDATE_CUSTOMERS_SUCCESS,
  UPDATE_CUSTOMERS_FAIL,
  DELETE_CUSTOMERS,
  DELETE_CUSTOMERS_SUCCESS,
  DELETE_CUSTOMERS_FAIL,
  GET_CUSTOMER_TYPE,
  GET_CUSTOMER_TYPE_SUCCESS,
  GET_CUSTOMER_TYPE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  customers: [],
  visitLog: [],
  custReceipts: [],
  custInvoice: [],
  custStatement: [],

  addingCustomer: false,
  deletingCustomer: false,
  addCustomerResponse: {},
  updateCustomerResponse: {},
  deleteCustomerResponse: {},
  customerIdToBeDeleted: "",
  customer: {},
  error: {},

  customerTypes: [],
};

const Customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        params: action.payload,
      };
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload.data,
      };
    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CUST_VISITLOG:
      return {
        ...state,
        params: action.payload,
      };
    case GET_CUST_VISITLOG_SUCCESS:
      return {
        ...state,
        visitLog: action.payload.data,
      };
    case GET_CUST_VISITLOG_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CUST_RECEIPTS:
      return {
        ...state,
        params: action.payload,
      };
    case GET_CUST_RECEIPTS_SUCCESS:
      return {
        ...state,
        custReceipts: action.payload.data,
      };
    case GET_CUST_RECEIPTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CUST_INVOICE:
      return {
        ...state,
        params: action.payload,
      };
    case GET_CUST_INVOICE_SUCCESS:
      return {
        ...state,
        custInvoice: action.payload.data,
      };
    case GET_CUST_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CUST_STATEMENT:
      return {
        ...state,
        params: action.payload,
      };
    case GET_CUST_STATEMENT_SUCCESS:
      return {
        ...state,
        custStatement: action.payload.data,
      };
    case GET_CUST_STATEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_CUSTOMERS:
      return {
        ...state,
        addingPopup: true,
        customer: action.payload,
      };

    case ADD_CUSTOMERS_SUCCESS:
      let newCustomers = [...state.customers, action.payload.data];
      return {
        ...state,
        addingCustomer: false,
        addCustomerResponse: {
          type: "success",
          message: "Customer created successfully",
        },
        customers: newCustomers,
        error: {},
      };

    case ADD_CUSTOMERS_FAIL:
      return {
        ...state,
        addingCustomer: false,
        addCustomerResponse: { type: "failure", message: "Creating customer failed" },
        error: action.payload,
      };
    case UPDATE_CUSTOMERS:
      return {
        ...state,
        updatingChartOfAccount: true,
        popup: action.payload,
      };

    case UPDATE_CUSTOMERS_SUCCESS:
      let newCustomer1 = state.customers.map((item) => {
        if (item._id == state.customer._id) {
          return action.payload.data;
        } else {
          return item;
        }
      });
      return {
        ...state,
        updatingCustomer: false,
        customer: "",
        updateCustomerResponse: {
          type: "success",
          message: "Customer updated successfully",
        },
        customers: newCustomer1,
      };
    // let newCustomerarr = [];
    // let newCustomer = state.customers.filter((customer) => {
    //   if (customer._id == state.customer._id) {
    //     //console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
    //     newCustomerarr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
    //   } else {
    //       newCustomerarr.push(customer);
    //   }
    // });
    // return {
    //   ...state,
    //   updatingCustomer: false,
    //   customer: "",
    //   updateCustomerResponse: {
    //     type: "success",
    //     message: "Customer updated successfully",
    //   },
    //   customers: newCustomerarr,
    // };

    case UPDATE_CUSTOMERS_FAIL:
      return {
        ...state,
        updatingCustomer: false,
        updateCustomerResponse: {
          type: "failure",
          message: "Updating customer failed",
        },
        error: action.payload,
      };
    case DELETE_CUSTOMERS:
      return {
        ...state,
        deletingPopup: true,
        popupIdToBeDeleted: action.payload,
      };

    case DELETE_CUSTOMERS_SUCCESS:
      let newData = state.customers.filter((e) => {
        return e._id != state.customerIdToBeDeleted;
      });
      return {
        ...state,
        deletingCustomer: false,
        customerIdToBeDeleted: "",
        deleteCustomerResponse: {
          type: "success",
          message: "Customer deleted successfully",
        },
        customers: newData,
      };

    case DELETE_CUSTOMERS_FAIL:
      return {
        ...state,
        deletingCustomer: false,
        deleteCustomerResponse: {
          type: "failure",
          message: "Deleting customer failed",
        },
        error: action.payload,
      };

    case GET_CUSTOMER_TYPE:
      return {
        ...state,
        params: action.payload,
      };
    case GET_CUSTOMER_TYPE_SUCCESS:
      return {
        ...state,
        customerTypes: action.payload.data,
      };
    case GET_CUSTOMER_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Customer;
