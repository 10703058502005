import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { Card, CardBody, Col, Row, Label, Button, Table, CardTitle } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import moment from "moment";
import "./dashboard.scss";
// import { jsonToExcel } from "../../../helpers/globalFunctions";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "../../../common/PdfPrint";
import  Swal from 'sweetalert2'
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";


const Admission = (props) => {
  const history = useHistory();
  const [masterObject, setmasterObject] = useState({ current_status: "6448c0a64d91dd125a878dcc" });
  const [datatable, setDataTable] = useState([]);
  const [counsellors, setcounsellors] = useState([]);
  const [currentStatus, setcurrentStatus] = useState([]);
  const [selectedCounsellor, setselectedCounsellor] = useState(null);
  const [selectedCurrentStatus, setselectedCurrentStatus] = useState({ label: "Admitted", value: "6448c0a64d91dd125a878dcc" });
  const [drinkingStatus, setDrinkingStatus] = useState(null);
  const [selectedAdmissionType, setSelectedAdmissionType] = useState(null);

  const [exportData, setExportData] = useState([]);

  const componentRef = useRef();

  useEffect(() => {
    fetchTableData();
    fetchCounsellors();
    fetchCurrentStatus();
    // eslint-disable-next-line
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function generatePDF(id) {
    axios
      .post(`admission/generate-form?admission_id=${id}`)
      .then((res) => {
        if (res.data.status) {
          fetchTableData();
          toastr.success(res.data.message);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
      });
  }

  function handleDeleteAdmission(id){
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios.delete(`${API_URL}admission?id=${id}`).then((res)=>{
          toastr.success(res?.data?.message)
          fetchTableData()
        }).catch((err)=>{
          console.log(err?.response?.data)
        })
      }
    })
  }

  // function handleExport() {
  //   if (exportData.length > 0) {
  //     jsonToExcel(exportData, `admissions-${moment().format("DD-MM-YY-hh-mm")}`);
  //   } else {
  //     toastr.warning("No data available");
  //   }
  // }

  function fetchTableData(filter) {
    let { keyword, from_date, to_date, counsellor_id, discharge_date, current_status, drinking_status, admission_type } = masterObject;
    let query = [
      keyword && `keyword=${keyword}`,
      from_date && `from_date=${from_date}`,
      to_date && `to_date=${to_date}`,
      counsellor_id && `counsellor_id=${counsellor_id}`,
      discharge_date && `discharge_date=${discharge_date}`,
      current_status && `current_status=${current_status}`,
      drinking_status && `drink_status=${drinking_status}`,
      admission_type && `admission_type=${admission_type}`,
    ]
      .filter(Boolean)
      .join("&");

    if (filter === false) query = "";
    axios
      .get(`${API_URL}admission/list?${query}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((res) => {
        const list = [];
        const exportDataArray = [];
        res.data.data.map((data, index) => {
          let item = {};
          let exportData = {};
          item.loading = false;
          item.id = index + 1;
          item._id = data._id;
          item.reg_no = data?.reg_no;
          item.serial_number = data?.serial_number;
          item.date_time = moment(data?.date).format("DD-MM-YYYY");
          item.name = `${data?.fname || ""}  ${data?.lname || ""}`;
          item.name1 = (
            <span
              className="table-link-hover"
              onClick={() =>
                history.push(`/admission/${data?.reg_no || data?.serial_number || data._id}`, {
                  id: data?._id,
                })
              }>
              {data?.fname + " " + data?.lname}
              {data?.counselling_status === 0 ? (
                <span
                  style={{
                    marginLeft: "10px",
                    display: "inline-block",
                    backgroundColor: "#1aa51a",
                    color: "white",
                    padding: "2px 5px",
                    borderRadius: "10px",
                    fontSize: "10px",
                  }}>
                  New
                </span>
              ) : null}
            </span>
          );
          item.mobile = data?.mobile;
          // item.email = data?.basic_details?.basic_id?.email
          item.staff = data?.addedBy?.firstName + " " + data?.addedBy?.lastName;
          item.support_person_name = data?.support_persons[0]?.sp_name;
          item.support_person_mobile = data?.support_persons[0]?.sp_mobile;
          item.current_status = data?.current_status?.name;
          item.age = data?.age;

          item.action = item.action = (
            <div className="d-flex align-items-center justify-content-center">
              <span
                style={{ color: "#495057", textDecoration: "none" }}
                // to={"/admission/" + data?.reg_no}
                onClick={() =>
                  history.push(`/admission/${data?.reg_no || data?.serial_number || data._id}`, {
                    id: data?._id,
                  })
                }>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}></i>
              </span>

              {res?.data?.form_view ? (
                <>
                  {data?.form_path?.length > 0 && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                          PDF
                        </Tooltip>
                      }>
                      <span
                        style={{ color: "#495057", textDecoration: "none" }}
                        // to={"/admission/" + data?.reg_no}
                        onClick={() => {
                          window.open(`${API_URL}admission/patient-form/${data?.reg_no || data?._id}`, "_blank");
                        }}>
                        <i
                          className="fas fa-print"
                          style={{
                            fontSize: "1.1em",
                            cursor: "pointer",
                            marginLeft: "0.1em",
                            marginRight: "0.4em",
                          }}></i>
                      </span>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                        Generate PDF
                      </Tooltip>
                    }>
                    <span
                      style={{ color: "#495057", textDecoration: "none" }}
                      // to={"/admission/" + data?.reg_no}
                      onClick={() => generatePDF(data?._id, index)}>
                      <i
                        className={`${item.loading ? "mdi mdi-spin mdi-loading" : "fas fa-file-pdf"}`}
                        style={{
                          fontSize: "1.1em",
                          cursor: "pointer",
                          marginLeft: "0.3em",
                          marginRight: "0.4em",
                        }}></i>
                    </span>
                  </OverlayTrigger>

                  <span onClick={()=>handleDeleteAdmission(item._id)}>
                  <i
                  className="fas fa-trash"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}></i>
                  </span>
                </>
              ) : null}

              {/* <i
              className="far fa-edit"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                // preUpdateEnquiry(item);
                // toTop();
              }}
            ></i> */}
              {/* <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    Delete
                  </Tooltip>
                }
              >
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "0.9em", cursor: "pointer" }}
                  onClick={() => {
                    deleteAdmission(data._id);
                  }}
                ></i>
              </OverlayTrigger> */}
            </div>
          );
          list.push(item);

          // Export data
          exportData["Serial No"] = item.serial_number;
          exportData["Admission Date"] = moment(data?.date).format("DD-MM-YYYY");
          exportData.Name = item.name;
          exportData.Address = data?.address;
          exportData.Mobile = data?.mobile;
          exportData["Counsellor Name"] = data?.counsillor_name;
          exportData["Discharge Date"] = data?.exp_discharge_date ? moment(data?.exp_discharge_date).format("DD-MM-YYYY") : "";
          exportDataArray.push(exportData);

          return item;
        });
        setDataTable(list);
        setExportData(exportDataArray);
      })
      .catch((err) => {});
  }

  function fetchCounsellors() {
    axios
      .get(`${API_URL}user/counsellors`, {
        headers: { "x-access-token": accessToken },
      })
      .then((res) => {
        let counsellors = res.data.data;
        let options =
          counsellors &&
          counsellors.map((e) => {
            return {
              label: `${e.firstName} ${e.lastName}`,
              value: e._id,
            };
          });
        setcounsellors(options);
      })
      .catch((error) => {});
  }

  function fetchCurrentStatus() {
    axios
      .get(`${API_URL}admission/current-status`, {
        headers: { "x-access-token": accessToken },
      })
      .then((res) => {
        let allStatus = res?.data?.data;
        let options =
          allStatus &&
          allStatus.map((e) => {
            return {
              label: e.name,
              value: e._id,
            };
          });
        setcurrentStatus(options);
      })
      .catch((error) => {});
  }

  function handleInput(input) {
    let value = input.target.value;
    let name = input.target.name;

    setmasterObject({ ...masterObject, [name]: value });
  }

  function handleSelect(selected, name) {
    switch (name) {
      case "counsellor_id":
        setselectedCounsellor(selected);
        break;
      case "current_status":
        setselectedCurrentStatus(selected);
        break;
      case "drinking_status":
        setDrinkingStatus(selected);
        break;
      case "admission_type":
        setSelectedAdmissionType(selected);
        break;
      default:
        break;
    }
    setmasterObject({ ...masterObject, [name]: selected.value });
  }

  let reset = async () => {
    let filter = false;
    setmasterObject(null);
    setselectedCounsellor(null);
    setselectedCurrentStatus(null);
    setDrinkingStatus(null);
    setSelectedAdmissionType(null);
    fetchTableData(filter);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Serial No",
        field: "serial_number",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "reg_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date_time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name1",
        sort: "asc",
        width: 270,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Age",
      //   field: "age",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Support Person",
        field: "support_person_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Support Mobile",
        field: "support_person_mobile",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "current_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: datatable,
  };

  let drinkingOptions = [
    { label: "No News", value: "0" },
    { label: "Continues to drink", value: "1" },
    { label: "Substituting with drugs", value: "2" },
    { label: "Severe relapses, sober", value: "3" },
    { label: "Mild relapses, sobel", value: "4" },
    { label: "No relapse", value: "5" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Admissions" />

          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Name/Mobile/Pin/Reg No/Serial No</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          placeholder="Name/Mobile/Pin/Reg No/Serial No"
                          className="form-control"
                          type="text"
                          onChange={handleInput}
                          value={masterObject?.keyword || ""}
                          id="mobile"
                          name="keyword"
                        />
                        {/* </div> */}
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From date</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          value={masterObject?.from_date || ""}
                          onChange={handleInput}
                          id="from_date"
                          name="from_date"
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To date</Label>
                        <input className="form-control" type="date" id="to_date" value={masterObject?.to_date || ""} name="to_date" onChange={handleInput} />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Counsellor</Label>
                        <Select
                          //isMulti
                          options={counsellors}
                          value={selectedCounsellor}
                          name="counsellor_id"
                          v
                          onChange={(e) => {
                            handleSelect(e, "counsellor_id");
                          }}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Admission status</Label>
                        <Select
                          //isMulti
                          name="current_status"
                          value={selectedCurrentStatus}
                          onChange={(e) => {
                            handleSelect(e, "current_status");
                          }}
                          options={currentStatus}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Discharge date</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          value={masterObject?.discharge_date || ""}
                          onChange={handleInput}
                          id="discharge_date"
                          name="discharge_date"
                        />
                        {/* </div> */}
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Drinking status</Label>
                        <Select
                          //isMulti
                          name="drinking_status"
                          value={drinkingStatus}
                          menuPosition="auto"
                          onChange={(e) => {
                            handleSelect(e, "drinking_status");
                          }}
                          options={drinkingOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Admission Type</Label>
                        <Select
                          name="admission_type"
                          value={selectedAdmissionType}
                          options={[
                            {
                              label: "OP",
                              value: "0",
                            },
                            {
                              label: "IP",
                              value: "1",
                            },
                          ]}
                          onChange={(selected) => {
                            handleSelect(selected, "admission_type");
                          }}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </div>
                    </Col>

                    <Col md="3" style={{ paddingTop: "0px" }}>
                      <div style={{ transform: "translateY(-30%)", top: "50%" }} className="position-relative mb-3 d-flex ">
                        <Button className="me-3" color="primary" type="submit" onClick={fetchTableData}>
                          Search
                        </Button>

                        <Button className="me-3" onClick={handlePrint} color="success" type="button">
                          Export
                        </Button>

                        <Button color="danger" type="reset" onClick={reset}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Card>
              <CardBody>
                <MDBDataTable
                  id="admissionTableId"
                  entries={20}
                  responsive
                  searching={true}
                  info={true}
                  disableRetreatAfterSorting={true}
                  bordered
                  data={data}
                />
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
      <div>
        <div style={{ display: "none" }}>
          <ComponentToPrint ref={componentRef}>
            <div id="export-details" className="border p-4">
              <Row>
                <Col lg={12}>
                  <h3 style={{ textAlign: "center", marginTop: "10px", marginBottom: 0 }}>Pratheeksha De Addiction Centre</h3>
                  <div style={{ textAlign: "center" }}>Nayanar Road, Ponniyam West (PO),</div>
                  <div style={{ textAlign: "center" }}>Thalassery 670641,</div>
                  <div style={{ textAlign: "center" }}>Kannur, Kerala India.</div>

                  <CardTitle className="h3 text-center mt-2">
                    {selectedCurrentStatus ? `${selectedCurrentStatus?.label} patient details` : "All patient details"}
                  </CardTitle>

                  <div className="printing-header">
                    <ul>
                      <li hidden={!masterObject?.from_date}>From Date : {masterObject?.from_date && moment(masterObject?.from_date).format("DD/MM/YYYY")}</li>
                      <li hidden={!masterObject?.to_date}>To Date : {masterObject?.to_date && moment(masterObject?.to_date).format("DD/MM/YYYY")}</li>
                    </ul>
                  </div>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Serial No</th>
                          <th>Admission Date</th>
                          <th style={{ maxWidth: "100px" }}>Name</th>
                          <th style={{ maxWidth: "150px" }}>Address</th>
                          <th>Mobile</th>
                          <th>Counsellor Name</th>
                          <th>Discharge Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {exportData.map((item, idx) => {
                          return (
                            <tr>
                              <th scope="row">{idx + 1}</th>
                              <td>{item["Serial No"]}</td>
                              <td>{item["Admission Date"]}</td>
                              <td style={{ maxWidth: "100px" }}>{item.Name}</td>
                              <td style={{ maxWidth: "150px" }}>{item.Address}</td>
                              <td>{item.Mobile}</td>
                              <td>{item["Counsellor Name"]}</td>
                              <td>{item["Discharge Date"]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <div className="printing-footer">
                    <ul>
                      <li>Exported Date : {moment().format("DD/MM/YYYY")}</li>
                      <li>Exported Time : {moment().format("hh:mm a")}</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </ComponentToPrint>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Admission;
