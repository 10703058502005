/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label, Container, Progress, Input, CardHeader, Badge } from "reactstrap";
import axios from "axios";
import toastr from "toastr";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import { getUserId } from "../../../helpers/globalFunctions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { result } from "lodash";
const Notification = (props) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [tableData, settableData] = useState({});
  const [list, setList] = useState([]);
  const userId = getUserId();

  useEffect(() => {
    get_All_Notificatin();
  }, []);

  function change_Status(id) {
    //console.log("id", id);
    let dat = { message_id: id };
    //console.log("dat", dat);
    axios
      .put(`${API_URL}notification/view-status`, dat, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        //console.log("res", res.data);
        if (res.data.status === 200) {
          get_All_Notificatin();
        }
      });
  }

  function get_All_Notificatin() {
    axios
      .get(`${API_URL}notification?&user_id=${userId}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        //console.log("list notification", res);
        var result = res?.data?.data;
        //console.log("result", result);
        let readData = [];

        result?.notifications?.map((item, index) => {
          //console.log("itemtalble", item);

          item.id = index + 1;

          item.time = moment(item.time, "HH:mm").format("h:mm A");
          item.date = moment(item?.date).format("DD-MM-YYYY");

          if (item?.read_status == 0) {
            item.read_status = (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute" }}>
                    Mark as Read
                  </Tooltip>
                }>
                <span>
                  {" "}
                  <Badge style={{ cursor: "pointer" }} onClick={() => change_Status(item._id)} className="bg bg-danger">
                    UnRead
                  </Badge>
                </span>
              </OverlayTrigger>
            );
          } else {
            item.read_status = <Badge className="bg bg-success">Readed</Badge>;
          }

          readData.push(item);
        });

        setList(readData);
      });
  }
  //console.log("list", list);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 90,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 90,
      },
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 90,
      },
      {
        label: "Message",
        field: "body",
        sort: "asc",
        width: 90,
      },
      {
        label: "Status",
        field: "read_status",
        sort: "asc",
        width: 90,
      },
    ],
    rows: list,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb breadcrumbItem="Notifications" />
          <Card>
            <CardBody>
              <MDBDataTable id="notificationTable" responsive bordered data={data} disableRetreatAfterSorting={true} entries={50} />
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notification;
