/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import FormGroup from "@mui/material/FormGroup";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Label, Media, CardHeader, Collapse, Form, Input } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./grouptherapy.scss";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const GroupTherapy = (props) => {
  const formRef = useRef();

  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientOptions, setPatientOptions] = useState([]);
  const [counsellingType, setCounsellingType] = useState("");
  const [values, setValues] = useState([{}]);
  const [isOpen, setIsOpen] = useState(false);
  const [patientvalues, setPatientValues] = useState(false);
  const [patientprevvalues, setPatientPrevValues] = useState(false);
  const [masterObject, setmasterObject] = useState({});
  const [selectedGender, setSelectedGender] = useState(null);
  const [grouptherapyIdTobeDeleted, setGrouptherapyIdTobeDeleted] = useState(null);
  const [grouptherapyIdTobeUpdated, setGrouptherapyIdTobeUpdated] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [issues, setIssues] = useState([]);
  const [selectedValue, setSelectedValue] = useState([{}]);

  const toggle = () => setIsOpen(!isOpen);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();

  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetch_all_patients();
    handleTableData();
    fetch_previous_grouptherapy_details();
    fetch_all_issues();
  }, []);

  const handleRadioChange = (e, index) => {
    var list = [...values];
    list[index] = { issue: "", code: "" };
    list[index].issue = e.target.id;
    list[index].code = e.target.value;
    setSelectedValue(e.target.value);
    setValues(list);
    setmasterObject({
      ...masterObject,
      details: list,
    });
  };

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: `${el?.fname} ${el?.lname} ${el?.reg_no || el?.serial_number || ""}`,
              value: el?._id,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  const fetch_all_issues = () => {
    axios
      .get(`${API_URL}group-therapy/issues`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let data = res.data.data;

        setIssues(data);
      });
  };
  const handleSelectChange = async (selected, type) => {
    switch (type) {
      case "admission_id":
        setSelectedPatient(selected);
        setPatientValues(true);

        //fetch_previous_medication_details(selected.value)
        let data = await fetch_patient_details(selected.value);
        let previous_data = await fetch_previous_grouptherapy_details(selected.value);
        // //console.log("previous_data", previous_data)
        setmasterObject({
          ...masterObject,
          mobile: data.mobile,
          age: data.age,
          gender: data.gender,
          admitted_date: data.admitted_date,
          admission_id: selected.value,
          counsellor: data.counsellor,
          prev_from_date: previous_data.from_date,
          prev_to_date: previous_data.to_date,
          prev_therapist: previous_data.therapist,
          prev_details: previous_data.details,
          previous_data: previous_data,
        });

        break;
      case "type":
        setCounsellingType(selected);
        setmasterObject({
          ...masterObject,
          type: selected.value,
        });
      default:
        break;
    }
  };
  const fetch_patient_details = (_id) => {
    return axios
      .get(`${API_URL}admission?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        //console.log("qq", result);

        let data = {};
        data.mobile = result?.basic_details?.basic_id?.mobile;
        data.age = result?.basic_details?.age;
        //data.gender = result?.basic_details?.gender
        data.counsellor = result?.counsellor_id?.firstName;
        data.admitted_date = moment(result?.basic_details?.reg_date).format("DD-MM-YYYY");
        let gender_value = {};

        if (result?.basic_details?.gender == 0) {
          data.gender = "Female";
        }
        if (result?.basic_details?.gender == 1) {
          data.gender = "Male";
        }
        if (result?.basic_details?.gender == 2) {
          data.gender = "Others";
        }

        setSelectedGender(gender_value);
        return data;
      });
  };

  const fetch_previous_grouptherapy_details = (_id) => {
    return axios
      .get(`${API_URL}group-therapy/latest?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        //console.log("aa", result);
        if (result.length > 0) {
          setPatientPrevValues(true);
        } else {
          setPatientPrevValues(false);
        }

        return result;
      });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const preUpdateGrouptherapy = (item) => {
    setGrouptherapyIdTobeUpdated(item?._id);
    axios
      .get(`${API_URL}group-therapy?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        //item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname
        item._id = result?._id;
        item.admission_id = result?.admission_id?._id;
        item.reg_no = result?.admission_id?.reg_no;
        item.staff = result?.addedBy?.firstName;
        item.from_date = result?.from_date;
        item.to_date = result?.to_date;

        item.group = result?.group;
        item.therapist = result?.therapist;
        item.details = result?.details;
        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };
        setValues(result?.details);

        setSelectedPatient(patient);

        setmasterObject(item);
      });
  };
  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}group-therapy?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        item.from_date = moment(result?.from_date).format("DD-MM-YYYY");
        item.to_date = moment(result?.to_date).format("DD-MM-YYYY");
        item.reg_no = result?.admission_id?.reg_no;
        item.staff = result?.addedBy?.firstName;

        item.group = result?.group;
        item.therapist = result?.therapist;
        item.details = result?.details;
        // result?.details.map((value,index)=>{
        //     item.issue=

        // })

        //console.log("item", item);

        setPopupData(item);
        setPopupView(true);
      });
  };

  function handleTableData() {
    var url = `${API_URL}group-therapy/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        result.map((item, index) => {
          item.id = index + 1;
          item.name = (
            <span
              className="table-link-hover"
              onClick={() =>
                history.push("/admission/" + item?.admission_id?.reg_no || "", {
                  id: item?.admission_id._id,
                })
              }>
              {`${item?.admission_id?.basic_details?.basic_id?.fname} ${item?.admission_id?.basic_details?.basic_id?.lname} ${
                item?.admission_id?.admission_times?.label || ""
              }`}
            </span>
          );
          item.from_date = moment(item?.from_date).format("DD-MM-YYYY");
          item.to_date = moment(item?.to_date).format("DD-MM-YYYY");
          item.reg_no = item?.admission_id?.reg_no;
          item.firstName = item?.addedBy?.firstName;
          // item.duration = item?.duration;
          item.group = item?.group;
          item.therapist = item?.therapist;

          if (props.options.edit) {
            item.action = (
              <>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    preUpdateGrouptherapy(item);
                    toTop();
                  }}></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "0.9em", cursor: "pointer" }}
                  onClick={() => {
                    setGrouptherapyIdTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </>
            );
          } else {
            item.action = (
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
            );
          }
        });

        setTableData(result);
      });
  }

  const customStyles = {
    lineHeight: "1.8",
  };
  const reset = () => {
    formRef.current.reset();
    // handleTableData();
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "reg_no",
        sort: "asc",
        width: 270,
      },
      {
        label: "From Date",
        field: "from_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "To Date",
        field: "to_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Group",
        field: "group",
        sort: "asc",
        width: 200,
      },
      {
        label: "Therapist",
        field: "therapist",
        sort: "asc",
        width: 200,
      },

      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  useEffect(() => {
    data.rows.map((row) => {
      if (props.options.edit) {
        row.action = (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                handleClickOpen(row);
              }}></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                preUpdateGrouptherapy(row);
                toTop();
              }}></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "0.9em", cursor: "pointer" }}
              onClick={() => {
                setGrouptherapyIdTobeDeleted(row._id);
                setConfirmDeleteAlert(true);
              }}></i>
          </>
        );
      } else {
        row.action = (
          <i
            className="fas fa-eye"
            style={{
              fontSize: "0.9em",
              cursor: "pointer",
              marginLeft: "0.1em",
              marginRight: "0.4em",
            }}
            onClick={() => {
              handleClickOpen(row);
            }}></i>
        );
      }
    });
  }, [props.options]);

  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };

  const handleValidSubmit = (e) => {
    //console.log("selectedValue", selectedValue);
    if (grouptherapyIdTobeUpdated) {
      axios
        .put(`${API_URL}group-therapy`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Group Therapy updated successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({});
          setSelectedPatient(null);
          setCounsellingType(null);
          setPatientValues(false);
          setPatientPrevValues(false);
          handleTableData();
          setValues([{}]);
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    } else {
      axios
        .post(`${API_URL}group-therapy`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Group Therapy added successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({});
          setSelectedPatient(null);
          setPatientValues(false);
          setPatientPrevValues(false);
          handleTableData();
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}group-therapy?_id=` + grouptherapyIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                //console.log("res", res);
                if (res.data.status === 200) {
                  //console.log("deleted");
                  toastr.success("Group Therapy deleted successfully");
                  if (masterObject && masterObject._id === grouptherapyIdTobeDeleted) {
                    formRef.current.reset();
                    setmasterObject({});
                    setGrouptherapyIdTobeDeleted(null);
                  }
                  setGrouptherapyIdTobeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Group Therapy");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Group Therapy Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table id="product_available_day" className="table table-bordered dataTable">
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>Name :</td>
                <td>{popupData?.name}</td>
                <td style={{ paddingLeft: "25px" }}>From Date :</td>
                <td style={{ textAlign: "left" }}>{popupData?.from_date}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>To Date :</td>
                <td>{popupData?.to_date}</td>
                <td style={{ paddingLeft: "25px" }}>Group :</td>
                <td style={{ textAlign: "left" }}>{popupData?.group}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Therapist :</td>
                <td>{popupData?.therapist}</td>
                <td style={{ paddingLeft: "25px" }}>Staff :</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
              </tr>
            </table>
            <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
              <TableHead>
                <TableRow style={{ textAlign: "center" }}>
                  <TableCell
                    style={{
                      width: "5px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    No.
                  </TableCell>
                  <TableCell
                    style={{
                      width: "150px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Issues
                  </TableCell>
                  <TableCell
                    style={{
                      width: "100px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Code
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popupData?.details &&
                  popupData?.details.map((item, index) => (
                    <TableRow>
                      <TableCell
                        style={{
                          // width: "180px",
                          textAlign: "center",
                          fontWeight: "200",
                          fontSize: "12.819px",
                          // fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                        }}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          textAlign: "left",
                          fontSize: "12.819px",
                          //fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                          fontWeight: "200",
                        }}>
                        {item?.issue?.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          textAlign: "center",
                          fontSize: "12.819px",
                          //fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                          fontWeight: "200",
                          display: "flex",
                          gap: "10px",
                        }}>
                        {item?.code}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Group Therapy - Weekly" />
          {props.options.edit && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}>
                      <Row>
                        <Col md="3">
                          <div className="mt-1">
                            <Label>Patient</Label>
                            <Select
                              name="admission_id"
                              value={selectedPatient}
                              onChange={(selected) => {
                                handleSelectChange(selected, "admission_id");
                              }}
                              // validate={{ required: { value: true } }}
                              options={patientOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        {patientvalues == true ? (
                          <>
                            <Col md="9">
                              <Row>
                                <div className="mt-1">
                                  <h6>Patient Basic Details:</h6>
                                </div>
                              </Row>
                              <Row>
                                <Col md="3" className="mt-1">
                                  <div>
                                    <Label>Mobile:</Label>
                                    <span>{masterObject?.mobile}</span>
                                  </div>
                                </Col>
                                <Col md="2" className="mt-1">
                                  <Label>Gender:</Label>
                                  <span>{masterObject?.gender}</span>
                                </Col>
                                <Col md="1" className="mt-1">
                                  <Label>Age:</Label>
                                  <span>{masterObject?.age}</span>
                                </Col>
                                <Col md="3" className="mt-1">
                                  <Label>Admitted Date:</Label>
                                  <span>{masterObject?.admitted_date}</span>
                                </Col>
                                <Col md="3" className="mt-1">
                                  <Label>Counsellor:</Label>
                                  <span>{masterObject?.counsellor}</span>
                                </Col>
                              </Row>
                            </Col>

                            {patientprevvalues == true ? (
                              <Row>
                                <div className="mt-0">
                                  <Card style={{ background: "#f6f6f6" }}>
                                    <Link to="#" onClick={toggle} className="text-dark">
                                      <div className="p-2">
                                        <Media className="d-flex align-items-center">
                                          <div className="flex-1 overflow-hidden">
                                            <h6>Previous Group Therapy Details</h6>
                                          </div>
                                          <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                        </Media>
                                      </div>
                                    </Link>
                                    <Collapse isOpen={isOpen}>
                                      <div>
                                        <Table
                                          style={{
                                            textAlign: "center",
                                            border: "1px solid black",
                                          }}
                                          className="newtable">
                                          <TableHead>
                                            <TableRow style={{ textAlign: "center" }}>
                                              <TableCell
                                                style={{
                                                  // width: "180px",
                                                  textAlign: "center",
                                                  border: "1px solid black !important",
                                                  fontWeight: "bold",
                                                }}>
                                                No.
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: "180px",
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                }}>
                                                From Date
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: "100px",
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                }}>
                                                To Date
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  // width: "110px",
                                                  textAlign: "center",
                                                  border: "1px solid black",
                                                  fontWeight: "bold",
                                                }}>
                                                Therapist
                                              </TableCell>

                                              <TableCell
                                                style={{
                                                  // width: "180px",
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                }}>
                                                Issues
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  // width: "180px",
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                }}>
                                                Code
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {masterObject?.previous_data &&
                                              masterObject?.previous_data.map((item, index) => (
                                                <TableRow>
                                                  <TableCell
                                                    style={{
                                                      // width: "180px",
                                                      //textAlign: "center",
                                                      fontWeight: "200",
                                                      fontSize: "12.819px",
                                                      // fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                    }}>
                                                    {index + 1}
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      //textAlign: "center",
                                                      fontSize: "12.819px",
                                                      //fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                      fontWeight: "200",
                                                      //verticalAlign:"top"
                                                    }}>
                                                    {item?.from_date}
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      // textAlign: "center",
                                                      fontSize: "12.819px",
                                                      //fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                      fontWeight: "200",
                                                    }}>
                                                    {item?.to_date}
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      //textAlign: "center",
                                                      fontSize: "12.819px",
                                                      // fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                      fontWeight: "200",
                                                    }}>
                                                    {item?.therapist}
                                                  </TableCell>

                                                  <TableCell
                                                    component="td"
                                                    scope="row"
                                                    style={{
                                                      textAlign: "left",
                                                      fontSize: "12.819px",
                                                      //fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                      fontWeight: "200",
                                                    }}>
                                                    <ul>
                                                      {item?.details &&
                                                        item?.details.map((element, index) => {
                                                          return <li>{element?.issue?.name}</li>;
                                                        })}
                                                    </ul>
                                                  </TableCell>
                                                  <TableCell
                                                    component="td"
                                                    scope="row"
                                                    style={{
                                                      textAlign: "left",
                                                      fontSize: "12.819px",
                                                      //fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                      fontWeight: "200",
                                                    }}>
                                                    <ul>
                                                      {item?.details &&
                                                        item?.details.map((element, index) => {
                                                          return <li>{element?.code}</li>;
                                                        })}
                                                    </ul>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                          </TableBody>
                                        </Table>
                                      </div>
                                    </Collapse>
                                  </Card>
                                </div>
                              </Row>
                            ) : null}
                          </>
                        ) : null}

                        <Col md="2" className="mt-1">
                          <Label>From Date</Label>
                          <AvField
                            name="from_date"
                            placeholder="Date"
                            type="date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={handleChangeInput}
                            value={masterObject?.from_date}
                          />
                        </Col>
                        <Col md="2" className="mt-1">
                          <Label>To Date</Label>
                          <AvField
                            name="to_date"
                            placeholder="Date"
                            type="date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={handleChangeInput}
                            value={masterObject?.to_date}
                            min={masterObject?.from_date}
                          />
                        </Col>

                        <Col md="2" className="mt-1">
                          <Label>Therapist</Label>
                          <AvField
                            name="therapist"
                            type="text"
                            className="form-control"
                            placeholder="Therapist Name"
                            value={masterObject?.therapist}
                            onChange={handleChangeInput}
                          />
                        </Col>
                        <Col md="2" className="mt-1">
                          <Label>Group</Label>
                          <AvField
                            name="group"
                            type="text"
                            className="form-control"
                            placeholder="Group"
                            value={masterObject?.group}
                            onChange={handleChangeInput}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="7">
                          <div className="mt-3">
                            <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                              <TableHead>
                                <TableRow style={{ textAlign: "center" }}>
                                  <TableCell
                                    style={{
                                      width: "5px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    No.
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "150px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Issues
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "100px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Code
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {issues &&
                                  issues.map((item, index) => (
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          // width: "180px",
                                          textAlign: "center",
                                          fontWeight: "200",
                                          fontSize: "12.819px",
                                          // fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                        }}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          textAlign: "left",
                                          fontSize: "12.819px",
                                          //fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                          fontWeight: "200",
                                        }}>
                                        {item?.name}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          textAlign: "center",
                                          fontSize: "12.819px",
                                          //fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                          fontWeight: "200",
                                          display: "flex",
                                          gap: "10px",
                                        }}>
                                        <div className="form-check mb-3">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`issue1-${index}`}
                                            value="A"
                                            id={`${item._id}`}
                                            onChange={(e) => handleRadioChange(e, index)}
                                            // checked={selectedValue=="A"}
                                            checked={values[index]?.code === "A"}
                                          />
                                          <label className="form-check-label">A</label>
                                        </div>
                                        <div className="form-check mb-3">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`issue2-${index}`}
                                            value="B"
                                            id={`${item._id}`}
                                            onChange={(e) => handleRadioChange(e, index)}
                                            //checked={selectedValue === "B"}
                                            checked={values[index]?.code === "B"}
                                          />
                                          <label className="form-check-label">B</label>
                                        </div>
                                        <div className="form-check mb-3">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`issue3-${index}`}
                                            value="C"
                                            id={`${item._id}`}
                                            onChange={(e) => handleRadioChange(e, index)}
                                            //checked={selectedValue === "C"}
                                            checked={values[index]?.code === "C"}
                                          />
                                          <label className="form-check-label" htmlFor={`issue3-${index}`}>
                                            C
                                          </label>
                                        </div>
                                        <div className="form-check mb-3">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`issue4-${index}`}
                                            value="D"
                                            id={`${item._id}`}
                                            onChange={(e) => handleRadioChange(e, index)}
                                            //checked={selectedValue === "D"}
                                            checked={values[index]?.code === "D"}
                                          />
                                          <label className="form-check-label" htmlFor={`issue4-${index}`}>
                                            D
                                          </label>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1" className="mt-0">
                          <FormGroup className="float-right ">
                            {grouptherapyIdTobeUpdated ? (
                              <Button color="primary" type="submit">
                                {"Update"}
                              </Button>
                            ) : (
                              <Button color="primary" type="submit">
                                {"Submit"}
                              </Button>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <MDBDataTable
                        id="grouptherapyTableID"
                        responsive
                        //striped
                        bordered
                        data={data}
                        info={true}
                        searching={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GroupTherapy;
