import React, { useEffect, useState } from "react";
import "./MarqueeScroll.css"; // Import CSS styles for the marquee

const MarqueeScroll = ({ children }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const container = document.getElementById("marquee-container");
    const containerHeight = container.offsetHeight;
    const contentHeight = container.scrollHeight;

    if (contentHeight <= containerHeight) {
      // Content fits within the container, no need for scrolling
      return;
    }

    const timer = setInterval(() => {
      setOffset((prevOffset) => {
        const scrollSpeed = 1; // Adjust the scroll speed as needed
        const newOffset = prevOffset + scrollSpeed;

        if (newOffset >= contentHeight) {
          // Reset offset to start position when it exceeds the content height
          return newOffset - contentHeight - containerHeight;
        }

        return newOffset;
      });
    }, 30); // Adjust the interval time for smoother scrolling

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div id="marquee-container" className="marquee-container">
      <div
        className="marquee-content"
        style={{ transform: `translateY(-${offset}px)` }}
      >
        {children} {children} {/* Duplicate the content */}
      </div>
    </div>
  );
};

export default MarqueeScroll;
