/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Row,
  TabPane,

} from "reactstrap";

import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function SexualHistory(props) {
  let {  details,  editable } = props;

  useEffect(() => {
    setMstrObject(details);
  }, [details]);

  const [mstrObject, setMstrObject] = useState({
    problems: {
      reducted_libido: false,
      excessive_sexual_urge: false,
      impotency: false,
      complete_abstinence: false,
      pre_mature_ejaculation: false,
      any_other: false,
    },
  });


  const handleInput = (e, mthd) => {
    let { name, value, type, checked } = e.target;
    let newVal = type === "checkbox" ? checked : value;
    if (type === "radio") {
      value = Number(value);
    }

    if (mthd === 0) {
      setMstrObject((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (mthd === 1) {
      setMstrObject((prev) => ({
        ...prev,
        problems: {
          ...prev?.problems,
          [name]: newVal,
        },
      }));
    }

    if (mthd === 2) {
      setMstrObject((prev) => ({
        ...prev,
        ext_marital_det: {
          ...prev?.ext_marital_det,
          [name]: value,
        },
      }));
    }

    if (mthd === 3) {
      setMstrObject((prev) => ({
        ...prev,
        rsk_sex_det: {
          ...prev?.rsk_sex_det,
          [name]: value,
        },
      }));
    }
  };

  function handleValidSubmit() {
    mstrObject.admission_id = props.id;
    axios
      .post(`${API_URL}admission/sexual-history`, mstrObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success(res.data.message);
        props.handleToggleBar("increment");
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  }

  return (
    <TabPane tabId={5}>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
          // setMstrObject({...mstrObject,problems:chkques});
          handleValidSubmit();
        }}
      >
        <Col md="12">
          <Row>
            <Col md="6">
              <h6>1. Are you sexually satisfied with your spouse?</h6>
            </Col>
            <Col md="6">
              <p>
                Always{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="sexual_satisfactions"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  checked={mstrObject?.sexual_satisfactions == 1 ? true : false}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; At times{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="sexual_satisfactions"
                  checked={mstrObject?.sexual_satisfactions == 2 ? true : false}
                  value={2}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; Never{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="sexual_satisfactions"
                  checked={mstrObject?.sexual_satisfactions == 3 ? true : false}
                  value={3}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
            <Col md="12" style={{ display: "flex" }}>
              <p style={{ marginLeft: "1rem" }}>Reasons</p>
              <div style={{ width: "50%" }}>
                <AvField
                  disabled={!editable}
                  name="reasons"
                  type="text"
                  className="form-control form-control mx-3 reason-w-50"
                  onChange={(e) => handleInput(e, 0)}
                  value={mstrObject?.reasons}
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col md="12" className="mt-2">
          <Row>
            <Col md="6">
              <h6>2. At present do you have any sexual problems?</h6>
            </Col>
            <Col md="6">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="sexual_problems"
                  checked={mstrObject?.sexual_problems == 1 ? true : false}
                  value={1}
                  style={{ marginLeft: "10px" }}
                  onChange={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="sexual_problems"
                  checked={mstrObject?.sexual_problems == 0}
                  value={0}
                  style={{ marginLeft: "10px" }}
                  onChange={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <p style={{ marginLeft: "1rem" }}>
                2.1 Reducted libido
                <input
                  disabled={!editable}
                  type="checkbox"
                  style={{ marginLeft: "10px" }}
                  checked={mstrObject?.problems?.reducted_libido}
                  name="reducted_libido"
                  onClick={(e) => handleInput(e, 1)}
                  className="form-check-input"
                />
              </p>
            </Col>
            <Col md="5">
              <p>
                2.2 Impotency
                <input
                  disabled={!editable}
                  type="checkbox"
                  checked={mstrObject?.problems?.impotency}
                  name="impotency"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 1)}
                  className="form-check-input"
                />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <p style={{ marginLeft: "1rem" }}>
                2.3 Excessive sexual urge
                <input
                  disabled={!editable}
                  type="checkbox"
                  checked={mstrObject?.problems?.excessive_sexual_urge}
                  name="excessive_sexual_urge"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 1)}
                  className="form-check-input"
                />
              </p>
            </Col>
            <Col md="5">
              <p>
                2.4 Complete abstinence
                <input
                  disabled={!editable}
                  type="checkbox"
                  checked={mstrObject?.problems?.complete_abstinence}
                  name="complete_abstinence"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 1)}
                  className="form-check-input"
                />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <p style={{ marginLeft: "1rem" }}>
                2.5 Any other
                <input
                  disabled={!editable}
                  type="checkbox"
                  checked={mstrObject?.problems?.any_other}
                  name="any_other"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 1)}
                  className="form-check-input"
                />
              </p>
            </Col>
            <Col md="5">
              <p>
                2.6 Pre mature ejaculation
                <input
                  disabled={!editable}
                  type="checkbox"
                  checked={mstrObject?.problems?.pre_mature_ejaculation}
                  name="pre_mature_ejaculation"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 1)}
                  className="form-check-input"
                />
              </p>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-2">
          <Row>
            <Col md="6">
              <h6>3. If unmarried,any extra marital experiences</h6>
            </Col>
            <Col md="6">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="ext_marital_exp"
                  value={1}
                  checked={mstrObject?.ext_marital_exp == 1 ? true : false}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="ext_marital_exp"
                  value={0}
                  checked={mstrObject?.ext_marital_exp === 0 ? true : false}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; N.A.{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="ext_marital_exp"
                  value={2}
                  checked={mstrObject?.ext_marital_exp == 2 ? true : false}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="5">
              <p style={{ marginLeft: "1rem" }}>3.1 Age of partner</p>
            </Col>

            <Col
              md="5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="age_of_partner"
                type="text"
                className="form-control form-control3"
                onChange={(e) => handleInput(e, 2)}
                value={mstrObject?.ext_marital_det?.age_of_partner}
              />
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="5">
              <p style={{ marginLeft: "1rem" }}>
                3.2 Is it a sustained relationship?
              </p>
            </Col>

            <Col
              md="5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="sustained_rltn"
                type="text"
                className="form-control form-control3"
                onChange={(e) => handleInput(e, 2)}
                value={mstrObject?.ext_marital_det?.sustained_rltn}
              />
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="5">
              <p style={{ marginLeft: "1rem" }}>
                3.3 For how many years have you known each other?
              </p>
            </Col>

            <Col
              md="5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="length_of_rltn"
                type="text"
                className="form-control form-control3"
                onChange={(e) => handleInput(e, 2)}
                value={mstrObject?.ext_marital_det?.length_of_rltn}
              />
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="5">
              <p style={{ marginLeft: "1rem" }}>
                3.4 What is the living arrangement?
              </p>
            </Col>

            <Col
              md="5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="living_arragement"
                type="text"
                className="form-control form-control3"
                onChange={(e) => handleInput(e, 2)}
                value={mstrObject?.ext_marital_det?.living_arragement}
              />
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="5">
              <p style={{ marginLeft: "1rem" }}>3.5 Any children details?</p>
            </Col>
            <Col md="5">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="childern"
                  checked={
                    mstrObject?.ext_marital_det?.childern == 1 ? true : false
                  }
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e, 2)}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="childern"
                  checked={
                    mstrObject?.ext_marital_det?.childern == 0 ? true : false
                  }
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onClick={(e) => handleInput(e, 2)}
                />{" "}
                &nbsp; Don't Know{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="childern"
                  checked={
                    mstrObject?.ext_marital_det?.childern == 2 ? true : false
                  }
                  style={{ marginLeft: "10px" }}
                  value={2}
                  onClick={(e) => handleInput(e, 2)}
                />{" "}
                &nbsp;
              </p>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="6">
              <h6>4 Have you involved in any high-risk sexual activities?</h6>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="risk_sexual_activity"
                  value={1}
                  checked={mstrObject?.risk_sexual_activity == 1 ? true : false}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="risk_sexual_activity"
                  value={0}
                  checked={mstrObject?.risk_sexual_activity == 0 ? true : false}
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <h6>5 Sex with commercial sex workers?</h6>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="sex_wt_csw"
                  checked={
                    mstrObject?.rsk_sex_det?.sex_wt_csw == 1 ? true : false
                  }
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e, 3)}
                />{" "}
                &nbsp; No{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="sex_wt_csw"
                  checked={
                    mstrObject?.rsk_sex_det?.sex_wt_csw == 0 ? true : false
                  }
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onClick={(e) => handleInput(e, 3)}
                />
              </p>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <p style={{ marginLeft: "1rem" }}>5.1 Did you use condoms?</p>
            </Col>
            <Col md="5">
              <p>
                Always{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="use_of_condoms"
                  checked={
                    mstrObject?.rsk_sex_det?.use_of_condoms == 2 ? true : false
                  }
                  style={{ marginLeft: "10px" }}
                  value={2}
                  onClick={(e) => handleInput(e, 3)}
                />{" "}
                &nbsp; Sometimes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="use_of_condoms"
                  checked={
                    mstrObject?.rsk_sex_det?.use_of_condoms == 1 ? true : false
                  }
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e, 3)}
                />{" "}
                &nbsp; Never{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="use_of_condoms"
                  checked={
                    mstrObject?.rsk_sex_det?.use_of_condoms == 0 ? true : false
                  }
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onClick={(e) => handleInput(e, 3)}
                />
              </p>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="6">
              <h6>6. Sex with casual acquaintance</h6>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="casual_sex"
                  checked={mstrObject?.casual_sex == 1 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onChange={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; No{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="casual_sex"
                  checked={mstrObject?.casual_sex == 0 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onChange={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <p style={{ marginLeft: "1rem" }}>6.1 Did you use condoms?</p>
            </Col>
            <Col md="5">
              <p>
                Always{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="use_of_condoms_csl"
                  checked={mstrObject?.use_of_condoms_csl == 2 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={2}
                  onChange={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; Sometimes{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="use_of_condoms_csl"
                  checked={mstrObject?.use_of_condoms_csl == 1 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onChange={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; Never{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="use_of_condoms_csl"
                  checked={mstrObject?.use_of_condoms_csl == 0 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onChange={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-3">
          <Row>
            <Col md="6">
              <h6>7. Have you been tested for STDs/HIV?</h6>
            </Col>
            <Col md="4">
              <p>
                Yes{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="stdHiv_tested"
                  checked={mstrObject?.stdHiv_tested == 1 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onChange={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; No{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="stdHiv_tested"
                  checked={mstrObject?.stdHiv_tested == 0 ? true : false}
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onChange={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p style={{ marginLeft: "1rem" }}>
                Positive/Negative{" "}
                <input
                  type="radio"
                  disabled={!editable}
                  name="hiv_test_det"
                  checked={mstrObject?.hiv_test_det == 0}
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onChange={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; Not willing to reveal{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="hiv_test_det"
                  checked={mstrObject?.hiv_test_det == 1}
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onChange={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; Not collected reports{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="hiv_test_det"
                  checked={mstrObject?.hiv_test_det == 2}
                  style={{ marginLeft: "10px" }}
                  value={2}
                  onChange={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; Not Applicable{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="hiv_test_det"
                  checked={mstrObject?.hiv_test_det == 3}
                  style={{ marginLeft: "10px" }}
                  value={3}
                  onChange={(e) => handleInput(e, 0)}
                />
              </p>
            </Col>
          </Row>
        </Col>

        <Row className="mt-4">
          <Col md={12} className="submit-btn-fixed">
            <FormGroup className="float-right ">
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("decrement")}
              >
                <i class="fas fa-solid fa-less-than"></i>
              </a>
              <button type="submit" className="save-continue-btn m-2">
                Save and continue{" "}
                <i
                  style={{ fontSize: "13px" }}
                  className="mdi mdi-arrow-right"
                ></i>
              </button>
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("increment")}
              >
                <i class="fas fa-solid fa-greater-than"></i>
              </a>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </TabPane>
  );
}

export default SexualHistory;
