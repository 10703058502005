/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Col, FormGroup, Label, Row, TabPane } from "reactstrap";
import Select from "react-select";
import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import InputMask from "react-input-mask";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function BasicDetails(props) {
  let { activeTabVartical, details, id, editable, admission } = props;
  const [masterObject, setmasterObject] = useState({});
  const [values, setValues] = useState([{}]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState(null);
  const [selectedEmploymentValue, setSelectedEmploymentValue] = useState(false);
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [selectedPlaceResidence, setSelectedPlaceResidence] = useState(null);
  const [selectedPriorTreatment, setSelectedPriorTreatment] = useState(null);
  const [selectedPriorValue, setSelectedPriorValue] = useState(false);
  const [selectedRefferal, setSelectedRefferal] = useState(null);
  const [referalOptions, setReferalOptions] = useState([]);
  const [selectedLivingArrangements, setSelectedLivingArrangements] = useState(null);
  const [livingArrangementsOptions, setlivingArrangementsOptions] = useState([]);
  const [selectedReligion, setSelectedReligion] = useState(null);
  const [religionOptions, setReligionOptions] = useState([]);
  const [selectedCaste, setSelectedCaste] = useState(null);
  const [casteOptions, setCasteOptions] = useState([]);
  const [uploadProgress, setUploadProgress] = useState();
  const [selectedSPState, setSPSelectedState] = useState(null);
  const [priorTreatment, setPriorTreatment] = useState({});
  const [counsellorOptions, setCounsellorOptions] = useState([]);
  const [selectedCounsellor, setSelectedCounsellor] = useState(null);
  const [selectedAdmissionType, setSelectedAdmissionType] = useState(null);
  const [IPValue, setSelectedIPValue] = useState(false);
  const [sameAddress, setSameAddress] = useState(false);
  const [selectedIDcardtype, setSelectedIDcardtype] = useState(null);
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  const [DOB, setDOB] = useState({ YYYY: "", MM: "", DD: "" });
  const years = [];
  const currentYear = new Date().getFullYear();
  const formRef = useRef();

  useEffect(() => {
    let age;
    if (DOB?.YYYY?.length > 3) {
      const currentDate = new Date();
      age = currentDate.getFullYear() - DOB.YYYY;
    } else {
      age = "";
    }

    setmasterObject({
      ...masterObject,
      dob: `${DOB?.YYYY ? DOB?.YYYY + (DOB?.MM?.length > 0 ? "-" : "") : ""}${DOB?.MM ? DOB?.MM + "-" : ""}${DOB?.DD ? DOB?.DD : ""}`,
      age,
    });
  }, [DOB]);

  useEffect(() => {
    let data = {};
    data.admission_id = id;
    data.basic_id = details?.basic_id?._id;
    data.mobile = details?.basic_id?.mobile;
    data.first_name = details?.basic_id?.fname;
    data.last_name = details?.basic_id?.lname;
    data.pin = details?.basic_id?.pin;
    data.address = details?.basic_id?.address;
    data.state = details?.basic_id?.state?._id;

    data.district = details?.basic_id?.district?._id;

    let state_value = {};
    state_value.label = details?.basic_id?.state?.name;
    state_value.value = details?.basic_id?.state?._id;
    data.state_name = state_value?.label;

    getDistrict(details?.basic_id?.state?._id);
    let district_value = {};
    district_value.label = details?.basic_id?.district?.district_name;
    district_value.value = details?.basic_id?.district?._id;
    data.district_name = district_value?.label;
    let counsellor_value = {};
    counsellor_value.label = admission?.counsellor_id?.firstName;
    counsellor_value.value = admission?.counsellor_id?._id;
    data.counsellor_id = admission?.counsellor_id?._id;
    let admissiontype_value = {};
    if (admission?.admission_type == 0) {
      admissiontype_value.label = "OP";
      admissiontype_value.value = 0;
    }
    if (admission?.admission_type == 1) {
      admissiontype_value.label = "IP";
      admissiontype_value.value = 1;
      setSelectedIPValue(true);
    }
    data.admission_type = admission?.admission_type;
    data.ip_number = admission?.ip_number;
    data.reg_date = details?.reg_date;
    data.aadhar_image = details?.aadhar_image;
    data.photo = details?.photo;
    data.aadhar_no = details?.aadhar_no;
    data.income = details?.income;
    data.reg_no = admission?.reg_no;
    data.serial_number = admission?.serial_number;
    data.exp_discharge_date = admission?.exp_discharge_date;
    data.remarks = admission?.remarks;
    data.occupation = details?.occupation;
    let community_value = {};
    community_value.label = details?.community?.name;
    community_value.value = details?.community?._id;
    data.community = details?.community?._id;
    let religion_value = {};
    religion_value.label = details?.religion?.name;
    religion_value.value = details?.religion?._id;
    data.religion = details?.religion?._id;
    let living_arragement_value = {};
    living_arragement_value.label = details?.living_arragement?.name;
    living_arragement_value.value = details?.living_arragement?._id;
    data.living_arragement = details?.living_arragement?._id;
    let referral_value = {};
    referral_value.label = details?.refferal?.name;
    referral_value.value = details?.refferal?._id;
    data.refferal = details?.refferal?._id;
    let qualification_value = {};
    qualification_value.label = details?.basic_id?.qualification?.qualification_name;
    qualification_value.value = details?.basic_id?.qualification?._id;
    data.qualification = details?.basic_id?.qualification?._id;
    let gender_value = {};
    data.gender = details?.gender;
    data.age = details?.age;
    data.dob = details?.dob;
    if (details?.dob) {
      const [YYYY, MM, DD] = details?.dob?.split("-");
      setDOB({
        YYYY: YYYY,
        DD: DD,
        MM: MM,
      });
    }
    if (details?.gender == 0) {
      gender_value.label = "Female";
      gender_value.value = 0;
    }
    if (details?.gender == 1) {
      gender_value.label = "Male";
      gender_value.value = 1;
    }
    if (details?.gender == 2) {
      gender_value.label = "Others";
      gender_value.value = 2;
    }
    let identity_type_value = {};
    data.identity_type = details?.identity_type;
    if (details?.identity_type == "1") {
      identity_type_value.label = "Voter ID";
      identity_type_value.value = 1;
    }
    if (details?.identity_type == "2") {
      identity_type_value.label = "Aadharcard";
      identity_type_value.value = 2;
    }
    if (details?.identity_type == "3") {
      identity_type_value.label = "Pancard";
      identity_type_value.value = 3;
    }
    if (details?.identity_type == "4") {
      identity_type_value.label = "Passort";
      identity_type_value.value = 4;
    }

    if (details?.identity_type == "5") {
      identity_type_value.label = "Driving licence";
      identity_type_value.value = 5;
    }
    if (details?.identity_type == "6") {
      identity_type_value.label = "Ration Card";
      identity_type_value.value = 6;
    }

    data.identity_no = details?.identity_no;

    let marital_status_value = {};
    data.marital_status = details?.marital_status;
    if (details?.marital_status == 0) {
      marital_status_value.label = "Unmarried";
      marital_status_value.value = 0;
    }
    if (details?.marital_status == 1) {
      marital_status_value.label = "Married";
      marital_status_value.value = 1;
    }
    if (details?.marital_status == 2) {
      marital_status_value.label = "Separated";
      marital_status_value.value = 2;
    }
    if (details?.marital_status == 3) {
      marital_status_value.label = "Divorced";
      marital_status_value.value = 3;
    }
    if (details?.marital_status == 4) {
      marital_status_value.label = "Widowed";
      marital_status_value.value = 4;
    }

    let employment_value = {};
    data.employment_status = details?.employment_status;
    if (details?.employment_status == 0) {
      employment_value.label = "Unemployed";
      employment_value.value = 0;
    }
    if (details?.employment_status == 1) {
      employment_value.label = "Employed";
      employment_value.value = 1;
      setSelectedEmploymentValue(true);
    }

    let recidence_value = {};
    data.recidence_place = details?.recidence_place;
    if (details?.recidence_place == 0) {
      recidence_value.label = "Rural";
      recidence_value.value = 0;
    }
    if (details?.recidence_place == 1) {
      recidence_value.label = "Urban";
      recidence_value.value = 1;
    }
    //prior_treatment
    let prior_treatment_value = {};
    data.prior_treatment = details?.prior_treatment;
    if (details?.prior_treatment == 0) {
      prior_treatment_value.label = "No";
      prior_treatment_value.value = 0;
    }
    if (details?.prior_treatment == 1) {
      prior_treatment_value.label = "Yes";
      prior_treatment_value.value = 1;
      setSelectedPriorValue(true);
      data.prior_treatment_det = details?.prior_treatment_det;
      data.place_of_treatment = details?.prior_treatment_det?.place_of_treatment;
      data.period = details?.prior_treatment_det?.period;
      setPriorTreatment(details?.prior_treatment_det);
      setSelectedYear({
        label: details?.prior_treatment_det?.year,
      });
    }
    //support_persons
    if (details?.support_persons.length > 0) {
      let tableData = [];
      details?.support_persons &&
        details?.support_persons.map((item, index) => {
          let val = {};
          val.sp_name = item?.sp_name;
          val.sp_address = item?.sp_address;
          val.sp_mobile = item?.sp_mobile;
          val.sp_state_name = item?.sp_state?.name;
          val.sp_state = item?.sp_state?._id;
          val.sp_district_name = item?.sp_district?.district_name;
          val.sp_district = item?.sp_district?._id;
          val.sp_relation = item?.sp_relation;
          tableData.push(val);
        });

      setValues(tableData);
    }
    data.support_persons = details?.support_persons;
    // data.support_persons=details?.support_persons

    setmasterObject(data);
    setSelectedState(state_value);
    setSelectedDistrict(district_value);
    setSelectedCaste(community_value);
    setSelectedReligion(religion_value);
    setSelectedLivingArrangements(living_arragement_value);
    setSelectedRefferal(referral_value);
    setSelectedQualification(qualification_value);
    setSelectedGender(gender_value);
    setSelectedIDcardtype(identity_type_value);
    setSelectedMaritalStatus(marital_status_value);
    setSelectedEmploymentStatus(employment_value);
    setSelectedPlaceResidence(recidence_value);
    setSelectedPriorTreatment(prior_treatment_value);
    setSelectedCounsellor(counsellor_value);
    setSelectedAdmissionType(admissiontype_value);
  }, [details]);

  for (let i = currentYear; i >= 1930; i--) {
    var k = {
      label: i,
      value: i,
    };
    years.push(k);
  }

  const handleYearChange = (e) => {
    setSelectedYear(e);
    setPriorTreatment({
      ...priorTreatment,
      year: e.value,
    });
    setmasterObject({ ...masterObject, prior_treatment_det: priorTreatment });
  };

  const addMore = () => {
    setValues([...values, {}]);
    // setSameAddress(true)
  };
  const getValidationPattern = (selectedIDcardtype) => {
    switch (selectedIDcardtype) {
      case 1: // Voter ID
        return /^[A-Za-z]{3}\d{7}$/; // Example pattern, modify it as per your requirement
      case 2: // Aadhaar Card
        return /^\d{4}\s\d{4}\s\d{4}$/;
      case 3: // PAN Card
        return /^[A-Z]{5}\d{4}[A-Z]{1}$/;
      // case 4: // Driving License
      //  return /^\d{2}\/\d{5}\/\d{4}$/;
      case 4:
        return /^[A-Za-z]{1}[0-9]{7}$/;
      case (5, 6):
        return /^.*$/;
      default:
        return /^.*$/; // Default pattern to allow any value
    }
  };
  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
    if (e.target.name == "dob") {
      const currentDate = new Date();
      const selectedDate = new Date(e.target.value);

      const yearDiff = currentDate.getFullYear() - selectedDate.getFullYear();
      const monthDiff = currentDate.getMonth() - selectedDate.getMonth();
      const dayDiff = currentDate.getDate() - selectedDate.getDate();

      let calculatedAge = yearDiff;

      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        calculatedAge--;
      }
      setmasterObject({
        ...masterObject,
        [name]: value,
        age: calculatedAge,
      });
    }
  };

  const handleChangeDob = (e) => {
    let { name, value } = e.target;
    const newDOB = { ...DOB };

    if (name === "DD") {
      newDOB.DD = value.length === 3 ? value.replace(/^0+/, "") : value.padStart(2, "0");
    } else if (name === "MM") {
      newDOB.MM = value.length === 3 ? value.replace(/^0+/, "") : value.padStart(2, "0");
    } else if (name === "YYYY") {
      newDOB.YYYY = value;
    }

    setDOB(newDOB);
  };

  function handleTableInputchange(e, index) {
    let { name, value } = e.target;
    const list = [...values];
    list[index][name] = e.target.value;

    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
  }

  function handleInputchange(e) {
    var name = e.target.name;
    var value = e.target.value;

    setPriorTreatment({
      ...priorTreatment,
      [name]: value,
    });
    setmasterObject({ ...masterObject, prior_treatment_det: priorTreatment });
  }

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
  };

  function handleChangeSelectItem(selected, index) {
    const list = [...values];
    list[index]["sp_state"] = selected.value;
    list[index]["sp_state_name"] = selected.label;
    // setSPSelectedState(selected)
    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
    getDistrict(selected.value);
  }

  function handleChangeSelectItem1(selected, index) {
    const list = [...values];
    list[index]["sp_district"] = selected.value;
    list[index]["sp_district_name"] = selected.label;
    // setSPSelectedState(selected)
    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
  }

  // const handleChangeSelectItem=(e,name,index)=> {
  //   const list = [...values];
  //   list[index][name] = e.value;
  //   setValues(list);
  //   setmasterObject({...masterObject,support_persons: list })
  //   setSPSelectedState(e);
  // }

  useEffect(() => {
    getStates();
    getQualification();
    getRefferallist();
    getlivingarrangement();
    getReligionlist();
    getCounsellors();
    getCommunitylist();
    getRelationshiplist();
  }, []);
  const getCounsellors = () => {
    axios
      .get(`${API_URL}user/counsellors`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.firstName, value: item._id });
        });
        setCounsellorOptions(options);
      });
  };
  const getStates = () => {
    axios
      .get(`${API_URL}enquiry/state-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id, id: item.id });
        });
        setStateOptions(options);
      });
  };

  const getQualification = () => {
    axios
      .get(`${API_URL}enquiry/qualification-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.qualification_name, value: item._id });
        });
        setQualificationOptions(options);
      });
  };
  const getDistrict = (_id) => {
    axios
      .get(`${API_URL}enquiry/district-list/?id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.district_name, value: item._id });
        });
        setDistrictOptions(options);
      });
  };

  const getRefferallist = () => {
    axios
      .get(`${API_URL}options/refferals`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setReferalOptions(options);
      });
  };
  const getlivingarrangement = () => {
    axios
      .get(`${API_URL}options/living-arrangements`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setlivingArrangementsOptions(options);
      });
  };

  const getReligionlist = () => {
    axios
      .get(`${API_URL}options/religion`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setReligionOptions(options);
      });
  };

  const getCommunitylist = (_id) => {
    axios
      .get(`${API_URL}options/community`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setCasteOptions(options);
      });
  };

  const getRelationshiplist = () => {
    axios
      .get(`${API_URL}options/relationships`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setRelationshipOptions(options);
      });
  };

  const handleSelectChange = (e, type) => {
    switch (type) {
      case "state":
        setSelectedState(e);
        setmasterObject({
          ...masterObject,
          state: e.value,
        });
        getDistrict(e.value);
        setSelectedDistrict(e.value);
        break;
      case "district":
        setSelectedDistrict(e);
        setmasterObject({
          ...masterObject,
          district: e.value,
        });
        break;
      case "gender":
        setSelectedGender(e);
        setmasterObject({
          ...masterObject,
          gender: e.value,
        });
        break;
      case "marital_status":
        setSelectedMaritalStatus(e);
        setmasterObject({
          ...masterObject,
          marital_status: e.value,
        });
        break;
      case "qualification":
        setSelectedQualification(e);
        setmasterObject({
          ...masterObject,
          qualification: e.value,
        });
        break;
      case "employment_status":
        setSelectedEmploymentStatus(e);
        setmasterObject({
          ...masterObject,
          employment_status: e.value,
        });
        if (e.value == 1) {
          setSelectedEmploymentValue(true);
        }
        if (e.value == 0) {
          setSelectedEmploymentValue(false);
        }
        break;
      case "recidence_place":
        setSelectedPlaceResidence(e);
        setmasterObject({
          ...masterObject,
          recidence_place: e.value,
        });
        break;
      case "prior_treatment":
        setSelectedPriorTreatment(e);
        setmasterObject({
          ...masterObject,
          prior_treatment: e.value,
        });
        if (e.value == 1) {
          setSelectedPriorValue(true);
        }
        if (e.value == 0) {
          setSelectedPriorValue(false);
        }
        break;
      case "refferal":
        setSelectedRefferal(e);
        setmasterObject({
          ...masterObject,
          refferal: e.value,
        });
        break;
      case "living_arragement":
        setSelectedLivingArrangements(e);
        setmasterObject({
          ...masterObject,
          living_arragement: e.value,
        });
        break;
      case "religion":
        setSelectedReligion(e);
        setmasterObject({
          ...masterObject,
          religion: e.value,
        });
        // getCommunitylist(e.value);
        // setSelectedCaste(e.value);
        break;
      case "community":
        setSelectedCaste(e);
        setmasterObject({
          ...masterObject,
          community: e.value,
        });
        break;
      case "counsellor_id":
        setSelectedCounsellor(e);
        setmasterObject({
          ...masterObject,
          counsellor_id: e.value,
        });
        break;
      case "admission_type":
        setSelectedAdmissionType(e);
        setmasterObject({
          ...masterObject,
          admission_type: e.value,
        });
        if (e.value == 1) {
          setSelectedIPValue(true);
        } else {
          setSelectedIPValue(false);
          // setmasterObject({
          //   ...masterObject,
          //   ip_number:"",
          // });
        }
        break;
      case "identity_type":
        setSelectedIDcardtype(e);
        setmasterObject({
          ...masterObject,
          identity_type: e.value,
          identity_no: "",
        });
        break;

      default:
        break;
    }
  };

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("photo", e.target.files[0]);
    axios
      .post(`${API_URL}admission/photo`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setmasterObject({
            ...masterObject,
            [`photo`]: response.data.file.filename,
          });

          toastr.success("Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteImage = () => {
    setmasterObject({
      ...masterObject,
      [`photo`]: "",
    });
  };

  const uploadAdhaar = (e) => {
    const fd = new FormData();
    fd.append("aadhar_image", e.target.files[0]);
    axios
      .post(`${API_URL}admission/aadhar`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setmasterObject({
            ...masterObject,
            [`aadhar_image`]: response.data.file.filename,
          });

          toastr.success("Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteAdhaar = () => {
    setmasterObject({
      ...masterObject,
      [`aadhar_image`]: "",
    });
  };

  const handleValidSubmit = (e) => {
    axios
      .put(`${API_URL}admission`, masterObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        // if (res.data.status === 200) {
        toastr.success("Basic details added successfully");
        props.setReload(!props.reload);
        // formRef.current.reset();
        // props.handleToggleBar("increment");
        setValues([{}]);
        setmasterObject({});

        // setSelectedYear(null)
        // setSelectedDistrict(null)
        // setSelectedGender(null)
        // setSelectedEmploymentStatus(null)
        // setSelectedMaritalStatus(null)
        // setSelectedQualification(null)
        // setSelectedPlaceResidence(null)
        // setSelectedPriorTreatment(null)
        // setSelectedRefferal(null)
        // setSelectedLivingArrangements(null)
        // setSelectedReligion(null)
        // setSelectedCaste(null)
        // setPriorTreatment(null)

        // }
        //  else {
        //   toastr.error(res.data.message, "Failed to add basic details");
        //   return;
        // }
      })
      .catch((err) => {
        // console.log(err)
        toastr.error(err?.response?.data?.message);
        // toastr.error("something went wrong");
        return;
      });
  };

  const handleSameaddress = (e, index) => {
    if (e.target.checked == true) {
      const address = masterObject.address;
      const list = [...values];
      list[index]["sp_address"] = address;
      list[index]["sp_state"] = masterObject.state;
      list[index]["sp_state_name"] = masterObject.state_name;
      list[index]["sp_district"] = masterObject.district;
      list[index]["sp_district_name"] = masterObject.district_name;
      setValues(list);
      setmasterObject({ ...masterObject, support_persons: list });
    } else {
      //  setSameAddress(false)
      const list = [...values];
      list[index]["sp_address"] = "";
      list[index]["sp_state"] = "";
      list[index]["sp_state_name"] = "";
      list[index]["sp_district"] = "";
      list[index]["sp_district_name"] = "";
      setValues(list);
      setmasterObject({ ...masterObject, support_persons: list });
    }
  };

  function handleChangeSelectItem2(selected, index) {
    const list = [...values];
    list[index]["sp_relation"] = selected.label;
    // list[index]["sp_district_name"] = selected.label;

    setValues(list);
    setmasterObject({ ...masterObject, support_persons: list });
  }

  return (
    <div>
      <AvForm
        className="needs-validation"
        onValidSubmit={(e) => {
          handleValidSubmit(e);
        }}>
        <Row>
          <Col md="3">
            <div className="mt-2">
              <Label>Mobile</Label>
              <AvField
                disabled={!editable}
                name="mobile"
                placeholder="Mobile"
                type="Number"
                className="form-control"
                //validate={{ required: { value: true } }}
                validate={{
                  required: { value: true },
                  pattern: { value: "/^[6-9][0-9]{9}$/" },
                }}
                errorMessage="Enter valid 10 digit number"
                onChange={handleChangeInput}
                value={masterObject?.mobile}
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mt-2">
              <Label>First Name</Label>
              <AvField
                disabled={!editable}
                name="first_name"
                placeholder="First Name"
                type="text"
                className="form-control"
                validate={{ required: { value: true } }}
                value={masterObject?.first_name}
                onChange={handleChangeInput}
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mt-2">
              <Label>Last Name</Label>
              <AvField
                disabled={!editable}
                name="last_name"
                placeholder="Last Name"
                type="text"
                className="form-control"
                validate={{ required: { value: true } }}
                onChange={handleChangeInput}
                value={masterObject?.last_name}
              />
            </div>
          </Col>
          <Col md="3" className="mt-2">
            <Label>Admission Type</Label>
            <Select
              isDisabled={!editable}
              name="admission_type"
              value={selectedAdmissionType}
              onChange={(selected) => {
                handleSelectChange(selected, "admission_type");
              }}
              options={[
                {
                  label: "OP",
                  value: 0,
                },
                {
                  label: "IP",
                  value: 1,
                },
              ]}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3">
            <div className="mt-2">
              <Label>Serial Number</Label>
              <AvField
                disabled={!editable}
                name="serial_number"
                placeholder="Serial Number"
                type="text"
                className="form-control"
                onChange={handleChangeInput}
                value={masterObject?.serial_number}
              />
            </div>
          </Col>

          {IPValue === true && (
            <Col md="3">
              <div className="mt-2">
                <Label>Register Number</Label>
                <AvField
                  disabled={!editable}
                  name="reg_no"
                  placeholder="Register Number"
                  type="text"
                  className="form-control"
                  onChange={handleChangeInput}
                  value={masterObject?.reg_no}
                />
              </div>
            </Col>
          )}

          {IPValue === true ? (
            <Col md="3">
              <div className="mt-2">
                <Label>IP Number</Label>
                <AvField
                  disabled={!editable}
                  name="ip_number"
                  placeholder="IP Number"
                  type="text"
                  className="form-control"
                  // validate={{ required: { value: true } }}
                  onChange={handleChangeInput}
                  value={masterObject?.ip_number}
                />
              </div>
            </Col>
          ) : null}

          <Col md="3">
            <div className="mt-2">
              <Label>Registration Date</Label>
              <AvField
                disabled={!editable}
                name="reg_date"
                placeholder="Date"
                type="date"
                className="form-control"
                //validate={{ required: { value: true } }}
                onChange={handleChangeInput}
                value={masterObject?.reg_date}
              />
            </div>
          </Col>
          <Col md="3" className="mt-2">
            <Label>State</Label>
            <Select
              isDisabled={!editable}
              name="state"
              value={selectedState}
              validate={{ required: { value: true } }}
              onChange={(selected) => {
                handleSelectChange(selected, "state");
              }}
              options={stateOptions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3" className="mt-2">
            <Label>District</Label>
            <Select
              isDisabled={!editable}
              name="district"
              value={selectedDistrict}
              validate={{ required: { value: true } }}
              onChange={(selected) => {
                handleSelectChange(selected, "district");
              }}
              options={districtOptions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3">
            <div className="mt-2">
              <Label>Address</Label>
              <AvField
                disabled={!editable}
                name="address"
                placeholder="Address"
                type="textarea"
                className="form-control"
                // validate={{ required: { value: true } }}
                onChange={handleChangeInput}
                value={masterObject?.address}
                rows={1}
              />
            </div>
          </Col>
          <Col md="3" className="mt-2">
            <Label>Pin</Label>
            <AvField
              name="pin"
              type="text"
              disabled={!editable}
              // minLength={6}
              // maxLength={6}
              className="form-control"
              placeholder="Pincode"
              validate={{
                required: { value: true },
                pattern: { value: "^[0-9]+$", errorMessage: "Invalid pincode" },
              }}
              onChange={handleChangeInput}
              value={masterObject?.pin}
            />
          </Col>

          <Col md="3">
            <div className="mt-2">
              <Label>DOB</Label>
              <div className="d-flex w-100" style={{ gap: "3px" }}>
                <div style={{ flex: 1 }}>
                  <AvField
                    name="DD"
                    disabled={!editable}
                    validate={{
                      min: { value: 1, errorMessage: "1 to 31" },
                      max: { value: 31, errorMessage: "1 to 31" },
                      required: {
                        value: DOB?.MM?.length > 0,
                        errorMessage: "Day is required",
                      },
                    }}
                    value={DOB?.DD}
                    placeholder="DD"
                    onChange={handleChangeDob}
                    type="number"
                    className="form-control"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <AvField
                    name="MM"
                    disabled={!editable}
                    validate={{
                      min: { value: 1, errorMessage: "1 to 12" },
                      max: { value: 12, errorMessage: "1 to 12" },
                      required: {
                        value: DOB?.DD?.length > 0,
                        errorMessage: "Month is required",
                      },
                    }}
                    value={DOB?.MM}
                    placeholder="MM"
                    onChange={handleChangeDob}
                    type="number"
                    className="form-control"
                  />
                </div>
                <div style={{ flex: 2 }}>
                  <AvField
                    name="YYYY"
                    disabled={!editable}
                    placeholder="YYYY"
                    value={DOB?.YYYY}
                    onChange={handleChangeDob}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Year is required",
                      },
                      pattern: {
                        value: /^[0-9]{4}$/,
                        errorMessage: "Year must be a 4-digit number (YYYY)",
                      },
                    }}
                    type="number"
                    className="form-control flex-grow-1"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md="1">
            <div className="mt-2">
              <Label>Age</Label>
              <AvField disabled={!editable} name="age" type="Number" className="form-control" onChange={handleChangeInput} value={masterObject?.age} />
            </div>
          </Col>
          <Col md="2" className="mt-2">
            <Label>Gender</Label>
            <Select
              isDisabled={!editable}
              name="gender"
              placeholder="Select"
              value={selectedGender}
              onChange={(selected) => {
                handleSelectChange(selected, "gender");
              }}
              options={[
                { label: "Female", value: 0 },
                { label: "Male", value: 1 },
                { label: "Others", value: 2 },
              ]}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3" className="mt-2">
            <Label>Marital Status</Label>
            <Select
              isDisabled={!editable}
              name="marital_status"
              value={selectedMaritalStatus}
              onChange={(selected) => {
                handleSelectChange(selected, "marital_status");
              }}
              options={[
                { label: "Unmarried", value: 0 },
                { label: "Married", value: 1 },
                { label: "Separated", value: 2 },
                { label: "Divorced", value: 3 },
                { label: "Widowed", value: 4 },
              ]}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>

          <Col md="3" className="mt-2">
            <Label>Religion</Label>
            <Select
              isDisabled={!editable}
              name="religion"
              value={selectedReligion}
              onChange={(selected) => {
                handleSelectChange(selected, "religion");
              }}
              options={religionOptions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3" className="mt-2">
            <Label>Community</Label>
            <Select
              isDisabled={!editable}
              name="community"
              value={selectedCaste}
              onChange={(selected) => {
                handleSelectChange(selected, "community");
              }}
              options={casteOptions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3" className="mt-2">
            <Label>Qualification</Label>
            <Select
              isDisabled={!editable}
              name="qualification"
              placeholder="Select"
              value={selectedQualification}
              onChange={(selected) => {
                handleSelectChange(selected, "qualification");
              }}
              options={qualificationOptions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3" className="mt-2">
            <Label>Employment Status</Label>
            <Select
              isDisabled={!editable}
              name="employment_status"
              value={selectedEmploymentStatus}
              onChange={(selected) => {
                handleSelectChange(selected, "employment_status");
              }}
              options={[
                { label: "Employed", value: 1 },
                { label: "Unemployed", value: 0 },
              ]}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>

          {selectedEmploymentValue == true ? (
            <>
              <Col md="3" className="mt-2">
                <Label>Occupation</Label>
                <AvField
                  disabled={!editable}
                  name="occupation"
                  type="text"
                  className="form-control"
                  placeholder="Occupation"
                  onChange={handleChangeInput}
                  value={masterObject?.occupation}
                />
              </Col>
              <Col md="3">
                <div className="mt-2">
                  <Label>Income</Label>
                  <AvField
                    disabled={!editable}
                    name="income"
                    placeholder="Income"
                    type="Number"
                    className="form-control"
                    onChange={handleChangeInput}
                    value={masterObject?.income}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </Col>
            </>
          ) : null}
          <Col md="3" className="mt-2">
            <Label>Living Arrangements</Label>
            <Select
              isDisabled={!editable}
              name="living_arragement"
              placeholder="Select"
              value={selectedLivingArrangements}
              onChange={(selected) => {
                handleSelectChange(selected, "living_arragement");
              }}
              options={livingArrangementsOptions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3" className="mt-2">
            <Label>Place Of Residence</Label>
            <Select
              isDisabled={!editable}
              name="recidence_place"
              value={selectedPlaceResidence}
              onChange={(selected) => {
                handleSelectChange(selected, "recidence_place");
              }}
              options={[
                { label: "Rural", value: 0 },
                { label: "Urban", value: 1 },
              ]}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>

          <Col md="3" className="mt-2">
            <Label>Referral</Label>
            <Select
              isDisabled={!editable}
              name="refferal"
              placeholder="Select"
              value={selectedRefferal}
              onChange={(selected) => {
                handleSelectChange(selected, "refferal");
              }}
              options={referalOptions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3" className="mt-2">
            <Label>ID Card Type</Label>
            <Select
              name="identity_type"
              placeholder="Select"
              isDisabled={!editable}
              value={selectedIDcardtype}
              validate={{ required: { value: true } }}
              onChange={(selected) => {
                handleSelectChange(selected, "identity_type");
              }}
              options={[
                { label: "Voter ID", value: 1, placeholder: "Voter ID Number" },
                {
                  label: "Aadharcard",
                  value: 2,
                  placeholder: "Aadharcard Number",
                },
                { label: "Pancard", value: 3, placeholder: "Pancard Number" },
                { label: "Passport", value: 4, placeholder: "Passport Number" },
                {
                  label: "Driving licence",
                  value: 5,
                  placeholder: "Driving licence Number",
                },
                {
                  label: "Ration Card",
                  value: 6,
                  placeholder: "Ration Card Number",
                },
              ]}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3">
            <div className="mt-2">
              <Label>{selectedIDcardtype?.label || "ID Card No"}</Label>
              {selectedIDcardtype?.value === 2 ? (
                <AvField
                  name="identity_no"
                  disabled={!editable}
                  placeholder={selectedIDcardtype?.placeholder || "ID Card Number"}
                  type={selectedIDcardtype?.type || "text"}
                  className="form-control"
                  onChange={handleChangeInput}
                  value={masterObject?.identity_no}
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: /^\d{4}\s\d{4}\s\d{4}$/,
                      message: "Invalid Aadhaar Card Number",
                    },
                  }}
                  mask="9999 9999 9999"
                  tag={InputMask}
                />
              ) : (
                <AvField
                  name="identity_no"
                  disabled={!editable}
                  placeholder={selectedIDcardtype?.placeholder || "ID Card Number"}
                  type={selectedIDcardtype?.type || "text"}
                  className="form-control"
                  onChange={handleChangeInput}
                  value={masterObject?.identity_no}
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: getValidationPattern(selectedIDcardtype?.value),
                    },
                  }}
                />
              )}
            </div>
          </Col>

          <Col md="3" className="mt-2">
            <Label>Treated For Addiction Before</Label>
            <Select
              isDisabled={!editable}
              name="prior_treatment"
              placeholder="Select"
              value={selectedPriorTreatment}
              //  validate={{ required: { value: true } }}
              onChange={(selected) => {
                handleSelectChange(selected, "prior_treatment");
              }}
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
              ]}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>

          {selectedPriorValue == true ? (
            <>
              <Col md="3" className="mt-2">
                <Label>Year Of Treatment</Label>
                <Select
                  isDisabled={!editable}
                  value={selectedYear}
                  onChange={handleYearChange}
                  name="year"
                  //  onChange={(e)=> handleInputchange(e)}
                  options={years}
                  classNamePrefix="select2-selection"
                  menuPosition="auto"
                />
              </Col>

              <Col md="3" className="mt-2">
                <Label>Place of Treatment</Label>
                <AvField
                  disabled={!editable}
                  name="place_of_treatment"
                  type="text"
                  className="form-control"
                  placeholder="Place of Treatment"
                  value={masterObject?.place_of_treatment}
                  onChange={(e) => handleInputchange(e)}
                />
              </Col>
              <Col md="3" className="mt-2">
                <Label>Days/months of sobriety</Label>
                <AvField
                  disabled={!editable}
                  name="period"
                  type="text"
                  className="form-control"
                  placeholder="Days/months of sobriety"
                  onChange={(e) => handleInputchange(e)}
                  value={masterObject?.period}
                />
              </Col>
            </>
          ) : null}
          <Col md="3" className="mt-2">
            <Label>Counsellor</Label>
            <Select
              isDisabled={!editable}
              name="counsellor_id"
              placeholder="Select"
              value={selectedCounsellor}
              onChange={(selected) => {
                handleSelectChange(selected, "counsellor_id");
              }}
              options={counsellorOptions}
              classNamePrefix="select2-selection"
              menuPosition="auto"
            />
          </Col>
          <Col md="3">
            <div className="mt-2">
              <Label>Discharge Date</Label>
              <AvField
                disabled={!editable}
                name="exp_discharge_date"
                placeholder="Date"
                type="date"
                className="form-control"
                onChange={handleChangeInput}
                value={masterObject?.exp_discharge_date}
              />
            </div>
          </Col>

          <Col md="3">
            <div className="mt-2">
              <Label>Remarks</Label>
              <AvField
                disabled={!editable}
                name="remarks"
                placeholder="Remarks"
                type="textarea"
                className="form-control"
                onChange={handleChangeInput}
                value={masterObject?.remarks}
                rows={1}
              />
            </div>
          </Col>

          <Col md="3" className="mt-2">
            <div className="mb-3">
              <Label htmlFor="validationCustom03">Photo</Label>
              {masterObject?.photo ? (
                <div div className="img-wraps d-flex flex-column">
                  {masterObject.photo && masterObject.photo.startsWith("http") ? (
                    <img alt="" width="150" height="150" src={`${masterObject.photo}`} />
                  ) : (
                    <img alt="" width="150" height="150" src={`${API_URL}uploads/admissions/photos/${masterObject.photo}`} />
                  )}
                  {editable && (
                    <button
                      className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                      onClick={deleteImage}
                      style={{ width: "150px" }}>
                      Delete
                    </button>
                  )}
                </div>
              ) : (
                <AvField disabled={!editable} name="photo" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
              )}
            </div>
          </Col>
        </Row>

        <br></br>
        <h5>Support Person Details</h5>
        <Row>
          <Col xl="12">
            <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
              <TableHead>
                <TableRow style={{ textAlign: "center" }}>
                  <TableCell
                    style={{
                      width: "0px",
                      textAlign: "center",
                      fontWeight: "normal",
                      paddingLeft: "0rem",
                      paddingRight: "0rem",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    No.
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Address
                  </TableCell>
                  <TableCell
                    style={{
                      width: "130px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    State
                  </TableCell>
                  <TableCell
                    style={{
                      width: "130px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    District
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Mobile
                  </TableCell>
                  <TableCell
                    style={{
                      width: "120px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Relationship
                  </TableCell>
                  {values.length !== 1 && editable ? (
                    <TableCell
                      style={{
                        width: "0px",
                        textAlign: "center",
                        fontWeight: "500",
                        paddingLeft: "0rem",
                        paddingRight: "0rem",
                        fontSize: "12.819px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        color: "#495057",
                      }}>
                      Action
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {values &&
                  values.map((item, index) => (
                    <TableRow
                      style={{
                        verticalAlign: "top",
                      }}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          textAlign: "center",
                          fontSize: "12.819px",
                          fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                          fontWeight: "500",
                        }}>
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        <AvField
                          disabled={!editable}
                          name="sp_name"
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          id="validationCustom05"
                          onChange={(e) => handleTableInputchange(e, index)}
                          value={item.sp_name}
                        />
                      </TableCell>
                      <TableCell>
                        <AvField
                          disabled={!editable}
                          name="sp_address"
                          placeholder="Address"
                          type="textarea"
                          className="form-control"
                          rows={1}
                          id="validationCustom05"
                          onChange={(e) => handleTableInputchange(e, index)}
                          value={item.sp_address}
                        />
                        {!item.sp_address && (
                          <>
                            <p></p>
                            <AvInput
                              type="checkbox"
                              name="same_address"
                              style={{ cursor: "pointer" }}
                              // id="parents-death"
                              checked={sameAddress}
                              onClick={(e) => {
                                handleSameaddress(e, index);
                              }}
                            />
                            &nbsp; &nbsp;
                            <Label check for="checkbox">
                              Same as above
                            </Label>
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <Select
                          isDisabled={!editable}
                          name="sp_state_name"
                          //value={selectedSPState}
                          value={{
                            label: item.sp_state_name,
                            value: item.sp_state,
                          }}
                          onChange={(selected) => {
                            handleChangeSelectItem(selected, index);
                          }}
                          options={stateOptions}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          isDisabled={!editable}
                          name="sp_district_name"
                          value={{
                            label: item.sp_district_name,
                            value: item.sp_district,
                          }}
                          onChange={(selected) => {
                            handleChangeSelectItem1(selected, index);
                          }}
                          options={districtOptions}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </TableCell>
                      <TableCell>
                        <AvField
                          disabled={!editable}
                          name="sp_mobile"
                          type="number"
                          className="form-control"
                          placeholder="Mobile"
                          id="validationCustom05"
                          onChange={(e) => handleTableInputchange(e, index)}
                          value={item.sp_mobile}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          name="sp_relation"
                          isDisabled={!editable}
                          value={{
                            label: item.sp_relation,
                            value: item.sp_relation,
                          }}
                          onChange={(selected) => {
                            handleChangeSelectItem2(selected, index);
                          }}
                          options={relationshipOptions}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </TableCell>

                      {editable && (
                        <TableCell>
                          {values.length !== 1 && (
                            <i
                              className="fas fa-trash"
                              style={{
                                cursor: "pointer",
                                justifyContent: "center",
                              }}
                              onClick={() => handleRemove(index)}></i>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Col>
        </Row>
        {editable && (
          <span
            onClick={() => addMore()}
            style={{
              width: "190px",
              cursor: "pointer",
              fontSize: "12.819px",
              fontFamily: "IBM Plex Sans, sans-serif",
              color: "#495057",
              fontWeight: "500",
            }}>
            {" "}
            + Add More{" "}
          </span>
        )}
        {editable && (
          <Row className="mt-4">
            <Col md={12} className="submit-btn-fixed">
              <FormGroup className="float-right ">
                <button type="submit" className="save-continue-btn">
                  Save
                  <i style={{ fontSize: "13px" }}></i>
                </button>
              </FormGroup>
            </Col>
          </Row>
        )}
      </AvForm>
    </div>
  );
}

export default BasicDetails;
