/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import { getLocalbody } from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import "./company.scss";
// import {
//   addCompany,
//   updateCompany,
//   deleteCompany,
// } from "../../../store/actions";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

const Company = () => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  //const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [uploadProgress, setUploadProgress] = useState();
  const [companyIdTobeUpdated, setCompanyIdTobeUpdated] = useState(null);
  const [companyIdToBeDeleted, setCompanyIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  // const dispatch = useDispatch();
  const formRef = useRef();
  const { addingCompany, deletingCompany, addCompanyResponse, updateCompanyResponse, deleteCompanyResponse, error } = useSelector((state) => state.companies);

  useEffect(() => {
    fetch_all_states();
    handleTableData();
  }, []);

  // useEffect(() => {
  //   //console.log("addCompanyResponse:-------- ", addCompanyResponse);
  //   if (addCompanyResponse.type === "success") {
  //     toastr.success(addCompanyResponse.message);
  //     handleTableData();
  //   } else if (addCompanyResponse.type === "failure") {
  //     toastr.error(addCompanyResponse.message);
  //   }
  // }, [addCompanyResponse]);

  // useEffect(() => {
  //   //console.log("updateCompanyResponse:---->>>> ", updateCompanyResponse);
  //   if (updateCompanyResponse.type === "success") {
  //     toastr.success(updateCompanyResponse.message);
  //     setCompanyIdTobeUpdated(null);
  //     handleTableData();
  //   } else if (updateCompanyResponse.type === "failure") {
  //     toastr.error(error.data.message, updateCompanyResponse.message);
  //   }
  // }, [updateCompanyResponse]);

  // useEffect(() => {
  //   if (deleteCompanyResponse.type === "success") {
  //     toastr.success(deleteCompanyResponse.message);
  //     setCompanyIdToBeDeleted(null);
  //     handleTableData();
  //   } else if (deleteCompanyResponse.type === "failure") {
  //     toastr.error(error.data.message, deleteCompanyResponse.message);
  //   }
  // }, [deleteCompanyResponse]);

  function fetch_all_states() {
    axios
      .get(`${API_URL}states/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var state_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el.id,
            };
          });

        setStateOptions([
          {
            options: state_data,
          },
        ]);
      });
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preUpdateCompany = (item) => {
    //console.log("item: ", item);
    setCompanyIdTobeUpdated(item._id);
    axios
      .get(`${API_URL}company/singlelist?company_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item) => {
            let districtData = {
              label: item?.district_name,
              value: item?.district_id,
            };

            setSelectedDistrict(districtData);

            let stateData = {
              label: item?.state_name,
              value: item?.state_id,
            };
            item.company_district = item.district_id;
            item.company_state = item.state_id;
            // handleSelectChange(stateData,"state");
            setSelectedState(stateData);
            fetch_all_districts(stateData?.value);
          });
        setMaster(...result);
      });
  };
  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}company/singlelist?company_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        //console.log("result: ", result);
        result &&
          result.map((item) => {
            item.date = moment(item.company_date).format("DD-MM-YYYY");
            item.time = moment(item.company_time, "HHmmss").format("hh:mm a");
            setCompanyDetails(item);
            setShowPopup(true);
          });
      });
  };
  function handleTableData() {
    var url = `${API_URL}company/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        //console.log("result: ", result);

        result.map((item, index) => {
          item.id = index + 1;
          // item.localbody_name =  getLocalbody();
          // item.type = item?.cust_type;
          // item.district_name = item?.district;
          // item.ward_name = item?.ward;

          // item.image = (
          //   <div style={{ display: "flex", justifyContent: "center" }}>
          //     <img
          //       src={`${API_URL}public/uploads/company_logo${item?.company_logo}`}
          //       height="160"
          //       width="120"
          //     />
          //   </div>
          // );

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  preUpdateCompany(item);
                  toTop();
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setCompanyIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </div>
          );
        });

        setDetails(result);
      });
  }
  function fetch_all_districts(id) {
    axios
      .get(`${API_URL}districts/list?state_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var district_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.district_name,
              value: el._id,
            };
          });

        setDistrictOptions([
          {
            options: district_data,
          },
        ]);
      });
  }
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "district":
        setSelectedDistrict(selected);
        setMaster({
          ...master,
          ["company_district"]: selected.value,
        });
        break;
      case "state":
        setSelectedState(selected);
        setSelectedDistrict(null);
        setMaster({
          ...master,
          ["company_state"]: selected.value,
        });
        fetch_all_districts(selected.value);
        break;
      default:
        break;
    }
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("company_logo", e.target.files[0]);
    axios
      .post(`${API_URL}company/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        //console.log("response: ", response);
        if (response.data.status === "success") {
          setMaster({
            ...master,
            [`company_logo`]: response.data.file.filename,
          });
          // toastr.success("Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const handleValidSubmit = (event, values) => {
    if (companyIdTobeUpdated) {
      axios
        .put(`${API_URL}company/update`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Company updated successfully");
            formRef.current.reset();
            setSelectedDistrict(null);
            setSelectedState(null);
            setMaster({
              company_logo: "",
            });
            handleTableData();
            setCompanyIdTobeUpdated(null);
          } else {
            toastr.error("Falied to update company");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}company/`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          //console.log("res: ", res);
          if (res.data.status === 200) {
            toastr.success("Company created successfully");
            formRef.current.reset();
            setSelectedDistrict(null);
            setSelectedState(null);
            setMaster({
              company_logo: "",
            });
            handleTableData();
          } else {
            toastr.error("Falied to create company");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
    // companyIdTobeUpdated
    //   ? dispatch(updateCompany(master))
    //   : dispatch(addCompany(master));
  };
  const deleteCompanyIcon = () => {
    setMaster({
      ...master,
      [`company_logo`]: "",
    });
  };
  const data = {
    columns: [
      {
        label: "Company ID",
        field: "company_unqid",
        sort: "asc",
        width: 150,
      },
      {
        label: "Company Name",
        field: "company_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Code",
        field: "company_shortcode",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contact Person",
        field: "company_person",
        sort: "asc",
        width: 100,
      },
      {
        label: "Mobile",
        field: "company_mob",
        sort: "asc",
        width: 10,
      },
      {
        label: "District",
        field: "district_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Added By",
        field: "staff_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}company` + "/" + companyIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Company deleted successfully");
                  if (master && master?._id === companyIdToBeDeleted) {
                    formRef.current.reset();
                    setSelectedDistrict(null);
                    setSelectedState(null);
                    setMaster({
                      company_logo: "",
                    });
                    handleTableData();
                    setCompanyIdTobeUpdated(null);
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete company");
                  return;
                }
              });
            // dispatch(deleteCompany(companyIdToBeDeleted));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal show={showPopup} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Company Details</h5>
          <button
            type="button"
            onClick={() => {
              setShowPopup(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table id="product_available_day" className="table table-bordered dataTable">
              <tr>
                <td style={{ padding: "10px", width: "282px" }}>Company Name :</td>
                <td>{companyDetails?.company_name}</td>
                <td>Company ID :</td>
                <td style={{ textAlign: "left" }}>{companyDetails?.company_unqid}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", width: "282px" }}>Date :</td>
                <td>{companyDetails?.date}</td>
                <td>Time :</td>
                <td style={{ textAlign: "left" }}>{companyDetails?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", width: "282px" }}>Company Code :</td>
                <td>{companyDetails?.company_shortcode}</td>
                <td>Contact Person :</td>
                <td style={{ textAlign: "left" }}>{companyDetails?.company_person}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", width: "282px" }}>Mobile :</td>
                <td>{companyDetails?.company_mob}</td>
                <td>Designation :</td>
                <td style={{ textAlign: "left" }}>{companyDetails?.company_design}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", width: "282px" }}>District :</td>
                <td>{companyDetails?.district_name}</td>
                <td>State :</td>
                <td style={{ textAlign: "left" }}>{companyDetails?.state_name}</td>
              </tr>
              <tr hidden={!companyDetails?.company_logo}>
                <td style={{ padding: "10px", width: "282px" }}>Logo :</td>
                <td style={{ textAlign: "left" }}>
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      paddingLeft: "0px",
                    }}
                    src={`${API_URL}uploads/company_logo/${companyDetails?.company_logo}`}></img>
                </td>
              </tr>
              {/* <tr>
                  <td style={{ padding: "10px",width:"282px" }}>Address :</td>
                  <td>{companyDetails?.company_address}</td>
                  <td>Pin :</td>
                  <td style={{ textAlign: "left" }}>{companyDetails?.company_pin}</td>
                </tr> */}
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Company" />

          {/*Text field 1*/}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Company Name</Label>
                          <AvField
                            name="company_name"
                            placeholder="Company Name"
                            type="text"
                            errorMessage="Enter Company Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleInputChange}
                            value={master?.company_name}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Short Code</Label>
                          <AvField
                            name="company_shortcode"
                            placeholder="Short Code"
                            type="text"
                            errorMessage="Enter Short code"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleInputChange}
                            value={master?.company_shortcode}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Contact Person</Label>
                          <AvField
                            name="company_person"
                            placeholder="Contact Person"
                            type="text"
                            errorMessage="Enter Contact Person"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleInputChange}
                            value={master?.company_person}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Designation</Label>
                          <AvField
                            name="company_design"
                            placeholder="Designation"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleInputChange}
                            value={master?.company_design ? master.company_design : ""}
                          />
                        </div>
                      </Col>

                      {/*Text field 2*/}
                    </Row>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Mobile No.</Label>
                          <AvField
                            name="company_mob"
                            placeholder="Mobile No."
                            type="number"
                            errorMessage="Enter valid mobile no"
                            className="form-control"
                            validate={{
                              minLength: { value: 10 },
                              maxLength: { value: 10 },
                            }}
                            id="validationCustom03"
                            onChange={handleInputChange}
                            value={master?.company_mob ? master.company_mob : ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Landline No.</Label>
                          <AvField
                            name="company_land"
                            placeholder="Landline No."
                            type="phone"
                            errorMessage="Enter valid 11 digit number"
                            className="form-control"
                            validate={{
                              minLength: { value: 11 },
                              maxLength: { value: 11 },
                            }}
                            id="validationCustom04"
                            onChange={handleInputChange}
                            value={master?.company_land ? master.company_land : ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Email ID</Label>
                          <AvField
                            name="company_email"
                            placeholder="Email ID"
                            type="email"
                            errorMessage=" Please provide a valid Email ID."
                            className="form-control"
                            validate={{ email: true }}
                            id="validationCustom05"
                            onChange={handleInputChange}
                            value={master?.company_email ? master.company_email : ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Website</Label>
                          <AvField
                            name="company_web"
                            placeholder="Website"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleInputChange}
                            value={master?.company_web ? master.company_web : ""}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/*Text field 3*/}
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Address</Label>
                          <AvField
                            name="company_address"
                            placeholder="Address"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleInputChange}
                            value={master?.company_address ? master.company_address : ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Select State</Label>
                          <Select
                            name="state_id"
                            value={selectedState}
                            onChange={(selected) => {
                              handleSelectChange(selected, "state");
                            }}
                            options={stateOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="district_id"
                            value={selectedDistrict}
                            onChange={(selected) => {
                              handleSelectChange(selected, "district");
                            }}
                            options={districtOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">PIN</Label>
                          <AvField
                            name="company_pin"
                            placeholder="PIN"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleInputChange}
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "PIN must be only with numbers",
                              },
                            }}
                            value={master?.company_pin ? master.company_pin : ""}
                            min={0}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/*Text field 4*/}
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">GSTIN</Label>
                          <AvField
                            name="company_gstin"
                            placeholder="GSTIN"
                            type="number"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleInputChange}
                            value={master?.company_gstin ? master.company_gstin : ""}
                            min={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">PAN No.</Label>
                          <AvField
                            name="company_pan"
                            placeholder="PAN No"
                            type="text"
                            className="form-control"
                            id="validationCustom04"
                            onChange={handleInputChange}
                            value={master?.company_pan ? master.company_pan : ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">CIN No.</Label>
                          <AvField
                            name="company_cin"
                            placeholder="CIN No."
                            type="number"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleInputChange}
                            value={master?.company_cin ? master.company_cin : ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">TDS No.</Label>
                          <AvField
                            name="company_tds"
                            placeholder="TDS No"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleInputChange}
                            value={master?.company_tds ? master.company_tds : ""}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/*Text field 5*/}
                    <Row>
                      {/* <Col md="3" style={{ display: "block" }}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Company Logo(300x400)
                          </Label>
                          <AvField
                            name="company_logo"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            onChange={uploadImage}
                            rows="1"
                          />
                        </div>
                      </Col> */}
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Company Logo(300x400)
                          </Label>
                          <br />
                          <Button variant="contained" component="label">
                            <Upload></Upload>
                            {"  "}
                            Upload File
                            <input type="file" hidden />
                          </Button>
                        </div>
                      </Col> */}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Latitude</Label>
                          <AvField
                            name="company_latitude"
                            placeholder="Latitude"
                            type="number"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleInputChange}
                            value={master?.company_latitude ? master.company_latitude : ""}
                            //value={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Longitude</Label>
                          <AvField
                            name="company_longitude"
                            placeholder="Longitude"
                            type="number"
                            className="form-control"
                            id="validationCustom04"
                            onChange={handleInputChange}
                            value={master?.company_longitude ? master.company_longitude : ""}
                            //value={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Company Logo</Label>
                          {master?.company_logo ? (
                            <div div className="img-wraps">
                              {master.company_logo && master.company_logo.startsWith("http") ? (
                                <img alt="" width="150" height="150" src={`${master.company_logo}`} />
                              ) : (
                                <img alt="" width="150" height="150" src={`${API_URL}uploads/company_logo/${master.company_logo}`} />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteCompanyIcon}
                                style={{ width: "150px" }}>
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField name="company_logo" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
                          )}
                          {/* {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )} */}
                        </div>
                      </Col>
                    </Row>

                    {/*Text Field End*/}
                    {companyIdTobeUpdated ? (
                      <Button color="primary" type="submit" disabled={addingCompany ? true : false}>
                        {addingCompany ? "Updating" : "Update"}
                      </Button>
                    ) : (
                      <Button color="primary" type="submit" disabled={addingCompany ? true : false}>
                        {addingCompany ? "Adding" : "Submit"}
                      </Button>
                    )}
                    {/* <Button color="primary" type="submit">
                      Submit
                    </Button> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable id="companyTableId" responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Company;
