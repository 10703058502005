/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";

import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Col, Label, Button, Card, CardBody, Row, ToastHeader } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import moment from "moment";
import toastr from "toastr";

import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./Faqcategory.scss";

const Faqcategory = () => {
  const [faqCategoryObject, setfaqCategoryObject] = useState({});
  const [faqCategoryValue, setfaqCategoryValue] = useState("");
  const [uploadProgress, setUploadProgress] = useState();
  const [faqCategoryForTable, setfaqCategoryForTable] = useState([]);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [faqIdToBeDeleted, setfaqIdToBeDeleted] = useState(null);
  const [faqIdToBeUpdated, setfaqIdToBeUpdated] = useState(null);

  const reset = () => {
    // formRef.current.reset();
    // setcargoIdToUpdated(null);
    setfaqCategoryObject({});
  };
  const {
    districts,
    addingfaqCategory,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const formRef = useRef();
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // fetch_modules();
    // fetch_branch();
    // fetch_privilege();
    handleTableData();
  }, []);
  function handleTableData() {
    var url = `${API_URL}faqCategory/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        //console.log("result: ", result);
        let faqData = [];

        result.map((item, index) => {
          if (typeof item.icon != "undefined" && item.icon != "") {
            item.icon = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={`${API_URL}uploads/faqCategory_icon/${item.icon}`}
                  style={{ height: "25px", cursor: "pointer" }}
                  onClick={() => window.open(`${API_URL}uploads/faqCategory_icon/${item.icon}`, "_blank")}
                />
              </div>
            );
          } else {
            item.icon = "";
          }
          // if(item.icon!="" && typeof item.icon!="undefined")
          // {
          //   item.icon = (
          //     <div
          //       style={{
          //         display: "flex",
          //         justifyContent: "space-evenly",
          //       }}
          //     >
          //       <img
          //         src={`${API_URL}uploads/faqCategory_icon/${item.icon}`}
          //       ></img>
          //     </div>
          //   );
          // }
          // else{
          //   item.icon="";
          // }

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateFaq(item);
                }}></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setfaqIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </div>
          );
          item.id = index + 1;
          // if(item.icon!="" && typeof item.icon!="undefined")
          // {
          //   item.icon = (
          //       <div style={{ display: "flex", justifyContent: "center" }}>
          //         <img
          //           src={`${API_URL}uploads/faqCategory_icon/${item.icon}`}
          //           style={{ height:"25px", cursor: "pointer" }}
          //           onClick={()=> window.open(`${API_URL}uploads/faqCategory_icon/${item.icon}`, "_blank")}
          //         />
          //       </div>
          //     );
          //     //console.log(item);
          // }
          // else
          // {
          //   item.icon = "";
          // }
          // item.date = moment(item.subject_date).format("DD-MM-YYYY");
          //  item.time = moment(item.subject_time, "HHmmss").format("hh:mm a");
          // if (item?.subjectup_date) item.date = moment(item.subjectup_date).format("DD-MM-YYYY");
          // else item.date = moment(item.subject_date).format("DD-MM-YYYY");

          // if (item?.subjectup_time) item.time = moment(item.subjectup_time,"HHmmss").format("hh:mm a");
          // else item.time = moment(item.subject_time,"HHmmss").format("hh:mm a");

          if (item?.updatedBy) item.staff = item.updatedBy.firstName;
          else item.staff = item?.addedby.firstName;
          faqData.push(item);
        });

        setfaqCategoryForTable(faqData);
      });
  }

  let preUpdateFaq = (item) => {
    // setfaqCategoryValue(item?.name);

    setfaqIdToBeUpdated(item?._id);
    axios
      .get(`${API_URL}faqCategory/single?faq_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var state = res.data.data;
          //console.log("state",state)
          setfaqCategoryObject(state);
        }
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        // sort: "asc",
        width: 150,
      },
      {
        label: "Category Name",
        field: "category_name",
        sort: "desc",
        width: 270,
      },
      {
        label: "Icon",
        field: "icon",
        // sort: "asc",
        width: 150,
      },

      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "desc",
        width: 150,
      },
    ],
    rows: faqCategoryForTable,
  };

  function handleChangeSubject(e) {
    let name = e.target.name;
    let value = e.target.value;
    setfaqCategoryValue(value);
    setfaqCategoryObject({ ...faqCategoryObject, [name]: value });
    //console.log(e.target.value);
    //console.log(e.target.name);
  }
  const handleValidSubmit = () => {
    //console.log(faqCategoryObject)

    if (faqIdToBeUpdated) {
      axios
        .put(`${API_URL}faqCategory/update?_id`, faqCategoryObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Faq Category updates successfully");
            handleTableData();
            setfaqCategoryObject({});
            formRef.current.reset();
            setfaqIdToBeUpdated(null);
          } else toastr.error("Failed to update Faq Category");
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}faqCategory/`, faqCategoryObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          //console.log(res);
          if (res.data.success === true) {
            toastr.success("Faq Category added successfully");
            handleTableData();
            // setShowCompany(false);
            setfaqCategoryValue("");
            setfaqCategoryObject({});
            formRef.current.reset();
          } else toastr.error("Failed to add Faq Category");
        })
        .catch((err) => {
          console.log(err);
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("faqCtegory_icon", e.target.files[0]);
    axios
      .post(`${API_URL}faqCategory/faqicon/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setfaqCategoryObject({
            ...faqCategoryObject,
            [`icon`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteImage = () => {
    setfaqCategoryObject({
      ...faqCategoryObject,
      [`icon`]: "",
    });
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}faqCategory` + "/" + faqIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Faq Category deleted successfully");

                  if (faqCategoryObject && faqCategoryObject?._id === faqIdToBeDeleted) {
                    formRef.current.reset();
                    setfaqIdToBeUpdated(null);
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete Faq Category");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Faq Category" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Category Name</Label>
                          <AvField
                            name="category_name"
                            placeholder="Category Name"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Category Name"
                            onChange={handleChangeSubject}
                            //  value={faqCategoryValue}
                            value={faqCategoryObject?.category_name}
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Icon</Label>
                          {faqCategoryObject?.icon ? (
                            <div div className="img-wraps">
                             
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/faqCategory_icon/${faqCategoryObject?.icon}`}
                                />
                              
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteImage}
                                style={{ width: "150px" }}
                              
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="icon"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col> */}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Icon</Label>
                          {faqCategoryObject?.icon ? (
                            <div div className="img-wraps">
                              {faqCategoryObject.icon && faqCategoryObject.icon.startsWith("http") ? (
                                <img alt="" width="150" height="150" src={`${faqCategoryObject.icon}`} />
                              ) : (
                                <img alt="" width="150" height="150" src={`${API_URL}uploads/faqCategory_icon/${faqCategoryObject.icon}`} />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteImage}
                                style={{ width: "150px" }}>
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField name="icon" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
                          )}
                        </div>
                      </Col>
                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {faqIdToBeUpdated ? (
                            <Button
                              className="mx-2"
                              color="primary"
                              type="submit"
                              // style={{ marginRight: "5%" }}
                              disabled={addingfaqCategory ? true : false}>
                              {addingfaqCategory ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button className="mx-2" color="primary" type="submit" disabled={addingfaqCategory ? true : false}>
                              {addingfaqCategory ? "Adding" : "Submit"}
                            </Button>
                          )}
                          <Button color="danger" type="reset" onClick={() => reset()}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    id="AddFaqcategorytable"
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Faqcategory;
