import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";


export const getPatientsOpt = (date, keyword) =>
  axios
    .get(`admission/options/names`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => {
      res.data.data.map((item) => {
        item.label = item?.fname + " " + item?.lname;
        item.value = item?._id;

        return item
      });
      return res.data.data;
    })
    .catch((err) => console.error(err));

/**
 * @param id - The ID of the admission id.
 * @param date - result date
 */
export const getPatientResults = (id, date) => {
  if (!id) {
    return Promise.resolve({ data: [] });
  }
  return axios
    .get(`test?id=${id || ""}&date=${date || ""}`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const addTestResult = (data) =>
  axios
    .post(`test/result`, data, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data)
    .catch((err) => console.error(err.response.data));

//   {

//     "admission_id": "ObjectId",
//     "date": "String(YYYY-MM-DD)",
// // pass any one field or all from below
//     "temp_morning": "String",
//     "temp_noon": "String",
//     "temp_evening": "String",
//     "bp": "String",
//     "pulse": "String"
// }
