/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./medicine.scss";
const Medicine = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [medicineIdTobeUpdated, setMedicineIdTobeUpdated] = useState(null);
  const [medicineIdTobeDeleted, setMedicineTobeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preUpdateMedicine = (item) => {
    if (item?.name) {
      setMaster({
        medicine_name: item.name,
        medicine_id: item._id,
        dose: item.dose,
      });
      setMedicineIdTobeUpdated(item?._id);
    }
  };
  const handleTableData = () => {
    axios
      .get(`${API_URL}medicine/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            if (item?.updated_date) item.date = moment(item?.updated_date).format("DD-MM-YYYY");
            else if (item?.date) item.date = moment(item?.date).format("DD-MM-YYYY");
            else item.date = "";

            if (item?.updated_time) item.time = moment(item?.updated_time, "hhmmss").format("hh:mm a");
            else if (item?.time) item.time = moment(item?.time, "hhmmss").format("hh:mm a");
            else item.time = "";
            if (item?.updatedby) {
              let updatedBy = item.updatedby || {};
              if (updatedBy?.lastName) item.staff = updatedBy?.firstName + " " + updatedBy?.lastName;
              else item.staff = updatedBy?.firstName;
            } else {
              let addedBy = item.addedby || {};
              if (addedBy?.lastName) item.staff = addedBy?.firstName + " " + addedBy?.lastName;
              else item.staff = addedBy?.firstName;
            }
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1.2em",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateMedicine(item);
                  }}></i>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => {
                    setMedicineTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </div>
            );
          });
        setDetails(result);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },

      {
        label: "Medicine",
        field: "name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Dose",
        field: "dose",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
    ],
    rows: details,
  };

  useEffect(() => {
    if (props.options.edit) {
      data.columns.push({
        label: "Action",
        field: "action",
        width: 300,
      });
    }
  }, [props.options]);

  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
    if (medicineIdTobeUpdated) {
      axios
        .put(`${API_URL}medicine/update`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Medicine updated successfully");
            setMaster({});
            handleTableData();
            setMedicineIdTobeUpdated(null);
          } else {
            toastr.error("Failed to update Medicine");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}medicine/add`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Medicine added successfully");
            setMaster({});
            handleTableData();
          } else {
            toastr.error("Failed to add Medicine");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}medicine` + "/" + medicineIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Medicine deleted successfully");
                  if (master && master.medicine_id === medicineIdTobeDeleted) {
                    formRef.current.reset();
                    setMaster({});
                    setMedicineIdTobeUpdated(null);
                  }
                  setMedicineTobeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Medicine");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Medicine" />
          {props.options.edit && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={() => {
                        handleValidSubmit();
                      }}>
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Medicine Name</Label>
                            <AvField
                              name="medicine_name"
                              placeholder="Medicine name"
                              type="text"
                              errorMessage="Enter Medicine Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={master?.medicine_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Dose</Label>
                            <AvField
                              name="dose"
                              placeholder="Dose"
                              type="text"
                              errorMessage="Enter Dose"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={master?.dose}
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>

                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {medicineIdTobeUpdated ? (
                              <Button color="primary" type="submit">
                                {"Update"}
                              </Button>
                            ) : (
                              <Button color="primary" type="submit">
                                {"Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="manageMedicineTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Medicine;
