/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//import "./grouptherapy.scss";
import toastr from "toastr";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Treatmentplan = (props) => {
  const formRef = useRef();

  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientOptions, setPatientOptions] = useState([]);
  const [values, setValues] = useState([{}]);
  const [patientvalues, setPatientValues] = useState(false);
  const [patientprevvalues, setPatientPrevValues] = useState(false);
  const [masterObject, setmasterObject] = useState({});
  const [selectedGender, setSelectedGender] = useState(null);
  const [grouptherapyIdTobeDeleted, setGrouptherapyIdTobeDeleted] = useState(null);
  const [grouptherapyIdTobeUpdated, setGrouptherapyIdTobeUpdated] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [issues, setIssues] = useState([]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetch_all_patients();
    handleTableData();
    //fetch_previous_grouptherapy_details()
    fetch_all_treatmentplan_goals();
  }, []);

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: `${el?.fname} ${el?.lname || ""} ${el?.reg_no ? "- " + el?.reg_no : ""}`,
              value: el?._id,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  const fetch_all_treatmentplan_goals = (e) => {
    return axios
      .get(`${API_URL}options/treatmentplan_goals?admission_id=` + e, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let datas = {};
        let data = res.data.data;

        setIssues(data);
        setValues(data);
        return data;
      });
  };
  const handleSelectChange = async (selected, type) => {
    switch (type) {
      case "admission_id":
        setSelectedPatient(selected);
        setPatientValues(true);

        //fetch_previous_medication_details(selected.value)
        let data = await fetch_patient_details(selected.value);

        let goals = await fetch_all_treatmentplan_goals(selected.value);
        // //console.log("previous_data", previous_data)
        setmasterObject({
          ...masterObject,
          mobile: data.mobile,
          age: data.age,
          gender: data.gender,
          admitted_date: data.admitted_date,
          admission_id: selected.value,
          counsellor: data.counsellor,
        });
        setValues(goals);

        break;

      default:
        break;
    }
  };
  const fetch_patient_details = (_id) => {
    return axios
      .get(`${API_URL}admission?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        //console.log("qq", result)

        let data = {};
        data.mobile = result?.basic_details?.basic_id?.mobile;
        data.age = result?.basic_details?.age;
        //data.gender = result?.basic_details?.gender
        data.counsellor = result?.counsellor_id?.firstName;
        data.admitted_date = moment(result?.basic_details?.reg_date).format("DD-MM-YYYY");
        let gender_value = {};

        if (result?.basic_details?.gender == 0) {
          data.gender = "Female";
        }
        if (result?.basic_details?.gender == 1) {
          data.gender = "Male";
        }
        if (result?.basic_details?.gender == 2) {
          data.gender = "Others";
        }

        setSelectedGender(gender_value);
        return data;
      });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const preUpdateGrouptherapy = (item) => {
    setGrouptherapyIdTobeUpdated(item?._id);
    axios
      .get(`${API_URL}group-therapy?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        //item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname
        item._id = result?._id;
        item.admission_id = result?.admission_id?._id;
        item.reg_no = result?.admission_id?.reg_no;
        item.staff = result?.addedBy?.firstName;
        item.from_date = result?.from_date;
        item.to_date = result?.to_date;

        item.group = result?.group;
        item.therapist = result?.therapist;
        item.details = result?.details;
        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };
        setValues(result?.details);

        setSelectedPatient(patient);

        setmasterObject(item);
      });
  };
  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}group-therapy?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        item.from_date = moment(result?.from_date).format("DD-MM-YYYY");
        item.to_date = moment(result?.to_date).format("DD-MM-YYYY");
        item.reg_no = result?.admission_id?.reg_no;
        item.staff = result?.addedBy?.firstName;

        item.group = result?.group;
        item.therapist = result?.therapist;
        item.details = result?.details;
        // result?.details.map((value,index)=>{
        //     item.issue=

        // })

        //console.log("item", item)

        setPopupData(item);
        setPopupView(true);
      });
  };

  function handleTableData() {
    var url = `${API_URL}group-therapy/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        //console.log("result", result)

        result.map((item, index) => {
          item.id = index + 1;
          item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
          item.from_date = moment(item?.from_date).format("DD-MM-YYYY");
          item.to_date = moment(item?.to_date).format("DD-MM-YYYY");
          item.reg_no = item?.admission_id?.reg_no;
          item.firstName = item?.addedBy?.firstName;
          // item.duration = item?.duration;
          item.group = item?.group;
          item.therapist = item?.therapist;

          item.action = (
            <>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  preUpdateGrouptherapy(item);
                  toTop();
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "0.9em", cursor: "pointer" }}
                onClick={() => {
                  setGrouptherapyIdTobeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </>
          );
        });

        setTableData(result);
      });
  }

  const customStyles = {
    lineHeight: "1.8",
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "from_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "reg_no",
        sort: "asc",
        width: 270,
      },

      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  function handleTableInputchange(e, index, goal) {
    let { name, value } = e.target;
    const list = [...values];
    //list[index]={goal:'',activities:'',time_frame:''}
    //list[index].goal = goal;
    list[index][name] = value;
    setValues(list);
    setmasterObject({ ...masterObject, treatment_plan: list });
  }

  const handleValidSubmit = (data) => {
    //console.log("selectedValue", data)
    // if (grouptherapyIdTobeUpdated) {
    //     axios
    //         .put(`${API_URL}group-therapy`, masterObject, {
    //             headers: {
    //                 "x-access-token": accessToken,
    //             },
    //         })
    //         .then((res) => {

    //             toastr.success("Group Therapy updated successfully");

    //             formRef.current.reset();

    //             setValues([{}]);
    //             setmasterObject({});
    //             setSelectedPatient(null)
    //             setCounsellingType(null)
    //             setPatientValues(false)
    //             setPatientPrevValues(false)
    //             handleTableData();
    //             setValues([{}]);

    //         })
    //         .catch((err) => {

    //             console.log(err);
    //             return;
    //         });

    // }
    // else {
    axios
      .post(`${API_URL}admission/treatment-plan`, masterObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success("Treatment Plan added successfully");

        formRef.current.reset();

        setValues([
          {
            ...values,
            activities: "",
            time_frame: "",
          },
        ]);
        setmasterObject({});
        setSelectedPatient(null);
        setPatientValues(false);
        setPatientPrevValues(false);
        handleTableData();
        fetch_all_treatmentplan_goals();
      })
      .catch((err) => {
        console.log(err);
        return;
      });
    // }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Treatment Plan" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {props.options.edit ? (
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={() => {
                        handleValidSubmit(masterObject);
                      }}>
                      <Row>
                        <Col md="3">
                          <div className="mt-1">
                            <Label>Patient</Label>
                            <Select
                              name="admission_id"
                              value={selectedPatient}
                              onChange={(selected) => {
                                handleSelectChange(selected, "admission_id");
                              }}
                              // validate={{ required: { value: true } }}
                              options={patientOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        {patientvalues == true ? (
                          <>
                            <Col md="9">
                              <Row>
                                <div className="mt-1">
                                  <h6>Patient Basic Details:</h6>
                                </div>
                              </Row>
                              <Row>
                                <Col md="3" className="mt-1">
                                  <div>
                                    <Label>Mobile:</Label>
                                    <span>{masterObject?.mobile}</span>
                                  </div>
                                </Col>
                                <Col md="2" className="mt-1">
                                  <Label>Gender:</Label>
                                  <span>{masterObject?.gender}</span>
                                </Col>
                                <Col md="1" className="mt-1">
                                  <Label>Age:</Label>
                                  <span>{masterObject?.age}</span>
                                </Col>
                                <Col md="3" className="mt-1">
                                  <Label>Admitted Date:</Label>
                                  <span>{masterObject?.admitted_date}</span>
                                </Col>
                                <Col md="3" className="mt-1">
                                  <Label>Counsellor:</Label>
                                  <span>{masterObject?.counsellor}</span>
                                </Col>
                              </Row>
                            </Col>
                          </>
                        ) : null}

                        {/* <Col md="2" className="mt-1">

                                <Label>From Date</Label>
                                <AvField

                                    name="from_date"
                                    placeholder="Date"
                                    type="date"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    onChange={handleChangeInput}
                                    value={masterObject?.from_date}
                                   
                                />


                            </Col>
                            <Col md="2" className="mt-1">

                                <Label>To Date</Label>
                                <AvField

                                    name="to_date"
                                    placeholder="Date"
                                    type="date"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    onChange={handleChangeInput}
                                    value={masterObject?.to_date}
                                    min={masterObject?.from_date}
                                />


                            </Col> */}

                        {/* <Col md="2" className="mt-1">

                                <Label>Therapist</Label>
                                <AvField

                                    name="therapist"
                                    type="text"
                                    className="form-control"
                                    placeholder="Therapist Name"
                                    value={masterObject?.therapist}
                                    onChange={handleChangeInput}

                                />


                            </Col>
                            <Col md="2" className="mt-1">

                                <Label>Group</Label>
                                <AvField

                                    name="group"
                                    type="text"
                                    className="form-control"
                                    placeholder="Group"
                                    value={masterObject?.group}
                                    onChange={handleChangeInput}

                                />


                            </Col> */}
                      </Row>

                      <Row>
                        <Col xl="11">
                          <div className="mt-3">
                            <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                              <TableHead>
                                <TableRow style={{ textAlign: "center" }}>
                                  <TableCell
                                    style={{
                                      // width: "180px",
                                      //textAlign: "center",
                                      fontWeight: "200",
                                      fontSize: "12.819px",
                                      // fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    No.
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      //width: "5px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Short-term and long-term
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      //  width: "150px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Specific activites to be undertaken
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      // width: "100px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Time frame
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values &&
                                  values.map((item, index) => (
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          // width: "180px",
                                          textAlign: "center",
                                          fontWeight: "200",
                                          fontSize: "12.819px",
                                          // fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                        }}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          // width: "180px",
                                          textAlign: "left",
                                          fontWeight: "200",
                                          fontSize: "12.819px",
                                          // fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                        }}>
                                        {item?.goal}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          textAlign: "center",
                                          fontSize: "12.819px",
                                          //fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                          fontWeight: "200",
                                        }}>
                                        <AvField
                                          // disabled={!editable}
                                          name="activities"
                                          type="text"
                                          className="form-control"
                                          // placeholder="Name"
                                          //id="validationCustom05"
                                          id={`${item.name}`}
                                          onChange={(e) => handleTableInputchange(e, index, item?.name)}
                                          value={item.activities}
                                        />
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          textAlign: "center",
                                          fontSize: "12.819px",
                                          //fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                          fontWeight: "200",
                                          // display: "flex",
                                          // gap: "10px"
                                        }}>
                                        <AvField
                                          //disabled={!editable}
                                          name="time_frame"
                                          type="text"
                                          className="form-control"
                                          // placeholder="Name"
                                          id={`${item.name}`}
                                          onChange={(e) => handleTableInputchange(e, index, item?.name)}
                                          value={item.time_frame}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="1" className="mt-0">
                          <FormGroup className="float-right ">
                            {/* {grouptherapyIdTobeUpdated ? (
                                        <Button color="primary" type="submit">
                                            {"Update"}
                                        </Button>
                                    ) : ( */}
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                            {/* )} */}
                          </FormGroup>
                        </Col>
                      </Row>
                    </AvForm>
                  ) : (
                    <h4 style={{ fontStyle: "IBM Plex Sans, sans-serif" }}>You dont have access treatment plan</h4>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Treatmentplan;
