/* eslint-disable */
import React, { useState, useEffect } from 'react'
import {

    Col,


    FormGroup,

    Label,

    Row,

    TabPane,

} from "reactstrap";
import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";

import accessToken from '../../../helpers/jwt-token-access/accessToken';

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function Referel(props) {
    let {  details, editable } = props;

    const [MasterObj, setMasterObj] = useState({});

    useEffect(() => {
        setMasterObj(details)
    }, [details]); 

    


   

    const handleInput = (e, mthd) => {
        let {name,value,type,checked} = e.target;
        let newVal = type === "checkbox" ? checked : value
        if (mthd === 0) {
            setMasterObj(prev=>({
                ...prev,
                additional_treatment:{
                    ...prev?.additional_treatment,
                    [name]:newVal
                }
            }))
        }
    }

    function handlequestion(e) {
        let name = e.target.name;
        let value = e.target.value;
        setMasterObj({
            ...MasterObj, [name]: value
        })
    }


    function handleValidSubmit() {
        MasterObj.admission_id = props.id;
        axios
            .post(`${API_URL}admission/referral-details`, MasterObj, {
                headers: {
                    "x-access-token": accessToken
                }
            })
            .then((res) => {
                toastr.success(res.data.message)
                
            }).catch((err) => {
                toastr.error(err.response.data.message)
                return;
            })

    }



    return (
        <TabPane tabId={11}>

            <AvForm
                className="needs-validation"
                onValidSubmit={() => {
                    handleValidSubmit();
                }}>

                <div>
                    <h6>1. Client Required Additional Treatment ?</h6>

                    <p style={{ marginLeft: "2rem" }} >
                        <Label>TB</Label>
                        <input
                        disabled={!editable}
                            name='TB'
                            // disabled={editable}
                            className="form-check-input"
                            style={{ marginLeft: "10px" }}
                            type='checkbox'
                            checked={MasterObj?.additional_treatment?.TB}
                            onClick={(e) => handleInput(e, 0)} />


                        <Label style={{ marginLeft: "2rem" }}>HIV/AIDS</Label>
                        <input
                        disabled={!editable}
                            // disabled={editable}
                            style={{ marginLeft: "10px" }}
                            type='checkbox'
                            name='HIV'
                            className="form-check-input"
                            checked={MasterObj?.additional_treatment?.HIV}
                            onClick={(e) => handleInput(e, 0)} />

                        <Label style={{ marginLeft: "2rem" }}>STD/STIS</Label>
                        <input
                        disabled={!editable}
                            // disabled={editable}
                            style={{ marginLeft: "10px" }}
                            type='checkbox'
                            name="STD_STIS"
                            checked={MasterObj?.additional_treatment?.STD_STIS}
                            className="form-check-input"
                            onClick={(e) => handleInput(e, 0)} />

                        <Label style={{ marginLeft: "2rem" }}>Hepatitis B&C</Label>
                        <input
                        disabled={!editable}
                            // disabled={editable}
                            style={{ marginLeft: "10px" }}
                            type='checkbox'
                            name='HEPATITIS_BC'
                            checked={MasterObj?.additional_treatment?.HEPATITIS_BC}
                            className="form-check-input"
                            onClick={(e) => handleInput(e, 0)} />

                        <Label style={{ marginLeft: "2rem" }}>Abscess</Label>
                        <input
                        disabled={!editable}
                            // disabled={editable}
                            style={{ marginLeft: "10px" }}
                            type='checkbox'
                            name='ABSCESS'
                            checked={MasterObj?.additional_treatment?.ABSCESS}
                            onClick={(e) => handleInput(e, 0)} />
                    </p>
                </div>
                <div>
                    <Row>
                        <Col md="4">
                            <h6>2. Any Other Please Specify </h6>
                        </Col>
                        <Col md="4">
                            <AvField
                            disabled={!editable}
                                name="any_other"
                                type="text"
                                onChange={(e)=>handleInput(e,0)}
                                value={MasterObj?.additional_treatment?.any_other}
                            />
                        </Col>
                    </Row>
                </div>
                <div className='mt-4'>
                    <Row>
                        <Col md="4">
                            <h6>3. Date and Details Of Visits?</h6>
                        </Col>

                        <Col md="4">
                            <AvField
                            disabled={!editable}
                                name="visti_det"
                                value={MasterObj?.visti_det}
                                onChange={handlequestion}
                                type="text"
                            />
                        </Col>
                    </Row>

                </div>

                <div className='mt-4'>
                    <Row>
                        <Col md="4">
                            <h6>4. Action Taken ?</h6>
                        </Col>

                        <Col md="4">
                            <AvField
                            disabled={!editable}
                                name="action_taken"
                                type="text"
                                value={MasterObj?.action_taken}
                                onChange={handlequestion}


                            />
                        </Col>
                    </Row>

                </div>

                <div className='mt-4'>
                    <Row>
                        <Col md="4">
                            <h6>5. Referral To</h6>
                        </Col>

                        <Col md="4">
                            <AvField
                            disabled={!editable}
                                name="referral_to"
                                type="text"
                                value={MasterObj?.referral_to}
                                onChange={handlequestion}
                            />
                        </Col>
                    </Row>

                </div>

                <div className='mt-4'>
                    <Row>
                        <Col md="4">
                            <h6>6.Referred Name Of Organisation?</h6>
                        </Col>

                        <Col md="4">
                            <AvField
                            disabled={!editable}
                                name="organisation"
                                type="text"
                                value={MasterObj?.organisation}
                                onChange={handlequestion}
                            />
                        </Col>
                    </Row>

                </div>

                <Row className='mt-4'>
                    <Col md={12} className="submit-btn-fixed">
                        <FormGroup className="float-right ">
                            <a
                                className="save-continue-btn"
                                onClick={() => props.handleToggleBar("decrement")}
                            >
                                <i class="fas fa-solid fa-less-than"></i>
                            </a>
                            <button type="submit" className="save-continue-btn m-2">
                                Save and continue{" "}
                                <i
                                    style={{ fontSize: "13px" }}
                                    className="mdi mdi-arrow-right"
                                ></i>
                            </button>
                            <a
                                className="save-continue-btn"
                                onClick={() => props.handleToggleBar("increment")}
                            >
                                <i class="fas fa-solid fa-greater-than"></i>
                            </a>
                        </FormGroup>
                    </Col>
                </Row>


            </AvForm>


        </TabPane>
    )
}

export default Referel