/* eslint-disable */
import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>
              <p className="m-0">
                ©{new Date().getFullYear()} Pratheeksha Integrated
                Rehabilitation Center for Addicts (IRCA). All rights reserved.
              </p>
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                <p className="m-0" style={{ color: "#ffffff" }}>
                  <a
                    href="https://srvinfotech.com"
                    target="_blank"
                    style={{ color: "#74788d" }}
                  >
                    &nbsp;Powered by SRV InfoTech
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
