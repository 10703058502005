/* eslint-disable */
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Col, Label, Row, TabPane } from "reactstrap";
import "./style.scss";
import { getPreviousDetails, previousSubmit } from "./api";
import toastr from "toastr";

const PrviousHistory = (props) => {
  let { editable } = props;
  const [masterObject, setMasterObject] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getPreviousDetails(props.id).then((data) => {
      setMasterObject(data);
      setIsLoading(false);
    });
  }, [props]);

  const handleInput = (e, objName) => {
    let { name, type, value, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;
    if (objName === undefined) {
      setMasterObject((prevState) => ({
        ...prevState,
        admission_id: props.id,
        [name]: newValue,
      }));
    } else {
      setMasterObject((prevState) => ({
        ...prevState,
        admission_id: props.id,
        [objName]: {
          ...prevState[objName],
          [name]: newValue,
        },
      }));
    }
  };

  const handleSubmit = (data) => {
    previousSubmit(data).then((res) => {
      if (res.status === 200) {
        toastr.success("Medical form updated successfully");
      }
    });
  };
  return (
    <TabPane tabId={2}>
      {isLoading ? (
        <Row>
          <Col md={12} style={{ margin: "8rem 0" }}>
            <div class="simple-spinner">
              <span></span>
            </div>
          </Col>
        </Row>
      ) : (
        <AvForm
          className="needs-validation"
          method="POST"
          onValidSubmit={(e) => {
            e.preventDefault();
            handleSubmit(masterObject);
          }}>
          {/* <Card> */}
          {/* <CardBody> */}
          <Col md="12">
            <Row>
              <div className="mt-3 mb-2">
                <h6 style={{ fontWeight: "600" }}>1. Withdrawal symptoms experienced when the patient stopped</h6>
              </div>
            </Row>
            <Row className="ps-4">
              <Col md="6">
                <h6 className="text-center" style={{ fontWeight: "600" }}>
                  Alcohol
                </h6>
                <Row>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        checked={masterObject?.withdrawal_symptom_alcl?.tremors || false}
                        name="tremors"
                        className="form-check-input cursor-pointer"
                        id="tremors"
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_alcl")}
                      />
                      <Label className="form-check-label" htmlFor="tremors">
                        Tremors
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.withdrawal_symptom_alcl?.insomnia || false}
                        className="form-check-input cursor-pointer  "
                        name="insomnia"
                        id="insomnia"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_alcl")}
                      />
                      <Label className="form-check-label" htmlFor="insomnia">
                        Insomnia
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.withdrawal_symptom_alcl?.fits || false}
                        className="form-check-input cursor-pointer  "
                        name="fits"
                        id="fits"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_alcl")}
                      />
                      <Label className="form-check-label" htmlFor="fits">
                        Fits
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        checked={masterObject?.withdrawal_symptom_alcl?.nausea || false}
                        name="nausea"
                        id="nausea"
                        className="form-check-input cursor-pointer "
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_alcl")}
                      />
                      <Label className="form-check-label" htmlFor="nausea">
                        Nausea
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.withdrawal_symptom_alcl?.pains || false}
                        className="form-check-input cursor-pointer "
                        name="pains"
                        id="pains"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_alcl")}
                      />
                      <Label className="form-check-label" htmlFor="pains">
                        Achs/Pains
                      </Label>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <h6 className="text-center" style={{ fontWeight: "600" }}>
                  Drugs
                </h6>
                <Row>
                  <Col xs={6} md="6">
                    <p className="ms-1">
                      <input
                        disabled={!editable}
                        checked={masterObject?.withdrawal_symptom_drugs?.tremors || false}
                        name="tremors"
                        id="tremors1"
                        className="form-check-input cursor-pointer "
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_drugs")}
                      />
                      <Label className="form-check-label" htmlFor="tremors1">
                        Tremors
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p className="ms-1">
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.withdrawal_symptom_drugs?.insomnia || false}
                        className="form-check-input cursor-pointer "
                        name="insomnia"
                        id="insomnia1"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_drugs")}
                      />
                      <Label className="form-check-label" htmlFor="insomnia1">
                        Insomnia
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p className="ms-1">
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.withdrawal_symptom_drugs?.diarrhea || false}
                        className="form-check-input cursor-pointer "
                        name="diarrhea"
                        id="diarrhea"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_drugs")}
                      />
                      <Label className="form-check-label" htmlFor="diarrhea">
                        Diarrhea
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p className="ms-1">
                      <input
                        disabled={!editable}
                        checked={masterObject?.withdrawal_symptom_drugs?.pains || false}
                        name="pains"
                        id="pains1"
                        className="form-check-input cursor-pointer "
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_drugs")}
                      />
                      <Label className="form-check-label" htmlFor="pains1">
                        Severe Pain
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p className="ms-1">
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.withdrawal_symptom_drugs?.restlessness || false}
                        className="form-check-input cursor-pointer "
                        name="restlessness"
                        id="restlessness1"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "withdrawal_symptom_drugs")}
                      />
                      <Label className="form-check-label" htmlFor="restlessness1">
                        Restlessness
                      </Label>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* Other psychiatric complications */}
          <Col md="12">
            <Row>
              <Col md="6">
                <Row>
                  <div className="mt-3 mb-2">
                    <h6 style={{ fontWeight: "600" }}>2. Other psychiatric complications</h6>
                  </div>
                </Row>
                <Row className="ps-4">
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        name="depression"
                        id="depression"
                        checked={masterObject?.other_psych_cmpltn?.depression || false}
                        className="form-check-input cursor-pointer "
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_psych_cmpltn")}
                      />
                      <Label className="form-check-label" htmlFor="depression">
                        Depression
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.other_psych_cmpltn?.confusion || false}
                        className="form-check-input cursor-pointer "
                        name="confusion"
                        id="confusion"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_psych_cmpltn")}
                      />
                      <Label className="form-check-label" htmlFor="confusion">
                        Confusion
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.other_psych_cmpltn?.hallucinations || false}
                        className="form-check-input cursor-pointer "
                        name="hallucinations"
                        id="hallucinations"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_psych_cmpltn")}
                      />
                      <Label className="form-check-label" htmlFor="hallucinations">
                        Hallucinations
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        checked={masterObject?.other_psych_cmpltn?.aggressive_outbursts || false}
                        name="aggressive_outbursts"
                        id="aggressive_outbursts"
                        className="form-check-input cursor-pointer "
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_psych_cmpltn")}
                      />
                      <Label className="form-check-label" htmlFor="aggressive_outbursts">
                        Aggressive outbursts
                      </Label>
                    </p>
                  </Col>

                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.other_psych_cmpltn?.paranoid_ideas || false}
                        className="form-check-input cursor-pointer "
                        name="paranoid_ideas"
                        id="paranoid_ideas"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_psych_cmpltn")}
                      />
                      <Label className="form-check-label" htmlFor="paranoid_ideas">
                        Paranoid Ideas
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.other_psych_cmpltn?.suicidal_attempt || false}
                        className="form-check-input cursor-pointer "
                        name="suicidal_attempt"
                        id="suicidal_attempt"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_psych_cmpltn")}
                      />
                      <Label className="form-check-label" htmlFor="suicidal_attempt">
                        Suicidal ideation/attempts
                      </Label>
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col md="6">
                <Row>
                  <div className="mt-3 mb-2">
                    <h6 style={{ fontWeight: "600" }}>3. History of other medical problems in the past</h6>
                  </div>
                </Row>
                <Row className="ps-3">
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        checked={masterObject?.other_medical_prblm?.heamatemesis || false}
                        name="heamatemesis"
                        id="heamatemesis"
                        className="form-check-input cursor-pointer "
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_medical_prblm")}
                      />
                      <Label className="form-check-label" htmlFor="heamatemesis">
                        Haematemesis
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.other_medical_prblm?.jaundice || false}
                        className="form-check-input cursor-pointer "
                        name="jaundice"
                        id="jaundice"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_medical_prblm")}
                      />
                      <Label className="form-check-label" htmlFor="jaundice">
                        Jaundice
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.other_medical_prblm?.abscesses || false}
                        className="form-check-input cursor-pointer "
                        name="abscesses"
                        id="abscesses"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_medical_prblm")}
                      />
                      <Label className="form-check-label" htmlFor="abscesses">
                        Abscesses
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        checked={masterObject?.other_medical_prblm?.bleeding_piles || false}
                        name="bleeding_piles"
                        id="bleeding_piles"
                        className="form-check-input cursor-pointer "
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_medical_prblm")}
                      />
                      <Label className="form-check-label" htmlFor="bleeding_piles">
                        Bleeding piles
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.other_medical_prblm?.skin_problems || false}
                        className="form-check-input cursor-pointer "
                        name="skin_problems"
                        id="skin_problems"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_medical_prblm")}
                      />
                      <Label className="form-check-label" htmlFor="skin_problems">
                        Skin problems
                      </Label>
                    </p>
                  </Col>
                  <Col md="6">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.other_medical_prblm?.any_other || false}
                        className="form-check-input cursor-pointer "
                        name="any_other"
                        id="any_other1"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "other_medical_prblm")}
                      />
                      <Label className="form-check-label" htmlFor="any_other1">
                        Any other
                      </Label>
                      {masterObject?.other_medical_prblm?.any_other && (
                        <AvField
                          disabled={!editable}
                          name="any_others"
                          type="textarea"
                          rows={1}
                          className="form-control mt-2"
                          placeholder="Any other"
                          onChange={(e) => handleInput(e, "other_medical_prblm")}
                          validate={{ required: { value: true } }}
                          value={masterObject?.other_medical_prblm?.any_others}
                        />
                      )}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* Chronic health problems */}
          <Col md="12">
            <Row>
              <div className="mt-3 mb-2">
                <h6 style={{ fontWeight: "600" }}>4. Chronic health problems</h6>
              </div>
            </Row>
            <Row className="ps-4">
              <Col xs={6} md="4">
                <p>
                  <input
                    disabled={!editable}
                    checked={masterObject?.chronic_problems?.diabetes || false}
                    name="diabetes"
                    id="diabetes"
                    className="form-check-input cursor-pointer "
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInput(e, "chronic_problems")}
                  />
                  <Label className="form-check-label" htmlFor="diabetes">
                    Diabetics
                  </Label>
                </p>
              </Col>
              <Col xs={6} md="4">
                <p>
                  <input
                    disabled={!editable}
                    type="checkbox"
                    checked={masterObject?.chronic_problems?.liver_disorders || false}
                    className="form-check-input cursor-pointer "
                    name="liver_disorders"
                    id="liver_disorders"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInput(e, "chronic_problems")}
                  />
                  <Label className="form-check-label" htmlFor="liver_disorders">
                    Liver disorders
                  </Label>
                </p>
              </Col>
              <Col xs={6} md="4">
                <p>
                  <input
                    disabled={!editable}
                    type="checkbox"
                    checked={masterObject?.chronic_problems?.epilepsy || false}
                    className="form-check-input cursor-pointer "
                    name="epilepsy"
                    id="epilepsy"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInput(e, "chronic_problems")}
                  />
                  <Label className="form-check-label" htmlFor="epilepsy">
                    Epilepsy
                  </Label>
                </p>
              </Col>
              <Col xs={6} md="4">
                <p>
                  <input
                    disabled={!editable}
                    checked={masterObject?.chronic_problems?.infections || false}
                    name="infections"
                    id="infections"
                    className="form-check-input cursor-pointer "
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInput(e, "chronic_problems")}
                  />
                  <Label className="form-check-label" htmlFor="infections">
                    Infections
                  </Label>
                </p>
              </Col>
              <Col xs={6} md="4">
                <p>
                  <input
                    disabled={!editable}
                    type="checkbox"
                    checked={masterObject?.chronic_problems?.cardiac_problem || false}
                    className="form-check-input cursor-pointer "
                    name="cardiac_problem"
                    id="cardiac_problem"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInput(e, "chronic_problems")}
                  />
                  <Label className="d-inline" htmlFor="cardiac_problem">
                    Cardiac problems-HBP/IHD/RHD
                  </Label>
                </p>
              </Col>
              <Col xs={6} md="4">
                <p>
                  <input
                    disabled={!editable}
                    type="checkbox"
                    checked={masterObject?.chronic_problems?.other || false}
                    className="form-check-input cursor-pointer "
                    name="other"
                    id="other"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInput(e, "chronic_problems")}
                  />
                  <Label className="form-check-label" htmlFor="other">
                    Others
                  </Label>
                  {masterObject?.chronic_problems?.other && (
                    <AvField
                      disabled={!editable}
                      name="others"
                      type="textarea"
                      rows={1}
                      className="form-control mt-2"
                      placeholder="other"
                      onChange={(e) => handleInput(e, "chronic_problems")}
                      validate={{ required: { value: true } }}
                      value={masterObject?.chronic_problems?.others}
                    />
                  )}
                </p>
              </Col>
              <Col md="12">
                <p>
                  <input
                    disabled={!editable}
                    type="checkbox"
                    checked={masterObject?.chronic_problems?.respiratory_problem || false}
                    className="form-check-input cursor-pointer"
                    name="respiratory_problem"
                    id="respiratory_problem"
                    style={{ marginRight: "10px" }}
                    onClick={(e) => handleInput(e, "chronic_problems")}
                  />
                  <Label htmlFor="respiratory_problem" className="d-inline" style={{ wordBreak: "break-all" }}>
                    Respiratory problems-Pulmonary TB/Chronic Brochitis/Bronchial asthma
                  </Label>
                </p>
              </Col>
              <Col md="12">
                <Row className="align-items-baseline">
                  <Col md={4}>
                    <p>
                      <Label className="d-inline" style={{ wordBreak: "break-all" }}>
                        History of previous head injuries,if any
                      </Label>
                    </p>
                  </Col>
                  <Col md={8}>
                    <AvField
                      disabled={!editable}
                      name="prev_head_injuries"
                      type="textarea"
                      rows={1}
                      className="form-control"
                      onChange={(e) => handleInput(e)}
                      //validate={{ required: { value: true } }}
                      value={masterObject?.prev_head_injuries}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/*  Other Information */}
          <Col md="12">
            <Row>
              <Col md="12">
                <Row>
                  <div className="mt-3 mb-2">
                    <h6 style={{ fontWeight: "600" }}>5. Other Information</h6>
                  </div>
                </Row>
                <Row className="ps-4">
                  <Col xs={6} md={3}>
                    <h6 style={{ fontWeight: "450" }}>Use of Tobacco Products :</h6>
                  </Col>
                  <Col xs={6} md="3">
                    <p>
                      <input
                        disabled={!editable}
                        checked={masterObject?.use_of_tobacco_prdct?.smoking || false}
                        name="smoking"
                        id="smoking"
                        className="form-check-input cursor-pointer "
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "use_of_tobacco_prdct")}
                      />
                      <Label className="form-check-label" htmlFor="smoking">
                        smoking
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="3">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.use_of_tobacco_prdct?.pan_chewing || false}
                        className="form-check-input cursor-pointer "
                        name="pan_chewing"
                        id="pan_chewing"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "use_of_tobacco_prdct")}
                      />
                      <Label className="form-check-label" htmlFor="pan_chewing">
                        Pan Chewing
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="3">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.use_of_tobacco_prdct?.other || false}
                        className="form-check-input cursor-pointer "
                        name="other"
                        id="other2"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "use_of_tobacco_prdct")}
                      />
                      <Label className="form-check-label" htmlFor="other2">
                        Others
                      </Label>
                      {masterObject?.use_of_tobacco_prdct?.other && (
                        <AvField
                          disabled={!editable}
                          name="others"
                          type="textarea"
                          rows={1}
                          className="form-control mt-2"
                          placeholder="other"
                          onChange={(e) => handleInput(e, "use_of_tobacco_prdct")}
                          validate={{ required: { value: true } }}
                          value={masterObject?.use_of_tobacco_prdct?.others}
                        />
                      )}
                    </p>
                  </Col>
                </Row>
                <Row className="ps-4">
                  <Col xs={6} md={3}>
                    <h6 style={{ fontWeight: "450" }}>Family History of :</h6>
                  </Col>
                  {/* <div className='mt-2'> */}
                  <Col xs={6} md="3">
                    <p>
                      <input
                        disabled={!editable}
                        checked={masterObject?.family_history_of?.alcoholism || false}
                        name="alcoholism"
                        id="alcoholism"
                        className="form-check-input cursor-pointer "
                        type="checkbox"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "family_history_of")}
                      />
                      <Label className="form-check-label" htmlFor="alcoholism">
                        Alcoholism
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="3">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.family_history_of?.drug_abuse || false}
                        className="form-check-input cursor-pointer "
                        name="drug_abuse"
                        id="drug_abuse"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "family_history_of")}
                      />
                      <Label className="form-check-label" htmlFor="drug_abuse">
                        Drug Abuse
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md="3">
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.family_history_of?.psychiatric_illness || false}
                        className="form-check-input cursor-pointer "
                        name="psychiatric_illness"
                        id="psychiatric_illness"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "family_history_of")}
                      />
                      <Label className="form-check-label" htmlFor="psychiatric_illness">
                        Psychiatric illness
                      </Label>
                    </p>
                  </Col>
                </Row>
                <Row className="ps-4">
                  <Row className="align-items-baseline">
                    <Col md={4}>
                      <Label className="d-inline" style={{ wordBreak: "break-all" }}>
                        Knowledge of allergy to specific drugs :
                      </Label>
                    </Col>
                    <Col md={8}>
                      <AvField
                        disabled={!editable}
                        name="allergy_to_drugs"
                        type="textarea"
                        rows={1}
                        className="form-control"
                        //validate={{ required: { value: true } }}
                        onChange={(e) => handleInput(e, "family_history_of")}
                        value={masterObject?.family_history_of?.allergy_to_drugs}
                      />
                    </Col>
                  </Row>
                </Row>
              </Col>
              <Col md="12">
                <Row className="mb-3">
                  <div className="mt-3 mb-2">
                    <h6 style={{ fontWeight: "600" }}>6. Physical condition at the time of admission</h6>
                  </div>
                </Row>
                <Row className="mb-3 ps-4">
                  <Col md={6}>
                    <Row className="align-items-baseline mb-3">
                      <Col md={3}>
                        <Label htmlFor="name">Pulse rate :</Label>
                      </Col>
                      <Col md={6}>
                        <AvField
                          disabled={!editable}
                          name="pulse_rate"
                          type="text"
                          className="form-control"
                          placeholder="Enter pulse rate"
                          onChange={(e) => handleInput(e, "physical_cndn_at_admission")}
                          value={masterObject?.physical_cndn_at_admission?.pulse_rate}
                          // validate={{
                          //   required: { value: true },
                          // }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-baseline mb-3">
                      <Col md={3}>
                        <Label htmlFor="name">Blood pressure </Label>
                      </Col>
                      <Col md={6}>
                        <AvField
                          disabled={!editable}
                          name="bp"
                          type="text"
                          className="form-control"
                          placeholder="Enter blood pressure"
                          onChange={(e) => handleInput(e, "physical_cndn_at_admission")}
                          value={masterObject?.physical_cndn_at_admission?.bp}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-baseline mb-3">
                      <Col md={3}>
                        <Label htmlFor="name">Urine sugar </Label>
                      </Col>
                      <Col md={6}>
                        <AvField
                          disabled={!editable}
                          name="urine_sugar"
                          type="text"
                          className="form-control"
                          placeholder="Enter urine sugar"
                          onChange={(e) => handleInput(e, "physical_cndn_at_admission")}
                          value={masterObject?.physical_cndn_at_admission?.urine_sugar}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="align-items-baseline ">
                      <Col md={3}>
                        <Label htmlFor="name">Weight</Label>
                      </Col>
                      <Col md={6}>
                        <AvField
                          disabled={!editable}
                          name="weight"
                          type="text"
                          className="form-control"
                          placeholder="Enter weight"
                          onChange={(e) => handleInput(e, "physical_cndn_at_admission")}
                          value={masterObject?.physical_cndn_at_admission?.weight}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-3 ps-4">
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.tremors || false}
                        className="form-check-input cursor-pointer "
                        name="tremors"
                        id="tremors2"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="tremors2">
                        Tremors
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.jaudice || false}
                        className="form-check-input cursor-pointer "
                        name="jaudice"
                        id="jaudice"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="jaudice">
                        Jaudice
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.malnutrition || false}
                        className="form-check-input cursor-pointer "
                        name="malnutrition"
                        id="malnutrition"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="malnutrition">
                        Malnutrition
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.lymph_nodes || false}
                        className="form-check-input cursor-pointer "
                        name="lymph_nodes"
                        id="lymph_nodes"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="lymph_nodes">
                        Lymph nodes
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.loss_of_body_hair || false}
                        className="form-check-input cursor-pointer "
                        name="loss_of_body_hair"
                        id="loss_of_body_hair"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="loss_of_body_hair">
                        Loss of body hair
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.clubbing_of_nails || false}
                        className="form-check-input cursor-pointer "
                        name="clubbing_of_nails"
                        id="clubbing_of_nails"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="clubbing_of_nails">
                        Clubbing of nails
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.glossitis || false}
                        className="form-check-input cursor-pointer "
                        name="glossitis"
                        id="glossitis"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="glossitis">
                        Glossitis
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.wasting_of_muscles || false}
                        className="form-check-input cursor-pointer "
                        name="wasting_of_muscles"
                        id="wasting_of_muscles"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="wasting_of_muscles">
                        Wasting of muscles
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.spider_naevi || false}
                        className="form-check-input cursor-pointer "
                        name="spider_naevi"
                        id="spider_naevi"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="spider_naevi">
                        Spider naevi
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.abscess || false}
                        className="form-check-input cursor-pointer "
                        name="abscess"
                        id="abscess"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="abscess">
                        Abscess
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.anemia || false}
                        className="form-check-input cursor-pointer "
                        name="anemia"
                        id="anemia"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="anemia">
                        Anemia
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.palmar_erythema || false}
                        className="form-check-input cursor-pointer "
                        name="palmar_erythema"
                        id="palmar_erythema"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="palmar_erythema">
                        Palmar erythema
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.gynaecomastia || false}
                        className="form-check-input cursor-pointer "
                        name="gynaecomastia"
                        id="gynaecomastia"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="gynaecomastia">
                        Gynaecomastia
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.flushed_face_swetting || false}
                        className="form-check-input cursor-pointer "
                        name="flushed_face_swetting"
                        id="flushed_face_swetting"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label htmlFor="flushed_face_swetting" className="d-inline form-check-label">
                        flushed face/excessive sweating
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.pedal_edema || false}
                        className="form-check-input cursor-pointer "
                        name="pedal_edema"
                        id="pedal_edema"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="pedal_edema">
                        Pedal Edema
                      </Label>
                    </p>
                  </Col>
                  <Col xs={6} md={3}>
                    <p>
                      <input
                        disabled={!editable}
                        type="checkbox"
                        checked={masterObject?.physical_cndn_at_admission?.injection_marks || false}
                        className="form-check-input cursor-pointer "
                        name="injection_marks"
                        id="injection_marks"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleInput(e, "physical_cndn_at_admission")}
                      />
                      <Label className="form-check-label" htmlFor="injection_marks">
                        Injection marks
                      </Label>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {/* Record abnormalities, if any, on examination of the
          following */}
          <Col md="12">
            <Row>
              <div className=" mb-2">
                <h6 style={{ fontWeight: "600" }}>7. Record abnormalities, if any, on examination of the following</h6>
              </div>
            </Row>
            <Row className="ps-4">
              <Col md={6} className="mb-3">
                <Label className="me-3 d-inline form-check-label" htmlFor="respiratory_system">
                  Respiratory system :
                </Label>
                <p className="d-inline">
                  <Label className="mb-0 me-1 form-check-label" htmlFor="respiratory_systemY">
                    Yes
                  </Label>
                  <input
                    disabled={!editable}
                    onClick={(e) => handleInput(e, "abnormalities")}
                    type="radio"
                    className="form-check-input cursor-pointer  me-2"
                    name="respiratory_system"
                    id="respiratory_systemY"
                    value={1}
                    checked={masterObject?.abnormalities?.respiratory_system == 1 ? true : false}
                  />
                  <Label className="mb-0 me-1 form-check-label" htmlFor="respiratory_systemN">
                    No
                  </Label>
                  <input
                    disabled={!editable}
                    onClick={(e) => handleInput(e, "abnormalities")}
                    type="radio"
                    className="form-check-input cursor-pointer  "
                    name="respiratory_system"
                    id="respiratory_systemN"
                    value={0}
                    checked={masterObject?.abnormalities?.respiratory_system == 0 ? true : false}
                  />
                </p>
              </Col>
              <Col md={6} className="mb-3">
                <Label className="me-3 d-inline form-check-label">Cardio yascular system :</Label>
                <p className="d-inline">
                  <Label className="mb-0 me-1 form-check-label" htmlFor="cardio_vascular_systemY">
                    Yes
                  </Label>
                  <input
                    disabled={!editable}
                    onClick={(e) => handleInput(e, "abnormalities")}
                    type="radio"
                    className="form-check-input cursor-pointer  me-2"
                    name="cardio_vascular_system"
                    id="cardio_vascular_systemY"
                    value={1}
                    checked={masterObject?.abnormalities?.cardio_vascular_system == 1 ? true : false}
                    // checked={attendanceData?.att_type == 1 ? true : false}
                  />
                  <Label className="mb-0 me-1 form-check-label" htmlFor="cardio_vascular_systemN">
                    No
                  </Label>
                  <input
                    disabled={!editable}
                    onClick={(e) => handleInput(e, "abnormalities")}
                    type="radio"
                    className="form-check-input cursor-pointer  "
                    name="cardio_vascular_system"
                    id="cardio_vascular_systemN"
                    value={0}
                    checked={masterObject?.abnormalities?.cardio_vascular_system == 0 ? true : false}
                  />
                </p>
              </Col>
              <Col md={6} className="mb-3">
                <Label className="me-3 d-inline">Gastro intenstinal system :</Label>
                <p className="d-inline">
                  <Label className="mb-0 me-1 form-check-label" htmlFor="gastro_intestinal_systemY">
                    Yes
                  </Label>
                  <input
                    disabled={!editable}
                    onClick={(e) => handleInput(e, "abnormalities")}
                    type="radio"
                    className="form-check-input cursor-pointer  me-2"
                    name="gastro_intestinal_system"
                    id="gastro_intestinal_systemY"
                    value={1}
                    checked={masterObject?.abnormalities?.gastro_intestinal_system == 1 ? true : false}
                  />
                  <Label className="mb-0 me-1 form-check-label" htmlFor="gastro_intestinal_systemN">
                    No
                  </Label>
                  <input
                    disabled={!editable}
                    onClick={(e) => handleInput(e, "abnormalities")}
                    type="radio"
                    className="form-check-input cursor-pointer  "
                    name="gastro_intestinal_system"
                    id="gastro_intestinal_systemN"
                    value={0}
                    checked={masterObject?.abnormalities?.gastro_intestinal_system == 0 ? true : false}
                  />
                </p>
              </Col>
              <Col md={6} className="mb-3">
                <Label className="me-3 d-inline form-check-label">Nervous system :</Label>
                <p className="d-inline">
                  <Label className="mb-0 me-1 form-check-label" htmlFor="nervous_systemY">
                    Yes
                  </Label>
                  <input
                    disabled={!editable}
                    onClick={(e) => handleInput(e, "abnormalities")}
                    type="radio"
                    className="form-check-input cursor-pointer  me-2"
                    name="nervous_system"
                    id="nervous_systemY"
                    value={1}
                    checked={masterObject?.abnormalities?.nervous_system == 1 ? true : false}
                  />
                  <Label className="mb-0 me-1 form-check-label" htmlFor="nervous_systemN">
                    No
                  </Label>
                  <input
                    disabled={!editable}
                    onClick={(e) => handleInput(e, "abnormalities")}
                    type="radio"
                    className="form-check-input cursor-pointer  "
                    name="nervous_system"
                    id="nervous_systemN"
                    value={0}
                    checked={masterObject?.abnormalities?.nervous_system == 0 ? true : false}
                  />
                </p>
              </Col>
            </Row>
          </Col>

          <Col md="12">
            <Row>
              <div className="mt-2 mb-2">
                <h6 style={{ fontWeight: "600" }}>8. Impression of counsellor</h6>
              </div>
            </Row>
            <Row>
              <Col md={12}>
                <Label className="ps-4 me-3 d-inline">Denial :</Label>
                <p className="d-inline my-3 me-5">
                  <Label className="mb-0 me-1 form-check-label" htmlFor="counsellor_denial0">
                    Mild
                  </Label>
                  <input
                    disabled={!editable}
                    type="radio"
                    className="form-check-input cursor-pointer  me-2"
                    name="counsellor_denial"
                    id="counsellor_denial0"
                    value={0}
                    onClick={(e) => handleInput(e)}
                    checked={masterObject?.counsellor_denial == 0 ? true : false}
                  />
                </p>
                <p className="d-inline my-3 me-5">
                  <Label className=" mb-0 me-1 form-check-label" htmlFor="counsellor_denial1">
                    Moderate
                  </Label>
                  <input
                    disabled={!editable}
                    type="radio"
                    className="form-check-input cursor-pointer  me-2"
                    name="counsellor_denial"
                    id="counsellor_denial1"
                    value={1}
                    onClick={(e) => handleInput(e)}
                    checked={masterObject?.counsellor_denial == 1 ? true : false}
                  />
                </p>
                <p className="d-inline  my-3">
                  <Label className="mb-0  me-1 form-check-label" htmlFor="counsellor_denial2">
                    Severe
                  </Label>
                  <input
                    disabled={!editable}
                    type="radio"
                    className="form-check-input cursor-pointer  me-2"
                    name="counsellor_denial"
                    id="counsellor_denial2"
                    value={2}
                    onClick={(e) => handleInput(e)}
                    checked={masterObject?.counsellor_denial == 2 ? true : false}
                  />
                </p>
              </Col>
            </Row>
          </Col>
          {editable && (
            <div className="d-flex my-3 me-3 justify-content-end">
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          )}
          {/* </CardBody> */}
          {/* </Card> */}
        </AvForm>
      )}
    </TabPane>
  );
};

export default PrviousHistory;
