import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// import DashboardNew from "../pages/Dashboard New/index";
// import User from "../pages/User1/indexold";
// import Staff from "../pages/User1/Staff";
// import StaffDetails from "../pages/User1/Staff/Staff Detail/StaffDetails";
// import UserDashboard from "../pages/User1/UserDashboard/UserDashboard";

import User from "../pages/User/index";
import Staff from "../pages/User/Staff";
import StaffDetails from "../pages/User/Staff/Staff Detail/StaffDetails";

import Company from "../pages/MasterSettings/Company";
import Privilage from "../pages/MasterSettings/Privilage";
import Rules from "../pages/MasterSettings/Rules";
import Settings from "../pages/MasterSettings/Master_Setting";
import RulesApp from "../pages/MasterSettings/RulesApp";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiImageCropper from "../pages/Ui/ui-image-cropper";

// Forms
import BasicElements from "../pages/Forms/BasicElements";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";

//Icons

import IconUnicons from "../pages/Icons/IconUnicons";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";
// import VerifyOtp from "../pages/Authentication/otpPage";
import ResetPassword from "../pages/Authentication/resetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

//security
import BlockedIP from "../pages/Security/Blocked IP/BlockedIP";
import UserBlock from "../pages/Security/User Block/UserBlock";
import UserActivityLog from "../pages/Security/User Activity Log/UserActivityLog";

//manage

import Faq from "../pages/Manage/Faq/Faq";
import country from "../pages/Manage/country/country";
import Notification from "../pages/Manage/Notification/Notification";
import Faqcategory from "../pages/Manage/Faqcategory/Faqcategory";
import Enquiry from "../pages/Manage/Enquiry/enquiry";

//  FolloUps

import Assessment from "../pages/FollowUps/Assessment/index";
import Review from "../pages/FollowUps/Review/index";
//user
//import AddcargoUser from "../pages/User1/AddUsers/index";

//  |Enquiry
//  |---------------------*/
import Enquiries from "../pages/Enquiry/index";

/*---------------------
/*---------------------
|Account
|---------------------*/
import AccountType from "../pages/Accounts/Account-Type";
import AccountDetails from "../pages/Accounts/AccountDetails";
import Chart from "../pages/Accounts/Chart";
import FundTransfer from "../pages/Accounts/FundTransfer";
import IncomeExpenses from "../pages/Accounts/IncomeExpenses";
import TransactionLog from "../pages/Accounts/TransactionLog";
import ProfitAndLoss from "../pages/Accounts/ProfitAndLoss";
import DayBook from "../pages/Accounts/DayBook";
import CreditDebitFund from "../pages/Accounts/CreditDebit";

//? Admission
import AdmissionDashboard from "../pages/Admission/Dashboard";
import Admission from "../pages/Admission";
import Newadmission from "../pages/Admission/NewAdmission/index";
import patients from "../pages/ViewPatients";
import medication from "../pages/Medication";
import medicine from "../pages/Manage/Medicine";
import counsellingnotes from "../pages/CounsellingNotes";
import GroupTherapy from "../pages/GroupTherapy";

import Medicalform from "../pages/Medicalform/Medicalform";
import Medicalforms from "../pages/Medicalform/index";
//? Temperature and pressure
import TemperatureBp from "../pages/TemperatureBp";
//? Medicine chart
import MedicineChart from "../pages/MedicineChart";
//? Ai Meeting
import AiMeeting from "../pages/AiMeeting/AiMeeting";
// Treatment Plan
import Treatmentplan from "../pages/Treatmentplan";
// Interns Certificate
import InternCertificates from "../pages/Certificates/Interns/Interns";
import DischargeCertificates from "../pages/Certificates/Discharge/Discharge";
//bill generation
import Billgeneration from "../pages/Accounts/Bill_generation";

//Manage
import Medicaltest from "../pages/Manage/MedicalTest";
// <<=========== Test Result ============>>
import TestResult from "../pages/TestResult";
import TestPdf from "../pages/TestPdf";
/*---------------------
|Reports
|---------------------*/

import enquiry from "../pages/Manage/Enquiry/enquiry";

import Staffs from "../pages/Staffs";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/users", component: User },
  { path: "/staff", component: Staff },
  { path: "/user/:userId", component: StaffDetails },

  // { path: "/user", component: User },
  //{ path: "/staff", component: Staff },
  // { path: "/staff/:userId", component: StaffDetails },
  // { path: "/user/:userId", component: StaffDetails },
  // { path: "/profile/:userId", component: StaffDetails },

  //{ path: "/addUser/userId", component: StaffDetails },

  { path: "/company", component: Company },
  // { path: "/branch", component: Branch },
  { path: "/privilage", component: Privilage },
  { path: "/rules", component: Rules },
  { path: "/settings", component: Settings },
  { path: "/rules_app", component: RulesApp },

  //enquiry
  { path: "/manage-enquiry", component: Enquiries },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-image-cropper", component: UiImageCropper },

  // Forms
  { path: "/basic-elements", component: BasicElements },
  { path: "/form-layouts", component: FormLayouts },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },

  // Icons
  { path: "/icons-unicons", component: IconUnicons },
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // //profile
  { path: "/profile", component: UserProfile },

  // //manage

  { path: "/Faq", component: Faq },
  { path: "/country", component: country },
  { path: "/notification", component: Notification },
  { path: "/Faqcategory", component: Faqcategory },
  { path: "/enquiry", component: enquiry },

  { path: "/blockedip", component: BlockedIP },
  { path: "/userblock", component: UserBlock },
  { path: "/UserActivityLog", component: UserActivityLog },

  { path: "/view-patients", component: patients },
  { path: "/medication", component: medication },
  { path: "/medicine", component: medicine },
  { path: "/counsellingnotes", component: counsellingnotes },
  { path: "/grouptherapy", component: GroupTherapy },
  //users
  // { path: "/users", component: AddcargoUser },
  //  { path: "/addUser/userId", component: UserDashboard },

  //all booking
  // {path:"/all-booking",component:Allbooking},

  /*---------------------
  |Account
  |---------------------*/
  { path: "/account-head", component: AccountType },
  { path: "/account-category", component: AccountDetails },
  { path: "/accounts-chart", component: Chart },
  { path: "/fund-transfer", component: FundTransfer },
  { path: "/income-expense", component: IncomeExpenses },
  { path: "/transaction-log", component: TransactionLog },
  { path: "/transaction-log/:chartId", component: TransactionLog },
  { path: "/profitAndLoss", component: ProfitAndLoss },
  { path: "/day-book", component: DayBook },
  { path: "/credit-debit-fund", component: CreditDebitFund },

  //bookingenquiry
  { path: "/booking/enquiry", component: Enquiry },

  //  FollowUps
  { path: "/followup-assessment", component: Assessment },
  { path: "/followup-review", component: Review },

  //? Admission
  { path: "/admission-dashboard", component: AdmissionDashboard },
  { path: "/admission/:userId", component: Admission },
  { path: "/new-admission", component: Newadmission },
  { path: "/medical-form", component: Medicalform },
  { path: "/medical-forms", component: Medicalforms },

  //? Tempreture and BP
  { path: "/temperature-bp", component: TemperatureBp },
  //? Medicine Chart
  { path: "/medicine-chart", component: MedicineChart },
  //? Ai Meeting
  { path: "/ai-meeting", component: AiMeeting },

  //treatment plan
  { path: "/treatment_plan", component: Treatmentplan },
  { path: "/intern-certificates", component: InternCertificates },
  { path: "/discharge-certificates", component: DischargeCertificates },
  { path: "/staffs", component: Staffs },

  //Bill generation
  { path: "/bill_generation", component: Billgeneration },

  //Manage
  { path: "/manage_test", component: Medicaltest },

  // <<=========== Test Result ============>>
  { path: "/test-result", component: TestResult },
  { path: "/test-pdf", component: TestPdf },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/*", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  // { path: "/verify-otp", component: VerifyOtp },
  { path: "/reset-password", component: ResetPassword },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  // { path: "/qr-code/:qrcodeId", component: ViewQrCode },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
];

export { userRoutes, authRoutes };
