import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

export const previousSubmit = (data) =>
  axios
    .post(`${API_URL}medical-form/prev-history`, data, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data)
    .catch((err) => err.response);

export const getPreviousDetails = (id) =>
  axios
    .get(`${API_URL}medical-form?admission_id=${id || ""}`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data.data)
    .catch((err) => err.response);
