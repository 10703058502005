import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Row, Col } from "reactstrap";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { useHistory } from "react-router-dom";
// Import components
import MegaMenu from "../MegaMenu/MegaMenu";

import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-new.png";
import "./style.css";

// import bookingIcon from "../../assets/images/common-menu/addorder.svg";
// import viewbookingIcon from "../../assets/images/common-menu/vieworder.svg";
// import daybookIcon from "../../assets/images/common-menu/daybook.svg";
// import coinIcon from "../../assets/images/common-menu/coins.svg";

// import Select from "react-select";
//i18n
import { withTranslation } from "react-i18next";
// import qrcodescanIcon from "../../assets/images/qrcode-scan.PNG";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType, getCompaniesMasterOptions, } from "../../store/actions";
// import { getLocalbody, getRouteLength, getRouteName } from "../../helpers/globalFunctions";
import toastr from "toastr";
// import { selectClasses } from "@mui/material";

const Header = (props) => {
  // const [userId, setUserId] = useState(null);
  const [search, setsearch] = useState(false);
  const [showMegaMenu] = useState(false);
  // const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [counter, setCounter] = useState(0);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const dispatch = useDispatch();
  // const { companiesMasterOptions } = useSelector((state) => state.companies);
  const { userStatus, errorStatus } = useSelector((state) => state.users);
  const [filteredData, setFilteredData] = useState([]);
  const [keyWord, setKeyWord] = useState("");
  // const [routeName, setRouteName] = useState(getRouteName(props));
  // const [routeLength, setRouteLength] = useState(getRouteLength(props));
  const [selectedId, setSelectedId] = useState(-1);
  const selectedRef = useRef(null);

  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      // setUserId(user_obj._id);
      //  fetchMasterCompanies(user_obj._id);
      dispatch(getCompaniesMasterOptions(user_obj._id));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      // var data = localStorage.getItem("authUser");
      // var user_obj = JSON.parse(data);
      // setUserId(user_obj._id);
      // dispatch(getUsersStatus(user_obj._id));
    }
    const s = setInterval(() => {
      setCounter((c) => c + 1);
    }, 60000);
    return () => clearInterval(s);
  }, [counter]);
  useEffect(() => {
    if (userStatus?.success === true) {
      let data = userStatus?.data;
      if (data && data.status === 2) {
        toastr.error("Your account is blocked. Please contact your administrator");
        localStorage.removeItem("authUser");
        setTimeout(() => {
          history.push("/login");
        }, 1000);
      }
    } else if (userStatus?.success === false) {
      toastr.error("Token has been expired,please login again");
      localStorage.removeItem("authUser");
      setTimeout(() => {
        history.push("/login");
      }, 1000);
    }
    // eslint-disable-next-line
  }, [userStatus]);

  useEffect(() => {
    if (errorStatus?.response) {
      let error = errorStatus.response.data;
      if (error?.status === 400) {
        toastr.error(error?.message, "please login again");
        localStorage.removeItem("authUser");
        setTimeout(() => {
          window.location.assign("/login");
          // history.push("/login");
        }, 1000);
      }
    }
  }, [errorStatus]);

  function toggleFullscreen() {
    if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    if (!isMobile) {
      var body = document.body;
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }

    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }

  // function toggleMegaMenu() {
  //   setShowMegaMenu(!showMegaMenu);
  // }

  useEffect(() => {
    handleSearchChange(keyWord);
    // eslint-disable-next-line
  }, [keyWord]);

  const handleSearchChange = (search) => {
    axios
      .get(`${API_URL}search?search=${search}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let details = [];
        res.data.data.forEach((data, i) => {
          details.push({
            name: data.basic_details.fullname + ", " + data.basic_details.mobile + ", " + data.basic_details.district,
            route: "/admission/" + data.reg_no || "",
            id: data._id,
          });
        });
        setSelectedId(-1);
        setFilteredData(details);
      })
      .catch((err) => console.log(err));
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" && selectedId > 0) {
      e.preventDefault();
      setSelectedId(selectedId - 1);
    } else if (e.key === "ArrowDown" && selectedId < filteredData.length - 1) {
      e.preventDefault();
      setSelectedId(selectedId + 1);
    } else if (e.key === "Enter" && selectedId >= 0) {
      e.preventDefault();
      setKeyWord("");
      history.push(filteredData[selectedId]?.route, {
        id: filteredData[selectedId]?.id,
      });
    }
  };

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [selectedRef, selectedId]);

  return (
    <React.Fragment>
      {showMegaMenu ? <MegaMenu /> : null}
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="20" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="20" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
            </button>

            <div className="app-search d-none d-lg-block" style={{ width: "643px" }}>
              <Row style={{ width: "385px" }}>
                <Col md="9" style={{ padding: "0px" }}>
                  <div className="position-relative" style={{ paddingLeft: "8px" }}>
                    <input
                      type="text"
                      className="form-control shadow-sm"
                      placeholder={props.t("eg:Name, Mobile, Reg No")}
                      onKeyDown={(e) => {
                        handleKeyDown(e);
                        if (e.key === "Backspace" && keyWord.length === 0) {
                          setFilteredData([]);
                        }
                      }}
                      value={keyWord}
                      onChange={(e) => setKeyWord(e.target.value)}
                    />
                    <i
                      className="fas fa-search"
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "1.5rem",
                        fontSize: "17px",
                        opacity: "0.7",
                      }}></i>
                  </div>
                </Col>
              </Row>

              {filteredData.length !== 0 && (
                <div className="shadow overflowScroll">
                  {filteredData.map((value, key) => {
                    return (
                      // eslint-disable-next-line
                      <a
                        className="search_hover"
                        onClick={() => {
                          history.push(value.route, {
                            id: value.id,
                          });
                          setKeyWord("");
                        }}
                        ref={selectedId === key ? selectedRef : null}>
                        <p
                          className="drop_hover"
                          style={{
                            padding: "5px 15px",
                            borderRadius: "0.7rem",
                            backgroundColor: key === selectedId ? "rgba(0, 113, 146, 0.2)" : "",
                          }}>
                          {value?.name}
                        </p>
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex">
            <Dropdown
              className="d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search);
              }}
              type="button">
              <DropdownToggle className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" tag="button">
                {" "}
                <i className="uil-search" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>

            <Dropdown className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen">
                <i style={{ color: "grey" }} className="uil-minus-path"></i>
              </button>
            </Dropdown>
            <NotificationDropdown />

            <ProfileMenu />

            <Link to="/logout">
              <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                <i
                  class="fas fa-power-off"
                  style={{
                    fontSize: "1.2em",
                    color: "grey",
                  }}></i>
              </button>
            </Link>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
