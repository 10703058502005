/* eslint-disable */
import React, { useState, useEffect } from "react";

import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Label } from "reactstrap";
import {  Box, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Stack from "@mui/material/Stack";

import { apiError } from "../../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

import "./StaffDetails.scss";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import axios from "axios";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import {  getRouteName } from "../../../../helpers/globalFunctions";



const StaffDetails = (props) => {
  const { userId } = props.match.params;
  const [details, setDetails] = useState({});
  const [userLogData, setUserLogData] = useState([]);
  const [routeName, setRouteName] = useState(getRouteName(props));

  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });

  const [agentNumber, setAgentNumber] = useState("");
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    if (userId) {
      handleTableData(userId);
      fetch_user_details();
    }
  }, []);
  const fetch_user_details = () => {
    let userid = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
    }
    let Userdata = {
      user_id: userid,
    };
    axios
      .post(`${API_URL}api/staff/user_profile`, Userdata, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.result;
        //console.log("result: ", result);
        if (result && result.length > 0) {
          setAgentNumber(result[0].mobile);
        }
      });
  };
  function handleTableData(id) {
    axios
      .get(`${API_URL}user/single?user_reg=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        // result &&
        //   result.forEach((element) => {

        if (result?.lastName) result.User_Name = result.firstName + " " + result.lastName;
        else result.User_Name = result.firstName;
        let user_privilege = result.privilage || {};
        result.privilege_name = user_privilege?.privilege_name;
        let userBranch = result.branch || {};
        result.branchName = userBranch?.name;
        result.userAddedDate = moment(result.date).format("DD-MM-YYYY");
        if (result?.user_joining_date) {
          result.joiningDate = moment(result?.user_joining_date).format("DD-MM-YYYY");
        }
        if (result?.user_resigning_date) {
          result.resigningDate = moment(result?.user_resigning_date).format("DD-MM-YYYY");
        }
        if (result?.user_dob) result.Dob = moment(result?.user_dob).format("DD-MM-YYYY");
        setDetails(result);
        fetch_user_log(result?._id);
        // });
      });
  }
  const fetch_user_log = (id) => {
    let userid = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
    }
    axios
      .get(`${API_URL}security/userlog?user_id=` + userid + "&from_date=" + "" + "&to_date=" + "" + "&staff_id=" + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
            // item.action = (
            //   <div style={{ display: "flex", justifyContent: "center" }}>
            //     <i
            //       className="fas fa-eye"
            //       style={{
            //         fontSize: "1em",
            //         cursor: "pointer",
            //         marginLeft: "0.1em",
            //         marginRight: "0.1em",
            //       }}
            //       onClick={() => {
            //         handleClickOpenForm(item);
            //       }}
            //     ></i>
            //   </div>
            // );
          });
        setUserLogData(result);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Created IP",
        field: "activity_ip",
        sort: "asc",
        width: 200,
      },
      {
        label: "Activity",
        field: "activity_desc",
        sort: "asc",
        width: 200,
      },
    ],
    rows: userLogData,
  };

  function createData1(si, date, receiptno, amount, dueamount, staff) {
    return { si, date, receiptno, amount, dueamount, staff };
  }

  const rows2 = [
    createData1(1, "24 - 11 - 2021", "RE2111000004", "50/-", "50/-", "Prabija"),
    createData1(2, "24 - 11 - 2021", "RE2111000005", "400/-", "-350/-", "Prabija"),
  ];

  function createData3(si, date, time, referenceNo, customerId, customerName, amount, comment, due, staff) {
    return {
      si,
      date,
      time,
      referenceNo,
      customerId,
      customerName,
      amount,
      comment,
      due,
      staff,
    };
  }

  const rows3 = [createData3(1, "26-11-2020", "11:11:24", 123, 123, "admin", 700, "abc", 100, "srv")];

  const [tab3, setTab3] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);
  };

  const [date, setDate] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  function createData4(si, date, time, customerName, type, invoiceNo, debit, credit, balance) {
    return {
      si,
      date,
      time,
      customerName,
      type,
      invoiceNo,
      debit,
      credit,
      balance,
    };
  }

  const rows4 = [createData4(1, "26-11-2020", "11:11:24", "admin", "Invoice", "SRV123", 700, 100, 100)];
  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
    setPasswordObject({
      password: "",
      confirmPassword: "",
    });
  };
  const handleValidSubmitPassword = () => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        user_id: userIdTobeUpdated,
        password: passwordObject?.password,
      };
      axios
        .post(`${API_URL}user/reset_password`, item, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            setPasswordObject({
              password: "",
              confirmPassword: "",
            });
            setUserIdToBeUpdated(null);
            closeModal();
          } else {
            toastr.error(res.data.message);
            return;
          }
        });
    } else {
      toastr.error("Passwords are not matching");
    }
  };
  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  // const userCall = () => {
  //   let data = {
  //     customer_no: 0 + details?.mobile,
  //     agent_no: 0 + agentNumber,
  //     localbody: currentLocalbody,
  //   };
  //   axios
  //     .post(`${API_URL}api/staff/customer_call`, data, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((response) => {
  //       let result = response.data;
  //       //console.log("result: ", result);
  //       if (result && result.success === true) {
  //         toastr.success("Call Send successfully");
  //       } else {
  //         toastr.error(result.message);
  //       }
  //     });
  // };
  return (
    <React.Fragment>
      <Modal
        show={showModal}
        toggle={() => {
          closeModal();
        }}
        centered={true}
        size="md">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleValidSubmitPassword(e, v);
          }}>
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type="password"
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.password}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Confirm Password</Label>
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage=" Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.confirmPassword}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="btn btn-dark" style={{ marginRight: "1rem" }} onClick={closeModal}>
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem={
              routeName === "user" || routeName === "profile" ? "User" + " " + "-" + " " + details?.userId : "Staff" + " " + "-" + " " + details?.userId
            }
          />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}>
                      <div className="card-body">
                        <div className="float-end dropdown">
                          <a aria-haspopup="true" className="text-body font-size-16 ddropdown-toggle" aria-expanded="false">
                            {/* <OverlayTrigger
                              placement="top"
                              //delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip
                                  id="button-tooltip-2"
                                  style={{ position: "absolute", top: "-30px" }}
                                >
                                  Reset password
                                </Tooltip>
                              }
                            > */}

                            {/* </OverlayTrigger> */}
                          </a>
                          <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu-end dropdown-menu">
                            <a to="/" tabindex="0" role="menuitem" className="dropdown-item">
                              Edit
                            </a>
                            <a to="/" tabindex="1" role="menuitem" className="dropdown-item">
                              Action
                            </a>
                            <a to="/" tabindex="2" role="menuitem" className="dropdown-item">
                              Remove
                            </a>
                          </div>
                          {/* <div className="clearfix"></div> */}
                          {/* <div>
                                <img
                                  alt
                                  className="avatar-lg rounded-circle img-thumbnail"
                                  src="/static/media/avatar-4.b23e41d9.jpg"
                                />
                              </div> */}
                        </div>
                        {/* Customer Details Tab start */}

                        {details?.user_image ? (
                          <Stack direction="row" spacing={2} style={{ justifyContent: "center" }}>
                            <Avatar
                              alt="Remy Sharp"
                              src={`${API_URL}uploads/user_images/${details?.user_image}`}
                              sx={{ width: 56, height: 56 }}
                              style={{
                                width: "6rem",
                                height: "6rem",
                                marginBottom: "auto",
                              }}
                            />
                          </Stack>
                        ) : (
                          <Stack direction="row" spacing={2} style={{ justifyContent: "center" }}>
                            <Avatar
                              alt="Remy Sharp"
                              src={defaultProfile}
                              sx={{ width: 56, height: 56 }}
                              style={{
                                width: "6rem",
                                height: "6rem",
                                marginBottom: "auto",
                              }}
                            />
                          </Stack>
                        )}
                        <i
                          className="fas fa-key"
                          style={{
                            fontSize: "1em",
                            position: "relative",
                            float: "right",
                            bottom: "83px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowModal(true);
                            setUserIdToBeUpdated(userId);
                          }}></i>
                        <h5
                          className="mt-3 mb-1"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}>
                          {details?.User_Name}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}>
                          <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                            {/* <CallIcon style={{ width: "10px", height: "10px" }}/> */}
                            {/* <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  id="button-tooltip-2"
                                  style={{ position: "absolute", top: "-30px" }}
                                >
                                  Call Now
                                </Tooltip>
                              }
                            > */}
                            <button
                              class="btn btn-success"
                              style={{
                                borderRadius: "40px",
                                marginRight: "10px",
                                padding: "0",
                                width: "28px",
                                cursor: "default",
                              }}
                              hidden={!details.mobile}
                              // onClick={() => userCall()}
                            >
                              <i className="uil-phone-alt" style={{ fontSize: "15px" }} />
                            </button>
                            {/* </OverlayTrigger> */}
                          </div>
                          {details?.mobile}
                        </div>
                        {/* <p
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          <i
                            className="uil-phone-alt"
                            style={{ fontSize: "15px" }}
                            hidden={!details?.mobile}
                          />
                          {details?.mobile}
                        </p> */}
                        <p
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "15px",
                            marginBottom: "0",
                          }}>
                          {details?.privilege_name}
                        </p>

                        <div
                          className="mt-1 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}>
                          {/* <button
                            type="button"
                            className="btn btn-light btn-sm"
                          >
                            <i className="uil uil-envelope-alt me-2"></i>
                            Message
                          </button> */}
                        </div>
                        <Divider />

                        {/* <div style={{ fontFamily: "IBM Plex Sans,sans-serif" }}>
                          <p className="mb-1">Name:</p>
                          <h5 className="font-size-16">{details.firstName}</h5>
                        </div>
                        <div className="mt-4">
                          <p className="mb-1">Mobile:</p>
                          <h5 className="font-size-16">{details.mobile}</h5>
                        </div> */}
                        <div className="mt-2" hidden={!details?.Dob}>
                          <p className="mb-1">DOB:</p>
                          <h5 className="font-size-14">{details?.Dob}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Email:</p>

                          <h5 className="font-size-14">
                            <MailOutlineIcon style={{ fontSize: "15px" }} hidden={!details?.email} />
                            &nbsp;
                            {details?.email}
                          </h5>
                        </div>
                        {/* <div className="mt-2" hidden={!details?.salary}>
                          <p className="mb-1">Salary:</p>
                          <h5 className="font-size-14">{details?.salary}</h5>
                        </div> */}
                        <div className="mt-2" hidden={!details?.joiningDate}>
                          <p className="mb-1">Joining Date:</p>
                          <h5 className="font-size-14">{details?.joiningDate}</h5>
                        </div>
                        <div className="mt-2" hidden={!details?.resigningDate}>
                          <p className="mb-1">Resigning Date:</p>
                          <h5 className="font-size-14">{details?.resigningDate}</h5>
                        </div>

                        <div className="mt-2" hidden={!details?.branchName}>
                          <p className="mb-1">Branch:</p>
                          <h5 className="font-size-14">{details?.branchName}</h5>
                        </div>
                        <div className="mt-2" hidden={!details?.address}>
                          <p className="mb-1">Address:</p>
                          <h5 className="font-size-14">{details?.address}</h5>
                        </div>

                        {/* <div className="mt-2">
                          <p className="mb-1">Ward and Group:</p>
                          <h5
                            className="font-size-14"
                            hidden={!details?.ward_name}
                          >
                            {details?.ward_name}
                            {","}
                            <br />
                            <h5
                              className="font-size-14"
                              hidden={!details?.group_name}
                            >
                              {details?.group_name}
                            </h5>
                          </h5>
                        </div> */}

                        <div className="mt-2">
                          <p className="mb-1">User Type:</p>
                          {details?.app_user === 1 ? <h5 className="font-size-14">{"App"}</h5> : <h5 className="font-size-14">{"CRM"}</h5>}
                        </div>
                        <div className="mt-2" hidden={details?.app_user !== 1}>
                          <p className="mb-1">Language:</p>
                          <h5 className="font-size-14">{details?.app_language === 1 ? "English" : "Malayalam"}</h5>
                        </div>
                        <div className="mt-2" hidden={details?.app_user !== 1 || !details?.app_version}>
                          <p className="mb-1">Version:</p>
                          <h5 className="font-size-14">{details?.app_version}</h5>
                        </div>
                        <div hidden={!details?.user_documents}>
                          <p className="mb-1">Documents:</p>
                          <img style={{ height: "162px" }} src={`${API_URL}uploads/user_images/${details?.user_documents}`} />
                        </div>
                        {/* second paper */}

                        {/* Customer Details Tab end */}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}>
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example">
                              <Tab
                                icon={<LocationCityIcon style={{ fontSize: "20px" }} />}
                                label="Activity log"
                                value="1"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                // icon={
                                //   <LocationCityIcon
                                //     style={{ fontSize: "20px" }}
                                //   />
                                // }
                                label=""
                                value=""
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                                disabled={true}
                              />
                              <Tab
                                // icon={
                                //   <LocationCityIcon
                                //     style={{ fontSize: "20px" }}
                                //   />
                                // }
                                label=""
                                value=""
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                                disabled={true}
                              />
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable id="userActivitylogDataId" responsive bordered data={data} entries={20} />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(StaffDetails));

// StaffDetails.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
