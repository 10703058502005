/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import $ from "jquery";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { apiError } from "../../../store/actions";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getLocalbody,
  getFirstday,
  getDate,
} from "../../../helpers/globalFunctions";
import "./style.scss";
const TransactionLog = (props) => {
  const { chartId } = props.match.params;
  const [transationLogList, setTransactionLogList] = useState([]);
  const [selectedTransationType, setSelectedTransationType] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [master, setMaster] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [chartOfAccOption, setChartOfAccOption] = useState([]);
  const [selectedChart, setSelectedChart] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();
  const handleSelectedTransactionType = (selected) => {
    // let date1 = master?.from_date ? master.from_date : "";
    // let date2 = master?.to_date ? master.to_date : "";
    // let type = selected.value;
    // let chart_id = "";
    // if (selectedChart !== null) {
    //   chart_id = selectedChart.value;
    // }
    setSelectedTransationType(selected);
    // handleTableData(type, date1, date2, chart_id);
  };
  useEffect(() => {
    if (chartId) {
      fetch_all_accounts(currentLocalbody);
    } else {
      fetch_all_accounts(currentLocalbody);
      handleTableData();
    }
  }, [currentLocalbody]);

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    //date validation
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
      });
    }

    //
    // setMaster({
    //   ...master,
    //   [e.target.name]: e.target.value,
    // });
    // let type = "";
    // if (selectedTransationType !== null) {
    //   type = selectedTransationType.value;
    // }
    // let chart_id = "";
    // if (selectedChart !== null) {
    //   chart_id = selectedChart.value;
    // }
    // handleTableData(type, date1, date2, chart_id);
  };
  const reset = () => {
    setMaster({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedTransationType(null);
    setSelectedChart(null);
    handleTableData();
    if (chartId) {
      history.push("/transaction-log");
    }
  };
  function handleTableData(
    type = "",
    from_date = "",
    to_date = "",
    acc_chart_id = ""
  ) {
    var url = `${API_URL}transaction_log?localbody_id=` + currentLocalbody;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&type=" +
      type +
      "&acc_chart_id=" +
      acc_chart_id +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date;
    // if (
    //   type != null &&
    //   from_date != null &&
    //   to_date != null &&
    //   acc_chart_id != null
    // ) {
    //   url =
    //     url +
    //     "&type=" +
    //     type +
    //     "&from_date=" +
    //     from_date +
    //     "&to_date=" +
    //     to_date +
    //     "&acc_chart_id=" +
    //     acc_chart_id;
    // } else if (type != null) {
    //   url = url + "&type=" + type;
    // } else if (from_date != null) {
    //   url = url + "&from_date=" + from_date;
    // } else if (to_date != null) {
    //   url = url + "&to_date=" + to_date;
    // } else if (acc_chart_id != null) {
    //   url = url + "&acc_chart_id=" + acc_chart_id;
    // }
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let Data = [];
        result.map((item, index) => {
          if (item.transaction_log_type == 0) {
            item.n_Credit = parseFloat(item.transaction_log_amount);
          } else item.n_Debit = parseFloat(item.transaction_log_amount);

          item.amount_column = parseFloat(
            item.transaction_log_fundsource_crntamt
          ).toFixed(2);
          item.id = index + 1;
          item.trns_log_date = moment(item.trns_log_date).format("DD-MM-YYYY");
          item.time = moment(item.trns_log_time, "HHmmss").format("hh:mm a");
          if (item?.lname) item.user = item?.fname + " " + item.lname;
          else item.user = item?.fname;
          Data.push(item);
        });
        setTransactionLogList(Data);
      });
  }
  const fetch_all_accounts = (id) => {
    axios
      .get(`${API_URL}transaction_log/accounts?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let account_data =
          res.data.data &&
          res.data.data.map((element) => {
            return {
              label: element.acc_chart_name,
              value: element._id,
            };
          });
        if (chartId) {
          // let type = "";
          // let date1 = "";
          // let date2 = "";
          account_data &&
            account_data.map((el) => {
              if (el.value === chartId) {
                setSelectedChart(el);
                // handleTableData(type, date1, date2, chartId);
              }
            });
        }
        setChartOfAccOption([
          {
            options: account_data,
          },
        ]);
      });
  };
  const handleSelectChange = (selected) => {
    // let date1 = master?.from_date ? master.from_date : "";
    // let date2 = master?.to_date ? master.to_date : "";
    // let type = "";
    // if (selectedTransationType !== null) {
    //   type = selectedTransationType.value;
    // }
    setSelectedChart(selected);
    // handleTableData(type, date1, date2, selected.value);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "trns_log_date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "acc_type_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Account",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Ref. ID",
      //   field: "log_unique_id",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Debit",
        field: "n_Debit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Credit",
        field: "n_Credit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "amount_column",
        sort: "asc",
        width: 200,
      },
      {
        label: "Remark",
        field: "transaction_log_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "user",
        sort: "asc",
        width: 200,
      },
    ],
    rows: transationLogList,
    // rows:[{
    //   id:1,
    //   trns_log_date:"	31/01/2022",
    //   trns_log_time:"03:45 PM",
    //   acc_type_name:"Bank",
    //   acc_chart_name:"wallet",
    //   log_unique_id:"T2201WAL12314",
    //   n_Debit:"20",
    //   n_Credit:"",
    //   amount_column:"11",
    //   fname:"admin"
    // },
    // {
    //   id:2,
    //   trns_log_date:"	31/01/2022",
    //   trns_log_time:"04:45 PM",
    //   acc_type_name:"Bank",
    //   acc_chart_name:"Razorpay",
    //   log_unique_id:"T2201WAK00018",
    //   n_Debit:"50",
    //   n_Credit:"",
    //   amount_column:"21",
    //   fname:"admin"
    // }]
  };
  const handleSearch = () => {
    let date1 = master?.from_date ? master.from_date : "";
    let date2 = master?.to_date ? master.to_date : "";
    let type = "";
    if (selectedTransationType !== null) {
      type = selectedTransationType.value;
    }
    let chart_id = "";
    if (selectedChart !== null) {
      chart_id = selectedChart.value;
    }
    handleTableData(type, date1, date2, chart_id);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Transaction Log" />

          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        onChange={handleDate}
                        value={master?.from_date}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        onChange={handleDate}
                        value={master?.to_date}
                        min={master?.from_date}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Transaction Type</Label>
                      <Select
                        name="customer_community_id"
                        value={selectedTransationType}
                        onChange={(selected) => {
                          handleSelectedTransactionType(selected);
                        }}
                        options={[
                          { label: "Credit", value: 0 },
                          { label: "Debit", value: 1 },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Chart of account</Label>
                      <Select
                        name="chart_id"
                        value={selectedChart}
                        onChange={(selected) => {
                          handleSelectChange(selected);
                        }}
                        options={chartOfAccOption}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                    <Button
                      color="primary"
                      type="submit"
                      onClick={() => handleSearch()}
                    >
                      Search
                    </Button>
                  </Col>
                  <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                    <Button color="danger" type="reset" onClick={() => reset()}>
                      Reset
                    </Button>
                  </Col>
                </Row>

                <MDBDataTable
                  id="table_transaction_log"
                  responsive
                  bordered
                  data={data}
                  searching={true}
                  info={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(TransactionLog)
);

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
