/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Row,
  TabPane,
} from "reactstrap";
import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function LeisuretimeActivities(props) {
  let {  details,  editable } = props;

  useEffect(() => {
    setgames(details?.games_exercises);
    setmovies(details?.movies_dramas);
    settvvideo(details?.tv_music);
    setreading(details?.reading);
    setrelativ(details?.vst_rltvs_frnds);
    setother(details?.others);

    setmasterobj({
      ...masterobj,
      games_exercises: details?.games_exercises,
      movies_dramas: details?.movies_dramas,
      tv_music: details?.tv_music,
      reading: details?.reading,
      vst_rltvs_frnds: details?.vst_rltvs_frnds,
      others: details?.others,
    });
  }, [details]);

  const [masterobj, setmasterobj] = useState({});
  const [games, setgames] = useState({});
  const [movies, setmovies] = useState({});
  const [tvvideo, settvvideo] = useState({});
  const [reading, setreading] = useState({});
  const [relativ, setrelativ] = useState({});
  const [other, setother] = useState({});

  function handlegames(e) {
    let name = e.target.name;
    let value = e.target.value;
    setgames({
      ...games,
      [name]: value,
    });
  }

  function handlemovie(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmovies({
      ...movies,
      [name]: value,
    });
  }

  function handletvvideo(e) {
    let name = e.target.name;
    let value = e.target.value;
    settvvideo({
      ...tvvideo,
      [name]: value,
    });
  }

  function handlereading(e) {
    let name = e.target.name;
    let value = e.target.value;
    setreading({
      ...reading,
      [name]: value,
    });
  }

  function handlerelative(e) {
    let name = e.target.name;
    let value = e.target.value;
    setrelativ({
      ...relativ,
      [name]: value,
    });
  }

  function handleothers(e) {
    let name = e.target.name;
    let value = e.target.value;
    setother({
      ...other,
      [name]: value,
    });
  }

  useEffect(() => {
    setmasterobj({
      ...masterobj,
      games_exercises: games,
      movies_dramas: movies,
      tv_music: tvvideo,
      reading: reading,
      vst_rltvs_frnds: relativ,
      others: other,
    });
  }, [games, movies, tvvideo, reading, relativ, other]);

  function handleValidSubmit() {
    masterobj.admission_id = props.id;
    axios
      .post(`${API_URL}admission/leisure-activities`, masterobj, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success(res.data.message);
        props.handleToggleBar("increment");
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  }

  return (
    <TabPane tabId={9}>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
          handleValidSubmit();
        }}
      >
        <Row>
          <h6>1. Activities</h6>
        </Row>
        <Col md="10" className="mt-2">
          <Row>
            <h6 style={{ marginLeft: "1rem" }}>
              1.1 Playing Games,physical exercises
            </h6>
          </Row>
          <Row>
            <Col md="6">
              <p style={{ marginLeft: "2rem" }}>Before Addiction</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="before_addiction"
                type="text"
                className="form-control form-control3"
                onChange={handlegames}
                value={masterobj?.games_exercises?.before_addiction}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6" className="mt-">
              <p style={{ marginLeft: "2rem" }}>In the last one year</p>
            </Col>

            <Col
              className="mt-2"
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="last_one_year"
                type="text"
                className="form-control form-control3"
                onChange={handlegames}
                value={masterobj?.games_exercises?.last_one_year}
              />
            </Col>
          </Row>
          <Row>
            <h6 style={{ marginLeft: "1rem" }}>1.2 Going to movies,dramas</h6>
          </Row>
          <Row>
            <Col md="6" className="mt-2">
              <p style={{ marginLeft: "2rem" }}>Before Addiction</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="before_addiction"
                type="text"
                className="form-control form-control3"
                onChange={handlemovie}
                value={masterobj?.movies_dramas?.before_addiction}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <p style={{ marginLeft: "2rem" }}>In the last one year</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="last_one_year"
                type="text"
                className="form-control form-control3"
                onChange={handlemovie}
                value={masterobj?.movies_dramas?.last_one_year}
              />
            </Col>
          </Row>

          <Row>
            <h6 style={{ marginLeft: "1rem" }}>
              1.3 Watching TV/video,listening to music
            </h6>
          </Row>
          <Row>
            <Col md="6" className="mt-2">
              <p style={{ marginLeft: "2rem" }}>Before Addiction</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="before_addiction"
                type="text"
                className="form-control form-control3"
                onChange={handletvvideo}
                value={masterobj?.tv_music?.before_addiction}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <p style={{ marginLeft: "2rem" }}>In the last one year</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="last_one_year"
                type="text"
                className="form-control form-control3"
                onChange={handletvvideo}
                value={masterobj?.tv_music?.last_one_year}
              />
            </Col>
          </Row>
          <Row>
            <h6 style={{ marginLeft: "1rem" }}>1.4 Reading</h6>
          </Row>
          <Row>
            <Col md="6" className="mt-2">
              <p style={{ marginLeft: "2rem" }}>Before Addiction</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
                disabled={!editable}
                name="before_addiction"
                type="text"
                className="form-control form-control3"
                onChange={handlereading}
                value={masterobj?.reading?.before_addiction}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <p style={{ marginLeft: "2rem" }}>In the last one year</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
               disabled={!editable}
                name="last_one_year"
                type="text"
                className="form-control form-control3"
                onChange={handlereading}
                value={masterobj?.reading?.last_one_year}
              />
            </Col>
          </Row>

          <Row>
            <h6 style={{ marginLeft: "1rem" }}>
              1.5 Visiting relatives/friends
            </h6>
          </Row>
          <Row>
            <Col md="6" className="mt-2">
              <p style={{ marginLeft: "2rem" }}>Before Addiction</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
               disabled={!editable}
                name="before_addiction"
                type="text"
                className="form-control form-control3"
                onChange={handlerelative}
                value={masterobj?.vst_rltvs_frnds?.before_addiction}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <p style={{ marginLeft: "2rem" }}>In the last one year</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
               disabled={!editable}
                name="last_one_year"
                type="text"
                className="form-control form-control3"
                onChange={handlerelative}
                value={masterobj?.vst_rltvs_frnds?.last_one_year}
              />
            </Col>
          </Row>
          <Row>
            <h6 style={{ marginLeft: "1rem" }}>1.6 Other Hobbies/talents</h6>
          </Row>
          <Row>
            <Col md="6" className="mt-2">
              <p style={{ marginLeft: "2rem" }}>Before Addiction</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
               disabled={!editable}
                name="before_addiction"
                type="text"
                className="form-control form-control3"
                onChange={handleothers}
                value={masterobj?.others?.before_addiction}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <p style={{ marginLeft: "2rem" }}>In the last one year</p>
            </Col>

            <Col
              md="4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AvField
               disabled={!editable}
                name="last_one_year"
                type="text"
                className="form-control form-control3"
                onChange={handleothers}
                value={masterobj?.others?.last_one_year}
              />
            </Col>
          </Row>
        </Col>

        <Row className="mt-4">
          <Col md={12} className="submit-btn-fixed">
            <FormGroup className="float-right ">
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("decrement")}
              >
                <i class="fas fa-solid fa-less-than"></i>
              </a>
              <button type="submit" className="save-continue-btn m-2">
                Save and continue{" "}
                <i
                  style={{ fontSize: "13px" }}
                  className="mdi mdi-arrow-right"
                ></i>
              </button>
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("increment")}
              >
                <i class="fas fa-solid fa-greater-than"></i>
              </a>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </TabPane>
  );
}

export default LeisuretimeActivities;
