/* eslint-disable */
import React, { useState, useEffect, useRef, Suspense } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import FamilyHIstory from "./Family History/FamilyHIstory";
import "./styles.scss";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";

import classnames from "classnames";
import BasicDetails from "./BasicDetails";
import ChildhoodHistory from "./ChildhoodHistory";
import EducationalHistory from "./EducationalHistory";
import MaritalHistory from "./MaritalHistory";
import SexualHistory from "./SexualHistory";
import OccupationalHistory from "./OccupationalHistory";
import FinancialHistory from "./FinancialHistory";
import LegalHistory from "./LegalHistory";
import LeisuretimeActivities from "./LeisuretimeActivities";
import Religiousbeliefs from "./ReligiousBelief";
import Referrel from "./Referrel";
import CounsellingNotes from "./CounsellingNotes/Counselling";
import Treatmentplan from "./CounsellingNotes/Treatmentplan";
import GroupTherapy from "./GroupTherapy";
import { getAdmissionDetails } from "./main/api";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ProfileMenu from "./Profile/ProfileMenu";
import Assessment from "./FollowUps/Assessment/index";
import Medication from "./Medication";
import Review from "./FollowUps/Review/index";
import DrinkingHistory from "./MedicalForm/DrinkingHistory";
import PriviousHistory from "./MedicalForm/PreviousHistory";
import { useHistory, useLocation } from "react-router-dom";
import MedicalChart from "./MedicalChart/MedicalChart";
import { post } from "../../helpers/api_helper";
import { ObjectId } from "../../helpers/globalVariables";

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

const Admission = (props) => {
  const { state } = useLocation();
  const history = useHistory();

  const [activeTabVartical, setoggleTabVertical] = useState(1);
  const [admissionDetails, setAdmissionDetails] = useState(null);
  const [reload, setReload] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [medicalActiveTab, setMedicalActiveTab] = useState(1);
  const [alltedTabs, setAllotedTabs] = useState([]);
  const [activeTabJustify, setactiveTabJustify] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const loggerUser = JSON.parse(localStorage.getItem("authUser"));
  const [isAdmin, setIsAdmin] = useState(loggerUser.privilage === ObjectId.ADMIN || false);
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    const privilageId = JSON.parse(localStorage.getItem("authUser")).privilage;
    getAllotedTabs(privilageId);
  }, []);

  let getAllotedTabs = (privilege) =>
    post(`${API_URL}tabs/alloted-tabs`, { privilege }).then((res) => {
      setAllotedTabs(res.data[0].alloted_tabs);
      setactiveTabJustify(res.data[0].alloted_tabs[0].tab);
    });

  useEffect(() => {
    // Object.keys(alloted_tabs[0]).length === 0
    if (alltedTabs.length > 0 && Object.keys(alltedTabs[0]).length === 0) {
      history.push("/dashboard");
    }
  }, [alltedTabs]);

  useEffect(() => {
    setisLoading(true);
    getAdmissionDetails(state.id).then((data) => {
      if (isAdmin) {
        setIsEditable(true);
      } else {
        setIsEditable(data.editable);
      }
      setAdmissionDetails(data);
      setisLoading(false);
    });
  }, [state, reload]);

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab);
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 11) {
        setoggleTabVertical(tab);
      }
    }
  }

  const handleToggleBar = (name) => {
    name === "increment" ? toggleTabVertical(activeTabVartical + 1) : toggleTabVertical(activeTabVartical - 1);
  };

  // ? Toolttip component
  function MyToolTip({ text, children }) {
    return (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip
            id="tooltip-top"
            style={{
              position: "absolute",
              top: "-25px",
            }}>
            {text}
          </Tooltip>
        }>
        <>{children}</>
      </OverlayTrigger>
    );
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab);
      }
    }
  }

  useEffect(() => {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    // body.classList.toggle("sidebar-enable");
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" /*breadcrumbItem={admissionDetails?.reg_no} */ />
          {isLoading ? (
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody style={{ minHeight: "65vh" }}>
                    <div class="simple-spinner">
                      <span></span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              {/* //? Sidebar details */}
              <Col xl="3">
                <ProfileMenu details={admissionDetails} />
              </Col>

              <Col xl="9">
                <Card>
                  <CardBody>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      {alltedTabs.map((item, index) => {
                        return (
                          <NavItem key={index + "navTab"}>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTabJustify === "" + item.tab + "",
                              })}
                              onClick={() => {
                                toggleCustomJustified("" + item.tab + "");
                              }}>
                              <span className="d-block d-sm-none">
                                <i className={item.icon}></i>
                              </span>
                              <span className="d-none d-sm-block">{item.name}</span>
                            </NavLink>
                          </NavItem>
                        );
                      })}
                    </Nav>

                    <TabContent activeTab={activeTabJustify}>
                      {alltedTabs.map((item, index) => {
                        switch (item.tab) {
                          case "1":
                            return (
                              <TabPane
                                tabId="1"
                                className="p-3"
                                // style={{ minHeight: "40.2rem" }}
                              >
                                {activeTabJustify === "1" && (
                                  <BasicDetails
                                    activeTabVartical={activeTabVartical}
                                    handleToggleBar={handleToggleBar}
                                    details={admissionDetails?.basic_details}
                                    admission={admissionDetails}
                                    id={admissionDetails?._id}
                                    reload={reload}
                                    setReload={setReload}
                                    editable={isEditable && item.tab_edit ? true : false}
                                  />
                                )}
                              </TabPane>
                            );
                          case "2":
                            return (
                              <TabPane tabId="2" className="pt-3" style={{ minHeight: "40.2rem" }}>
                                {activeTabJustify === "2" && (
                                  <div className="vertical-wizard wizard clearfix vertical">
                                    <div className="steps clearfix">
                                      <ul>
                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 1,
                                          })}>
                                          <MyToolTip text="Family history">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 1,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(1);
                                              }}>
                                              {/* <span className="number">2.</span>{" "} */}
                                              <i className="mdi mdi-human-male-child"></i>
                                              &nbsp; Family
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 2,
                                          })}>
                                          <MyToolTip text="Childhood and adolescent history">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 2,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(2);
                                              }}>
                                              {/* <span className="number">3.</span> */}
                                              <i className="fas fa-child"></i> Adolescent
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 3,
                                          })}>
                                          <MyToolTip text="Educational history">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 3,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(3);
                                              }}>
                                              {/* <span className="number">4.</span>  */}
                                              <i className="fas fa-graduation-cap"></i>
                                              &nbsp; Educational
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 4,
                                          })}>
                                          <MyToolTip text="Marital history">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 4,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(4);
                                              }}>
                                              {/* <span className="number">5.</span> */}
                                              <i className="mdi mdi-ring"></i>
                                              &nbsp; Marital
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 5,
                                          })}>
                                          <MyToolTip text="Sexual history">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 5,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(5);
                                              }}>
                                              {/* <span className="number">6.</span> */}
                                              <i className="fa fa-venus-mars"></i>
                                              &nbsp; Sexual
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 6,
                                          })}>
                                          <MyToolTip text="Occupational history">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 6,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(6);
                                              }}>
                                              {/* <span className="number">7.</span>{" "} */}
                                              <i className="mdi mdi-account-tie"></i>
                                              &nbsp; Occupational
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 7,
                                          })}>
                                          <MyToolTip text="Financial history">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 7,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(7);
                                              }}>
                                              {/* <span className="number">8.</span>  */}
                                              <i className="fas fa-rupee-sign"></i>
                                              &nbsp; Financial
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 8,
                                          })}>
                                          <MyToolTip text="Legal history">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 8,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(8);
                                              }}>
                                              {/* <span className="number">9.</span>  */}
                                              <i class="fas fa-duotone fa-gavel"></i> &nbsp;Legal
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 9,
                                          })}>
                                          <MyToolTip text="Leisure time activties">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 9,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(9);
                                              }}>
                                              {/* <span className="number">10.</span>  */}
                                              <i className="mdi mdi-av-timer"></i>
                                              &nbsp; Leisure time
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 10,
                                          })}>
                                          <MyToolTip text="Religious beliefs">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 10,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(10);
                                              }}>
                                              {/* <span className="number">11.</span> */}
                                              <i className="fas fa-pray"></i>
                                              &nbsp; Religious beliefs
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTabVartical === 11,
                                          })}>
                                          <MyToolTip text="Refferal-medical as well as other referrals">
                                            <NavLink
                                              className={classnames({
                                                active: activeTabVartical === 11,
                                                done: true,
                                              })}
                                              onClick={() => {
                                                toggleTabVertical(11);
                                              }}>
                                              {/* <span className="number">12.</span>  */}
                                              <i className="fas fa-user-plus"></i>
                                              &nbsp;
                                              <span>Refferal</span>
                                            </NavLink>
                                          </MyToolTip>
                                        </NavItem>
                                      </ul>
                                    </div>
                                    <div className="content clearfix scroll-bar">
                                      <TabContent activeTab={activeTabVartical}>
                                        {/* {activeTabVartical === 1 && ( */}
                                        <FamilyHIstory
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          details={admissionDetails?.family_history}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                        />
                                        {/* )} */}
                                        {/* {activeTabVartical === 2 && ( */}
                                        <ChildhoodHistory
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          details={admissionDetails?.childhood_history}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                        />
                                        {/* )} */}
                                        {/* {activeTabVartical === 3 && ( */}
                                        <EducationalHistory
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          details={admissionDetails?.educational_history}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                        />
                                        {/* )} */}
                                        {/* {activeTabVartical === 4 && ( */}
                                        <MaritalHistory
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                          details={admissionDetails?.marital_history}
                                        />
                                        {/* )} */}
                                        {/* {activeTabVartical === 5 && ( */}
                                        <SexualHistory
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          details={admissionDetails?.sexual_history}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                        />
                                        {/* )} */}
                                        {/* {activeTabVartical === 6 && ( */}
                                        <OccupationalHistory
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          details={admissionDetails?.occupational_history}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                        />
                                        {/* )} */}
                                        {/* {activeTabVartical === 7 && ( */}
                                        <FinancialHistory
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          details={admissionDetails?.financial_history}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                        />
                                        {/* )} */}
                                        {/* {activeTabVartical === 8 && ( */}
                                        <LegalHistory
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          id={admissionDetails?._id}
                                          details={admissionDetails?.legal_history}
                                          editable={isEditable && item.tab_edit ? true : false}
                                        />
                                        {/* )} */}
                                        {/* {activeTabVartical === 9 && ( */}
                                        <LeisuretimeActivities
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                          details={admissionDetails?.leisure_activities}
                                        />
                                        {/* )} */}
                                        {/* {activeTabVartical === 10 && ( */}
                                        <Religiousbeliefs
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                          details={admissionDetails?.religious_details}
                                        />
                                        {/* )} */}

                                        {/* {activeTabVartical === 11 && ( */}
                                        <Referrel
                                          activeTabVartical={activeTabVartical}
                                          handleToggleBar={handleToggleBar}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                          details={admissionDetails?.referral_details}
                                        />
                                        {/* )} */}
                                      </TabContent>
                                    </div>
                                  </div>
                                )}
                              </TabPane>
                            );
                          case "3":
                            return (
                              // <TabPane tabId={"3"}>
                              //   <CounsellingNotes id={admissionDetails?._id}
                              //    editable={item.tab_edit}
                              //   />
                              // </TabPane>

                              <TabPane tabId="3" className="pt-3">
                                {activeTabJustify === "3" && (
                                  <div className="wizard clearfix">
                                    <div className="steps clearfix">
                                      <ul>
                                        <NavItem
                                          className={classnames({
                                            current: activeTab === 1,
                                          })}>
                                          <NavLink
                                            className={classnames({
                                              current: activeTab === 1,
                                            })}
                                            onClick={() => {
                                              toggleTab(1);
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            <i className="fas fa-book-open"></i>
                                            &nbsp; Counselling Notes
                                          </NavLink>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTab === 2,
                                          })}>
                                          <NavLink
                                            className={classnames({
                                              current: activeTab === 2,
                                            })}
                                            onClick={() => {
                                              toggleTab(2);
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            <i className="fas fa-book-open"></i>
                                            &nbsp; Treatment Plan
                                          </NavLink>
                                        </NavItem>
                                      </ul>
                                    </div>
                                    <div>
                                      <TabContent activeTab={activeTab}>
                                        <CounsellingNotes
                                          activeTab={activeTab}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                        />

                                        <Treatmentplan activeTab={activeTab} id={admissionDetails?._id} editable={isEditable && item.tab_edit ? true : false} />
                                      </TabContent>
                                    </div>
                                  </div>
                                )}
                              </TabPane>
                            );
                          case "4":
                            return (
                              <TabPane tabId={"4"}>
                                {activeTabJustify === "4" && <Medication id={admissionDetails?._id} editable={isEditable && item.tab_edit ? true : false} />}
                              </TabPane>
                            );
                          case "5":
                            return (
                              <TabPane tabId={"5"}>
                                {activeTabJustify === "5" && <GroupTherapy id={admissionDetails?._id} editable={isEditable && item.tab_edit ? true : false} />}
                              </TabPane>
                            );
                          case "6":
                            return (
                              <TabPane tabId="6" className="pt-3">
                                {activeTabJustify === "6" && (
                                  <div className="wizard clearfix">
                                    <div className="steps clearfix">
                                      <ul>
                                        <NavItem
                                          className={classnames({
                                            current: medicalActiveTab === 1,
                                          })}>
                                          <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                              current: medicalActiveTab === 1,
                                            })}
                                            onClick={() => {
                                              setMedicalActiveTab(1);
                                            }}>
                                            <i className="fas fa-book-open"></i>
                                            &nbsp; Drinking/Drug History
                                          </NavLink>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: medicalActiveTab === 2,
                                          })}>
                                          <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                              current: medicalActiveTab === 2,
                                            })}
                                            onClick={() => {
                                              setMedicalActiveTab(2);
                                            }}>
                                            <i className="fas fa-book-open"></i>
                                            &nbsp; Previous History
                                          </NavLink>
                                        </NavItem>
                                      </ul>
                                    </div>
                                    <div className="mt-3">
                                      <TabContent activeTab={medicalActiveTab}>
                                        <DrinkingHistory
                                          medicalActiveTab={medicalActiveTab}
                                          id={admissionDetails?._id}
                                          editable={isEditable && item.tab_edit ? true : false}
                                        />
                                        <PriviousHistory
                                          editable={isEditable && item.tab_edit ? true : false}
                                          id={admissionDetails?._id}
                                          medicalActiveTab={medicalActiveTab}
                                        />
                                      </TabContent>
                                    </div>
                                  </div>
                                )}
                              </TabPane>
                            );
                          case "7":
                            return (
                              <TabPane tabId="7" className="pt-3">
                                {activeTabJustify === "7" && <MedicalChart id={admissionDetails?._id} editable={isEditable && item.tab_edit ? true : false} />}
                              </TabPane>
                            );
                          case "8":
                            return (
                              <TabPane tabId="8" className="pt-3">
                                {activeTabJustify === "8" && (
                                  <div className="wizard clearfix">
                                    <div className="steps clearfix">
                                      <ul>
                                        <NavItem
                                          className={classnames({
                                            current: activeTab === 1,
                                          })}>
                                          <NavLink
                                            className={classnames({
                                              current: activeTab === 1,
                                            })}
                                            onClick={() => {
                                              toggleTab(1);
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            <i className="fas fa-book-open"></i>
                                            &nbsp; Review
                                          </NavLink>
                                        </NavItem>

                                        <NavItem
                                          className={classnames({
                                            current: activeTab === 2,
                                          })}>
                                          <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                              current: activeTab === 2,
                                            })}
                                            onClick={() => {
                                              toggleTab(2);
                                            }}>
                                            <i className="fas fa-book-open"></i>
                                            &nbsp; Assessment
                                          </NavLink>
                                        </NavItem>
                                      </ul>
                                    </div>
                                    <div>
                                      <TabContent activeTab={activeTab}>
                                        <Review activeTab={activeTab} id={admissionDetails?._id} editable={isEditable && item.tab_edit ? true : false} />

                                        <Assessment activeTab={activeTab} editable={isEditable && item.tab_edit ? true : false} id={admissionDetails?._id} />
                                      </TabContent>
                                    </div>
                                  </div>
                                )}
                              </TabPane>
                            );
                          default:
                            return <></>;
                        }
                      })}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Admission;
