/* eslint-disable */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Card, CardBody, Label, Button, Badge } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import "./enquiry.scss";
import CreatableSelect from "react-select/creatable";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { getDate, getFirstday } from "../../helpers/globalFunctions";
import { get } from "../../helpers/api_helper";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
const Enquiry = (props) => {
  const formRef = useRef();
  const formRef1 = useRef();
  const [enquiryObject, setEnquiryObject] = useState({ date_time: moment().format("YYYY-MM-DD HH:mm:ss") });
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [enqError, setEnqError] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [selectedState, setSelectedState] = useState({
    label: "Kerala",
    value: "634d2b7f2bd8fbee01762bcb",
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "Kannur",
    value: "629b4df34d405c9d49192652",
  });
  const [selectedMobile, setselectedMobile] = useState(null);
  const [searchMobileListOptions, setsearchMobileListOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedSource, setSelectedSource] = useState({});
  const [sourceOptions, setSourceOptions] = useState([]);
  const [selectedHeardFrom, setSelectedHeardFrom] = useState({});
  const [heardFromOptions, setHeardFromOptions] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [courseOptions, setCourseOptions] = useState([]);
  const [enqIdToBeUpdated, setEnqIdToBeUpdated] = useState(null);
  const [enqIdToBeDeleted, setEnqIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [datatable, setDataTable] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalObject, setModalObject] = useState({});
  const [mobError, setMobError] = useState("");

  /** Followup */
  const [followupObject, setFollowupObject] = useState({
    showCourse: "none",
    showNext: "block",
    status: 0,
    statusObj: { label: "New", value: 0 },
    course: null,
    courseObj: null,
    source: null,
    sourceObj: null,
  });
  const [followupOptions, setFollowupOptions] = useState([
    { label: "New", value: 0 },
    { label: "Pending", value: 1 },
    { label: "Cancelled", value: 3 },
  ]);
  const [modeOptions, setModeOptions] = useState([]);
  const [enqIdToBeShown, setEnqIdToBeShown] = useState(null);
  const [followupTable, setFollowupTable] = useState([]);

  const history = useHistory();
  const addToAdmission = (item) => {
    //console.log("itemzz", item)
    axios
      .post(
        `${API_URL}admission/`,
        {
          first_name: item.enq_basic_id.fname,
          last_name: item.enq_basic_id.lname,
          enq_id: item._id,
          mobile: item.enq_basic_id.mobile,
          basic_id: item.enq_basic_id._id,
          state: item.enq_basic_id.state,
          district: item.enq_basic_id.district,
          pin: item.enq_basic_id.pin,
          address: item.enq_basic_id.address,
        },
        {
          headers: { "x-access-token": accessToken },
        }
      )
      .then((res) => {
        //console.log(res.data.status)
        if (res.data.status === 200) getDataTable();
      })
      .catch((e) => {
        toastr.error(e.response.data.message);
      });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const getQualifications = (id, type = null) => {
    axios
      .get(`${API_URL}enquiry/qualification-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.qualification_name, value: item._id });
        });
        setQualificationOptions(options);
      });
  };

  const getStates = () => {
    axios
      .get(`${API_URL}enquiry/state-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id, id: item.id });
        });
        setStateOptions(options);
      });
  };

  const getDistrict = (_id) => {
    //console.log("_id", _id)
    axios
      .get(`${API_URL}enquiry/district-list/?id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.district_name, value: item._id });
        });
        setDistrictOptions(options);
      });
  };

  const getSources = () => {
    axios
      .get(`${API_URL}enquiry/source-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setSourceOptions(options);
      });
  };

  const getHeardFrom = () => {
    axios
      .get(`${API_URL}enquiry/heardfrom-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({ label: item.name, value: item._id });
        });
        setHeardFromOptions(options);
      });
  };

  const getCourses = () => {
    axios
      .get(`${API_URL}chapter/course-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var options = [];
        data.map((item) => {
          options.push({
            label: item.course_name,
            value: item._id,
            mode: item.mode,
            amount: item?.amount,
            offer_amount: item?.offer_amount,
            live_amount: item?.live_amount,
            live_offer_amount: item?.live_offer_amount,
          });
        });
        setCourseOptions(options);
      });
  };

  const getDataTable = (from_date = "", to_date = "", status = "") => {
    var url = `${API_URL}enquiry/get_datatable`;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }
    url = url + "?from_date=" + from_date + "&to_date=" + to_date + "&status=" + status;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        //console.log("data", data)
        var list = [];
        data.map((item, index) => {
          item.id = index + 1;
          item.name = item.enq_basic_id?.fname + " " + item.enq_basic_id?.lname;
          item.mobile = item.enq_basic_id?.mobile;
          item.email = item.enq_basic_id?.email;
          item.source = item.source?.name;
          item.firstName = item.added_by?.firstName;
          item.date = moment(item?.date).format("DD-MM-YYYY");
          if (item.latest_follow_status == 0)
            item.status = (
              <Button className="btn btn-sm custbadge" color="primary">
                New
              </Button>
            );
          else if (item.latest_follow_status == 1)
            item.status = (
              <Button className="btn btn-sm custbadge" color="warning">
                Pending
              </Button>
            );
          else if (item.latest_follow_status == 2)
            item.status = (
              <Button className="btn btn-sm custbadge" color="success">
                Admitted
              </Button>
            );
          else if (item.latest_follow_status == 3)
            item.status = (
              <Button className="btn btn-sm custbadge" color="danger">
                Cancelled
              </Button>
            );
          if (props.options.edit) {
            item.action = (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                      Admission
                    </Tooltip>
                  }>
                  {item.latest_follow_status === 2 ? (
                    <i className="fas fa-user-check" style={{ fontSize: "0.9em", cursor: "not-allowed", marginLeft: "7px" }}></i>
                  ) : (
                    <i
                      className="fas fa-user-check"
                      style={{ fontSize: "0.9em", cursor: "pointer", marginLeft: "7px" }}
                      //onClick={() => addToAdmission(item)}
                      onClick={() => {
                        history.push("/new-admission/", {
                          item: item?._id,
                        });
                      }}></i>
                  )}
                </OverlayTrigger>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    preUpdateEnquiry(item);
                    toTop();
                  }}></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "0.9em", cursor: "pointer" }}
                  onClick={() => {
                    setEnqIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </>
            );
          } else {
            item.action = (
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
            );
          }
          list.push(item);
        });
        setDataTable(list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    let status = searchData?.status ? searchData.status : "";

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    getDataTable(date1, date2, status);
  };

  const handleSearchSelectChange = (selected, name) => {
    let from_date = searchData?.from_date ? searchData.from_date : "";
    let to_date = searchData?.to_date ? searchData.to_date : "";
    let status = searchData?.status ? searchData.status : "";

    switch (name) {
      case "status":
        setSelectedStatus(selected);
        setSearchData({
          ...searchData,
          status: selected.value,
        });
        getDataTable(from_date, to_date, selected.value);
        break;
      default:
        break;
    }
  };
  const preUpdateEnquiry = (item) => {
    //console.log("item", item)
    setEnqIdToBeUpdated(item?._id);
    axios
      .get(`${API_URL}enquiry/single-enquiry?id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        if (data) {
          setselectedMobile({ label: data.mobile, value: data.mobile });
          setSelectedQualification({ label: data?.qualification_name, value: data?.qualification });
          setSelectedState({ label: data?.state_name, value: data?.state, id: data?.state });
          getDistrict(data?.state);
          setSelectedDistrict({ label: data?.district_name, value: data?.district });
          setSelectedSource({ label: data?.source_name, value: data?.source });
          setSelectedHeardFrom({ label: data?.heardfrom_name, value: data?.heardfrom });
          setSelectedCourse({ label: data?.course_name, value: data?.course });
          setEnquiryObject(data);
        }
      });
  };
  const handleCreateMobile = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue, label: inputValue };
      setsearchMobileListOptions([...searchMobileListOptions, newValue]);
      setselectedMobile(newValue);
      $("#reg_mobile").attr("class", "select2-selection");
      setMobError("");
      setEnquiryObject({
        ...enquiryObject,
        mobile: inputValue,
        enq_basic_id: null,
        fname: "",
        lname: "",
        city: "",
        qualification: null,
        email: "",
        address: "",
        district: "",
        pin: "",
        state: "",
      });
      setSelectedQualification(null);
      setSelectedState(null);
      setSelectedDistrict(null);
      setDistrictOptions([]);
    },
    [searchMobileListOptions, enquiryObject]
  );
  const searchProfile = () => {
    axios
      .get(`${API_URL}enquiry/mobile-list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var mobiles = [];
        res.data.data.map((el, index) => {
          mobiles.push({
            label: el.mobile,
            value: el.mobile,
            id: el._id,
            fname: el.fname,
            lname: el.lname,
            mobile: el.mobile,
            email: el.email,
            city: el.city,
            qualification: { label: el.qualification_name, value: el.qualification },
            address: el.address,
            district: { label: el.district_name, value: el.district },
            email: el.email,
            fname: el.fname,
            lname: el.lname,
            pin: el.pin,
            state: { label: el.state_name, value: el.state },
          });
        });
        //console.log("mobiles",mobiles);
        setsearchMobileListOptions([{ options: mobiles }]);
      });
  };

  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setEnquiryObject({
      ...enquiryObject,
      [name]: value,
    });
  };

  const handleSelectChange = (e, type) => {
    switch (type) {
      case "1":
        setSelectedQualification(e);
        setEnquiryObject({
          ...enquiryObject,
          qualification: e.value,
        });
        break;
      case "2":
        setSelectedState(e);
        //console.log("e", e)
        setEnquiryObject({
          ...enquiryObject,
          state: e.value,
        });
        getDistrict(e.value);
        setSelectedDistrict({});
        break;
      case "3":
        setSelectedDistrict(e);
        setEnquiryObject({
          ...enquiryObject,
          district: e.value,
        });
        break;
      case "4":
        setSelectedSource(e);
        setEnquiryObject({
          ...enquiryObject,
          source: e.value,
        });
        break;
      case "5":
        setSelectedHeardFrom(e);
        setEnquiryObject({
          ...enquiryObject,
          heardfrom: e.value,
        });
        break;
      case "6":
        setSelectedCourse(e);
        setEnquiryObject({
          ...enquiryObject,
          course: e.value,
        });
        break;
      case "7":
        setselectedMobile(e);
        //console.log("eeeeeee",e)

        if (e) {
          if (typeof e.id !== "undefined") {
            setSelectedQualification(e.qualification);
            setSelectedDistrict(e.district);
            setSelectedState(e.state);
            getDistrict(e.state.value);

            setEnquiryObject({
              ...enquiryObject,
              enq_basic_id: e.id,
              mobile: e.value,
              fname: e.fname,
              lname: e.lname,
              city: e.city,
              qualification: e.qualification.value,
              email: e.email,
              address: e.address,
              district: e.district.value,
              pin: e.pin,
              state: e.state.value,
            });
          } else {
            setSelectedQualification(null);
            setSelectedState(null);
            setSelectedDistrict(null);
            setDistrictOptions([]);
            setEnquiryObject({
              ...enquiryObject,
              mobile: e.value,
              enq_basic_id: null,
              fname: "",
              lname: "",
              city: "",
              qualification: null,
              email: "",
              address: "",
              district: null,
              pin: "",
              state: null,
            });
          }

          $("#reg_mobile").attr("class", "select2-selection");
          setMobError("");
        } else {
          setSelectedQualification(null);
          setSelectedState(null);
          setSelectedDistrict(null);
          setDistrictOptions([]);
          setEnquiryObject({
            ...enquiryObject,
            mobile: "",
            enq_basic_id: null,
            fname: "",
            lname: "",
            city: "",
            qualification: null,
            email: "",
            address: "",
            district: null,
            pin: "",
            state: null,
          });
          $("#reg_mobile").attr("class", "select2-selection border border-danger");
          setMobError("Mobile is required");
        }
        break;
    }
  };

  const handleValidSubmit = () => {
    if (typeof enquiryObject.mobile !== "undefined" && enquiryObject.mobile != "") {
      $("#reg_mobile").attr("class", "select2-selection");
      setMobError("");
    } else {
      $("#reg_mobile").attr("class", "select2-selection border border-danger");
      setMobError("Mobile is required");
      return;
    }
    if (enqIdToBeUpdated) {
      axios
        .post(`${API_URL}enquiry/update`, enquiryObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Enquiry updated successfully");
            reset();
            setEnqIdToBeUpdated(null);
            getDataTable();
            searchProfile();
          } else {
            toastr.error("Failed to update enquiry");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      //console.log("enquiryObject", enquiryObject)
      axios
        .post(`${API_URL}enquiry/add`, enquiryObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Enquiry added successfully");
            reset();
            getDataTable();
            searchProfile();
          } else {
            toastr.error("Failed to add enquiry");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}enquiry/single-enquiry?id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        if (data) {
          setIsOpenModal(true);
          data.date_time = moment().format("YYYY-MM-DD hh:mm a");
          setModalObject(data);
          setEnqIdToBeShown(item._id);
          getFollowupDataTable(item._id);
        }
      });
  };

  const getFollowupDataTable = (id) => {
    axios
      .get(`${API_URL}enquiry/followup-list?id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data.follow_up;
        data.sort(function (a, b) {
          var x = a._id;
          var y = b._id;
          // factor -1 because you want them sorted DESC
          return -1 * (x < y ? -1 : x > y ? 1 : 0);
        });
        var list = [];
        data.map((item, index) => {
          item.id = index + 1;
          item.source = item.source?.name;
          item.course_name = item.course?.course_name;
          item.date = moment(item?.date).format("DD-MM-YYYY") + " " + item?.time;
          item.firstName = item.added_by?.firstName;
          if (item?.mode == "1") {
            item.mode = "Live";
          } else if (item?.mode == "0") {
            item.mode = "Recorded";
          }

          //console.log("11", item.next_follow_date)

          item.next_follow_date = moment(item?.next_follow_date).format("DD-MM-YYYY");
          if (item.follow_status == 0)
            item.status = (
              <Button className="btn btn-sm custbadge" color="primary">
                New
              </Button>
            );
          else if (item.follow_status == 1)
            item.status = (
              <Button className="btn btn-sm custbadge" color="warning">
                Pending
              </Button>
            );
          else if (item.follow_status == 2) {
            // item.comments = (<>{item.comments}
            //     <br></br><b>Course : </b> {item.course_name}
            //     <br></br><b>Mode : </b> {item.mode}
            //     <br></br><b>Course Fee : </b><i className="fas fa-rupee-sign"></i> {item.course_fee}
            // </>)
            item.status = (
              <Button className="btn btn-sm custbadge" color="success">
                Registered
              </Button>
            );
          } else if (item.follow_status == 3)
            item.status = (
              <Button className="btn btn-sm custbadge" color="danger">
                Cancelled
              </Button>
            );

          list.push(item);
        });
        list.push({
          id: list.length + 1,
          source: res.data.data.source?.name,
          date: moment(res.data.data?.date_time).format("DD-MM-YYYY hh:mm a"),
          comments: res.data.data?.comment,
          status: (
            <Button className="btn btn-sm custbadge" color="primary">
              New
            </Button>
          ),
          firstName: res.data.data.added_by?.firstName,
        });
        setFollowupTable(list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFollowupSubmit = () => {
    followupObject.enq_id = enqIdToBeShown;
    axios
      .post(`${API_URL}enquiry/add-followup`, followupObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Followup added successfully");
          followreset();
          getFollowupDataTable(enqIdToBeShown);
        } else {
          toastr.error("Failed to add followup");
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  const handleFollowupData = (e) => {
    setFollowupObject({
      ...followupObject,
      [e.target.name]: e.target.value,
    });
  };

  const handleFollowupSelectChange = (e, type) => {
    switch (type) {
      case "1":
        if (e.value == 2) {
          setFollowupObject({
            ...followupObject,
            follow_status: e.value,
            statusObj: e,
            showCourse: "block",
            showNext: "none",
            course: null,
            courseObj: null,
            mode: null,
            modeObj: null,
            amount: "",
            offer_amount: "",
            course_fee: "",
          });
        } else {
          setFollowupObject({
            ...followupObject,
            follow_status: e.value,
            statusObj: e,
            showCourse: "none",
            showNext: "block",
            course: null,
            courseObj: null,
            mode: null,
            modeObj: null,
            amount: "",
            offer_amount: "",
            course_fee: "",
          });
        }
        break;
      case "4":
        setFollowupObject({
          ...followupObject,
          source: e.value,
          sourceObj: e,
        });
        break;
      case "2":
        setFollowupObject({
          ...followupObject,
          course: e.value,
          courseObj: e,
          mode: null,
          modeObj: null,
          amount: "",
          offer_amount: "",
          course_fee: "",
        });
        if (e.mode == "2") {
          setModeOptions([
            { label: "Live", value: "1", amount: e.live_amount, offer_amount: e.live_offer_amount },
            { label: "Recorded", value: "0", amount: e.amount, offer_amount: e.offer_amount },
          ]);
        } else if (e.mode == "1") {
          setModeOptions([{ label: "Live", value: "1", amount: e.live_amount, offer_amount: e.live_offer_amount }]);
        } else if (e.mode == "0") {
          setModeOptions([{ label: "Recorded", value: "0", amount: e.amount, offer_amount: e.offer_amount }]);
        } else {
          setModeOptions([]);
        }
        break;
      case "3":
        setFollowupObject({
          ...followupObject,
          mode: e.value,
          modeObj: e,
          course_fee: e.offer_amount,
          amount: e.amount,
          offer_amount: e.offer_amount,
        });
        break;
    }
  };

  const followreset = () => {
    formRef1.current.reset();
    setFollowupObject({
      showCourse: "none",
      showNext: "block",
      status: 0,
      statusObj: { label: "New", value: 0 },
      course: null,
      courseObj: null,
      source: null,
      sourceObj: null,
      mode: null,
      modeObj: null,
      amount: "",
      offer_amount: "",
      course_fee: "",
    });
  };

  const reset = () => {
    formRef.current.reset();
    setSelectedQualification({});
    setSelectedState({});
    setSelectedDistrict({});
    setDistrictOptions([]);
    setSelectedSource({});
    setSelectedHeardFrom({});
    setSelectedCourse({});
    setselectedMobile(null);
    setEnquiryObject({ date_time: moment().format("YYYY-MM-DD HH:mm:ss"), comment: "", address: "" });
    setEnqIdToBeUpdated(null);
  };

  const searchreset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedStatus(null);

    getDataTable();
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 100,
      },
      {
        label: "Source",
        field: "source",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: datatable,
  };

  useEffect(() => {
    if (props.options.edit) {
      data.rows.map((row, idx) => {
        if (props.options.edit) {
          row.action = (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                    Admission
                  </Tooltip>
                }>
                {row.latest_follow_status === 2 ? (
                  <i className="fas fa-user-check" style={{ fontSize: "0.9em", cursor: "not-allowed", marginLeft: "7px" }}></i>
                ) : (
                  <i
                    className="fas fa-user-check"
                    style={{ fontSize: "0.9em", cursor: "pointer", marginLeft: "7px" }}
                    //onClick={() => addToAdmission(row)}
                    onClick={() => {
                      history.push("/new-admission/", {
                        row: row?._id,
                      });
                    }}></i>
                )}
              </OverlayTrigger>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(row);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  preUpdateEnquiry(row);
                  toTop();
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "0.9em", cursor: "pointer" }}
                onClick={() => {
                  setEnqIdToBeDeleted(row._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </>
          );
        }
      });
    } else {
      data.rows.map((row, idx) => {
        row.action = (
          <i
            className="fas fa-eye"
            style={{
              fontSize: "0.9em",
              cursor: "pointer",
              marginLeft: "0.1em",
              marginRight: "0.4em",
            }}
            onClick={() => {
              handleClickOpen(row);
            }}></i>
        );
      });
    }
  }, [props.options]);
  const followupdata = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      // {
      //     label: "Source",
      //     field: "source",
      //     sort: "asc",
      //     width: 150,
      // },
      // {
      //     label: "Next Follow",
      //     field: "next_follow_date",
      //     sort: "asc",
      //     width: 150,
      // },
      {
        label: "Comment",
        field: "comments",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      // {
      //     label: "Course",
      //     field: "course_name",
      //     sort: "asc",
      //     width: 150,
      // },
      // {
      //     label: "Mode",
      //     field: "mode",
      //     sort: "asc",
      //     width: 150,
      // },
      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 150,
      },
    ],
    rows: followupTable,
  };
  useEffect(() => {
    getQualifications();
    getStates();
    getSources();
    getHeardFrom();
    getCourses();
    getDataTable();
    searchProfile();
  }, []);
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}enquiry` + "/delete/" + enqIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status == 200) {
                  toastr.success("Enquiry deleted successfully");
                  setEnqIdToBeDeleted(null);
                  if (enqIdToBeUpdated && enqIdToBeUpdated.toString() == enqIdToBeDeleted.toString()) {
                    reset();
                  }
                  getDataTable();
                } else {
                  toastr.error("Something went wrong");
                  setEnqIdToBeDeleted(null);
                }
              })
              .catch((err) => {
                console.log(err);
                toastr.error("Something went wrong");
                setEnqIdToBeDeleted(null);
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal show={isOpenModal} size="xl" centered={true}>
        <div className="modal-header">
          <div className="modal-title">
            Enquiry <b>#{modalObject?.enq_unq_id}</b> On <b>{modalObject?.date_time}</b>
          </div>
          <button
            type="button"
            onClick={() => {
              setIsOpenModal(false);
              setEnqIdToBeShown(null);
              getDataTable();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <table id="enquiryModalTable">
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>
                        : {modalObject?.fname} {modalObject?.lname}
                      </td>
                      <td>
                        <b>Mobile</b>
                      </td>
                      <td>: {modalObject?.mobile}</td>
                      <td>
                        <b>Email</b>
                      </td>
                      <td>: {modalObject?.email}</td>
                      <td>
                        <b>Address</b>
                      </td>
                      <td colSpan={"2"}>: {modalObject?.address}</td>
                      {/* <td><b>Qualification</b></td><td>:{modalObject?.qualification_name}</td> */}
                    </tr>
                    <tr>
                      <td>
                        <b>City</b>
                      </td>
                      <td>: {modalObject?.city}</td>
                      <td>
                        <b>District</b>
                      </td>
                      <td>: {modalObject?.district_name}</td>
                      <td>
                        <b>State</b>
                      </td>
                      <td>: {modalObject?.state_name}</td>
                      <td>
                        <b>Pincode</b>
                      </td>
                      <td>: {modalObject?.pin}</td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              {props.options.edit && (
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef1}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleFollowupSubmit(e, v);
                      }}>
                      <Row>
                        <Col md="2">
                          <Label>Status</Label>
                          <Select
                            id="followupstatus"
                            name="followupstatus"
                            value={followupObject?.statusObj}
                            onChange={(selected) => {
                              handleFollowupSelectChange(selected, "1");
                            }}
                            options={followupOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                            menuPlacement="auto"
                          />
                        </Col>
                        <Col md="2" style={{ display: followupObject?.showCourse }}>
                          <Label>Course</Label>
                          <Select
                            id="followupcourse"
                            name="course"
                            value={followupObject?.courseObj}
                            onChange={(selected) => {
                              handleFollowupSelectChange(selected, "2");
                            }}
                            options={courseOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        <Col md="2" style={{ display: followupObject?.showCourse }}>
                          <Label>Mode</Label>
                          <Select
                            id="followupmode"
                            name="mode"
                            value={followupObject?.modeObj}
                            onChange={(selected) => {
                              handleFollowupSelectChange(selected, "3");
                            }}
                            options={modeOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        <Col md="2" style={{ display: followupObject?.showCourse }}>
                          <Label>
                            Fee{" "}
                            {followupObject?.amount ? (
                              <del class="text-muted me-2">
                                <i className="fas fa-rupee-sign"></i>
                                {followupObject?.amount}
                              </del>
                            ) : (
                              ""
                            )}{" "}
                            {followupObject?.offer_amount ? (
                              <>
                                <i className="fas fa-rupee-sign"></i>
                                {followupObject?.offer_amount}
                              </>
                            ) : (
                              ""
                            )}
                          </Label>
                          <AvField
                            style={{ display: followupObject?.showCourse }}
                            type="text"
                            className="form-control"
                            name="course_fee"
                            value={followupObject?.course_fee}
                            placeholder="Fee"
                            onChange={(e) => {
                              handleFollowupData(e);
                            }}
                          />
                        </Col>

                        <Col md="4">
                          <Label>Comments</Label>
                          <AvField
                            name="comments"
                            placeholder="Comments"
                            type="textarea"
                            className="form-control"
                            value={followupObject?.comment}
                            onChange={(selected) => {
                              handleFollowupData(selected);
                            }}
                            validate={{ required: { value: true } }}
                            errorMessage="Enter your comments"
                            rows="1"
                          />
                        </Col>
                        <Col md="2" style={{ marginTop: "26px" }}>
                          <Button color="primary" type="submit" style={{ marginRight: "10px" }}>
                            Submit
                          </Button>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => {
                              followreset();
                            }}>
                            Reset
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable id="followDataTableId" responsive bordered data={followupdata} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Manage Enquiry" />
          <Row>
            <Col md="12">
              {props.options?.edit && (
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      ref={formRef}
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}>
                      <Row>
                        <Col md="2">
                          <Label>Mobile</Label>
                          <CreatableSelect
                            searchable={true}
                            placeholder="Mobile"
                            openOnClick={false}
                            openMenuOnClick={false}
                            name="mobile"
                            id="reg_mobile"
                            value={selectedMobile}
                            options={searchMobileListOptions}
                            type="number"
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "7");
                            }}
                            onCreateOption={handleCreateMobile}
                            isClearable
                          />

                          <div className="text-danger" style={{ fontSize: "11px" }}>
                            {mobError != "" ? mobError : ""}
                          </div>
                        </Col>
                        <Col md="2">
                          <Label>Email</Label>
                          <AvField
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Email ID"
                            validate={{ required: { value: true } }}
                            errorMessage="Email ID is required"
                            onChange={handleChangeInput}
                            value={enquiryObject?.email}
                          />
                        </Col>
                        <Col md="2">
                          <Label>First Name</Label>
                          <AvField
                            name="fname"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            validate={{ required: { value: true } }}
                            errorMessage="First Name is required"
                            onChange={handleChangeInput}
                            value={enquiryObject?.fname}
                          />
                        </Col>
                        <Col md="2">
                          <Label>Last Name</Label>
                          <AvField
                            name="lname"
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            onChange={handleChangeInput}
                            value={enquiryObject?.lname}
                          />
                        </Col>

                        <Col md="2">
                          <Label>State</Label>
                          <Select
                            name="state"
                            //placeholder='Select...'
                            value={selectedState}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "2");
                            }}
                            options={stateOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        <Col md="2">
                          <Label>District</Label>
                          <Select
                            name="district"
                            //placeholder='Select...'
                            value={selectedDistrict}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "3");
                            }}
                            options={districtOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        <Col md="2" className="mt-2">
                          <Label>City</Label>
                          <AvField
                            name="city"
                            type="text"
                            className="form-control"
                            placeholder="City name"
                            // validate={{ required: { value: true } }}
                            // errorMessage="City is required"
                            onChange={handleChangeInput}
                            value={enquiryObject?.city}
                          />
                        </Col>
                        <Col md="2" className="mt-2">
                          <Label>Address</Label>
                          <AvField
                            name="address"
                            type="textarea"
                            className="form-control"
                            placeholder="Address"
                            // validate={{ required: { value: true } }}
                            // errorMessage="Address is required"
                            onChange={handleChangeInput}
                            value={enquiryObject?.address}
                            rows={1}
                          />
                        </Col>

                        <Col md="2" className="mt-2">
                          <Label>PIN</Label>
                          <AvField
                            name="pin"
                            type="number"
                            className="form-control"
                            placeholder="Pincode"
                            // minLength={6}
                            // maxLength={6}
                            // validate={{ required: { value: true } }}
                            // errorMessage="Pincode is required"
                            onChange={handleChangeInput}
                            value={enquiryObject?.pin}
                            onWheel={(e) => e.target.blur()}
                          />
                        </Col>
                        <Col md="2" className="mt-2">
                          <Label>Source</Label>
                          <Select
                            name="source"
                            //placeholder='Select...'
                            value={selectedSource}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "4");
                            }}
                            options={sourceOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                        <Col md="2" className="mt-2">
                          <Label>Heard From</Label>
                          <Select
                            name="heard_from"
                            //placeholder='Select...'
                            value={selectedHeardFrom}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              handleSelectChange(selected, "5");
                            }}
                            options={heardFromOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        {/* <Col md="3" className="mt-2" >
                                                  <Label>Interested Course</Label>
                                                  <Select
                                                      name='interest_course'
                                                      placeholder='Select...'
                                                      value={selectedCourse}
                                                      validate={{ required: { value: true } }}
                                                      onChange={(selected) => {
                                                          handleSelectChange(selected, '6')
                                                      }}
                                                      options={courseOptions}
                                                      classNamePrefix="select2-selection"
                                                      menuPosition="auto"
                                                  />
                                                  
                                              </Col> */}
                        <Col md="2" className="mt-2">
                          <Label>Comment</Label>
                          <AvField
                            name="comment"
                            type="textarea"
                            className="form-control"
                            placeholder="Comment"
                            // validate={{ required: { value: true } }}
                            // errorMessage="Comment is required"
                            onChange={handleChangeInput}
                            value={enquiryObject?.comment}
                            rows={1}
                          />
                        </Col>
                        <Col md="3" className="mt-3">
                          {enqIdToBeUpdated ? (
                            <div>
                              <Button className="mx-2" color="primary" disabled={enqError > 0 ? true : false} type="submit">
                                Update
                              </Button>
                              <Button className="mx-2" color="danger" type="reset" onClick={reset}>
                                Reset
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button className="mx-2" color="primary" disabled={enqError > 0 ? true : false} type="submit">
                                Save
                              </Button>

                              <Button className="mx-2" color="danger" type="reset" onClick={reset}>
                                Reset
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                          max={searchData?.to_date}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          onChange={handleDate}
                          min={searchData?.from_date}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Status</Label>
                        <Select
                          name="status"
                          value={selectedStatus}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "status");
                          }}
                          options={[
                            {
                              label: "New",
                              value: 0,
                            },
                            {
                              label: "Pending",
                              value: 1,
                            },
                            {
                              label: "Admitted",
                              value: 2,
                            },
                            {
                              label: "Cancelled",
                              value: 3,
                            },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "4px" }}>
                      <div className="mt-4 mb-2">
                        <Button color="danger" type="reset" onClick={() => searchreset()}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable id="enquiryTableId" responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Enquiry;
