/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Row,
  TabPane,
} from "reactstrap";
import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function LegalHistory(props) {
  let {  details, editable } = props;

  useEffect(() => {
    setmasterobj(details);
  }, [details]);

  const [masterobj, setmasterobj] = useState({});

  const handleInput = (e, mthd) => {
    let { name, value, type, } = e.target;
    if (mthd === 0) {
      if (type === "radio") {
        value = Number(value);
      }
      setmasterobj((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (mthd === 1) {
      setmasterobj((prev) => ({
        ...prev,
        trouble_with_law_det: {
          ...(prev?.trouble_with_law_det || {}),
          [name]: value,
        },
      }));
    }
  };

  function handleValidSubmit() {
    masterobj.admission_id = props.id;
    axios
      .post(`${API_URL}admission/legal-history`, masterobj, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success(res.data.message);
        props.handleToggleBar("increment");
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  }

  return (
    <TabPane tabId={8}>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
          handleValidSubmit();
        }}
      >
        <Col md="12">
          <Row>
            <Col md="6">
              <h6>1. Have you been arrested for sale of drug?</h6>
            </Col>
            <Col md="5">
              <p>
                Yes{" "}
                <input
                  disabled={!editable}
                  type="radio"
                  name="arrested_for_drug_sales"
                  style={{ marginLeft: "10px" }}
                  checked={
                    masterobj?.arrested_for_drug_sales == 1 ? true : false
                  }
                  value={1}
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp; No{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="arrested_for_drug_sales"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  checked={
                    masterobj?.arrested_for_drug_sales == 0 ? true : false
                  }
                  onClick={(e) => handleInput(e, 0)}
                />{" "}
                &nbsp;
              </p>
            </Col>
            <Col
              md="5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p style={{ marginLeft: "1rem" }}>No. of times</p>

              <AvField
               disabled={!editable}
                name="no_of_times_drg_sale_arst"
                type="text"
                className="form-control form-control"
                onChange={(e) => handleInput(e, 0)}
                value={masterobj?.no_of_times_drg_sale_arst}
              />
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-2">
          <Row>
            <Col md="6">
              <h6>2. Have you been arrested for possession of drugs?</h6>
            </Col>
            <Col md="5">
              <p>
                Yes{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e, 0)}
                  checked={masterobj?.arrested_for_drug_psn == 1 ? true : false}
                  name="arrested_for_drug_psn"
                />{" "}
                &nbsp; No{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  name="arrested_for_drug_psn"
                  onClick={(e) => handleInput(e, 0)}
                  checked={masterobj?.arrested_for_drug_psn == 0 ? true : false}
                />{" "}
                &nbsp;
              </p>
            </Col>
            <Col
              md="5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p style={{ marginLeft: "1rem" }}>No. of times</p>

              <AvField
               disabled={!editable}
                name="no_of_times_drg_psn_arst"
                type="text"
                className="form-control form-control"
                onChange={(e) => handleInput(e, 0)}
                value={masterobj?.no_of_times_drg_psn_arst}
              />
            </Col>
          </Row>
        </Col>

        <Col md="12" className="mt-3">
          <Row>
            <Col md="6">
              <h6>3. Have you got into trouble with law for the following?</h6>
            </Col>
            <Col md="5">
              <p>
                Yes{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="trouble_with_law"
                  style={{ marginLeft: "10px" }}
                  value={1}
                  onClick={(e) => handleInput(e, 0)}
                  checked={masterobj?.trouble_with_law == 1 ? true : false}
                />{" "}
                &nbsp; No{" "}
                <input
                 disabled={!editable}
                  type="radio"
                  name="trouble_with_law"
                  style={{ marginLeft: "10px" }}
                  value={0}
                  onClick={(e) => handleInput(e, 0)}
                  checked={masterobj?.trouble_with_law == 0 ? true : false}
                />{" "}
                &nbsp;
              </p>
            </Col>
            <Col
              md="6"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p style={{ marginLeft: "1rem" }}>
                Arrested for drunken/drug influenced behaviour
              </p>

              <AvField
               disabled={!editable}
                name="arstd_fr_drk_bhvr"
                type="text"
                className="form-control form-control"
                style={{ width: "10rem" }}
                onChange={(e) => handleInput(e, 1)}
                value={masterobj?.trouble_with_law_det?.arstd_fr_drk_bhvr}
              />
            </Col>
            <Col
              md="6"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p style={{ marginLeft: "1rem" }}>Fined for drunken driving</p>

              <AvField
               disabled={!editable}
                name="finded_drnk_drv"
                type="text"
                className="form-control form-control"
                style={{ width: "10rem" }}
                onChange={(e) => handleInput(e, 1)}
                value={masterobj?.trouble_with_law_det?.finded_drnk_drv}
              />
            </Col>
            <Col
              md="12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p style={{ marginLeft: "1rem" }}>
                Had an accident(even minor) while driving under the influence of
                alcohol/drug
              </p>

              <AvField
               disabled={!editable}
                name="acdnt_drnk_drvng"
                type="text"
                className="form-control form-control"
                style={{ width: "10rem" }}
                onChange={(e) => handleInput(e, 1)}
                value={masterobj?.trouble_with_law_det?.acdnt_drnk_drvng}
              />
            </Col>
            {/* <Col
              md="6"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p style={{ marginLeft: "1rem" }}>
                Under the influence of alcohol/drugs
              </p>

              <AvField
                name="relationship"
                type="text"
                className="form-control form-control"
                style={{width:"10rem"}}
                onChange={handletrbleques}


              />
            </Col> */}
            <Col
              className="mt-3"
              md="6"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p style={{ marginLeft: "1rem" }}>Assault</p>

              <AvField
               disabled={!editable}
                name="assault"
                type="text"
                className="form-control form-control"
                style={{ width: "10rem" }}
                onChange={(e) => handleInput(e, 1)}
                value={masterobj?.trouble_with_law_det?.assault}
              />
            </Col>
            <Col
              md="6"
              className="mt-3"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p style={{ marginLeft: "1rem" }}>Anyother</p>

              <AvField
               disabled={!editable}
                name="any_other"
                type="text"
                className="form-control form-control"
                style={{ width: "10rem" }}
                onChange={(e) => handleInput(e, 1)}
                value={masterobj?.trouble_with_law_det?.any_other}
              />
            </Col>
          </Row>
        </Col>

        <Row className="mt-4">
          <Col md={12} className="submit-btn-fixed">
            <FormGroup className="float-right ">
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("decrement")}
              >
                <i class="fas fa-solid fa-less-than"></i>
              </a>
              <button type="submit" className="save-continue-btn m-2">
                Save and continue{" "}
                <i
                  style={{ fontSize: "13px" }}
                  className="mdi mdi-arrow-right"
                ></i>
              </button>
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("increment")}
              >
                <i class="fas fa-solid fa-greater-than"></i>
              </a>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </TabPane>
  );
}

export default LegalHistory;
