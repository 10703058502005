/* eslint-disable */
import React, { useEffect, useState } from "react";
import {  Row, Col, CardBody, Card, Label, FormGroup, Modal } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { deleteStaff, getDistrict, getStaffDetails, getState, onSubmit, updateStaff } from "./api";
import toastr from "toastr";
import "./styles.scss";
import { useRef } from "react";
import { MDBDataTable } from "mdbreact";
import DeleteAlert from "../../components/Common/DeleteAlert";
import moment from "moment";

const Staffs = (props) => {
  const [state, setState] = useState([]);
  const [errors, setErrors] = useState({});
  const [reload, setReload] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [viewValue, setViewValue] = useState(null);
  const [modalCenter, setmodalCenter] = useState(false);
  const [masterObject, setMasterObject] = useState({});
  const [updatedValue, setUpdatedValue] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState({ show: false });
  const formRef = useRef();

  useEffect(() => {
    getState().then((data) => {
      setState(data);
    });
  }, []);

  useEffect(() => {
    getStaffDetails().then((res) => {
      let data = res.data;
      data.map((item, idx) => {
        item.id = idx + 1;
        item.fullname = item?.first_name + " " + item?.last_name;
        item.mobile_no = (
          <a className="table-link-hover" href={`tel:+${item?.mobile}`}>
            {item?.mobile}
          </a>
        );
        if (item.district === undefined) {
          item.district = {};
        }
        if (item.state === undefined) {
          item.state = {};
        }
        item.district.value = item?.district?._id;
        item.district.label = item?.district?.district_name;
        item.state.value = item?.state?._id;
        item.state.label = item?.state?.name;
        item.districts = item?.district?.district_name || "";
        item.states = item?.state?.name || "";

        if (item.resigning_date) {
          item.resigning_date1 = moment(item?.resigning_date || "").format("DD-MM-YYYY");
        } else item.resigning_date1 = "____";
        if (item.joining_date) {
          item.joining_date1 = moment(item?.joining_date || "").format("DD-MM-YYYY");
        } else item.joining_date1 = "____";

        if (props.options.edit) {
          item.action = (
            <span>
              <i
                className="far fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  setViewValue(item);
                  togCenter();
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  updateValue(item);
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setConfirmDeleteAlert({ show: true, id: item._id });
                }}></i>
            </span>
          );
        } else {
          item.action = (
            <span>
              <i
                className="far fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  setViewValue(item);
                  togCenter();
                }}></i>
            </span>
          );
        }

        return item
      });
      setTableData(data);
    });
  }, [reload]);

  useEffect(() => {
    getDistrict(selectedState?.value).then((data) => setDistricts(data));
  }, [selectedState]);

  const handleChange = (e) => {
    let { value, name } = e.target;
    setMasterObject((preVal) => ({ ...preVal, [name]: value }));
  };

  const handleSelect = (e, name) => {
    if (e === null) {
      setSelectedDistrict(null);
      setSelectedState(null);
      setMasterObject((preVal) => ({ ...preVal, state: null, district: null }));
    } else {
      setMasterObject((preVal) => ({ ...preVal, [name]: e.value }));
    }
  };

  const handleSubmit = (data) => {
    if (!data.state) {
      setErrors((prev) => ({ ...prev, state: true }));
      return;
    }
    if (!data.district) {
      setErrors((prev) => ({ ...prev, district: true }));
      return;
    }
    onSubmit(data).then((data) => {
      if (data.status === 200) {
        setReload((prev) => !prev);
        setSelectedDistrict(null);
        setSelectedState(null);
        toastr.success(data.message);
        handleReset();
      } else toastr.error(data.message);
    });
  };

  const updateValue = (data) => {
    setUpdatedValue(data);
    setSelectedDistrict(data?.district);
    setSelectedState(data?.state);
    setMasterObject(data);
  };

  const handleReset = () => {
    console.log("clicked");
    setMasterObject({ address: "" });
    setSelectedDistrict(null);
    setUpdatedValue(null);
    setSelectedState(null);
    formRef.current.reset();
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleUpdate = (data) => {
    data.district = data?.district || data?.district?._id;
    data.state = data?.state || data?.state?._id;
    data.staff_id = data?._id;
    if (!data.state) {
      setErrors((prev) => ({ ...prev, state: true }));
      return;
    }
    if (!data.district) {
      setErrors((prev) => ({ ...prev, district: true }));
      return;
    }
    deleteValuesObject(data, ["action", "addedBy", "createdAt", "created_time", "districts", "id", "ip", "states", "unique_id", "updatedAt", "__v", "status"]);
    updateStaff(data).then((res) => {
      if (res.status === 200) {
        toastr.success(res.message);
        setReload((prev) => !prev);
        setMasterObject((prev) => {});
        setUpdatedValue(null);
        setSelectedDistrict(null);
        setSelectedState(null);
        handleReset();
      }
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Name",
        field: "fullname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pincode",
        field: "pin",
        sort: "asc",
        width: 270,
      },
      {
        label: "State",
        field: "states",
        sort: "asc",
        width: 270,
      },
      {
        label: "District",
        field: "districts",
        sort: "asc",
        width: 270,
      },
      {
        label: "Join Date",
        field: "joining_date1",
        sort: "asc",
        width: 270,
      },
      {
        label: "Ressign Date",
        field: "resigning_date1",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };
  useEffect(() => {
    data.rows.map((item) => {
      if (props.options.edit) {
        item.action = (
          <span>
            <i
              className="far fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                setViewValue(item);
                togCenter();
              }}></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
              onClick={() => {
                toTop();
                updateValue(item);
              }}></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                setConfirmDeleteAlert({ show: true, id: item._id });
              }}></i>
          </span>
        );
      } else {
        item.action = (
          <span>
            <i
              className="far fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                setViewValue(item);
                togCenter();
              }}></i>
          </span>
        );
      }
    });
  }, [props.options]);

  const deleteValuesObject = (object, keywords = []) => {
    keywords.forEach((keyword) => {
      delete object[keyword];
    });
  };

  let deleteToggle = () => {
    setConfirmDeleteAlert({
      ...confirmDeleteAlert,
      show: !confirmDeleteAlert.show,
    });
  };

  let handleDeleteStaff = (id) => {
    deleteStaff(id).then((data) => {
      if (data.status === 200) {
        toastr.success(data.message);
        setMasterObject({ address: "" });
        setUpdatedValue(null);
        setSelectedDistrict(null);
        setSelectedState(null);
        setConfirmDeleteAlert({ show: false });
        setReload((prev) => !prev);
        handleReset();
      }
    });
  };
  const togCenter = () => {
    setmodalCenter(!modalCenter);
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const handleKey = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38) {
      e.preventDefault();
    }
  };

  return (
    <>
      <DeleteAlert name="Certificate Delete" onConfirm={handleDeleteStaff} toggle={deleteToggle} data={confirmDeleteAlert} />
      <Modal
        isOpen={modalCenter}
        toggle={() => {
          togCenter();
        }}
        centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">{viewValue?.unique_id}</h5>
          <button
            type="button"
            onClick={() => {
              setmodalCenter(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <table id="viewTable">
            <tbody>
              <tr>
                <td className="view-table pb-2">Firstname</td>
                <td className="view-table pb-2 table-bold">{viewValue?.first_name}</td>
              </tr>
              <tr>
                <td className="view-table pb-2">Lastname</td>
                <td className="view-table pb-2 table-bold">{viewValue?.last_name}</td>
              </tr>
              <tr>
                <td className="view-table pb-2">State</td>
                <td className="view-table pb-2 table-bold">{viewValue?.states}</td>
              </tr>
              <tr>
                <td className="view-table pb-2">Address</td>
                <td className="view-table pb-2 table-bold">{viewValue?.address}</td>
              </tr>
              <tr>
                <td className="view-table pb-2">Mobile</td>
                <td className="view-table pb-2 table-bold">{viewValue?.mobile_no}</td>
              </tr>
              <tr>
                <td className="view-table pb-2">Pin</td>
                <td className="view-table pb-2 table-bold">{viewValue?.pin}</td>
              </tr>
              <tr>
                <td className="view-table pb-2">Join Date</td>
                <td className="view-table pb-2 table-bold">{viewValue?.joining_date ? moment(viewValue?.joining_date).format("DD-MM-YYYY") : null}</td>
              </tr>
              {viewValue?.resigning_date ? (
                <tr>
                  <td className="view-table pb-2">Resigning Date</td>
                  <td className="view-table pb-2 table-bold">{viewValue?.resigning_date ? moment(viewValue?.resigning_date).format("DD-MM-YYYY") : null}</td>
                </tr>
              ) : null}

              <tr>
                <td className="view-table pb-2">Added by</td>
                <td className="view-table pb-2 table-bold">{viewValue?.addedBy?.firstName}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Staffs" />
          {props.options.edit && (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm ref={formRef} onValidSubmit={() => (!updatedValue ? handleSubmit(masterObject) : handleUpdate(masterObject))}>
                      <Row>
                        <Col md={4} lg={5} xl={4}>
                          <Row>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="name">Firstname</Label>
                                <AvField
                                  //   disabled={!editable}
                                  name="first_name"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter firstname"
                                  value={masterObject?.first_name}
                                  onChange={handleChange}
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="lastname">Lastname</Label>
                                <AvField
                                  //   disabled={!editable}
                                  name="last_name"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter lastname"
                                  value={masterObject?.last_name}
                                  onChange={handleChange}
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={3} lg={3} xl={3}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="name">Address</Label>
                            <AvField
                              //   disabled={!editable}
                              name="address"
                              type="textarea"
                              rows={1}
                              className="form-control"
                              placeholder="Enter address"
                              value={masterObject?.address}
                              onChange={handleChange}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={5} lg={4} xl={5}>
                          <Row>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="name">State</Label>
                                <Select
                                  className="basic-single "
                                  classNamePrefix="select"
                                  menuPosision="fixed"
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      height: "35px",
                                      minHeight: "35px",
                                      borderColor: `${errors.state ? "#f46a6a" : "#cccccc"}`,
                                    }),
                                  }}
                                  isClearable={true}
                                  value={selectedState}
                                  name="state"
                                  options={state}
                                  onFocus={() => setErrors((prev) => ({ ...prev, state: false }))}
                                  onChange={(e) => {
                                    setSelectedState(e);
                                    handleSelect(e, "state");
                                  }}
                                />
                                {errors.state && (
                                  <span
                                    style={{
                                      width: "100%",
                                      marginTop: "-0.9rem",
                                      fontSize: "87.5%",
                                      color: "#f46a6a",
                                    }}>
                                    This field is invalid
                                  </span>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="name">District</Label>
                                <Select
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      height: "35px",
                                      minHeight: "35px",
                                      borderColor: `${errors.district ? "#f46a6a" : "#cccccc"}`,
                                    }),
                                  }}
                                  className="basic-single "
                                  classNamePrefix="select"
                                  menuPosision="fixed"
                                  // isClearable={true}
                                  value={selectedDistrict}
                                  name="qualification"
                                  onFocus={() =>
                                    setErrors((prev) => ({
                                      ...prev,
                                      district: false,
                                    }))
                                  }
                                  options={districts}
                                  onChange={(e) => {
                                    setSelectedDistrict(e);
                                    handleSelect(e, "district");
                                  }}
                                />
                                {errors.district && (
                                  <span
                                    style={{
                                      width: "100%",
                                      marginTop: "-0.9rem",
                                      fontSize: "87.5%",
                                      color: "#f46a6a",
                                    }}>
                                    This field is invalid
                                  </span>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={5} lg={5} xl={4}>
                          <Row>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="mobile">Mobile</Label>
                                <AvField
                                  name="mobile"
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter mobile no"
                                  value={masterObject?.mobile}
                                  onChange={handleChange}
                                  onWheel={(e) => e.target.blur()}
                                  onKeyDown={handleKey}
                                  validate={{
                                    required: { value: true },
                                    minLength: { value: 10 },
                                    maxLength: { value: 10 },
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="lastname">Pincode</Label>
                                <AvField
                                  name="pin"
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter pincode"
                                  value={masterObject?.pin}
                                  onWheel={(e) => e.target.blur()}
                                  onKeyDown={handleKey}
                                  onChange={handleChange}
                                  validate={{
                                    required: { value: true },
                                    minLength: { value: 5 },
                                    maxLength: { value: 6 },
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={5} lg={7} xl={4}>
                          <Row>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="mobile">Joining</Label>
                                <AvField
                                  name="joining_date"
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter mobile no"
                                  value={masterObject?.joining_date}
                                  onChange={handleChange}
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="lastname">Resigning</Label>
                                <AvField
                                  //   disabled={!editable}
                                  name="resigning_date"
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter pincode"
                                  value={masterObject?.resigning_date}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={5} xl={3} style={{ gap: "1.2rem" }} className="d-flex my-auto mb-3">
                          {!updatedValue ? (
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          ) : (
                            <button type="submit" className="btn btn-warning">
                              Update
                            </button>
                          )}

                          <button
                            onClick={() => {
                              handleReset();
                            }}
                            type="reset"
                            className="btn btn-danger">
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Card>
              <CardBody>
                <MDBDataTable className="text-center" id="staff-data-table" responsive bordered data={data} />
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Staffs;
