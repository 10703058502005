/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { apiError, getUsersProfile } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { getMasterBranch } from "../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import "./user.scss";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const Users = (props) => {
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [userObject, setUserObject] = useState({});
  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [usersForTable, setUsersForTable] = useState([]);
  const [userId, setUserId] = useState(1);
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);
  const [privilegeOptions, setPrivilegeOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [uploadProgress, setUploadProgress] = useState();
  // const [currentBranch, setCurrectBranch] = useState(getMasterBranch());
  // const { companiesMasterOptions } = useSelector((state) => state.companies);
  // const [currentCompany, setCurrectCompany] = useState(getCompany());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [errors, setErrors] = useState({
    privilegeError: "",
    branchError: "",
  });
  const dispatch = useDispatch();
  let history = useHistory();
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
    fetch_all_privilege();
    fetchbranches();
  }, []);

  // useEffect(() => {
  //   let data = [];

  //   companiesMasterOptions.data &&
  //     companiesMasterOptions.data.map((el) => {
  //       if (el?._id === currentCompany) {
  //         let val = {};
  //         val.label = el.company_name;
  //         val.value = el._id;
  //         data.push(val);
  //       }
  //     });

  //   setCompanyOptions([
  //     {
  //       options: data,
  //     },
  //   ]);
  // }, [companiesMasterOptions]);

  function handleTableData() {
    var url = `${API_URL}user`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        result.map((item, index) => {
          item.id = index + 1;

          if (item?.lastName) {
            item.name1 = item?.firstName + " " + item?.lastName;
          } else {
            item.name1 = item?.firstName;
          }
          item.privilage1 = item.privilage && item.privilage.privilege_name;

          // if (item.app_user === 1) {
          //   item.appUser = (
          //     <div style={{ display: "flex", justifyContent: "center" }}>
          //       <i
          //         className="uil-android"
          //         style={{
          //           fontSize: "1.5em",
          //           cursor: "pointer",
          //           marginLeft: "0.2em",
          //           marginRight: "0.5em",
          //           color: "green",
          //         }}
          //       ></i>
          //     </div>
          //   );
          // }
          item.date = moment(item.date).format("DD-MM-YYYY");
          if (item?.updated_by) {
            let updatedBy = item?.updated_by || {};
            if (updatedBy?.lastName) item.staff = updatedBy?.firstName + " " + updatedBy?.lastName;
            else item.staff = updatedBy?.firstName;
          } else {
            let addedBy = item?.created_by || {};
            if (addedBy?.lastName) item.staff = addedBy?.firstName + " " + addedBy?.lastName;
            else item.staff = addedBy?.firstName;
          }

          if (item?.status === 0) {
            item.block_status = (
              <>
                <LockOpenIcon
                  style={{
                    color: "green",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => updateStatus(item)}
                />
              </>
            );
          } else if (item?.status === 2) {
            item.block_status = (
              <>
                <LockOpenIcon
                  style={{
                    color: "red",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => updateStatus(item)}
                />
              </>
            );
          }

          if (props.options.edit) {
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-key"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    setShowModal(true);
                    setUserIdToBeUpdated(item._id);
                  }}></i>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    history.push("/user/" + item.userId);
                    //console.log("11",item.userId)
                  }}></i>

                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateUser(item);
                  }}></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setUserIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </div>
            );
          } else {
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    history.push("/user/" + item.userId);
                    //console.log("11",item.userId)
                  }}></i>
              </div>
            );
          }
        });

        setUsersForTable(result);
      });
  }
  const fetchbranches = () => {
    axios
      .get(`${API_URL}branch/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let branch_data =
          res.data.data &&
          res.data.data.map((branch) => {
            return {
              label: branch?.name,
              value: branch?._id,
            };
          });
        setBranchOptions([
          {
            options: branch_data,
          },
        ]);
      });
  };
  const updateStatus = (item) => {
    axios
      .put(`${API_URL}security/userblock?user_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.status === 0) {
            toastr.success("User unblocked successfully");
          } else toastr.success("User blocked successfully");

          handleTableData();
        } else {
          toastr.error("Failed");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  function fetch_all_privilege() {
    axios
      .get(`${API_URL}privilage/items`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var privilege_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.privilege_name,
              value: el._id,
            };
          });

        setPrivilegeOptions([
          {
            options: privilege_data,
          },
        ]);
      });
  }

  useEffect(() => {
    setUserObject({ ["addedby"]: 1 });
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      //console.log(obj);

      setUserId(obj._id);
      setUserObject({
        ...userObject,
        ["addedby"]: userId,
      });
    }
  }, [userId]);

  let preUpdateUser = (item) => {
    axios
      .get(`${API_URL}user/single/?user_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        // result &&
        //   result.map((item) => {
        result.user_id = result?._id;
        let privilegeData = result.privilage || {};
        let privilege = {
          label: privilegeData?.privilege_name,
          value: privilegeData?._id,
        };
        setSelectedPrivilege(privilege);
        let branchData = result.branch || {};
        let branch = {
          label: branchData?.name,
          value: branchData?._id,
        };
        setSelectedBranch(branch);

        setUserIdToBeUpdated(result._id);
        setUserObject({ ...result, password: null });
        // });
      });
  };

  // let preUpdateUserPassword = (item) => {
  //   setUserIdToBeUpdated(item._id);
  //   setShowModal(true);
  // };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "User ID",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name1",
        sort: "asc",
        width: 400,
      },

      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 100,
      },
      {
        label: "Privilege",
        field: "privilage1",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Company",
      //   field: "company1",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "block_status",
        sort: "asc",
        width: 50,
      },
      // {
      //   label: "App User",
      //   field: "appUser",
      //   sort: "asc",
      //   width: 50,
      // },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: usersForTable,
  };

  useEffect(() => {
    data.rows.map((item) => {
      if (props.options.edit) {
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-key"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                setShowModal(true);
                setUserIdToBeUpdated(item._id);
              }}></i>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                history.push("/user/" + item.userId);
                //console.log("11",item.userId)
              }}></i>

            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                toTop();
                preUpdateUser(item);
              }}></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                setUserIdToBeDeleted(item._id);
                setConfirmDeleteAlert(true);
              }}></i>
          </div>
        );
      } else {
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                history.push("/user/" + item.userId);
                //console.log("11",item.userId)
              }}></i>
          </div>
        );
      }
    });
  }, [props.options]);

  function handleChangeUser(e) {
    let name = e.target.name;
    let value = e.target.value;
    setUserObject({ ...userObject, [name]: value });
  }

  // function handleSelectedPrivilage(value) {
  //   let userobject = userObject;
  //   //console.log("userObject: ", userObject);
  //   if (value.label === "Admin" || value.label === "Super Admin") {
  //     setSelectedBranch(null);
  //     userobject.local_body = [];
  //     userobject.ward = [];
  //     userobject.user_group = [];
  //   }
  //   if (value.label === "Operator Admin" || value.label === "Reporter Admin") {
  //     setSelectedBranch(null);
  //     setShowDistrict(false);
  //     userobject.local_body = [];
  //     userobject.ward = [];
  //     userobject.user_group = [];
  //     setShowLocalbody(false);
  //   } else {
  //     setShowLocalbody(true);
  //     setShowDistrict(true);
  //   }
  //   if (
  //     value.label === "Helper" ||
  //     value.label === "Team Leader" ||
  //     value.label === "Driver" ||
  //     value.label === "Supervisor"
  //   ) {
  //     setShowGroup(true);
  //     setShowWard(true);
  //     setSelectedGroup(null);
  //     setSelectedBranch(null);
  //     setSelectedWard(null);
  //     userobject.local_body = [];
  //     userobject.ward = [];
  //     userobject.user_group = [];
  //   } else {
  //     setShowGroup(false);
  //   }
  //   if (value.label === "Operator Admin" || value.label === "Reporter Admin") {
  //     setShowLocalbodyMulti(true);
  //   } else {
  //     setSelectedBranchMulti(null);
  //     setShowLocalbodyMulti(false);
  //   }
  //   if (
  //     value.label === "Helper" ||
  //     value.label === "Team Leader" ||
  //     value.label === "Driver" ||
  //     value.label === "Ward Member" ||
  //     value.label === "Supervisor"
  //   ) {
  //     setShowWard(true);
  //     setSelectedWard(null);
  //     setSelectedBranch(null);
  //     userobject.local_body = [];
  //     userobject.ward = [];
  //     userobject.user_group = [];
  //   } else setShowWard(false);
  //   if (value.label === "Health Inspector") {
  //     setSelectedBranch(null);
  //     setShowWardMulti(true);
  //     setShowWard(false);
  //     userobject.local_body = [];
  //     userobject.ward = [];
  //     userobject.user_group = [];
  //     setSelectedWard(null);
  //   } else {
  //     setSelectedWardMulti(null);
  //     setShowWardMulti(false);
  //   }
  //   if (value.label === "Chairman" || value.label === "Secretary") {
  //     setSelectedWard(null);
  //     setSelectedGroup(null);
  //     userobject.ward = [];
  //     userobject.user_group = [];
  //   }
  //   // let errorVal = errors;
  //   if (
  //     value.label === "Helper" ||
  //     value.label === "Team Leader" ||
  //     value.label === "Driver" ||
  //     value.label === "Supervisor"
  //   ) {
  //     setShowWard(false);
  //     setShowGroup(false);
  //     setSelectedWard(null);
  //     setSelectedGroup(null);
  //     setShowgroup(true);
  //     userobject.ward = [];
  //     userobject.user_group = [];
  //     if (selectedgroup === null) {
  //       errorVal.groupError = "Please select group";
  //     }
  //     if (selectedWardMultiLocal === null) {
  //       errorVal.wardError = "Please select ward";
  //     }
  //   } else {
  //     setShowgroup(false);
  //     errorVal.groupError = "";
  //     errorVal.wardError = "";
  //     setSelectedgroup(null);
  //     setSelectedWardMultiLocal(null);
  //     setWardMultiOptionsLocal([]);
  //   }
  //   errorVal.privilegeError = "";
  //   setSelectedPrivilege(value);
  //   setErrors({
  //     ...errorVal,
  //   });
  //   if (userIdTobeUpdated) {
  //     setUserObject({
  //       ...userObject,
  //       userobject,
  //       ["privilage_value"]: value.value,
  //     });
  //   } else
  //     setUserObject({ ...userObject, userobject, ["privilage"]: value.value });
  // }

  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  const handleValidSubmit = (event, values) => {
    if (selectedPrivilege === null) {
      let errorValue = errors;
      if (selectedPrivilege === null) {
        errorValue.privilegeError = "Please select privilege";
      }
      // if (selectedBranch === null) {
      //   errorValue.branchError = "Please select branch";
      // }
      setErrors({
        ...errorValue,
      });
    } else {
      if (userIdTobeUpdated) {
        axios
          .put(`${API_URL}user`, userObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            //console.log("111")
            if (res.data.success === true) {
              let result = res.data.data;
              //console.log("result",result)
              toastr.success("User updated successfully");
              setShowModal(false);
              setUserIdToBeUpdated(null);
              setPasswordObject({});
              handleTableData();
              setSelectedBranch(null);
              setSelectedPrivilege(null);
              setUserObject({
                firstName: "",
                lastName: "",
                email: "",
                username: "",
                mobile: "",
                salary: "",
                password: "",
                address: "",
                user_dob: "",
                user_joining_date: "",
                user_resigning_date: "",
              });
              formRef.current.reset();
              setErrors({
                privilegeError: "",
                branchError: "",
              });
              if (userId === result._id) {
                dispatch(getUsersProfile(userId));
              }
            } else {
              toastr.error("Failed to update user");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}user`, userObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            //console.log("res: ", res);
            if (res.data.success === true) {
              let result = res.data.data;
              toastr.success("User created successfully");
              handleTableData();
              formRef.current.reset();
              setSelectedBranch(null);
              setSelectedPrivilege(null);
              setPasswordObject({});
              setUserObject({
                firstName: "",
                lastName: "",
                email: "",
                username: "",
                mobile: "",
                salary: "",
                password: "",
                address: "",
                user_dob: "",
                user_joining_date: "",
                user_resigning_date: "",
              });
              setErrors({
                privilegeError: "",
                branchError: "",
              });
              if (userId === result._id) {
                let data = {
                  user_id: userId,
                };
                dispatch(getUsersProfile(data));
              }
            } else {
              toastr.error("Failed to create user");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  const handleValidSubmitPassword = () => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        user_id: userIdTobeUpdated,
        password: passwordObject?.password,
      };
      axios
        .post(`${API_URL}user/reset_password`, item, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            setPasswordObject({
              password: "",
              confirmPassword: "",
            });
            closeModal();
            formRef.current.reset();
          } else {
            toastr.error(res.data.message);
            return;
          }
        });
    } else {
      toastr.error("Passwords are not matching");
    }
  };

  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
    setPasswordObject({
      password: "",
      confirmPassword: "",
    });
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("user_image", e.target.files[0]);
    axios
      .post(`${API_URL}user/image`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setUserObject({
            ...userObject,
            [`user_image`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const uploadDocument = (e) => {
    const fd = new FormData();
    fd.append("user_image", e.target.files[0]);
    axios
      .post(`${API_URL}user/image`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setUserObject({
            ...userObject,
            [`user_documents`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteUserImage = () => {
    setUserObject({
      ...userObject,
      [`user_image`]: "",
    });
  };
  const deleteUserDocument = () => {
    setUserObject({
      ...userObject,
      [`user_documents`]: "",
    });
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "privilege":
        setSelectedPrivilege(selected);
        setUserObject({
          ...userObject,
          privilage: selected.value,
        });
        setErrors({
          ...errors,
          privilegeError: "",
        });
        break;
      case "branch":
        setSelectedBranch(selected);
        setUserObject({
          ...userObject,
          branch: selected.value,
        });
        setErrors({
          ...errors,
          branchError: "",
        });
        break;
      default:
        break;
    }
  };
  const handleDate = (e) => {
    setUserObject({
      ...userObject,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}user` + "/" + userIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("User deleted successfully");
                  if (userObject && userObject?._id === userIdToBeDeleted) {
                    setShowModal(false);
                    setUserIdToBeUpdated(null);
                    setPasswordObject({});
                    setSelectedBranch(null);
                    setSelectedPrivilege(null);
                    setUserObject({
                      firstName: "",
                      lastName: "",
                      email: "",
                      username: "",
                      mobile: "",
                      salary: "",
                      password: "",
                      address: "",
                      user_dob: "",
                      user_joining_date: "",
                      user_resigning_date: "",
                    });
                  }
                  setErrors({
                    privilegeError: "",
                    branchError: "",
                  });
                  handleTableData();
                } else {
                  toastr.error("Failed to delete user");
                  return;
                }
              });

            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal
        show={showModal}
        toggle={() => {
          closeModal();
        }}
        centered={true}
        size="md">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Reset Password</h5>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleValidSubmitPassword(e, v);
          }}>
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type="password"
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.password}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Confirm Password</Label>
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage=" Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.confirmPassword}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="btn btn-dark" style={{ marginRight: "1rem" }} onClick={closeModal}>
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Users" />
          {props.options.edit && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}>
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">First name</Label>
                            <AvField
                              name="firstName"
                              placeholder="First name"
                              type="text"
                              errorMessage="Enter First Name"
                              className="form-control"
                              validate={{ pattern: { value: "^[A-Za-z]+$", errorMessage: "Enter valid firstname" } }}
                              id="validationCustom01"
                              value={userObject.firstName}
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Last name</Label>
                            <AvField
                              name="lastName"
                              placeholder="Last name"
                              type="text"
                              className="form-control"
                              validate={{ pattern: { value: "^[A-Za-z]+$", errorMessage: "Enter valid lastname" } }}
                              id="validationCustom02"
                              value={userObject.lastName}
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Email</Label>
                            <AvField
                              name="email"
                              placeholder="Email"
                              type="email"
                              errorMessage="Enter valid Email"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={userObject.email}
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Username</Label>
                            <AvField
                              name="username"
                              placeholder="Username"
                              type="text"
                              errorMessage="Enter valid Username"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={userObject.username}
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom04">Mobile</Label>
                            <AvField
                              name="mobile"
                              placeholder="Mobile"
                              type="number"
                              errorMessage="Please provide a valid mobile."
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom04"
                              value={userObject.mobile}
                              onChange={handleChangeUser}
                              minLength={10}
                              maxLength={10}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">DOB</Label>
                            <input className="form-control" type="date" id="user_dob" name="user_dob" value={userObject?.user_dob} onChange={handleDate} />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Date Of Joining</Label>
                            <input
                              className="form-control"
                              type="date"
                              id="user_joining_date"
                              name="user_joining_date"
                              value={userObject?.user_joining_date}
                              onChange={handleDate}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Date Of Resigning</Label>
                            <input
                              className="form-control"
                              type="date"
                              id="user_resigning_date"
                              name="user_resigning_date"
                              value={userObject?.user_resigning_date}
                              onChange={handleDate}
                              min={userObject?.user_joining_date}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Privilege</Label>
                            <Select
                              name="privilage"
                              value={selectedPrivilege}
                              onChange={(value) => {
                                handleSelectChange(value, "privilege");
                              }}
                              options={privilegeOptions}
                              classNamePrefix="select2-selection"
                            />
                            <p className="text-danger" style={{ fontSize: "11px" }}>
                              {selectedPrivilege === null ? errors.privilegeError : ""}
                            </p>
                          </div>
                        </Col>
                        {/* <Col md={3}>
              <div className="mb-3">
                <Label>Branch</Label>
                <Select
                  name="branch"
                  value={selectedBranch}
                  onChange={(value) => {
                    handleSelectChange(value, "branch");
                  }}
                  options={branchOptions}
                  classNamePrefix="select2-selection"
                />
                <p
                  className="text-danger"
                  style={{ fontSize: "11px" }}
                >
                  {selectedBranch === null ? errors.branchError : ""}
                </p>
              </div>
            </Col> */}
                        {userIdTobeUpdated ? null : (
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom05">Password</Label>
                              <AvField
                                name="password"
                                placeholder="Password"
                                type="password"
                                errorMessage=" Please provide a valid password"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom05"
                                value={userObject.password}
                                onChange={handleChangeUser}
                              />
                            </div>
                          </Col>
                        )}
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Address</Label>
                            <AvField
                              name="address"
                              type="textarea"
                              className="form-control"
                              id="validationCustom03"
                              value={userObject.address}
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Image</Label>
                            {userObject?.user_image ? (
                              <div div className="img-wraps">
                                {userObject.user_image && userObject.user_image.startsWith("http") ? (
                                  <img alt="" width="150" height="150" src={`${userObject.user_image}`} />
                                ) : (
                                  <img alt="" width="150" height="150" src={`${API_URL}uploads/user_images/${userObject.user_image}`} />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteUserImage}
                                  style={{ width: "150px" }}
                                  type="button">
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField name="user_image" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
                            )}
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Document</Label>
                            {userObject?.user_documents ? (
                              <div div className="img-wraps">
                                {userObject.user_documents && userObject.user_documents.startsWith("http") ? (
                                  <img alt="" width="150" height="150" src={`${userObject.user_documents}`} />
                                ) : (
                                  <img alt="" width="150" height="150" src={`${API_URL}uploads/user_images/${userObject.user_documents}`} />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteUserDocument}
                                  style={{ width: "150px" }}
                                  type="button">
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField name="user_documents" type="file" className="form-control" id="validationCustom03" onChange={uploadDocument} rows="1" />
                            )}
                          </div>
                        </Col>
                        <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                          {userIdTobeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable id="userTableId" responsive bordered data={data} searching={true} info={true} disableRetreatAfterSorting={true} entries={20} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(Users));

Users.propTypes = {
  error: PropTypes.any,
  users: PropTypes.array,
};
