/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";

import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Col, Label, Button, Card, CardBody, Row, ToastHeader } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import "./country.scss";
import toastr from "toastr";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

function Country() {
  const [countryObject, setcountryObject] = useState({});
  const [uploadProgress, setUploadProgress] = useState();
  const [countryForTable, setcountryForTable] = useState([]);

  const reset = () => {
    formRef.current.reset();
    // setcargoIdToUpdated(null);
    setcountryObject({});
  };
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const {
    districts,
    addingcountry,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  useEffect(() => {
    handleTableData();
    //  fetch_all_category();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function handleTableData() {
    var url = `${API_URL}country/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        //console.log("result: ", result);
        let countryData = [];

        result.map((item, index) => {
          if (typeof item.flag != "undefined" && item.flag != "") {
            item.flag1 = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={`${API_URL}uploads/country_flag/${item.flag}`}
                  style={{ height: "25px", cursor: "pointer" }}
                  onClick={() => window.open(`${API_URL}uploads/country_flag/${item.flag}`, "_blank")}
                  width={"30px"}
                />
              </div>
            );
          } else {
            item.flag1 = "";
          }

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  //  preUpdateFaq(item);
                }}></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  // setfaqIdToBeDeleted(item._id);
                  // setConfirmDeleteAlert(true);
                }}></i>
            </div>
          );
          item.id = index + 1;
          // item.category1=item?.category?.category_name;

          // item.date = moment(item.subject_date).format("DD-MM-YYYY");
          //  item.time = moment(item.subject_time, "HHmmss").format("hh:mm a");
          // if (item?.subjectup_date) item.date = moment(item.subjectup_date).format("DD-MM-YYYY");
          // else item.date = moment(item.subject_date).format("DD-MM-YYYY");

          // if (item?.subjectup_time) item.time = moment(item.subjectup_time,"HHmmss").format("hh:mm a");
          // else item.time = moment(item.subject_time,"HHmmss").format("hh:mm a");

          // if (item?.updatedBy) item.staff = item.updatedBy.firstName;
          // else item.staff = item?.addedby.firstName;
          countryData.push(item);
        });

        setcountryForTable(countryData);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Country",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Country Code",
        field: "phonecode",
        sort: "asc",
        width: 200,
      },
      {
        label: "Currency Code",
        field: "code",
        sort: "asc",
        width: 200,
      },
      {
        label: "Short Name",
        field: "sortname",
        sort: "asc",
        width: 200,
      },
      {
        label: "Flag",
        field: "flag1",
        sort: "asc",
        width: 100,
      },
      {
        label: "Time",
        field: "time_offset",
        sort: "asc",
        width: 100,
      },
      {
        label: "Vat",
        field: "vat",
        sort: "asc",
        width: 100,
      },
      {
        label: "Order Code",
        field: "country_order_code",
        sort: "asc",
        width: 100,
      },
      // {
      // label: "Mobile",
      // field: "mobile",
      // sort: "asc",
      // width: 10,
      // },
      // {
      // label: "City",
      // field: "city",
      // sort: "asc",
      // width: 100,
      // },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: countryForTable,
  };
  function handleChangeInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    // setfaqValue(value);
    setcountryObject({ ...countryObject, [name]: value });
    //console.log(e.target.value);
    //console.log(e.target.name);
  }

  const handleValidSubmit = () => {
    //console.log(countryObject)

    // if (faqIdToBeUpdated) {
    //   axios
    //   .put(`${API_URL}faq/update?_id`, faqObject, {
    //   headers: {
    //   "x-access-token": accessToken,
    //   },
    //   })
    //   .then((res) => {
    //   if (res.data.success === true) {
    //   toastr.success("Faq updates successfully");
    //   handleTableData();
    //   setselectedCategory();
    //   setfaqObject({});
    //   formRef.current.reset();
    //   setfaqIdToBeUpdated(null);

    //   } else toastr.error("Failed to update Faq");
    //   })
    //   .catch((err) => {
    //   toastr.error(err.response.data.message);
    //   return;
    //   });
    // } else {
    axios
      .post(`${API_URL}country/`, countryObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        //console.log(res);
        if (res.data.status === 200) {
          toastr.success("Country added successfully");
          handleTableData();
          // setShowCompany(false);
          // setfaqCategoryValue("")
          // setselectedCategory(null);
          setcountryObject({});
          formRef.current.reset();
        } else toastr.error("Failed to add Country");
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.response.data.message);
        return;
      });
    // }
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("country_flag", e.target.files[0]);
    axios
      .post(`${API_URL}country/flag/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setcountryObject({
            ...countryObject,
            [`flag_name`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteImage = () => {
    setcountryObject({
      ...countryObject,
      [`flag_name`]: "",
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Country" />
          <Row>
            <Col xl="12">
              <Card className="p-2">
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Country Name</Label>
                          <AvField
                            name="name"
                            placeholder="Country Name"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Country Name"
                            onChange={handleChangeInput}
                            value={countryObject?.name}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Currency</Label>
                          <AvField
                            name="currency"
                            placeholder="Currency"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Currency"
                            onChange={handleChangeInput}
                            value={countryObject?.currency}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Time</Label>
                          <AvField
                            name="time_offset"
                            placeholder="Time"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Time"
                            onChange={handleChangeInput}
                            value={countryObject?.time_offset}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Vat</Label>
                          <AvField
                            name="vat"
                            placeholder="Vat"
                            type="Number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Vat"
                            onChange={handleChangeInput}
                            value={countryObject?.vat}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Country Code</Label>
                          <AvField
                            name="phonecode"
                            placeholder="Country Code"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Country Code"
                            onChange={handleChangeInput}
                            value={countryObject?.phonecode}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Currency Code</Label>
                          <AvField
                            name="code"
                            placeholder="Currency Code"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Currency Code"
                            onChange={handleChangeInput}
                            value={countryObject?.code}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Short Name</Label>
                          <AvField
                            name="sortname"
                            placeholder="Short Name"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Sort Name"
                            onChange={handleChangeInput}
                            value={countryObject?.sortname}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Order Code</Label>
                          <AvField
                            name="country_order_code"
                            placeholder="Order Code"
                            type="Number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Order Code"
                            onChange={handleChangeInput}
                            value={countryObject?.country_order_code}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Flag</Label>
                          {countryObject?.flag_name ? (
                            <div div className="img-wraps">
                              {countryObject.flag_name && countryObject.flag_name.startsWith("http") ? (
                                <img alt="" width="150" height="150" src={`${countryObject.flag_name}`} />
                              ) : (
                                <img alt="" width="150" height="150" src={`${API_URL}/uploads/country_flag/${countryObject?.flag_name}`} />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteImage}
                                style={{ width: "150px" }}>
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField name="flag_name" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
                          )}
                        </div>
                      </Col>
                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {/* {cargoIdToUpdated ? ( */}
                          {/* <Button className='mx-2'
                              color='primary'
                              type='update'>
                              update
                            </Button> */}
                          {/* )  */}
                          {/* :  */}
                          <Button className="mx-2" color="primary" type="submit" disabled={addingcountry ? true : false}>
                            {addingcountry ? "Adding" : "Submit"}
                          </Button>
                          {/* } */}
                          <Button color="danger" type="reset" onClick={() => reset()}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive id="Addcountrytable" bordered data={data} entries={20} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Country;
