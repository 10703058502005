/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import FormGroup from "@mui/material/FormGroup";
import { Link } from "react-router-dom";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import { Row, Col, Card, CardBody, Button, Label, Media, Collapse, NavItem, NavLink, TabContent } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getDate } from "../../helpers/globalFunctions";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./medication.scss";
import { Pagination } from "@mui/material";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ObjectId } from "../../helpers/globalVariables";
import PrviousHistory from "../Admission/MedicalForm/PreviousHistory";
import DrinkingHistory from "../Admission/MedicalForm/DrinkingHistory";

const Medication = (props) => {
  const formRef = useRef();

  const [selectedPatient, setSelectedPatient] = useState({ editable: true });
  const [patientOptions, setPatientOptions] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [medicalActiveTab, setMedicalActiveTab] = useState(1);
  const [values, setValues] = useState([
    {
      end_date: "",
      frq_morning: 0,
      frq_noon: 0,
      frq_night: 0,
      days: "",
    },
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [patientvalues, setPatientValues] = useState(false);
  const [patientprevvalues, setPatientPrevValues] = useState(false);
  const [masterObject, setmasterObject] = useState({
    medication_date: getDate(new Date()),
  });
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [medicationIdTobeDeleted, setMedicationTobeDeleted] = useState(null);
  const [medicationIdTobeUpdated, setMedicationTobeUpdated] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [medicalchartvalues, setmedicalchartPrevValues] = useState(false);
  const [tempchartvalues, settempchartPrevValues] = useState(false);
  const [selectedTest, setSelectedTest] = useState("");
  const [testOptions, setTestOptions] = useState([]);
  const toggle = () => setIsOpen(!isOpen);
  const toggle1 = () => setIsOpen1(!isOpen1);
  const toggle2 = () => setIsOpen2(!isOpen2);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();

  const [tableData, setTableData] = useState([]);

  const [admissionId, setAdmissionId] = useState(null);

  const [tempPage, setTempPage] = useState(1);
  const [tempLimit] = useState(10);
  const [tempCount, setTempCount] = useState(0);

  const [medicinePage, setMedicinePage] = useState(1);
  const [medicineLimit] = useState(10);
  const [medicineCount, setMedicineCount] = useState(0);

  const [medicationPage, setMedicationPage] = useState(1);
  const [medicationLimit] = useState(10);
  const [medicationCount, setMedicationCount] = useState(0);

  const [testResultPage, setTestResultPage] = useState(1);
  const [testResultLimit] = useState(10);
  const [testResultCount, setTestResultCount] = useState(0);
  const [testResutlPrevValues, setTestResutlPrevValues] = useState(false);

  const loggerUser = JSON.parse(localStorage.getItem("authUser"));
  const [isAdmin] = useState(loggerUser.privilage === ObjectId.ADMIN || false);

  useEffect(() => {
    fetch_all_patients();
    handleTableData();
    fetch_all_medicines();
    fetch_all_test();
    // fetch_previous_medicine_details()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchTempBpData() {
      if (admissionId) {
        const tempBp = await fetch_previous_temp_bp_details(admissionId);
        setmasterObject({
          ...masterObject,
          bp_temp_chart: tempBp,
        });
      }
    }
    fetchTempBpData();
    // eslint-disable-next-line
  }, [admissionId, tempPage]);

  useEffect(() => {
    async function fetchMedicineData() {
      if (admissionId) {
        const medical_chart = await fetch_previous_medicine_details(admissionId);
        setmasterObject({
          ...masterObject,
          medical_chart: medical_chart,
        });
      }
    }
    fetchMedicineData();
    // eslint-disable-next-line
  }, [admissionId, medicinePage]);

  useEffect(() => {
    async function fetchMedicationData() {
      if (admissionId) {
        const previous_data = await fetch_previous_medication_details(admissionId);
        setmasterObject({
          ...masterObject,
          previous_data: previous_data,
        });
      }
    }
    fetchMedicationData();
    // eslint-disable-next-line
  }, [admissionId, medicationPage]);

  useEffect(() => {
    async function fetchPrevTestResult() {
      if (admissionId) {
        const previous_data = await fetch_previous_test_results(admissionId);
        setmasterObject({
          ...masterObject,
          previous_test_result: previous_data,
        });
      }
    }
    fetchPrevTestResult();
    // eslint-disable-next-line
  }, [admissionId, testResultPage]);

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: `${el?.fname || ""}  ${el?.lname || ""}  ${el?.reg_no ? `- ${el?.reg_no}` : "" || ""}`,
              value: el?._id,
              editable: el?.editable,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  const fetch_all_test = () => {
    axios
      .get(`${API_URL}test-category/option-new`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let test_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setTestOptions([
          {
            options: test_data,
          },
        ]);
      });
  };

  const fetch_all_medicines = () => {
    axios
      .get(`${API_URL}medicine/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let medicine_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name + " " + el?.dose,
              value: el?._id,
            };
          });
        setMedicineOptions([
          {
            options: medicine_data,
          },
        ]);
      });
  };

  const fetch_patient_details = (_id) => {
    return axios
      .get(`${API_URL}admission?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let data = {};
        data.mobile = result?.basic_details?.basic_id?.mobile;
        data.age = result?.basic_details?.age;

        data.counsellor = result?.counsellor_id?.firstName;
        data.admitted_date = moment(result?.basic_details?.reg_date).format("DD-MM-YYYY");
        let gender_value = {};

        if (result?.basic_details?.gender == 0) {
          data.gender = "Female";
        }
        if (result?.basic_details?.gender == 1) {
          data.gender = "Male";
        }
        if (result?.basic_details?.gender == 2) {
          data.gender = "Others";
        }
        //  setmasterObject(data)
        //setSelectedGender(gender_value);
        return data;
      });
  };

  const fetch_previous_medication_details = (_id) => {
    //let admission_id = "6433ce9b297ee1f6f9c17b88"

    return axios
      .get(`${API_URL}medication/medication_last?_id=${_id}&page=${medicationPage}&limit=${medicationLimit}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data;
        setMedicationCount(Math.ceil(result.total_count / medicationLimit));

        if (result.data.length > 0) {
          setPatientPrevValues(true);
        } else {
          setPatientPrevValues(false);
        }
        return result.data;
      });
  };

  const fetch_previous_test_results = (_id) => {
    //let admission_id = "6433ce9b297ee1f6f9c17b88"

    return axios
      .get(`${API_URL}test/result/latest?admission_id=${_id}&page=${testResultPage}&limit=${testResultLimit}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data;
        setTestResultCount(Math.ceil(result.count / testResultLimit));
        if (result.data.length > 0) {
          setTestResutlPrevValues(true);
        } else {
          setTestResutlPrevValues(false);
        }
        return result.data;
      });
  };

  const fetch_previous_medicine_details = (_id) => {
    return axios
      .get(`${API_URL}medicine-checklist/latest?admission_id=${_id}&page=${medicinePage}&limit=${medicineLimit}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data;
        setMedicineCount(Math.ceil(result.total_count / medicineLimit));
        if (result.data.length > 0) {
          setmedicalchartPrevValues(true);
        } else {
          setmedicalchartPrevValues(false);
        }

        return result.data;
      });
  };

  const fetch_previous_temp_bp_details = (_id) => {
    return axios
      .get(`${API_URL}temp-bp-chart/latest?admission_id=${_id}&page=${tempPage}&limit=${tempLimit}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTempCount(Math.ceil(res.data.total_count / tempLimit));
        if (result.length > 0) {
          settempchartPrevValues(true);
        } else {
          settempchartPrevValues(false);
        }

        return result;
      });
  };

  function handleTempPagination(el, newpage) {
    setTempPage(newpage);
  }

  function handleMedicinePagination(el, newpage) {
    setMedicinePage(newpage);
  }

  function handleMedicationPagination(el, newpage) {
    setMedicationPage(newpage);
  }

  function handleTestResultPagination(el, newpage) {
    setTestResultPage(newpage);
  }

  const addMore = () => {
    setValues([...values, {}]);
  };

  function handleTableData() {
    var url = `${API_URL}medication/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
          item.id = index + 1;
          item.name1 = (
            <span
              className="table-link-hover"
              onClick={() =>
                history.push("/admission/" + item?.admission_id.reg_no || "", {
                  id: item?.admission_id._id,
                })
              }>
              {`${item?.admission_id?.basic_details?.basic_id?.fname} ${item?.admission_id?.basic_details?.basic_id?.lname} ${
                item?.admission_id?.admission_times?.label || ""
              }`}
            </span>
          );

          item.date = moment(item?.medication_date).format("DD-MM-YYYY");
          item.reg_no = item?.admission_id?.reg_no;
          item.firstName = item.added_by?.firstName;

          if (props.options.edit) {
            item.action = (
              <>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    preupdateMedication(item, item?.admission_id._id);
                    // handleSelectChange(item._id,"admission_id")
                    toTop();
                  }}></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "0.9em", cursor: "pointer" }}
                  onClick={() => {
                    setMedicationTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </>
            );
          } else {
            item.action = (
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
            );
          }
        });

        setTableData(result);
      });
  }

  const reset = () => {
    formRef.current.reset();
    // handleTableData();
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name1",
        sort: "asc",
        width: 270,
      },
      {
        label: "Reg No",
        field: "reg_no",
        sort: "asc",
        width: 200,
      },

      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  useEffect(() => {
    data.rows.map((row) => {
      if (props.options.edit) {
        row.action = (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                handleClickOpen(row);
              }}></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                preupdateMedication(row, row?.admission_id._id);
                // handleSelectChange(item._id,"admission_id")
                toTop();
              }}></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "0.9em", cursor: "pointer" }}
              onClick={() => {
                setMedicationTobeDeleted(row._id);
                setConfirmDeleteAlert(true);
              }}></i>
          </>
        );
      } else {
        row.action = (
          <i
            className="fas fa-eye"
            style={{
              fontSize: "0.9em",
              cursor: "pointer",
              marginLeft: "0.1em",
              marginRight: "0.4em",
            }}
            onClick={() => {
              handleClickOpen(row);
            }}></i>
        );
      }
    });
  }, [props.options]);

  const handleSelectChange = async (selected, type) => {
    switch (type) {
      case "admission_id":
        setSelectedPatient(selected);
        setPatientValues(true);
        let data = await fetch_patient_details(selected.value);
        setAdmissionId(selected.value);
        let medical_chart = await fetch_previous_medicine_details(selected.value);
        let previous_data = await fetch_previous_medication_details(selected.value);
        let bp_temp_chart = await fetch_previous_temp_bp_details(selected.value);
        let previous_test_result = await fetch_previous_test_results(selected.value);

        setmasterObject({
          ...masterObject,
          mobile: data.mobile,
          age: data.age,
          gender: data.gender,
          admitted_date: data.admitted_date,
          admission_id: selected.value,
          previous_data: previous_data,
          medical_chart: medical_chart,
          bp_temp_chart: bp_temp_chart,
          previous_test_result,
        });

        break;
      case "test":
        let arrayVal = [];
        selected &&
          selected.forEach((element) => {
            arrayVal.push(element.value);
          });
        setSelectedTest(selected);
        setmasterObject({
          ...masterObject,
          test: arrayVal,
        });

      default:
        break;
    }
  };
  function handleChangeSelectItem(selected, index) {
    const list = [...values];
    list[index]["medicine"] = selected.value;
    list[index]["medicine_name"] = selected.label;
    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
    setSelectedMedicine(selected);
  }

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);

    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
  };

  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };

  function handleTableInputchange(e, index) {
    let { name, value } = e.target;

    name = name.split("-")[0];

    console.log(name);
    const list = [...values];
    list[index][name] = value;

    if (name === "days") {
      let medication_date = masterObject?.medication_date;
      console.log("medication_date: ", medication_date);
      let endDate = moment(medication_date).add(value - 1, "days");
      let end_date = moment(endDate).format("YYYY-MM-DD");
      list[index]["end_date"] = end_date;
    }
    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
  }

  const handleValidSubmit = (e) => {
    if (medicationIdTobeUpdated) {
      axios
        .put(`${API_URL}medication/edit`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Medication updated successfully");

          formRef.current.reset();

          setValues([
            {
              end_date: "",
              frq_morning: 0,
              frq_noon: 0,
              frq_night: 0,
              days: "",
            },
          ]);
          setmasterObject({});
          setSelectedPatient(null);
          setSelectedTest(null);
          handleTableData();
          setPatientValues(false);
          setmasterObject((masterObject) => ({ ...masterObject, remarks: "", complaints: "" }));
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    } else {
      axios
        .post(`${API_URL}medication/add`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Medication added successfully");

          formRef.current.reset();

          setValues([
            {
              end_date: "",
              frq_morning: 0,
              frq_noon: 0,
              frq_night: 0,
              days: "",
            },
          ]);
          setmasterObject({});
          setSelectedPatient(null);
          setSelectedTest(null);
          handleTableData();
          setPatientValues(false);
          setmasterObject((masterObject) => ({ ...masterObject, remarks: "", complaints: "" }));
        })
        .catch((err) => {
          return;
        });
    }
  };

  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}medication/medication_view?id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        item.date = moment(result?.medication_date).format("DD-MM-YYYY");
        item.reg_no = result?.admission_id?.reg_no;
        item.firstName = result.added_by?.firstName;
        item.complaints = result?.complaints;
        item.remarks = result?.remarks;
        item.medicine = result?.medicine;

        item.tests = <>{result?.test && result?.test.map((element, index) => element.name).join(", ")}</>;

        // setMedicineData(result?.medicine)

        setPopupData(item);
        setPopupView(true);
      });
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preupdateMedication = async (item, id) => {
    setMedicationTobeUpdated(item?._id);
    setPatientValues(true);
    let data = await fetch_patient_details(id);
    let previous_data = await fetch_previous_medication_details(id);
    let medical_chart = await fetch_previous_medicine_details(id);
    let bp_temp_chart = await fetch_previous_temp_bp_details(id);

    axios
      .get(`${API_URL}medication/medication_view?id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let items = {};
        items._id = item?._id;
        items.admission_id = result?.admission_id?._id;
        items.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        items.date = moment(result?.medication_date).format("DD-MM-YYYY");
        items.reg_no = result?.admission_id?.reg_no;
        items.firstName = result.added_by?.firstName;
        items.complaints = result?.complaints;
        items.remarks = result?.remarks;
        items.medication_date = result?.medication_date;
        items.mobile = data?.mobile;
        items.age = data?.age;
        items.gender = data?.gender;
        items.admitted_date = data?.admitted_date;
        items.previous_data = previous_data;
        items.medical_chart = medical_chart;
        items.bp_temp_chart = bp_temp_chart;

        let testArr = [];
        result?.test &&
          result?.test.forEach((element) => {
            let array = {
              label: element.name,
              value: element._id,
            };
            testArr.push(array);
          });
        items.test = result?.test;
        setSelectedTest(testArr);

        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };
        if (result?.medicine?.length > 0) {
          let tableData = [];
          result?.medicine &&
            result?.medicine.map((el, index) => {
              let val = {};
              val.end_date = el?.end_date;
              val.frq_morning = el?.frq_morning;
              val.frq_noon = el?.frq_noon;
              val.frq_night = el?.frq_night;
              val.days = el?.days;
              val.remarks = el?.remarks;
              val.medicine = el?.medicine._id;
              if (el?.medicine?.dose !== "") {
                val.medicine_name = el?.medicine?.name + " " + el?.medicine?.dose;
              }
              val.medicine_name = el?.medicine?.name;
              // val.medicine_name = el?.medicine?.name + " " + el?.medicine?.dose;

              tableData.push(val);
            });
          items.medicine = result?.medicine;
          setValues(tableData);
        }

        setSelectedPatient(patient);
        setmasterObject(items);

        // setMedicineData(result?.medicine)

        // setPopupData(item)
        // setPopupView(true);
      });
  };
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}medication` + "/" + medicationIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Medication deleted successfully");
                  if (masterObject && masterObject.medication_id === medicationIdTobeDeleted) {
                    formRef.current.reset();
                    setmasterObject({});

                    // setMedicationIdTobeUpdated(null);
                  }
                  setMedicationTobeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Medication");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Medication Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <div className="modal-body">
          <table id="product_available_day" className="table table-bordered dataTable">
            <tr>
              <td style={{ width: "184px", padding: "10px" }}>Name :</td>
              <td>{popupData?.name}</td>
              <td style={{ paddingLeft: "25px" }}>Reg No :</td>
              <td style={{ textAlign: "left" }}>{popupData?.reg_no}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Date :</td>
              <td>{popupData?.date}</td>
              <td style={{ paddingLeft: "25px" }}>Staff :</td>
              <td style={{ textAlign: "left" }}>{popupData?.firstName}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Complaints :</td>
              <td>{popupData?.complaints}</td>
              <td style={{ padding: "10px" }}></td>
              <td></td>
            </tr>

            <tr colSpan={4}>
              <td style={{ padding: "10px" }}>Test :</td>
              <td> {popupData?.tests} </td>

              {/* <td>{popupData?.test.map((item, index) => item)}</td> */}
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Remarks :</td>
              <td>{popupData?.remarks}</td>
              <td style={{ padding: "10px" }}></td>
              <td></td>
            </tr>
          </table>
          <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
            <TableHead>
              <tr style={{ textAlign: "center" }}>
                <th
                  style={{
                    width: "5px",
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "12.819px",
                    fontFamily: "IBM Plex Sans, sans-serif",
                    color: "#495057",
                  }}
                  rowSpan={2}>
                  No.
                </th>
                <th
                  style={{
                    width: "150px",
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "12.819px",
                    fontFamily: "IBM Plex Sans, sans-serif",
                    color: "#495057",
                  }}
                  rowSpan={2}>
                  Medicine
                </th>
                <th
                  style={{
                    width: "100px",
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "12.819px",
                    fontFamily: "IBM Plex Sans, sans-serif",
                    color: "#495057",
                  }}
                  colSpan={3}>
                  Frequency
                </th>

                <th
                  style={{
                    width: "100px",
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "12.819px",
                    fontFamily: "IBM Plex Sans, sans-serif",
                    color: "#495057",
                  }}
                  rowSpan={2}>
                  Days
                </th>

                <th
                  style={{
                    width: "200px",
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "12.819px",
                    fontFamily: "IBM Plex Sans, sans-serif",
                    color: "#495057",
                  }}
                  rowSpan={2}>
                  Remarks
                </th>
              </tr>
              <tr>
                <th style={{ width: "10px", textAlign: "center" }}>Morning</th>
                <th style={{ width: "10px", textAlign: "center" }}>Noon</th>
                <th style={{ width: "10px", textAlign: "center" }}>Night</th>
              </tr>
            </TableHead>
            <TableBody>
              {popupData?.medicine &&
                popupData?.medicine.map((item, index) => {
                  return (
                    <tr>
                      <td
                        style={{
                          // width: "180px",
                          textAlign: "center",
                          fontWeight: "200",
                          fontSize: "12.819px",
                          // fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                        }}>
                        {index + 1}
                      </td>
                      <td
                        component="th"
                        scope="row"
                        style={{
                          textAlign: "left",
                          fontSize: "12.819px",
                          //fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                          fontWeight: "200",
                        }}>
                        {`${item?.medicine?.name || ""}` + " " + `${item?.medicine?.dose || ""}`}
                      </td>

                      <td style={{ textAlign: "center" }}>{item?.frq_morning}</td>
                      <td style={{ textAlign: "center" }}>{item?.frq_noon}</td>
                      <td style={{ textAlign: "center" }}>{item?.frq_night}</td>
                      <td style={{ textAlign: "center" }}>{item?.days}</td>
                      <td style={{ textAlign: "center" }}>{item?.remarks}</td>
                    </tr>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Medication" />
          {props.options.edit && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation ttt"
                      method="POST"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}>
                      <Row>
                        {/* <Col md="5">
              <Row>*/}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Patient</Label>
                            <Select
                              name="admission_id"
                              value={selectedPatient}
                              onChange={(selected) => {
                                handleSelectChange(selected, "admission_id");
                              }}
                              options={patientOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        {/* </Row>
            </Col> */}
                        {patientvalues == true ? (
                          <>
                            <Col md="9">
                              <Row>
                                <div className="mt-2">
                                  <h6>Patient Basic Details:</h6>
                                </div>
                              </Row>
                              <Row>
                                <Col md="3" className="mt-1">
                                  <div>
                                    <Label>Mobile:</Label>
                                    <span>{masterObject?.mobile}</span>
                                  </div>
                                </Col>
                                <Col md="2" className="mt-1">
                                  <Label>Gender:</Label>
                                  <span>{masterObject?.gender}</span>
                                </Col>
                                <Col md="1" className="mt-1">
                                  <Label>Age:</Label>
                                  <span>{masterObject?.age}</span>
                                </Col>
                                <Col md="3" className="mt-1">
                                  <Label>Admitted Date:</Label>
                                  <span>{masterObject?.admitted_date}</span>
                                </Col>
                              </Row>
                            </Col>
                            {medicalchartvalues == true ? (
                              <Row>
                                <div className="mt-0">
                                  <Card /* style={{ background: "#f6f6f6" }} */>
                                    <Link to="#" onClick={toggle} className="text-dark">
                                      <div className="p-2">
                                        <Media className="d-flex align-items-center">
                                          <div className="flex-1 overflow-hidden">
                                            <h6>Medicine check list</h6>
                                          </div>
                                          <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                        </Media>
                                      </div>
                                    </Link>
                                    <Collapse isOpen={isOpen}>
                                      <div>
                                        <Table style={{ textAlign: "center" }} className="table1 ">
                                          <TableHead>
                                            <tr style={{ textAlign: "center" }}>
                                              <th
                                                style={{
                                                  width: "5px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                No.
                                              </th>
                                              <th
                                                style={{
                                                  width: "50px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Date
                                              </th>

                                              <th
                                                style={{
                                                  width: "300px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                colSpan={3}>
                                                Medicine
                                              </th>
                                            </tr>
                                            <tr>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Morning
                                              </th>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Noon
                                              </th>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Night
                                              </th>
                                            </tr>
                                          </TableHead>
                                          <TableBody>
                                            {masterObject?.medical_chart &&
                                              masterObject?.medical_chart.map((item, index) => (
                                                <tr>
                                                  <td>{(medicinePage - 1) * medicineLimit + index + 1}</td>

                                                  <td className="text-center">{moment(item?.date).format("DD-MM-YYYY")}</td>

                                                  <td
                                                    style={{
                                                      textAlign: "left",
                                                    }}>
                                                    {" "}
                                                    <ul>
                                                      {item?.medicines_morning &&
                                                        item?.medicines_morning.map((element, index) => {
                                                          return <li>{`${element?.name || ""}  ${element?.dose || ""}`}</li>;
                                                        })}
                                                    </ul>
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "left",
                                                    }}>
                                                    <ul>
                                                      {item?.medicines_noon &&
                                                        item?.medicines_noon.map((element, index) => {
                                                          return <li>{`${element?.name || ""}  ${element?.dose || ""}`}</li>;
                                                        })}
                                                    </ul>
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "left",
                                                    }}>
                                                    <ul>
                                                      {item?.medicines_night &&
                                                        item?.medicines_night.map((element, index) => {
                                                          return <li>{`${element?.name || ""}  ${element?.dose || ""}`}</li>;
                                                        })}
                                                    </ul>
                                                  </td>
                                                </tr>
                                              ))}
                                          </TableBody>
                                        </Table>
                                      </div>
                                      <div className="d-flex justify-content-center my-2">
                                        {medicineCount > 1 && <Pagination count={medicineCount} page={medicinePage} onChange={handleMedicinePagination} />}
                                      </div>
                                    </Collapse>
                                  </Card>
                                </div>
                              </Row>
                            ) : null}

                            {tempchartvalues == true ? (
                              <Row>
                                <div className="mt-0">
                                  <Card /* style={{ background: "#f6f6f6" }} */>
                                    <Link to="#" onClick={toggle1} className="text-dark">
                                      <div className="p-2">
                                        <Media className="d-flex align-items-center">
                                          <div className="flex-1 overflow-hidden">
                                            <h6>Temperature BP Details</h6>
                                          </div>
                                          <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                        </Media>
                                      </div>
                                    </Link>
                                    <Collapse isOpen={isOpen1}>
                                      <div>
                                        <Table style={{ textAlign: "center" }} className="table1 ">
                                          <TableHead>
                                            <tr style={{ textAlign: "center" }}>
                                              <th
                                                style={{
                                                  width: "5px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                No.
                                              </th>
                                              <th
                                                style={{
                                                  width: "150px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Date
                                              </th>
                                              <th
                                                style={{
                                                  width: "100px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                colSpan={6}>
                                                Temperature
                                              </th>
                                              <th
                                                style={{
                                                  width: "150px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                BP
                                              </th>

                                              <th
                                                style={{
                                                  width: "100px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Pulse
                                              </th>
                                            </tr>
                                            <tr>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Time
                                              </th>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Morning
                                              </th>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Time
                                              </th>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Noon
                                              </th>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Time
                                              </th>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Night
                                              </th>
                                            </tr>
                                          </TableHead>
                                          <TableBody>
                                            {masterObject?.bp_temp_chart &&
                                              masterObject?.bp_temp_chart.map((item, index) => {
                                                return (
                                                  <>
                                                    {item?.temp_details.map((el, idx) => {
                                                      if (idx === 0) {
                                                        return (
                                                          <tr>
                                                            <td rowSpan={item?.temp_details.length}>{index + 1}</td>
                                                            <td className="text-center" rowSpan={item?.temp_details.length}>
                                                              {moment(item?.date).format("DD-MM-YYYY")}
                                                            </td>

                                                            <td className="p-2 text-center" style={{ minWidth: "5em" }}>
                                                              {el?.temp_morning_time ? moment(el?.temp_morning_time, "HH:mm").format("hh:mm a") : ""}
                                                            </td>
                                                            <td className="p-2 text-center" style={{ minWidth: "3em" }}>
                                                              {el?.temp_morning}
                                                            </td>
                                                            <td className="p-2 text-center" style={{ minWidth: "5em" }}>
                                                              {el?.temp_noon_time ? moment(el?.temp_noon_time, "HH:mm").format("hh:mm a") : ""}
                                                            </td>
                                                            <td className="p-2 text-center">{el?.temp_noon}</td>
                                                            <td className="p-2 text-center" style={{ minWidth: "5em" }}>
                                                              {el?.temp_evening_time ? moment(el?.temp_evening_time, "HH:mm").format("hh:mm a") : ""}
                                                            </td>
                                                            <td className="p-2 text-center">{el?.temp_evening}</td>

                                                            <td className="p-2 text-center">{el?.bp}</td>
                                                            <td className="p-2 text-center" component="th">
                                                              {el?.pulse}
                                                            </td>
                                                          </tr>
                                                        );
                                                      } else {
                                                        return (
                                                          <tr>
                                                            <td className="p-2 text-center" style={{ minWidth: "5em" }}>
                                                              {el?.temp_morning_time ? moment(el?.temp_morning_time, "HH:mm").format("hh:mm a") : ""}
                                                            </td>
                                                            <td className="p-2 text-center" style={{ minWidth: "3em" }}>
                                                              {el?.temp_morning}
                                                            </td>
                                                            <td className="p-2 text-center" style={{ minWidth: "5em" }}>
                                                              {el?.temp_noon_time ? moment(el?.temp_noon_time, "HH:mm").format("hh:mm a") : ""}
                                                            </td>
                                                            <td className="p-2 text-center">{el.temp_noon}</td>
                                                            <td className="p-2 text-center" style={{ minWidth: "5em" }}>
                                                              {el?.temp_evening_time ? moment(el?.temp_evening_time, "HH:mm").format("hh:mm a") : ""}
                                                            </td>
                                                            <td className="p-2 text-center">{el?.temp_evening}</td>

                                                            <td className="p-2 text-center">{el?.bp}</td>
                                                            <td className="p-2 text-center" component="th">
                                                              {el?.pulse}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    })}
                                                  </>
                                                );
                                              })}
                                          </TableBody>
                                        </Table>
                                      </div>
                                      <div className="d-flex justify-content-center my-2">
                                        {tempCount > 1 && <Pagination count={tempCount} page={tempPage} onChange={handleTempPagination} />}
                                      </div>
                                    </Collapse>
                                  </Card>
                                </div>
                              </Row>
                            ) : null}

                            {patientprevvalues == true ? (
                              <Row>
                                <div className="mt-0">
                                  <Card /* style={{ background: "#f6f6f6" }} */>
                                    <Link to="#" onClick={toggle2} className="text-dark">
                                      <div className="p-2">
                                        <Media className="d-flex align-items-center">
                                          <div className="flex-1 overflow-hidden">
                                            <h6>Previous Medication Details</h6>
                                          </div>
                                          <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                        </Media>
                                      </div>
                                    </Link>
                                    <Collapse isOpen={isOpen2}>
                                      <div>
                                        <Table style={{ textAlign: "center" }} className="table1 ">
                                          <TableHead>
                                            <tr style={{ textAlign: "center" }}>
                                              <th
                                                style={{
                                                  width: "5px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                No.
                                              </th>
                                              <th
                                                style={{
                                                  width: "100px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Date
                                              </th>
                                              <th
                                                style={{
                                                  width: "120px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Complaints
                                              </th>
                                              <th
                                                style={{
                                                  width: "110px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Remarks
                                              </th>
                                              <th
                                                style={{
                                                  width: "170px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Medicine
                                              </th>
                                              <th
                                                style={{
                                                  width: "170px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Test
                                              </th>
                                              <th
                                                style={{
                                                  width: "100px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                colSpan={3}>
                                                Frequency
                                              </th>

                                              <th
                                                style={{
                                                  width: "100px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Days
                                              </th>

                                              <th
                                                style={{
                                                  width: "150px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}
                                                rowSpan={2}>
                                                Remarks
                                              </th>
                                            </tr>
                                            <tr>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Morning
                                              </th>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Noon
                                              </th>
                                              <th
                                                style={{
                                                  width: "10px",
                                                  fontWeight: "500",
                                                }}>
                                                Night
                                              </th>
                                            </tr>
                                          </TableHead>
                                          <TableBody>
                                            {masterObject?.previous_data &&
                                              masterObject?.previous_data.map((item, index) => (
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td className="text-center">{moment(item?.medication_date).format("DD-MM-YYYY")}</td>
                                                  <td>{item?.complaints}</td>
                                                  <td>{item?.remarks}</td>
                                                  {/* {item?.medicine &&
                                                                              item?.medicine.map((items, index) => ( */}
                                                  <>
                                                    <td
                                                      // component="th"
                                                      // scope="row"
                                                      style={{
                                                        textAlign: "left",
                                                      }}>
                                                      <ul>
                                                        {item?.medicine &&
                                                          item?.medicine.map((element, index) => {
                                                            // if (!element?.medicine?.dose) {
                                                            //   console.log("true");
                                                            //   element.medicine.dose = "";
                                                            // }
                                                            return (
                                                              <>
                                                                {`${element?.medicine?.name || ""} ${element?.medicine?.dose || ""}`}
                                                                <br></br>
                                                              </>
                                                            );
                                                          })}
                                                      </ul>
                                                    </td>
                                                    <td
                                                      // component="th"
                                                      // scope="row"
                                                      style={{
                                                        textAlign: "left",
                                                      }}>
                                                      <ul>
                                                        {item?.test &&
                                                          item?.test.map((element, index) => {
                                                            return element?.category?.name ? <li>{element?.category?.name}</li> : null;
                                                          })}
                                                      </ul>
                                                    </td>

                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}>
                                                      {item?.medicine &&
                                                        item?.medicine.map((element, index) => {
                                                          return (
                                                            <>
                                                              {element?.frq_morning}
                                                              <br></br>
                                                            </>
                                                          );
                                                        })}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}>
                                                      {item?.medicine &&
                                                        item?.medicine.map((element, index) => {
                                                          return (
                                                            <>
                                                              {element?.frq_noon}
                                                              <br></br>
                                                            </>
                                                          );
                                                        })}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}>
                                                      {item?.medicine &&
                                                        item?.medicine.map((element, index) => {
                                                          return (
                                                            <>
                                                              {element?.frq_night}
                                                              <br></br>
                                                            </>
                                                          );
                                                        })}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}>
                                                      {item?.medicine &&
                                                        item?.medicine.map((element, index) => {
                                                          return (
                                                            <>
                                                              {element?.days}
                                                              <br></br>
                                                            </>
                                                          );
                                                        })}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}>
                                                      {item?.medicine &&
                                                        item?.medicine.map((element, index) => {
                                                          return (
                                                            <>
                                                              {element?.remarks}
                                                              <br></br>
                                                            </>
                                                          );
                                                        })}
                                                    </td>
                                                  </>
                                                  {/* ))} */}
                                                </tr>
                                              ))}
                                          </TableBody>
                                        </Table>
                                      </div>
                                      <div className="d-flex justify-content-center my-2">
                                        {medicationCount > 1 && (
                                          <Pagination count={medicationCount} page={medicationPage} onChange={handleMedicationPagination} />
                                        )}
                                      </div>
                                    </Collapse>
                                  </Card>
                                </div>
                              </Row>
                            ) : null}

                            {testResutlPrevValues && (
                              <Row>
                                <div className="mt-0">
                                  <Card /* style={{ background: "#f6f6f6" }} */>
                                    <Link to="#" onClick={() => setIsOpen3(!isOpen3)} className="text-dark">
                                      <div className="p-2">
                                        <Media className="d-flex align-items-center">
                                          <div className="flex-1 overflow-hidden">
                                            <h6>Previous Test result</h6>
                                          </div>
                                          <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                        </Media>
                                      </div>
                                    </Link>
                                    <Collapse isOpen={isOpen3}>
                                      <div>
                                        <Table style={{ textAlign: "center" }} className="test-result-table1 ">
                                          <TableHead>
                                            <tr style={{ textAlign: "center" }}>
                                              <th
                                                style={{
                                                  width: "5px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                No.
                                              </th>
                                              <th
                                                style={{
                                                  width: "100px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                Date
                                              </th>
                                              <th
                                                style={{
                                                  width: "120px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                Category
                                              </th>
                                              <th
                                                style={{
                                                  width: "110px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                Tests
                                              </th>
                                              <th
                                                style={{
                                                  width: "170px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                Values
                                              </th>
                                              <th
                                                style={{
                                                  width: "170px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                Added by
                                              </th>
                                            </tr>
                                          </TableHead>
                                          <TableBody>
                                            {masterObject?.previous_test_result?.map((item, index) => {
                                              let itemRowSpan = item.data.reduce((total, category) => total + category.tests.length, 0);
                                              return item.data.map((category, catIndex) => {
                                                const categoryRowSpan = category.tests.length;
                                                const itemFirstRow = catIndex === 0;
                                                return category.tests.map((inner, iIndex) => {
                                                  const categoryFirstRow = iIndex === 0;
                                                  return (
                                                    <tr>
                                                      {itemFirstRow && categoryFirstRow && <td rowSpan={itemRowSpan}>{index + 1}</td>}
                                                      {itemFirstRow && categoryFirstRow && <td rowSpan={itemRowSpan}>{item._id}</td>}
                                                      {categoryFirstRow && <td rowSpan={categoryRowSpan}>{category.category_name}</td>}
                                                      <td>{inner.name}</td>
                                                      <td>{inner.value}</td>
                                                      {categoryFirstRow && (
                                                        <td rowSpan={categoryRowSpan}>{`${category.firstName || ""} ${category.lastName || ""}`}</td>
                                                      )}
                                                    </tr>
                                                  );
                                                });
                                              });
                                            })}
                                          </TableBody>
                                        </Table>
                                      </div>
                                      <div className="d-flex justify-content-center my-2">
                                        {testResultCount > 1 && (
                                          <Pagination count={testResultCount} page={testResultPage} onChange={handleTestResultPagination} />
                                        )}
                                      </div>
                                    </Collapse>
                                  </Card>
                                </div>
                              </Row>
                            )}

                            <Row>
                              <div className="mt-0">
                                <Card /* style={{ background: "#f6f6f6" }} */>
                                  <Link to="#" onClick={() => setIsOpen4((pre) => !pre)} className="text-dark">
                                    <div className="p-2">
                                      <Media className="d-flex align-items-center">
                                        <div className="flex-1 overflow-hidden">
                                          <h6>Medical Form</h6>
                                        </div>
                                        <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                      </Media>
                                    </div>
                                  </Link>
                                  <Collapse isOpen={isOpen4}>
                                    <div className="wizard clearfix p-4">
                                      <div className="steps clearfix">
                                        <ul>
                                          <NavItem
                                            className={classnames({
                                              current: medicalActiveTab === 1,
                                            })}>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                current: medicalActiveTab === 1,
                                              })}
                                              onClick={() => {
                                                setMedicalActiveTab(1);
                                              }}>
                                              <i className="fas fa-book-open"></i>
                                              &nbsp; Drinking/Drug History
                                            </NavLink>
                                          </NavItem>

                                          <NavItem
                                            className={classnames({
                                              current: medicalActiveTab === 2,
                                            })}>
                                            <NavLink
                                              style={{ cursor: "pointer" }}
                                              className={classnames({
                                                current: medicalActiveTab === 2,
                                              })}
                                              onClick={() => {
                                                setMedicalActiveTab(2);
                                              }}>
                                              <i className="fas fa-book-open"></i>
                                              &nbsp; Previous History
                                            </NavLink>
                                          </NavItem>
                                        </ul>
                                      </div>
                                      <div className="mt-3">
                                        <TabContent activeTab={medicalActiveTab}>
                                          <DrinkingHistory medicalActiveTab={medicalActiveTab} id={admissionId} show={false} />
                                          <PrviousHistory show={false} id={admissionId} medicalActiveTab={medicalActiveTab} />
                                        </TabContent>
                                      </div>
                                    </div>
                                  </Collapse>
                                </Card>
                              </div>
                            </Row>
                          </>
                        ) : null}

                        <Col md="2">
                          <div className="mb-3">
                            <Label>Date</Label>
                            <AvField
                              name="medication_date"
                              placeholder="Date"
                              type="date"
                              validate={{ required: { value: true } }}
                              // max={moment().format("YYYY-MM-DD")}
                              className="form-control"
                              onChange={handleChangeInput}
                              value={masterObject?.medication_date}
                              disabled={isAdmin ? false : !selectedPatient?.editable}
                            />
                          </div>
                        </Col>

                        <Col md="2">
                          <div className="mb-3">
                            <Label>Complaints</Label>
                            <AvField
                              name="complaints"
                              placeholder="Complaints"
                              type="textarea"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              value={masterObject?.complaints}
                              onChange={handleChangeInput}
                              rows={1}
                              disabled={isAdmin ? false : !selectedPatient?.editable}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Test</Label>
                            <Select
                              isMulti={true}
                              name="test"
                              value={selectedTest}
                              onChange={(selected) => {
                                handleSelectChange(selected, "test");
                              }}
                              options={testOptions}
                              classNamePrefix="select2-selection"
                              isDisabled={isAdmin ? false : !selectedPatient?.editable}
                              //className="test_class"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Remarks</Label>
                            <AvField
                              name="remarks"
                              placeholder="Remarks"
                              type="textarea"
                              className="form-control"
                              rows={1}
                              value={masterObject?.remarks}
                              onChange={handleChangeInput}
                              disabled={isAdmin ? false : !selectedPatient?.editable}
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="mt-0">
                        <h6>Medicine Details</h6>
                      </div>

                      <Row>
                        <Col xl="12">
                          <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                            <TableHead>
                              <TableRow style={{ textAlign: "center" }}>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  No.
                                </TableCell>
                                <TableCell
                                  style={{
                                    // width: "230px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  Medicine Name
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "130px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  Frequency(Morning/Noon/Night)
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "90px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  Days
                                </TableCell>

                                <TableCell
                                  style={{
                                    //width: "250px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                  }}>
                                  Remarks
                                </TableCell>
                                {values.length !== 1 ? (
                                  <TableCell
                                    style={{
                                      width: "0px",
                                      textAlign: "center",
                                      fontWeight: "500",
                                      paddingLeft: "0rem",
                                      paddingRight: "0rem",
                                      fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057",
                                    }}>
                                    Action
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values &&
                                values.map((item, index) => (
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        textAlign: "center",
                                        fontSize: "12.819px",
                                        fontFamily: "IBM Plex Sans, sans-serif",
                                        color: "#495057",
                                        fontWeight: "500",
                                      }}>
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "left",
                                      }}>
                                      <Select
                                        name="medicine_name"
                                        //value={selectedMedicine}
                                        value={{
                                          label: item.medicine_name,
                                          value: item.medicine,
                                        }}
                                        onChange={(selected) => {
                                          handleChangeSelectItem(selected, index);
                                        }}
                                        options={medicineOptions}
                                        isDisabled={isAdmin ? false : !selectedPatient?.editable}
                                        classNamePrefix="select2-selection"
                                      />
                                    </TableCell>
                                    <TableCell style={{ display: "flex", gap: "10px" }}>
                                      <AvField
                                        name={`frq_morning-${index}`}
                                        type="number"
                                        className="form-control"
                                        min={0}
                                        //placeholder="Morning"
                                        id="validationCustom05"
                                        onChange={(e) => handleTableInputchange(e, index)}
                                        value={item.frq_morning || ""}
                                        validate={{ required: { value: item.medicine_name ? true : false } }}
                                        onWheel={(e) => e.target.blur()}
                                        disabled={isAdmin ? false : !selectedPatient?.editable}
                                      />
                                      <AvField
                                        name={`frq_noon-${index}`}
                                        type="number"
                                        className="form-control"
                                        min={0}
                                        // placeholder="Noon"
                                        id="validationCustom05"
                                        onChange={(e) => handleTableInputchange(e, index)}
                                        value={item.frq_noon || ""}
                                        onWheel={(e) => e.target.blur()}
                                        disabled={isAdmin ? false : !selectedPatient?.editable}
                                        validate={{ required: { value: item.medicine_name ? true : false } }}
                                      />
                                      <AvField
                                        name={`frq_night-${index}`}
                                        type="number"
                                        className="form-control"
                                        // placeholder="Night"
                                        id="validationCustom05"
                                        onChange={(e) => handleTableInputchange(e, index)}
                                        value={item.frq_night || ""}
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                        disabled={isAdmin ? false : !selectedPatient?.editable}
                                        validate={{ required: { value: item.medicine_name ? true : false } }}
                                      />
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      <AvField
                                        name={`days-${index}`}
                                        placeholder="Days"
                                        type="Number"
                                        className="form-control"
                                        // validate={{ required: { value: true } }}
                                        id="validationCustom05"
                                        onChange={(e) => handleTableInputchange(e, index)}
                                        value={item.days}
                                        onWheel={(e) => e.target.blur()}
                                        min={1}
                                        disabled={isAdmin ? false : !selectedPatient?.editable}
                                        validate={{ required: { value: item.medicine_name ? true : false } }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name={`remarks-${index}`}
                                        placeholder="Remarks"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom05"
                                        onChange={(e) => handleTableInputchange(e, index)}
                                        value={item.remarks}
                                        disabled={isAdmin ? false : !selectedPatient?.editable}
                                      />
                                    </TableCell>

                                    <TableCell>
                                      {values.length !== 1 && (
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            cursor: "pointer",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => handleRemove(index)}></i>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Col>
                      </Row>
                      <span
                        onClick={() => addMore()}
                        style={{
                          width: "190px",
                          cursor: "pointer",
                          fontSize: "12.819px",
                          fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                          fontWeight: "500",
                        }}>
                        {" "}
                        + Add More{" "}
                      </span>
                      <Row className="mt-1">
                        <Col md="12" className="mt-1">
                          <FormGroup className="float-right ">
                            {isAdmin || selectedPatient?.editable ? (
                              <Button color="primary" type="submit">
                                {medicationIdTobeUpdated ? "Update" : "Submit"}
                              </Button>
                            ) : selectedPatient?.editable ? (
                              <Button color="primary" type="submit">
                                {medicationIdTobeUpdated ? "Update" : "Submit"}
                              </Button>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <MDBDataTable
                      id="medicationTableID"
                      responsive
                      //striped
                      bordered
                      data={data}
                      info={true}
                      searching={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Medication;
