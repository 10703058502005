import SweetAlert from "react-bootstrap-sweetalert";
function DeleteAlert({ name, data, onConfirm, toggle }) {
  // data format {show:bolean,id:"" }
  return data.show ? (
    <SweetAlert
      key={name}
      title=""
      showCancel
      confirmButtonText="Delete"
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      onConfirm={() => onConfirm(data.id)}
      onCancel={toggle}
    >
      Are you sure you want to delete it?
    </SweetAlert>
  ) : (
    <></>
  );
}
export default DeleteAlert;
