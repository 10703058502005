import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import userSaga from "./users/saga";
import privilagesSaga from "./privilages/saga";
import companiesSaga from "./companies/saga";
import branchesSaga from "./branches/saga";
import districtSaga from "./district/saga";
//Ward
import wardSaga from "./ward/saga";
//Localbody
import localbodySaga from "./localbody/saga";
//Group
import groupSaga from "./group/saga";

//customer
import customerSaga from "./customer/saga";

//wasteitem
import wasteItemSaga from "./wasteItem/saga";

import menusSaga from "./menu/saga";

import customerCategorySaga from "./customerCategory/saga";
import qrcodeSaga from "./qrCode/saga";
import tariffSaga from "./tariff/saga";
import invoiceSaga from "./invoice/saga";
import receiptSaga from "./receipt/saga";
import packageSaga from "./package/saga";
import acctypeSaga from "./accountType/saga";
import accdetailSaga from "./accountDetail/saga";
import chartOfAccSaga from "./chartOfAccount/saga";
import creditDebitSaga from "./creditDebit/saga";
import fundTransferSaga from "./fundTransfer/saga";
import incomeExpenseSaga from "./IncomeExpenses/saga";
import popupSaga from "./popup/saga";
import fetchStaffSalarySaga from "./salary/saga";
import sliderSaga from "./slider/saga";

import patient from "./patients/saga";
export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(userSaga),
    fork(privilagesSaga),
    fork(companiesSaga),
    fork(branchesSaga),
    fork(districtSaga),
    fork(wardSaga),
    fork(localbodySaga),
    fork(groupSaga),
    fork(customerSaga),
    fork(wasteItemSaga),
    fork(menusSaga),
    fork(customerCategorySaga),
    fork(qrcodeSaga),
    fork(tariffSaga),
    fork(invoiceSaga),
    fork(receiptSaga),
    fork(packageSaga),
    fork(acctypeSaga),
    fork(accdetailSaga),
    fork(chartOfAccSaga),
    fork(creditDebitSaga),
    fork(fundTransferSaga),
    fork(incomeExpenseSaga),
    fork(popupSaga),
    fork(fetchStaffSalarySaga),
    fork(sliderSaga),
    fork(patient),
  ]);
}
