/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import { Row, Col, Button, TabPane } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
//import "./grouptherapy.scss";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import axios from "axios";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Treatmentplan = (props) => {
  const formRef = useRef();

  let { activeTabVartical, details, id, editable } = props;
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientOptions, setPatientOptions] = useState([]);
  const [values, setValues] = useState([{}]);
  const [isOpen, setIsOpen] = useState(false);
  const [patientvalues, setPatientValues] = useState(false);
  const [patientprevvalues, setPatientPrevValues] = useState(false);
  const [masterObject, setmasterObject] = useState({
    admission_id: props.id,
  });
  const [selectedGender, setSelectedGender] = useState(null);

  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [issues, setIssues] = useState([]);
  const [selectedValue, setSelectedValue] = useState([{}]);

  const toggle = () => setIsOpen(!isOpen);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();

  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetch_all_patients();
    //fetch_previous_grouptherapy_details()
    fetch_all_treatmentplan_goals(props);
  }, [props]);

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.fname + " " + el?.lname + "-" + el?.reg_no,
              value: el?._id,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  const fetch_all_treatmentplan_goals = (props) => {
    return axios
      .get(`${API_URL}options/treatmentplan_goals?admission_id=` + props.id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let datas = {};
        let data = res.data.data;

        setIssues(data);
        setValues(data);
        return data;
      });
  };
  // const handleSelectChange = async (selected, type) => {
  //     switch (type) {
  //         case "admission_id":
  //             setSelectedPatient(selected);
  //             setPatientValues(true)

  //             //fetch_previous_medication_details(selected.value)
  //             //let data = await fetch_patient_details(selected.value);

  //             let goals=await fetch_all_treatmentplan_goals(selected.value)
  //             // //console.log("previous_data", previous_data)
  //             setmasterObject({
  //                 ...masterObject,
  //                 // mobile: data.mobile,
  //                 // age: data.age,
  //                 // gender: data.gender,
  //                 // admitted_date: data.admitted_date,
  //                 admission_id: selected.value,
  //                 //counsellor: data.counsellor,

  //             });
  //             setValues(goals)

  //             break;

  //         default:
  //             break;
  //     }
  // };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  function handleTableInputchange(e, index, goal) {
    let { name, value } = e.target;
    const list = [...values];
    //list[index]={goal:'',activities:'',time_frame:''}
    //list[index].goal = goal;
    list[index][name] = value;
    setValues(list);
    setmasterObject({ ...masterObject, treatment_plan: list });
  }

  const handleValidSubmit = (data) => {
    axios
      .post(`${API_URL}admission/treatment-plan`, masterObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        toastr.success("Treatment Plan added successfully");

        formRef.current.reset();

        setValues([
          {
            ...values,
            activities: "",
            time_frame: "",
          },
        ]);
        setmasterObject({});
        setSelectedPatient(null);
        setPatientValues(false);
        setPatientPrevValues(false);
        //handleTableData();
        fetch_all_treatmentplan_goals(props);
      })
      .catch((err) => {
        console.log(err);
        return;
      });
    // }
  };

  return (
    <TabPane tabId={2}>
      <React.Fragment>
        <Row>
          <Col xl="12">
            <AvForm
              ref={formRef}
              className="needs-validation"
              onValidSubmit={() => {
                handleValidSubmit(masterObject);
              }}>
              <Row>
                <Col xl="11">
                  <div className="mt-3">
                    <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                      <TableHead>
                        <TableRow style={{ textAlign: "center" }}>
                          <TableCell
                            style={{
                              // width: "180px",
                              //textAlign: "center",
                              fontWeight: "200",
                              fontSize: "12.819px",
                              // fontFamily: "IBM Plex Sans, sans-serif",
                              color: "#495057",
                            }}>
                            No.
                          </TableCell>
                          <TableCell
                            style={{
                              //width: "5px",
                              textAlign: "center",
                              fontWeight: "500",
                              fontSize: "12.819px",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              color: "#495057",
                            }}>
                            Short-term and long-term
                          </TableCell>
                          <TableCell
                            style={{
                              //  width: "150px",
                              textAlign: "center",
                              fontWeight: "500",
                              fontSize: "12.819px",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              color: "#495057",
                            }}>
                            Specific activites to be undertaken
                          </TableCell>
                          <TableCell
                            style={{
                              // width: "100px",
                              textAlign: "center",
                              fontWeight: "500",
                              fontSize: "12.819px",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              color: "#495057",
                            }}>
                            Time frame
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values &&
                          values.map((item, index) => (
                            <TableRow>
                              <TableCell
                                style={{
                                  // width: "180px",
                                  textAlign: "center",
                                  fontWeight: "200",
                                  fontSize: "12.819px",
                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                  color: "#495057",
                                }}>
                                {index + 1}
                              </TableCell>
                              <TableCell
                                style={{
                                  // width: "180px",
                                  textAlign: "left",
                                  fontWeight: "200",
                                  fontSize: "12.819px",
                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                  color: "#495057",
                                }}>
                                {item?.goal}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  textAlign: "center",
                                  fontSize: "12.819px",
                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                  color: "#495057",
                                  fontWeight: "200",
                                }}>
                                <AvField
                                  // disabled={!editable}
                                  name="activities"
                                  type="text"
                                  className="form-control"
                                  // placeholder="Name"
                                  //id="validationCustom05"
                                  id={`${item.name}`}
                                  onChange={(e) => handleTableInputchange(e, index, item?.name)}
                                  value={item.activities}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  textAlign: "center",
                                  fontSize: "12.819px",
                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                  color: "#495057",
                                  fontWeight: "200",
                                  // display: "flex",
                                  // gap: "10px"
                                }}>
                                <AvField
                                  //disabled={!editable}
                                  name="time_frame"
                                  type="text"
                                  className="form-control"
                                  // placeholder="Name"
                                  id={`${item.name}`}
                                  onChange={(e) => handleTableInputchange(e, index, item?.name)}
                                  value={item.time_frame}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="1" className="mt-0">
                  <FormGroup className="float-right ">
                    {/* {grouptherapyIdTobeUpdated ? (
                                                        <Button color="primary" type="submit">
                                                            {"Update"}
                                                        </Button>
                                                    ) : ( */}
                    <Button color="primary" type="submit">
                      {"Submit"}
                    </Button>
                    {/* )} */}
                  </FormGroup>
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </React.Fragment>
    </TabPane>
  );
};

export default Treatmentplan;
