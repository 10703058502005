/* eslint-disable */
import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_COMPANIES_OPTIONS,
  GET_COMPANIES_MASTER_OPTIONS,
  SAVE_SELECTED_COMPANY,
  GET_LOCALBODIES_MASTER_OPTIONS,
  ADD_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY
} from "./actionTypes";

import {
  getCompaniesOptionsSuccess,
  getCompaniesOptionsFail,
  getCompaniesMasterOptionsSuccess,
  getCompaniesMasterOptionsFail,
  saveCompanyNameSuccess,
  saveCompanyNameFail,
  getLocalbodiesMasterOptionsSuccess,
  getLocalbodiesMasterOptionsFail,
  addCompanySuccess,
  addCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail
} from "./actions";

//Include Both Helper File with needed methods
import {
  getCompaniesOptions,
  getCompaniesMasterOptions,
  saveCompanyName,
  getLocalbodiesMasterOptions,
  addCompany,
  updateCompany,
  deleteCompany,
} from "../../helpers/backend_helper";

function* fetchCompanies() {
  try {
    const response = yield call(getCompaniesOptions);
    yield put(getCompaniesOptionsSuccess(response));
  } catch (error) {
    yield put(getCompaniesOptionsFail(error));
  }
}

function* fetchMasterCompanies({ company_id: company_id }) {
  try {
    const response = yield call(getCompaniesMasterOptions, company_id);
    yield put(getCompaniesMasterOptionsSuccess(response));
  } catch (error) {
    yield put(getCompaniesMasterOptionsFail(error));
  }
}
function* onSavecompany({ payload: data }) {
  try {
    const response = yield call(saveCompanyName, data);
    yield put(saveCompanyNameSuccess(response));
  } catch (error) {
    yield put(saveCompanyNameFail(error));
  }
}
function* fetchMasterCompaniesLocalbodies({ company_id: company_id }) {
  try {
    const response = yield call(getLocalbodiesMasterOptions, company_id);
    yield put(getLocalbodiesMasterOptionsSuccess(response));
  } catch (error) {
    yield put(getLocalbodiesMasterOptionsFail(error));
  }
}
function* onAddCompany({ payload: data }) {
  try {
    const response = yield call(addCompany, data);
    yield put(addCompanySuccess(response));
  } catch (error) {
    yield put(addCompanyFail(error.response));
  }
}
function* onUpdateCompany({ payload: data }) {
  try {
    const response = yield call(updateCompany, data);
    yield put(updateCompanySuccess(response));
  } catch (error) {
    yield put(updateCompanyFail(error.response));
  }
}

function* ondeleteCompany({ payload: companyId }) {
  try {
    const response = yield call(deleteCompany, companyId);
    yield put(deleteCompanySuccess(response));
  } catch (error) {
    yield put(deleteCompanyFail(error.response));
  }
}

function* companiesSaga() {
  yield takeEvery(GET_COMPANIES_OPTIONS, fetchCompanies);
  yield takeEvery(GET_COMPANIES_MASTER_OPTIONS, fetchMasterCompanies);
  yield takeEvery(SAVE_SELECTED_COMPANY, onSavecompany);
  yield takeEvery(
    GET_LOCALBODIES_MASTER_OPTIONS,
    fetchMasterCompaniesLocalbodies
  );
  yield takeEvery(ADD_COMPANY,onAddCompany);
  yield takeEvery(UPDATE_COMPANY,onUpdateCompany);
  yield takeEvery(DELETE_COMPANY,ondeleteCompany);
}

export default companiesSaga;
