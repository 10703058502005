/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Resete from "@mui/icons-material/ResetTvRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Label, Media, CardHeader, Collapse, Form, Input } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./assessment.scss";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import toastr from "toastr";
// import { getUsers } from "../../store/actions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Assessment = (props) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const history = useHistory();

  const [masterObject, setmasterObject] = useState({});

  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientOptions, setPatientOptions] = useState([]);

  const [selectedDrugFree, setSelectedDrugFree] = useState("");
  const [physicalWellBeing, setPhysicalWellBeing] = useState("");
  const [psychologicalWellBeing, setPsychologicalWellBeing] = useState("");
  const [healthRelationship, setHealthRelationship] = useState("");
  const [crimeFree, setCrimeFree] = useState("");
  const [gainfullyEmployed, setGainfullyEmployed] = useState("");
  const [stability, setStability] = useState("");

  const [patientValues, setPatientValues] = useState(false);
  const [patientPrevValues, setPatientPrevValues] = useState(false);
  const [selectGender, setSelectGender] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [tableData, setTableData] = useState([]);

  const [assessmentIdToBeUpdated, setAssessmentIdToBeUpdated] = useState(null);
  const [assessmentIdToBeDeleted, setAssessmentIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);

  useEffect(() => {
    fetch_all_patients();
    handleTableData();
    fetch_previous_assessment_details();
  }, []);

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: `${el?.fname || ""}  ${el?.lname || ""}  ${el?.reg_no ? `- ${el?.reg_no}` : "" || ""}`,
              value: el?._id,
            };
          });

        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  const fetch_patient_details = (_id) => {
    return axios
      .get(`${API_URL}admission?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let data = {};
        data.mobile = result?.basic_details?.basic_id?.mobile;
        data.age = result?.basic_details?.age;
        data.counsellor = result?.counsellor_id?.firstName + " " + result?.counsellor_id?.lastName;
        data.admitted_date = moment(result?.date).format("DD-MM-YYYY");

        let gender_value = {};

        if (result?.basic_details?.gender == 0) {
          data.gender = "Female";
        }

        if (result?.basic_details?.gender == 1) {
          data.gender = "Male";
        }

        if (result?.basic_details?.gender == 2) {
          data.gender = "Others";
        }

        setSelectGender(gender_value);
        return data;
      });
  };

  function fetch_previous_assessment_details(_id) {
    return axios
      .get(`${API_URL}group-therapy/latest?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        if (result.length > 0) {
          setPatientPrevValues(true);
        } else {
          setPatientPrevValues(false);
        }

        return result;
      });
  }

  function handleTableData() {
    var url = `${API_URL}recovery-assessment/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          item.name = `${item?.admission_id?.basic_details?.basic_id?.fname} ${item?.admission_id?.basic_details?.basic_id?.lname} ${
            item?.admission_id?.admission_times?.label || ""
          }`;
          item.name1 = (
            <span
              className="table-link-hover"
              onClick={() =>
                history.push("/admission/" + item?.admission_id?.reg_no || "", {
                  id: item?.admission_id._id,
                })
              }>
              {`${item?.admission_id?.basic_details?.basic_id?.fname} ${item?.admission_id?.basic_details?.basic_id?.lname} ${
                item?.admission_id?.admission_times?.label || ""
              }`}
            </span>
          );
          item.reg_no = item?.admission_id?.reg_no;
          item.staff = item?.addedBy?.firstName + " " + item?.addedBy?.lastName;
          item.date = moment(item?.date).format("DD-MM-YYYY");

          if (props.options.edit) {
            item.action = (
              <>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}></i>

                <i
                  className="fas fa-edit"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    preUpdateAssessment(item);
                    toTop();
                  }}></i>

                <i
                  className="fas fa-trash-alt"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setAssessmentIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </>
            );
          } else {
            item.action = (
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
            );
          }
        });

        setTableData(result);
      });
  }

  const customStyles = {
    lineHeight: "1.8",
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const preUpdateAssessment = (item) => {
    setAssessmentIdToBeUpdated(item?._id);
    axios
      .get(`${API_URL}recovery-assessment?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let item = {};
        item._id = result?._id;
        item.admission_id = result?.admission_id?._id;
        item.reg_no = result?.admission_id?.reg_no;
        item.staff = result?.addedBy?.firstName + " " + result?.addedBy?.lastName;
        item.date = result?.date;

        item.drug_free_life = result?.drug_free_life;
        item.physical_well_being = result?.physical_well_being;
        item.psychological_well_being = result?.psychological_well_being;
        item.health_relationship = result?.health_relationship;
        item.crime_free = result?.crime_free;
        item.gainfully_employed = result?.gainfully_employed;
        item.financial_stability = result?.financial_stability;
        item.counsellor_comments = result?.counsellor_comments;
        item.remarks = result?.remarks;
        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };
        setSelectedPatient(patient);

        if (result.crime_free == "3") {
          setCrimeFree({ label: "Severe relapses, sober", value: "3" });
        }

        //  Alcohol/drug free life
        if (result?.drug_free_life == 0) {
          setSelectedDrugFree({ label: "No News", value: "0" });
        }

        if (result?.drug_free_life == 1) {
          setSelectedDrugFree({ label: "Continues to drink", value: "1" });
        }

        if (result?.drug_free_life == 2) {
          setSelectedDrugFree({ label: "Substituting with drugs", value: "2" });
        }

        if (result?.drug_free_life == 3) {
          setSelectedDrugFree({ label: "Severe relapses, sober", value: "3" });
        }

        if (result?.drug_free_life == 4) {
          setSelectedDrugFree({ label: "Mild relapses, sober", value: "4" });
        }

        if (result?.drug_free_life == 5) {
          setSelectedDrugFree({ label: "No relapse", value: "5" });
        }

        // Physical Well Being
        if (result?.physical_well_being == 0) {
          setPhysicalWellBeing({ label: "No News", value: "0" });
        }

        if (result?.physical_well_being == 1) {
          setPhysicalWellBeing({
            label: "Persistent inability to function due to poor physical condition",
            value: "1",
          });
        }

        if (result?.physical_well_being == 2) {
          setPhysicalWellBeing({
            label: "Unable to function most of the times due to poor physical condition",
            value: "2",
          });
        }

        if (result?.physical_well_being == 3) {
          setPhysicalWellBeing({
            label: "Average health with transient problems",
            value: "3",
          });
        }

        if (result?.physical_well_being == 4) {
          setPhysicalWellBeing({
            label: "Absent or minimal symptoms but functions well generally",
            value: "4",
          });
        }

        if (result?.physical_well_being == 5) {
          setPhysicalWellBeing({
            label: "Superior functioning with no problems",
            value: "5",
          });
        }

        //  Psychological Well Being
        if (result?.psychological_well_being == 0) {
          setPsychologicalWellBeing({ label: "No News", value: "0" });
        }

        if (result?.psychological_well_being == 1) {
          setPsychologicalWellBeing({
            label: "Persistent inability to function due to poor psychological condition",
            value: "1",
          });
        }

        if (result?.psychological_well_being == 2) {
          setPsychologicalWellBeing({
            label: "Unable to function most of the times due to poor psychological condition",
            value: "2",
          });
        }

        if (result?.psychological_well_being == 3) {
          setPsychologicalWellBeing({
            label: "Average health with transient problems",
            value: "3",
          });
        }

        if (result?.psychological_well_being == 4) {
          setPsychologicalWellBeing({
            label: "Absent or minimal symptoms but functions well generally",
            value: "4",
          });
        }

        if (result?.psychological_well_being == 5) {
          setPsychologicalWellBeing({
            label: "Superior functioning with no problems",
            value: "5",
          });
        }

        // Healthy relationship with family members
        if (result?.health_relationship == 0) {
          setHealthRelationship({ label: "No News", value: "0" });
        }

        if (result?.health_relationship == 1) {
          setHealthRelationship({ label: "Disowned by family", value: "1" });
        }

        if (result?.health_relationship == 2) {
          setHealthRelationship({
            label: "By and large alienated from family",
            value: "2",
          });
        }

        if (result?.health_relationship == 3) {
          setHealthRelationship({
            label: "Mixed or indifferent feelings",
            value: "3",
          });
        }

        if (result?.health_relationship == 4) {
          setHealthRelationship({
            label: "Usually friendly,minor conflicts",
            value: "4",
          });
        }

        if (result?.health_relationship == 5) {
          setHealthRelationship({ label: "Highly supportive", value: "5" });
        }

        // Crime Free
        if (result?.crime_free == 0) {
          setCrimeFree({ label: "No News", value: "0" });
        }

        if (result?.crime_free == 1) {
          setCrimeFree({
            label: "Arrest for illicit sale and production",
            value: "1",
          });
        }

        if (result?.crime_free == 2) {
          setCrimeFree({ label: "Arrest for violent behaviour", value: "2" });
        }

        if (result?.crime_free == 3) {
          setCrimeFree({
            label: "Violent under intoxication (not arrested)",
            value: "3",
          });
        }

        if (result?.crime_free == 4) {
          setCrimeFree({ label: "Crime free", value: "4" });
        }

        if (result?.crime_free == 5) {
          setCrimeFree({ label: "Adopting higher values", value: "5" });
        }

        // Gainfully Employed
        if (result?.gainfully_employed == 0) {
          setGainfullyEmployed({ label: "No News", value: "0" });
        }

        if (result?.gainfully_employed == 1) {
          setGainfullyEmployed({ label: "Illegal employment", value: "1" });
        }

        if (result?.gainfully_employed == 2) {
          setGainfullyEmployed({ label: "Unemployed", value: "2" });
        }

        if (result?.gainfully_employed == 3) {
          setGainfullyEmployed({ label: "Irregular for work", value: "3" });
        }

        if (result?.gainfully_employed == 4) {
          setGainfullyEmployed({ label: "Mostly regular", value: "4" });
        }

        if (result?.gainfully_employed == 5) {
          setGainfullyEmployed({
            label: "Regular for work and productive",
            value: "5",
          });
        }

        //   Financial Stability
        if (result?.financial_stability == 0) {
          setStability({ label: "No News", value: "0" });
        }

        if (result?.financial_stability == 1) {
          setStability({
            label: "Severe debts, difficult to repay",
            value: "1",
          });
        }

        if (result?.financial_stability == 2) {
          setStability({
            label: "Moderate level of debts, can be repaid",
            value: "2",
          });
        }

        if (result?.financial_stability == 3) {
          setStability({ label: "No debts, no savings", value: "3" });
        }

        if (result?.financial_stability == 4) {
          setStability({ label: "Average savings", value: "4" });
        }

        if (result?.financial_stability == 5) {
          setStability({ label: "High level of savings", value: "4" });
        }

        setmasterObject(item);
      });
  };

  const handleValidSubmit = () => {
    let data = {
      _id: masterObject._id,
      reg_no: masterObject.reg_no,
      counsellor_comments: masterObject.counsellor_comments,
      crime_free: masterObject.crime_free,
      drug_free_life: masterObject.drug_free_life,
      financial_stability: masterObject.financial_stability,
      gainfully_employed: masterObject.gainfully_employed,
      health_relationship: masterObject.health_relationship,
      physical_well_being: masterObject.physical_well_being,
      psychological_well_being: masterObject.psychological_well_being,
      remarks: masterObject.remarks,
      admission_id: masterObject._id,
    };

    if (assessmentIdToBeUpdated) {
      axios
        .put(`${API_URL}recovery-assessment/`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Assessment updated successfully");

          formRef.current.reset();

          setmasterObject({});
          setAssessmentIdToBeUpdated(null);
          setSelectedPatient(null);
          setSelectedDrugFree(null);
          setPhysicalWellBeing(null);
          setPsychologicalWellBeing(null);
          setCrimeFree(null);
          setGainfullyEmployed(null);
          setHealthRelationship(null);
          setStability(null);
          setPatientValues(false);
          setPatientPrevValues(false);
          handleTableData();
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    } else {
      axios
        .post(`${API_URL}recovery-assessment/`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Assessment added successfully");

          formRef.current.reset();

          setmasterObject({});
          setCrimeFree(null);
          setGainfullyEmployed(null);
          setHealthRelationship(null);
          setPhysicalWellBeing(null);
          setPsychologicalWellBeing(null);
          setSelectedDrugFree(null);
          setSelectedPatient(null);
          setStability(null);
          setPatientValues(false);
          setPatientPrevValues(false);
          handleTableData();
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }
  };

  const handleSelectChange = async (selected, type) => {
    switch (type) {
      case "admission_id":
        setSelectedPatient(selected);
        setPatientValues(true);

        let data = await fetch_patient_details(selected.value);
        let previous_data = await fetch_previous_assessment_details(selected.value);

        setmasterObject({
          ...masterObject,
          mobile: data.mobile,
          gender: data.gender,
          age: data.age,
          admitted_date: data.admitted_date,
          admission_id: selected.value,
          counsellor: data.counsellor,
          prev_date: moment(previous_data?.date).format("DD-MM-YYYY"),
          prev_counsellor: previous_data.counsellor,
          prev_therapist: previous_data.therapist,
          previous_data: previous_data,
        });

        break;

      case "drug_free_life":
        setSelectedDrugFree(selected);
        setmasterObject({
          ...masterObject,
          drug_free_life: selected.value,
        });
        break;

      case "physical_well_being":
        setPhysicalWellBeing(selected);
        setmasterObject({
          ...masterObject,
          physical_well_being: selected.value,
        });
        break;

      case "psychological_well_being":
        setPsychologicalWellBeing(selected);
        setmasterObject({
          ...masterObject,
          psychological_well_being: selected.value,
        });
        break;

      case "health_relationship":
        setHealthRelationship(selected);
        setmasterObject({
          ...masterObject,
          health_relationship: selected.value,
        });
        break;

      case "crime_free":
        setCrimeFree(selected);
        setmasterObject({
          ...masterObject,
          crime_free: selected.value,
        });
        break;

      case "gainfully_employed":
        setGainfullyEmployed(selected);
        setmasterObject({
          ...masterObject,
          gainfully_employed: selected.value,
        });
        break;

      case "financial_stability":
        setStability(selected);
        setmasterObject({
          ...masterObject,
          financial_stability: selected.value,
        });
        break;

      default:
        break;
    }
  };

  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };

  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}recovery-assessment?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let item = {};
        item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        item.counsellor_comments = result?.counsellor_comments;
        item.reg_no = result?.admission_id?.reg_no;
        item.remarks = result?.remarks;
        item.staff = result?.addedBy?.firstName + " " + result?.addedBy?.lastName;
        item.date = moment(result?.date).format("DD-MM-YYYY");

        //  Alcohol/drug free life
        if (result?.drug_free_life == 0) {
          item.drug_free_life = "No news";
        }

        if (result?.drug_free_life == 1) {
          item.drug_free_life = "Continues to drink";
        }

        if (result?.drug_free_life == 2) {
          item.drug_free_life = "Substituting with drugs";
        }

        if (result?.drug_free_life == 3) {
          item.drug_free_life = "Severe relapses, sober";
        }

        if (result?.drug_free_life == 4) {
          item.drug_free_life = "Mild relapses, sober";
        }

        if (result?.drug_free_life == 5) {
          item.drug_free_life = "No relapse";
        }

        // Physical Well Being
        if (result?.physical_well_being == 0) {
          item.physical_well_being = "No news";
        }

        if (result?.physical_well_being == 1) {
          item.physical_well_being = "Persistent inability to function due to poor physical condition";
        }

        if (result?.physical_well_being == 2) {
          item.physical_well_being = "Unable to function most of the times due to poor physical condition";
        }

        if (result?.physical_well_being == 3) {
          item.physical_well_being = "Average health with transient problems";
        }

        if (result?.physical_well_being == 4) {
          item.physical_well_being = "Absent or minimal symptoms but functions well generally";
        }

        if (result?.physical_well_being == 5) {
          item.physical_well_being = "Superior functioning with no problems";
        }

        //  Psychological Well Being
        if (result?.psychological_well_being == 0) {
          item.psychological_well_being = "No news";
        }

        if (result?.psychological_well_being == 1) {
          item.psychological_well_being = "Persistent inability to function due to poor psychological condition";
        }

        if (result?.psychological_well_being == 2) {
          item.psychological_well_being = "Unable to function most of the times due to poor psychological condition";
        }

        if (result?.psychological_well_being == 3) {
          item.psychological_well_being = "Average health with transient problems";
        }

        if (result?.psychological_well_being == 4) {
          item.psychological_well_being = "Absent or minimal symptoms but functions well generally";
        }

        if (result?.psychological_well_being == 5) {
          item.psychological_well_being = "Superior functioning with no problems";
        }

        // Healthy relationship with family members
        if (result?.health_relationship == 0) {
          item.health_relationship = "No news";
        }

        if (result?.health_relationship == 1) {
          item.health_relationship = "Disowned by family";
        }

        if (result?.health_relationship == 2) {
          item.health_relationship = "By and large alienated from family";
        }

        if (result?.health_relationship == 3) {
          item.health_relationship = "Mixed or indifferent feelings";
        }

        if (result?.health_relationship == 4) {
          item.health_relationship = "Usually friendly,minor conflicts";
        }

        if (result?.health_relationship == 5) {
          item.health_relationship = "Highly supportive";
        }

        // Crime Free
        if (result?.crime_free == 0) {
          item.crime_free = "No news";
        }

        if (result?.crime_free == 1) {
          item.crime_free = "Arrest for illicit sale and production";
        }

        if (result?.crime_free == 2) {
          item.crime_free = "Arrest for violent behaviour";
        }

        if (result?.crime_free == 3) {
          item.crime_free = "Violent under intoxication (not arrested)";
        }

        if (result?.crime_free == 4) {
          item.crime_free = "Crime free";
        }

        if (result?.crime_free == 5) {
          item.crime_free = "Adopting higher values";
        }

        // Gainfully Employed
        if (result?.gainfully_employed == 0) {
          item.gainfully_employed = "No news";
        }

        if (result?.gainfully_employed == 1) {
          item.gainfully_employed = "Illegal employment";
        }

        if (result?.gainfully_employed == 2) {
          item.gainfully_employed = "Unemployed";
        }

        if (result?.gainfully_employed == 3) {
          item.gainfully_employed = "Irregular for work";
        }

        if (result?.gainfully_employed == 4) {
          item.gainfully_employed = "Mostly regular";
        }

        if (result?.gainfully_employed == 5) {
          item.gainfully_employed = "Regular for work and productive";
        }

        //   Financial Stability
        if (result?.financial_stability == 0) {
          item.financial_stability = "No news";
        }

        if (result?.financial_stability == 1) {
          item.financial_stability = "Severe debts, difficult to repay";
        }

        if (result?.financial_stability == 2) {
          item.financial_stability = "Moderate level of debts, can be repaid";
        }

        if (result?.financial_stability == 3) {
          item.financial_stability = "No debts, no savings";
        }

        if (result?.financial_stability == 4) {
          item.financial_stability = "Average savings";
        }

        if (result?.financial_stability == 5) {
          item.financial_stability = "High level of savings";
        }

        setPopupData(item);
        setPopupView(true);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reg No",
        field: "reg_no",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  useEffect(() => {
    data.rows.map((item) => {
      if (props.options.edit) {
        item.action = (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                handleClickOpen(item);
              }}></i>

            <i
              className="fas fa-edit"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                preUpdateAssessment(item);
                toTop();
              }}></i>

            <i
              className="fas fa-trash-alt"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
              }}
              onClick={() => {
                setAssessmentIdToBeDeleted(item._id);
                setConfirmDeleteAlert(true);
              }}></i>
          </>
        );
      } else {
        item.action = (
          <i
            className="fas fa-eye"
            style={{
              fontSize: "0.9em",
              cursor: "pointer",
              marginLeft: "0.1em",
              marginRight: "0.4em",
            }}
            onClick={() => {
              handleClickOpen(item);
            }}></i>
        );
      }
    });
  }, props.options);

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}recovery-assessment?_id=` + assessmentIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Assessment deleted successfully");

                  if (masterObject && masterObject._id === assessmentIdToBeDeleted) {
                    formRef.current.reset();
                    setmasterObject({});
                    setAssessmentIdToBeDeleted(null);
                  }
                  setAssessmentIdToBeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Assessment");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Assessment Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="close"></button>
        </div>

        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table id="product_available_day" className="table table-bordered dataTable">
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>Name :</td>
                <td>{popupData?.name}</td>
                <td style={{ paddingLeft: "25px" }}>Reg No. :</td>
                <td style={{ textAlign: "left" }}>{popupData?.reg_no}</td>
              </tr>

              <tr>
                <td style={{ padding: "10px" }}>Remarks :</td>
                <td>{popupData?.remarks}</td>
                <td style={{ paddingLeft: "25px" }}>Counsellor's comment :</td>
                <td style={{ textAlign: "left" }}>{popupData?.counsellor_comments}</td>
              </tr>

              <tr>
                <td style={{ paddingLeft: "10px" }}>Date :</td>
                <td>{popupData?.date}</td>
                <td style={{ padding: "25px" }}>Staff :</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
              </tr>
            </table>

            <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
              <TableHead>
                <TableRow style={{ textAlign: "center" }}>
                  <TableCell
                    style={{
                      width: "5px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    No.
                  </TableCell>

                  <TableCell
                    style={{
                      width: "115px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Areas of improvement
                  </TableCell>

                  <TableCell
                    style={{
                      width: "150px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      // width: "180px",
                      textAlign: "center",
                      fontWeight: "200",
                      fontSize: "12.819px",
                      // fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    1
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "left",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                    }}>
                    Alcohol/drug free life
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "center",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                      display: "flex",
                      gap: "10px",
                    }}>
                    {popupData?.drug_free_life}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      // width: "180px",
                      textAlign: "center",
                      fontWeight: "200",
                      fontSize: "12.819px",
                      // fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    2
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "left",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                    }}>
                    Physical well being
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "center",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                      display: "flex",
                      gap: "10px",
                    }}>
                    {popupData?.physical_well_being}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      // width: "180px",
                      textAlign: "center",
                      fontWeight: "200",
                      fontSize: "12.819px",
                      // fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    3
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "left",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                    }}>
                    Psychological well being
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "center",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                      display: "flex",
                      gap: "10px",
                    }}>
                    {popupData?.psychological_well_being}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      // width: "180px",
                      textAlign: "center",
                      fontWeight: "200",
                      fontSize: "12.819px",
                      // fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    4
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "left",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                    }}>
                    Healthy relationship with family members
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "center",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                      display: "flex",
                      gap: "10px",
                    }}>
                    {popupData?.health_relationship}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      // width: "180px",
                      textAlign: "center",
                      fontWeight: "200",
                      fontSize: "12.819px",
                      // fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    5
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "left",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                    }}>
                    Crime free
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "center",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                      display: "flex",
                      gap: "10px",
                    }}>
                    {popupData?.crime_free}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      // width: "180px",
                      textAlign: "center",
                      fontWeight: "200",
                      fontSize: "12.819px",
                      // fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    6
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "left",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                    }}>
                    Gainfully employed
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "center",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                      display: "flex",
                      gap: "10px",
                    }}>
                    {popupData?.gainfully_employed}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      // width: "180px",
                      textAlign: "center",
                      fontWeight: "200",
                      fontSize: "12.819px",
                      // fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}>
                    7
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "left",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                    }}>
                    Financial stability
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      textAlign: "center",
                      fontSize: "12.819px",
                      //fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                      fontWeight: "200",
                      display: "flex",
                      gap: "10px",
                    }}>
                    {popupData?.financial_stability}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Assessment" />
          {props.options.edit && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}>
                      <Row>
                        <Col md="3">
                          <div className="mt-1">
                            <Label>Patient</Label>
                            <Select
                              name="admission_id"
                              value={selectedPatient}
                              // menuPosition="fixed"
                              onChange={(selected) => {
                                handleSelectChange(selected, "admission_id");
                              }}
                              options={patientOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        {patientValues == true ? (
                          <>
                            <Col md="9">
                              <Row>
                                <div className="mt-1">
                                  <h6>Patient Basic Details:</h6>
                                </div>
                              </Row>

                              <Row>
                                <Col md="3" className="mt-1">
                                  <div>
                                    <Label>Mobile:</Label>
                                    <span>{masterObject.mobile}</span>
                                  </div>
                                </Col>

                                <Col md="2" className="mt-1">
                                  <div>
                                    <Label>Gender:</Label>
                                    <span>{masterObject.gender}</span>
                                  </div>
                                </Col>

                                <Col md="1" className="mt-1">
                                  <div>
                                    <Label>Age:</Label>
                                    <span>{masterObject.age}</span>
                                  </div>
                                </Col>

                                <Col md="3" className="mt-1">
                                  <div>
                                    <Label>Admitted Date:</Label>
                                    <span>{masterObject.admitted_date}</span>
                                  </div>
                                </Col>

                                <Col md="3" className="mt-1">
                                  <div>
                                    <Label>Counsellor:</Label>
                                    <span>{masterObject.counsellor}</span>
                                  </div>
                                </Col>
                              </Row>
                            </Col>

                            {patientPrevValues == true ? (
                              <Row>
                                <div className="mt-0">
                                  <Card style={{ background: "#f6f6f6" }}>
                                    <Link to="#" onClick={toggle} className="text-dark">
                                      <div className="p-2">
                                        <Media className="d-flex aign-items-center">
                                          <div className="flex-1 overflow-hidden">
                                            <h6>Previous Assessment Details</h6>
                                          </div>

                                          <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                        </Media>
                                      </div>
                                    </Link>

                                    <Collapse isOpen={isOpen}>
                                      <div>
                                        <Table style={{ textAlign: "center" }} className="newtable">
                                          <TableHead>
                                            <TableRow style={{ textAlign: "center" }}>
                                              <TableCell
                                                style={{
                                                  textAlign: "center",
                                                  border: "1px solid black !important",
                                                  fontWeight: "bold",
                                                }}>
                                                No.
                                              </TableCell>

                                              <TableCell
                                                style={{
                                                  textAlign: "center",
                                                  border: "1px solid black !important",
                                                  fontWeight: "bold",
                                                }}>
                                                Date
                                              </TableCell>

                                              <TableCell
                                                style={{
                                                  textAlign: "center",
                                                  border: "1px solid black !important",
                                                  fontWeight: "bold",
                                                }}>
                                                Therapist
                                              </TableCell>

                                              <TableCell
                                                style={{
                                                  textAlign: "center",
                                                  border: "1px solid black !important",
                                                  fontWeight: "bold",
                                                }}>
                                                Counsellor
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>

                                          <TableBody>
                                            {masterObject?.previous_data &&
                                              masterObject?.previous_data.map((item, index) => (
                                                <TableRow>
                                                  <TableCell
                                                    style={{
                                                      // width: "180px",
                                                      //textAlign: "center",
                                                      fontWeight: "200",
                                                      fontSize: "12.819px",
                                                      // fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                    }}>
                                                    {index + 1}
                                                  </TableCell>

                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      //textAlign: "center",
                                                      fontSize: "12.819px",
                                                      //fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                      fontWeight: "200",
                                                      //verticalAlign:"top"
                                                    }}>
                                                    {item?.date}
                                                  </TableCell>

                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      //textAlign: "center",
                                                      fontSize: "12.819px",
                                                      // fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                      fontWeight: "200",
                                                    }}>
                                                    {item?.therapist}
                                                  </TableCell>

                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      //textAlign: "center",
                                                      fontSize: "12.819px",
                                                      // fontFamily: "IBM Plex Sans, sans-serif",
                                                      color: "#495057",
                                                      fontWeight: "200",
                                                    }}>
                                                    {item?.counsellor}
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                          </TableBody>
                                        </Table>
                                      </div>
                                    </Collapse>
                                  </Card>
                                </div>
                              </Row>
                            ) : null}
                          </>
                        ) : null}

                        <Col md="3">
                          <div className="mt-1">
                            <Label>Alcohol/drug free life</Label>
                            <Select
                              //  menuPosition="fixed"
                              name="drug_free_life"
                              value={selectedDrugFree}
                              options={[
                                { label: "No News", value: "0" },
                                { label: "Continues to drink", value: "1" },
                                { label: "Substituting with drugs", value: "2" },
                                { label: "Severe relapses, sober", value: "3" },
                                { label: "Mild relapses, sober", value: "4" },
                                { label: "No relapse", value: "5" },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "drug_free_life");
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mt-1">
                            <Label>Physical Well Being</Label>
                            <Select
                              name="physical_well_being"
                              value={physicalWellBeing}
                              // menuPosition="fixed"
                              options={[
                                { label: "No News", value: "0" },
                                {
                                  label: "Persistent inability to function due to poor physical condition",
                                  value: "1",
                                },
                                {
                                  label: "Unable to function most of the times due to poor physical condition",
                                  value: "2",
                                },
                                {
                                  label: "Average health with transient problems",
                                  value: "3",
                                },
                                {
                                  label: "Absent or minimal symptoms but functions well generally",
                                  value: "4",
                                },
                                {
                                  label: "Superior functioning with no problems",
                                  value: "5",
                                },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "physical_well_being");
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mt-1">
                            <Label>Psychological Well Being</Label>
                            <Select
                              name="psychological_well_being"
                              value={psychologicalWellBeing}
                              // menuPosition="fixed"
                              options={[
                                { label: "No News", value: "0" },
                                {
                                  label: "Persistent inability to function due to poor psychological condition",
                                  value: "1",
                                },
                                {
                                  label: "Unable to function most of the times due to poor psychological condition",
                                  value: "2",
                                },
                                {
                                  label: "Average health with transient problems",
                                  value: "3",
                                },
                                {
                                  label: "Absent or minimal symptoms but functions well generally",
                                  value: "4",
                                },
                                {
                                  label: "Superior functioning with no problems",
                                  value: "5",
                                },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "psychological_well_being");
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mt-1">
                            <Label>Healthy relationship with family members</Label>
                            <Select
                              name="health_relationship"
                              value={healthRelationship}
                              // menuPosition="fixed"
                              options={[
                                { label: "No News", value: "0" },
                                { label: "Disowned by family", value: "1" },
                                {
                                  label: "By and large alienated from family",
                                  value: "2",
                                },
                                {
                                  label: "Mixed or indifferent feelings",
                                  value: "3",
                                },
                                {
                                  label: "Usually friendly,minor conflicts",
                                  value: "4",
                                },
                                { label: "Highly supportive", value: "5" },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "health_relationship");
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mt-1">
                            <Label>Crime free</Label>
                            <Select
                              name="crime_free"
                              value={crimeFree}
                              // menuPosition="fixed"
                              options={[
                                { label: "No News", value: "0" },
                                {
                                  label: "Arrest for illicit sale and production",
                                  value: "1",
                                },
                                {
                                  label: "Arrest for violent behaviour",
                                  value: "2",
                                },
                                {
                                  label: "Violent under intoxication (not arrested)",
                                  value: "3",
                                },
                                { label: "Crime free", value: "4" },
                                { label: "Adopting higher values", value: "5" },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "crime_free");
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mt-1">
                            <Label>Gainfully employed </Label>
                            <Select
                              name="gainfully_employed"
                              value={gainfullyEmployed}
                              // menuPosition="fixed"
                              options={[
                                { label: "No News", value: "0" },
                                { label: "Illegal employment", value: "1" },
                                { label: "Unemployed", value: "2" },
                                { label: "Irregular for work", value: "3" },
                                { label: "Mostly regular", value: "4" },
                                {
                                  label: "Regular for work and productive",
                                  value: "5",
                                },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "gainfully_employed");
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mt-1">
                            <Label>Financial stability</Label>
                            <Select
                              name="financial_stability"
                              value={stability}
                              // menuPosition="fixed"
                              options={[
                                { label: "No News", value: "0" },
                                {
                                  label: "Severe debts, difficult to repay",
                                  value: "1",
                                },
                                {
                                  label: "Moderate level of debts, can be repaid",
                                  value: "2",
                                },
                                { label: "No debts, no savings", value: "3" },
                                { label: "Average savings", value: "4" },
                                { label: "High level of savings", value: "5" },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "financial_stability");
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mt-1">
                            <Label>Remarks</Label>
                            <AvField
                              name="remarks"
                              placeholder="Remarks"
                              type="textarea"
                              className="form-control"
                              value={masterObject?.remarks}
                              onChange={handleChangeInput}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mt-1">
                            <Label>Counsellor's Comment</Label>
                            <AvField
                              name="counsellor_comments"
                              placeholder="Comments"
                              type="textarea"
                              className="form-control"
                              value={masterObject?.counsellor_comments}
                              onChange={handleChangeInput}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Col md="1" className="mt-1" style={{ padding: "10px" }}>
                        {assessmentIdToBeUpdated ? (
                          <Button color="primary" type="submit">
                            {"Update"}
                          </Button>
                        ) : (
                          <Button color="primary" type="submit">
                            {"Submit"}
                          </Button>
                        )}
                      </Col>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <MDBDataTable
                        id="assessmentTableID"
                        responsive
                        striped
                        bordered
                        data={data}
                        info={true}
                        searching={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Assessment;
