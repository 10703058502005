/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import { Row, Col, Card, CardBody, Button, Label, Media, Collapse } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getDate } from "../../helpers/globalFunctions";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./counsellingnotes.scss";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Medication = (props) => {
  const formRef = useRef();

  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientOptions, setPatientOptions] = useState([]);
  const [counsellingType, setCounsellingType] = useState("");
  const [values, setValues] = useState([{}]);
  const [isOpen, setIsOpen] = useState(false);
  const [patientvalues, setPatientValues] = useState(false);
  const [patientprevvalues, setPatientPrevValues] = useState(false);
  const [masterObject, setmasterObject] = useState({
    c_date: getDate(new Date()),
  });
  const [selectedGender, setSelectedGender] = useState(null);
  const [counsellingIdTobeDeleted, setCounsellingIdTobeDeleted] = useState(null);
  const [counsellingIdTobeUpdated, setCounsellingIdTobeUpdated] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [options, setoptions] = useState({ edit: false });

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetch_all_patients();
    handleTableData();
  }, []);

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: `${el?.fname || ""}  ${el?.lname || ""}  ${el?.reg_no ? `- ${el?.reg_no}` : "" || ""}`,
              value: el?._id,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };

  const handleSelectChange = async (selected, type) => {
    switch (type) {
      case "admission_id":
        setSelectedPatient(selected);
        setPatientValues(true);

        //fetch_previous_medication_details(selected.value)
        let data = await fetch_patient_details(selected.value);
        let previous_data = await fetch_previous_counselling_details(selected.value);
        //console.log("previous_data", previous_data);
        setmasterObject({
          ...masterObject,
          mobile: data.mobile,
          age: data.age,
          gender: data.gender,
          admission_id: selected.value,
          date: previous_data.date,
          staff: previous_data.staff,
          prev_duration: previous_data.duration,

          prev_type: previous_data.type,
          prev_issues: previous_data.issues,
          previous_data: previous_data,
        });

        break;
      case "type":
        setCounsellingType(selected);
        setmasterObject({
          ...masterObject,
          type: selected.value,
        });
      default:
        break;
    }
  };
  const fetch_patient_details = (_id) => {
    return axios
      .get(`${API_URL}admission?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let data = {};
        data.mobile = result?.basic_details?.basic_id?.mobile;
        data.age = result?.basic_details?.age;
        data.gender = result?.basic_details?.gender;
        data.counsellor = result?.counsellor_id?.firstName;
        data.admitted_date = moment(result?.date).format("DD-MM-YYYY");
        let gender_value = {};

        if (result?.basic_details?.gender == 0) {
          data.gender = "Female";
        }
        if (result?.basic_details?.gender == 1) {
          data.gender = "Male";
        }
        if (result?.basic_details?.gender == 2) {
          data.gender = "Others";
        }

        setSelectedGender(gender_value);
        return data;
      });
  };

  const fetch_previous_counselling_details = (_id) => {
    return axios
      .get(`${API_URL}counselling/latest?admission_id=` + _id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        if (result.length > 0) {
          setPatientPrevValues(true);
        } else {
          setPatientPrevValues(false);
        }
        let data = [];
        result &&
          result.map((item, index) => {
            //console.log("c_datess", item?.duration);

            data.c_date = moment(item?.c_date).format("DD-MM-YYYY");
            if (item?.updatedBy) {
              let updatedBy = item.updatedBy || {};
              if (updatedBy?.lastName) data.staff = updatedBy?.firstName + " " + updatedBy?.lastName;
              else data.staff = updatedBy?.firstName;
            } else {
              let addedBy = item.addedBy || {};
              if (item?.lastName) data.staff = addedBy?.firstName + " " + addedBy?.lastName;
              else data.staff = addedBy?.firstName;
            }
            data.duration = item?.duration;
            data.issues = item?.issues;
            if (item?.type == 0) {
              data.type = "I";
            }
            if (item?.type == 1) {
              data.type = "F";
            }
          });

        //console.log(data, "zzz");

        return data;
      });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const preUpdateCounsellingNotes = (item) => {
    setCounsellingIdTobeUpdated(item?._id);
    axios
      .get(`${API_URL}counselling?c_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let data = {};
        data.c_id = item?._id;
        // data.date = moment(result?.date).format("DD-MM-YYYY");
        data.c_date = item?.c_date;
        data.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
        data.duration = result?.duration;
        // item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname
        data.issues = result?.issues;
        data.admission_id = result?.admission_id?.basic_details?.basic_id?._id;
        data.type = result?.type;
        let type = {};
        if (result.type == 0) {
          type = {
            label: "I",
            value: 0,
          };
        } else {
          type = {
            label: "F",
            value: 1,
          };
        }
        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };
        //console.log("data", data);
        setSelectedPatient(patient);
        setCounsellingType(type);
        setmasterObject(data);
      });
  };

  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}counselling?c_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let data = {};
        data.date = moment(result?.date).format("DD-MM-YYYY");
        data.c_date = moment(result?.c_date).format("DD-MM-YYYY");
        data.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
        data.duration = result?.duration;
        data.time = moment(item?.time, "hhmmss").format("hh:mm a");
        if (result?.type == 0) {
          data.type = "I";
        } else {
          data.type = "F";
        }
        data.issues = result?.issues;
        data.staff = result?.addedBy?.firstName + " " + result?.addedBy?.lastName;

        setPopupData(data);
        setPopupView(true);
      });
  };

  function handleTableData() {
    var url = `${API_URL}counselling/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        // //console.log("result", result);
        result.map((item, index) => {
          item.id = index + 1;
          console.log(item);
          item.name = `${item?.admission_id?.basic_details?.basic_id?.fname} ${item?.admission_id?.basic_details?.basic_id?.lname} ${
            item?.admission_id?.admission_times?.label || ""
          }`;
          item.name1 = (
            <span
              className="table-link-hover"
              onClick={() =>
                history.push("/admission/" + item?.admission_id.reg_no || "", {
                  id: item?.admission_id._id,
                })
              }>
              {`${item?.admission_id?.basic_details?.basic_id?.fname} ${item?.admission_id?.basic_details?.basic_id?.lname} ${
                item?.admission_id?.admission_times?.label || ""
              }`}
            </span>
          );
          item.date = moment(item?.c_date).format("DD-MM-YYYY");
          item.reg_no = item?.admission_id?.reg_no;
          item.firstName = item?.addedBy?.firstName;
          item.duration = item?.duration;

          if (item?.type == 0) {
            item.type = "I";
          } else {
            item.type = "F";
          }

          if (props.options.edit) {
            item.action = (
              <>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    preUpdateCounsellingNotes(item);
                    toTop();
                  }}></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "0.9em", cursor: "pointer" }}
                  onClick={() => {
                    setCounsellingIdTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </>
            );
          } else {
            item.action = (
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
            );
          }
        });

        setTableData(result);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Reg No",
        field: "reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "I/F",
        field: "type",
        sort: "asc",
        width: 200,
      },
      {
        label: "Duration",
        field: "duration",
        sort: "asc",
        width: 200,
      },

      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  useEffect(() => {
    if (props.options.edit) {
      data.rows.map((row, idx) => {
        row.action = (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                handleClickOpen(row);
              }}></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                preUpdateCounsellingNotes(row);
                toTop();
              }}></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "0.9em", cursor: "pointer" }}
              onClick={() => {
                setCounsellingIdTobeDeleted(row._id);
                setConfirmDeleteAlert(true);
              }}></i>
          </>
        );
      });
    } else {
      data.rows.map((row, idx) => {
        row.action = (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                handleClickOpen(row);
              }}></i>
          </>
        );
      });
    }
  }, [props.options]);

  const customStyles = {
    lineHeight: "1.8",
  };
  const reset = () => {
    formRef.current.reset();
    // handleTableData();
  };

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
  };

  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };

  function handleTableInputchange(e, index) {
    let { name, value } = e.target;
    const list = [...values];
    list[index][name] = e.target.value;

    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
  }

  const handleValidSubmit = (e) => {
    if (counsellingIdTobeUpdated) {
      axios
        .put(`${API_URL}counselling`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Counselling Notes updated successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({
            c_date: getDate(new Date()),
          });
          setSelectedPatient(null);
          setCounsellingType(null);
          setPatientValues(false);
          setPatientPrevValues(false);
          handleTableData();
          setCounsellingIdTobeUpdated(null);
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    } else {
      axios
        .post(`${API_URL}counselling`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Counselling Notes added successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({
            c_date: getDate(new Date()),
          });
          setSelectedPatient(null);
          setCounsellingType(null);
          setPatientValues(false);
          setPatientPrevValues(false);
          handleTableData();
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}counselling?c_id=` + counsellingIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Counselling notes deleted successfully");
                  if (masterObject && masterObject.c_id === counsellingIdTobeDeleted) {
                    formRef.current.reset();
                    setmasterObject({});
                    setCounsellingIdTobeDeleted(null);
                  }
                  setCounsellingIdTobeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Medicine");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Counselling Notes Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table id="product_available_day" className="table table-bordered dataTable">
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>Patient Name :</td>
                <td>{popupData?.name}</td>
                <td style={{ paddingLeft: "25px" }}>Date :</td>
                <td style={{ textAlign: "left" }}>{popupData?.c_date}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Time :</td>
                <td>{popupData?.time}</td>
                <td style={{ paddingLeft: "25px" }}>I/F :</td>
                <td style={{ textAlign: "left" }}>{popupData?.type}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Duration :</td>
                <td>{popupData?.duration}</td>
                <td style={{ paddingLeft: "25px" }}>Staff :</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Issues :</td>
                <td>{popupData?.issues}</td>
                <td style={{ paddingLeft: "25px" }}></td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Counselling Notes" />
          {props.options.edit && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}>
                      <Row>
                        <Col md="3">
                          <div className="mt-2">
                            <Label>Patient</Label>
                            <Select
                              name="admission_id"
                              value={selectedPatient}
                              onChange={(selected) => {
                                handleSelectChange(selected, "admission_id");
                              }}
                              options={patientOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        {patientvalues == true ? (
                          <>
                            <Col md="9">
                              <Row>
                                <div className="mt-2">
                                  <h6>Patient Basic Details:</h6>
                                </div>
                              </Row>
                              <Row>
                                <Col md="3" className="mt-1">
                                  <div>
                                    <Label>Mobile:</Label>
                                    <span>{masterObject?.mobile}</span>
                                  </div>
                                </Col>
                                <Col md="2" className="mt-1">
                                  <Label>Gender:</Label>
                                  <span>{masterObject?.gender}</span>
                                </Col>
                                <Col md="1" className="mt-1">
                                  <Label>Age:</Label>
                                  <span>{masterObject?.age}</span>
                                </Col>
                                <Col md="3" className="mt-1">
                                  <Label>Admitted Date:</Label>
                                  <span>{masterObject?.mobile}</span>
                                </Col>
                              </Row>
                            </Col>
                            {patientprevvalues == true ? (
                              // <Col xl="12">
                              //   <Row>
                              //     <div className="mt-4">
                              //       <h6>Previous Counselling Details:</h6>
                              //     </div>
                              //   </Row>
                              //   <Table
                              //     style={{ textAlign: "center" }}
                              //     className="table table-bordered dataTable"
                              //   >
                              //     <TableHead>
                              //       <TableRow style={{ textAlign: "center" }}>
                              //         <TableCell
                              //           style={{
                              //             // width: "180px",
                              //             textAlign: "center",
                              //             fontWeight: "500",
                              //             fontSize: "12.819px",
                              //             fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //           }}
                              //         >
                              //           No.
                              //         </TableCell>
                              //         <TableCell
                              //           style={{
                              //             width: "180px",
                              //             textAlign: "center",
                              //             fontWeight: "500",
                              //             fontSize: "12.819px",
                              //             fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //           }}
                              //         >
                              //           Date
                              //         </TableCell>
                              //         <TableCell
                              //           style={{
                              //             width: "100px",
                              //             textAlign: "center",
                              //             fontWeight: "500",
                              //             fontSize: "12.819px",
                              //             fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //           }}
                              //         >
                              //           I/F
                              //         </TableCell>
                              //         <TableCell
                              //           style={{
                              //             // width: "110px",
                              //             textAlign: "center",
                              //             fontWeight: "500",
                              //             fontSize: "12.819px",
                              //             fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //           }}
                              //         >
                              //           Duration
                              //         </TableCell>

                              //         <TableCell
                              //           style={{
                              //             // width: "180px",
                              //             textAlign: "center",
                              //             fontWeight: "500",
                              //             fontSize: "12.819px",
                              //             fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //           }}
                              //         >
                              //           Staff
                              //         </TableCell>
                              //         <TableCell
                              //           style={{
                              //             // width: "180px",
                              //             textAlign: "center",
                              //             fontWeight: "500",
                              //             fontSize: "12.819px",
                              //             fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //           }}
                              //         >
                              //           Issues Dealt With
                              //         </TableCell>
                              //       </TableRow>
                              //     </TableHead>
                              //     <TableBody>
                              //       <TableRow>
                              //         <TableCell
                              //           style={{
                              //             // width: "180px",
                              //             textAlign: "center",
                              //             fontWeight: "200",
                              //             fontSize: "12.819px",
                              //             // fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //           }}
                              //         >
                              //           1
                              //         </TableCell>
                              //         <TableCell
                              //           component="th"
                              //           scope="row"
                              //           style={{
                              //             textAlign: "center",
                              //             fontSize: "12.819px",
                              //             //fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //             fontWeight: "200",
                              //           }}
                              //         >
                              //           {masterObject?.date}
                              //         </TableCell>
                              //         <TableCell
                              //           component="th"
                              //           scope="row"
                              //           style={{
                              //             textAlign: "center",
                              //             fontSize: "12.819px",
                              //             //fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //             fontWeight: "200",
                              //           }}
                              //         >
                              //           {masterObject?.prev_type}
                              //         </TableCell>
                              //         <TableCell
                              //           component="th"
                              //           scope="row"
                              //           style={{
                              //             textAlign: "center",
                              //             fontSize: "12.819px",
                              //             // fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //             fontWeight: "200",
                              //           }}
                              //         >
                              //           {masterObject?.prev_duration}
                              //         </TableCell>
                              //         <TableCell
                              //           component="th"
                              //           scope="row"
                              //           style={{
                              //             textAlign: "center",
                              //             fontSize: "12.819px",
                              //             // fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //             fontWeight: "200",
                              //           }}
                              //         >
                              //           {masterObject?.staff}
                              //         </TableCell>
                              //         <TableCell
                              //           component="td"
                              //           scope="row"
                              //           style={{
                              //             textAlign: "center",
                              //             fontSize: "12.819px",
                              //             //fontFamily: "IBM Plex Sans, sans-serif",
                              //             color: "#495057",
                              //             fontWeight: "200",
                              //           }}
                              //         >
                              //           {masterObject?.prev_issues}
                              //         </TableCell>
                              //       </TableRow>
                              //     </TableBody>
                              //   </Table>
                              // </Col>

                              <Row>
                                <div className="mt-0">
                                  <Card style={{ background: "#f6f6f6" }}>
                                    <Link to="#" onClick={toggle} className="text-dark">
                                      <div className="p-2">
                                        <Media className="d-flex align-items-center">
                                          <div className="flex-1 overflow-hidden">
                                            <h6>Previous Counselling Details</h6>
                                          </div>
                                          <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                        </Media>
                                      </div>
                                    </Link>
                                    <Collapse isOpen={isOpen}>
                                      <div>
                                        <Table style={{ textAlign: "center" }} className="table2">
                                          <TableHead>
                                            <TableRow style={{ textAlign: "center" }}>
                                              <TableCell
                                                style={{
                                                  // width: "180px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                No.
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: "180px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                Date
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: "100px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                I/F
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  // width: "110px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                Duration
                                              </TableCell>

                                              <TableCell
                                                style={{
                                                  // width: "180px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                Staff
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  // width: "180px",
                                                  textAlign: "center",
                                                  fontWeight: "500",
                                                  fontSize: "12.819px",
                                                  fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                Issues Dealt With
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {/* {masterObject?.previous_data &&
                                  masterObject?.previous_data.map(
                                    (item, index) => (
                                      <> */}
                                            <TableRow>
                                              <TableCell
                                                style={{
                                                  // width: "180px",
                                                  textAlign: "center",
                                                  fontWeight: "200",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                }}>
                                                {1}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                  fontWeight: "200",
                                                }}>
                                                {masterObject?.previous_data?.c_date}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                  fontWeight: "200",
                                                }}>
                                                {masterObject?.prev_type}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                  fontWeight: "200",
                                                }}>
                                                {masterObject?.prev_duration}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "12.819px",
                                                  // fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                  fontWeight: "200",
                                                }}>
                                                {masterObject?.staff}
                                              </TableCell>
                                              <TableCell
                                                component="td"
                                                scope="row"
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "12.819px",
                                                  //fontFamily: "IBM Plex Sans, sans-serif",
                                                  color: "#495057",
                                                  fontWeight: "200",
                                                }}>
                                                {masterObject?.prev_issues}
                                              </TableCell>
                                            </TableRow>
                                            {/* </>
                         ))} */}
                                          </TableBody>
                                        </Table>
                                      </div>
                                    </Collapse>
                                  </Card>
                                </div>
                              </Row>
                            ) : null}
                          </>
                        ) : null}

                        <Col md="2">
                          <div className="mt-2">
                            <Label>Date</Label>
                            <AvField
                              // disabled={!editable}
                              name="c_date"
                              placeholder="Date"
                              type="date"
                              className="form-control"
                              //validate={{ required: { value: true } }}
                              onChange={handleChangeInput}
                              value={masterObject?.c_date}
                            />
                          </div>
                        </Col>

                        <Col md="2" className="mt-2">
                          <Label>I/F</Label>
                          <Select
                            name="type"
                            value={counsellingType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "type");
                            }}
                            options={[
                              { label: "I", value: 0 },
                              { label: "F", value: 1 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </Col>

                        <Col md="2" className="mt-2">
                          <Label>Duration(Minutes)</Label>
                          <AvField
                            name="duration"
                            type="number"
                            // minLength={6}
                            // maxLength={6}
                            className="form-control"
                            placeholder="Duration"
                            value={masterObject?.duration}
                            onChange={handleChangeInput}
                            onWheel={(e) => e.target.blur()}
                          />
                        </Col>

                        <Col md="3" className="mt-2">
                          <Label>Issues dealt with</Label>
                          <AvField
                            name="issues"
                            type="textarea"
                            // minLength={6}
                            // maxLength={6}
                            className="form-control"
                            placeholder="Issues dealt with"
                            value={masterObject?.issues}
                            rows={1}
                            onChange={handleChangeInput}
                          />
                        </Col>
                        <Row>
                          <Col md="1" className="mt-3">
                            {counsellingIdTobeUpdated ? (
                              <Button color="primary" type="submit">
                                {"Update"}
                              </Button>
                            ) : (
                              <Button color="primary" type="submit">
                                {"Submit"}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <MDBDataTable
                        id="counsellingTableID"
                        responsive
                        //striped
                        bordered
                        data={data}
                        info={true}
                        searching={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Medication;
