/* eslint-disable */
import React, { useState, useEffect,  } from 'react'
import {
    Col,
    FormGroup,
    Row,
    TabPane,
} from "reactstrap";

import toastr from "toastr";
import axios from "axios";
import {  AvForm } from "availity-reactstrap-validation";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function Religiousbeliefs(props) {
let {  details,  editable } = props;

useEffect(() => {
    setbeliver(details?.religious_beliefs);
    setprayhome(details?.pray_at_home);
    setvisttem(details?.regular_place_of_worship);
    setgopilgrm(details?.pilgrimages);
    setfestiv(details?.festival_celebration)
    setmasterobj({
        ...masterobj,
        religious_beliefs:details?.religious_beliefs,
        pray_at_home:details?.pray_at_home,
        regular_place_of_worship:details?.regular_place_of_worship,
        pilgrimages:details?.pilgrimages,
        festival_celebration:details?.festival_celebration
    })
 
}, [details]);


  const [masterobj, setmasterobj] = useState({});  
  const [beliver, setbeliver] = useState(null);
  const [prayhome, setprayhome] = useState(null);
  const [visttem, setvisttem] = useState(null);
  const [gopilgrm, setgopilgrm] = useState(null);
  const [festiv, setfestiv] = useState(null);


  function handlebelive(e){
   setbeliver(Number(e.target.value))
   setmasterobj({...masterobj,
    religious_beliefs:Number(e.target.value)
   })
  }

  function handlepray(e){
    setprayhome(Number(e.target.value))
    setmasterobj({...masterobj,
        pray_at_home:Number(e.target.value)
    })
   }

   function handletenble(e){
    setvisttem(Number(e.target.value))
    setmasterobj({...masterobj,
        regular_place_of_worship:Number(e.target.value)
    })
   }

function handdlepilfrm(e){
    setgopilgrm(Number(e.target.value))
    setmasterobj({...masterobj,
        pilgrimages:Number(e.target.value)
    })
}

function handlefestive(e){
    setfestiv(Number(e.target.value))
    setmasterobj({
        ...masterobj,festival_celebration:Number(e.target.value)
    })
}

function handleValidSubmit(){
    masterobj.admission_id=props.id;
    axios
    .post(`${API_URL}admission/religious-details`,masterobj,{
        headers:{
            "x-access-token":accessToken
        }
    }).then((res)=>{
        toastr.success(res.data.message)
        props.handleToggleBar("increment");
    })
    .catch((err)=>{
        toastr.error(err.response.data.message)
        return;
    })
}

    return (
        <TabPane tabId={10}>
            <AvForm
                className="needs-validation" 
                onValidSubmit={() => {
                    handleValidSubmit();
                }}>
                <Col md="12" >
                    <Row>
                        <Col md="5">
                            <h6>1. Are you a</h6>
                        </Col>
                        <Col md="6">
                            <p>Believer  <input disabled={!editable} type="radio"  name='religious_beliefs'   checked={beliver===0} style={{ marginLeft: "10px", }} value={0} onChange={handlebelive} />  &nbsp;
                                Non Believer  <input disabled={!editable} type="radio"  name='religious_beliefs' checked={beliver===1} style={{ marginLeft: "10px", }} value={1} onChange={handlebelive} /> &nbsp;
                                Indifferent  <input disabled={!editable} type="radio" name='religious_beliefs'  checked={beliver===2} style={{ marginLeft: "10px", }} value={2} onChange={handlebelive} />
                            </p>
                        </Col>

                    </Row>
                </Col>
                <Col md="5">
                    <h6>2. Do you </h6>
                </Col>
                <Col md="12" >
                    <Row>

                        <Col md="5">
                            <p style={{ marginLeft: "1rem" }}>Pray at home</p>
                        </Col>
                        <Col md="6">
                            <p>Always  <input disabled={!editable} type="radio" style={{ marginLeft: "10px", }} value={0}  checked={prayhome===0}  name='pray_at_home' onChange={handlepray}  />  &nbsp;
                                Sometimes  <input disabled={!editable} type="radio" style={{ marginLeft: "10px", }} value={1} checked={prayhome===1} name='pray_at_home' onChange={handlepray}  /> &nbsp;
                                Never  <input disabled={!editable} type="radio" style={{ marginLeft: "10px", }} value={2} checked={prayhome===2} name='pray_at_home'  onChange={handlepray} />
                            </p>
                        </Col>
                        <Col md="5">
                            <p style={{ marginLeft: "1rem" }}>Visit Temple/Church/Mosk regularly</p>
                        </Col>
                        <Col md="6">
                            <p>Always  <input disabled={!editable} type="radio" style={{ marginLeft: "10px", }}  checked={visttem===0} value={0}  name='regular_place_of_worship' onChange={handletenble}  />  &nbsp;
                                Sometimes  <input disabled={!editable} type="radio" style={{ marginLeft: "10px", }} checked={visttem===1} value={1} name='regular_place_of_worship'  onChange={handletenble} /> &nbsp;
                                Never  <input disabled={!editable} type="radio" style={{ marginLeft: "10px", }} checked={visttem===2} value={2} name='regular_place_of_worship' onChange={handletenble}  />
                            </p>
                        </Col>

                        <Col md="5">
                            <p style={{ marginLeft: "1rem" }}>Go on pilgrimages</p>
                        </Col>
                        <Col md="6">
                            <p>Always  <input disabled={!editable}  type="radio" style={{ marginLeft: "10px", }} value={0}  checked={gopilgrm===0}  name='pilgrimages'  onChange={handdlepilfrm} />  &nbsp;
                                Sometimes  <input disabled={!editable} type="radio" style={{ marginLeft: "10px", }} value={1} checked={gopilgrm===1} name='pilgrimages'  onChange={handdlepilfrm}/> &nbsp;
                                Never  <input disabled={!editable} type="radio" style={{ marginLeft: "10px", }} value={2} checked={gopilgrm===2} name='pilgrimages' onChange={handdlepilfrm} />
                            </p>
                        </Col>

                        <Col md="5">
                            <p style={{ marginLeft: "1rem" }}>Celebrate festivals</p>
                        </Col>
                        <Col md="6">
                            <p>Always  <input  disabled={!editable} type="radio" style={{ marginLeft: "10px", }} value={0}  name='festival_celebration' checked={festiv===0}  onChange={handlefestive} />  &nbsp;
                                Sometimes  <input  disabled={!editable} type="radio" style={{ marginLeft: "10px", }} value={1} name='festival_celebration' checked={festiv===1} onChange={handlefestive} /> &nbsp;
                                Never  <input  disabled={!editable} type="radio" style={{ marginLeft: "10px", }} value={2} name='festival_celebration' checked={festiv===2} onChange={handlefestive} />
                            </p>
                        </Col>

                    </Row>
                </Col>
                <Row className='mt-4'>
                    <Col md={12} className="submit-btn-fixed"> 
                        <FormGroup className="float-right ">
                            <a
                                className="save-continue-btn"
                                onClick={() => props.handleToggleBar("decrement")}
                            >
                                <i class="fas fa-solid fa-less-than"></i>
                            </a>
                            <button type="submit" className="save-continue-btn m-2">
                                Save and continue{" "}
                                <i
                                    style={{ fontSize: "13px" }}
                                    className="mdi mdi-arrow-right"
                                ></i>
                            </button>
                            <a
                                className="save-continue-btn"
                                onClick={() => props.handleToggleBar("increment")}
                            >
                                <i class="fas fa-solid fa-greater-than"></i>
                            </a>
                        </FormGroup>
                    </Col>
                </Row>
            </AvForm>
        </TabPane>
    );
}

export default Religiousbeliefs;
