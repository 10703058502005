import { GET_PATIENTS_OPTIONS, GET_PATIENTS_OPTIONS_SUCCESS, GET_PATIENTS_OPTIONS_FAIL } from "./actionTypes";

export const getPatientsOptions = () => ({
  type: GET_PATIENTS_OPTIONS,
  // payload: companyId,
});

export const getPatientsOptionsSuccess = (patients) => ({
  type: GET_PATIENTS_OPTIONS_SUCCESS,
  payload: patients,
});

export const getPatientsOptionsFail = (error) => ({
  type: GET_PATIENTS_OPTIONS_FAIL,
  payload: error,
});
