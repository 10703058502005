/* eslint-disable */
import {
  GET_COMPANIES_OPTIONS_SUCCESS,
  GET_COMPANIES_OPTIONS_FAIL,
  GET_COMPANIES_MASTER_OPTIONS_SUCCESS,
  GET_COMPANIES_MASTER_OPTIONS_FAIL,
  SAVE_SELECTED_COMPANY_SUCCESS,
  SAVE_SELECTED_COMPANY_FAIL,
  GET_LOCALBODIES_MASTER_OPTIONS_SUCCESS,
  GET_LOCALBODIES_MASTER_OPTIONS_FAIL,
  ADD_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  companiesOptions: [],
  companiesMasterOptions: [],
  companyname: {},
  localbodiesMasterOptions: [],

  addingCompany: false,
  deletingCompany: false,
  addCompanyResponse: {},
  updateCompanyResponse: {},
  deleteCompanyResponse: {},
  companyIdToBeDeleted: "",
  error:{}
};

const companies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANIES_OPTIONS_SUCCESS:
      return {
        ...state,
        companiesOptions: action.payload.data,
      };

    case GET_COMPANIES_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_COMPANIES_MASTER_OPTIONS_SUCCESS:
      return {
        ...state,
        companiesMasterOptions: action.payload,
      };

    case GET_COMPANIES_MASTER_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SAVE_SELECTED_COMPANY_SUCCESS:
      return { ...state, companyname: action.payload };

    case SAVE_SELECTED_COMPANY_FAIL:
      return { ...state, error: "cant save company" };

    case GET_LOCALBODIES_MASTER_OPTIONS_SUCCESS:
      return {
        ...state,
        localbodiesMasterOptions: action.payload,
      };

    case GET_LOCALBODIES_MASTER_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_COMPANY:
      return {
        ...state,
        addingCompany: true,
        companyname: action.payload,
      };

    case ADD_COMPANY_SUCCESS:
      let newComapny = [...state.companiesOptions, action.payload.data];
      return {
        ...state,
        addingCompany: false,
        addCompanyResponse: {
          type: "success",
          message: "Company created successfully",
        },
        companiesOptions: newComapny,
        error: {},
      };

    case ADD_COMPANY_FAIL:
      return {
        ...state,
        addingCompany: false,
        addCompanyResponse: {
          type: "failure",
          message: "Creating company failed",
        },
        error: action.payload,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        updatingCompany: true,
        companyname: action.payload,
      };

    case UPDATE_COMPANY_SUCCESS:
      let newCompany1 = state.companiesOptions.map((item) => {
        if (item._id == state.companyname._id) {
          return action.payload.data;
        } else {
          return item;
        }
      });
      return {
        ...state,
        updatingComapny: false,
        companyname: "",
        updateCompanyResponse: {
          type: "success",
          message: "Company updated successfully",
        },
        companiesOptions: newCompany1,
      };
    

    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        updatingCompany: false,
        updateCompanyResponse: {
          type: "failure",
          message: "Updating company failed",
        },
        error: action.payload,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        deletingCompany: true,
        companyIdToBeDeleted: action.payload,
      };

    case DELETE_COMPANY_SUCCESS:
      let newData = state.companiesOptions.filter((e) => {
        return e._id != state.companyIdToBeDeleted;
      });
      return {
        ...state,
        deletingCompany: false,
        companyIdToBeDeleted: "",
        deleteCompanyResponse: {
          type: "success",
          message: "Company deleted successfully",
        },
        companiesOptions: newData,
      };

    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        deletingCompany: false,
        deleteCompanyResponse: {
          type: "failure",
          message: "Deleting company failed",
        },
        error: action.payload,
      };
    default:
      return state;
  }
};

export default companies;
