import { Card, CardBody, Col, FormGroup,Row, Table } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getDate } from "../../helpers/globalFunctions";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useState } from "react";
import { useEffect } from "react";
import { getMedicineChecklist, updateMedicine } from "./api";
import Select from "react-select";
import toastr from "toastr";
import "./styles.css";

const MedicineChart = (props) => {
  const [date, setDate] = useState(getDate(new Date()));
  const [keyWord, setKeyword] = useState("");
  const [tempData, setTempData] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getMedicineChecklist(date, keyWord).then((data) => {
      setTempData(data);
    });
  }, [date, reload, keyWord]);

  let handleSelect = (e, admission_id, name, idx) => {
    let data = {};
    let objectId = null;
    let logObject = {};
    if (e !== null) {
      objectId = e.map((e) => e.value);
      logObject = e.map((e) => ({
        medicine: e._id,
        medication_id: e.medication_id,
      }));
    } else {
      objectId = [];
    }
    data.date = date;
    data.admission_id = admission_id;
    data[name] = objectId;
    data[name + "_log"] = logObject;
    updateMedicine(data).then((data) => {
      setReload(!reload);
      data.status === 200 ? toastr.success(data.message) : console.log(data);
    });
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Medicine chart" />
          <Row>
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    <Col lg="3">
                      <FormGroup className="mx-2 my-3">
                        <AvField name="date" type="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mx-2 my-3 position-relative">
                        <AvField
                          name="text"
                          placeholder="Name, Reg No"
                          type="text"
                          className="form-control"
                          value={keyWord}
                          onChange={(e) => setKeyword(e.target.value)}
                        />
                        {keyWord.length > 0 && (
                          <i
                            style={{
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => setKeyword("")}
                            className="position-absolute">
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg">
                              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                            </svg>
                          </i>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  <div className="table-responsive">
                    <Table style={{ border: "1px solid #dadada" }} className="table table-bordered mb-0 table-hover ">
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th style={{ verticalAlign: "middle" }} scope="col" rowSpan={2}>
                            No
                          </th>
                          <th style={{ verticalAlign: "middle" }} rowSpan={2}>
                            Reg No
                          </th>
                          <th
                            style={{
                              verticalAlign: "middle",
                              minWidth: "10rem",
                            }}
                            rowSpan={2}>
                            Name
                          </th>
                          <th style={{ verticalAlign: "middle" }} rowSpan={1} colSpan={3}>
                            Medicines
                          </th>
                        </tr>
                        <tr>
                          <th scope="row" className="text-center">
                            Morning
                          </th>
                          <th className="text-center" scope="row">
                            Noon
                          </th>
                          <th className="text-center" scope="row">
                            Night
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ border: "1px solid #dadada" }}>
                        {tempData.map((data, idx) => {
                          return (
                            <tr key={idx}>
                              <th scope="row">{idx + 1}</th>
                              <td>{data?.admission?.reg_no}</td>
                              <td>{data?.fname + " " + data?.lname}</td>
                              <td className="p-2 mx-auto">
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  menuPosition="fixed"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      width: "auto",
                                      margin: " 0 auto",
                                      borderStyle: "none ",
                                    }),
                                  }}
                                  name="medicines_morning"
                                  defaultValue={data.given_morning}
                                  value={data.given_morning}
                                  isMulti={true}
                                  options={data.pending_morning}
                                  onChange={(e) => {
                                    handleSelect(e, data.admission._id, "medicines_morning");
                                  }}
                                  isDisabled={!props.options.edit}
                                />
                              </td>
                              <td className="p-2 mx-auto">
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  menuPosition="fixed"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      width: "auto",
                                      margin: " 0 auto",
                                      borderStyle: "none ",
                                    }),
                                  }}
                                  name="medicines_noon"
                                  isMulti={true}
                                  defaultValue={data.given_noon}
                                  value={data.given_noon}
                                  options={data.pending_noon}
                                  onChange={(e) => {
                                    handleSelect(e, data.admission._id, "medicines_noon");
                                  }}
                                  isDisabled={!props.options.edit}
                                />
                              </td>
                              <td className="p-2 mx-auto">
                                <Select
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      width: "auto",
                                      margin: " 0 auto",
                                      borderStyle: "none ",
                                    }),
                                  }}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  isMulti={true}
                                  name="medicines_night"
                                  menuPosition="fixed"
                                  defaultValue={data.given_night}
                                  value={data.given_night}
                                  options={data.pending_night}
                                  onChange={(e) => {
                                    handleSelect(e, data.admission._id, "medicines_night");
                                  }}
                                  isDisabled={!props.options.edit}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </>
  );
};

export default MedicineChart;
