/* eslint-disable */
import axios from "axios";
import { get, post } from "../../../helpers/api_helper";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
export const getDischargedPatients = () =>
  new Promise(async (resolve, reject) => {
    let response = await get(`${API_URL}options/discharged-patients`);
    if (response) {
      response.map((data, idx) => {
        data.id = idx + 1;
        data.value = data._id;
        data.label =
          data?.basic_details?.basic_id?.fname +
          " " +
          data?.basic_details?.basic_id?.lname;
      });
      resolve(response);
    }
  });

export const getIdentity = () =>
  new Promise(async (resolve, reject) => {
    let response = await get(`${API_URL}options/identity`);
    resolve(response.data);
  });

export const submitDischarge = (data) =>
  new Promise(async (resolve, reject) => {
    let res = await post(`${API_URL}certificates/discharge`, data);
    resolve(res);
  });

export const getDischargeCertificates = () =>
  new Promise(async (resolve, reject) => {
    let res = await get(`${API_URL}certificates/discharge`);
    resolve(res.data);
  });
