/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
// import { useDispatch } from "react-redux";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import FormGroup from "@mui/material/FormGroup";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getDate } from "../../../helpers/globalFunctions";
//Import Breadcrumb
import "./medication.scss";
// import { useHistory } from "react-router-dom";

import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Medication = (props) => {
  const formRef = useRef();
  let { editable } = props;

  // const [selectedPatient, setSelectedPatient] = useState("");
  // const [patientOptions, setPatientOptions] = useState([]);
  // const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [values, setValues] = useState([{}]);

  const [masterObject, setmasterObject] = useState({
    medication_date: getDate(new Date()),
  });

  const [medicineOptions, setMedicineOptions] = useState([]);
  const [medicationIdTobeDeleted, setMedicationTobeDeleted] = useState(null);
  const [medicationIdTobeUpdated, setMedicationTobeUpdated] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupData, setPopupData] = useState({});

  const [popupView, setPopupView] = useState(false);
  const [selectedTest, setSelectedTest] = useState("");
  const [testOptions, setTestOptions] = useState([]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetch_all_patients();
    handleTableData(props);
    fetch_all_medicines();
    fetch_all_test();
    // fetch_previous_medicine_details()
    setmasterObject({
      ...masterObject,
      admission_id: props.id,
    });
    // eslint-disable-next-line
  }, [props]);

  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.fname + " " + el?.lname + "-" + el?.reg_no,
              value: el?._id,
            };
          });
        // setPatientOptions([
        //   {
        //     options: patient_data,
        //   },
        // ]);
      });
  };

  const fetch_all_test = () => {
    axios
      .get(`${API_URL}test/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let test_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setTestOptions([
          {
            options: test_data,
          },
        ]);
      });
  };

  const fetch_all_medicines = () => {
    axios
      .get(`${API_URL}medicine/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let medicine_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name + " " + el?.dose,
              value: el?._id,
            };
          });
        setMedicineOptions([
          {
            options: medicine_data,
          },
        ]);
      });
  };

  const addMore = () => {
    setValues([...values, {}]);
  };

  function handleTableData(props) {
    let admission_id = props.id;
    var url = `${API_URL}medication/list?admission_id=` + admission_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
          item.date = moment(item?.medication_date).format("DD-MM-YYYY");
          // item.reg_no = item?.admission_id?.reg_no
          item.firstName = item.added_by?.firstName;
          if (props?.options?.edit) {
            item.action = (
              <>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    preupdateMedication(item);
                    toTop();
                  }}></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "0.9em", cursor: "pointer" }}
                  onClick={() => {
                    setMedicationTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </>
            );
          } else {
            item.action = (
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
            );
          }

          return item;
        });

        setTableData(result);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Complaints",
        field: "complaints",
        sort: "asc",
        width: 200,
      },

      {
        label: "Remarks",
        field: "remarks",
        sort: "asc",
        width: 200,
      },

      {
        label: "Staff",
        field: "firstName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  useEffect(() => {
    data.rows.map((row) => {
      if (props?.options?.edit) {
        row.action = (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                handleClickOpen(row);
              }}></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              onClick={() => {
                preupdateMedication(row);
                toTop();
              }}></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "0.9em", cursor: "pointer" }}
              onClick={() => {
                setMedicationTobeDeleted(row._id);
                setConfirmDeleteAlert(true);
              }}></i>
          </>
        );
      } else {
        <i
          className="fas fa-eye"
          style={{
            fontSize: "0.9em",
            cursor: "pointer",
            marginLeft: "0.1em",
            marginRight: "0.4em",
          }}
          onClick={() => {
            handleClickOpen(row);
          }}></i>;
      }
      return row;
    });
    // eslint-disable-next-line
  }, [props.options]);

  function handleChangeSelectItem(selected, index) {
    const list = [...values];
    list[index]["medicine"] = selected.value;
    list[index]["medicine_name"] = selected.label;
    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
    // setSelectedMedicine(selected);
  }

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
  };

  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };

  function handleTableInputchange(e, index) {
    let { name, value } = e.target;
    const list = [...values];
    list[index][name] = value;

    if (name == "days") {
      let medication_date = masterObject?.medication_date;
      let endDate = moment(medication_date).add(e.target.value - 1, "days");
      let end_date = moment(endDate).format("YYYY-MM-DD");
      list[index]["end_date"] = end_date;
    }
    setValues(list);
    setmasterObject({ ...masterObject, medicine: list });
  }

  const handleValidSubmit = (e) => {
    if (medicationIdTobeUpdated) {
      axios
        .put(`${API_URL}medication/edit`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Medication updated successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({
            medication_date: getDate(new Date()),
            admission_id: props.id,
            remarks: "",
          });
          // setSelectedPatient(null);
          handleTableData(props);
          setSelectedTest(null);
          setMedicationTobeUpdated(null);
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    } else {
      axios
        .post(`${API_URL}medication/add`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Medication added successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({
            medication_date: getDate(new Date()),
            admission_id: props.id,
            remarks: "",
          });
          // setSelectedPatient(null);
          handleTableData(props);
          setSelectedTest(null);
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }
  };

  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}medication/medication_view?id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let item = {};
        item.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        item.date = moment(result?.medication_date).format("DD-MM-YYYY");
        item.reg_no = result?.admission_id?.reg_no;
        item.firstName = result.added_by?.firstName;
        item.complaints = result?.complaints;
        item.remarks = result?.remarks;
        item.medicine = result?.medicine;

        item.tests = <>{result?.test && result?.test.map((element, index) => element.name).join(", ")}</>;

        // setMedicineData(result?.medicine)

        setPopupData(item);
        setPopupView(true);
      });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preupdateMedication = (item) => {
    setMedicationTobeUpdated(item?._id);
    axios
      .get(`${API_URL}medication/medication_view?id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let items = {};
        items._id = item?._id;
        items.admission_id = result?.admission_id?._id;
        items.name = result?.admission_id?.basic_details?.basic_id?.fname + " " + result?.admission_id?.basic_details?.basic_id?.lname;
        //items.date = moment(result?.medication_date).format("DD-MM-YYYY")
        items.reg_no = result?.admission_id?.reg_no;
        items.firstName = result.added_by?.firstName;
        items.complaints = result?.complaints;
        items.remarks = result?.remarks;
        items.medication_date = result?.medication_date;
        items.test = result?.test;

        let testArr = [];
        items.test &&
          items.test.forEach((element) => {
            let array = {
              label: element.name,
              value: element._id,
            };
            // setSelectedTest(element._id);

            testArr.push(array);
          });
        items.test = result?.test;
        setSelectedTest(testArr);

        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };
        if (result?.medicine.length > 0) {
          let tableData = [];
          result?.medicine &&
            result?.medicine.map((el, index) => {
              let val = {};
              val.frq_morning = el?.frq_morning;
              val.frq_noon = el?.frq_noon;
              val.frq_night = el?.frq_night;
              val.days = el?.days;
              val.remarks = el?.remarks;
              val.medicine = el?.medicine._id;
              // val.medicine_name = el?.medicine?.name + " " + el?.medicine?.dose;
              if (el?.medicine?.dose !== "") {
                val.medicine_name = el?.medicine?.name + " " + el?.medicine?.dose;
              }
              val.medicine_name = el?.medicine?.name;
              tableData.push(val);
              // //console.log("el", el)
              // //console.log("val", val)
            });
          items.medicine = result?.medicine;
          setValues(tableData);
        }

        // setSelectedPatient(patient);
        setmasterObject(items);

        // setMedicineData(result?.medicine)

        // setPopupData(item)
        // setPopupView(true);
      });
  };

  const handleSelectChange = async (selected, type) => {
    switch (type) {
      case "test":
        let arrayVal = [];
        selected &&
          selected.forEach((element) => {
            arrayVal.push(element.value);
          });
        setSelectedTest(selected);
        setmasterObject({
          ...masterObject,
          test: arrayVal,
        });

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}medication` + "/" + medicationIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Medication deleted successfully");
                  if (masterObject && masterObject.medication_id === medicationIdTobeDeleted) {
                    formRef.current.reset();
                    setmasterObject({});
                    // setMedicationIdTobeUpdated(null);
                  }
                  setMedicationTobeDeleted(null);
                  handleTableData(props);
                } else {
                  toastr.error(res.data.message, "Failed to delete Medication");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Medication Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table id="product_available_day" className="table table-bordered dataTable">
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>Name :</td>
                <td>{popupData?.name}</td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}>{popupData?.reg_no}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Date :</td>
                <td>{popupData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Staff :</td>
                <td style={{ textAlign: "left" }}>{popupData?.firstName}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Complaints :</td>
                <td>{popupData?.complaints}</td>
                <td style={{ padding: "10px" }}></td>
                <td></td>
              </tr>
              <tr colSpan={4}>
                <td style={{ padding: "10px" }}>Test :</td>
                <td> {popupData?.tests} </td>

                {/* <td>{popupData?.test.map((item, index) => item)}</td> */}
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Remarks :</td>
                <td>{popupData?.remarks}</td>
                <td style={{ padding: "10px" }}></td>
                <td></td>
              </tr>
            </table>
            <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
              <TableHead>
                <tr style={{ textAlign: "center" }}>
                  <th
                    style={{
                      width: "5px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}
                    rowSpan={2}>
                    No.
                  </th>
                  <th
                    style={{
                      width: "150px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}
                    rowSpan={2}>
                    Medicine
                  </th>
                  <th
                    style={{
                      width: "100px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}
                    colSpan={3}>
                    Frequency
                  </th>

                  <th
                    style={{
                      width: "100px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}
                    rowSpan={2}>
                    Days
                  </th>

                  <th
                    style={{
                      width: "200px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12.819px",
                      fontFamily: "IBM Plex Sans, sans-serif",
                      color: "#495057",
                    }}
                    rowSpan={2}>
                    Remarks
                  </th>
                </tr>
                <tr>
                  <th style={{ width: "10px" }}>Morning</th>
                  <th style={{ width: "10px" }}>Noon</th>
                  <th style={{ width: "10px" }}>Night</th>
                </tr>
              </TableHead>
              <TableBody>
                {popupData?.medicine &&
                  popupData?.medicine.map((item, index) => {
                    if (!item?.medicine?.dose) {
                      item.medicine.dose = "";
                    }
                    return (
                      <tr>
                        <td
                          style={{
                            // width: "180px",
                            textAlign: "center",
                            fontWeight: "200",
                            fontSize: "12.819px",
                            // fontFamily: "IBM Plex Sans, sans-serif",
                            color: "#495057",
                          }}>
                          {index + 1}
                        </td>
                        <td
                          component="th"
                          scope="row"
                          style={{
                            textAlign: "left",
                            fontSize: "12.819px",
                            //fontFamily: "IBM Plex Sans, sans-serif",
                            color: "#495057",
                            fontWeight: "200",
                          }}>
                          {item?.medicine?.name + " " + item?.medicine?.dose}
                        </td>

                        <td>{item?.frq_morning}</td>
                        <td>{item?.frq_noon}</td>
                        <td>{item?.frq_night}</td>
                        <td>{item?.days}</td>
                        <td>{item?.remarks}</td>
                      </tr>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </AvForm>
      </Modal>
      <div>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  ref={formRef}
                  className="needs-validation"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Date</Label>
                        <AvField
                          name="medication_date"
                          placeholder="Date"
                          type="date"
                          className="form-control"
                          onChange={handleChangeInput}
                          value={masterObject?.medication_date}
                          disabled={!editable}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Complaints</Label>
                        <AvField
                          name="complaints"
                          placeholder="Complaints"
                          type="textarea"
                          className="form-control"
                          // validate={{ required: { value: true } }}
                          value={masterObject?.complaints}
                          onChange={handleChangeInput}
                          disabled={!editable}
                          rows={1}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Test</Label>
                        <Select
                          isMulti={true}
                          name="test"
                          value={selectedTest}
                          onChange={(selected) => {
                            handleSelectChange(selected, "test");
                          }}
                          options={testOptions}
                          classNamePrefix="select2-selection"
                          isDisabled={!editable}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Remarks</Label>
                        <AvField
                          name="remarks"
                          placeholder="Remarks"
                          type="textarea"
                          className="form-control"
                          value={masterObject?.remarks}
                          onChange={handleChangeInput}
                          disabled={!editable}
                          rows={1}
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-0">
                    <h6>Medicine Details</h6>
                  </div>

                  <Row>
                    <Col xl="12">
                      <Table style={{ textAlign: "center" }} className="table table-bordered dataTable">
                        <TableHead>
                          <TableRow style={{ textAlign: "center" }}>
                            <TableCell
                              style={{
                                width: "0px",
                                textAlign: "center",
                                fontWeight: "normal",
                                paddingLeft: "0rem",
                                paddingRight: "0rem",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#495057",
                              }}>
                              No.
                            </TableCell>
                            <TableCell
                              style={{
                                // width: "230px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#495057",
                              }}>
                              Medicine Name
                            </TableCell>
                            <TableCell
                              style={{
                                width: "130px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#495057",
                              }}>
                              Frequency(Morning/Noon/Night)
                            </TableCell>
                            <TableCell
                              style={{
                                width: "90px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#495057",
                              }}>
                              Days
                            </TableCell>

                            <TableCell
                              style={{
                                //width: "250px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#495057",
                              }}>
                              Remarks
                            </TableCell>
                            {values.length !== 1 ? (
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "500",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontSize: "12.819px",
                                  fontFamily: "IBM Plex Sans, sans-serif",
                                  color: "#495057",
                                }}>
                                Action
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values &&
                            values.map((item, index) => (
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "12.819px",
                                    fontFamily: "IBM Plex Sans, sans-serif",
                                    color: "#495057",
                                    fontWeight: "500",
                                  }}>
                                  {index + 1}
                                </TableCell>
                                <TableCell style={{ textAlign: "left" }}>
                                  {/* <AvField
                                                                            name="name"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Medicine Name"
                                                                            id="validationCustom05"
                                                                            onChange={(e) => handleTableInputchange(e, index)}
                                                                            value={item.name}
                                                                        /> */}

                                  <Select
                                    name="medicine_name"
                                    //value={selectedMedicine}
                                    value={{
                                      label: item.medicine_name,
                                      value: item.medicine,
                                    }}
                                    onChange={(selected) => {
                                      handleChangeSelectItem(selected, index);
                                    }}
                                    options={medicineOptions}
                                    classNamePrefix="select2-selection"
                                    isDisabled={!editable}
                                  />
                                </TableCell>
                                <TableCell style={{ display: "flex", gap: "10px" }}>
                                  <AvField
                                    name="frq_morning"
                                    type="Number"
                                    className="form-control"
                                    //placeholder="Morning"
                                    id="validationCustom05"
                                    onChange={(e) => handleTableInputchange(e, index)}
                                    value={item?.frq_morning}
                                    validate={{
                                      maxLength: { value: 3 },
                                    }}
                                    disabled={!editable}
                                  />
                                  <AvField
                                    name="frq_noon"
                                    type="Number"
                                    className="form-control"
                                    // placeholder="Noon"
                                    id="validationCustom05"
                                    onChange={(e) => handleTableInputchange(e, index)}
                                    value={item?.frq_noon}
                                    disabled={!editable}
                                    validate={{
                                      maxLength: { value: 3 },
                                    }}
                                  />
                                  <AvField
                                    name="frq_night"
                                    type="Number"
                                    className="form-control"
                                    // placeholder="Night"
                                    id="validationCustom05"
                                    onChange={(e) => handleTableInputchange(e, index)}
                                    value={item?.frq_night}
                                    disabled={!editable}
                                    validate={{
                                      maxLength: { value: 3 },
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    name="days"
                                    placeholder="Days"
                                    type="Number"
                                    className="form-control"
                                    // validate={{ required: { value: true } }}
                                    id="validationCustom05"
                                    onChange={(e) => handleTableInputchange(e, index)}
                                    value={item?.days}
                                    disabled={!editable}
                                  />
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    name="remarks"
                                    placeholder="Remarks"
                                    type="text"
                                    className="form-control"
                                    // id="validationCustom05"
                                    onChange={(e) => handleTableInputchange(e, index)}
                                    value={item?.remarks}
                                    disabled={!editable}
                                  />
                                </TableCell>

                                <TableCell>
                                  {values.length !== 1 && (
                                    <i
                                      className="fas fa-trash"
                                      style={{
                                        cursor: "pointer",
                                        justifyContent: "center",
                                      }}
                                      onClick={() => handleRemove(index)}></i>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Col>
                  </Row>
                  {editable && (
                    <>
                      <span
                        onClick={() => addMore()}
                        style={{
                          width: "190px",
                          cursor: "pointer",
                          fontSize: "12.819px",
                          fontFamily: "IBM Plex Sans, sans-serif",
                          color: "#495057",
                          fontWeight: "500",
                        }}>
                        {" "}
                        + Add More{" "}
                      </span>
                    </>
                  )}

                  <Row className="mt-1">
                    <Col md="12" className="mt-1">
                      <FormGroup className="float-right ">
                        {editable && (
                          <>
                            {medicationIdTobeUpdated ? (
                              <Button color="primary" type="submit">
                                {"Update"}
                              </Button>
                            ) : (
                              <Button color="primary" type="submit">
                                {"Submit"}
                              </Button>
                            )}
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    <MDBDataTable
                      id="medicationTableID"
                      responsive
                      //striped
                      bordered
                      data={data}
                      info={true}
                      searching={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Medication;
