/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Col, FormGroup, Label, Row, TabPane } from "reactstrap";
import toastr from "toastr";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function EducationalDetails(props) {
  let { details, editable } = props;
  const [mstrObject, setMstrObject] = useState({
    yrs_of_eductn: "",
    gd_academic_rcrd: false,
    hgh_achvr_xtr_crclr_actvt: false,
  });

  useEffect(() => {
    if (typeof details !== "undefined") {
      setMstrObject(details);
    }
  }, [details]);

  let handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.checked;
    setMstrObject((preState) => ({ ...preState, [name]: value }));
  };

  let handleSubmit = (value) => {
    value.admission_id = props.id;
    axios
      .put(`${API_URL}admission/education-history`, value, {
        headers: { "x-access-token": accessToken },
      })
      .then((res) => {
        toastr.success(res.data.message);
        props.handleToggleBar("increment");
      });
  };

  const handleKey = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38) {
      e.preventDefault();
    }
  };

  
  return (
    <TabPane tabId={3}>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
          handleSubmit(mstrObject);
        }}
      >
        <Row>
          <h6>1. Years Of Education</h6>
        </Row>

        <Row>
          <Col md="4" style={{ marginLeft: "1rem" }}>
            <AvField
              disabled={!editable}
              name="yrs_of_eductn"
              placeholder="Years Of Education"
              type="Number"
              value={mstrObject?.yrs_of_eductn}
              onWheel={(e) => e.target.blur()}
              onKeyDown={handleKey}
              className="form-control"
              // validate={{
              //   required: { value: true },
              // }}
              onChange={(e) =>
                setMstrObject((preVal) => ({
                  ...preVal,
                  [e.target.name]: e.target.value,
                }))
              }
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <div className="mt-3">
            <h6> 2. Achievements in the past</h6>
          </div>
        </Row>
        <Row>
          {/* <div className='mt-2'> */}
          <Col md="12">
            <p style={{ marginLeft: "1rem" }} className="mb-0">
              <Label>2.1 Good Academic records</Label>
              <input
                disabled={!editable}
                type="checkbox"
                className="form-check-input"
                name="gd_academic_rcrd"
                style={{ marginLeft: "10px" }}
                checked={mstrObject?.gd_academic_rcrd}
                onClick={handleInput}
              />
            </p>
          </Col>
          <Col md="12">
            <p style={{ marginLeft: "1rem" }} className="mb-0">
              <Label>2.2 High achiever in extra curricular activities</Label>
              <input
                disabled={!editable}
                type="checkbox"
                className="form-check-input"
                name="hgh_achvr_xtr_crclr_actvt"
                style={{ marginLeft: "10px" }}
                checked={mstrObject?.hgh_achvr_xtr_crclr_actvt}
                onClick={handleInput}
              />
            </p>
          </Col>
          {/* </div> */}
        </Row>

        <Row className="mt-4">
          <Col md={12} className="submit-btn-fixed">
            <FormGroup className="float-right ">
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("decrement")}
              >
                <i class="fas fa-solid fa-less-than"></i>
              </a>
              {editable && (
                <button type="submit" className="save-continue-btn m-2">
                  Save and continue{" "}
                  <i
                    style={{ fontSize: "13px" }}
                    className="mdi mdi-arrow-right"
                  ></i>
                </button>
              )}
              <a
                className="save-continue-btn"
                onClick={() => props.handleToggleBar("increment")}
              >
                <i class="fas fa-solid fa-greater-than"></i>
              </a>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </TabPane>
  );
}

export default EducationalDetails;
