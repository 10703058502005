/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import defaultProfile from "../../assets/images/person-icon.png";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./viewpatients.scss";
import { useHistory } from "react-router-dom";

import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { ObjectId } from "../../helpers/globalVariables";

const ViewPatient = () => {
  const formRef = useRef();

  const loggerUser = JSON.parse(localStorage.getItem("authUser"));
  const [viewPatientObject, setViewPatientObject] = useState({});

  const [viewPatientValue, setViewPatientValue] = useState("");
  const [isAdmin, setIsAdmin] = useState(loggerUser.privilage === ObjectId.ADMIN || false);

  console.log(isAdmin);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();

  const [tableData, setTableData] = useState([]);
  console.log(tableData);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getUsers());
    handleTableData();
  }, []);

  function handleTableData(search = "") {
    var url = `${API_URL}admission/patients?search=` + search;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          item.fname = item?.fname + " " + item?.lname;
          item.mobile = item?.mobile;
          item.photo = item?.photo;
          item.counselling_status = item?.counselling_status;
        });
        setTableData(result);
      });
  }

  function handleChangeOurProject(e) {
    let name = e.target.name;
    let value = e.target.value;
    setViewPatientValue(value);
    setViewPatientObject({ ...viewPatientObject, [name]: value });
  }

  const handleSearch = () => {
    let search = viewPatientObject?.search ? viewPatientObject.search : "";

    handleTableData(search);
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  const reset = () => {
    formRef.current.reset();
    handleTableData();
  };

  function handleEditable(id, status) {
    axios
      .post(
        `${API_URL}admission/patient-editable`,
        { id, status },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        console.log(res);
        handleTableData(viewPatientObject?.search || "");
      })
      .catch((err) => console.log(err.response.data));
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View Patients" />
          <Row>
            <Col xl="12">
              <Card style={{ padding: "0px 0px 2px 0px" }}>
                <CardBody>
                  <AvForm ref={formRef}>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Patient</Label>
                          <AvField
                            style={customStyles}
                            name="search"
                            placeholder="Name,Mobile,Reg No"
                            type="text"
                            //errorMessage="Enter Patient Name"
                            className="form-control"
                            id="validationCustom02"
                            value={viewPatientObject?.search}
                            onChange={handleChangeOurProject}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-2" style={{ paddingTop: "25px" }}>
                          <Button color="success" type="submit" style={{ marginRight: "5%" }} onClick={() => handleSearch()}>
                            Filter
                          </Button>

                          <Button color="danger" type="reset" onClick={() => reset()}>
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {tableData?.map((item) => (
              <div className="col-sm-6 col-xl-3 card1">
                <div className="text-center card card-patients" style={{ borderRadius: "5px" }}>
                  <div className="card-body" style={{ cursor: "pointer" }}>
                    <div
                      onClick={() => {
                        history.push("/admission/" + item.reg_no, {
                          id: item?._id,
                        });
                      }}
                      className="float-end dropdown">
                      <a aria-haspopup="true" className="text-body font-size-16 dropdown-toggle" aria-expanded="false">
                        {/* <i className="uil uil-ellipsis-h"></i> */}
                      </a>
                      <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu-end dropdown-menu">
                        <a href="/" tabindex="0" role="menuitem" className="dropdown-item">
                          Edit
                        </a>
                        <a href="/" tabindex="0" role="menuitem" className="dropdown-item">
                          Action
                        </a>
                        <a href="/" tabindex="0" role="menuitem" className="dropdown-item">
                          Remove
                        </a>
                      </div>
                    </div>

                    {item?.counselling_status === 0 ? (
                      <span
                        style={{
                          float: "right",
                          display: "inline-block",
                          backgroundColor: "#1aa51a",
                          color: "white",
                          padding: "2px 5px",
                          borderRadius: "10px",
                          fontSize: "10px",
                        }}>
                        New
                      </span>
                    ) : null}
                    <span
                      style={{
                        float: "left",
                        display: "inline-block",
                        backgroundColor: "#f5f6f8",
                        color: "black",
                        padding: "2px 5px",
                        borderRadius: "10px",
                        fontSize: "13px",
                      }}>
                      {(item.reg_no && "Reg no : " + item.reg_no) || (item.serial_number && "Serial No : " + item.serial_number)}
                    </span>

                    <div
                      className="clearfix"
                      onClick={() => {
                        history.push("/admission/" + item.reg_no, {
                          id: item?._id,
                        });
                      }}></div>
                    {item?.photo ? (
                      <div
                        onClick={() => {
                          history.push("/admission/" + item.reg_no, {
                            id: item?._id,
                          });
                        }}
                        className="mb-4">
                        <img className="avatar-lg rounded-circle img-thumbnail" src={`${API_URL}uploads/admissions/photos/${item?.photo}`} alt="/" />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          history.push("/admission/" + item.reg_no, {
                            id: item?._id,
                          });
                        }}
                        className="mb-4">
                        <img className="avatar-lg rounded-circle img-thumbnail" src={defaultProfile} alt="/" />
                      </div>
                    )}

                    <h5
                      onClick={() => {
                        history.push("/admission/" + item.reg_no, {
                          id: item?._id,
                        });
                      }}
                      className="font-size-16 mb-1">
                      <a className="text-dark">{item.fname}</a>
                    </h5>

                    <p
                      onClick={() => {
                        history.push("/admission/" + item.reg_no, {
                          id: item?._id,
                        });
                      }}
                      className="text-muted mb-2">
                      {" "}
                      <i className="uil-phone-alt" style={{ color: "black" }}></i>
                      <span style={{ marginLeft: "3px" }}></span>
                      {item.mobile}
                    </p>
                    <h6 className="text-muted mb-2">
                      <i className="fas fa-rupee-sign"></i> {item.total_balance}
                    </h6>

                    {/* <a
                      type="button"
                      className="text-truncate btn btn-btn-outline-light"
                      style={{ textAlign: "left" }}
                      onClick={() => {
                        history.push("/admission/" + item?.reg_no, {
                          id: item?._id,
                        });
                        // window.location.assign("/staff/" + item._id);
                      }}>
                      <i className="uil uil-user me-1"></i>
                      Profile
                    </a> */}
                    <div>
                      {isAdmin && (
                        <Button
                          color={`${item?.editable ? "success" : "danger"}`}
                          size="sm"
                          type="submit"
                          onClick={() => handleEditable(item._id, !item?.editable)}>
                          {item?.editable ? "Active" : "Disabled"}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewPatient;
