import { takeEvery, put, call } from "redux-saga/effects";

import { ADD_PRIVILAGE, DELETE_PRIVILAGE, UPDATE_PRIVILAGE, GET_PRIVILAGES, GET_PRIVILAGES_OPTIONS } from "./actionTypes";

import {
  getPrivilagesOptionsSuccess,
  getPrivilagesOptionsFail,
  getAllPrivilegesSuccess,
  getAllPrivilegesFail,
  addPrivilegeFail,
  addPrivilegeSuccess,
  updatePrivilegeFail,
  updatePrivilegeSuccess,
  deletePrivilegeFail,
  deletePrivilegeSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import { getAllPrivileges, addPrivilege, updatePrivilege, deletePrivilege, getPrivilagesOptions } from "../../helpers/backend_helper";
function* fetchPrivilege() {
  try {
    const response = yield call(getAllPrivileges);
    yield put(getAllPrivilegesSuccess(response));
  } catch (error) {
    yield put(getAllPrivilegesFail(error));
  }
}

function* onaddPrivilege({ payload: privilege }) {
  try {
    const response = yield call(addPrivilege, privilege);
    yield put(addPrivilegeSuccess(response));
  } catch (error) {
    yield put(addPrivilegeFail(error.response));
  }
}

function* onupdatePrivilege({ payload: privilege }) {
  //console.log(area);
  try {
    const response = yield call(updatePrivilege, privilege);
    yield put(updatePrivilegeSuccess(response));
  } catch (error) {
    yield put(updatePrivilegeFail(error.response));
  }
}

function* ondeletePrivilege({ payload: privilegeId, auth_data: auth_user }) {
  try {
    const response = yield call(deletePrivilege, privilegeId, auth_user);
    yield put(deletePrivilegeSuccess(response));
  } catch (error) {
    yield put(deletePrivilegeFail(error.response));
  }
}

function* fetchPrivilages() {
  try {
    const response = yield call(getPrivilagesOptions);
    yield put(getPrivilagesOptionsSuccess(response));
  } catch (error) {
    yield put(getPrivilagesOptionsFail(error));
  }
}

function* privilagesSaga() {
  yield takeEvery(GET_PRIVILAGES, fetchPrivilege);
  yield takeEvery(ADD_PRIVILAGE, onaddPrivilege);
  yield takeEvery(UPDATE_PRIVILAGE, onupdatePrivilege);
  yield takeEvery(DELETE_PRIVILAGE, ondeletePrivilege);
  yield takeEvery(GET_PRIVILAGES_OPTIONS, fetchPrivilages);
}

export default privilagesSaga;
