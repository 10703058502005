import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { useState } from "react";
import { useEffect } from "react";

const Authmiddleware = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => {
  const history = useHistory();
  const [editOptions, setEditOptions] = useState({ export: false, view: false, delete: false, edit: false, loading: false });

  useEffect(() => {
    if (isAuthProtected && rest.path) {
      const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
      setEditOptions((pre) => ({ ...pre, loading: true }));
      axios
        .post(
          `${API_URL}auth/allowed`,
          { module_url: rest.path },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.allowed) {
            setEditOptions((pre) => ({ ...res.data.options, loading: false }));
          } else {
            history.push("/dashboard");
          }
        })
        .catch((err) => {
          setEditOptions((pre) => ({ ...pre, error: err.response.data.message, loading: false }));
        });
    }
  }, [isAuthProtected, rest.path, history]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        return (
          <Layout>
            <Component {...props} options={editOptions} />
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  options: PropTypes.object,
  component: PropTypes.any,
  layout: PropTypes.any,
};

export default Authmiddleware;
