/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Col, FormGroup, Label, Row, TabPane, Card, CardBody, Button } from "reactstrap";
import Select from "react-select";
import toastr from "toastr";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import "./counsellingnotes.scss";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Modal } from "react-bootstrap";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { getDate, getFirstday } from "../../../../helpers/globalFunctions";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function CounsellingNotes(props) {
  const formRef = useRef();

  let { activeTabVartical, details, id, editable } = props;

  const [viewPatientObject, setViewPatientObject] = useState({});
  const [viewPatientValue, setViewPatientValue] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientOptions, setPatientOptions] = useState([]);
  const [counsellingType, setCounsellingType] = useState("");
  const [values, setValues] = useState([{}]);
  const [prevvalues, setPrevValues] = useState([{}]);
  const [isOpen, setIsOpen] = useState(false);
  const [patientvalues, setPatientValues] = useState(false);
  const [patientprevvalues, setPatientPrevValues] = useState(false);
  const [masterObject, setmasterObject] = useState({
    admission_id: props.id,
    c_date: getDate(new Date()),
  });
  const [selectedGender, setSelectedGender] = useState(null);
  const [counsellingIdTobeDeleted, setCounsellingIdTobeDeleted] = useState(null);
  const [counsellingIdTobeUpdated, setCounsellingIdTobeUpdated] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch_all_patients();
    handleTableData();
    // setmasterObject({
    //     ...masterObject,
    //     admission_id: props.id,
    //     c_date: getDate(new Date()),
    // });
    // fetch_patient_details()
    //fetch_previous_counselling_details()
  }, [props]);
  const handleChangeInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setmasterObject({
      ...masterObject,
      [name]: value,
    });
  };
  const fetch_all_patients = () => {
    axios
      .get(`${API_URL}admission/options/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let patient_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.fname + " " + el?.lname + "-" + el?.reg_no,
              value: el?._id,
            };
          });
        setPatientOptions([
          {
            options: patient_data,
          },
        ]);
      });
  };
  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}counselling?c_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let data = {};
        data.date = moment(result?.c_date).format("DD-MM-YYYY");
        data.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
        data.duration = result?.duration;
        data.time = moment(item?.time, "hhmmss").format("hh:mm a");
        if (result?.type == 0) {
          data.type = "I";
        } else {
          data.type = "F";
        }
        data.issues = result?.issues;
        data.staff = result?.addedBy?.firstName + " " + result?.addedBy?.lastName;

        setPopupData(data);
        setPopupView(true);
      });
  };
  function preUpdateCounsellingNotes(item) {
    setCounsellingIdTobeUpdated(item?._id);
    axios
      .get(`${API_URL}counselling?c_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let data = {};
        data.c_id = item?._id;
        // data.date = moment(result?.date).format("DD-MM-YYYY");
        data.c_date = item?.c_date;
        data.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
        data.duration = result?.duration;
        // item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname
        data.issues = result?.issues;
        data.admission_id = result?.admission_id?.basic_details?.basic_id?._id;
        data.type = result?.type;
        let type = {};
        if (result.type == 0) {
          type = {
            label: "I",
            value: 0,
          };
        } else {
          type = {
            label: "F",
            value: 1,
          };
        }
        let patient = {
          label:
            result?.admission_id?.basic_details?.basic_id?.fname +
            " " +
            result?.admission_id?.basic_details?.basic_id?.lname +
            "-" +
            result?.admission_id?.reg_no,
          value: result?.admission_id?.basic_details?.basic_id?._id,
        };

        setSelectedPatient(patient);
        setCounsellingType(type);
        setmasterObject(data);
      });
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function handleTableData() {
    let admission_id = props.id;

    var url = `${API_URL}counselling/list?admission_id=` + admission_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          item.name = item?.admission_id?.basic_details?.basic_id?.fname + " " + item?.admission_id?.basic_details?.basic_id?.lname;
          item.date = moment(item?.c_date).format("DD-MM-YYYY");
          item.reg_no = item?.admission_id?.reg_no;
          item.firstName = item?.addedBy?.firstName;
          item.duration = item?.duration;
          if (item?.type == 0) {
            item.type = "I";
          } else {
            item.type = "F";
          }

          item.action = (
            <>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.4em",
                }}
                onClick={() => {
                  preUpdateCounsellingNotes(item);
                  toTop();
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "0.9em", cursor: "pointer" }}
                onClick={() => {
                  setCounsellingIdTobeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </>
          );
        });

        setTableData(result);
      });
  }
  const handleSelectChange = async (selected, type) => {
    switch (type) {
      case "type":
        setCounsellingType(selected);
        setmasterObject({
          ...masterObject,
          type: selected.value,
        });
      default:
        break;
    }
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      // {
      //     label: "Name",
      //     field: "name",
      //     sort: "asc",
      //     width: 270,
      // },
      // {
      //     label: "Reg No",
      //     field: "reg_no",
      //     sort: "asc",
      //     width: 200,
      // },
      {
        label: "I/F",
        field: "type",
        sort: "asc",
        width: 200,
      },

      {
        label: "Issues",
        field: "issues",
        sort: "asc",
        width: 200,
      },

      // {
      //     label: "Duration",
      //     field: "duration",
      //     sort: "asc",
      //     width: 200,
      // },

      // {
      //     label: "Staff",
      //     field: "firstName",
      //     sort: "asc",
      //     width: 100,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableData,
  };

  const handleValidSubmit = (e) => {
    if (counsellingIdTobeUpdated) {
      axios
        .put(`${API_URL}counselling`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Counselling Notes updated successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({
            c_date: getDate(new Date()),
            admission_id: props.id,
          });
          setSelectedPatient(null);
          setCounsellingType(null);
          setPatientValues(false);
          setPatientPrevValues(false);
          handleTableData();
          setCounsellingIdTobeUpdated(null);
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    } else {
      axios
        .post(`${API_URL}counselling`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Counselling Notes added successfully");

          formRef.current.reset();

          setValues([{}]);
          setmasterObject({
            c_date: getDate(new Date()),
            admission_id: props.id,
          });
          setSelectedPatient(null);
          setCounsellingType(null);

          handleTableData();
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }
  };

  return (
    <TabPane tabId={1}>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}counselling?c_id=` + counsellingIdTobeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Counselling notes deleted successfully");
                    if (masterObject && masterObject.c_id === counsellingIdTobeDeleted) {
                      formRef.current.reset();
                      setmasterObject({});
                      setCounsellingIdTobeDeleted(null);
                    }
                    setCounsellingIdTobeDeleted(null);
                    handleTableData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Medicine");
                    return;
                  }
                })
                .catch((err) => {
                  toastr.error(err.response.data.message);
                  return;
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}>
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <Modal show={popupView} size="lg" centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Counselling Notes Details </h5>
            <button
              type="button"
              onClick={() => {
                setPopupView(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
            <div className="modal-body">
              <table id="product_available_day" className="table table-bordered dataTable">
                <tr>
                  <td style={{ width: "184px", padding: "10px" }}>Patient Name :</td>
                  <td>{popupData?.name}</td>
                  <td style={{ paddingLeft: "25px" }}>Date :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.date}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Time :</td>
                  <td>{popupData?.time}</td>
                  <td style={{ paddingLeft: "25px" }}>I/F :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.type}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Duration :</td>
                  <td>{popupData?.duration}</td>
                  <td style={{ paddingLeft: "25px" }}>Staff :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Issues :</td>
                  <td>{popupData?.issues}</td>
                  <td style={{ paddingLeft: "25px" }}></td>
                  <td style={{ textAlign: "left" }}></td>
                </tr>
              </table>
            </div>
          </AvForm>
        </Modal>
        <div className="mt-2">
          {/* <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Counselling Notes" /> */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}>
                    <Row>
                      <Col md="3">
                        <div className="mt-0">
                          <Label>Date</Label>
                          <AvField
                            disabled={!editable}
                            name="c_date"
                            placeholder="Date"
                            type="date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={handleChangeInput}
                            value={masterObject?.c_date}
                          />
                        </div>
                      </Col>

                      <Col md="3" className="mt-0">
                        <Label>I/F</Label>
                        <Select
                          name="type"
                          isDisabled={!editable}
                          value={counsellingType}
                          onChange={(selected) => {
                            handleSelectChange(selected, "type");
                          }}
                          options={[
                            { label: "I", value: 0 },
                            { label: "F", value: 1 },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </Col>

                      <Col md="2" className="mt-0">
                        <Label>Duration(Minutes)</Label>
                        <AvField
                          disabled={!editable}
                          name="duration"
                          type="number"
                          validate={{ required: { value: true } }}
                          min={1}
                          className="form-control"
                          placeholder="Duration"
                          value={masterObject?.duration}
                          onChange={handleChangeInput}
                        />
                      </Col>

                      <Col md="3" className="mt-0">
                        <Label>Issues dealt with</Label>
                        <AvField
                          name="issues"
                          type="textarea"
                          // minLength={6}
                          // maxLength={6}
                          className="form-control"
                          placeholder="Issues dealt with"
                          value={masterObject?.issues}
                          disabled={!editable}
                          onChange={handleChangeInput}
                          rows={1}
                        />
                      </Col>

                      <Col md="1" className="mt-4">
                        {/* <FormGroup >
                                                        <button type="submit" className="save-continue-btn" >
                                                            Submit
                                                            <i style={{ fontSize: "13px" }}></i>
                                                        </button>
                                                    </FormGroup> */}
                        {editable && (
                          <>
                            {counsellingIdTobeUpdated ? (
                              <Button color="primary" type="submit">
                                {"Update"}
                              </Button>
                            ) : (
                              <Button color="primary" type="submit">
                                {"Submit"}
                              </Button>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      {/* <h5>Previous Counselling Details</h5> */}
                      <MDBDataTable
                        id="counsellingTablesID"
                        responsive
                        //striped
                        bordered
                        data={data}
                        info={true}
                        searching={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {/* </div>
            </div> */}
      </React.Fragment>
    </TabPane>
  );
}
export default CounsellingNotes;
